<div class="paginator">
  <div class="paginator__side paginator__side--left">
    <button
      *ngIf="!isFirstPage()"
      class="paginator__button paginator__button--prev"
      type="button"
      (click)="changePage(-1)"
      cdkMonitorElementFocus
    >
      <span class="visually-hidden">Go to previous page</span>
    </button>
  </div>

  <div class="paginator__middle">
    {{ pageIndexView * pageSize - pageSize + 1 }}
    {{ ' - ' }}
    {{
      pageIndexView * pageSize > totalCount
        ? totalCount
        : pageIndexView * pageSize
    }}
    <span i18n="@@paginator_of">of</span>
    {{ totalCount }}
  </div>
  <div class="paginator__side paginator__side--right">
    <button
      *ngIf="!isLastPage()"
      class="paginator__button paginator__button--next"
      type="button"
      (click)="changePage(1)"
      cdkMonitorElementFocus
    >
      <span class="visually-hidden">Go to next page</span>
    </button>
  </div>
</div>
