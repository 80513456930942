<select
  [ngClass]="{
    'drop-down__select': true,
    'drop-down__select--invalid': isInvalid
  }"
  [attr.id]="id"
  [attr.name]="name"
  [formControl]="formControl"
  [attr.aria-invalid]="isInvalid"
  [attr.aria-describedby]="isInvalid ? errorId : null"
  [attr.disabled]="disabled ? '' : null"
>
  <option value="" selected hidden>{{ placeholder }}</option>
  <option
    [ngValue]="valueKey ? option[valueKey] : option"
    *ngFor="let option of options"
  >
    {{ labelKey ? option[labelKey] : option }}
  </option>
</select>
