<label class="simple-select__label visually-hidden" [attr.for]="id">
  {{ label }}
</label>
<select
  class="simple-select__select"
  [(ngModel)]="value"
  (ngModelChange)="updateValue($event)"
  [attr.id]="id"
  [attr.name]="name"
>
  <option [ngValue]="option[valueKey]" *ngFor="let option of options">
    {{ option[labelKey] }}
  </option>
</select>
