import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { NewsFeedContainerComponent } from './containers/news-feed/news-feed-container.component';
import { NewsComponent } from './news.component';
import { PreviewFeedCardComponent } from './containers/preview-feed-card/preview-feed-card.component';
import { BlogPostComponent } from '../shared/components/feed/blog-post/blog-post.component';
import { FeedType } from '../shared/enums/FeedType';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { Module } from '../shared/enums/Module';

const routes: Routes = [
  {
    path: 'news',
    component: NewsComponent,
    canActivate: [AuthenticationGuard],
    data: {
      module: Module.News,
      pageTitle: TranslationMessages.NewsTitle
    },
    children: [
      {
        path: '',
        component: NewsFeedContainerComponent,
        data: {
          showMenuBar: true,
          pageTitle: TranslationMessages.NewsTitle,
          siteMapKey: SiteMapItemKeys.NewsFeed
        }
      },
      {
        path: ':id',
        component: BlogPostComponent,
        data: {
          showMenuBar: false,
          feedType: FeedType.News,
          siteMapKey: SiteMapItemKeys.NewsDetails
        }
      }
    ]
  },
  {
    path: 'preview-news-post/:id',
    component: PreviewFeedCardComponent,
    canActivate: [],
    data: {
      module: Module.News,
      showMenuBar: false,
      hideNavigationBar: true,
      siteMapKey: SiteMapItemKeys.PreviewNewsPost
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule {}
