import { Component, OnInit } from '@angular/core';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import { ButtonType } from '../../../../../shared/enums/ButtonLink';
import { AnalyticsEvents } from '../../../../../shared/enums/AnalyticsEvents';
import { select, Store } from '@ngrx/store';
import {
  AppState,
  getActiveWeekPerformanceAndGoal
} from '../../../../../reducers';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { GoalActions } from '../../actions';
import { GoalSlidersConfig, SlidersConfig } from '../../config/slidersConfig';
import { ItemType } from '../../enums/ItemType';
import { GoalDetails } from '../../../../../core/dataEntities/goal/goalDetails';
import { GoalStoreService } from '../../services/goal.store-service';
import { filter, first } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { WagesActions } from '../../../../actions';
import { ValidationStatus } from '../../enums/ValidationStatus';

@Component({
  selector: 'app-weekly-goal-container',
  templateUrl: './weekly-goal-container.component.html',
  styleUrls: ['./weekly-goal-container.component.scss'],
  providers: [GoalStoreService]
})
export class WeeklyGoalContainerComponent implements OnInit {
  public SvgIconNameEnum = SvgIconName;
  public ButtonTypeEnum = ButtonType;
  public AnalyticsEventsEnum = AnalyticsEvents;

  public config: GoalSlidersConfig;
  currentEarning$: Observable<number>;
  goalDetails$: Observable<GoalDetails>;
  isPerformanceLoading$: Observable<boolean>;
  isGoalLoading$: Observable<boolean>;
  isEarningsLoading$: Observable<boolean>;
  associatePaySplit$: Observable<number>;
  ItemTypeEnum = ItemType;
  ValidationStatusEnum = ValidationStatus;
  goalFormGroup: FormGroup;

  constructor(
    private store: Store<AppState>,
    public goalStoreService: GoalStoreService,
    private route: ActivatedRoute
  ) {
    this.config = SlidersConfig;
    this.route.paramMap.pipe(first()).subscribe((params: ParamMap) => {
      const weekEndingDate = params.get('week');

      this.store.dispatch(
        WagesActions.loadPaymentWeekDetails({
          weekEndingDate
        })
      );
      this.store.dispatch(
        GoalActions.loadWeeklyGoal({
          weekEndingDate
        })
      );
      this.store.dispatch(
        GoalActions.loadWeeklyPerformance({
          weekEndingDate
        })
      );
    });
  }

  ngOnInit() {
    this.isPerformanceLoading$ = this.goalStoreService.isPerformanceLoading$;
    this.isGoalLoading$ = this.goalStoreService.isGoalLoading$;
    this.isEarningsLoading$ = this.goalStoreService.isEarningsLoading$;
    this.currentEarning$ = this.goalStoreService.currentEarning$;
    this.goalDetails$ = this.goalStoreService.goalDetails$;
    this.associatePaySplit$ = this.goalStoreService.associatePaySplit$;
    this.store
      .pipe(
        select(getActiveWeekPerformanceAndGoal, { config: this.config }),
        filter(({ performance, goal }) => performance != null && goal != null),
        first()
      )
      .subscribe(
        ({ performance, goal }) => {
          this.goalFormGroup = this.goalStoreService.createGoalForm(
            this.config,
            goal,
            performance
          );
        },
        err => {
          console.error(err);
        }
      );
  }

  onSubmit(goalFormGroup) {
    this.store.dispatch(
      GoalActions.submitGoal({
        goal: goalFormGroup.value
      })
    );
  }
}
