import { Injectable } from '@angular/core';
import { NgxHotjarService } from 'ngx-hotjar';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable()
export class AnalyticsService {
  constructor(protected $hotjar: NgxHotjarService, private router: Router) {
  }

  registerTrackingEvent(eventName: string) {
    if(!environment.hotjar.siteId){
      return;
    }
    if (eventName) {
      this.$hotjar.virtualPageView(this.getCurrentRoute() + '/' + eventName);
    }
  }

  virtualPageView(path) {
    if(!environment.hotjar.siteId){
      return;
    }

    this.$hotjar.virtualPageView(path);
  }

  private getCurrentRoute() {
    return this.router.url.substr(0, this.router.url.lastIndexOf('/'));
  }
}
