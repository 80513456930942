import { Injectable } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { createEffect } from '@ngrx/effects';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { AppState, RouteSelectors } from '../../reducers';
import { Store } from '@ngrx/store';
import { ApplicationInsightsService } from '../../shared/services/application-insights.service';

const isValidAnalyticsTrackCallPayload = event => {
  return (
    (event && event.detail && event.detail.analyticsId) ||
    (event &&
      event.target &&
      event.target.hasAttribute &&
      event.target.hasAttribute('analyticsId'))
  );
};

@Injectable()
export class AnalyticsEffects {
  click$ = fromEvent(document, 'click');
  customEvent$ = fromEvent(document, 'customAnalytics');
  analyticsTrackCall$ = createEffect(
    () =>
      merge(this.click$, this.customEvent$).pipe(
        filter(isValidAnalyticsTrackCallPayload),
        tap(event => {
          let analyticsId;
          let analyticsLabel;
          const analyticsData = event.detail && event.detail.analyticsData;

          if (event && event.detail && event.detail.analyticsId) {
            analyticsId = event.detail.analyticsId;
            analyticsLabel = analyticsData ? analyticsData.label : null;
          } else if (event && event.target) {
            analyticsId = event.target.getAttribute('analyticsId');
            analyticsLabel = event.target.getAttribute('analyticsLabel');
          }
          let path = analyticsId;
          if (analyticsLabel) {
            path += `/${analyticsLabel}`;
          }

          if (analyticsData) {
            if (analyticsData.category) {
              path = `${analyticsData.category}/${path}`;
            }
            if (analyticsData.action) {
              path += `/${analyticsData.action}`;
            }
          }
          this.analyticsService.virtualPageView(`/${path}`);
        })
      ),
    { dispatch: false }
  );

  routeChangeTrackCall$ = createEffect(
    () =>
      this.router.events.pipe(
        filter(event => event instanceof ResolveEnd),
        withLatestFrom(
          this.store.select(RouteSelectors.selectRouteData),
          this.store.select(RouteSelectors.selectCurrentRoute)
        ),
        tap(([e, data, route]) => {
          const activatedComponent = route.component;
          const event = e as ResolveEnd;
          const routeTemplate = this.getRouteTemplate(event.state.root);
          if (activatedComponent) {
            this.appInsightsService.logPageView(
              `${activatedComponent.name} ${routeTemplate}`,
              event.urlAfterRedirects
            );
          }
          if (route.paramMap.keys.length) {
            this.analyticsService.virtualPageView(`/${routeTemplate}`);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private analyticsService: AnalyticsService,
    private appInsightsService: ApplicationInsightsService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
      if (path[path.length - 1] !== '/') {
        path += '/';
      }
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
