<dl class="list">
  <ng-container *ngFor="let listItem of listItems">
    <div
      [attr.data-cy]="listItem.dataCy"
      [ngClass]="{
        list__item: true,
        'list__item--full': 1 === listItem.columns,
        'list__item--half': 2 === listItem.columns,
        'list__item--third': 3 === listItem.columns,
        'list__item--scrollable': listItem.scrollable
      }"
    >
      <ng-template #defaultTitleTemplate>
        {{ listItem.title }}
      </ng-template>

      <dt class="list__title">
        <ng-container *ngTemplateOutlet="listItem.titleTemplate ? listItem.titleTemplate : defaultTitleTemplate"></ng-container>
      </dt>
      <ng-container *ngIf="listItem.isArray">
        <dd *ngFor="let items of listItem.items" class="list__value">{{ items }}</dd>
      </ng-container>
      <ng-container *ngIf="!listItem.isArray">
        <dd class="list__value">{{ listItem.items }}</dd>
      </ng-container>
    </div>
  </ng-container>
</dl>
