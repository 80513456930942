import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { Router } from '@angular/router';
import { AppNotification } from '../../dataEntities/notification/notification';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
  animations: [
    trigger('enterLeaveTrigger', [
      transition(':enter', [
        style({ height: '0', 'min-height': '0' }),
        animate('0.3s ease-in-out', style({}))
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ height: '0', 'min-height': '0' }))
      ])
    ])
  ]
})
export class NotificationBarComponent implements OnInit {
  @Input() errorNotification: AppNotification;
  @Input() infoNotification: AppNotification;
  @Input() noActionNotification: AppNotification;
  @Input() successNotification: AppNotification;

  @Output() dismissError = new EventEmitter();
  @Output() dismissInfo = new EventEmitter();
  @Output() dismissSuccess = new EventEmitter();

  SvgIconNameEnum = SvgIconName;

  constructor(private router: Router) {}

  ngOnInit() {}

  onDismissError() {
    this.dismissError.emit(true);
  }

  onDismissInfo() {
    this.dismissInfo.emit(true);
  }

  onDismissSuccess() {
    this.dismissSuccess.emit(true);
  }

  showOver(): boolean {
    const isLandingPage = this.router.url.split('?')[0] === '/';

    return (
      (this.infoNotification && this.infoNotification.showOver) ||
      (this.errorNotification && this.errorNotification.showOver) ||
      (this.noActionNotification && this.noActionNotification.showOver) ||
      (this.successNotification && this.successNotification.showOver) ||
      isLandingPage
    );
  }
}
