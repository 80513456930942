import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {ApplicationInsightsService} from '../../shared/services/application-insights.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private injector: Injector) {
  }

  handleError(error) {
    console.error(error);
    const service = this.injector.get(ApplicationInsightsService);

    service.logApplicationError(error.stack);
  }
}
