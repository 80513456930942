import { Associates, Surveys } from '../enums/ApiResponseCodes';
import { TranslationMessages } from '../enums/TranslationMessages';

export const AppErrorMessages = {
  [Associates.ASSOCIATE_NOT_FOUND]: TranslationMessages.ErrorMessagesAssociateNotFound,
  [Associates.WEEK_ENDING_DATE_NOT_SATURDAY]: TranslationMessages.ErrorMessagesWeekEndingDateNotSaturday,
  [Associates.EARNINGS_NOT_ALLOWED_FOR_SITE]: TranslationMessages.ErrorMessagesEarningsNotAllowedForSite,
  [Surveys.SURVEY_ALREADY_SUBMITTED_FOR_ASSOCIATE]: TranslationMessages.ErrorMessagesSurveyAlreadySubmitted,
  [Surveys.SURVEY_NOT_FOUND]: TranslationMessages.ErrorMessagesSurveyNotFound,
  unknownError: TranslationMessages.ErrorMessagesUnknownError
};
