import { Injectable, Injector, OnDestroy, TemplateRef } from '@angular/core';
import {
  ComponentType,
  Overlay,
  OverlayConfig,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay';
import * as _ from 'lodash';

import { INFOBOX_DATA, InfoBoxConfig } from './info-box/info-box.config';
import { InfoBoxContainerComponent } from './info-box/info-box-container.component';
import {
  ComponentPortal,
  PortalInjector,
  TemplatePortal
} from '@angular/cdk/portal';
import { InfoBoxRef } from './info-box/info-box-ref';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class InfoBoxService implements OnDestroy {
  private createdOverlays: OverlayRef[] = [];
  private routerSubscription;
  private groupConfig: {
    [key: string]: {
      singlePerGroup: boolean;
    };
  } = {};
  private overlayRefGroups = new Map();

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private overlay: Overlay,
    private injector: Injector,
    private router: Router
  ) {
    this.routerSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.closeInfoboxesOnNavigation();
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  open<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    c: InfoBoxConfig,
    groupName: string = ''
  ) {
    const config = this.applyConfigDefaults(c, new InfoBoxConfig());
    const overlayRef = this.createOverlay(config);
    const infoBoxContainer = this.attachDialogContainer(
      overlayRef,
      InfoBoxContainerComponent,
      config
    );
    this.closeAllGroupInfoBoxes(groupName);
    this.attachOverlay(overlayRef);
    const infoBoxRef = this.attachDialogContent(
      componentOrTemplateRef,
      infoBoxContainer.instance,
      overlayRef,
      config
    );

    if (!this.groupConfig[groupName]) {
      this.groupConfig[groupName] = {
        singlePerGroup: c.singlePerGroup
      };
    }

    this.overlayRefGroups.set(overlayRef, groupName);

    return infoBoxRef;
  }

  private createOverlay(config: InfoBoxConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);

    overlayConfig.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(config.triggerEl)
      .withPositions([
        {
          originX: config.originX,
          originY: config.originY,
          overlayX: config.overlayX,
          overlayY: config.overlayY,
          offsetY: config.offsetY,
          offsetX: config.offsetX
        }
      ]);

    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(infoBoxConfig: InfoBoxConfig): OverlayConfig {
    const state = new OverlayConfig({
      scrollStrategy:
        infoBoxConfig.scrollStrategy &&
        infoBoxConfig.scrollStrategy === 'reposition'
          ? this.overlay.scrollStrategies.reposition()
          : this.overlay.scrollStrategies.close(),
      hasBackdrop: infoBoxConfig.hasBackdrop,
      width: infoBoxConfig.width,
      height: infoBoxConfig.height,
      disposeOnNavigation: infoBoxConfig.disposeOnNavigation
    });
    if (infoBoxConfig.backdropClass) {
      state.backdropClass = infoBoxConfig.backdropClass;
    }
    return state;
  }

  private attachDialogContainer<T>(
    overlayRef: OverlayRef,
    component: ComponentType<T>,
    config: InfoBoxConfig
  ) {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = new PortalInjector(
      userInjector || this.injector,
      new WeakMap([[InfoBoxConfig, config]])
    );
    const containerPortal = new ComponentPortal(
      component,
      config.viewContainerRef,
      injector,
      config.componentFactoryResolver
    );
    return overlayRef.attach<T>(containerPortal);
  }

  private attachDialogContent<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    dialogContainer: InfoBoxContainerComponent,
    overlayRef: OverlayRef,
    config: InfoBoxConfig
  ): InfoBoxRef<T> {
    const infoBoxRef = new InfoBoxRef<T>(overlayRef, dialogContainer);

    if (config.hasBackdrop) {
      overlayRef.backdropClick().subscribe(() => {
        if (!infoBoxRef.disableClose) {
          infoBoxRef.close();
        }
      });
    }

    if (componentOrTemplateRef instanceof TemplateRef) {
      dialogContainer.attachTemplatePortal(
        new TemplatePortal<T>(componentOrTemplateRef, null, {
          dialogRef: infoBoxRef
        } as any)
      );
    } else {
      const injector = this.createInjector<T>(
        config,
        infoBoxRef,
        dialogContainer
      );
      const contentRef = dialogContainer.attachComponentPortal(
        new ComponentPortal(componentOrTemplateRef, undefined, injector)
      );

      infoBoxRef.componentInstance = contentRef.instance;
    }

    return infoBoxRef;
  }

  private createInjector<T>(
    config: InfoBoxConfig,
    infoBoxRef: InfoBoxRef<T>,
    infoBoxContainer: InfoBoxContainerComponent
  ): PortalInjector {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;

    const injectionTokens = new WeakMap<any, any>([
      [InfoBoxContainerComponent, infoBoxContainer],
      [INFOBOX_DATA, config.data],
      [InfoBoxRef, infoBoxRef]
    ]);

    return new PortalInjector(userInjector || this.injector, injectionTokens);
  }

  private applyConfigDefaults(
    config?: InfoBoxConfig,
    defaultOptions?: InfoBoxConfig
  ): InfoBoxConfig {
    return { ...defaultOptions, ...config };
  }

  private attachOverlay(overlayRef: OverlayRef) {
    this.createdOverlays.push(overlayRef);
    overlayRef.detachments().subscribe(() => {
      this.removeCreatedOverlay(overlayRef);
    });
  }

  private removeCreatedOverlay(overlayRef) {
    const i = this.createdOverlays.indexOf(overlayRef);
    if (i >= 0) {
      this.createdOverlays.splice(i, 1);
    }
  }

  private closeInfoboxesOnNavigation() {
    this.closeOverlayRefIf(or => {
      const config = or.getConfig() || {};
      return config.disposeOnNavigation;
    });
  }

  private closeAllGroupInfoBoxes(groupName) {
    const config = this.groupConfig[groupName];
    if (!config || !config.singlePerGroup) {
      return;
    }
    this.closeOverlayRefIf(infoBox => {
      const gn = this.overlayRefGroups.get(infoBox);
      return gn === groupName;
    });
  }

  private closeOverlayRefIf(validationFn: (infoBox: OverlayRef) => boolean) {
    const overlays = [...this.createdOverlays];
    _.forEach(overlays, co => {
      const shouldDispose = validationFn(co);
      if (shouldDispose) {
        co.dispose();
      }
    });
  }
}

export class InfoBoxResult<T = any> {
  success: boolean;
  data?: T;
}
