<div
  [ngClass]="{
    'number-input': true,
    'number-input--yellow': validationStatus === ValidationStatusEnum.Warning,
    'number-input--red': validationStatus === ValidationStatusEnum.Error,
    'number-input--focus': isInputFocused,
    'number-input--error': hasError
  }"
  [formGroup]="formGroup"
>
  <span
    *ngIf="prefix"
    [ngClass]="{
      'number-input__prefix': true,
      'number-input__prefix--focus': isInputFocused}
    "
  >{{prefix}} </span>
  <input
    [id]="name"
    class="number-input__input"
    type="number"
    formControlName="value"
    [value]="value"
    [attr.aria-invalid]="hasError"
    [name]="name"
    (keyup)="onKeyup($event)"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    [attr.data-cy]="name"
  />
  <div class="number-input__text-value" area-hidden="true">
    <span class="number-input__hidden" *ngIf="prefix">{{prefix}} </span>
    <span class="number-input__hidden">{{value}}</span>
    <span
      *ngIf="suffix"
      [ngClass]="{'number-input__hidden': isInputFocused}"
    > {{suffix}}</span>
  </div>
</div>
