import { OpportunitiesService } from '../../../../core/contracts/OpportunitiesService';
import { forkJoin, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { OpportunityDetailsResponse } from '../../../../core/responses/opportunities/opportunityDetailsResponse';
import { OpenShiftListItem } from '../../../../core/dataEntities/opportunities/openShiftListItem';
import { ContentDeliveryService } from '../../../../shared/services/content-delivery.service';
import { SiteService } from '../../../../shared/services/site.service';
import { AppliedOpportunitiesResponse } from '../../../../core/responses/opportunities/appliedOpportunitiesResponse';
import { ListOfOpportunitiesResponse } from '../../../../core/responses/opportunities/listOfOpportunitiesResponse';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FilterType } from '../enums/FilterType';
import { SortDirections } from '../../../../core/enums/SortDirections';
import { isEmpty, keys, find } from 'lodash';
import { ApiGatewayService } from '../../../../shared/services/api.gateway.service';
import { Store } from '@ngrx/store';
import {
  AppState,
  getAssociateHomeState,
  getAuthToken
} from '../../../../reducers';
import { OpportunityDetails } from '../../../../core/dataEntities/opportunities/opportunityDetails';
import { defaultOpportunitiesListFilter } from '../reducers/opportunities-list.reducer';
import { JobStatus } from '../../../../shared/enums/JobStatus';

@Injectable({
  providedIn: 'root'
})
export class OpportunitiesApiService implements OpportunitiesService {
  roadCrewApiEndpoint = 'roadcrew';
  homeState = '';

  constructor(
    private siteService: SiteService,
    private contentDeliveryService: ContentDeliveryService,
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    const associateHomeState$ = this.store.select(getAssociateHomeState);
    associateHomeState$.subscribe(val => {
      this.homeState = val;
    });
  }

  getDetails(
    opportunityId: string,
    otherId: string,
    associateId: number
  ): Observable<OpportunityDetailsResponse> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.get<any>(
          `${this.roadCrewApiEndpoint}/get_opportunity_details`,
          authToken,
          {
            opportunityId,
            associateId,
            id: otherId,
            language: this.localeId
          }
        );
      })
    );
  }

  getOpenShifts(siteId): Observable<OpenShiftListItem[]> {
    const query = {
      'fields.author.sys.contentType.sys.id': 'author',
      'fields.category.sys.contentType.sys.id': 'newsCategory',
      'fields.sites[in]': `${siteId}`,
      'fields.category.fields.key': 'workOpportunity'
    };

    return forkJoin(
      this.contentDeliveryService.getNews(0, 10, query),
      this.siteService.getSiteInfo(siteId)
    ).pipe(
      map(([feed, siteInfo]) => {
        const shifts = [];
        feed.posts.forEach(post => {
          shifts.push({
            id: post.id,
            date: post.shift.startDateTime,
            site: siteInfo
          } as OpenShiftListItem);
        });

        return shifts;
      })
    );
  }

  getListOfAppliedOpportunities(
    associateId: number
  ): Observable<AppliedOpportunitiesResponse> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.get<any>(
          `${this.roadCrewApiEndpoint}/opportunities/${associateId}/applied`,
          authToken
        );
      })
    );
  }

  getRoadCrewOpenings(
    associateId: number,
    params: {
      page?: { skip: number; limit: number };
      sort?: { active: string; direction: string };
      filter?: any;
    }
  ): Observable<ListOfOpportunitiesResponse> {
    const requestParams: any = {
      associateId,
      start: params.page ? params.page.skip : 0,
      size: params.page ? params.page.limit : 25,
      associateHomeState: this.homeState
    };

    let requestBody = null;
    if (params.filter) {
      requestBody = {
        ...this.prepareOpportunityListFilter(params.filter)
      };
    }
    if (params.sort) {
      requestParams.sortBy = params.sort.active;
      requestParams.sortDirection =
        params.sort.direction === SortDirections.Asc
          ? 'ascending'
          : 'descending';
    }

    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .post(
            `${this.roadCrewApiEndpoint}/opportunities`,
            authToken,
            requestBody,
            requestParams
          )
          .pipe(
            map((response: any) => {
              if (isEmpty(response)) {
                return null;
              }
              return response;
            })
          );
      })
    );
  }

  markAppliedOpportunityAsSeen(
    opportunityId: string,
    associateId: number
  ): Observable<any> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.post(
          `${this.roadCrewApiEndpoint}/opportunities/mark_as_seen`,
          authToken,
          {
            opportunityId,
            associateId
          }
        );
      })
    );
  }

  applyForOpportunity(
    opportunity: OpportunityDetails,
    associateId: number,
    associateName: string,
    preferredAirport: string
  ): Observable<any> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.post<any>(
          `${this.roadCrewApiEndpoint}/opportunities/apply`,
          authToken,
          {
            ...this.prepareApplyOpportunityObject(
              opportunity,
              associateId,
              associateName,
              preferredAirport
            )
          },
          {
            opportunityId: opportunity.opportunityID,
            associateId,
            preferredAirport
          }
        );
      })
    );
  }

  cancelAppliedOpportunity(
    opportunityId: string,
    associateId: number
  ): Observable<any> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.post<any>(
          `${this.roadCrewApiEndpoint}/opportunities/cancel`,
          authToken,
          null,
          {
            opportunityId,
            associateId
          }
        );
      })
    );
  }

  getAggregatedOpportunities(
    associateId: number,
    propertyName: FilterType,
    filter: any
  ): Observable<any> {
    const filters = this.prepareOpportunityListFilter(filter);
    const key = find(
      keys(filters),
      k => k.toLowerCase() === propertyName.toLowerCase()
    );

    filters[key] = defaultOpportunitiesListFilter[key];

    const requestBody = {
      ...filters
    };

    const requestParams: any = {
      associateId,
      type: 'byvalue',
      propertyName,
      associateHomeState: this.homeState
    };
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .post(
            `${this.roadCrewApiEndpoint}/opportunities/aggregated`,
            authToken,
            requestBody,
            requestParams
          )
          .pipe(
            map((response: any) => {
              if (isEmpty(response)) {
                return null;
              }
              return response;
            })
          );
      })
    );
  }

  getOpportunitiesCount(associateId: number, filter: any): Observable<any> {
    const filters = this.prepareOpportunityListFilter(filter);
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .post(
            `${this.roadCrewApiEndpoint}/opportunities/count`,
            authToken,
            { ...filters },
            {
              associateId,
              associateHomeState: this.homeState
            }
          )
          .pipe();
      })
    );
  }

  getIsEachOpportunityReviewed(associateId: number): Observable<any> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .get(
            `${this.roadCrewApiEndpoint}/opportunities/${associateId}/unseen`,
            authToken
          )
          .pipe(
            map(result => {
              return !result;
            })
          );
      })
    );
  }

  prepareApplyOpportunityObject(
    opportunity: OpportunityDetails,
    associateId,
    associateName,
    preferredAirport
  ) {
    return {
      id: opportunity.opportunityID,
      status: JobStatus.Applied,
      associate_eid: associateId,
      associate_name: associateName,
      preferred_airport: preferredAirport,
      opportunity_title: null,
      last_seen_on: opportunity.lastSeenOn,
      sub_status: opportunity.subStatus,
      startDate: opportunity.startDate,
      endDate: opportunity.endDate,
      expireDate: opportunity.expireDate,
      duration: opportunity.duration,
      responsiblePerson: opportunity.responsiblePerson,
      positions: opportunity.positions
    };
  }

  private prepareOpportunityListFilter(filter) {
    return {
      duration: filter.duration || defaultOpportunitiesListFilter.duration,
      jobTitle: filter.jobTitle || defaultOpportunitiesListFilter.jobTitle,
      locationState:
        filter.locationState || defaultOpportunitiesListFilter.locationState,
      startDateFrom:
        filter.startDateFrom || defaultOpportunitiesListFilter.startDateFrom,
      query: filter.query || defaultOpportunitiesListFilter.query
    };
  }
}
