import { NgModule } from '@angular/core';
import { IfHasPermissionDirective } from './if-has-permission.directive';
import { FeatureToggleDirective } from './feature-toggle.directive';
import { EnvironmentService } from '../services/environment.service';

@NgModule({
  declarations: [IfHasPermissionDirective, FeatureToggleDirective],
  exports: [IfHasPermissionDirective, FeatureToggleDirective],
  providers: [EnvironmentService]
})
export class DirectivesModule {}
