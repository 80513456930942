<app-alert
  title="Turn On Text Alerts"
  i18n-title="@@phone-reminder_turnOnTextAlerts"
  subtitle="Stay informed on new work opportunities and important alerts."
  i18n-subtitle="@@phone-reminder_stayInformed"
  [iconName]="SvgIconName.Earnings"
  [appearance]="AlertAppearance.Green"
  (closeAlert)="onCloseAlert($event)"
  actionTitle="Sign Up"
  i18n-actionTitle="@@phone-reminder_signUp"
  dismissTitle="Not Right Now"
  i18n-dismissTitle="@@phone-reminder_notNow"
></app-alert>
