import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectIsEachSurveySubmitted } from '../../../reducers';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-associate-profile-tabs',
  templateUrl: './associate-profile-tabs.component.html'
})
export class AssociateProfileTabsComponent implements OnInit {
  eachSurveySubmitted$: Observable<boolean>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.eachSurveySubmitted$ = this.store.select(selectIsEachSurveySubmitted);
  }
}
