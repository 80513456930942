import { Routes } from '@angular/router';
import { MyTeamMembersComponent } from './containers/my-team-members/my-team-members.component';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { Roles } from '../core/enums/Roles';
import { AuthorizationGuard } from '../core/guards/authorizationGuard/authorization.guard';
import { MyTeamComponent } from './my-team.component';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { MyTeamMemberCheckInComponent } from './containers/my-team-member-check-in/my-team-member-check-in.component';
import { MyTeamMemberProductionComponent } from './containers/my-team-member-production/my-team-member-production.component';
import { Module } from '../shared/enums/Module';
const ɵ0 = {
    siteMapKey: SiteMapItemKeys.MyTeam,
    allowedRoles: [Roles.SiteManager, Roles.SiteSupervisor]
}, ɵ1 = {
    module: Module.MyTeam,
    pageTitle: TranslationMessages.MyTeamTitle
}, ɵ2 = {
    module: Module.MyTeam,
    siteMapKey: SiteMapItemKeys.MyTeamMemberCheckInHistory,
    pageTitle: TranslationMessages.AssociateCheckInHistoryTitle
}, ɵ3 = {
    module: Module.MyTeam,
    siteMapKey: SiteMapItemKeys.MyTeamMemberProduction,
    pageTitle: TranslationMessages.AssociateProductionStatsTitle
};
const routes = [
    {
        path: 'my-team',
        component: MyTeamComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: MyTeamMembersComponent,
                data: ɵ1
            },
            {
                path: ':id/check-ins',
                component: MyTeamMemberCheckInComponent,
                data: ɵ2
            },
            {
                path: ':id/production',
                component: MyTeamMemberProductionComponent,
                data: ɵ3
            }
        ]
    }
];
export class MyTeamRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
