import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-accordion-header-primary',
  templateUrl: './accordion-header-primary.component.html',
  styleUrls: ['./accordion-header-primary.component.scss']
})
export class AccordionHeaderPrimaryComponent implements OnInit {
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() subtitle: string;
  @Input() subtitleTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}
