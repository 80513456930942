import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, filter } from 'rxjs/operators';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Post } from '../../../../core/dataEntities/feed/post';
import { combineLatest, Observable, Subject } from 'rxjs';
import { NewsActions } from '../../../../news/actions';
import { PostLinkType } from '../../../../news/enums/PostTypes';
import { RichTextHtmlRendererOptions } from '../../../constants';
import {
  AppState,
  getActivePost,
  getIsAppOnline,
  selectIsErrorOccurredOnPostLoading,
  selectIsPostLoading
} from '../../../../reducers';
import { FeedType } from '../../../enums/FeedType';
import { ResourcesActions } from '../../../../resources/actions';
import { ApplicationTitleService } from '../../../services/application-title.service';
import { selectNavigationBackUrl } from '../../../../core/reducers/navigation.reducer';
import { SvgIconName } from '../../../enums/SvgIcon';
import { ButtonAppearance } from '../../../enums/ButtonLink';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit, OnDestroy {
  private postId: string;
  public post: Post = null;
  public content: string;
  onDestroySubject = new Subject();
  public isPostLoading$: Observable<boolean>;
  public isAppOnline$: Observable<boolean>;
  public isPostErrorOccurred$: Observable<boolean>;
  public backUrl$: Observable<string>;
  public SvgIconNameEnum = SvgIconName;
  public ButtonAppearanceEnum = ButtonAppearance;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private appTitle: ApplicationTitleService
  ) {}

  ngOnInit() {
    this.isPostLoading$ = this.store.select(selectIsPostLoading);
    this.isAppOnline$ = this.store.select(getIsAppOnline);
    this.backUrl$ = this.store.select(selectNavigationBackUrl);
    this.isPostErrorOccurred$ = this.store.select(
      selectIsErrorOccurredOnPostLoading
    );
    combineLatest(this.route.data, this.route.paramMap)
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(([data, params]) => {
        this.postId = params.get('id');

        if (data.feedType === FeedType.News) {
          this.store.dispatch(NewsActions.loadPost({ postId: this.postId }));
        } else {
          this.store.dispatch(
            ResourcesActions.loadStarPost({ postId: this.postId })
          );
        }
      });

    this.store
      .select(getActivePost, { postId: this.postId })
      .pipe(
        filter(post => post),
        takeUntil(this.onDestroySubject)
      )
      .subscribe((post: Post) => {
        if (post) {
          if (post.linkType === PostLinkType.Internal) {
            this.content = documentToHtmlString(
              post.internalBlogPost.body,
              RichTextHtmlRendererOptions
            );
            this.post = post;
            this.appTitle.setTitle(this.post.title);
          } else if (post.linkType === PostLinkType.External) {
            window.open(post.externalPostUrl, '_self');
          }
        }
      });
  }

  ngOnDestroy() {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  onLikeEvent() {
    this.store.dispatch(
      NewsActions.likePost({
        postId: this.post.id,
        isLiked: !this.post.isLiked
      })
    );
  }
}
