import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { WeekPayDetails } from '../../../core/dataEntities/wages/weekPayDetails';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { Store } from '@ngrx/store';
import {
  AppState,
  getIsAppOnline,
  getUserTrainingStatus,
  selectActiveWeekCompletedTasksCount,
  selectActiveWeekPayDetails,
  selectIsPayLoading
} from '../../../reducers';
import { ActivatedRoute } from '@angular/router';
import { first, map, shareReplay } from 'rxjs/operators';
import { WagesActions } from '../../actions';
import { TrainingStatus } from '../../../shared/enums/TrainingStatus';
import { StatsAppearance } from '../../../shared/enums/Stats';
import { ItemType } from '../../modules/goal/enums/ItemType';
import { TranslationMessages } from '../../../shared/enums/TranslationMessages';
import { TranslationService } from '../../../shared/services/translation.service';
import { AssociateProductionDetails } from '../../../core/dataEntities/associate/associateProductionDetails';
import {
  selectIsProductionDetailsLoading,
  selectProductionDetails
} from '../../../profile/reducers/profile.reducer';
import { ProfileActions } from '../../../profile/actions';
import moment from 'moment';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit, OnDestroy {
  private onDestroySubject;
  public weekPayDetails$: Observable<WeekPayDetails>;
  public isHistoricalEarning$: Observable<boolean>;
  public isFutureProcessing$: Observable<boolean>;
  public showEmptyState$: Observable<boolean>;
  public isPayLoading$: Observable<boolean>;
  public isProductionLoading$: Observable<boolean>;
  public isAppOnline$: Observable<boolean>;
  public productionDetails$: Observable<AssociateProductionDetails>;
  public trainingStatus$: Observable<TrainingStatus>;
  public tasksCount$: Observable<number>;
  public options = [];
  public activeItemType = ItemType.Pallets;
  public ItemTypeEnum = ItemType;

  SvgIconNameEnum = SvgIconName;
  PageTitleAppearanceEnum = PageTitleAppearance;
  TrainingStatusEnum = TrainingStatus;
  StatsAppearanceEnum = StatsAppearance;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    translationService: TranslationService
  ) {
    this.options = [
      {
        id: ItemType.Pallets,
        name: translationService.translate(TranslationMessages.PalletsPerHour)
      },
      {
        id: ItemType.Cases,
        name: translationService.translate(TranslationMessages.CasesPerHour)
      }
    ];
  }

  ngOnInit() {
    const weekPayDetails$ = this.store.select(selectActiveWeekPayDetails);
    this.productionDetails$ = this.store.select(selectProductionDetails);
    this.isPayLoading$ = this.store.select(selectIsPayLoading);
    this.isAppOnline$ = this.store.select(getIsAppOnline);
    this.trainingStatus$ = this.store.select(getUserTrainingStatus);
    this.tasksCount$ = this.store.select(selectActiveWeekCompletedTasksCount);
    this.isProductionLoading$ = this.store.select(
      selectIsProductionDetailsLoading
    );
    this.showEmptyState$ = combineLatest(
      weekPayDetails$,
      this.isAppOnline$
    ).pipe(
      map(([weekPayDetails, isAppOnline]) => {
        return !weekPayDetails && !isAppOnline;
      })
    );

    this.weekPayDetails$ = combineLatest(
      weekPayDetails$,
      this.isPayLoading$
    ).pipe(
      map(([weekPayDetails, isPayLoading]) => {
        if (!weekPayDetails || isPayLoading) {
          return null;
        }
        return weekPayDetails;
      })
    );

    this.isHistoricalEarning$ = this.weekPayDetails$.pipe(
      map(weekPayDetails => {
        if (!weekPayDetails) {
          return;
        }
        return moment(weekPayDetails.weekEndingDate).isSameOrBefore(moment());
      }),
      shareReplay(1)
    );

    this.isFutureProcessing$ = this.weekPayDetails$.pipe(
      map(weekPayDetails => {
        if (!weekPayDetails) {
          return;
        }
        return moment(weekPayDetails.paymentProcessDate).isAfter(moment());
      }),
      shareReplay(1)
    );

    combineLatest(weekPayDetails$, this.route.paramMap)
      .pipe(first())
      .subscribe(([activeWeekPay, params]) => {
        if (activeWeekPay == null) {
          this.store.dispatch(
            WagesActions.loadPaymentWeekDetails({
              weekEndingDate: params.get('id')
            })
          );
        }

        this.store.dispatch(ProfileActions.loadProductionDetails());
      });
  }

  onChange(value) {
    this.activeItemType = value;
  }

  ngOnDestroy() {
    if (this.onDestroySubject) {
      this.onDestroySubject.unsubscribe();
      this.onDestroySubject = null;
    }
  }
}
