import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  AppState,
  getApexId,
  getIsAppOnline,
  getUserName,
  selectIsUserTrainee,
  selectUserPhoto
} from '../../../reducers';
import { ProfileActions } from '../../actions';
import { AssociateProductionDetails } from '../../../core/dataEntities/associate/associateProductionDetails';
import {
  selectIsProductionDetailsLoading,
  selectProductionDetails
} from '../../reducers/profile.reducer';
import { map } from 'rxjs/operators';
import { Features } from '../../../core/enums/Features';

@Component({
  selector: 'app-associate-production',
  templateUrl: './associate-production.component.html',
  styleUrls: ['./associate-production.component.scss']
})
export class AssociateProductionComponent implements OnInit {
  userName$: Observable<string>;
  userApexId$: Observable<number>;
  isTrainee$: Observable<boolean>;
  isProductionDetailsLoading$: Observable<boolean>;
  productionDetails$: Observable<AssociateProductionDetails>;
  showEmptyState$: Observable<boolean>;
  isAppOnline$: Observable<boolean>;
  photo$: Observable<string>;
  public Features = Features;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store.dispatch(ProfileActions.loadProductionDetails());
    this.isAppOnline$ = this.store.select(getIsAppOnline);
    this.userName$ = this.store.pipe(select(getUserName));
    this.userApexId$ = this.store.pipe(select(getApexId));
    this.isTrainee$ = this.store.pipe(select(selectIsUserTrainee));
    this.isProductionDetailsLoading$ = this.store.pipe(
      select(selectIsProductionDetailsLoading)
    );
    this.productionDetails$ = this.store.pipe(select(selectProductionDetails));
    this.photo$ = this.store.pipe(select(selectUserPhoto));
    this.showEmptyState$ = combineLatest(
      this.productionDetails$,
      this.isAppOnline$
    ).pipe(
      map(([productionDetails, isAppOnline]) => {
        return !productionDetails && !isAppOnline;
      })
    );
  }
}
