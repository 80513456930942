/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./associate-photo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../file-uploader/file-uploader.component.ngfactory";
import * as i7 from "../file-uploader/file-uploader.component";
import * as i8 from "@ngrx/store";
import * as i9 from "../../services/translation.service";
import * as i10 from "./associate-photo.component";
import * as i11 from "../../modules/modal/bottom-sheet/bottom-sheet.service";
var styles_AssociatePhotoComponent = [i0.styles];
var RenderType_AssociatePhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssociatePhotoComponent, data: {} });
export { RenderType_AssociatePhotoComponent as RenderType_AssociatePhotoComponent };
function View_AssociatePhotoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Associate Profile"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.photoSrc; _ck(_v, 0, 0, currVal_0); }); }
function View_AssociatePhotoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-svg-icon", [["class", "associate-photo__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.canAddPhoto ? _co.SvgIconNameEnum.CameraAdd : _co.SvgIconNameEnum.CameraDenied); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AssociatePhotoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fileUploaderComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "button", [["class", "associate-photo"], ["type", "button"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.photo ? _co.editPhoto($event) : _co.openUploadPrompt()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { width: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "span", [["class", "associate-photo__content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssociatePhotoComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssociatePhotoComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-file-uploader", [["class", "associate-photo-uploader visually-hidden"], ["fileType", "image/jpg,image/jpeg,image/png"]], null, [[null, "filesUploaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesUploaded" === en)) {
        var pd_0 = (_co.changePhoto($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FileUploaderComponent_0, i6.RenderType_FileUploaderComponent)), i1.ɵdid(11, 4440064, [[1, 4]], 0, i7.FileUploaderComponent, [i8.Store, i1.ChangeDetectorRef, i9.TranslationService], { fileType: [0, "fileType"], uploadedFiles: [1, "uploadedFiles"] }, { filesUploaded: "filesUploaded" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 4, 0, ((_co.width / 14) + "rem")); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.photo; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.photo; _ck(_v, 9, 0, currVal_3); var currVal_4 = "image/jpg,image/jpeg,image/png"; var currVal_5 = _co.files; _ck(_v, 11, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.canAddPhoto || (_co.canDeletePhoto && _co.photo)) ? null : true); _ck(_v, 1, 0, currVal_0); }); }
export function View_AssociatePhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-associate-photo", [], null, null, null, View_AssociatePhotoComponent_0, RenderType_AssociatePhotoComponent)), i1.ɵdid(1, 638976, null, 0, i10.AssociatePhotoComponent, [i4.DomSanitizer, i11.BottomSheetService, i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssociatePhotoComponentNgFactory = i1.ɵccf("app-associate-photo", i10.AssociatePhotoComponent, View_AssociatePhotoComponent_Host_0, { photo: "photo", canAddPhoto: "canAddPhoto", canDeletePhoto: "canDeletePhoto", width: "width" }, { deletePhotoEvent: "deletePhotoEvent", addPhotoEvent: "addPhotoEvent" }, []);
export { AssociatePhotoComponentNgFactory as AssociatePhotoComponentNgFactory };
