import { Component, OnInit } from '@angular/core';
import { SvgIconColor, SvgIconName } from '../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-resources-container',
  templateUrl: './resources-container.component.html',
  styleUrls: ['./resources-container.component.scss']
})
export class ResourcesContainerComponent implements OnInit {
  SvgIconNameEnum = SvgIconName;
  SvgIconColorEnum = SvgIconColor;

  constructor() {}

  ngOnInit() {}
}
