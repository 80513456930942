import { createAction, props } from '@ngrx/store';

import { WeekPaySummaryResponse } from '../../core/responses/wages/weekPaySummaryResponse';
import { WeekPayDetailsResponse } from '../../core/responses/wages/weekPayDetailsResponse';

export const loadPayHistory = createAction(
  '[Pay History Component] Load History'
);

export const payHistoryLoaded = createAction(
  '[Pay History Component] History Loaded',
  props<{ payHistories: WeekPaySummaryResponse[] }>()
);

export const loadPaymentWeekDetails = createAction(
  '[Pay Component] Load Details',
  props<{ weekEndingDate: string }>()
);

export const weekPaymentDetailsLoaded = createAction(
  '[Pay Component] Details Loaded',
  props<{ weekPay: WeekPayDetailsResponse }>()
);

export const togglePaymentDetailsLoading = createAction(
  '[Pay Component] Toggle Payment Details Loading',
);
