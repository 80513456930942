import { Component, Input, OnInit } from '@angular/core';
import { ChipAppearance } from '../../enums/Chip';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {
  @Input() appearance: ChipAppearance;

  public ChipAppearanceEnum = ChipAppearance;

  constructor() {}

  ngOnInit() {}
}
