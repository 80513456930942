import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Observable, Subscription } from 'rxjs';
import { first, shareReplay, withLatestFrom } from 'rxjs/operators';
import {
  IEventTelemetry,
  IExceptionTelemetry
} from '@microsoft/applicationinsights-common';
import { Store } from '@ngrx/store';
import { AppState, getIsAppOnline } from '../../reducers';
import { AppInsightsFactory } from './application-insights-factory.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService implements OnDestroy {
  private readonly routerSubscription: Subscription;
  private appInsights$ = new Observable<ApplicationInsights>();

  constructor(
    private store: Store<AppState>,
    private appInsightsFactory: AppInsightsFactory
  ) {
    this.appInsights$ = this.appInsightsFactory.create().pipe(shareReplay(1));
  }

  setUserId(userId: string) {
    this.appInsights$.pipe(first()).subscribe(appInsights => {
      appInsights.setAuthenticatedUserContext(userId);
    });
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights$
      .pipe(withLatestFrom(this.store.select(getIsAppOnline)), first())
      .subscribe(([appInsights, isAppOnline]) => {
        if (isAppOnline) {
          appInsights.trackPageView({ name, uri });
        }
      });
  }

  logCustomEvent(eventName: string, customProperties?: any) {
    this.appInsights$
      .pipe(withLatestFrom(this.store.select(getIsAppOnline)), first())
      .subscribe(([appInsights, isAppOnline]) => {
        const event: IEventTelemetry = {
          name: eventName,
          properties: customProperties
        };
        if (isAppOnline) {
          appInsights.trackEvent(event);
        }
      });
  }

  logCustomEventStart(eventName: string) {
    this.appInsights$
      .pipe(withLatestFrom(this.store.select(getIsAppOnline)), first())
      .subscribe(([appInsights, isAppOnline]) => {
        if (isAppOnline) {
          appInsights.startTrackEvent(eventName);
        }
      });
  }

  logCustomEventStop(eventName: string, customProperties?: any) {
    this.appInsights$
      .pipe(withLatestFrom(this.store.select(getIsAppOnline)), first())
      .subscribe(([appInsights, isAppOnline]) => {
        if (isAppOnline) {
          appInsights.stopTrackEvent(
            eventName,
            Object.assign({}, customProperties)
          );
        }
      });
  }

  logApplicationError(error: Error) {
    this.appInsights$
      .pipe(withLatestFrom(this.store.select(getIsAppOnline)), first())
      .subscribe(([appInsights, isAppOnline]) => {
        if (isAppOnline && environment.production) {
          const exception: IExceptionTelemetry = {
            error: new Error(JSON.stringify(error))
          };

          appInsights.trackException(exception);
        }
      });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
