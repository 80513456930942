import { Component, ContentChildren, OnInit, QueryList } from '@angular/core';
import { FooterNoteComponent } from './footer-note/footer-note.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @ContentChildren(FooterNoteComponent)
  notes: QueryList<FooterNoteComponent>;

  constructor() {
  }

  ngOnInit() {
  }
}
