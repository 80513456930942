import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { SvgIconName } from '../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit {
  @Output()
  openMenu = new EventEmitter();

  SvgIconNameEnum = SvgIconName;

  constructor() {}

  ngOnInit() {}
}
