import { Component, OnInit, Input } from '@angular/core';
import { JobDetailsCardComponent } from '../job-details-card/job-details-card.component';
import { DayEarningDetails } from '../../../core/dataEntities/wages/dayEarningDetails';
import { EarningTypes } from '../../../core/enums/EarningTypes';
import { ProductionEarning } from '../../../core/dataEntities/wages/productionEarning';
import { HourlyEarningsDetailsCardComponent } from '../hourly-earnings-details-card/hourly-earnings-details-card.component';
import { HourlyEarning } from '../../../core/dataEntities/wages/hourlyEarning';
import { ExceptionEarningsDetailsCardComponent } from '../exception-earnings-details-card/exception-earnings-details-card.component';
import { ExceptionEarning } from '../../../core/dataEntities/wages/exceptionEarning';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { BottomSheetService } from '../../../shared/modules/modal/bottom-sheet/bottom-sheet.service';
import { ServiceEarningsDetailsCardComponent } from '../service-earnings-details-card/service-earnings-details-card.component';
import { LoadTypes } from '../../../core/enums/LoadTypes';

@Component({
  selector: 'app-earnings-breakdown-table',
  templateUrl: './earnings-breakdown-table.component.html',
  styleUrls: ['./earnings-breakdown-table.component.scss']
})
export class EarningsBreakdownTableComponent implements OnInit {
  @Input() dayEarnings: DayEarningDetails;
  public columns = ['task', 'netRevenue', 'split', 'pay', 'earnings'];

  EarningTypesEnum = EarningTypes;
  LoadTypesEnum = LoadTypes;
  SvgIconNameEnum = SvgIconName;

  isCaliforniaView = false;

  constructor(private bottomSheet: BottomSheetService) {}

  ngOnInit() {
    if (this.dayEarnings !== null && this.dayEarnings !== undefined) {
      this.isCaliforniaView = this.dayEarnings.newCaliforniaPayModelUsed;
    }
  }

  openDetailsModal(
    detailsType: EarningTypes,
    details: any,
    isCaliforniaView: boolean
  ) {
    switch (detailsType) {
      case EarningTypes.HourlyEarning: {
        this.openHourlyEarningsDetailsModal(details);
        break;
      }
      case EarningTypes.ProductionEarning: {
        if (details.loadType === LoadTypes.Service) {
          this.openServiceEarningsDetailsModal(details);
        } else {
          this.openJobDetailsModal(details, isCaliforniaView);
        }
        break;
      }
      case EarningTypes.ExceptionEarning: {
        this.openExceptionEarningsDetailsModal(details);
        break;
      }
    }
  }

  openJobDetailsModal(
    taskDetails: ProductionEarning,
    isCaliforniaView: boolean
  ): void {
    this.bottomSheet.open(JobDetailsCardComponent, {
      data: { taskDetails, isCaliforniaView }
    });
  }

  openHourlyEarningsDetailsModal(taskDetails: HourlyEarning): void {
    this.bottomSheet.open(HourlyEarningsDetailsCardComponent, {
      data: { taskDetails }
    });
  }

  openExceptionEarningsDetailsModal(taskDetails: ExceptionEarning): void {
    this.bottomSheet.open(ExceptionEarningsDetailsCardComponent, {
      data: { taskDetails }
    });
  }

  openServiceEarningsDetailsModal(taskDetails: ExceptionEarning): void {
    this.bottomSheet.open(ServiceEarningsDetailsCardComponent, {
      data: { taskDetails }
    });
  }
}
