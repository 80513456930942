import { Component } from '@angular/core';
import { DialogRef } from '../../../shared/modules/modal/dialog-ref';

@Component({
  selector: 'app-about-check-in',
  templateUrl: './about-check-in.component.html',
  styleUrls: ['./about-check-in.component.scss']
})
export class AboutCheckInComponent {
  constructor(private modalRef: DialogRef<AboutCheckInComponent>) {}

  closeModal() {
    this.modalRef.close({ success: true });
  }
}
