<app-filter-modal-layout>
  <ng-container slot="modal-header" i18n="@@region-filter_header">
    Filter By Region
  </ng-container>
  <ng-container slot="modal-content" class="region-filter">
    <div *ngIf="data?.regions && data?.regions.length">
      <button
        *ngIf="
          selectedRegions.length &&
          selectedRegions?.length === availableFilters.length
        "
        class="region-filter__select-button"
        (click)="deselectAll()"
        i18n="@@region-filter_deselectAll"
        cdkMonitorElementFocus
      >
        Deselect All
      </button>
      <button
        *ngIf="
          !selectedRegions.length ||
          selectedRegions?.length < availableFilters.length
        "
        class="region-filter__select-button"
        (click)="selectAll()"
        i18n="@@region-filter_selectAll"
        cdkMonitorElementFocus
      >
        Select All
      </button>
      <div *ngFor="let region of data?.regions">
        <app-checkbox-input
          [disabled]="region?.count === 0"
          [align]="CheckBoxInputAlignEnum.Top"
          [ngModel]="arrayHasValue(selectedRegions, region?.value)"
          (ngModelChange)="selectDuration(region?.value)"
        >
          <div>
            <div>{{ region?.label }} ({{ region?.count }})</div>
            <div class="region-filter__states">
              {{ region?.statesLabels | join: ', ' }}
            </div>
          </div>
        </app-checkbox-input>
      </div>
    </div>

    <ng-template #emptyStateMessage i18n="@@region-filter_noDataFound">
      There are no filter options.
    </ng-template>

    <app-empty-state
      *ngIf="data?.regions === null || data?.regions.length === 0"
      [emptyStateTemplate]="emptyStateMessage"
      [emptyStateIcon]="SvgIconNameEnum.EmptyStateApplications"
    ></app-empty-state>
  </ng-container>

  <ng-container slot="modal-action">
    <app-button-link
      (buttonClick)="resetFilter()"
      label="Reset Filter"
      i18n-label="@@region-filter_resetFilter"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>

    <app-button-link
      (buttonClick)="filter()"
      label="Done"
      i18n-label="@@region-filter_done"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>
  </ng-container>
</app-filter-modal-layout>
