<section class="associate-page-header">
  <app-associate-photo
    [photo]="photoSrc"
    [canAddPhoto]="isAssociateView"
    [canDeletePhoto]="true"
    (deletePhotoEvent)="deletePhoto($event)"
    width="96"
  ></app-associate-photo>
  <div class="associate-page-header__side">
    <h1 class="associate-page-header__user-name">{{ userName }}</h1>
    <div class="associate-page-header__info">
      <app-associate-qr-code
        *ngIf="isAssociateView"
        [apexId]="apexId"
        class="associate-page-header__qr"
      ></app-associate-qr-code>
      <div class="associate-page-header__user-details">
        <span data-cy="apexId">ID# {{ apexId }}</span>
        <app-chip
          *ngIf="isTrainee"
          [appearance]="ChipAppearanceEnum.Green"
          i18n="@@trainee"
        >
          Trainee
        </app-chip>
      </div>
    </div>
  </div>
</section>
