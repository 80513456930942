/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./post-image.component";
var styles_PostImageComponent = [i0.styles];
var RenderType_PostImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostImageComponent, data: {} });
export { RenderType_PostImageComponent as RenderType_PostImageComponent };
function View_PostImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "post-image__src"]], [[1, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.title, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PostImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "post-safety-tip": 0, "post-safety-tip--equipment": 1, "post-safety-tip--environment": 2, "post-safety-tip--manual-labor": 3, "post-safety-tip--general-safety": 4 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "post-safety-tip__heading"]], [[1, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, (_co.subtype === _co.PostSafetyTipTypeEnum.Equipment), (_co.subtype === _co.PostSafetyTipTypeEnum.Environment), (_co.subtype === _co.PostSafetyTipTypeEnum.ManualLabor), (_co.subtype === _co.PostSafetyTipTypeEnum.GeneralSafety)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ("feed-card-heading-" + _co.postId); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.title; _ck(_v, 5, 0, currVal_2); }); }
export function View_PostImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "post-image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostImageComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostImageComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.type === _co.PostCategoryTypeEnum.SafetyTip); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PostImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-image", [], null, null, null, View_PostImageComponent_0, RenderType_PostImageComponent)), i1.ɵdid(1, 114688, null, 0, i3.PostImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostImageComponentNgFactory = i1.ɵccf("app-post-image", i3.PostImageComponent, View_PostImageComponent_Host_0, { image: "image", title: "title", type: "type", subtype: "subtype", postId: "postId" }, {}, []);
export { PostImageComponentNgFactory as PostImageComponentNgFactory };
