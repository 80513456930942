import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { MsalModule } from '@azure/msal-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { reducers, metaReducers } from './reducers';
import { SharedComponentsModule } from './shared/components/shared-components/shared-components.module';
import { ApplicationInsightsService } from './shared/services/application-insights.service';
import { TranslationService } from './shared/services/translation.service';
import { WagesModule } from './wages/wages.module';
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { LayoutComponent } from './core/components/layout.component';
import { MenuBarComponent } from './core/components/menu-bar/menu-bar.component';
import { ApiGatewayService } from './shared/services/api.gateway.service';
import { NotificationBarComponent } from './core/components/notification-bar/notification-bar.component';
import { UserEffects } from './core/effects/user.effects';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { RefreshTokenInterceptor } from './shared/http-interceptors/refresh-token-interceptor';
import { AuthenticationService } from './shared/services/authentication.service';
import { SurveyModule } from './surveys/survey.module';
import { NewsModule } from './news/news.module';
import { ResourcesModule } from './resources/resources.module';
import { ProfileModule } from './profile/profile.module';
import { AppInsightsFactory } from './shared/services/application-insights-factory.service';
import { SidebarModule } from 'ng-sidebar';
import { GlobalErrorHandler } from './core/handlers/global-error.handler';
import { PipesModule } from './shared/pipes/pipes.module';
import {
  APP_ERROR_MESSAGES,
  errorMessagesFactory,
  errorMessagesProvider
} from './shared/error-handlers/catch-app-error';
import { LayoutEffects } from './core/effects/layout.effects';
import { AnalyticsEffects } from './core/effects/analytics.effects';
import { NavigationBarComponent } from './core/components/navigation-bar/navigation-bar.component';
import { NavigationLinkComponent } from './core/components/navigation-bar/navigation-link/navigation-link.component';
import { ContentDeliveryService } from './shared/services/content-delivery.service';
import { ContentfulClientFactory } from './shared/services/contentful-client-factory.service';
import { AuthorizationService } from './shared/services/authorization.service';
import { ApplicationTitleService } from './shared/services/application-title.service';
import { UpdateService } from './shared/services/update.service';
import { UpdatePromptService } from './shared/services/update-prompt.service';
import { NgxHotjarModule } from 'ngx-hotjar';
import { IsOnlineInterceptor } from './shared/http-interceptors/is-online-interceptor';
import { AnonymousGatewayService } from './shared/services/anonymous.gateway.service';
import {
  navigationFeatureKey,
  reducer
} from './core/reducers/navigation.reducer';
import { NavigationEffects } from './core/effects/navigation.effects';
import { SiteMapService } from './shared/services/site-map.service';
import { APP_SITE_MAP, AppSiteMap } from './core/siteMap/SiteMapTree';
import { MyTeamModule } from './my-team/my-team.module';
import { ApplicationUpdateComponent } from './core/components/application-update/application-update.component';
import { AlertService } from './shared/services/alert.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { DynamicContentModule } from './dynamic-content/dynamic-content.module';
import { StaticContentModule } from './static-content/static-content.module';
import { DirectivesModule } from './core/directives/directives.module';
import { OverlayLoaderService } from './shared/services/overlay-loader.service';
import { TabsModule } from './shared/components/tabs/tabs.module';
import { StaticContentService } from './core/contracts/StaticContentService';
import { A11yModule } from '@angular/cdk/a11y';
import { StaticContentApiService } from './shared/services/static-content.service';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    LayoutComponent,
    MenuBarComponent,
    NotificationBarComponent,
    NavigationBarComponent,
    NavigationLinkComponent,
    ApplicationUpdateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedComponentsModule,
    PipesModule,
    DirectivesModule,
    SidebarModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([UserEffects, LayoutEffects, AnalyticsEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forFeature([NavigationEffects]),
    StoreModule.forFeature(navigationFeatureKey, reducer),
    MsalModule.forRoot(environment.azureADTenantConfig),
    WagesModule,
    SurveyModule,
    NewsModule,
    ResourcesModule,
    ProfileModule,
    MyTeamModule,
    DynamicContentModule,
    StaticContentModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    NgxLocalStorageModule.forRoot(),
    AppRoutingModule,
    environment.hotjar.siteId
      ? NgxHotjarModule.forRoot(environment.hotjar.siteId)
      : [],
    TabsModule,
    A11yModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: LOCALE_ID,
      useValue: 'en'
    },
    {
      provide: APP_ERROR_MESSAGES,
      useFactory: errorMessagesFactory
    },
    TranslationService,
    ContentfulClientFactory,
    ContentDeliveryService,
    AppInsightsFactory,
    ApplicationInsightsService,
    ApplicationTitleService,
    ApiGatewayService,
    AnonymousGatewayService,
    AuthorizationService,
    AuthenticationService,
    UpdateService,
    UpdatePromptService,
    AlertService,
    AnalyticsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IsOnlineInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    errorMessagesProvider,
    Title,
    {
      provide: APP_SITE_MAP,
      useValue: AppSiteMap
    },
    SiteMapService,
    OverlayLoaderService,
    {
      provide: StaticContentService,
      useClass: StaticContentApiService
    }
  ],
  entryComponents: [ApplicationUpdateComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
