import {
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Output,
  QueryList
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { TabGroupService } from '../services/tab-group.service';

/** Used to generate unique ID's for each tab component */
let nextId = 0;

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  providers: [TabGroupService]
})
export class TabGroupComponent {
  @ContentChildren(
    forwardRef(() => TabComponent),
    { descendants: true }
  )
  tabs: QueryList<TabComponent>;

  groupId: number;
  @Output() tabChange = new EventEmitter();

  constructor(private tabGroupService: TabGroupService) {
    this.groupId = nextId++;
  }

  get selectedTabIndex() {
    return this.selectedTab
      ? this.tabs.toArray().indexOf(this.selectedTab)
      : -1;
  }

  get selectedTab() {
    return this.tabGroupService.getActiveTab();
  }

  setActiveTab(tabIndex) {
    const activeTab = this.tabs.find((item, idx) => idx === tabIndex);
    this.tabGroupService.setActiveTab(activeTab);
    this.tabChange.emit(activeTab);
  }

  getTabLabelId(i: number): string {
    return `tab-label-${this.groupId}-${i}`;
  }

  getTabContentId(i: number): string {
    return `tab-content-${this.groupId}-${i}`;
  }

  getTabIndex(idx: number): number | null {
    return this.selectedTabIndex === idx ? 0 : -1;
  }
}
