import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appTabLabelWrapper]'
})
export class TabLabelWrapperDirective {

  constructor(private elementRef: ElementRef) {}

  focus(): void {
    this.elementRef.nativeElement.focus();
  }
}
