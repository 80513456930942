<svg class="svg-source">
  <defs>
    <symbol viewBox="0 0 8 8" id="timeline-check" fill="none">
      <path
        transform="translate(0,1)"
        d="M7 1L2.77778 5L1 3"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </symbol>
  </defs>
</svg>
<ul class="timeline" aria-hidden="true">
  <li
    [ngClass]="{
      timeline__item: true,
      'timeline__item--active':
        trainingStatus === TrainingStatusEnum.WeekOneTraining
    }"
  >
    <div class="timeline__dot">
      <svg class="timeline__icon">
        <use xlink:href="#timeline-check"></use>
      </svg>
    </div>
  </li>
  <li
    [ngClass]="{
      timeline__item: true,
      'timeline__item--active':
        trainingStatus === TrainingStatusEnum.WeekTwoTraining
    }"
  >
    <div class="timeline__dot">
      <svg class="timeline__icon">
        <use xlink:href="#timeline-check"></use>
      </svg>
    </div>
  </li>
  <li
    [ngClass]="{
      timeline__item: true,
      'timeline__item--active':
        trainingStatus === TrainingStatusEnum.WeekThreeTraining
    }"
  >
    <div class="timeline__dot">
      <svg class="timeline__icon">
        <use xlink:href="#timeline-check"></use>
      </svg>
    </div>
  </li>
  <li
    [ngClass]="{
      timeline__item: true,
      'timeline__item--active':
        trainingStatus === TrainingStatusEnum.WeekFourTraining
    }"
  >
    <div class="timeline__dot">
      <svg class="timeline__icon">
        <use xlink:href="#timeline-check"></use>
      </svg>
    </div>
  </li>
  <li
    [ngClass]="{
      timeline__item: true,
      'timeline__item--active':
        trainingStatus === TrainingStatusEnum.WeekFiveTraining
    }"
  >
    <div class="timeline__dot">
      <svg class="timeline__icon">
        <use xlink:href="#timeline-check"></use>
      </svg>
    </div>
  </li>
  <li
    [ngClass]="{
      timeline__item: true,
      'timeline__item--active':
        trainingStatus === TrainingStatusEnum.WeekSixTraining
    }"
  >
    <div class="timeline__dot">
      <svg class="timeline__icon">
        <use xlink:href="#timeline-check"></use>
      </svg>
    </div>
  </li>
</ul>
<div class="card">
  <ng-content></ng-content>
</div>
