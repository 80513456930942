<app-card-layout>
  <app-card-layout-item>
    <app-page-section-header
      title="Shift details"
      i18n-title="@@shift-details-card_shiftDetails"
    ></app-page-section-header>
    <div class="details-item">
      <app-svg-icon
        [name]="SvgIconNameEnum.Calendar"
        class="details-item__icon details-item__icon--top"
      ></app-svg-icon>
      <div class="details-item__content">
        <span class="details-item__text details-item__text--capitalize">
          {{ startDate | date: 'EEEE, MMMM d' }}
        </span>
        <span class="details-item__text" i18n="@@shift-details-card_hours">
          {{ startDate | date: 'hh:mm aa' }} -
          {{ endDate | date: 'hh:mm aa' }} ({{ durationInHours }} hours)
        </span>
      </div>
    </div>
    <div class="details-item">
      <app-svg-icon
        [name]="SvgIconNameEnum.Location"
        class="details-item__icon"
      ></app-svg-icon>
      <span class="details-item__text">
        {{ shiftDetails.siteInfo?.name }}
      </span>
    </div>
    <div class="details-item">
      <app-svg-icon
        [name]="SvgIconNameEnum.Person"
        class="details-item__icon"
      ></app-svg-icon>
      <span
        class="details-item__text"
        i18n="@@shift-details-card_associatesNeeded"
      >
        {{ shiftDetails.associatesNeeded }} Associates Needed
      </span>
    </div>
  </app-card-layout-item>
  <app-card-layout-item>
    <app-page-section-header
      *ngIf="shiftDetails.manager.title === 'Site Manager'"
      title="Contact shift manager"
      i18n-title="@@shift-details-card_contactShiftManager"
    ></app-page-section-header>
    <app-page-section-header
      *ngIf="shiftDetails.manager.title === 'Site Supervisor'"
      title="Contact shift supervisor"
      i18n-title="@@shift-details-card_contactShiftSupervisor"
    ></app-page-section-header>
    <p class="manager__name">
      {{ shiftDetails.manager.firstName }}
      {{ shiftDetails.manager.lastName }}
      <span *ngIf="shiftDetails.authorRole" class="manager__title">
        {{ shiftDetails.authorRole }}
      </span>
    </p>

    <ng-container *ngIf="preview; else detailsTemplate">
      <div class="details-item">
        <app-svg-icon
          [name]="SvgIconNameEnum.Phone"
          class="details-item__icon"
        ></app-svg-icon>
        <a class="details-item__link">
          {{ shiftDetails.phoneNumber | mask: '(000) 000 - 0000' }}
        </a>
      </div>
    </ng-container>
    <ng-template #detailsTemplate>
      <div
        *ngIf="
          shiftDetails.contactType === ShiftContactTypesEnum.Both ||
          shiftDetails.contactType === ShiftContactTypesEnum.Call
        "
        class="details-item"
      >
        <app-svg-icon
          [name]="SvgIconNameEnum.Phone"
          class="details-item__icon"
        ></app-svg-icon>
        <a
          class="details-item__link"
          [attr.href]="'tel:' + shiftDetails.phoneNumber"
        >
          {{ shiftDetails.phoneNumber | mask: '(000) 000 - 0000' }}
        </a>
      </div>
      <div class="bottom">
        <app-button-link
          *ngIf="
            shiftDetails.contactType === ShiftContactTypesEnum.Both ||
            shiftDetails.contactType === ShiftContactTypesEnum.Text
          "
          [label]="'Text ' + shiftDetails.manager.firstName"
          (buttonClick)="openSMSMobile()"
          [iconName]="SvgIconNameEnum.MessageBubble"
        ></app-button-link>
        <p
          class="bottom__text"
          i18n="@@shift-details-card_footerYouMustReceiveVerbalApproval"
        >
          You must receive verbal approval from your shift manager in order to
          be scheduled for this shift.
        </p>
      </div>
    </ng-template>
  </app-card-layout-item>
</app-card-layout>
