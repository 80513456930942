<span class="accordion-header__title">
  <ng-template #defaultTitleTemplate>
    {{ title }}
  </ng-template>
  <ng-container
    *ngTemplateOutlet="titleTemplate ? titleTemplate : defaultTitleTemplate"
  ></ng-container>
</span>
<span class="accordion-header__subtitle">
  <ng-template #defaultSubTitleTemplate>
    {{ subtitle }}
  </ng-template>
  <ng-container
    *ngTemplateOutlet="
      subtitleTemplate ? subtitleTemplate : defaultSubTitleTemplate
    "
  ></ng-container>
</span>
