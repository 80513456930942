/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/router";
import * as i5 from "./menu-button.component";
var styles_MenuButtonComponent = [i0.styles];
var RenderType_MenuButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuButtonComponent, data: {} });
export { RenderType_MenuButtonComponent as RenderType_MenuButtonComponent };
function View_MenuButtonComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_MenuButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MenuButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["cdkMonitorElementFocus", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "menu-button": 0, "menu-button--top-rounded-border": 1, "menu-button--bottom-rounded-border": 2 }), i1.ɵdid(4, 147456, null, 0, i3.CdkMonitorFocus, [i1.ElementRef, i3.FocusMonitor], null, null), i1.ɵdid(5, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_3)), i1.ɵdid(7, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, true, _co.topRoundedBorder, _co.bottomRoundedBorder); _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.queryParams; var currVal_4 = _co.link; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v.parent, 0); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MenuButtonComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MenuButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["cdkMonitorElementFocus", ""]], [[8, "href", 4]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "menu-button": 0, "menu-button--top-rounded-border": 1, "menu-button--bottom-rounded-border": 2 }), i1.ɵdid(4, 147456, null, 0, i3.CdkMonitorFocus, [i1.ElementRef, i3.FocusMonitor], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_5)), i1.ɵdid(6, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, true, _co.topRoundedBorder, _co.bottomRoundedBorder); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent, 0); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 0, 0, currVal_0); }); }
function View_MenuButtonComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MenuButtonComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["cdkMonitorElementFocus", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickButton($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "menu-button": 0, "menu-button--top-rounded-border": 1, "menu-button--bottom-rounded-border": 2 }), i1.ɵdid(4, 147456, null, 0, i3.CdkMonitorFocus, [i1.ElementRef, i3.FocusMonitor], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_7)), i1.ɵdid(6, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, _co.topRoundedBorder, _co.bottomRoundedBorder); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v.parent, 0); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_MenuButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_MenuButtonComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.link && !_co.isExternalUrl); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.link && _co.isExternalUrl); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.link; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_MenuButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-button", [], null, null, null, View_MenuButtonComponent_0, RenderType_MenuButtonComponent)), i1.ɵdid(1, 49152, null, 0, i5.MenuButtonComponent, [], null, null)], null, null); }
var MenuButtonComponentNgFactory = i1.ɵccf("app-menu-button", i5.MenuButtonComponent, View_MenuButtonComponent_Host_0, { topRoundedBorder: "topRoundedBorder", bottomRoundedBorder: "bottomRoundedBorder", isExternalUrl: "isExternalUrl", link: "link", queryParams: "queryParams" }, { buttonClick: "buttonClick" }, ["*"]);
export { MenuButtonComponentNgFactory as MenuButtonComponentNgFactory };
