import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LayoutActions, RouterActions } from '../actions';
import { map, switchMap, tap, withLatestFrom, filter } from 'rxjs/operators';
import {
  AppState,
  getHideNavigationBar,
  getShowMenuBar,
  RouteSelectors,
  getAppHasNotification,
  getPersistRouteChangeCount,
  getIsAppLoading
} from '../../reducers';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { DialogRef } from '../../shared/modules/modal/dialog-ref';
import { ApplicationUpdateComponent } from '../components/application-update/application-update.component';
import { AlertService } from '../../shared/services/alert.service';
import { OverlayLoaderService } from '../../shared/services/overlay-loader.service';

@Injectable()
export class LayoutEffects {
  dialogRef: DialogRef<ApplicationUpdateComponent>;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private alertService: AlertService,
    private overlayLoaderService: OverlayLoaderService
  ) {}

  showMenuBar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routerNavigated),
      withLatestFrom(
        this.store.select(RouteSelectors.selectRouteData),
        this.store.select(getShowMenuBar),
        this.store.select(RouteSelectors.selectCurrentRoute)
      ),
      switchMap(([action, routeData, showMenuBar]) => {
        const shouldShowMenuBarForRoute = !!(
          routeData && routeData.showMenuBar
        );
        if (showMenuBar === shouldShowMenuBarForRoute) {
          return EMPTY;
        }
        return of(
          LayoutActions.toggleMenuBar({
            show: shouldShowMenuBarForRoute
          })
        );
      })
    )
  );

  hideNavigationBar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routerNavigated),
      withLatestFrom(
        this.store.select(RouteSelectors.selectRouteData),
        this.store.select(getHideNavigationBar)
      ),
      switchMap(([action, routeData, hideNavigationBar]) => {
        const shouldHideNavigationBarForRoute = !!(
          routeData && routeData.hideNavigationBar
        );
        if (hideNavigationBar === shouldHideNavigationBarForRoute) {
          return EMPTY;
        }
        return of(
          LayoutActions.toggleNavigationBar({
            hide: shouldHideNavigationBarForRoute
          })
        );
      })
    )
  );

  showAlertUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.showAlertUpdate),
        tap(() => {
          this.dialogRef = this.alertService.open(ApplicationUpdateComponent);
        })
      ),
    { dispatch: false }
  );

  cleanLayoutMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routerNavigated),
      withLatestFrom(
        this.store.select(getAppHasNotification),
        this.store.select(getPersistRouteChangeCount)
      ),
      filter(([action, hasNotification]) => hasNotification === true),
      map(([action, hasNotification, persistCount]) => {
        if (persistCount > 0) {
          return LayoutActions.reducePersistRouteChangeCount();
        }
        return LayoutActions.cleanLayoutMessages();
      })
    )
  );

  handleOverlayLoader = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.toggleAppLoading),
      withLatestFrom(this.store.select(getIsAppLoading)),
      switchMap(([action, isLoading]) => {
        isLoading
          ? this.overlayLoaderService.open()
          : this.overlayLoaderService.close();
        return EMPTY;
      })
    )
  );
}
