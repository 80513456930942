/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./empty-state.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./empty-state.component";
import * as i7 from "../../services/translation.service";
var styles_EmptyStateComponent = [i0.styles];
var RenderType_EmptyStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmptyStateComponent, data: {} });
export { RenderType_EmptyStateComponent as RenderType_EmptyStateComponent };
function View_EmptyStateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emptyStateMessage; _ck(_v, 0, 0, currVal_0); }); }
function View_EmptyStateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_EmptyStateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "empty-state"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-svg-icon", [["class", "empty-state__icon"], ["description", "Empty state document"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"], description: [1, "description"] }, null), (_l()(), i1.ɵand(0, [["defaultStateTemplate", 2]], null, 0, null, View_EmptyStateComponent_1)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "empty-state__message"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptyStateComponent_2)), i1.ɵdid(6, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emptyStateIcon; var currVal_1 = "Empty state document"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.emptyStateTemplate ? _co.emptyStateTemplate : i1.ɵnov(_v, 3)); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_EmptyStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-state", [], null, null, null, View_EmptyStateComponent_0, RenderType_EmptyStateComponent)), i1.ɵdid(1, 114688, null, 0, i6.EmptyStateComponent, [i7.TranslationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmptyStateComponentNgFactory = i1.ɵccf("app-empty-state", i6.EmptyStateComponent, View_EmptyStateComponent_Host_0, { emptyStateMessageKey: "emptyStateMessageKey", emptyStateTemplate: "emptyStateTemplate", emptyStateIcon: "emptyStateIcon" }, {}, []);
export { EmptyStateComponentNgFactory as EmptyStateComponentNgFactory };
