import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tab-bar-link',
  templateUrl: './tab-bar-link.component.html',
  styleUrls: ['./tab-bar-link.component.scss']
})
export class TabBarLinkComponent implements OnInit {
  @Input() link: string;
  @Input() indicator = false;

  constructor() {}

  ngOnInit() {}
}
