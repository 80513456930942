/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./job-title-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/form/checkbox-input/checkbox-input.component.ngfactory";
import * as i3 from "../../../../../shared/components/form/form-field/form-field.control";
import * as i4 from "../../../../../shared/components/form/checkbox-input/checkbox-input.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../../shared/components/form/form-field/form-field.component";
import * as i7 from "../../../../../shared/pipes/translate.pipe";
import * as i8 from "../../../../../shared/services/translation.service";
import * as i9 from "@angular/common";
import * as i10 from "../../../../../shared/components/empty-state/empty-state.component.ngfactory";
import * as i11 from "../../../../../shared/components/empty-state/empty-state.component";
import * as i12 from "../filter-modal-layout/filter-modal-layout.component.ngfactory";
import * as i13 from "../filter-modal-layout/filter-modal-layout.component";
import * as i14 from "../../../../../shared/components/button-link/button-link.component.ngfactory";
import * as i15 from "../../../../../shared/components/button-link/button-link.component";
import * as i16 from "../../../../../shared/services/analytics-utilities.service";
import * as i17 from "./job-title-filter.component";
import * as i18 from "../../../../../shared/modules/modal/dialog-ref";
import * as i19 from "../../../../../shared/modules/modal/dialog-config";
var styles_JobTitleFilterComponent = [i0.styles];
var RenderType_JobTitleFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JobTitleFilterComponent, data: {} });
export { RenderType_JobTitleFilterComponent as RenderType_JobTitleFilterComponent };
function View_JobTitleFilterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "job-title-filter__select-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deselectAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Deselect All "]))], null, null); }
function View_JobTitleFilterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "job-title-filter__select-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Select All "]))], null, null); }
function View_JobTitleFilterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "app-checkbox-input", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = (_co.selectJobTitle(((_v.context.$implicit == null) ? null : _v.context.$implicit.jobTitle)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CheckboxInputComponent_0, i2.RenderType_CheckboxInputComponent)), i1.ɵprd(6144, null, i3.FormFieldControl, null, [i4.CheckboxInputComponent]), i1.ɵdid(3, 114688, null, 0, i4.CheckboxInputComponent, [i5.FormBuilder, [2, i6.FORM_FIELD]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.CheckboxInputComponent]), i1.ɵdid(5, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(7, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵted(8, 0, [" ", " (", ") "])), i1.ɵpid(0, i7.TranslatePipe, [i8.TranslationService])], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_7 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.count) === 0); var currVal_8 = _co.arrayHasValue(_co.selectedJobTitles, ((_v.context.$implicit == null) ? null : _v.context.$implicit.jobTitle)); _ck(_v, 5, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_v.context.$implicit.jobTitle)); var currVal_10 = _v.context.$implicit.count; _ck(_v, 8, 0, currVal_9, currVal_10); }); }
function View_JobTitleFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobTitleFilterComponent_2)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobTitleFilterComponent_3)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobTitleFilterComponent_4)), i1.ɵdid(6, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedJobTitles.length && (((_co.selectedJobTitles == null) ? null : _co.selectedJobTitles.length) === _co.availableFilters.length)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.selectedJobTitles.length || (((_co.selectedJobTitles == null) ? null : _co.selectedJobTitles.length) < _co.availableFilters.length)); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.data.jobTitles; _ck(_v, 6, 0, currVal_2); }, null); }
function View_JobTitleFilterComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" There are no filter options. "]))], null, null); }
function View_JobTitleFilterComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-state", [], null, null, null, i10.View_EmptyStateComponent_0, i10.RenderType_EmptyStateComponent)), i1.ɵdid(1, 114688, null, 0, i11.EmptyStateComponent, [i8.TranslationService], { emptyStateTemplate: [0, "emptyStateTemplate"], emptyStateIcon: [1, "emptyStateIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v.parent, 7); var currVal_1 = _co.SvgIconNameEnum.EmptyStateApplications; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_JobTitleFilterComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "app-filter-modal-layout", [], null, null, null, i12.View_FilterModalLayoutComponent_0, i12.RenderType_FilterModalLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i13.FilterModalLayoutComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Filter By Job Title "])), (_l()(), i1.ɵeld(4, 0, null, 1, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobTitleFilterComponent_1)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["emptyStateMessage", 2]], null, 0, null, View_JobTitleFilterComponent_5)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JobTitleFilterComponent_6)), i1.ɵdid(9, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, 2, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-button-link", [["label", "Reset Filter"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.resetFilter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_ButtonLinkComponent_0, i14.RenderType_ButtonLinkComponent)), i1.ɵdid(12, 638976, null, 0, i15.ButtonLinkComponent, [i16.AnalyticsUtilitiesService], { appearance: [0, "appearance"], label: [1, "label"] }, { buttonClick: "buttonClick" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-button-link", [["label", "Done"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.filter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_ButtonLinkComponent_0, i14.RenderType_ButtonLinkComponent)), i1.ɵdid(14, 638976, null, 0, i15.ButtonLinkComponent, [i16.AnalyticsUtilitiesService], { appearance: [0, "appearance"], label: [1, "label"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (((_co.data == null) ? null : _co.data.jobTitles) && ((_co.data == null) ? null : _co.data.jobTitles.length)); _ck(_v, 6, 0, currVal_0); var currVal_1 = ((((_co.data == null) ? null : _co.data.jobTitles) === null) || (((_co.data == null) ? null : _co.data.jobTitles.length) === 0)); _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.ButtonAppearanceEnum.Link; var currVal_3 = "Reset Filter"; _ck(_v, 12, 0, currVal_2, currVal_3); var currVal_4 = _co.ButtonAppearanceEnum.Link; var currVal_5 = "Done"; _ck(_v, 14, 0, currVal_4, currVal_5); }, null); }
export function View_JobTitleFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-job-title-filter", [], null, null, null, View_JobTitleFilterComponent_0, RenderType_JobTitleFilterComponent)), i1.ɵdid(1, 114688, null, 0, i17.JobTitleFilterComponent, [i18.DialogRef, i19.DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JobTitleFilterComponentNgFactory = i1.ɵccf("app-job-title-filter", i17.JobTitleFilterComponent, View_JobTitleFilterComponent_Host_0, {}, {}, []);
export { JobTitleFilterComponentNgFactory as JobTitleFilterComponentNgFactory };
