import { first, switchMap } from 'rxjs/operators';
import isEmpty from 'lodash/fp/isEmpty';
import { getAuthToken } from '../../reducers';
import { SortDirections } from '../../core/enums/SortDirections';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/api.gateway.service";
import * as i2 from "@ngrx/store";
export class TeamService {
    constructor(apiGateway, store) {
        this.apiGateway = apiGateway;
        this.store = store;
    }
    loadSiteTeam(site, query, sort, page) {
        const params = {
            pageSize: page.pageSize,
            currentPage: page.pageIndex + 1,
            sortBy: sort.active,
            sortAsc: sort.direction === SortDirections.Asc
        };
        if (query) {
            params.searchBy = query;
        }
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway
                .get(`managers/team/${site}`, authToken, params)
                .map((response) => {
                if (isEmpty(response)) {
                    return null;
                }
                return response;
            });
        }));
    }
}
TeamService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TeamService_Factory() { return new TeamService(i0.ɵɵinject(i1.ApiGatewayService), i0.ɵɵinject(i2.Store)); }, token: TeamService, providedIn: "root" });
