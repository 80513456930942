import { DateTimeUtils } from '../../../../shared/utils/DateTimeUtils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class GoalEditGuard {
    constructor(router) {
        this.router = router;
    }
    canActivate(route) {
        const currentWeekEnd = DateTimeUtils.getCurrentWeekEndDate();
        if (currentWeekEnd === route.params.week) {
            return true;
        }
        this.router.navigate(['goal/breakdown', route.params.week]);
        return false;
    }
}
GoalEditGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoalEditGuard_Factory() { return new GoalEditGuard(i0.ɵɵinject(i1.Router)); }, token: GoalEditGuard, providedIn: "root" });
