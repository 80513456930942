import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { CustomEvents } from '../../../shared/enums/CustomEvents';
import { ApplicationInsightsService } from '../../../shared/services/application-insights.service';
import { DialogRef } from '../../../shared/modules/modal/dialog-ref';
import { DIALOG_DATA } from '../../../shared/modules/modal/dialog-config';

@Component({
  selector: 'app-exception-earnings-details-card',
  templateUrl: './exception-earnings-details-card.component.html'
})
export class ExceptionEarningsDetailsCardComponent
  implements OnInit, OnDestroy {
  public columns = ['exceptionEarningsType', 'earnings'];
  taskDetails;
  tableData;

  constructor(
    private modalRef: DialogRef<ExceptionEarningsDetailsCardComponent>,
    private appInsights: ApplicationInsightsService,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.taskDetails = this.data.taskDetails;
    this.tableData = {
      cellData: this.data.taskDetails.breakdown,
      footerData: this.taskDetails.earnings
    };
    this.appInsights.logCustomEventStart(
      CustomEvents.ExceptionEarningsModalOpen
    );
  }

  ngOnDestroy() {
    this.appInsights.logCustomEventStop(
      CustomEvents.ExceptionEarningsModalOpen,
      this.taskDetails
    );
  }

  closeModal() {
    this.modalRef.close({ success: true });
  }
}
