import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabComponent } from '../tab/tab.component';

@Injectable()
export class TabGroupService {
  private activeTabSubject = new BehaviorSubject<TabComponent>(null);

  activeTab$ = this.activeTabSubject.asObservable();

  getActiveTab(): TabComponent {
    return this.activeTabSubject.value;
  }

  setActiveTab(step: TabComponent) {
    this.activeTabSubject.next(step);
  }
}
