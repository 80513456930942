<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<a
  *ngIf="link && !isExternalUrl"
  [routerLink]="link"
  [queryParams]="queryParams"
  [ngClass]="{
    'menu-button': true,
    'menu-button--top-rounded-border': topRoundedBorder,
    'menu-button--bottom-rounded-border': bottomRoundedBorder
  }"
  cdkMonitorElementFocus
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a
  *ngIf="link && isExternalUrl"
  [href]="link"
  [ngClass]="{
    'menu-button': true,
    'menu-button--top-rounded-border': topRoundedBorder,
    'menu-button--bottom-rounded-border': bottomRoundedBorder
  }"
  cdkMonitorElementFocus
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<button
  *ngIf="!link"
  type="button"
  (click)="onClickButton($event)"
  [ngClass]="{
    'menu-button': true,
    'menu-button--top-rounded-border': topRoundedBorder,
    'menu-button--bottom-rounded-border': bottomRoundedBorder
  }"
  cdkMonitorElementFocus
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
