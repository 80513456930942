import { OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { first, shareReplay, withLatestFrom } from 'rxjs/operators';
import { getIsAppOnline } from '../../reducers';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./application-insights-factory.service";
export class ApplicationInsightsService {
    constructor(store, appInsightsFactory) {
        this.store = store;
        this.appInsightsFactory = appInsightsFactory;
        this.appInsights$ = new Observable();
        this.appInsights$ = this.appInsightsFactory.create().pipe(shareReplay(1));
    }
    setUserId(userId) {
        this.appInsights$.pipe(first()).subscribe(appInsights => {
            appInsights.setAuthenticatedUserContext(userId);
        });
    }
    logPageView(name, uri) {
        this.appInsights$
            .pipe(withLatestFrom(this.store.select(getIsAppOnline)), first())
            .subscribe(([appInsights, isAppOnline]) => {
            if (isAppOnline) {
                appInsights.trackPageView({ name, uri });
            }
        });
    }
    logCustomEvent(eventName, customProperties) {
        this.appInsights$
            .pipe(withLatestFrom(this.store.select(getIsAppOnline)), first())
            .subscribe(([appInsights, isAppOnline]) => {
            const event = {
                name: eventName,
                properties: customProperties
            };
            if (isAppOnline) {
                appInsights.trackEvent(event);
            }
        });
    }
    logCustomEventStart(eventName) {
        this.appInsights$
            .pipe(withLatestFrom(this.store.select(getIsAppOnline)), first())
            .subscribe(([appInsights, isAppOnline]) => {
            if (isAppOnline) {
                appInsights.startTrackEvent(eventName);
            }
        });
    }
    logCustomEventStop(eventName, customProperties) {
        this.appInsights$
            .pipe(withLatestFrom(this.store.select(getIsAppOnline)), first())
            .subscribe(([appInsights, isAppOnline]) => {
            if (isAppOnline) {
                appInsights.stopTrackEvent(eventName, Object.assign({}, customProperties));
            }
        });
    }
    logApplicationError(error) {
        this.appInsights$
            .pipe(withLatestFrom(this.store.select(getIsAppOnline)), first())
            .subscribe(([appInsights, isAppOnline]) => {
            if (isAppOnline && environment.production) {
                const exception = {
                    error: new Error(JSON.stringify(error))
                };
                appInsights.trackException(exception);
            }
        });
    }
    ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }
}
ApplicationInsightsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationInsightsService_Factory() { return new ApplicationInsightsService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.AppInsightsFactory)); }, token: ApplicationInsightsService, providedIn: "root" });
