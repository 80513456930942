import { ApplicationRef, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { first, map } from 'rxjs/operators';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable()
export class AppInsightsFactory {
  constructor(private applicationRef: ApplicationRef) {}

  create() {
    const config = {
      instrumentationKey: environment.applicationInsights.instrumentationKey,
      accountId: environment.applicationInsights.accountId
    };

    return this.applicationRef.isStable.pipe(
      first(isStable => isStable),
      map(() => {
        console.log('App stable');
        const appInsights = new ApplicationInsights({ config });

        appInsights.loadAppInsights();

        appInsights.addTelemetryInitializer(
        (envelope) => {
          envelope.tags['ai.cloud.role'] = environment.applicationInsights.role;
        });

        return appInsights;
      })
    );
  }
}
