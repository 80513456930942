import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderWrapperComponent } from '../components/loader-wrapper/loader-wrapper.component';

@Injectable()
export class OverlayLoaderService {
  private overlayRef: OverlayRef = null;
  constructor(private overlay: Overlay) {}

  open() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create(this.getOverlayConfig());
      const loaderOverlay = new ComponentPortal(LoaderWrapperComponent);
      this.overlayRef.attach(loaderOverlay);
    }
  }

  close() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
}
