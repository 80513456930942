import { Observable } from 'rxjs';
import { WeekPaySummaryResponse } from '../responses/wages/weekPaySummaryResponse';
import { WeekPayDetailsResponse } from '../responses/wages/weekPayDetailsResponse';

export abstract class WagesService {
  abstract getWeeklyPayments(): Observable<WeekPaySummaryResponse[]>;

  abstract getWeekPaymentsBreakdown(
    weekEndDate
  ): Observable<WeekPayDetailsResponse>;
}
