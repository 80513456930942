<app-form-field [required]="required" *ngIf="formGroup">
  <app-label
    [asLegend]="formGroup.get('type').value === SurveyQuestionTypesEnum.YesNo"
    [innerHTML]="question | documentToHtml: 'span'"
  ></app-label>
  <app-radio-group
    *ngIf="formGroup.get('type').value === SurveyQuestionTypesEnum.YesNo"
    [formControl]="formGroup.get('answer')"
  >
    <app-radio-input value="yes" i18n="@@yes">
      Yes
    </app-radio-input>
    <app-radio-input value="no" i18n="@@no">
      No
    </app-radio-input>
  </app-radio-group>

  <app-select
    [formControl]="formGroup.get('answer')"
    *ngIf="formGroup.get('type').value === SurveyQuestionTypesEnum.Scale"
    placeholder="Select"
    valueKey=""
    labelKey=""
    [options]="scaleOptions"
  ></app-select>

  <app-text-area
    *ngIf="formGroup.get('type').value === SurveyQuestionTypesEnum.Open"
    [id]="id"
    [formControl]="formGroup.get('answer')"
    [maxLength]="150"
  ></app-text-area>

  <app-validation [errors]="formGroup.get('answer').errors">
    <app-validation-message
      *ngIf="formGroup.get('answer').errors?.required"
      i18n="@@validation-message_thisIsARequiredQuestion"
    >
      This is a required question
    </app-validation-message>
    <app-validation-message
      *ngIf="formGroup.get('answer').errors?.maxlength"
      class="form-question__error"
      i18n="@@validation-message_maxLengthOfThisAreaIs150Char"
    >
      Max length of this area is
      {{ formGroup.get('answer').errors?.maxlength?.requiredLength }} characters
    </app-validation-message>
  </app-validation>
</app-form-field>
