import { NgModule } from '@angular/core';
import { ShiftsComponent } from './shifts.component';
import { ShiftsRoutingModule } from './shifts-routing.module';
import { ShiftEditComponent } from './containers/shift-edit/shift-edit.component';
import { SharedComponentsModule } from '../../../shared/components/shared-components/shared-components.module';
import { ShiftDetailsCardComponent } from './components/shift-details-card/shift-details-card.component';
import { NgxMaskModule } from 'ngx-mask';
import { ShiftPreviewComponent } from './containers/shift-preview/shift-preview.component';
import { ShiftDetailsComponent } from './containers/shift-details/shift-details.component';
import { EffectsModule } from '@ngrx/effects';
import { ShiftEffects } from './effects/shift.effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShiftDeleteModalComponent } from './components/shift-delete-modal/shift-delete-modal.component';
import { ShiftRequestsComponent } from './containers/shift-requests/shift-requests.component';
import { SiteService } from '../../../shared/services/site.service';

@NgModule({
  declarations: [
    ShiftsComponent,
    ShiftEditComponent,
    ShiftDetailsCardComponent,
    ShiftPreviewComponent,
    ShiftDetailsComponent,
    ShiftDeleteModalComponent,
    ShiftRequestsComponent
  ],
  imports: [
    SharedComponentsModule,
    ShiftsRoutingModule,
    NgxMaskModule,
    EffectsModule.forFeature([ShiftEffects]),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [SiteService],
  exports: [ShiftsComponent, ShiftEditComponent],
  entryComponents: [ShiftDeleteModalComponent]
})
export class ShiftsModule {}
