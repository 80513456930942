<app-page-header (click)="openModal()"></app-page-header>

<main class="main-container">
  <ng-template #register i18n="@@application-form_pageTitle">
    Road Crew Registration
  </ng-template>
  <app-page-title
    [titleTemplate]="register"
    [appearance]="PageTitleAppearanceEnum.Blue"
  ></app-page-title>
  <div class="road-crew-application">
    <app-multi-step-form #multiStepForm [formGroup]="applicationForm">
      <ng-container
        appFormStepDef="personalDetails"
        [isEditable]="true"
        title="Personal Details"
        i18n-title="@@application-form_personalDetailsTitle"
        subTitle="Edit any details that need updating"
        i18n-subTitle="@@application-form_personalDetailsSubTitle"
      >
        <app-multi-step-form-readonly-step
          *appReadOnlyStepDef="
            let step = step;
            let stepForm = stepForm;
            let canStepBeEditable = canStepBeEditable
          "
          [title]="step.title"
          [isStepEditable]="canStepBeEditable"
          [showDetails]="stepForm.valid"
          (editStep)="step.edit()"
        >
          <app-personal-details-readonly
            [formGroup]="stepForm"
          ></app-personal-details-readonly>
        </app-multi-step-form-readonly-step>
        <app-multi-step-form-editable-step
          *appEditableStepDef="
            let step = step;
            let stepForm = stepForm;
            let nextStepTitle = nextStepTitle
          "
          [title]="step.title"
          [subTitle]="step.subTitle"
        >
          <form appRootForm class="multi-step-editable" [formGroup]="stepForm">
            <app-personal-details-edit
              [formGroup]="stepForm"
              [jobTitleOptions]="staticContent?.jobTitles"
            ></app-personal-details-edit>
            <div class="road-crew-application__action">
              <app-button-link
                [label]="nextStepTitle"
                [iconName]="SvgIconNameEnum.ArrowRightFilled"
                [iconSize]="ButtonIconSizeEnum.Small"
                [appearance]="ButtonAppearanceEnum.Link"
                (buttonClick)="multiStepForm.next()"
                [type]="ButtonTypeEnum.Submit"
              ></app-button-link>
            </div>
          </form>
        </app-multi-step-form-editable-step>
      </ng-container>
      <ng-container
        appFormStepDef="contactInfo"
        title="Contact Information"
        i18n-title="@@application-form_contactInfoTitle"
        subTitle="How can we get in touch ahead of and during Road Crew work?"
        i18n-subTitle="@@application-form_contactInfoSubTitle"
      >
        <app-multi-step-form-readonly-step
          *appReadOnlyStepDef="
            let step = step;
            let stepForm = stepForm;
            let canStepBeEditable = canStepBeEditable
          "
          [title]="step.title"
          [isStepEditable]="canStepBeEditable"
          [showDetails]="stepForm.valid"
          (editStep)="step.edit()"
        >
          <app-contact-information-readonly
            [formGroup]="stepForm"
          ></app-contact-information-readonly>
        </app-multi-step-form-readonly-step>
        <app-multi-step-form-editable-step
          *appEditableStepDef="
            let step = step;
            let stepForm = stepForm;
            let nextStepTitle = nextStepTitle
          "
          [title]="step.title"
          [subTitle]="step.subTitle"
        >
          <form appRootForm class="multi-step-editable" [formGroup]="stepForm">
            <app-contact-information-edit
              [formGroup]="stepForm"
            ></app-contact-information-edit>
            <div class="road-crew-application__action">
              <app-button-link
                [label]="nextStepTitle"
                [iconName]="SvgIconNameEnum.ArrowRightFilled"
                [iconSize]="ButtonIconSizeEnum.Small"
                [appearance]="ButtonAppearanceEnum.Link"
                (buttonClick)="multiStepForm.next()"
                [type]="ButtonTypeEnum.Submit"
              ></app-button-link>
            </div>
          </form>
        </app-multi-step-form-editable-step>
      </ng-container>
      <ng-container
        appFormStepDef="licence"
        title="Travel & ID"
        i18n-title="@@application-form_licenceTitle"
        subTitle="Apply as a potential driver (requires valid drivers license)
                    or a non-driver (requires a state-issued license or ID)"
        i18n-subTitle="@@application-form_licenceSubTitle"
      >
        <app-multi-step-form-readonly-step
          *appReadOnlyStepDef="
            let step = step;
            let stepForm = stepForm;
            let canStepBeEditable = canStepBeEditable
          "
          [title]="step.title"
          [isStepEditable]="canStepBeEditable"
          [showDetails]="stepForm.valid"
          (editStep)="step.edit()"
        >
          <app-licence-readonly [formGroup]="stepForm"></app-licence-readonly>
        </app-multi-step-form-readonly-step>
        <app-multi-step-form-editable-step
          *appEditableStepDef="
            let step = step;
            let stepForm = stepForm;
            let nextStepTitle = nextStepTitle
          "
          [title]="step.title"
          [subTitle]="step.subTitle"
        >
          <form appRootForm class="multi-step-editable" [formGroup]="stepForm">
            <app-licence-edit [formGroup]="stepForm"></app-licence-edit>
            <div class="road-crew-application__action">
              <app-button-link
                [label]="nextStepTitle"
                [iconName]="SvgIconNameEnum.ArrowRightFilled"
                [iconSize]="ButtonIconSizeEnum.Small"
                [appearance]="ButtonAppearanceEnum.Link"
                (buttonClick)="multiStepForm.next()"
                [type]="ButtonTypeEnum.Submit"
              ></app-button-link>
            </div>
          </form>
        </app-multi-step-form-editable-step>
      </ng-container>
      <ng-container
        appFormStepDef="certifications"
        title="Certification & Training"
        i18n-title="@@application-form_certificationsTitle"
        subTitle="Check off any certifications you’ve completed.
                    If you’re unsure about one, you can leave it blank and edit it later."
        i18n-subTitle="@@application-form_certificationsSubTitle"
      >
        <app-multi-step-form-readonly-step
          *appReadOnlyStepDef="
            let step = step;
            let stepForm = stepForm;
            let canStepBeEditable = canStepBeEditable
          "
          [title]="step.title"
          [isStepEditable]="canStepBeEditable"
          [showDetails]="stepForm.valid"
          (editStep)="step.edit()"
        >
          <app-certification-readonly
            [formGroup]="stepForm"
          ></app-certification-readonly>
        </app-multi-step-form-readonly-step>
        <app-multi-step-form-editable-step
          *appEditableStepDef="
            let step = step;
            let stepForm = stepForm;
            let nextStepTitle = nextStepTitle
          "
          [title]="step.title"
          [subTitle]="step.subTitle"
        >
          <form appRootForm class="multi-step-editable" [formGroup]="stepForm">
            <app-certification-edit
              [formGroup]="stepForm"
              [certificationsList]="staticContent?.certifications"
            ></app-certification-edit>
            <div class="road-crew-application__action">
              <app-button-link
                [label]="nextStepTitle"
                [iconName]="SvgIconNameEnum.ArrowRightFilled"
                [iconSize]="ButtonIconSizeEnum.Small"
                [appearance]="ButtonAppearanceEnum.Link"
                (buttonClick)="onStepOutFromCertifications(multiStepForm)"
                [type]="ButtonTypeEnum.Submit"
              ></app-button-link>
            </div>
          </form>
        </app-multi-step-form-editable-step>
      </ng-container>
      <ng-container
        appFormStepDef="signature"
        title="Signature"
        i18n-title="@@application-form_signatureTitle"
      >
        <app-multi-step-form-readonly-step
          *appReadOnlyStepDef="
            let step = step;
            let stepForm = stepForm;
            let canStepBeEditable = canStepBeEditable
          "
          [title]="step.title"
          [isStepEditable]="canStepBeEditable"
          [showDetails]="stepForm.valid"
          (editStep)="step.edit()"
        ></app-multi-step-form-readonly-step>
        <app-multi-step-form-editable-step
          *appEditableStepDef="let step = step; let stepForm = stepForm"
          [title]="step.title"
        >
          <form appRootForm class="multi-step-editable" [formGroup]="stepForm">
            <app-signature-edit
              [formGroup]="stepForm"
              [termsAndConditions]="staticContent?.termsAndConditions"
              (submitForm)="submitForm()"
            ></app-signature-edit>
          </form>
        </app-multi-step-form-editable-step>
      </ng-container>
    </app-multi-step-form>
    <app-footer class="road-crew-application__footer">
      <app-footer-note i18n="@@application-form_registrationDisclaimer">
        Your registration details will be used to apply to any future Road Crew
        opportunities. You can always edit and re-submit details in the future.
      </app-footer-note>
    </app-footer>
  </div>
</main>
