import { Routes } from '@angular/router';
import { ShiftEditComponent } from './containers/shift-edit/shift-edit.component';
import { ShiftsComponent } from './shifts.component';
import { AuthenticationGuard } from '../../../core/guards/authenticationGuard/authentication.guard';
import { ShiftPreviewComponent } from './containers/shift-preview/shift-preview.component';
import { ShiftDetailsComponent } from './containers/shift-details/shift-details.component';
import { ShiftRequestsComponent } from './containers/shift-requests/shift-requests.component';
import { TranslationMessages } from '../../../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../../../core/enums/SiteMapItemKeys';
const ɵ0 = {
    module: 'shifts'
}, ɵ1 = {
    showMenuBar: true,
    pageTitle: TranslationMessages.ShiftsTitle,
    siteMapKey: SiteMapItemKeys.ShiftRequests
}, ɵ2 = {
    showMenuBar: false,
    hideNavigationBar: true,
    pageTitle: TranslationMessages.ShiftCreateTitle,
    siteMapKey: SiteMapItemKeys.ShiftCreate
}, ɵ3 = {
    showMenuBar: false,
    hideNavigationBar: true,
    pageTitle: TranslationMessages.ShiftEditTitle,
    siteMapKey: SiteMapItemKeys.ShiftEdit
}, ɵ4 = {
    showMenuBar: false,
    hideNavigationBar: true,
    pageTitle: TranslationMessages.ShiftPreviewTitle,
    siteMapKey: SiteMapItemKeys.ShiftPreview
}, ɵ5 = {
    showMenuBar: false,
    pageTitle: TranslationMessages.ShiftDetailsTitle,
    siteMapKey: SiteMapItemKeys.ShiftDetails
};
const routes = [
    {
        path: 'shifts',
        component: ShiftsComponent,
        canActivate: [AuthenticationGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: ShiftRequestsComponent,
                data: ɵ1
            },
            {
                path: 'create',
                component: ShiftEditComponent,
                data: ɵ2
            },
            {
                path: 'edit/:id',
                component: ShiftEditComponent,
                data: ɵ3
            },
            {
                path: 'preview',
                component: ShiftPreviewComponent,
                data: ɵ4
            },
            {
                path: 'details/:id',
                component: ShiftDetailsComponent,
                data: ɵ5
            }
        ]
    }
];
export class ShiftsRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
