<div class="current-goal">
  <app-card-layout>
    <app-card-layout-item>
      <div class="current-goal__content" data-cy="currentEarningsGoal">
        <h2
          class="current-goal__label"
          i18n="@@current-goal_currentEarningGoal"
        >
          Current Earnings Goal
        </h2>
        <output data-cy="goalAmount" class="current-goal__amount">
          <span class="current-goal__sign">$</span>
          {{ earningGoal }}
        </output>
        <p class="current-goal__note" data-cy="goalNote">
          <span i18n="@@current-goal_currentEarningsGoal">
            Current Earnings Goal based on your average
          </span>
          {{ associatePaySplit * 100 | number: '1.1-2' }}%
          <span i18n="@@current-goal_associatePay">Associate Pay.</span>
        </p>
        <app-info-button
          class="current-goal__info"
          [text]="infoBoxText"
        ></app-info-button>
      </div>
    </app-card-layout-item>
  </app-card-layout>
</div>
