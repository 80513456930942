import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UserActions, LayoutActions } from '../../actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import * as fromRoot from '../../../reducers';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ButtonAppearance } from '../../../shared/enums/ButtonLink';
import { Sidebar } from 'ng-sidebar';
import { SvgIconName, SvgIconColor } from '../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild(Sidebar, { static: true }) drawer: Sidebar;

  ButtonAppearanceEnum = ButtonAppearance;
  SvgIconNameEnum = SvgIconName;
  SvgIconColorEnum = SvgIconColor;
  isUserLoggedIn$: Observable<boolean>;
  appVersion: string;

  constructor(private store: Store<AppState>) {}

  @Input()
  isOpen;

  logout() {
    this.store.dispatch(UserActions.userLogout({ isAutoLogout: false }));
  }

  open() {
    this.drawer.open();
  }

  ngOnInit() {
    this.isUserLoggedIn$ = this.store.pipe(select(fromRoot.getIsUserLoggedIn));
    this.appVersion = environment.version;
  }

  closeSidenav() {
    this.store.dispatch(LayoutActions.closeSidenav());
  }
}
