import { Component, Input } from '@angular/core';
import { WeekPayDetails } from '../../../core/dataEntities/wages/weekPayDetails';
import { SvgIconName } from '../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-earnings-summary',
  templateUrl: './earnings-summary.component.html',
  styleUrls: ['./earnings-summary.component.scss']
})
export class EarningsSummaryComponent {
  SvgIconNameEnum = SvgIconName;
  isCaliforniaView = false;
  @Input() weekPayDetails: WeekPayDetails;

  constructor() {}
}
