import { Component, OnInit, Input } from '@angular/core';
import { SvgIconColor, SvgIconName } from '../../enums/SvgIcon';

@Component({
  selector: 'app-icon-text',
  templateUrl: './icon-text.component.html',
  styleUrls: ['./icon-text.component.scss']
})
export class IconTextComponent implements OnInit {
  @Input() iconName: SvgIconName;
  @Input() iconColorPrimary: SvgIconColor;
  @Input() iconColorSecondary: SvgIconColor;
  constructor() {}

  ngOnInit() {}
}
