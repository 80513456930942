import { Component, OnInit, Input } from '@angular/core';
import { SvgIconName } from 'src/app/shared/enums/SvgIcon';
import { Module } from 'src/app/shared/enums/Module';

@Component({
  selector: 'app-navigation-link',
  templateUrl: './navigation-link.component.html',
  styleUrls: ['./navigation-link.component.scss']
})
export class NavigationLinkComponent implements OnInit {
  @Input() link: string;
  @Input() title: string;
  @Input() iconName: SvgIconName;
  @Input() appearance: Module = Module.Wages;
  @Input() module: Module;
  @Input() indicator = false;

  ModuleEnum = Module;

  constructor() {}

  ngOnInit() {}
}
