/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-modal-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/a11y";
import * as i3 from "@angular/common";
import * as i4 from "./filter-modal-layout.component";
var styles_FilterModalLayoutComponent = [i0.styles];
var RenderType_FilterModalLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterModalLayoutComponent, data: {} });
export { RenderType_FilterModalLayoutComponent as RenderType_FilterModalLayoutComponent };
export function View_FilterModalLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["cdkTrapFocus", ""], ["class", "filter-modal-layout"], ["data-cy", "modal"]], null, null, null, null, null)), i1.ɵdid(1, 1458176, null, 0, i2.CdkTrapFocus, [i1.ElementRef, i2.FocusTrapFactory, i3.DOCUMENT], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["cdkFocusInitial", ""], ["class", "filter-modal-layout__heading"], ["tabindex", "0"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "filter-modal-layout__content"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "filter-modal-layout__action"]], null, null, null, null, null)), i1.ɵncd(null, 2)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FilterModalLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-modal-layout", [], null, null, null, View_FilterModalLayoutComponent_0, RenderType_FilterModalLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i4.FilterModalLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterModalLayoutComponentNgFactory = i1.ɵccf("app-filter-modal-layout", i4.FilterModalLayoutComponent, View_FilterModalLayoutComponent_Host_0, {}, {}, ["[slot='modal-header']", "[slot='modal-content']", "[slot='modal-action']"]);
export { FilterModalLayoutComponentNgFactory as FilterModalLayoutComponentNgFactory };
