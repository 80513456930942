import { Component, Input, OnInit } from '@angular/core';
import { QrCodeComponent } from '../../../../profile/components/qr-code/qr-code.component';
import { DialogService } from '../../../modules/modal/dialog/dialog.service';
import { SvgIconName } from '../../../enums/SvgIcon';

@Component({
  selector: 'app-associate-qr-code',
  templateUrl: './associate-qr-code.component.html',
  styleUrls: ['./associate-qr-code.component.scss']
})
export class AssociateQrCodeComponent implements OnInit {
  public SvgIconNameEnum = SvgIconName;

  @Input() apexId: number;

  constructor(private dialogService: DialogService) {}

  ngOnInit() {}

  showQRCode() {
    this.dialogService.open(QrCodeComponent, { data: { apexId: this.apexId } });
  }
}
