import { Routes } from '@angular/router';
import { AuthenticationGuard } from '../../../core/guards/authenticationGuard/authentication.guard';
import { TranslationMessages } from '../../../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../../../core/enums/SiteMapItemKeys';
import { WorkComponent } from './work.component';
import { OpportunitiesDetailsContainerComponent } from './containers/opportunities-details/opportunities-details-container.component';
import { OpportunitiesListContainerComponent } from './containers/opportunities-list/opportunities-list-container.component';
import { RoadCrewApplicationContainerComponent } from './containers/road-crew-application/road-crew-application-container.component';
import { environment } from '../../../../environments/environment';
import { OpportunitiesParentComponent } from './containers/opportunities-parent/opportunities-parent.component';
const ɵ0 = {
    module: 'roadCrew',
    showMenuBar: true,
    hideNavigationBar: false,
    pageTitle: TranslationMessages.RoadCrewApplicationTitle,
    siteMapKey: SiteMapItemKeys.RoadCrewApplication,
    canActivate: environment.features.opportunities
}, ɵ1 = {
    showMenuBar: false,
    hideNavigationBar: true,
    pageTitle: TranslationMessages.OpportunitiesListPageTitle,
    siteMapKey: SiteMapItemKeys.OpportunitiesList,
    canActivate: environment.features.opportunities
}, ɵ2 = {
    showMenuBar: false,
    hideNavigationBar: true,
    pageTitle: TranslationMessages.OpportunityDetailsPageTitle,
    siteMapKey: SiteMapItemKeys.OpportunityDetails,
    canActivate: environment.features.opportunities
}, ɵ3 = {
    showMenuBar: false,
    hideNavigationBar: true,
    pageTitle: TranslationMessages.RoadCrewApplicationTitle,
    siteMapKey: SiteMapItemKeys.RoadCrewApplication,
    canActivate: environment.features.opportunities
};
const routes = [
    {
        path: 'roadcrew',
        component: OpportunitiesParentComponent,
        canActivate: [AuthenticationGuard],
        data: ɵ0
    },
    {
        path: 'opportunities',
        component: WorkComponent,
        canActivate: [AuthenticationGuard],
        children: [
            {
                path: '',
                component: OpportunitiesListContainerComponent,
                data: ɵ1
            },
            {
                path: ':id/details',
                component: OpportunitiesDetailsContainerComponent,
                data: ɵ2
            }
        ]
    },
    {
        path: 'opportunity/apply',
        component: RoadCrewApplicationContainerComponent,
        canActivate: [AuthenticationGuard],
        data: ɵ3
    }
];
export class WorkRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
