<div class="expandable-panel">
  <h1 *ngIf="panel.title">
    {{ panel.title }}
  </h1>
  <div [innerHTML]="convertToHtmlString(panel.description)"></div>
  <app-accordion>
    <app-accordion-panel
      *ngFor="let item of panel.items; let i = index"
      [title]="item.title"
    >
      <div
        slot="body"
        [attr.data-cy]="'body_' + i"
        [innerHTML]="convertToHtmlString(item.description)"
      ></div>
    </app-accordion-panel>
  </app-accordion>
</div>
