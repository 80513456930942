import moment from 'moment';
import { IdDateFormat } from '../constants';

export class DateTimeUtils {
  static getWeekStartDateByEndDate(weekEndingDate) {
    return moment(weekEndingDate).add(-6, 'days');
  }
  static getWeeksCountFromDates(startDate, endDate) {
    const count = moment(endDate).diff(moment(startDate), 'week');
    return Math.floor(count);
  }

  static getDaysCountFromDates(startDate, endDate) {
    const count = moment(endDate).diff(moment(startDate), 'day');
    return Math.floor(count);
  }

  static getCurrentWeekEndDate() {
    const now = moment().utc();
    if (now.isoWeekday() === 7) {
      return now.add(6, 'days').format(IdDateFormat);
    }
    return now.isoWeekday(6).format(IdDateFormat);
  }

  static getPastWeekEndDate() {
    const now = moment().utc();
    const previousWeekDay = now.add(-7, 'days');
    if (now.isoWeekday() === 7) {
      return previousWeekDay.add(6, 'days').format(IdDateFormat);
    }
    return previousWeekDay.isoWeekday(6).format(IdDateFormat);
  }

  static getDayOfTheWeek() {
    return moment()
      .utc()
      .isoWeekday();
  }

  static getSixWeeksEndDate() {
    const sixWeeksDate = moment()
      .utc()
      .add(-6, 'weeks');
    if (sixWeeksDate.isoWeekday() === 7) {
      return sixWeeksDate.add(6, 'days').format(IdDateFormat);
    }
    return sixWeeksDate.isoWeekday(6).format(IdDateFormat);
  }
}
