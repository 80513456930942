<ng-template #defaultTitleTemplate>
  {{ title }}
</ng-template>

<h2
  *ngIf="title || titleTemplate"
  [ngClass]="{
    card__title: true,
    'card__title--blue': appearance === PageSectionHeaderAppearanceEnum.Blue,
    'card__title--green': appearance === PageSectionHeaderAppearanceEnum.Green,
    'card__title--red': appearance === PageSectionHeaderAppearanceEnum.Red,
    'card__title--gray': appearance === PageSectionHeaderAppearanceEnum.Gray,
    'card__title--gold': appearance === PageSectionHeaderAppearanceEnum.Gold
  }"
>
  <ng-container
    *ngTemplateOutlet="titleTemplate ? titleTemplate : defaultTitleTemplate"
  ></ng-container>
</h2>

<ng-template #defaultSubtitleTemplate>
  {{ subtitle }}
</ng-template>

<p *ngIf="subtitle || subTitleTemplate" class="card__subtitle">
  <ng-container
    *ngTemplateOutlet="
      subTitleTemplate ? subTitleTemplate : defaultSubtitleTemplate
    "
  ></ng-container>
</p>
