import { createAction, props } from '@ngrx/store';
import { OpportunityDetails } from '../../../../core/dataEntities/opportunities/opportunityDetails';
import { OpportunityListItem } from '../../../../core/dataEntities/opportunities/opportunityListItem';
import { AppliedOpportunityListItem } from '../../../../core/dataEntities/opportunities/appliedOpportunityListItem';
import { OpportunityType } from '../../../../core/enums/OpportunityType';
import { RoadCrewApplication } from '../../../../core/dataEntities/applicationForm/roadCrewApplication';
import { ApplicationFormStatus } from '../../../../core/enums/ApplicationFormStatus';

export const loadOpportunityDetails = createAction(
  '[Opportunities Details] Load Opportunity Details',
  props<{ id: string; otherId: string }>()
);

export const opportunityDetailsLoaded = createAction(
  '[Opportunities Details] Opportunity Details Loaded',
  props<{ opportunity: OpportunityDetails }>()
);

export const loadOpportunities = createAction(
  '[Opportunities] Load All Opportunities'
);

export const toggleOpportunityLoading = createAction(
  '[Opportunities] Toggle opportunity loading',
  props<{ isLoading: boolean }>()
);

export const allOpportunitiesLoaded = createAction(
  '[Opportunities] All Opportunities Loaded',
  props<{
    roadCrewOpenings: OpportunityListItem[];
    roadCrewOpeningsCount: number;
    updatedOpportunities: AppliedOpportunityListItem[];
    pendingOpportunities: AppliedOpportunityListItem[];
  }>()
);

export const openOpportunity = createAction(
  '[Opportunities] Open Opportunity',
  props<{
    id: string;
    opportunityId: string;
    opportunityType: OpportunityType;
    isNew?: boolean;
  }>()
);

export const loadApplicationForm = createAction(
  '[Application Form] Load Application Form'
);
export const applicationFormLoaded = createAction(
  '[Application Form] Application Form loaded',
  props<{
    form: RoadCrewApplication;
    formStatus: ApplicationFormStatus;
  }>()
);

export const submitApplicationForm = createAction(
  '[Application Form] Submit Application Form',
  props<{ form: RoadCrewApplication }>()
);

export const saveApplicationForm = createAction(
  '[Application Form] Save Application Form',
  props<{ form: RoadCrewApplication }>()
);

export const openApplicationForm = createAction(
  '[Application Form] Open Application Form'
);

export const applyForOpportunity = createAction(
  '[Opportunities Details] Apply For Opportunity',
  props<{ preferredAirport: string }>()
);

export const cancelAppliedOpportunity = createAction(
  '[Opportunities Details] Cancel Applied Opportunity'
);

export const getIsEachOpportunityReviewed = createAction(
  '[Opportunities] Get Is Each Opportunity Reviewed'
);

export const saveIsEachOpportunityReviewed = createAction(
  '[Opportunities] Save Is Each Opportunity Reviewed',
  props<{
    result: boolean;
  }>()
);
