import { Component, Input } from '@angular/core';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { ExpandablePanel } from '../../../core/dataEntities/dynamicContent/expandablePanel';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { RichTextHtmlRendererOptions } from '../../../shared/constants';

@Component({
  selector: 'app-expandable-panel',
  templateUrl: './expandable-panel.component.html',
  styleUrls: ['./expandable-panel.component.scss']
})
export class ExpandablePanelComponent {
  public PageTitleAppearanceEnum = PageTitleAppearance;

  @Input() panel: ExpandablePanel;

  constructor() {}

  convertToHtmlString(data: any) {
    return documentToHtmlString(data, RichTextHtmlRendererOptions);
  }
}
