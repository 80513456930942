import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { Post } from '../../../core/dataEntities/feed/post';
import { filter, first, takeUntil, map, finalize } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AppState,
  getIsAppOnline,
  selectIsErrorOccurredOnStarsFeedLoading,
  selectIsStarsFeedLoading,
  selectLoadedScriptStars,
  selectStarsFeedCurrentPageAndTotalCount
} from '../../../reducers';
import { ResourcesActions } from '../../actions';
import moment from 'moment';
import { FeedType } from '../../../shared/enums/FeedType';
import { ResourcesService } from '../../services/resources.service';
import { LayoutActions } from '../../../core/actions';

@Component({
  selector: 'app-stars-feed-container',
  templateUrl: './stars-feed-container.component.html',
  styleUrls: ['./stars-feed-container.component.scss']
})
export class StarsFeedContainerComponent implements OnInit, OnDestroy {
  onDestroySubject = new Subject();
  public itemsPerPage = 2;
  public page = 0;
  public total;
  public starsFeed$: Observable<Post[]>;
  public isStarsFeedLoading$: Observable<boolean>;
  public isErrorOccurredOnStarsFeedLoading$: Observable<boolean>;
  public isAppOnline$: Observable<boolean>;
  public showAppLoader$: Observable<boolean>;
  public blogPostsExtendable = {};
  PageTitleAppearanceEnum = PageTitleAppearance;
  FeedTypeEnum = FeedType;
  pinnedPost = null;

  constructor(
    private store: Store<AppState>,
    private resources: ResourcesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store
      .select(selectStarsFeedCurrentPageAndTotalCount)
      .pipe(
        filter(feed => feed.totalCount != null),
        first()
      )
      .subscribe(feed => {
        this.total = feed.totalCount;
        this.page = feed.currentPage;
      });

    this.isStarsFeedLoading$ = this.store.select(selectIsStarsFeedLoading);
    this.starsFeed$ = this.store.select(selectLoadedScriptStars);
    this.isErrorOccurredOnStarsFeedLoading$ = this.store.select(
      selectIsErrorOccurredOnStarsFeedLoading
    );
    this.isAppOnline$ = this.store.select(getIsAppOnline);
    this.showAppLoader$ = combineLatest(
      this.isStarsFeedLoading$,
      this.starsFeed$
    ).pipe(
      map(([isStarsFeedLoading, starsFeed]) => {
        return isStarsFeedLoading && starsFeed.length === 0;
      })
    );
    this.store.dispatch(
      ResourcesActions.loadScriptStars({
        page: this.page,
        itemsPerPage: this.itemsPerPage
      })
    );
    this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }));

    this.resources
      .loadPinnedPost()
      .pipe(
        finalize(() =>
          this.store.dispatch(
            LayoutActions.toggleAppLoading({ loading: false })
          )
        )
      )
      .subscribe(value => {
        this.pinnedPost = value;
      });
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    const position =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    combineLatest(
      this.starsFeed$,
      this.isStarsFeedLoading$.pipe(first(), takeUntil(this.onDestroySubject))
    ).subscribe(([stars, isStarsLoading]) => {
      if (document.documentElement.scrollHeight - position <= 60) {
        if (this.total > stars.length && !isStarsLoading) {
          this.page++;
          this.store.dispatch(
            ResourcesActions.loadScriptStars({
              page: this.page,
              itemsPerPage: this.itemsPerPage
            })
          );
        }
      }
    });
  }

  daysAgo(date: string) {
    return moment().diff(moment(date), 'days');
  }

  onLikeEvent(data: any) {
    this.store.dispatch(
      ResourcesActions.likePost({ postId: data.id, isLiked: data.isLiked })
    );
  }

  getPinnedPost() {
    return this.pinnedPost;
  }

  ngOnDestroy() {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  onContentSizeChange(event) {
    this.blogPostsExtendable[event.postId] = {
      isExtendable: event.isExtendable
    };
    this.cdr.detectChanges();
  }
}
