import { Component, OnInit } from '@angular/core';
import { SvgIconName } from '../../../../shared/enums/SvgIcon';
import { AlertAppearance } from '../../../../core/enums/Alert';
import { DialogRef } from '../../../../shared/modules/modal/dialog-ref';
import { Router } from '@angular/router';

@Component({
  selector: 'app-phone-reminder',
  templateUrl: './phone-reminder.component.html'
})
export class PhoneReminderComponent implements OnInit {
  SvgIconName = SvgIconName;
  AlertAppearance = AlertAppearance;

  constructor(
    private alertUpdateRef: DialogRef<PhoneReminderComponent>,
    private router: Router
  ) {}

  ngOnInit() {}

  onCloseAlert(response) {
    this.alertUpdateRef.close(response);
    if (response.success === true) {
      this.router.navigate(['/settings']);
    }
  }
}
