import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { InfoBoxComponent } from './info-box/info-box.component';
import { InfoBoxRef, InfoBoxState } from './info-box/info-box-ref';
import { SvgIconColor, SvgIconName } from '../../enums/SvgIcon';
import { InfoBoxService } from './info-box.service';

@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoButtonComponent {
  @Input() text = '';
  @ViewChild('trigger', { static: true }) triggerButton: ElementRef;
  SvgIconNameEnum = SvgIconName;
  SvgIconColorEnum = SvgIconColor;

  private infoBoxRef: InfoBoxRef<InfoBoxComponent>;

  constructor(private infoBox: InfoBoxService) {}

  toggle() {
    if (this.infoBoxRef && this.infoBoxRef.state === InfoBoxState.OPEN) {
      this.infoBoxRef.close();
      this.infoBoxRef = null;
    } else {
      this.infoBoxRef = this.infoBox.open(
        InfoBoxComponent,
        {
          triggerEl: this.triggerButton,
          disposeOnNavigation: true,
          singlePerGroup: true,
          data: {
            text: this.text
          },
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetY: -8,
          offsetX: 9
        },
        'InfoBoxComponent'
      );
    }
  }
}
