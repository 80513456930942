import { Component, Input } from '@angular/core';
import { SvgIconName } from '../../../enums/SvgIcon';
import { PageTitleAppearance } from '../../../enums/PageTitle';
import { ButtonColor } from '../../../enums/ButtonLink';
import {
  WeeklyCheckinList,
  WeeklyCheckinListItem
} from '../../../../core/dataEntities/profile/weeklyCheckinList';

@Component({
  selector: 'app-check-in-history',
  templateUrl: './check-in-history.component.html',
  styleUrls: ['./check-in-history.component.scss']
})
export class CheckInHistoryComponent {
  @Input() userName: string;
  @Input() weeklyCheckinList: WeeklyCheckinList;
  @Input() lastWeeklyCheckin: WeeklyCheckinListItem;

  public SvgIconNameEnum = SvgIconName;
  public PageTitleAppearanceEnum = PageTitleAppearance;
  public ButtonColorEnum = ButtonColor;

  constructor() {}
}
