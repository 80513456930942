import { Routes } from '@angular/router';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { SettingsContainerComponent } from './containers/settings-container/settings-container.component';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { WeeklyCheckInComponent } from './containers/weekly-check-in/weekly-check-in.component';
import { AuthorizationGuard } from '../core/guards/authorizationGuard/authorization.guard';
import { Roles } from '../core/enums/Roles';
import { AssociateCheckInHistoryComponent } from './containers/associate-check-in-history/associate-check-in-history.component';
import { AssociateProductionComponent } from './containers/associate-production/associate-production.component';
import { ProfileComponent } from './profile.component';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { Module } from '../shared/enums/Module';
import { PhotoPreviewComponent } from './containers/photo-preview/photo-preview.component';
const ɵ0 = {
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    allowedRoles: [Roles.Associate]
}, ɵ1 = {
    showMenuBar: true,
    module: Module.MyProfile,
    siteMapKey: SiteMapItemKeys.AssociateCheckInHistory,
    pageTitle: TranslationMessages.CheckInHistoryTitle
}, ɵ2 = {
    showMenuBar: true,
    module: Module.MyProfile,
    siteMapKey: SiteMapItemKeys.AssociateProduction,
    pageTitle: TranslationMessages.ProductionStatsTitle
}, ɵ3 = {
    hideNavigationBar: true,
    module: Module.MyProfile,
    siteMapKey: SiteMapItemKeys.TakePhoto
}, ɵ4 = {
    hideNavigationBar: true,
    siteMapKey: SiteMapItemKeys.Settings,
    pageTitle: TranslationMessages.SettingsTitle
}, ɵ5 = {
    hideNavigationBar: true,
    siteMapKey: SiteMapItemKeys.WeeklyCheckIn,
    allowedRoles: [Roles.Associate],
    pageTitle: TranslationMessages.WeeklyCheckInTitle
};
const routes = [
    {
        path: 'profile',
        component: ProfileComponent,
        data: ɵ0,
        children: [
            {
                path: 'check-ins',
                component: AssociateCheckInHistoryComponent,
                data: ɵ1
            },
            {
                path: 'production',
                component: AssociateProductionComponent,
                data: ɵ2
            },
            {
                path: 'photo',
                children: [
                    {
                        path: 'preview',
                        component: PhotoPreviewComponent,
                        data: ɵ3
                    }
                ]
            }
        ]
    },
    {
        path: 'settings',
        component: SettingsContainerComponent,
        canActivate: [AuthenticationGuard],
        data: ɵ4
    },
    {
        path: 'check-in/:week',
        component: WeeklyCheckInComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: ɵ5
    }
];
export class ProfileRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
