/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/a11y";
import * as i3 from "../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i4 from "../../../shared/components/svg-icon/svg-icon.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./menu-bar.component";
var styles_MenuBarComponent = [i0.styles];
var RenderType_MenuBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuBarComponent, data: {} });
export { RenderType_MenuBarComponent as RenderType_MenuBarComponent };
export function View_MenuBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "button", [["cdkMonitorElementFocus", ""], ["class", "menu-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMenu.emit("") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i2.CdkMonitorFocus, [i1.ElementRef, i2.FocusMonitor], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-svg-icon", [["class", "menu-button__icon"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(4, 638976, null, 0, i4.SvgIconComponent, [i5.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["data-cy", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Menu"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-svg-icon", [["class", "logo__icon"], ["description", "Capstone logotype"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(9, 638976, null, 0, i4.SvgIconComponent, [i5.DomSanitizer], { name: [0, "name"], description: [1, "description"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "logo__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Capstone"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.Menu; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.SvgIconNameEnum.CapstoneLogo; var currVal_2 = "Capstone logotype"; _ck(_v, 9, 0, currVal_1, currVal_2); }, null); }
export function View_MenuBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-bar", [], null, null, null, View_MenuBarComponent_0, RenderType_MenuBarComponent)), i1.ɵdid(1, 114688, null, 0, i6.MenuBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuBarComponentNgFactory = i1.ɵccf("app-menu-bar", i6.MenuBarComponent, View_MenuBarComponent_Host_0, {}, { openMenu: "openMenu" }, []);
export { MenuBarComponentNgFactory as MenuBarComponentNgFactory };
