export enum JobTitles {
  AsstTeamLeader = 'asstTeamLeader',
  Auditor = 'auditor',
  BackupTeamLead = 'backupTeamLead',
  Baler = 'baler',
  Clerk = 'clerk',
  ComplianceProgramAdministrator = 'complianceProgramAdministrator',
  CustomerServiceSupport = 'customerServiceSupport',
  DriverAssistant = 'driverAssistant',
  FieldRecruiter = 'fieldRecruiter',
  ForkliftOperator = 'forkliftOperator',
  FreightHandler = 'freightHandler',
  FreightHandlerRoadCrew = 'freightHandlerRoadCrew',
  FreightHauler = 'freightHauler',
  Fueler = 'fueler',
  Lead = 'lead',
  LeadAudit = 'leadAudit',
  LeadI = 'leadI',
  LeadIII = 'leadIII',
  LiftOperator = 'liftOperator',
  Loader = 'loader',
  Maintenance = 'maintenance',
  MaterialHandler = 'materialHandler',
  OfficeOrClerical = 'officeOrClerical',
  Painter = 'painter',
  PalletHandler = 'palletHandler',
  Palletizer = 'palletizer',
  ProductionManager = 'productionManager',
  ProductionSupervisor = 'productionSupervisor',
  Receiver = 'receiver',
  ReclaimsSpecialist = 'reclaimsSpecialist',
  Recruiter = 'recruiter',
  ReturnsProcessor = 'returnsProcessor',
  Sanitation = 'sanitation',
  SchedulingCoordinator = 'schedulingCoordinator',
  SeasonalFreightHandler = 'seasonalFreightHandler',
  SecurityOrGuardShack = 'securityOrGuardShack',
  Selector = 'selectorJob',
  SelectorRoadCrew = 'selectorRoadCrew',
  SeniorLeadman = 'seniorLeadman',
  SiteOpsAdminAsst = 'siteOpsAdminAsst',
  Spotter = 'spotter',
  Switcher = 'switcher',
  SwitcherNonCDL = 'switcherNonCDL',
  TrailerStripper = 'trailerStripper',
  TravelTeamSelector = 'travelTeamSelector',
  WarehouseAssociate = 'warehouseAssociate'
}
