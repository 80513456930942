import { Inject, Injectable } from '@angular/core';
import { APP_SITE_MAP, SiteMapItem } from '../../core/siteMap/SiteMapTree';
import { Router } from '@angular/router';
import { SiteMapItemKeys } from '../../core/enums/SiteMapItemKeys';

@Injectable()
export class SiteMapService {
  private siteMapCache: { [key: string]: SiteMapItem } = {};
  private siteMapParentCache: { [key: string]: SiteMapItem } = {};

  constructor(
    @Inject(APP_SITE_MAP)
      appSiteMap: SiteMapItem,
    private router: Router
  ) {
    this.cacheSiteMapItems(appSiteMap);
  }

  getSiteMapItem(siteMapKey: SiteMapItemKeys): SiteMapItem {
    return this.siteMapCache[siteMapKey];
  }

  getParentPath(siteMapKey: SiteMapItemKeys, routeParams: any): string[] {
    const siteMapItem = this.siteMapCache[siteMapKey];
    const parents = this.getParents(siteMapKey);
    if (!siteMapItem) {
      throw new Error(`Page is not found in site map`);
    }
    const parentParams = siteMapItem.mapToParentParams
      ? siteMapItem.mapToParentParams(routeParams)
      : routeParams;
    return this.getParentsUrls(parents, parentParams);
  }

  private getParents(siteMapKey: string, parents: SiteMapItem[] = []) {
    const parentNode = this.siteMapParentCache[siteMapKey];
    if (!parentNode) {
      return parents;
    }

    return this.getParents(parentNode.key, [...parents, parentNode]);
  }

  private cacheSiteMapItems(
    siteMapItem: SiteMapItem,
    parentSiteMapItem: SiteMapItem = null
  ) {
    if (siteMapItem.key) {
      this.siteMapCache[siteMapItem.key] = siteMapItem;
    }

    if (parentSiteMapItem && parentSiteMapItem.key && siteMapItem.key) {
      this.siteMapParentCache[siteMapItem.key] = parentSiteMapItem;
    }
    if (siteMapItem.children) {
      siteMapItem.children.forEach(sm => {
        this.cacheSiteMapItems(sm, siteMapItem);
      });
    }
  }

  private getParentsUrls(parents: SiteMapItem[], routeParams: any) {
    const state = parents.reduce(
      ({ parentUrls, params }, parent) => {
        const urlCommand = parent.createUrlCommand(params);
        let url = urlCommand;
        if (Array.isArray(urlCommand)) {
          const urlTree = this.router.createUrlTree(urlCommand);
          url = this.router.serializeUrl(urlTree);
        }
        return {
          parentUrls: [url, ...parentUrls],
          params: parent.mapToParentParams
            ? parent.mapToParentParams(params)
            : params
        };
      },
      {
        parentUrls: [],
        params: routeParams
      }
    );

    return state.parentUrls;
  }
}
