import moment from 'moment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  AppState,
  selectActiveWeekPayDetails,
  selectIsPayLoading,
  getIsAppOnline,
  getActiveWeekGoal,
  selectIsUserTrainee
} from '../../../reducers';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { WeekPayDetails } from '../../../core/dataEntities/wages/weekPayDetails';
import { WagesActions } from '../../actions';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { GoalDetails } from '../../../core/dataEntities/goal/goalDetails';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { AnalyticsEvents } from 'src/app/shared/enums/AnalyticsEvents';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit, OnDestroy {
  private weekDetailsSubscription;
  public weekPayDetails$: Observable<WeekPayDetails>;
  public isHistoricalEarning$: Observable<boolean>;
  public isFutureProcessing$: Observable<boolean>;
  public showEmptyState$: Observable<boolean>;
  public isPayLoading$: Observable<boolean>;
  public isAppOnline$: Observable<boolean>;
  public isTrainee$: Observable<boolean>;

  goalDetails$: Observable<GoalDetails>;
  SvgIconNameEnum = SvgIconName;
  PageTitleAppearanceEnum = PageTitleAppearance;
  AnalyticsEventsEnum = AnalyticsEvents;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit() {
    const weekPayDetails$ = this.store.select(selectActiveWeekPayDetails);
    this.isPayLoading$ = this.store.select(selectIsPayLoading);
    this.isAppOnline$ = this.store.select(getIsAppOnline);
    this.isTrainee$ = this.store.select(selectIsUserTrainee);

    this.showEmptyState$ = combineLatest(
      weekPayDetails$,
      this.isAppOnline$
    ).pipe(
      map(([weekPayDetails, isAppOnline]) => {
        return !weekPayDetails && !isAppOnline;
      })
    );

    this.weekPayDetails$ = combineLatest(
      weekPayDetails$,
      this.isPayLoading$
    ).pipe(
      map(([weekPayDetails, isPayLoading]) => {
        if (!weekPayDetails || isPayLoading) {
          return null;
        }
        return weekPayDetails;
      })
    );

    this.isHistoricalEarning$ = this.weekPayDetails$.pipe(
      map(weekPayDetails => {
        if (!weekPayDetails) {
          return;
        }
        return moment(weekPayDetails.weekEndingDate).isSameOrBefore(moment());
      }),
      shareReplay(1)
    );

    this.isFutureProcessing$ = this.weekPayDetails$.pipe(
      map(weekPayDetails => {
        if (!weekPayDetails) {
          return;
        }
        return moment(weekPayDetails.paymentProcessDate).isAfter(moment());
      }),
      shareReplay(1)
    );

    this.weekDetailsSubscription = this.route.paramMap.subscribe(
      (params: ParamMap) => {
        return this.store.dispatch(
          WagesActions.loadPaymentWeekDetails({
            weekEndingDate: params.get('id')
          })
        );
      }
    );

    this.goalDetails$ = this.store.pipe(select(getActiveWeekGoal));
  }

  ngOnDestroy() {
    if (this.weekDetailsSubscription) {
      this.weekDetailsSubscription.unsubscribe();
      this.weekDetailsSubscription = null;
    }
  }
}
