/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./opportunities-details-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/components/page-title/page-title.component.ngfactory";
import * as i4 from "../../../../../shared/components/page-title/page-title.component";
import * as i5 from "../../components/opportunity-details/opportunity-details.component.ngfactory";
import * as i6 from "../../components/opportunity-details/opportunity-details.component";
import * as i7 from "../../../../../shared/components/empty-state/empty-state.component.ngfactory";
import * as i8 from "../../../../../shared/components/empty-state/empty-state.component";
import * as i9 from "../../../../../shared/services/translation.service";
import * as i10 from "../../../../../shared/components/page-header/page-header-container.component.ngfactory";
import * as i11 from "../../../../../shared/components/page-header/page-header-container.component";
import * as i12 from "@ngrx/store";
import * as i13 from "./opportunities-details-container.component";
import * as i14 from "@angular/router";
import * as i15 from "../../../../../shared/modules/modal/dialog/dialog.service";
import * as i16 from "../../services/form-utilities.service";
var styles_OpportunitiesDetailsContainerComponent = [i0.styles];
var RenderType_OpportunitiesDetailsContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OpportunitiesDetailsContainerComponent, data: {} });
export { RenderType_OpportunitiesDetailsContainerComponent as RenderType_OpportunitiesDetailsContainerComponent };
function View_OpportunitiesDetailsContainerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Week In"]))], null, null); }
function View_OpportunitiesDetailsContainerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Weeks In"]))], null, null); }
function View_OpportunitiesDetailsContainerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "+ "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesDetailsContainerComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesDetailsContainerComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var currVal_1 = ((_v.parent.parent.parent.context.ngIf == null) ? null : _v.parent.parent.parent.context.ngIf.duration); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.parent.context.ngIf == null) ? null : _v.parent.parent.parent.context.ngIf.duration); _ck(_v, 1, 0, currVal_0); }); }
function View_OpportunitiesDetailsContainerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Less Than a Week In "]))], null, null); }
function View_OpportunitiesDetailsContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesDetailsContainerComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesDetailsContainerComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = (((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.duration) && (((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.duration) !== "0")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.duration) && (((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.duration) === "0")); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.StringHelpers.joinStrings(((_v.parent.parent.context.ngIf.location == null) ? null : _v.parent.parent.context.ngIf.location.city), ((_v.parent.parent.context.ngIf.location == null) ? null : _v.parent.parent.context.ngIf.location.stateName)); _ck(_v, 6, 0, currVal_2); }); }
function View_OpportunitiesDetailsContainerComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Week"]))], null, null); }
function View_OpportunitiesDetailsContainerComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Weeks"]))], null, null); }
function View_OpportunitiesDetailsContainerComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "+ "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesDetailsContainerComponent_10)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesDetailsContainerComponent_11)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var currVal_1 = ((_v.parent.parent.parent.context.ngIf == null) ? null : _v.parent.parent.parent.context.ngIf.duration); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.parent.context.ngIf == null) ? null : _v.parent.parent.parent.context.ngIf.duration); _ck(_v, 1, 0, currVal_0); }); }
function View_OpportunitiesDetailsContainerComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Less Than a Week "]))], null, null); }
function View_OpportunitiesDetailsContainerComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" National opportunity for "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesDetailsContainerComponent_9)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesDetailsContainerComponent_12)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.duration) && (((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.duration) !== "0")); _ck(_v, 6, 0, currVal_1); var currVal_2 = (((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.duration) && (((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.duration) === "0")); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf.skillsetName; _ck(_v, 4, 0, currVal_0); }); }
function View_OpportunitiesDetailsContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesDetailsContainerComponent_3)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesDetailsContainerComponent_8)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.postingBySkillset); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.postingBySkillset); _ck(_v, 3, 0, currVal_1); }, null); }
function View_OpportunitiesDetailsContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(0, [["weeksTemplate", 2]], null, 0, null, View_OpportunitiesDetailsContainerComponent_2)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-page-title", [], null, null, null, i3.View_PageTitleComponent_0, i3.RenderType_PageTitleComponent)), i1.ɵdid(3, 114688, null, 0, i4.PageTitleComponent, [], { titleTemplate: [0, "titleTemplate"], appearance: [1, "appearance"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "opportunities-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "app-opportunity-details", [], null, [[null, "register"], [null, "apply"], [null, "updateDetails"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("register" === en)) {
        var pd_0 = (_co.openApplicationForm() !== false);
        ad = (pd_0 && ad);
    } if (("apply" === en)) {
        var pd_1 = (_co.applyForOpportunity() !== false);
        ad = (pd_1 && ad);
    } if (("updateDetails" === en)) {
        var pd_2 = (_co.openApplicationForm() !== false);
        ad = (pd_2 && ad);
    } if (("cancel" === en)) {
        var pd_3 = (_co.cancelAppliedOpportunity() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_OpportunityDetailsComponent_0, i5.RenderType_OpportunityDetailsComponent)), i1.ɵdid(6, 114688, null, 0, i6.OpportunityDetailsComponent, [], { opportunityDetails: [0, "opportunityDetails"], userCertificates: [1, "userCertificates"], hasApplicationFormSubmitted: [2, "hasApplicationFormSubmitted"], applicationFormStatus: [3, "applicationFormStatus"], userEmail: [4, "userEmail"] }, { apply: "apply", updateDetails: "updateDetails", register: "register", cancel: "cancel" }), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1); var currVal_1 = _co.PageTitleAppearanceEnum.Green; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _v.context.ngIf; var currVal_3 = (_co.applicationForm ? _co.applicationForm.certificateInfo.certificates : null); var currVal_4 = (_co.applicationForm !== null); var currVal_5 = i1.ɵunv(_v, 6, 3, i1.ɵnov(_v, 7).transform(_co.applicationFormStatus$)); var currVal_6 = i1.ɵunv(_v, 6, 4, i1.ɵnov(_v, 8).transform(_co.userEmail$)); _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_OpportunitiesDetailsContainerComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Opportunity details not found. "]))], null, null); }
function View_OpportunitiesDetailsContainerComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-state", [], null, null, null, i7.View_EmptyStateComponent_0, i7.RenderType_EmptyStateComponent)), i1.ɵdid(1, 114688, null, 0, i8.EmptyStateComponent, [i9.TranslationService], { emptyStateTemplate: [0, "emptyStateTemplate"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 7); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OpportunitiesDetailsContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-header-container", [], null, null, null, i10.View_PageHeaderContainerComponent_0, i10.RenderType_PageHeaderContainerComponent)), i1.ɵdid(1, 114688, null, 0, i11.PageHeaderContainerComponent, [i12.Store], { appearance: [0, "appearance"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 9, "main", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_OpportunitiesDetailsContainerComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["emptyStateTranslation", 2]], null, 0, null, View_OpportunitiesDetailsContainerComponent_13)), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_OpportunitiesDetailsContainerComponent_14)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.PageHeaderAppearanceEnum.Green; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 6).transform(((i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.isOpportunityDetailsLoading$)) === false) && _co.opportunityDetails$))); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.isOpportunityDetailsLoading$)) === false) && (i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 11).transform(_co.opportunityDetails$)) === null)); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_OpportunitiesDetailsContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-opportunities-details", [], null, null, null, View_OpportunitiesDetailsContainerComponent_0, RenderType_OpportunitiesDetailsContainerComponent)), i1.ɵdid(1, 245760, null, 0, i13.OpportunitiesDetailsContainerComponent, [i12.Store, i14.ActivatedRoute, i15.DialogService, i16.FormUtilitiesService, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OpportunitiesDetailsContainerComponentNgFactory = i1.ɵccf("app-opportunities-details", i13.OpportunitiesDetailsContainerComponent, View_OpportunitiesDetailsContainerComponent_Host_0, {}, {}, []);
export { OpportunitiesDetailsContainerComponentNgFactory as OpportunitiesDetailsContainerComponentNgFactory };
