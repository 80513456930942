<main>
  <div
    *ngIf="
      (isAppOnline$ | async) === true &&
      (hasApplicationFormSubmitted$ | async) === false &&
      (isOpportunitiesLoading$ | async) === false
    "
    class="opportunities__register"
  >
    <app-card-layout [appearance]="CardLayoutAppearanceEnum.Green">
      <app-card-layout-item>
        <app-page-section-header
          title="Ready to hit the road?"
          i18n-title="@@opportunities_RoadCrewApplicationTitle"
          subtitle="Fill out a Road Crew application once. Quick-apply to any opportunity in
        seconds."
          i18n-subtitle="@@opportunities_RoadCrewApplicationDescription"
          [appearance]="PageSectionHeaderAppearanceEnum.Green"
        ></app-page-section-header>
        <app-button-link
          label="Register for Road Crew"
          i18n-label="@@opportunities_registerForRoadCrew"
          (buttonClick)="openApplicationForm()"
        ></app-button-link>
      </app-card-layout-item>
    </app-card-layout>
  </div>
  <div
    *ngIf="
      (isAppOnline$ | async) === true &&
      (hasApplicationFormSubmitted$ | async) === true &&
      (isOpportunitiesLoading$ | async) === false &&
      (applicationFormStatus$ | async) === ApplicationFormStatusEnum.Approved
    "
    class="opportunities__register"
  >
    <app-card-layout [appearance]="CardLayoutAppearanceEnum.Gold">
      <app-card-layout-item>
        <app-page-section-header
          title="Road Crew Registration Approved"
          i18n-title="@@opportunities_RoadCrewApplicationApproved"
          subtitle="If you wish to update your information, please click on the link below"
          i18n-subtitle="@@opportunities_RoadCrewApplicationApprovedDescription"
          [appearance]="PageSectionHeaderAppearanceEnum.Blue"
        ></app-page-section-header>
        <app-button-link
          label="Update my details"
          i18n-label="@@opportunities_updateRoadCrewRegistration"
          (buttonClick)="openApplicationForm()"
        ></app-button-link>
      </app-card-layout-item>
    </app-card-layout>
  </div>
  <div
    *ngIf="
      (isAppOnline$ | async) === true &&
      (applicationFormStatus$ | async) === ApplicationFormStatusEnum.Applied
    "
    class="opportunities__register"
  >
    <app-card-layout [appearance]="CardLayoutAppearanceEnum.Blue">
      <app-card-layout-item>
        <app-page-section-header
          title="Registration under review"
          i18n-title="@@opportunities_RoadCrewApplicationUnderReview"
          subtitle="Your registration is being reviewed by your manager. Once approved, you can apply to Road Crew openings."
          i18n-subtitle="@@opportunities_ApplicationUnderReviewDescription"
          [appearance]="PageSectionHeaderAppearanceEnum.Blue"
        ></app-page-section-header>
      </app-card-layout-item>
    </app-card-layout>
  </div>
  <div
    *ngIf="
      (hasApplicationFormSubmitted$ | async) === true &&
      (isOpportunitiesLoading$ | async) === false &&
      ((applicationFormStatus$ | async) ===
        ApplicationFormStatusEnum.RejectedCanNotReapply ||
        (applicationFormStatus$ | async) ===
          ApplicationFormStatusEnum.RejectedCanReapply)
    "
    class="opportunities__register"
  >
    <app-card-layout [appearance]="CardLayoutAppearanceEnum.Gray">
      <app-card-layout-item>
        <app-page-section-header
          title="Registration on hold"
          i18n-title="@@opportunities_RoadCrewApplicationOnHold"
          subtitle="Your road crew registration was placed on hold by your manager. Speak to your manager to learn more."
          i18n-subtitle="@@opportunities_RoadCrewApplicationOnHoldDescription"
          [appearance]="PageSectionHeaderAppearanceEnum.Gray"
        ></app-page-section-header>
        <p
          *ngIf="(rejectionReason$ | async) !== ''"
          style="margin-bottom: 1rem !important;"
        >
          <b i18n="@@opportunities_rejectionReason">Rejection reason:</b>
          &nbsp;{{ rejectionReason$ | async }}
        </p>
        <app-button-link
          *ngIf="
            (applicationFormStatus$ | async) ===
            ApplicationFormStatusEnum.RejectedCanReapply
          "
          label="Re-register for Road Crew"
          i18n-label="@@opportunities_reRegisterForRoadCrew"
          (buttonClick)="openApplicationForm()"
        ></app-button-link>
      </app-card-layout-item>
    </app-card-layout>
  </div>
  <app-loader [isLoading]="isOpportunitiesLoading$ | async"></app-loader>

  <app-tab-group
    *ngIf="
      (isAppOnline$ | async) === true &&
      (isOpportunitiesLoading$ | async) === false
    "
  >
    <app-tab
      i18n-textLabel="@@opportunities_openings"
      textLabel="Openings"
      [isActive]="true"
    >
      <app-openings
        [openShifts]="openShifts$ | async"
        [roadCrewOpenings]="roadCrewOpenings$ | async"
        [roadCrewOpeningsCount]="roadCrewOpeningsCount$ | async"
        [associateHomeState]="associateHomeState"
        (openItem)="openItem($event)"
        (openAll)="onOpenAllList()"
      ></app-openings>
    </app-tab>
    <app-tab
      i18n-textLabel="@@opportunities_applications"
      textLabel="Applications"
    >
      <ng-container *appTabLabel="let tab = tab">
        <app-indicator *ngIf="hasUpdatedItems$ | async"></app-indicator>
        {{ tab.textLabel }}
      </ng-container>
      <app-applications
        [applicationUpdates]="updatedOpportunities$ | async"
        [pendingUpdates]="pendingOpportunities$ | async"
        (openItem)="openItem($event)"
      ></app-applications>
    </app-tab>
  </app-tab-group>

  <ng-template #appOfflineTransaltion i18n="@@road-crew-offline-message">
    Road Crew data could not be loaded. Check your connection and
    <a href="roadcrew">try again</a>
  </ng-template>

  <app-empty-state
    *ngIf="(isAppOnline$ | async) === false"
    [emptyStateTemplate]="appOfflineTransaltion"
  ></app-empty-state>
</main>
