import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NavigationActions, RouterActions } from '../actions';
import { map, pairwise, startWith, withLatestFrom } from 'rxjs/operators';
import { AppState, RouteSelectors } from '../../reducers';
import { Store } from '@ngrx/store';
import {
  selectIsNavigationInitialized,
  selectNavigationBackUrl
} from '../reducers/navigation.reducer';
import { SiteMapService } from '../../shared/services/site-map.service';

@Injectable()
export class NavigationEffects {
  routeChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routerNavigated),
      withLatestFrom(
        this.store
          .select(RouteSelectors.selectRouteData)
          .pipe(startWith(null), pairwise()),
        this.store.select(RouteSelectors.selectRouteParams),
        this.store.select(RouteSelectors.selectCurrentRoute),
        this.store.select(selectIsNavigationInitialized)
      ),
      map(
        ([
          action,
          [oldRouteData, newRouteData],
          routeParams,
          route,
          isNavigationInitialized
        ]) => {
          const navigation = this.router.getCurrentNavigation();
          if (!isNavigationInitialized) {
            const siteMapKey = newRouteData.siteMapKey;
            const parentPath = this.siteMapService.getParentPath(
              siteMapKey,
              routeParams
            );
            return NavigationActions.navigationInitialized({
              url: action.payload.event.url,
              parentsUrl: parentPath
            });
          }

          if (navigation.extras.state && navigation.extras.state.navigateBack) {
            return NavigationActions.navigatedBack();
          }
          const replaceUrl = navigation.extras.replaceUrl;
          const replaceAppUrl =
            replaceUrl ||
            this.shouldReplaceAppUrl(
              navigation.extras.state,
              oldRouteData,
              newRouteData
            );
          return NavigationActions.navigatedForward({
            url: action.payload.event.url,
            replaceAppUrl
          });
        }
      )
    )
  );

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.navigateBack),
        withLatestFrom(this.store.select(selectNavigationBackUrl)),
        map(([action, previousNavigationUrl]) => {
          if (previousNavigationUrl) {
            this.router.navigateByUrl(previousNavigationUrl, {
              replaceUrl: action.replaceUrl,
              state: {
                navigateBack: true
              }
            });
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private siteMapService: SiteMapService
  ) {}

  private shouldReplaceAppUrl(
    navigationState,
    oldRouteData,
    newRouteData
  ): boolean {
    const replaceAppUrl = navigationState && navigationState.replaceAppUrl;
    const oldSiteMapKey = oldRouteData ? oldRouteData.siteMapKey : null;
    const newSiteMapKey = newRouteData.siteMapKey;
    if (navigationState && navigationState.replaceAppUrl) {
      return replaceAppUrl;
    }

    if (!oldSiteMapKey) {
      return false;
    }
    const oldSiteMap = this.siteMapService.getSiteMapItem(oldSiteMapKey);
    const newSiteMap = this.siteMapService.getSiteMapItem(newSiteMapKey);

    if (!oldSiteMap.group && !newSiteMap.group) {
      return false;
    }
    return oldSiteMap.group === newSiteMap.group;
  }
}
