<div class="modal-header">
  <app-svg-icon *ngIf="iconName" [name]="iconName" class="modal-header__icon"></app-svg-icon>
  <h1 class="modal-header__heading" id="dialog-header" tabindex="0" cdkFocusInitial data-cy="earningsModalHeading">
    <span class="modal-header__first-line">{{ primaryText }}</span>

    <ng-template #defaultSecondaryTextTemplate>
      {{ secondaryText }}
    </ng-template>

    <ng-container *ngTemplateOutlet="secondaryTextTemplate ? secondaryTextTemplate : defaultSecondaryTextTemplate"></ng-container>
  </h1>
  <time *ngIf="date && !dateRange" class="modal-header__date" [attr.datetime]="date" data-cy="earningsModalDate">
    {{ date | date: 'MM/dd/yy' }}
  </time>
  <time *ngIf="dateRange && !date" class="modal-header__date" [attr.datetime]="dateRange" data-cy="earningsModalDate">{{ dateRange }}</time>
</div>
