<app-modal-layout (closeModal)="closeModal()">
  <app-modal-header
    slot="modal-header"
    primaryText="Update"
    i18n-primaryText="@@update-location_confirm"
    secondaryText="Location"
    i18n-secondaryText="@@update-location_location"
  ></app-modal-header>
  <div slot="modal-content" class="update-location">
    <form appRootForm [formGroup]="form">
      <app-modal-content-row [sideSpacing]="true">
        <app-form-field [required]="true">
          <app-label i18n="@@update-location_preferredAirport">
            Preferred Airport Departure
          </app-label>
          <app-autocomplete
            [formControl]="form.get('preferredAirport')"
            [data]="airports"
            displayKey="display"
            i18n-placeholder="@@update-location_preferredAirportPlaceholder"
            placeholder="City, Airport Name, or Airport Code"
          ></app-autocomplete>
          <app-validation [errors]="form.get('preferredAirport').errors">
            <app-validation-message
              *ngIf="form.get('preferredAirport').errors?.required"
              i18n="@@update-location_preferredAirportRequired"
            >
              Enter preferred departure airport
            </app-validation-message>
          </app-validation>
        </app-form-field>
        <p class="update-location__notice" i18n="@@update-location_explanation">
          This helps us book the correct travel plans for you.
        </p>
      </app-modal-content-row>
      <div class="update-location__actions">
        <app-button-link
          (buttonClick)="updateAirport()"
          label="Update & Apply"
          i18n-label="@@update-location_update"
          [appearance]="ButtonAppearanceEnum.Link"
          [type]="ButtonTypeEnum.Submit"
        ></app-button-link>
      </div>
    </form>
  </div>
</app-modal-layout>
