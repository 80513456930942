import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MultiStepFormComponent } from '../../../../../shared/components/multi-step-form/multi-step-form.component';
import { SkipCertificationsComponent } from '../../components/skip-certifications/skip-certifications.component';
import { RoadCrewApplication } from '../../../../../core/dataEntities/applicationForm/roadCrewApplication';
import { OpportunitiesActions } from '../../actions';
import {
  CancelApplicationComponent,
  CancelType
} from '../../components/cancel-application/cancel-application.component';
import { LayoutActions, NavigationActions } from '../../../../../core/actions';
import { ApplicationFormService } from '../../services/application-form.service';
import { Store } from '@ngrx/store';
import { AppState, getHasRoadCrewAccess } from '../../../../../reducers';
import { DialogService } from '../../../../../shared/modules/modal/dialog/dialog.service';
import { FormGroup } from '@angular/forms';
import { selectApplicationForm } from '../../reducers/opportunities.reducer';
import { catchError, finalize, first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PageHeaderAppearance } from '../../../../../shared/enums/PageHeader';
import { PageTitleAppearance } from '../../../../../shared/enums/PageTitle';
import {
  ButtonAppearance,
  ButtonIconSize,
  ButtonType
} from '../../../../../shared/enums/ButtonLink';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import { StaticContentService } from '../../../../../core/contracts/StaticContentService';
import { RoadCrewStaticContent } from '../../../../../shared/enums/RoadCrewStaticContent';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../../../../shared/error-handlers/catch-app-error';
import { Router } from '@angular/router';

@Component({
  selector: 'app-road-crew-application-container',
  templateUrl: './road-crew-application-container.component.html',
  styleUrls: ['./road-crew-application-container.component.scss']
})
export class RoadCrewApplicationContainerComponent
  implements OnInit, OnDestroy {
  private applicationFormEdited = false;
  private destroySubject = new Subject();

  public applicationForm: FormGroup;
  public PageHeaderAppearanceEnum = PageHeaderAppearance;
  public PageTitleAppearanceEnum = PageTitleAppearance;
  public ButtonIconSizeEnum = ButtonIconSize;
  public ButtonAppearanceEnum = ButtonAppearance;
  public ButtonTypeEnum = ButtonType;
  public SvgIconNameEnum = SvgIconName;
  public staticContent = null;

  constructor(
    private applicationFormService: ApplicationFormService,
    private store: Store<AppState>,
    private dialogService: DialogService,
    private staticContentService: StaticContentService,
    private router: Router,
    @Inject(APP_ERROR_MESSAGES) private errorMessages
  ) {}

  ngOnInit() {
    this.store.dispatch(OpportunitiesActions.loadApplicationForm());
    this.store
      .select(selectApplicationForm)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(form => {
        this.applicationForm = this.applicationFormService.getApplicationFormGroup(
          form
        );
      });
    this.applicationForm.valueChanges.pipe(first()).subscribe(() => {
      this.applicationFormEdited = true;
    });

    this.store
      .select(getHasRoadCrewAccess)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(value => {
        if (!value) {
          this.router.navigate(['news']);
        }
      });

    this.loadStaticContent();
  }

  loadStaticContent() {
    this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }));
    this.staticContentService
      .getRoadCrewStaticContent([
        RoadCrewStaticContent.JobTitles,
        RoadCrewStaticContent.TermsAndConditions,
        RoadCrewStaticContent.Certificates
      ])
      .pipe(
        finalize(() =>
          this.store.dispatch(
            LayoutActions.toggleAppLoading({ loading: false })
          )
        ),
        catchError(handleAppError(this.errorMessages))
      )
      .subscribe(result => {
        this.staticContent = result;
      });
  }

  onStepOutFromCertifications(multiStepForm: MultiStepFormComponent) {
    if (
      !(
        this.applicationForm.get('certifications').value.certificationsChecked
          .length ||
        this.applicationForm.get('certifications').value
          .additionalCertifications
      )
    ) {
      this.dialogService
        .open(SkipCertificationsComponent)
        .afterClosed()
        .subscribe(result => {
          if (result.success) {
            multiStepForm.next();
          }
        });
    } else {
      multiStepForm.next();
    }
  }

  submitForm() {
    if (this.applicationForm.valid) {
      const applicationFormObject: RoadCrewApplication = this.applicationFormService.getApplicationFormObject(
        this.applicationForm
      );

      this.store.dispatch(
        OpportunitiesActions.submitApplicationForm({
          form: applicationFormObject
        })
      );
    }
  }

  openModal() {
    if (this.applicationFormEdited) {
      this.dialogService
        .open(CancelApplicationComponent, {
          data: {
            cancelType: CancelType.RoadCrewApplication
          }
        })
        .afterClosed()
        .subscribe(result => {
          if (result && result.success) {
            this.store.dispatch(
              NavigationActions.navigateBack({
                replaceUrl: true
              })
            );
          }
        });
    } else {
      this.store.dispatch(
        NavigationActions.navigateBack({
          replaceUrl: true
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next(null);
    this.destroySubject.complete();
    this.destroySubject = null;
  }
}
