<app-modal-layout (closeModal)="closeModal()">
  <ng-template
    #taskNumberTemplate
    i18n="@@service-earning-details-card_taskNumber"
  >
    PO: {{ taskDetails.po }}
  </ng-template>

  <app-modal-header
    slot="modal-header"
    i18n-primaryText="@@service-earning-details-card_selectingDetails"
    primaryText="Selecting Details"
    [secondaryTextTemplate]="taskNumberTemplate"
    [date]="taskDetails.date"
    [iconName]="SvgIconNameEnum.Boxes"
  ></app-modal-header>

  <div slot="modal-content">
    <app-modal-content-row>
      <app-desc-list>
        <app-desc-list-item
          [dataCy]="'siteName'"
          title="Site name"
          i18n-title="@@siteName"
          [items]="taskDetails.siteName"
        ></app-desc-list-item>
        <ng-template #oneAssociate i18n="@@associateOnTask">
          1 associate on task
        </ng-template>
        <ng-template #multipleAssociates i18n="@@associatesOnTask">
          {{ taskDetails.associatesOnTask.length }} associates on task
        </ng-template>
        <app-desc-list-item
          [dataCy]="'associatesOnTask'"
          [titleTemplate]="
            taskDetails.associatesOnTask.length > 1
              ? multipleAssociates
              : oneAssociate
          "
          [items]="taskDetails.associatesOnTask"
          [scrollable]="true"
        ></app-desc-list-item>
      </app-desc-list>
      <app-desc-list>
        <app-desc-list-item
          [dataCy]="'taskDetailsDock'"
          title="Dock"
          i18n-title="@@dock"
          [items]="taskDetails.dock"
          [columns]="2"
        ></app-desc-list-item>
      </app-desc-list>
    </app-modal-content-row>

    <table
      cdk-table
      [dataSource]="tableData.cellData"
      class="table table--small"
    >
      <caption
        class="visually-hidden"
        i18n="@@service-earning-details-card_jobDetails"
      >
        Job Details
      </caption>
      <ng-container cdkColumnDef="type">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          scope="col"
          i18n="@@service-earning-details-card_type"
        >
          Type
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="table__cell table__cell--bold"
        >
          <ng-container *ngIf="element.type === 'case-count'">
            <ng-container i18n="@@cases">Cases</ng-container>
          </ng-container>
          <ng-container *ngIf="element.type === 'pallet-count'">
            <ng-container i18n="@@pallets">Pallets</ng-container>
          </ng-container>
          <ng-container *ngIf="element.type === 'net-revenue'">
            <ng-container i18n="@@netRevenue">Net Revenue</ng-container>
          </ng-container>
          <ng-container *ngIf="element.type === 'associate-pay'">
            <ng-container i18n="@@pay%">Pay %</ng-container>
          </ng-container>
        </td>
        <td
          cdk-footer-cell
          *cdkFooterCellDef
          class="table__cell table__cell--bold"
        >
          <ng-container i18n="@@finalJobEarnings">
            Final Job Earnings
          </ng-container>
        </td>
      </ng-container>
      <ng-container cdkColumnDef="amount">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          scope="col"
          class="table__cell table__cell--number"
          i18n="@@selecting-details_data"
        >
          Data
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="table__cell table__cell--number"
        >
          <ng-container *ngIf="element.isCurrency">
            {{ element.amount | currency }}
          </ng-container>
          <ng-container *ngIf="element.isPercentage">
            {{ element.amount | number: '1.2-2' }}%
          </ng-container>
          <ng-container
            *ngIf="
              element.isPercentage === false && element.isCurrency === false
            "
          >
            {{ element.amount | number }}
          </ng-container>
        </td>
        <td
          cdk-footer-cell
          *cdkFooterCellDef
          class="table__cell table__cell--number"
        >
          {{ tableData.footerData | currency }}
        </td>
      </ng-container>

      <tr
        cdk-header-row
        *cdkHeaderRowDef="columns"
        [ngStyle]="{ display: 'none' }"
      ></tr>
      <tr cdk-row *cdkRowDef="let row; columns: columns"></tr>
      <tr cdk-footer-row *cdkFooterRowDef="columns"></tr>
    </table>
  </div>
</app-modal-layout>
