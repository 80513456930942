import { Observable } from 'rxjs';
import { RoadCrewApplicationResponse } from '../responses/application-form/roadCrewApplicationResponse';

export abstract class RoadCrewApplicationService {
  abstract getApplication(
    associateId: number
  ): Observable<RoadCrewApplicationResponse>;

  abstract submitApplication(associateId: number, body: any): Observable<any>;
}
