import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DepartmentInfo } from '../../core/responses/managers/subDepartmentsResponse';
import { AppState, getAuthToken } from '../../reducers';
import { first, switchMap } from 'rxjs/operators';
import { ApiGatewayService } from './api.gateway.service';
import { Store } from '@ngrx/store';

@Injectable()
export class SiteService {
  constructor(
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>
  ) {}

  getSiteInfo(siteId: number): Observable<DepartmentInfo> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.get<DepartmentInfo>(
          `sites/${siteId}`,
          authToken
        );
      })
    );
  }
}
