import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { InfoButtonComponent } from './info-button.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { InfoBoxContainerComponent } from './info-box/info-box-container.component';
import { SharedComponentsModule } from '../../components/shared-components/shared-components.module';
import { InfoBoxService } from './info-box.service';

@NgModule({
  declarations: [
    InfoButtonComponent,
    InfoBoxContainerComponent,
    InfoBoxComponent
  ],
  entryComponents: [InfoBoxComponent, InfoBoxContainerComponent],
  imports: [CommonModule, OverlayModule, PortalModule, SharedComponentsModule],
  exports: [InfoButtonComponent],
  providers: [InfoBoxService]
})
export class InfoBoxModule {}
