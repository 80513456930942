import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { EnvironmentService } from '../services/environment.service';

@Directive({
  selector: '[appFeatureToggle]'
})
export class FeatureToggleDirective implements OnInit {
  @Input() appFeatureToggle: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private environmentService: EnvironmentService
  ) {}

  ngOnInit() {
    if (this.isEnabled()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  isEnabled() {
    const { features } = this.environmentService.getEnvironmentConfig();

    const isInversion = this.appFeatureToggle.charAt(0) === '!';
    const featureName = isInversion
      ? this.appFeatureToggle.substr(1)
      : this.appFeatureToggle;

    return features[featureName] != null
      ? isInversion
        ? !features[featureName]
        : features[featureName]
      : true;
  }
}
