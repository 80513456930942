<app-feed-card-layout
  [post]="post"
  [ariaSetsize]="ariaSetsize"
  [ariaPosinset]="ariaPosinset"
  [isPinnedPost]="isPinnedPost"
  [isExtended]="isExtended"
  [extendableBlogPost]="extendableBlogPost"
>
  <ng-container slot="actions">
    <button
      *ngIf="showLike"
      type="button"
      [ngClass]="{
        like: true,
        'like--pressed': post.isLiked
      }"
      [attr.aria-pressed]="post.isLiked"
      (click)="!previewMode && like()"
    >
      <span class="like__title">{{ post.likes }}</span>
      <app-svg-icon
        [name]="SvgIconNameEnum.Like"
        class="like__icon"
      ></app-svg-icon>
    </button>
    <app-button-link
      class="read-more"
      data-cy="newsLinkExternal"
      *ngIf="
        post.linkType === PostLinkTypeEnum.External &&
        post.category !== PostCategoryTypeEnum.SafetyTip
      "
      i18n-label="@@readMore"
      label="Read more"
      [isExternalUrl]="true"
      [link]="previewMode ? '' : post.externalPostUrl"
      [iconName]="SvgIconNameEnum.External"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>
    <ng-template #showMoreTemplate i18n="@@news-feed-card_showMore">
      Show More
    </ng-template>
    <ng-template #showLessTemplate i18n="@@news-feed-card_showLess">
      Show Less
    </ng-template>
    <app-button-link
      class="read-more"
      *ngIf="extendableBlogPost"
      [labelTemplate]="isExtended ? showLessTemplate : showMoreTemplate"
      [appearance]="ButtonAppearanceEnum.Link"
      (buttonClick)="isExtended = !isExtended"
    ></app-button-link>

    <app-button-link
      class="read-more"
      data-cy="newsLinkInternal"
      *ngIf="
        post.linkType === PostLinkTypeEnum.Internal &&
        post.category !== PostCategoryTypeEnum.SafetyTip
      "
      i18n-label="@@readMore"
      label="Read more"
      [link]="
        previewMode
          ? ''
          : FeedTypeEnum.News === FeedType
          ? ['/news', post.id]
          : ['/resources/stars', post.id]
      "
      [iconName]="SvgIconNameEnum.ArrowRightFilled"
      [iconSize]="ButtonIconSizeEnum.Small"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>
  </ng-container>
</app-feed-card-layout>
