import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { SliderAppearance } from '../../enums/Slider';
import { GoalValidation } from '../../models/GoalValidation';
import { ValidationStatus } from '../../enums/ValidationStatus';
import { GoalValidationRange } from '../../enums/GoalValidationRange';
import { MathHelpers } from 'src/app/shared/helpers/math.helpers';
import { GoalStoreService } from '../../services/goal.store-service';
import { AnalyticsEvents } from '../../../../../shared/enums/AnalyticsEvents';

@Component({
  selector: 'app-goal-input-card',
  templateUrl: './goal-input-card.component.html',
  styleUrls: ['./goal-input-card.component.scss'],
  providers: []
})
export class GoalInputCardComponent implements OnInit, OnChanges {
  public goalTypesByValue = {};
  appearance = SliderAppearance.Default;
  previousValue = null;
  maxLimit = null;
  @Input() formGroup: FormGroup;
  @Input() goalType: string;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() siteAverage: number;
  @Input() associateAverage: number;
  @Input() infoBoxText: string;
  @Input() isGoalValid: boolean;
  @Input() averageFormat: string;
  @Input() siteAverageFormat: string;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() validation: GoalValidation;
  @Input() maxLimitPercent: number;
  GoalValidationStatusEnum = ValidationStatus;
  GoalValidationRangeEnum = GoalValidationRange;
  AnalyticsEventsEnum = AnalyticsEvents;
  private goalTypesItems: any[];

  constructor(public goalStoreService: GoalStoreService) {}

  ngOnInit() {
    this.maxLimit = this.setMaxSliderLimit(
      this.associateAverage,
      this.siteAverage,
      this.maxLimitPercent,
      this.step
    );
  }

  ngOnChanges() {
    this.maxLimit = this.setMaxSliderLimit(
      this.associateAverage,
      this.siteAverage,
      this.maxLimitPercent,
      this.step
    );
  }

  get goalTypes(): any[] {
    return this.goalTypesItems;
  }

  @Input()
  set goalTypes(value: any[]) {
    this.goalTypesItems = value;
    this.goalTypesByValue = _.keyBy(value, 'value');
  }

  get hasSingleGoalType() {
    return this.goalTypes && this.goalTypes.length === 1;
  }

  get hasMultipleGoalTypes() {
    return this.goalTypes && this.goalTypes.length > 1;
  }

  onGoalFocusEvent(event) {
    if (event && event.target) {
      this.previousValue = this.formGroup.get(this.goalType).value;
    }
  }

  onGoalTypeValueBlur(event) {
    if (event && event.target) {
      let value = event.target.value;
      if (
        (value < this.min || value > Math.min(this.max, this.maxLimit)) &&
        this.previousValue
      ) {
        value = this.previousValue;
      } else if (value < this.min) {
        value = this.min;
      } else if (value > this.max) {
        value = this.max;
      }
      this.formGroup.get(this.goalType).setValue(value);
      this.previousValue = null;
    }
  }

  checkIfHasFlag(value, requestedValue) {
    /* tslint:disable:no-bitwise */
    return value & requestedValue;
    /* tslint:enable:no-bitwise */
  }

  updateFormValue(value) {
    this.formGroup.get(this.goalType).patchValue(value);
  }

  setMaxSliderLimit(associateAverage, siteAverage, maxLimitPercent, step) {
    const largerValue = Math.max(associateAverage, siteAverage);
    let maxLimit =
      largerValue +
      MathHelpers.calculatePercentage(largerValue, maxLimitPercent);
    maxLimit = Number((Math.round(maxLimit / step) * step).toFixed(2));
    return maxLimit;
  }
}
