<app-earnings-header
  [isFutureProcessing]="isFutureProcessing$ | async"
  [isHistoricalEarning]="isHistoricalEarning$ | async"
  [weekPayDetails]="weekPayDetails$ | async"
></app-earnings-header>
<main class="main-container training">
  <app-earnings-summary
    [weekPayDetails]="weekPayDetails$ | async"
  ></app-earnings-summary>

  <app-earnings-tabs
    *ngIf="(isPayLoading$ | async) === false"
    class="training__tabs"
    [isHistoricalEarning]="isHistoricalEarning$ | async"
    [isTrainee]="true"
    [goal]="null"
  ></app-earnings-tabs>

  <app-loader
    [isLoading]="(isPayLoading$ | async) || (isProductionLoading$ | async)"
  ></app-loader>

  <div
    *ngIf="
      (isPayLoading$ | async) === false &&
      (isProductionLoading$ | async) === false
    "
    class="training__container"
  >
    <app-card-layout-timeline
      *ngIf="productionDetails$ | async as productionDetails"
      [trainingStatus]="trainingStatus$ | async"
      class="training__timeline"
    >
      <app-card-layout-item *ngIf="trainingStatus$ | async as trainingStatus">
        <div class="training__heading-container">
          <h2 class="training__title" i18n="@@training_titleHeader">
            Week
            {{
              productionDetails.associateInfo.trainingStatus | traineeWorkWeek
            }}
            training
          </h2>
          <p class="training__subtitle" i18n="@@training_subtitleHeader">
            {{ (weekPayDetails$ | async).weekStartingDate | date: 'MMM. d' }}
            -
            {{ (weekPayDetails$ | async).weekEndingDate | date: 'MMM. d' }}
            |
            {{ productionDetails.associatePaySplit * 100 | number: '1.0-0' }} %
            Split
          </p>
          <p
            i18n="@@training_descriptionWeekOne"
            *ngIf="trainingStatus === TrainingStatusEnum.WeekOneTraining"
          >
            Welcome to Capstone! This week you’ll focus getting started on the
            warehouse floor and learning the ropes.
          </p>
          <p
            i18n="@@training_descriptionWeekTwo"
            *ngIf="trainingStatus === TrainingStatusEnum.WeekTwoTraining"
          >
            Congrats on finishing your first week strong! Your trainee split %
            has been increased. Keep it up!
          </p>
          <p
            i18n="@@training_descriptionWeekThree"
            *ngIf="trainingStatus === TrainingStatusEnum.WeekThreeTraining"
          >
            You’re almost half way through your training program! Keep up the
            hard work.
          </p>
          <p
            i18n="@@training_descriptionWeekFour"
            *ngIf="trainingStatus === TrainingStatusEnum.WeekFourTraining"
          >
            Congratulations! From now on, you’re earning an even split on all
            shared tasks.
          </p>
          <p
            i18n="@@training_descriptionWeekFive"
            *ngIf="trainingStatus === TrainingStatusEnum.WeekFiveTraining"
          >
            2 weeks left! Focus on maximizing your productivity and get close to
            the site average.
          </p>
          <p
            i18n="@@training_descriptionWeekSix"
            *ngIf="trainingStatus === TrainingStatusEnum.WeekSixTraining"
          >
            You are only days away from being a fully-trained Capstone
            Associate. You’re almost there.
          </p>
        </div>
      </app-card-layout-item>
      <app-card-layout-item>
        <app-page-section-header
          i18n-title="@@training_averageEarningTitle"
          title="Average earnings per task"
        ></app-page-section-header>
        <p i18n="@@training_averageEarningsSubtitle">
          Over time, you’ll learn how each task earns money based on the load
          type and your productivity.
        </p>
        <app-stats>
          <app-stats-item
            [value]="tasksCount$ | async"
            numberFormat="1.0-0"
            i18n-title="@@training_tasksCompleted"
            title="Tasks Completed This Week"
            [appearance]="StatsAppearanceEnum.Blue"
          ></app-stats-item>
          <app-stats-item
            [value]="
              (tasksCount$ | async) != 0
                ? (weekPayDetails$ | async).totalEarnings /
                  (tasksCount$ | async)
                : 0
            "
            valuePrefix="$"
            numberFormat="1.2-2"
            [coloredSuffixPrefix]="true"
            i18n-title="@@training_averageEarningsPerTask"
            title="Average Earnings Per Task"
            [appearance]="StatsAppearanceEnum.Blue"
          ></app-stats-item>
        </app-stats>
      </app-card-layout-item>
      <app-card-layout-item>
        <app-page-section-header
          i18n-title="@@training_productivityGoalsTitle"
          title="Trainee productivity goals"
        ></app-page-section-header>
        <p i18n="@@training_productivityGoalsSubtitle">
          Each week your goals will increase. After week 6, your goals will
          match the average associate.
        </p>

        <app-simple-select
          class="training__select"
          [options]="options"
          [(value)]="activeItemType"
          (valueChange)="onChange($event)"
        ></app-simple-select>

        <ng-template #palletsHour i18n="@@training_palletsHour">
          Pallets/hour
        </ng-template>

        <ng-template #casesHour i18n="@@training_casesHour">
          Cases/hour
        </ng-template>

        <ng-template #goalGaugeTemplate i18n-title="@@training_gaugeTitle">
          <ng-container i18n="@@training_yourAvg">
            Your avg.
          </ng-container>
          <ng-container [ngSwitch]="activeItemType">
            <ng-container
              *ngSwitchCase="ItemTypeEnum.Pallets"
              [ngTemplateOutlet]="palletsHour"
            ></ng-container>
            <ng-container
              *ngSwitchCase="ItemTypeEnum.Cases"
              [ngTemplateOutlet]="casesHour"
            ></ng-container>
          </ng-container>
        </ng-template>

        <app-goal-gauge
          [current]="productionDetails.currentWeekProductivity.palletsPerHour"
          [max]="productionDetails.currentWeekProductivity.palletsPerHourGoal"
          [labelTemplate]="goalGaugeTemplate"
          suffix="avg."
          *ngIf="activeItemType === ItemTypeEnum.Pallets"
        ></app-goal-gauge>
        <app-goal-gauge
          [current]="productionDetails.currentWeekProductivity.casesPerHour"
          [max]="productionDetails.currentWeekProductivity.casesPerHourGoal"
          [labelTemplate]="goalGaugeTemplate"
          *ngIf="activeItemType === ItemTypeEnum.Cases"
          suffix="avg."
        ></app-goal-gauge>

        <p class="training__goal-text">
          <strong
            class="training__goal-text-bold"
            i18n="@@training_goalAverageText"
          >
            Week
            {{
              productionDetails.associateInfo.trainingStatus | traineeWorkWeek
            }}
            Goal:
            {{ productionDetails.currentWeekProductivity.goalPercentage }}% of
            site Average
          </strong>

          <ng-container
            *ngIf="
              activeItemType === ItemTypeEnum.Pallets &&
              productionDetails.siteProductivityAverage.productivityMetrics
                .palletsPerHour
            "
          >
            The site average is
            {{
              productionDetails.siteProductivityAverage.productivityMetrics
                .palletsPerHour | number: '1.2-2'
            }}
            <ng-container [ngTemplateOutlet]="palletsHour"></ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              activeItemType === ItemTypeEnum.Cases &&
              productionDetails.siteProductivityAverage.productivityMetrics
                .casesPerHour
            "
          >
            The site average is
            {{
              productionDetails.siteProductivityAverage.productivityMetrics
                .casesPerHour | number: '1.2-2'
            }}
            <ng-container [ngTemplateOutlet]="casesHour"></ng-container>
          </ng-container>
        </p>
      </app-card-layout-item>
      <app-card-layout-item>
        <app-goal-gauge
          [current]="productionDetails.currentWeekProductivity.hours"
          [max]="40"
          i18n="@@training_hoursWorked"
          label="Hours Worked"
          suffix="so far"
        ></app-goal-gauge>
        <p class="training__goal-text">
          <strong class="training__goal-text-bold" i18n="@@training_hoursGoal">
            Hours are set by your manager
          </strong>
          <span i18n="@@training_talkToManager">
            Talk to your manager if you have questions with your hours or
            schedule.
          </span>
        </p>
      </app-card-layout-item>
    </app-card-layout-timeline>

    <app-weekly-training
      [trainingStatus]="trainingStatus$ | async"
    ></app-weekly-training>

    <app-footer>
      <app-footer-note>
        <ng-container i18n="@@lastUpdated">Last updated</ng-container>
        {{ ' ' }}
        <time [attr.datetime]="(weekPayDetails$ | async).lastUpdatedAtDate">
          {{ (weekPayDetails$ | async).lastUpdatedAtDate | date }}
        </time>
      </app-footer-note>
    </app-footer>
  </div>
</main>
