import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { LayoutActions } from '../../core/actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

@Injectable()
export class UpdatePromptService {
  constructor(updates: SwUpdate, private store: Store<AppState>) {
    updates.available.subscribe(event => {
      this.store.dispatch(LayoutActions.showAlertUpdate());
    });
  }
}
