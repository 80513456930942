<app-svg-defs></app-svg-defs>
<app-layout
  [isDrawerOpen]="showSidenav$ | async"
  (drawerClosed)="closeSidenav()"
  *ngIf="(isUserLoggingOut$ | async) === false"
>
  <app-sidebar slot="drawer" *ngIf="isUserLoggedIn$ | async" data-cy="sidebar">
    <ng-container slot="header">
      <app-profile-header-container
        *ngIf="isUserLoggedIn$ | async"
      ></app-profile-header-container>
    </ng-container>
    <div slot="content">
      <ul class="reset-list-style">
        <ng-container *ngIf="userApexId$ | async">
          <app-profile-link-item
            *appIfHasPermission="[
              Roles.Associate,
              Roles.SiteLeads,
              Roles.SiteClerks
            ]"
            role="listitem"
            [iconName]="SvgIconName.QRCode"
            [iconColorPrimary]="SvgIconColor.Lochmara"
            title="Employee QR Code"
            i18n-title="@@app_employeeQrCode"
            data-cy="myProfileLinkItem"
            (buttonClick)="showQRCode()"
          ></app-profile-link-item>
        </ng-container>
        <app-profile-link-item
          role="listitem"
          [link]="'resources'"
          [iconName]="SvgIconName.PersonWQuestionMark"
          [iconColorPrimary]="SvgIconColor.Saffron"
          [iconColorSecondary]="SvgIconColor.SweetCorn"
          title="Resources & FAQ"
          i18n-title="@@app_resourcesLink"
          data-cy="resourcesLinkItem"
          (linkClick)="closeSidenav()"
        ></app-profile-link-item>
        <app-profile-link-item
          role="listitem"
          [link]="'news'"
          [iconName]="SvgIconName.Newspaper"
          [iconColorPrimary]="SvgIconColor.Lochmara"
          [iconColorSecondary]="SvgIconColor.Spindle"
          title="Newsfeed"
          i18n-title="@@app_newsfeedLink"
          (linkClick)="closeSidenav()"
          data-cy="newsFeedLinkItem"
        ></app-profile-link-item>
        <app-profile-link-item
          role="listitem"
          [link]="'wages'"
          [iconName]="SvgIconName.BanknoteBold"
          [iconColorPrimary]="SvgIconColor.MountainMeadow"
          [iconColorSecondary]="SvgIconColor.MountainMeadow"
          title="My earnings"
          i18n-title="@@app_myEarningsLink"
          (linkClick)="closeSidenav()"
          data-cy="myEarningsLinkItem"
        ></app-profile-link-item>
        <app-profile-link-item
          role="listitem"
          *ngIf="isMobileSafari()"
          [link]="'/add-pwa'"
          [iconName]="SvgIconName.PwaNotification"
          [iconColorPrimary]="SvgIconColor.Lochmara"
          [iconColorSecondary]="SvgIconColor.Spindle"
          title="Add MyCapstone to home"
          i18n-title="@@app_pwaNotificationLink"
          (linkClick)="closeSidenav()"
        ></app-profile-link-item>
      </ul>

      <ng-container
        *appIfHasPermission="[Roles.SiteManager, Roles.SiteSupervisor]"
      >
        <h2 class="manager__title" i18n="@@app_managerMenuTitle">
          Manager Tools
        </h2>

        <ng-template #shiftLink>
          <span i18n="@@app_shiftLink">
            Fill a Shift
          </span>
          <span *ngIf="shiftCount$ | async" i18n="@@app_postsActive">
            ({{ shiftCount$ | async }} {shiftCount$ | async, plural, =1 {post}
            other {posts}} active)
          </span>
        </ng-template>

        <ng-template #myTeamLink i18n="@@app_myTeam">My Team</ng-template>

        <ul class="reset-list-style">
          <app-profile-link-item
            role="listitem"
            [link]="'shifts'"
            [iconName]="SvgIconName.Shift"
            [iconColorPrimary]="SvgIconColor.Cinnabar"
            [iconColorSecondary]="SvgIconColor.MonaLisa"
            [appearance]="ProfileLinkItemAppearance.Red"
            [titleTemplate]="shiftLink"
            data-cy="shiftLinkItem"
            (linkClick)="closeSidenav()"
          ></app-profile-link-item>
          <app-profile-link-item
            role="listitem"
            [link]="'my-team'"
            [iconName]="SvgIconName.MyTeam"
            [iconColorPrimary]="SvgIconColor.Cinnabar"
            [iconColorSecondary]="SvgIconColor.MonaLisa"
            [appearance]="ProfileLinkItemAppearance.Red"
            [titleTemplate]="myTeamLink"
            data-cy="myTeamLinkItem"
            (linkClick)="closeSidenav()"
          ></app-profile-link-item>
        </ul>
      </ng-container>
    </div>
  </app-sidebar>

  <app-notification-bar
    *ngIf="appHasNotification$ | async"
    [errorNotification]="errorMessage$ | async"
    [infoNotification]="infoMessage$ | async"
    [noActionNotification]="noActionNotification$ | async"
    [successNotification]="successNotification$ | async"
    (dismissError)="onDismissError()"
    (dismissInfo)="onDismissInfo()"
    (dismissSuccess)="onDismissSuccess()"
  ></app-notification-bar>
  <app-menu-bar
    *ngIf="showMenuBar$ | async"
    (openMenu)="openSidenav()"
  ></app-menu-bar>
  <router-outlet></router-outlet>
</app-layout>
<app-loader
  [isLoading]="true"
  [isFullHeight]="true"
  *ngIf="isUserLoggingOut$ | async"
></app-loader>
