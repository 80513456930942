import { BLOCKS } from '@contentful/rich-text-types';

export const DateFormat = 'YYYY-MM-DDTHH:mm:ss';
export const IdDateFormat = 'YYYY-MM-DD';

export const KeyboardKeys = {
  ESCAPE: 'Escape'
};

export const RichTextHtmlRendererOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, next) =>
      `<p class="post__paragraph">${next(node.content).replace(
        /\n/g,
        '<br/>'
      )}</p>`
  }
};
