import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabComponent } from './tab/tab.component';
import { PortalModule } from '@angular/cdk/portal';
import { TabLabelDirective } from './tab-label.directive';
import { A11yModule } from '@angular/cdk/a11y';
import { TabLabelWrapperDirective } from './tab-label-wrapper.directive';
import { TabHeaderComponent } from './tab-header/tab-header.component';

@NgModule({
  declarations: [
    TabGroupComponent,
    TabComponent,
    TabLabelDirective,
    TabLabelWrapperDirective,
    TabHeaderComponent
  ],
  exports: [TabGroupComponent, TabComponent, TabLabelDirective],
  imports: [CommonModule, PortalModule, A11yModule]
})
export class TabsModule {}
