<div class="multi-step-readonly">
  <div>
    <h2 class="multi-step-readonly__heading">{{ title }}</h2>
    <ng-content *ngIf="showDetails"></ng-content>
  </div>
  <button
    *ngIf="isStepEditable"
    class="multi-step-readonly__edit"
    (click)="editStep.emit($event)"
    type="button"
  >
    Edit
  </button>
</div>
