/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../profile/components/profile-link-item/profile-link-item.component.ngfactory";
import * as i3 from "../../../profile/components/profile-link-item/profile-link-item.component";
import * as i4 from "./sidebar.component";
import * as i5 from "@ngrx/store";
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
export function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { drawer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "sidenav__header"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "sidenav__content"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "sidenav__footer footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "footer__bottom-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "ul", [["class", "reset-list-style"], ["slot", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-profile-link-item", [["data-cy", "settings"], ["role", "listitem"], ["title", "Settings"]], null, null, null, i2.View_ProfileLinkItemComponent_0, i2.RenderType_ProfileLinkItemComponent)), i1.ɵdid(9, 114688, null, 0, i3.ProfileLinkItemComponent, [], { title: [0, "title"], link: [1, "link"], iconName: [2, "iconName"], iconColorPrimary: [3, "iconColorPrimary"], iconColorSecondary: [4, "iconColorSecondary"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-profile-link-item", [["data-cy", "logout"], ["role", "listitem"], ["title", "Log Out"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ProfileLinkItemComponent_0, i2.RenderType_ProfileLinkItemComponent)), i1.ɵdid(11, 114688, null, 0, i3.ProfileLinkItemComponent, [], { title: [0, "title"], iconName: [1, "iconName"], iconColorPrimary: [2, "iconColorPrimary"], iconColorSecondary: [3, "iconColorSecondary"] }, { buttonClick: "buttonClick" }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "footer__version"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Settings"; var currVal_1 = "settings"; var currVal_2 = _co.SvgIconNameEnum.Settings; var currVal_3 = _co.SvgIconColorEnum.Horizon; var currVal_4 = _co.SvgIconColorEnum.Spindle; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "Log Out"; var currVal_6 = _co.SvgIconNameEnum.Logout; var currVal_7 = _co.SvgIconColorEnum.Horizon; var currVal_8 = _co.SvgIconColorEnum.Spindle; _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.appVersion; _ck(_v, 13, 0, currVal_9); }); }
export function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i4.SidebarComponent, [i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i1.ɵccf("app-sidebar", i4.SidebarComponent, View_SidebarComponent_Host_0, { isOpen: "isOpen" }, {}, ["[slot='header']", "[slot='content']"]);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
