<app-modal-layout (closeModal)="closeModal()">
  <app-modal-header
    slot="modal-header"
    primaryText="Hourly Earnings"
    i18n-primaryText="@@hourly-earnings-details_hourlyEarnings"
    secondaryText="BreakDown"
    i18n-secondaryText="@@hourly-earnings-details_breakDown"
    [date]="taskDetails.date"
  ></app-modal-header>

  <div slot="modal-content">
    <app-modal-content-row [sideSpacing]="true">
      <p i18n="@@hourly-earnings-details_hourlyEarningsBreakdownInfo">
        Here are all the tasks you completed that were paid on an hourly rate.
      </p>
    </app-modal-content-row>

    <table cdk-table [dataSource]="tableData.cellData" class="table table--small">
      <caption class="visually-hidden" i18n="@@hourly-earnings-details_hourlyEarningsBreakdown">Hourly earnings breakdown</caption>
      <ng-container cdkColumnDef="task">
        <th cdk-header-cell *cdkHeaderCellDef scope="col" i18n="@@hourly-earnings-details_task">Task</th>
        <td cdk-cell *cdkCellDef="let element" class="table__cell table__cell--bold">
          <ng-container>
            {{ element.task }}
          </ng-container>
        </td>
        <td cdk-footer-cell *cdkFooterCellDef colspan="3" class="table__cell table__cell--bold">
          <ng-container i18n="@@hourly-earnings-details_totalHourlyEarnings">Total Hourly Earnings</ng-container>
        </td>
      </ng-container>
      <ng-container cdkColumnDef="payRate">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          scope="col"
          class="table__cell table__cell--number"
          i18n="@@hourly-earnings-details_ratePerHour"
        >
          Rate/hr
        </th>
        <td cdk-cell *cdkCellDef="let element" class="table__cell table__cell--number">
          <ng-container>
            {{ element.payRate | currency }}
          </ng-container>
        </td>
        <td cdk-footer-cell *cdkFooterCellDef [ngStyle]="{ display: 'none' }"></td>
      </ng-container>
      <ng-container cdkColumnDef="hoursWorked">
        <th cdk-header-cell *cdkHeaderCellDef scope="col" class="table__cell table__cell--number" i18n="@@hourly-earnings-details_hours">
          Hours
        </th>
        <td cdk-cell *cdkCellDef="let element" class="table__cell table__cell--number">
          <ng-container>
            {{ element.hoursWorked | number: '1.2-2' }}
          </ng-container>
        </td>
        <td cdk-footer-cell *cdkFooterCellDef [ngStyle]="{ display: 'none' }"></td>
      </ng-container>
      <ng-container cdkColumnDef="totalEarnings">
        <th cdk-header-cell *cdkHeaderCellDef scope="col" class="table__cell table__cell--number" i18n="@@hourly-earnings-details_total">
          Total
        </th>
        <td cdk-cell *cdkCellDef="let element" class="table__cell table__cell--number">
          <ng-container>
            {{ element.totalEarnings | currency }}
          </ng-container>
        </td>
        <td cdk-footer-cell *cdkFooterCellDef class="table__cell table__cell--number">{{ tableData.footerData | currency }}</td>
      </ng-container>

      <tr cdk-header-row *cdkHeaderRowDef="columns"></tr>
      <tr cdk-row *cdkRowDef="let row; columns: columns"></tr>
      <tr cdk-footer-row *cdkFooterRowDef="columns"></tr>
    </table>
  </div>
</app-modal-layout>
