<app-production-details-layout *ngIf="productionDetails">
  <ng-container slot="productivity">
    <app-page-section-header
      i18n-title="@@production-details_productivityOverview"
      title="10 day productivity average"
    ></app-page-section-header>

    <ng-template
      #associatePalletsPerHourTitle
      i18n="@@production-details_associatePalletsPerHour"
    >
      Your Pallets Per Hour
    </ng-template>

    <ng-template
      #managerPalletsPerHourTitle
      i18n="@@production-details_managerPalletsPerHour"
    >
      Pallets Per Hour
    </ng-template>

    <ng-template
      #associateCasesPerHourTitle
      i18n="@@production-details_associateCasesPerHour"
    >
      Your Cases Per Hour
    </ng-template>

    <ng-template
      #managerCasesPerHourTitle
      i18n="@@production-details_managerCasesPerHour"
    >
      Cases Per Hour
    </ng-template>

    <app-simple-select
      [options]="options"
      [(value)]="activeItemType"
      (valueChange)="onChange($event)"
    ></app-simple-select>
    <app-stats>
      <app-stats-item
        *ngIf="activeItemType == ItemTypeEnum.Pallets"
        [value]="productionDetails.productivityAverage.palletsPerHour"
        numberFormat="1.0-0"
        [titleTemplate]="
          isAssociateView === true
            ? associatePalletsPerHourTitle
            : managerPalletsPerHourTitle
        "
        [appearance]="
          productionDetails.productivityAverage.palletsPerHourLevel
            | associateStatsAppearance
        "
        [chipTitle]="
          productionDetails.productivityAverage.palletsPerHourLevel
            | associateStatsLevelTitle
        "
        [chipAppearance]="
          productionDetails.productivityAverage.palletsPerHourLevel
            | associateStatsLevelAppearance
        "
      ></app-stats-item>
      <app-stats-item
        *ngIf="activeItemType == ItemTypeEnum.Cases"
        [value]="productionDetails.productivityAverage.casesPerHour"
        numberFormat="1.0-0"
        [titleTemplate]="
          isAssociateView === true
            ? associateCasesPerHourTitle
            : managerCasesPerHourTitle
        "
        [appearance]="
          productionDetails.productivityAverage.casesPerHourLevel
            | associateStatsAppearance
        "
        [chipTitle]="
          productionDetails.productivityAverage.casesPerHourLevel
            | associateStatsLevelTitle
        "
        [chipAppearance]="
          productionDetails.productivityAverage.casesPerHourLevel
            | associateStatsLevelAppearance
        "
      ></app-stats-item>
      <app-stats-item
        [value]="productionDetails.productivityAverage.onTruck"
        valueSuffix="%"
        [coloredSuffixPrefix]="true"
        i18n-title="@@production-details_onTruck"
        title="On-Truck %"
        [appearance]="
          productionDetails.productivityAverage.onTruckLevel
            | associateStatsAppearance
        "
        [chipTitle]="
          productionDetails.productivityAverage.onTruckLevel
            | associateStatsLevelTitle
        "
        [chipAppearance]="
          productionDetails.productivityAverage.onTruckLevel
            | associateStatsLevelAppearance
        "
      ></app-stats-item>
    </app-stats>
    <span
      class="production-details__average-notice"
      i18n="@@production-details_averageFooterText"
    >
      % rankings based on site averages
    </span>
  </ng-container>

  <ng-container slot="performance">
    <app-stats>
      <app-stats-item
        [value]="productionDetails.currentWeekProductivity.hours"
        i18n-title="@@production-details_hoursWorked"
        title="Hours Worked This Week"
        [appearance]="StatsAppearanceEnum.Green"
      ></app-stats-item>
      <app-stats-item
        [value]="productionDetails.violationCount"
        i18n-title="@@production-details_violations"
        title="Violation within 6 months"
        [appearance]="
          productionDetails.violationCount > 0
            ? StatsAppearanceEnum.Yellow
            : StatsAppearanceEnum.Blue
        "
      ></app-stats-item>
    </app-stats>

    <a
      *ngIf="!isAssociateView"
      class="production-details__toolkit-link"
      href="https://app.powerbi.com/groups/me/apps/e8e39e18-9de9-4d50-9655-a958bf47a0bf/reports/fea59f68-d64e-46de-88f0-ec23b6266cca/ReportSection"
      i18n="@@production-details_toolkitLink"
    >
      View details in Site Manager Toolkit
    </a>
  </ng-container>

  <ng-container slot="observation">
    <app-production-details-observations
      *ngIf="productionDetails"
      [safetyObservation]="productionDetails.safetyObservations"
    ></app-production-details-observations>
  </ng-container>
</app-production-details-layout>
