<a
  [attr.data-cy]="module"
  [routerLink]="link"
  [ngClass]="{
    'navigation-link': true,
    'navigation-link--earnings':
      ModuleEnum.Wages === module && appearance === ModuleEnum.Wages,
    'navigation-link--roadCrew':
      ModuleEnum.RoadCrew === module && appearance === ModuleEnum.RoadCrew,
    'navigation-link--news':
      ModuleEnum.News === module && appearance === ModuleEnum.News,
    'navigation-link--resources':
      ModuleEnum.Resources === module && appearance === ModuleEnum.Resources,
    'navigation-link--myteam':
      ModuleEnum.MyTeam === module && appearance === ModuleEnum.MyTeam,
    'navigation-link--myprofile':
      ModuleEnum.MyProfile === module && appearance === ModuleEnum.MyProfile,
    'navigation-link--covid':
      ModuleEnum.Covid === module && appearance === ModuleEnum.Covid,
    'navigation-link--inactive': module !== appearance,
    'navigation-link--inactive-w-indicator': module !== appearance && indicator
  }"
  cdkMonitorElementFocus
>
  <app-svg-icon [name]="iconName" class="navigation-link__icon"></app-svg-icon>
  <div class="navigation-link__container">
    <app-indicator *ngIf="indicator"></app-indicator>
    <span class="navigation-link__title">{{ title }}</span>
  </div>
</a>
