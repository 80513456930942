import { createAction, props } from '@ngrx/store';
import { SortDirection } from './sort-direction';

export function createDataTableActions<T>(entity, component = null) {
  return {
    loadItems: createLoadItemsAction(entity, component),
    itemsLoaded: createItemsLoadedAction<T>(entity, component),
    changePage: createChangeDataTablePageAction(entity, component),
    changeSort: createChangeDataTableSortAction(entity, component),
    changeFilter: createChangeDataTableFilterAction(entity, component),
    selectItem: createToggleSelectItemAction<T>(entity, component),
    selectAllItems: createToggleSelectAllItems(entity, component),
    unselectAllItems: createUnselectAllItemsAction(entity, component)
  };
}

function createLoadItemsAction(entity, component = null) {
  return createAction(`[${component || entity} Component] Load ${entity}`);
}

function createItemsLoadedAction<T>(entity, component = null) {
  return createAction(
    `[${component || entity} Component] ${entity} Loaded`,
    props<{ items: T[]; totalCount: number; fillCurrentItems?: boolean }>()
  );
}

function createChangeDataTablePageAction(entity, component = null) {
  return createAction(
    `[${component || entity} Component] ${entity} Page Change`,
    props<{ pageIndex: number; pageSize: number }>()
  );
}

function createChangeDataTableSortAction(entity, component = null) {
  return createAction(
    `[${component || entity} Component] ${entity} Sort Change`,
    props<{ direction: SortDirection; active: string }>()
  );
}

function createChangeDataTableFilterAction(entity, component = null) {
  return createAction(
    `[${component || entity} Component] ${entity} Filter Change`,
    props<{ filter: any }>()
  );
}

function createToggleSelectItemAction<T>(entity, component = null) {
  return createAction(
    `[${component || entity} Component] ${entity} Select`,
    props<{ item: T; selected: boolean }>()
  );
}

function createToggleSelectAllItems(entity, component = null) {
  return createAction(
    `[${component || entity} Component] ${entity} Select All`,
    props<{ selectAll: boolean }>()
  );
}

function createUnselectAllItemsAction(entity, component = null) {
  return createAction(
    `[${component || entity} Component] ${entity} Unselect All`
  );
}
