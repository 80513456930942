import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectDirectory, selectIsDirectoryLoading } from '../../../reducers';
import { ResourcesActions } from '../../actions';
import { Observable } from 'rxjs';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { Directory } from '../../../core/dataEntities/resources/directory';

@Component({
  selector: 'app-team-directory',
  templateUrl: './team-directory.component.html',
  styleUrls: ['./team-directory.component.scss']
})
export class TeamDirectoryComponent implements OnInit {
  public isDirectoryLoading$: Observable<boolean>;
  public directory$: Observable<Directory>;
  PageTitleAppearanceEnum = PageTitleAppearance;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.store.dispatch(ResourcesActions.loadDirectory());
    this.isDirectoryLoading$ = this.store.select(selectIsDirectoryLoading);
    this.directory$ = this.store.select(selectDirectory);
  }
}
