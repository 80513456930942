<p class="read-only-details">
  {{
    formGroup.get('isDriver').value
      ? 'Available as a driver'
      : 'Not available as a driver'
  }}
</p>
<p class="read-only-details">
  {{
    formGroup.get('isDriver').value
      ? formGroup.get('documents').value
        ? 'Driver License included'
        : 'Driver License not included'
      : formGroup.get('typeOfID').value
      ? translationService.translate(formGroup.get('typeOfID').value) +
        ' details included'
      : 'ID details not included'
  }}
</p>
