import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { DateTimeUtils } from '../../../../shared/utils/DateTimeUtils';

@Injectable({
  providedIn: 'root'
})
export class GoalEditGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const currentWeekEnd = DateTimeUtils.getCurrentWeekEndDate();

    if (currentWeekEnd === route.params.week) {
      return true;
    }

    this.router.navigate(['goal/breakdown', route.params.week]);
    return false;
  }
}
