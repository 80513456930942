<div
  [ngClass]="{
    'page-title': true,
    'page-title--blue': appearance === PageTitleAppearanceEnum.Blue,
    'page-title--white': appearance === PageTitleAppearanceEnum.White,
    'page-title--transparent':
      appearance === PageTitleAppearanceEnum.Transparent,
    'page-title--green': appearance === PageTitleAppearanceEnum.Green,
    'page-title--yellow': appearance === PageTitleAppearanceEnum.Yellow,
    'page-title--warning': appearance === PageTitleAppearanceEnum.Warning,
    'page-title--narrow': narrow
  }"
>
  <app-svg-icon
    *ngIf="iconName"
    [name]="iconName"
    [colorPrimary]="iconColorPrimary"
    [colorSecondary]="iconColorSecondary"
    class="page-title__icon"
  ></app-svg-icon>

  <ng-template #defaultTitleTemplate>
    {{ title }}
  </ng-template>

  <h1 class="page-title__heading" data-cy="pageTitleHeading">
    <ng-container
      *ngTemplateOutlet="titleTemplate ? titleTemplate : defaultTitleTemplate"
    ></ng-container>
  </h1>

  <ng-content></ng-content>
</div>
