/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./simple-select.component";
var styles_SimpleSelectComponent = [i0.styles];
var RenderType_SimpleSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimpleSelectComponent, data: {} });
export { RenderType_SimpleSelectComponent as RenderType_SimpleSelectComponent };
function View_SimpleSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit[_co.valueKey]; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit[_co.valueKey]; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit[_co.labelKey]; _ck(_v, 3, 0, currVal_2); }); }
export function View_SimpleSelectComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "simple-select__label visually-hidden"]], [[1, "for", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "select", [["class", "simple-select__select"]], [[1, "id", 0], [1, "name", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.value = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = (_co.updateValue($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(5, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleSelectComponent_1)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_11 = _co.value; _ck(_v, 5, 0, currVal_11); var currVal_12 = _co.options; _ck(_v, 9, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.id; var currVal_3 = _co.name; var currVal_4 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 7).ngClassValid; var currVal_9 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_SimpleSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-simple-select", [], null, null, null, View_SimpleSelectComponent_0, RenderType_SimpleSelectComponent)), i1.ɵdid(1, 49152, null, 0, i4.SimpleSelectComponent, [], null, null)], null, null); }
var SimpleSelectComponentNgFactory = i1.ɵccf("app-simple-select", i4.SimpleSelectComponent, View_SimpleSelectComponent_Host_0, { id: "id", value: "value", label: "label", name: "name", options: "options", valueKey: "valueKey", labelKey: "labelKey" }, { valueChange: "valueChange" }, []);
export { SimpleSelectComponentNgFactory as SimpleSelectComponentNgFactory };
