import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';
import { DocumentToHtmlPipe } from './document-to-html.pipe';
import { MergePipe } from './merge.pipe';
import { JoinPipe } from './join.pipe';
import { ArrayHasValuePipe } from './array-has-value.pipe';

@NgModule({
  declarations: [
    TranslatePipe,
    DocumentToHtmlPipe,
    JoinPipe,
    MergePipe,
    ArrayHasValuePipe
  ],
  exports: [
    TranslatePipe,
    DocumentToHtmlPipe,
    JoinPipe,
    MergePipe,
    ArrayHasValuePipe
  ]
})
export class PipesModule {}
