/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./validation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./validation.component";
var styles_ValidationComponent = [i0.styles];
var RenderType_ValidationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ValidationComponent, data: {} });
export { RenderType_ValidationComponent as RenderType_ValidationComponent };
function View_ValidationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "validation"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_ValidationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidationComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.shouldShowError || _co.shouldShowError()); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ValidationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-validation", [], null, null, null, View_ValidationComponent_0, RenderType_ValidationComponent)), i1.ɵdid(1, 114688, null, 0, i3.ValidationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ValidationComponentNgFactory = i1.ɵccf("app-validation", i3.ValidationComponent, View_ValidationComponent_Host_0, { errors: "errors", for: "for", shouldShowError: "shouldShowError" }, {}, ["*"]);
export { ValidationComponentNgFactory as ValidationComponentNgFactory };
