import { createAction, props } from '@ngrx/store';
import { Feed } from '../../core/dataEntities/feed/feed';
import { PostDetailsResponse } from '../../core/responses/news/postDetailsResponse';
import { Post } from '../../core/dataEntities/feed/post';
import { DirectoryResponse } from '../../core/responses/resources/directoryResponse';

export const loadDirectory = createAction(
  '[Resources Component] Load Directory'
);

export const directoryLoaded = createAction(
  '[Resources Component] Directory Loaded',
  props<{ directory: DirectoryResponse }>()
);

export const loadScriptStars = createAction(
  '[Resources Component] Load Script Stars',
  props<{ page: number; itemsPerPage: number }>()
);

export const scriptStarsLoaded = createAction(
  '[Resources Component] Script Stars Loaded',
  props<{
    feed: Feed;
    page: number;
    additionalData: PostDetailsResponse;
  }>()
);

export const loadStarPost = createAction(
  '[Resources Component] Load Script Star Post',
  props<{ postId: string }>()
);

export const postLoaded = createAction(
  '[Resources Component] Script Star Post Loaded',
  props<{ post: Post; postId: string; additionalData?: PostDetailsResponse }>()
);

export const errorOccurredOnStarsFeedLoading = createAction(
  '[Resources Component] Error Occurred On Stars feed loading'
);

export const errorOccurredOnPostLoading = createAction(
  '[Resources Component] Error Occurred On Stars feed loading'
);

export const likePost = createAction(
  '[Resources Component] Like/Dislike Post',
  props<{ postId: string; isLiked: boolean }>()
);

export const postLiked = createAction(
  '[Resources Component] Post Liked/Disliked',
  props<{ postId: string; isLiked: boolean }>()
);
