import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import * as jwt_decode from 'jwt-decode';
import { User } from '../../core/dataEntities/user/user';
import { Observable } from 'rxjs';
import { AuthenticationResult } from '@azure/msal-angular/dist/AuthenticationResult';
import { ofType } from '@ngrx/effects';
import { acquireTokenSuccess } from '../../core/actions/msal.actions';

@Injectable()
export class AuthenticationService {
  private logoutUrl = 'https://login.microsoftonline.com/common/oauth2/logout';
  private logoutRedirectUrl =
    environment.azureADTenantConfig.postLogoutRedirectUri;

  constructor(
    private authService: MsalService,
    private broadcastService: BroadcastService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  loginSuccess$(): Observable<AuthenticationResult> {
    return Observable.fromEventPattern(handler =>
      this.broadcastService.subscribe('msal:loginSuccess', handler)
    );
  }

  loginFailure$(): Observable<AuthenticationResult> {
    return Observable.fromEventPattern(handler =>
      this.broadcastService.subscribe('msal:loginFailure', handler)
    );
  }

  acquireTokenSuccess$() {
    return this.broadcastService
      .getMSALItem()
      .pipe(
        ofType(acquireTokenSuccess)
      )
  }

  logout(isAutoLogout: boolean) {
    const origin = window.location.origin;
    const storage = this.authService.getCacheStorage();
    const logoutUri =
      '/' +
      this.locale +
      this.logoutRedirectUrl.replace('IS_AUTO_LOGOUT', isAutoLogout.toString());
    storage.resetCacheItems();
    storage.clearCookie();

    window.location.href = `${this.logoutUrl}?post_logout_redirect_uri=${origin}${logoutUri}`;
  }

  decodeToken(token: string) {
    return jwt_decode(token);
  }

  getUser() {
    const msalUser = this.authService.getUser();

    const user: User = {
      id: (msalUser.idToken as any).oid,
      email: msalUser.displayableId,
      name: msalUser.name,
      isUserLoggedIn: true,
      token: '',
      roles: []
    };
    return user;
  }
}
