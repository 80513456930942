import { Component, Inject } from '@angular/core';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../shared/enums/ButtonLink';
import { DialogRef } from '../../../shared/modules/modal/dialog-ref';
import { DIALOG_DATA } from '../../../shared/modules/modal/dialog-config';

@Component({
  selector: 'app-change-photo',
  templateUrl: './change-photo.component.html',
  styleUrls: ['./change-photo.component.scss']
})
export class ChangePhotoComponent {
  public ButtonColor = ButtonColor;
  public ButtonAppearanceEnum = ButtonAppearance;

  constructor(
    private modalRef: DialogRef<ChangePhotoComponent>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  deletePhoto() {
    this.modalRef.close({ success: true, data: { removePhoto: true } });
  }

  replacePhoto() {
    const uploaderElement = document.getElementById(this.data.fileInputId);
    if (uploaderElement) {
      uploaderElement.focus();
      uploaderElement.click();
    }
    this.modalRef.close({ success: false });
  }

  closeModal() {
    this.modalRef.close({ success: false });
  }
}
