<div
  class="weekly-goal"
  *ngIf="goalDetails.goal || (!goalDetails.goal && !isHistoricalWeek)"
>
  <ng-container *ngIf="goalDetails.goal">
    <div data-cy="weeklyGoalContainer">
      <app-progress-bar
        [max]="goalDetails.goal"
        [current]="totalEarnings"
        [appearance]="
          isHistoricalWeek
            ? progressBarAppearance.Gray
            : progressBarAppearance.Green
        "
        [prefix]="'$'"
        [ariaLabelledBy]="'weekly-goal-title'"
      ></app-progress-bar>

      <p class="weekly-goal__progress-title">
        <ng-container
          *ngIf="!isHistoricalWeek && totalEarnings === 0"
          i18n="@@weekly-goal_zeroEarningsWeeklyGoalSubtitle"
        >
          Looks like you're behind on your weekly goal.
        </ng-container>

        <ng-container
          *ngIf="
            !isHistoricalWeek &&
            totalEarnings > 0 &&
            totalEarnings < goalDetails.goal
          "
          i18n="@@weekly-goal_goalInProgressWeeklyGoalSubtitle"
        >
          You're {{ percentage | number: '1.1-2' }} % of the way there.
          {daysLeft, plural, =1 {1 day} other {{{daysLeft}} days}} to go. Keep
          up the hard work!
        </ng-container>

        <ng-container
          *ngIf="!isHistoricalWeek && totalEarnings >= goalDetails.goal"
          i18n="@@weekly-goal_beatGoalWeeklyGoalSubtitle"
        >
          Nice job! You completed your earnings goal. Keep up the good work!
        </ng-container>

        <ng-container
          *ngIf="isHistoricalWeek && totalEarnings < goalDetails.goal"
          i18n="@@weekly-goal_goalNotMetProgressWeeklyGoalSubtitle"
        >
          Looks like you didn't meet your earnings goal. You can always adjust
          your goals below.
        </ng-container>

        <ng-container
          *ngIf="isHistoricalWeek && totalEarnings >= goalDetails.goal"
          i18n="@@weekly-goal_goalMetProgressWeeklyGoalSubtitle"
        >
          Congratulations! Keep up the hard work. Stay motivated. Stay safe.
        </ng-container>
      </p>
    </div>
  </ng-container>
  <div *ngIf="!goalDetails.goal && !isHistoricalWeek">
    <div class="weekly-goal__link-container">
      <a
        class="weekly-goal__image-link"
        [routerLink]="['/goal', goalDetails.id]"
        cdkMonitorElementFocus
      >
        <img
          class="weekly-goal__image"
          src="assets/images/goal-empty-state.svg"
          alt="Set goal"
        />
      </a>
    </div>

    <app-page-section-header
      title="Set Goals, Earn More"
      i18n-title="@@weekly-goal_emptyStateWeeklyGoalTitle"
      subtitle="Set and track goals for your hours and productivity. Calculate how much
      you could earn each week."
      i18n-subtitle="@@weekly-goal_emptyStateDescriptionWeeklyGoal"
    ></app-page-section-header>
    <app-button-link
      class="weekly-goal__set-goal"
      [color]="ButtonColorEnum.Green"
      [link]="['/goal', goalDetails.id]"
      i18n-label="@@setMyGoals"
      label="Set My Goals"
      data-cy="setMyGoalsButton"
    ></app-button-link>
  </div>
</div>
