import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from '../../../shared/modules/modal/dialog-ref';
import { DIALOG_DATA } from '../../../shared/modules/modal/dialog-config';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../shared/enums/ButtonLink';

@Component({
  selector: 'app-manage-associate-photo',
  templateUrl: './manage-associate-photo.component.html',
  styleUrls: ['./manage-associate-photo.component.scss']
})
export class ManageAssociatePhotoComponent implements OnInit {
  public ButtonAppearanceEnum = ButtonAppearance;
  public ButtonColorEnum = ButtonColor;
  constructor(
    private modalRef: DialogRef<ManageAssociatePhotoComponent>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  closeModal() {
    this.modalRef.close({ success: false });
  }

  removePhoto() {
    this.data.photo = null;
  }

  save() {
    this.modalRef.close({ success: true });
  }
}
