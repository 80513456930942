import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../../../core/guards/authenticationGuard/authentication.guard';
import { WeeklyGoalContainerComponent } from './containers/weekly-goal-container/weekly-goal-container.component';
import { GoalEditGuard } from './guards/goal-edit.guard';
import { TranslationMessages } from '../../../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../../../core/enums/SiteMapItemKeys';
import { Module } from '../../../shared/enums/Module';

const routes: Routes = [
  {
    path: 'goal/:week',
    component: WeeklyGoalContainerComponent,
    canActivate: [AuthenticationGuard, GoalEditGuard],
    data: {
      module: Module.Wages,
      hideNavigationBar: true,
      pageTitle: TranslationMessages.GoalTitle,
      siteMapKey: SiteMapItemKeys.GoalEdit
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GoalRoutingModule {}
