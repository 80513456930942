import { Component, OnInit, Input } from '@angular/core';
import { PostCategoryType } from '../../../../news/enums/PostTypes';
import { SvgIconColor, SvgIconName } from '../../../enums/SvgIcon';

@Component({
  selector: 'app-post-category',
  templateUrl: './post-category.component.html',
  styleUrls: ['./post-category.component.scss']
})
export class PostCategoryComponent implements OnInit {
  @Input() category: PostCategoryType;
  @Input() showName = false;

  SvgIconNameEnum = SvgIconName;
  SvgIconColorEnum = SvgIconColor;
  PostCategoryTypeEnum = PostCategoryType;

  constructor() {}

  ngOnInit() {}
}
