/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../../svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./modal-header.component";
var styles_ModalHeaderComponent = [i0.styles];
var RenderType_ModalHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalHeaderComponent, data: {} });
export { RenderType_ModalHeaderComponent as RenderType_ModalHeaderComponent };
function View_ModalHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-svg-icon", [["class", "modal-header__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ModalHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.secondaryText; _ck(_v, 0, 0, currVal_0); }); }
function View_ModalHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ModalHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "time", [["class", "modal-header__date"], ["data-cy", "earningsModalDate"]], [[1, "datetime", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.date; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.date, "MM/dd/yy")); _ck(_v, 1, 0, currVal_1); }); }
function View_ModalHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "time", [["class", "modal-header__date"], ["data-cy", "earningsModalDate"]], [[1, "datetime", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dateRange; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.dateRange; _ck(_v, 1, 0, currVal_1); }); }
export function View_ModalHeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalHeaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "h1", [["cdkFocusInitial", ""], ["class", "modal-header__heading"], ["data-cy", "earningsModalHeading"], ["id", "dialog-header"], ["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "modal-header__first-line"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(0, [["defaultSecondaryTextTemplate", 2]], null, 0, null, View_ModalHeaderComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalHeaderComponent_3)), i1.ɵdid(9, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalHeaderComponent_4)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalHeaderComponent_5)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; _ck(_v, 3, 0, currVal_0); var currVal_2 = (_co.secondaryTextTemplate ? _co.secondaryTextTemplate : i1.ɵnov(_v, 7)); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.date && !_co.dateRange); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.dateRange && !_co.date); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.primaryText; _ck(_v, 6, 0, currVal_1); }); }
export function View_ModalHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-header", [], null, null, null, View_ModalHeaderComponent_0, RenderType_ModalHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i6.ModalHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalHeaderComponentNgFactory = i1.ɵccf("app-modal-header", i6.ModalHeaderComponent, View_ModalHeaderComponent_Host_0, { primaryText: "primaryText", secondaryText: "secondaryText", secondaryTextTemplate: "secondaryTextTemplate", date: "date", dateRange: "dateRange", iconName: "iconName" }, {}, []);
export { ModalHeaderComponentNgFactory as ModalHeaderComponentNgFactory };
