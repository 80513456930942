/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./desc-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./desc-list.component";
var styles_DescListComponent = [i0.styles];
var RenderType_DescListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DescListComponent, data: {} });
export { RenderType_DescListComponent as RenderType_DescListComponent };
function View_DescListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 0, 0, currVal_0); }); }
function View_DescListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_DescListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dd", [["class", "list__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_DescListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DescListComponent_5)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.items; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DescListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dd", [["class", "list__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.items; _ck(_v, 2, 0, currVal_0); }); }
function View_DescListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], [[1, "data-cy", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { list__item: 0, "list__item--full": 1, "list__item--half": 2, "list__item--third": 3, "list__item--scrollable": 4 }), (_l()(), i1.ɵand(0, [["defaultTitleTemplate", 2]], null, 0, null, View_DescListComponent_2)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "dt", [["class", "list__title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DescListComponent_3)), i1.ɵdid(8, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DescListComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DescListComponent_6)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 4, 0, true, (1 === _v.context.$implicit.columns), (2 === _v.context.$implicit.columns), (3 === _v.context.$implicit.columns), _v.context.$implicit.scrollable); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_v.context.$implicit.titleTemplate ? _v.context.$implicit.titleTemplate : i1.ɵnov(_v, 5)); _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.isArray; _ck(_v, 10, 0, currVal_3); var currVal_4 = !_v.context.$implicit.isArray; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.dataCy; _ck(_v, 1, 0, currVal_0); }); }
export function View_DescListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dl", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DescListComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listItems; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DescListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-desc-list", [], null, null, null, View_DescListComponent_0, RenderType_DescListComponent)), i1.ɵdid(1, 114688, null, 1, i3.DescListComponent, [], null, null), i1.ɵqud(603979776, 1, { listItems: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DescListComponentNgFactory = i1.ɵccf("app-desc-list", i3.DescListComponent, View_DescListComponent_Host_0, {}, {}, []);
export { DescListComponentNgFactory as DescListComponentNgFactory };
