/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./desc-list-item.component";
var styles_DescListItemComponent = [];
var RenderType_DescListItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DescListItemComponent, data: {} });
export { RenderType_DescListItemComponent as RenderType_DescListItemComponent };
export function View_DescListItemComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_DescListItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-desc-list-item", [], null, null, null, View_DescListItemComponent_0, RenderType_DescListItemComponent)), i0.ɵdid(1, 114688, null, 0, i1.DescListItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DescListItemComponentNgFactory = i0.ɵccf("app-desc-list-item", i1.DescListItemComponent, View_DescListItemComponent_Host_0, { title: "title", titleTemplate: "titleTemplate", items: "items", columns: "columns", scrollable: "scrollable", dataCy: "dataCy" }, {}, []);
export { DescListItemComponentNgFactory as DescListItemComponentNgFactory };
