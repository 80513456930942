import { Component, Inject, OnInit } from '@angular/core';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';
import { DialogRef } from '../../../../../shared/modules/modal/dialog-ref';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { DIALOG_DATA } from '../../../../../shared/modules/modal/dialog-config';
import { OpportunitiesService } from '../../../../../core/contracts/OpportunitiesService';
import { finalize } from 'rxjs/operators';
import moment from 'moment';
import { IdDateFormat } from '../../../../../shared/constants';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit {
  SvgIconNameEnum = SvgIconName;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: true,
    selectorHeight: '340px',
    selectorWidth: null,
    dateFormat: 'yyyy-mm-dd',
    inline: true
  };
  countsLoading = false;
  totalCount = null;
  date: IMyDateModel = null;

  constructor(
    private opportunitiesService: OpportunitiesService,
    private modalRef: DialogRef<DateFilterComponent>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.setInitialDate();
    this.recalculateCountForDate(
      this.data.startDateFrom || moment(new Date()).format(IdDateFormat)
    );
  }

  dateChanged(newDate) {
    this.date = {
      isRange: true,
      dateRange: {
        beginJsDate: newDate.jsDate,
        endJsDate: new Date(new Date(newDate.jsDate).getFullYear() + 1, 11, 31)
      }
    };
    this.recalculateCountForDate(newDate.formatted);
  }

  recalculateCountForDate(date) {
    this.countsLoading = true;
    this.opportunitiesService
      .getOpportunitiesCount(this.data.userId, {
        ...this.data.filter,
        startDateFrom: date
      })
      .pipe(finalize(() => (this.countsLoading = false)))
      .subscribe(count => {
        this.totalCount = count;
      });
  }

  setTodayDate() {
    this.date = {
      isRange: true,
      dateRange: {
        beginJsDate: new Date(),
        endJsDate: new Date(new Date().getFullYear() + 1, 11, 31)
      }
    };
  }

  setInitialDate() {
    const beginDate = this.data.startDateFrom
      ? new Date(this.data.startDateFrom + 'T00:00:00')
      : new Date();
    this.date = {
      isRange: true,
      dateRange: {
        beginJsDate: beginDate,
        endJsDate: new Date(new Date(beginDate).getFullYear() + 1, 11, 31)
      }
    };
  }

  filter() {
    this.modalRef.close({
      success: true,
      data: {
        startDateFrom: moment(this.date.dateRange.beginJsDate).format(
          IdDateFormat
        )
      }
    });
  }

  resetFilter() {
    this.setInitialDate();
    this.modalRef.close({
      success: true,
      data: {
        startDateFrom: ''
      }
    });
  }

  closeModal() {
    this.modalRef.close({ success: false });
  }
}
