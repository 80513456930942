import { formatNumber } from '@angular/common';
import * as numeral from 'numeral';

export class MathHelpers {
  static calculatePercentage(value, percentage) {
    return (value * percentage) / 100;
  }

  static getPercentageDiff(value, baseValue) {
    return ((value * 100) / baseValue) - 100;
  }

  static getNumberPrecisionLength(a) {
    if (!isFinite(a)) {
      return 0;
    }
    let e = 1;
    let p = 0;
    while (Math.round(a * e) / e !== a) {
      e *= 10;
      p++;
    }
    return p;
  }

  static roundNumber(value, locale, format) {
    if (!format) {
      return value;
    }

    const formattedValue = formatNumber(value, locale, format);
    return numeral(formattedValue).value();
  }
}
