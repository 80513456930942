/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accordion-header-primary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./accordion-header-primary.component";
var styles_AccordionHeaderPrimaryComponent = [i0.styles];
var RenderType_AccordionHeaderPrimaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccordionHeaderPrimaryComponent, data: {} });
export { RenderType_AccordionHeaderPrimaryComponent as RenderType_AccordionHeaderPrimaryComponent };
function View_AccordionHeaderPrimaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
function View_AccordionHeaderPrimaryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_AccordionHeaderPrimaryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subtitle; _ck(_v, 0, 0, currVal_0); }); }
function View_AccordionHeaderPrimaryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_AccordionHeaderPrimaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "accordion-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["defaultTitleTemplate", 2]], null, 0, null, View_AccordionHeaderPrimaryComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccordionHeaderPrimaryComponent_2)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "accordion-header__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["defaultSubTitleTemplate", 2]], null, 0, null, View_AccordionHeaderPrimaryComponent_3)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccordionHeaderPrimaryComponent_4)), i1.ɵdid(7, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.titleTemplate ? _co.titleTemplate : i1.ɵnov(_v, 1)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.subtitleTemplate ? _co.subtitleTemplate : i1.ɵnov(_v, 5)); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_AccordionHeaderPrimaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accordion-header-primary", [], null, null, null, View_AccordionHeaderPrimaryComponent_0, RenderType_AccordionHeaderPrimaryComponent)), i1.ɵdid(1, 114688, null, 0, i3.AccordionHeaderPrimaryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccordionHeaderPrimaryComponentNgFactory = i1.ɵccf("app-accordion-header-primary", i3.AccordionHeaderPrimaryComponent, View_AccordionHeaderPrimaryComponent_Host_0, { title: "title", titleTemplate: "titleTemplate", subtitle: "subtitle", subtitleTemplate: "subtitleTemplate" }, {}, []);
export { AccordionHeaderPrimaryComponentNgFactory as AccordionHeaderPrimaryComponentNgFactory };
