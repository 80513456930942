/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/alert/alert.component.ngfactory";
import * as i2 from "../../../shared/components/alert/alert.component";
import * as i3 from "./application-update.component";
import * as i4 from "../../../shared/modules/modal/dialog-ref";
var styles_ApplicationUpdateComponent = [];
var RenderType_ApplicationUpdateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ApplicationUpdateComponent, data: {} });
export { RenderType_ApplicationUpdateComponent as RenderType_ApplicationUpdateComponent };
export function View_ApplicationUpdateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-alert", [["actionTitle", "Update Now"], ["dismissTitle", "Skip Update"], ["subtitle", "Instantly update to the latest version of MyCapstone for the latest features."], ["title", "Update MyCapstone"]], null, [[null, "closeAlert"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeAlert" === en)) {
        var pd_0 = (_co.onCloseAlert($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 638976, null, 0, i2.AlertComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"], iconName: [2, "iconName"], appearance: [3, "appearance"], actionTitle: [4, "actionTitle"], dismissTitle: [5, "dismissTitle"] }, { closeAlert: "closeAlert" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Update MyCapstone"; var currVal_1 = "Instantly update to the latest version of MyCapstone for the latest features."; var currVal_2 = _co.SvgIconName.AppIcon; var currVal_3 = _co.AlertAppearance.Blue; var currVal_4 = "Update Now"; var currVal_5 = "Skip Update"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_ApplicationUpdateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-application-update", [], null, null, null, View_ApplicationUpdateComponent_0, RenderType_ApplicationUpdateComponent)), i0.ɵdid(1, 114688, null, 0, i3.ApplicationUpdateComponent, [i4.DialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApplicationUpdateComponentNgFactory = i0.ɵccf("app-application-update", i3.ApplicationUpdateComponent, View_ApplicationUpdateComponent_Host_0, {}, {}, []);
export { ApplicationUpdateComponentNgFactory as ApplicationUpdateComponentNgFactory };
