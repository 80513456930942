import { Component, Input } from '@angular/core';
import { Survey } from '../../../core/dataEntities/surveys/survey';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { ChipAppearance } from '../../../shared/enums/Chip';
import { ButtonColor } from '../../../shared/enums/ButtonLink';

@Component({
  selector: 'app-survey-card',
  templateUrl: './survey-card.component.html',
  styleUrls: ['./survey-card.component.scss']
})
export class SurveyCardComponent {
  @Input() survey: Survey;

  SvgIconNameEnum = SvgIconName;
  ChipAppearanceEnum = ChipAppearance;
  ButtonColorEnum = ButtonColor;

  constructor() {}
}
