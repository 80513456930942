import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProfileActions } from '../actions';
import {
  catchError,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom,
  filter
} from 'rxjs/operators';
import {
  AppState,
  getUserId,
  getUserSitesIds,
  isUserAgreeWithTerms,
  selectWeeklyCheckIns,
  selectTempPhoto
} from '../../reducers';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../shared/error-handlers/catch-app-error';
import { WeeklyCheckInService } from '../services/weekly-check-in.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { LayoutActions, UserActions } from '../../core/actions';
import first from 'lodash/first';
import { TranslationService } from '../../shared/services/translation.service';
import { TranslationMessages } from '../../shared/enums/TranslationMessages';
import { AssociatesService } from '../../shared/services/associates.service';
import { AuthorizationService } from '../../shared/services/authorization.service';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private associatesService: AssociatesService,
    private store: Store<AppState>,
    private weeklyCheckInService: WeeklyCheckInService,
    private router: Router,
    private translation: TranslationService,
    private authorizationService: AuthorizationService,
    @Inject(APP_ERROR_MESSAGES) private errorMessages
  ) {}

  loadWeeklyCheckinList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadWeeklyCheckinList),
      withLatestFrom(this.store.select(getUserId)),
      switchMap(([action, userId]) => {
        return this.associatesService
          .getAssociateWeeklyCheckinList(userId)
          .pipe(
            map(weeklyCheckinListResponse => {
              const items = weeklyCheckinListResponse.weeklyCheckIns;

              const weeklyCheckinList = {
                rollingAverage: weeklyCheckinListResponse.rollingAverage,
                rollingAverageAppearance: this.associatesService.getRollingAverageAppearance(
                  weeklyCheckinListResponse.rollingAverage
                ),
                weeklyCheckIns: items.map(item =>
                  this.associatesService.mapWeeklyCheckinListItem(item)
                )
              };
              return ProfileActions.weeklyCheckinListLoaded({
                weeklyCheckinList
              });
            }),
            catchError(handleAppError(this.errorMessages))
          );
      })
    )
  );

  loadProductionDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadProductionDetails),
      withLatestFrom(this.store.select(getUserId)),
      switchMap(([action, userId]) => {
        return this.associatesService.getAssociateProduction(userId).pipe(
          map(productionDetails => {
            return ProfileActions.productionDetailsLoaded({
              productionDetails
            });
          }),
          catchError(handleAppError(this.errorMessages)),
          finalize(() => {
            this.store.dispatch(
              ProfileActions.toggleProductionDetailsIsLoading({
                isLoading: false
              })
            );
          })
        );
      })
    )
  );

  saveWeeklyCheckIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveWeeklyCheckIn),
      withLatestFrom(
        this.store.select(getUserId),
        this.store.select(getUserSitesIds)
      ),
      switchMap(([action, userId, userSites]) => {
        return this.weeklyCheckInService
          .saveWeeklyCheckIn(
            userId,
            first(userSites),
            action.date,
            action.checkIn
          )
          .pipe(
            switchMap(() => {
              return of(
                ProfileActions.weeklyCheckInSaved({ date: action.date }),
                LayoutActions.showSuccessMessage({
                  title: this.translation.translate(
                    TranslationMessages.WeeklyCheckInSubmitted
                  ),
                  subtitle: this.translation.translate(
                    TranslationMessages.ThanksForTheQuckUpdate
                  )
                })
              );
            })
          );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  weeklyCheckInSubmitted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.weeklyCheckInSaved),
        tap(action => {
          this.router.navigate(['']);
        })
      ),
    { dispatch: false }
  );

  checkIsUserNeedsToFillWeeklyCheckIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.userInfoLoaded, UserActions.agreeWithTerms),
        withLatestFrom(
          this.store.select(isUserAgreeWithTerms),
          this.store.select(getUserId),
          this.store.select(selectWeeklyCheckIns),
          this.authorizationService.isAssociate$
        ),
        filter(([action, isAgree]) => isAgree === true),
        map(([action, isAgree, userId, checkIns, isAssociate]) => {
          const navigation = this.router.getCurrentNavigation();
          if (
            isAssociate &&
            (checkIns.currentWeek || checkIns.pastWeek) &&
            this.weeklyCheckInService.shouldShowWeeklyCheckIn(userId)
          ) {
            if (checkIns.pastWeek) {
              this.router.navigate(
                ['check-in/', checkIns.pastWeekDate],
                navigation ? navigation.extras : undefined
              );
            } else {
              this.router.navigate(
                ['check-in/', checkIns.currentWeekDate],
                navigation ? navigation.extras : undefined
              );
            }
          }
        }),
        catchError(handleAppError(this.errorMessages))
      ),
    { dispatch: false }
  );

  takePhoto$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.takePhoto),
        tap(() => this.router.navigate(['profile/photo/preview']))
      ),
    { dispatch: false }
  );

  savePhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.savePhoto),
      withLatestFrom(
        this.store.select(getUserId),
        this.store.select(selectTempPhoto)
      ),
      tap(() =>
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }))
      ),
      switchMap(([action, userId, tempPhoto]) => {
        return this.associatesService.saveProfilePhoto(userId, tempPhoto).pipe(
          finalize(() =>
            this.store.dispatch(
              LayoutActions.toggleAppLoading({ loading: false })
            )
          ),
          switchMap(() => {
            return of(UserActions.photoSaved({ photo: tempPhoto }));
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  photoSaved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.photoSaved),
        tap(() => this.router.navigate(['profile/production']))
      ),
    { dispatch: false }
  );

  removePhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.removePhoto),
      tap(() =>
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }))
      ),
      withLatestFrom(this.store.select(getUserId)),
      switchMap(([action, userId]) => {
        return this.associatesService.deletePhoto(userId).pipe(
          switchMap(() => {
            return of(UserActions.photoRemoved());
          }),
          finalize(() =>
            this.store.dispatch(
              LayoutActions.toggleAppLoading({ loading: false })
            )
          )
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  photoRemoved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.photoRemoved),
        tap(() => this.router.navigate(['profile/production']))
      ),
    { dispatch: false }
  );
}
