import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { LayoutActions, UserActions } from '../../core/actions';
import moment from 'moment';
import _ from 'lodash';
import { Post } from '../../core/dataEntities/feed/post';
import { ResourcesActions } from '../actions';

export const starsFeedFeatureKey = 'stars-feed';

export interface StarsFeedState extends EntityState<Post> {
  isFeedLoading: boolean;
  isPostLoading: boolean;
  activePostId: string;
  currentPage: number;
  total: number;
  errorOccurred: boolean;
  postDetailsErrorOccurred: boolean;
}

export const adapter: EntityAdapter<Post> = createEntityAdapter<Post>();

export const initialState: StarsFeedState = adapter.getInitialState({
  isFeedLoading: false,
  isPostLoading: false,
  currentPage: null,
  total: null,
  errorOccurred: false,
  activePostId: null,
  postDetailsErrorOccurred: false
});

export const starsFeedReducer = createReducer(
  initialState,
  on(ResourcesActions.loadScriptStars, (state, action) => {
    return {
      ...state,
      isFeedLoading: true,
      currentPage: action.page
    };
  }),
  on(ResourcesActions.loadStarPost, (state, action) => {
    return {
      ...state,
      activePostId: action.postId,
      isPostLoading: true
    };
  }),
  on(ResourcesActions.postLoaded, (state, action) => {
    const post = _.cloneDeep(action.post);
    if (action.additionalData) {
      const details = action.additionalData.postLikeDetails.find(
        postLikeDetails => postLikeDetails.postId === post.id
      );
      post.isLiked = details ? details.isLikedByUser : false;
      post.likes = details ? details.likeCount : 0;
    }
    return adapter.addOne(
      { id: action.postId, ...post },
      {
        ...state,
        postDetailsErrorOccurred: false,
        isPostLoading: false
      }
    );
  }),
  on(ResourcesActions.scriptStarsLoaded, (state, action) => {
    let lastLoadedDay = state.entities[state.ids[state.ids.length - 1]]
      ? state.entities[state.ids[state.ids.length - 1]].date
      : null;
    const posts = _.cloneDeep(action.feed.posts);

    posts.map(post => {
      const postDate = moment(post.date);
      if (
        lastLoadedDay === null ||
        !postDate.isSame(moment(lastLoadedDay), 'day')
      ) {
        post.isFirstDayPost = true;
        lastLoadedDay = post.date;
      }

      const details = action.additionalData.postLikeDetails.find(
        postLikeDetails => postLikeDetails.postId === post.id
      );
      post.isLiked = details ? details.isLikedByUser : false;
      post.likes = details ? details.likeCount : 0;
    });

    if (action.page === 0) {
      return adapter.addMany(posts, {
        ...state,
        isFeedLoading: false,
        currentPage: action.page,
        total: action.feed.total
      });
    }

    return adapter.upsertMany(posts, {
      ...state,
      isFeedLoading: false,
      currentPage: action.page,
      total: action.feed.total
    });
  }),
  on(ResourcesActions.likePost, (state, action) => {
    const post = state.entities[action.postId];

    const updatePost = {
      id: action.postId,
      changes: {
        isLiked: action.isLiked,
        likes: post.likes + (action.isLiked ? 1 : -1)
      }
    };

    return adapter.updateOne(updatePost, state);
  }),
  on(ResourcesActions.postLiked, (state, action) => {
    const post = state.entities ? state.entities[action.postId] : null;

    if (post && post.isLiked !== action.isLiked) {
      const updatePost = {
        id: action.postId,
        changes: {
          isLiked: action.isLiked,
          likes: post.likes + (action.isLiked ? 1 : -1)
        }
      };

      return adapter.updateOne(updatePost, state);
    }

    return state;
  }),
  on(ResourcesActions.errorOccurredOnStarsFeedLoading, state => {
    return {
      ...state,
      errorOccurred: true
    };
  }),
  on(ResourcesActions.errorOccurredOnPostLoading, state => {
    return {
      ...state,
      postDetailsErrorOccurred: true,
      isPostLoading: false
    };
  }),
  on(LayoutActions.showError, LayoutActions.apiOffline, state => {
    return {
      ...state,
      isFeedLoading: false,
      isPostLoading: false
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(state: StarsFeedState | undefined, action: Action) {
  return starsFeedReducer(state, action);
}

const { selectAll } = adapter.getSelectors();
const { selectEntities } = adapter.getSelectors();
export const selectAllLoadedStars = selectAll;

export const selectIsStarsFeedLoading = (state: StarsFeedState) => {
  return state.isFeedLoading;
};

export const selectIsPostLoading = (state: StarsFeedState) => {
  return state.isPostLoading;
};

export const selectCurrentPage = (state: StarsFeedState) => {
  return state.currentPage;
};

export const selectTotalPostsCount = (state: StarsFeedState) => {
  return state.total;
};

export const selectIsErrorOccurred = (state: StarsFeedState) => {
  return state.errorOccurred;
};

export const selectActivePostId = (state: StarsFeedState) => {
  return state.activePostId;
};

export const selectIsPostErrorOccurred = (state: StarsFeedState) => {
  return state.postDetailsErrorOccurred;
};

export const selectActivePost = createSelector(
  selectActivePostId,
  selectEntities,
  (activePostId, entities) => {
    return entities[activePostId];
  }
);
