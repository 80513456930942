<app-tab-bar>
  <app-tab-bar-link
    link="../production"
    i18n="@@associate-profile-tabs_production"
  >
    Production
  </app-tab-bar-link>
  <app-tab-bar-link
    link="../check-ins"
    i18n="@@associate-profile-tabs_check-ins"
    [indicator]="(eachSurveySubmitted$ | async) === false"
  >
    Check-ins
  </app-tab-bar-link>
</app-tab-bar>
