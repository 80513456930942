import {
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { FormFieldControl } from '../form-field/form-field.control';
import { Subject } from 'rxjs';
import {
  FORM_FIELD,
  FormFieldComponent
} from '../form-field/form-field.component';
import { takeUntil } from 'rxjs/operators';

let nextUniqueId = 0;

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AutocompleteComponent)
    },
    { provide: FormFieldControl, useExisting: AutocompleteComponent }
  ]
})
export class AutocompleteComponent
  implements OnInit, OnDestroy, ControlValueAccessor, FormFieldControl {
  onDestroySubject = new Subject();
  formControl: FormControl;
  @ViewChild('autocomplete', { static: false }) autocomplete;
  @Input() data: any[];
  @Input() valueKey = 'id';
  @Input() displayKey: string;
  @Input() placeholder: string;
  @Input() disabled = false;

  protected defaultId = `app-autocomplete-${nextUniqueId++}`;
  protected componentId: string;

  constructor(
    fb: FormBuilder,
    @Optional() @Inject(FORM_FIELD) private formField: FormFieldComponent
  ) {
    this.id = this.id;
    this.formControl = fb.control('');
  }

  @Input()
  get id(): string {
    return this.componentId;
  }

  set id(value: string) {
    this.componentId = value || this.defaultId;
  }

  get errorId(): string {
    return `${this.id}-error`;
  }

  get isInvalid() {
    if (!this.formField) {
      return false;
    }
    return this.formField.isInvalid();
  }

  public get control() {
    return this.formControl;
  }

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(value => {
        this.propagateChange(value[this.valueKey]);
      });
  }

  writeValue(value) {
    const listItem = this.data.find(item => item[this.valueKey] === value);
    if (listItem) {
      this.formControl.setValue(listItem[this.displayKey], {
        emitEvent: false
      });
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  private propagateChange = (value: any) => {};

  onInputFocused() {
    // Force close suggestions modal when input item is focused because its causing UI lag as it tries to load all possible suggestions
    this.autocomplete.close();
  }

  onInputCleared() {
    this.autocomplete.close();
  }
}
