import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { ButtonAppearance, ButtonIconSize } from '../../../../enums/ButtonLink';

@Component({
  selector: 'app-multi-step-form-readonly-step',
  templateUrl: './multi-step-form-readonly-step.component.html',
  styleUrls: ['./multi-step-form-readonly-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiStepFormReadonlyStepComponent implements OnInit {
  @Input() title: string;
  @Input() isStepEditable: boolean;
  @Input() showDetails: boolean;
  @Output() editStep = new EventEmitter();
  ButtonIconSizeEnum = ButtonIconSize;
  ButtonAppearanceEnum = ButtonAppearance;

  constructor() {}

  ngOnInit() {}
}
