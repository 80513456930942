import { Observable } from 'rxjs';
import { OpportunityDetailsResponse } from '../responses/opportunities/opportunityDetailsResponse';
import { OpenShiftListItem } from '../dataEntities/opportunities/openShiftListItem';
import { ListOfOpportunitiesResponse } from '../responses/opportunities/listOfOpportunitiesResponse';
import { AppliedOpportunitiesResponse } from '../responses/opportunities/appliedOpportunitiesResponse';
import { FilterType } from '../../wages/modules/work/enums/FilterType';
import { OpportunityDetails } from '../dataEntities/opportunities/opportunityDetails';

export abstract class OpportunitiesService {
  abstract getDetails(
    opportunityId: string,
    otherId: string,
    associateId: number
  ): Observable<OpportunityDetailsResponse>;
  abstract getOpenShifts(siteId: number): Observable<OpenShiftListItem[]>;
  abstract getRoadCrewOpenings(
    associateId: number,
    params: {
      page?: { skip: number; limit: number };
      sort?: { active: string; direction: string };
      filter?: any;
    }
  ): Observable<ListOfOpportunitiesResponse>;
  abstract getListOfAppliedOpportunities(
    associateId: number
  ): Observable<AppliedOpportunitiesResponse>;
  abstract markAppliedOpportunityAsSeen(
    opportunityId: string,
    associateId: number
  ): Observable<any>;
  abstract applyForOpportunity(
    opportunity: OpportunityDetails,
    associateId: number,
    associateName: string,
    preferredAirport: string
  ): Observable<any>;
  abstract cancelAppliedOpportunity(
    opportunityId: string,
    associateId: number
  ): Observable<any>;
  abstract getAggregatedOpportunities(
    associateId: number,
    type: FilterType,
    filter: any
  ): Observable<any>;
  abstract getOpportunitiesCount(
    associateId: number,
    filter: any
  ): Observable<number>;
  abstract getIsEachOpportunityReviewed(
    associateId: number
  ): Observable<boolean>;
}
