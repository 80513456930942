import { Injectable } from '@angular/core';
import { ApiGatewayService } from '../../shared/services/api.gateway.service';
import { Store } from '@ngrx/store';
import { AppState, getAuthToken, getUserId } from '../../reducers';
import moment from 'moment';
import { first, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WeeklyCheckIn } from '../../core/dataEntities/profile/weeklyCheckIn';

@Injectable({
  providedIn: 'root'
})
export class WeeklyCheckInService {
  private associateWeeklyCheckInReminderKey = 'associateWeeklyCheckInReminder';

  constructor(
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>
  ) {}

  saveWeeklyCheckIn(
    userId: string,
    userSite: string,
    date: string,
    checkIn: WeeklyCheckIn
  ): Observable<any> {
    const requestBody = {
      associateGuid: userId,
      weekEndingDate: date,
      subDept: userSite,
      score: checkIn.score,
      message: checkIn.message
    };

    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.post(
          `associates/weeklyCheckIn`,
          authToken,
          requestBody
        );
      })
    );
  }

  shouldShowWeeklyCheckIn(userId: string): boolean {
    const reminder = this.getReminderStateFromLocalStorage().find(
      user => user.userId === userId
    );
    const now = moment.utc();

    if (reminder == null) {
      return true;
    }

    if (!moment(reminder.lastNotifyDateTime).isSame(now, 'week')) {
      return true;
    }

    if (moment(now).diff(reminder.lastNotifyDateTime, 'hours') < 4) {
      return false;
    }

    if (
      reminder.count === 2 &&
      moment(now).diff(reminder.lastNotifyDateTime, 'hours') > 24
    ) {
      return true;
    }

    return reminder.count < 3;
  }

  isCheckInSkipped(userId: string) {
    const reminders = this.getReminderStateFromLocalStorage();
    const lastReminder = reminders.find(user => user.userId === userId);

    if (lastReminder == null) {
      return false;
    }

    return lastReminder.count > 0;
  }

  skipSurvey() {
    this.store
      .select(getUserId)
      .pipe(first())
      .subscribe(userId => {
        const now = moment.utc();
        let reminders = this.getReminderStateFromLocalStorage();
        const reminder = reminders.find(user => user.userId === userId);

        if (reminder == null) {
          reminders.push({
            userId,
            lastNotifyDateTime: now.toISOString(),
            count: 1
          });

          localStorage.setItem(
            this.associateWeeklyCheckInReminderKey,
            JSON.stringify(reminders)
          );
        } else {
          const isSameWeek = moment(reminder.lastNotifyDateTime).isSame(
            now,
            'week'
          );
          reminders = reminders.map(user =>
            user.userId === userId
              ? {
                  ...user,
                  lastNotifyDateTime: now.toISOString(),
                  count: isSameWeek ? user.count + 1 : 0
                }
              : user
          );

          localStorage.setItem(
            this.associateWeeklyCheckInReminderKey,
            JSON.stringify(reminders)
          );
        }
      });
  }

  private getReminderStateFromLocalStorage() {
    const reminder = localStorage.getItem(
      this.associateWeeklyCheckInReminderKey
    );

    if (reminder == null) {
      return [];
    }

    return JSON.parse(
      localStorage.getItem(this.associateWeeklyCheckInReminderKey)
    ) as Array<Reminder>;
  }
}

interface Reminder {
  userId: string;
  lastNotifyDateTime: string;
  count: number;
}
