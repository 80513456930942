import { ButtonAppearance, ButtonIconSize } from '../../../enums/ButtonLink';
import {
  PostCategoryType,
  PostLinkType
} from '../../../../news/enums/PostTypes';
import { SvgIconName } from '../../../enums/SvgIcon';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  HostListener
} from '@angular/core';
import { Post } from '../../../../core/dataEntities/feed/post';
import { FeedType } from '../../../enums/FeedType';
import { FeedCardLayoutComponent } from '../feed-card-layout/feed-card-layout.component';

@Component({
  selector: 'app-news-feed-card',
  templateUrl: './news-feed-card.component.html',
  styleUrls: ['./news-feed-card.component.scss']
})
export class NewsFeedCardComponent implements OnInit, AfterViewInit {
  @Input() FeedType = FeedType.News;
  @Input() post: Post;
  @Input() ariaPosinset: number;
  @Input() ariaSetsize: number;
  @Input() previewMode = false;
  @Input() showLike = true;
  @Input() isPinnedPost = false;
  @Input() extendableBlogPost = false;
  @Output() postLiked = new EventEmitter();
  @Output() contentSizeChanged = new EventEmitter();

  SvgIconNameEnum = SvgIconName;
  PostLinkTypeEnum = PostLinkType;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonIconSizeEnum = ButtonIconSize;
  FeedTypeEnum = FeedType;
  PostCategoryTypeEnum = PostCategoryType;

  isExtended = false;

  @ViewChild(FeedCardLayoutComponent, { static: false })
  feedCardLayoutComponent: FeedCardLayoutComponent;

  @HostListener('window:resize')
  onResize() {
    this.checkIfBlogPostExtendable();
  }

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkIfBlogPostExtendable();
    });
  }

  like() {
    this.postLiked.emit({ id: this.post.id, isLiked: !this.post.isLiked });
  }

  checkIfBlogPostExtendable() {
    if (
      this.feedCardLayoutComponent &&
      this.feedCardLayoutComponent.contentfulContent &&
      this.feedCardLayoutComponent.contentfulContent.nativeElement &&
      this.feedCardLayoutComponent.contentfulContent.nativeElement
        .offsetHeight &&
      !this.post.internalBlogPost &&
      !this.post.externalPostUrl
    ) {
      this.contentSizeChanged.emit({
        postId: this.post.id,
        isExtendable:
          this.feedCardLayoutComponent.contentfulContent.nativeElement
            .offsetHeight >= 130
      });
    }
  }
}
