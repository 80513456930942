<app-alert
  title="Update MyCapstone"
  i18n-title="@@alert-update_updateMyCapstone"
  subtitle="Instantly update to the latest version of MyCapstone for the latest features."
  i18n-subtitle="@@alert-update_instantlyUpdate"
  [iconName]="SvgIconName.AppIcon"
  [appearance]="AlertAppearance.Blue"
  (closeAlert)="onCloseAlert($event)"
  actionTitle="Update Now"
  i18n-actionTitle="@@alert-update_updateNow"
  dismissTitle="Skip Update"
  i18n-dismissTitle="@@alert-update_skipUpdate"
></app-alert>
