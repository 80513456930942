<app-page-header-container></app-page-header-container>
<app-loader [isLoading]="isMainLoaderVisible$ | async"></app-loader>
<main class="main-container" *ngIf="teamMemberInfo$ | async as teamMemberInfo">
  <ng-container *ngIf="(isTeamMemberPhotoRemoving$ | async) === false">
    <app-associate-page-header
      [apexId]="teamMemberInfo.apexId"
      [userName]="teamMemberInfo.name"
      [isTrainee]="teamMemberInfo.isTrainee"
      [photoSrc]="teamMemberInfo.profilePhoto"
      (deletePhotoEvent)="deletePhoto()"
    ></app-associate-page-header>
    <div class="container">
      <app-team-member-tabs></app-team-member-tabs>
      <app-loader
        [isLoading]="isProductionDetailsLoading$ | async"
      ></app-loader>

      <ng-container
        *ngIf="
          (isProductionDetailsLoading$ | async) === false && productionDetails$
            | async as productionDetails
        "
      >
        <ng-template #productionDetailsView>
          <app-production-details
            [productionDetails]="productionDetails"
          ></app-production-details>
        </ng-template>

        <app-trainee-production-details
          *ngIf="
            productionDetails.associateInfo.isTrainee;
            else productionDetailsView
          "
          [productionDetails]="productionDetails"
          [associateName]="teamMemberInfo.name"
        ></app-trainee-production-details>
      </ng-container>
    </div>
  </ng-container>
</main>

<ng-template #emptyStateTemplate i18n="@@team_member_empty-state">
  Team member not found.
</ng-template>

<app-empty-state
  *ngIf="
    (teamMemberInfo$ | async) === null &&
    (isProductionDetailsLoading$ | async) === false
  "
  [emptyStateTemplate]="emptyStateTemplate"
></app-empty-state>
