import { Component, OnInit } from '@angular/core';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { ButtonColor } from '../../../shared/enums/ButtonLink';
import { combineLatest, Observable } from 'rxjs';
import {
  WeeklyCheckinList,
  WeeklyCheckinListItem
} from '../../../core/dataEntities/profile/weeklyCheckinList';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { MyTeamActions } from '../../actions';
import { TeamMemberInfo } from '../../view-models/team-member-details';
import {
  selectActiveTeamMemberCheckIns,
  selectActiveTeamMemberInfo,
  selectActiveTeamMemberLastCheckIn,
  selectIsTeamMemberCheckInsLoading,
  selectIsTeamMemberPhotoRemoving
} from '../../reducers/team-member-details.reducer';
import { Features } from '../../../core/enums/Features';

@Component({
  selector: 'app-my-team-member-check-in',
  templateUrl: './my-team-member-check-in.component.html',
  styleUrls: ['./my-team-member-check-in.component.scss']
})
export class MyTeamMemberCheckInComponent implements OnInit {
  public Features = Features;
  public SvgIconNameEnum = SvgIconName;
  public PageTitleAppearanceEnum = PageTitleAppearance;
  public ButtonColorEnum = ButtonColor;

  teamMemberInfo$: Observable<TeamMemberInfo>;
  isWeeklyCheckinLoading$: Observable<boolean>;
  weeklyCheckinList$: Observable<WeeklyCheckinList>;
  lastWeeklyCheckin$: Observable<WeeklyCheckinListItem>;
  isTeamMemberPhotoRemoving$: Observable<boolean>;
  isMainLoaderVisible$: Observable<boolean>;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
    this.route.paramMap.pipe(first()).subscribe((params: ParamMap) => {
      this.store.dispatch(
        MyTeamActions.loadTeamMemberCheckIns({ associateId: params.get('id') })
      );
    });
  }

  ngOnInit() {
    this.isWeeklyCheckinLoading$ = this.store.select(
      selectIsTeamMemberCheckInsLoading
    );
    this.weeklyCheckinList$ = this.store.select(selectActiveTeamMemberCheckIns);
    this.teamMemberInfo$ = this.store.select(selectActiveTeamMemberInfo);
    this.isTeamMemberPhotoRemoving$ = this.store.select(
      selectIsTeamMemberPhotoRemoving
    );
    this.lastWeeklyCheckin$ = this.store.select(
      selectActiveTeamMemberLastCheckIn
    );
    this.isMainLoaderVisible$ = combineLatest(
      this.isWeeklyCheckinLoading$,
      this.teamMemberInfo$,
      this.isTeamMemberPhotoRemoving$
    ).pipe(
      map(
        ([isWeeklyCheckinLoading, teamMemberInfo, isTeamMemberPhotoRemoving]) =>
          (isWeeklyCheckinLoading && teamMemberInfo === null) ||
          isTeamMemberPhotoRemoving === true
      )
    );
  }

  deletePhoto() {
    this.teamMemberInfo$.pipe(first()).subscribe(teamMemberInfo => {
      this.store.dispatch(
        MyTeamActions.deleteTeamMemberPhoto({
          teamMember: teamMemberInfo
        })
      );
    });
  }
}
