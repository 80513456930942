import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ApplicationInsightsService } from '../../../shared/services/application-insights.service';
import { CustomEvents } from '../../../shared/enums/CustomEvents';
import { DialogRef } from '../../../shared/modules/modal/dialog-ref';
import { DIALOG_DATA } from '../../../shared/modules/modal/dialog-config';
import { SvgIconName } from '../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-job-details-card',
  templateUrl: './job-details-card.component.html'
})
export class JobDetailsCardComponent implements OnInit, OnDestroy {
  public columns = ['type', 'amount'];
  taskDetails;
  tableData;
  SvgIconNameEnum = SvgIconName;
  isCaliforniaView = false;

  constructor(
    private modalRef: DialogRef<JobDetailsCardComponent>,
    private appInsights: ApplicationInsightsService,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.taskDetails = this.data.taskDetails;
    this.isCaliforniaView = this.data.isCaliforniaView;
    this.tableData = {
      cellData: [
        {
          type: 'net-revenue',
          amount: this.taskDetails.netRevenue,
          isCurrency: true
        },
        {
          type: 'earnings-percent',
          amount: this.taskDetails.earningsPercent,
          isCurrency: false
        },
        {
          type: 'split-percent',
          amount: this.taskDetails.splitPercent,
          isCurrency: false
        }
      ],
      footerData: this.taskDetails.associateEarnings
    };

    this.appInsights.logCustomEventStart(CustomEvents.JobDetailsModalOpen);
  }

  ngOnDestroy() {
    this.appInsights.logCustomEventStop(
      CustomEvents.JobDetailsModalOpen,
      this.taskDetails
    );
  }

  closeModal() {
    this.modalRef.close({ success: true });
  }
}
