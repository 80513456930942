import { Component, OnInit } from '@angular/core';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import Bowser from 'bowser';

@Component({
  selector: 'app-pwa-notification-container',
  templateUrl: './pwa-notification-container.component.html',
  styleUrls: ['./pwa-notification-container.component.scss']
})
export class PwaNotificationContainerComponent implements OnInit {
  SvgIconName = SvgIconName;
  PageTitleAppearanceEnum = PageTitleAppearance;
  browserName = 'your Browser';
  constructor() {}

  ngOnInit() {
    this.browserName = getBrowserName();
  }
}

const getBrowserName = (): string => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  if (browser !== null && browser !== undefined) {
    return browser.getBrowser().name;
  } else {
    return 'your Browser';
  }
};
