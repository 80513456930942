/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./opportunities-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/components/menu/menu-item/menu-item.component.ngfactory";
import * as i4 from "../../../../../shared/components/menu/menu-item/menu-item.component";
import * as i5 from "../../../../../shared/components/paginator/paginator.component.ngfactory";
import * as i6 from "../../../../../shared/components/paginator/paginator.component";
import * as i7 from "../../../../../shared/components/menu/menu.component.ngfactory";
import * as i8 from "../../../../../shared/components/menu/menu.component";
import * as i9 from "./opportunities-list.component";
var styles_OpportunitiesListComponent = [i0.styles];
var RenderType_OpportunitiesListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OpportunitiesListComponent, data: {} });
export { RenderType_OpportunitiesListComponent as RenderType_OpportunitiesListComponent };
function View_OpportunitiesListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Week In"]))], null, null); }
function View_OpportunitiesListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Weeks In"]))], null, null); }
function View_OpportunitiesListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "+ "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var currVal_1 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 1, 0, currVal_0); }); }
function View_OpportunitiesListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Less Than a Week In "]))], null, null); }
function View_OpportunitiesListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_4)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_7)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) && (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) !== "0")); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) && (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) === "0")); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.StringHelpers.joinStrings(((_v.parent.parent.context.$implicit.location == null) ? null : _v.parent.parent.context.$implicit.location.city), ((_v.parent.parent.context.$implicit.location == null) ? null : _v.parent.parent.context.$implicit.location.stateName)); _ck(_v, 5, 0, currVal_2); }); }
function View_OpportunitiesListComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Opening"]))], null, null); }
function View_OpportunitiesListComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Openings"]))], null, null); }
function View_OpportunitiesListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_10)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_11)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵted(-1, null, [", "]))], function (_ck, _v) { var currVal_1 = _v.parent.parent.parent.context.$implicit.associatesNeededTotal; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.associatesNeededTotal; _ck(_v, 1, 0, currVal_0); }); }
function View_OpportunitiesListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_9)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" starts ", " "])), i1.ɵppd(5, 2)], function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.$implicit.associatesNeededTotal || (_v.parent.parent.context.$implicit.associatesNeededTotal === 0)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.parent.context.$implicit.startDate, "MM/dd/yy")); _ck(_v, 4, 0, currVal_1); }); }
function View_OpportunitiesListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["opportunityTitle", 2]], null, 0, null, View_OpportunitiesListComponent_3)), (_l()(), i1.ɵand(0, [["opportunitySubTitle", 2]], null, 0, null, View_OpportunitiesListComponent_8)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-menu-item", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.openOpportunity.emit(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MenuItemComponent_0, i3.RenderType_MenuItemComponent)), i1.ɵdid(4, 114688, [[1, 4]], 0, i4.MenuItemComponent, [], { titleTemplate: [0, "titleTemplate"], subTitleTemplate: [1, "subTitleTemplate"], iconName: [2, "iconName"], iconColorPrimary: [3, "iconColorPrimary"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1); var currVal_1 = i1.ɵnov(_v, 2); var currVal_2 = (!((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.postingBySkillset) ? _co.SvgIconName.Location : _co.SvgIconName.Airplane); var currVal_3 = (!((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.postingBySkillset) ? _co.SvgIconColor.MountainMeadow : _co.SvgIconColor.Cinnabar); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_OpportunitiesListComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Week"]))], null, null); }
function View_OpportunitiesListComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Weeks"]))], null, null); }
function View_OpportunitiesListComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "+ "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_15)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_16)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var currVal_1 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 1, 0, currVal_0); }); }
function View_OpportunitiesListComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Less Than a Week "]))], null, null); }
function View_OpportunitiesListComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" National opportunity for "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_14)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_17)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) && (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) !== "0")); _ck(_v, 5, 0, currVal_1); var currVal_2 = (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) && (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) === "0")); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.skillsetName; _ck(_v, 3, 0, currVal_0); }); }
function View_OpportunitiesListComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Opening"]))], null, null); }
function View_OpportunitiesListComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Openings"]))], null, null); }
function View_OpportunitiesListComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_20)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_21)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵted(-1, null, [", "]))], function (_ck, _v) { var currVal_1 = _v.parent.parent.parent.context.$implicit.associatesNeededTotal; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.associatesNeededTotal; _ck(_v, 1, 0, currVal_0); }); }
function View_OpportunitiesListComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_19)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" starts ", " "])), i1.ɵppd(5, 2)], function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.$implicit.associatesNeededTotal || (_v.parent.parent.context.$implicit.associatesNeededTotal === 0)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.parent.context.$implicit.startDate, "MM/dd/yy")); _ck(_v, 4, 0, currVal_1); }); }
function View_OpportunitiesListComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["opportunityTitle", 2]], null, 0, null, View_OpportunitiesListComponent_13)), (_l()(), i1.ɵand(0, [["opportunitySubTitle", 2]], null, 0, null, View_OpportunitiesListComponent_18)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-menu-item", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.openOpportunity.emit(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MenuItemComponent_0, i3.RenderType_MenuItemComponent)), i1.ɵdid(4, 114688, [[1, 4]], 0, i4.MenuItemComponent, [], { titleTemplate: [0, "titleTemplate"], subTitleTemplate: [1, "subTitleTemplate"], iconName: [2, "iconName"], iconColorPrimary: [3, "iconColorPrimary"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1); var currVal_1 = i1.ɵnov(_v, 2); var currVal_2 = (!((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.postingBySkillset) ? _co.SvgIconName.Location : _co.SvgIconName.Airplane); var currVal_3 = (!((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.postingBySkillset) ? _co.SvgIconColor.MountainMeadow : _co.SvgIconColor.Cinnabar); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_OpportunitiesListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_12)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !((_v.context.$implicit == null) ? null : _v.context.$implicit.postingBySkillset); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.postingBySkillset); _ck(_v, 4, 0, currVal_1); }, null); }
function View_OpportunitiesListComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-paginator", [], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = (_co.changePage.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_PaginatorComponent_0, i5.RenderType_PaginatorComponent)), i1.ɵdid(1, 245760, null, 0, i6.PaginatorComponent, [], { pageIndex: [0, "pageIndex"], pageSize: [1, "pageSize"], totalCount: [2, "totalCount"] }, { pageChange: "pageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.page == null) ? null : _co.page.pageIndex); var currVal_1 = ((_co.page == null) ? null : _co.page.pageSize); var currVal_2 = _co.totalCount; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_OpportunitiesListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "opportunities-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "app-menu", [], null, null, null, i7.View_MenuComponent_0, i7.RenderType_MenuComponent)), i1.ɵdid(3, 114688, null, 1, i8.MenuComponent, [], { topRoundedBorder: [0, "topRoundedBorder"], bottomRoundedBorder: [1, "bottomRoundedBorder"] }, null), i1.ɵqud(603979776, 1, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListComponent_22)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = false; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.opportunitiesList; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.totalCount !== 0); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_OpportunitiesListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-opportunities-list", [], null, null, null, View_OpportunitiesListComponent_0, RenderType_OpportunitiesListComponent)), i1.ɵdid(1, 114688, null, 0, i9.OpportunitiesListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OpportunitiesListComponentNgFactory = i1.ɵccf("app-opportunities-list", i9.OpportunitiesListComponent, View_OpportunitiesListComponent_Host_0, { opportunitiesList: "opportunitiesList", page: "page", totalCount: "totalCount" }, { openOpportunity: "openOpportunity", changePage: "changePage" }, []);
export { OpportunitiesListComponentNgFactory as OpportunitiesListComponentNgFactory };
