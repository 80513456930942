<app-page-title
  title="Weekly Check-In"
  i18n-title="@@check-in-history_weeklyCheckIn"
  [appearance]="PageTitleAppearanceEnum.Transparent"
></app-page-title>
<app-card-layout>
  <app-card-layout-item>
    <app-page-section-header
      i18n-title="@@check-in-history_weeklyCheckInOverview"
      title="Check-in overview"
    ></app-page-section-header>
    <app-stats>
      <app-stats-item
        *ngIf="lastWeeklyCheckin"
        [value]="lastWeeklyCheckin.score"
        i18n-title="@@check-in-history_lastCheckIn"
        title="Last Check-In"
        [appearance]="lastWeeklyCheckin.appearance"
      ></app-stats-item>
      <app-stats-item
        [value]="weeklyCheckinList.rollingAverage"
        valueSuffix="/5"
        i18n-title="@@check-in-history_rollingAverage"
        title="Rolling Average"
        [appearance]="weeklyCheckinList.rollingAverageAppearance"
      ></app-stats-item>
    </app-stats>
  </app-card-layout-item>
</app-card-layout>
<section class="check-in-history__list">
  <ng-template #checkinHistoryTitle i18n="@@check-in-history_pastWeeks">
    Past
    {{ weeklyCheckinList.weeklyCheckIns.length }}
    {(weeklyCheckinList.weeklyCheckIns.length), plural, =1 {week} other {weeks}}
  </ng-template>
  <app-page-section-header
    *ngIf="weeklyCheckinList.weeklyCheckIns.length > 0"
    [titleTemplate]="checkinHistoryTitle"
  ></app-page-section-header>

  <app-card-layout *ngIf="weeklyCheckinList.weeklyCheckIns.length === 0">
    <app-card-layout-item>
      {{ userName }} has not received a checkin yet
    </app-card-layout-item>
  </app-card-layout>

  <app-accordion *ngIf="weeklyCheckinList.weeklyCheckIns.length > 0">
    <app-accordion-panel
      [disabled]="!weekItem.score"
      *ngFor="let weekItem of weeklyCheckinList.weeklyCheckIns"
    >
      <ng-template #headerRatingTitleTemplate>
        <time [attr.datetime]="weekItem.weekStartDate">
          {{ weekItem.weekStartDate | date: 'MM/dd' }}
        </time>
        -
        <time [attr.datetime]="weekItem.weekEndingDate">
          {{ weekItem.weekEndingDate | date: 'MM/dd' }}
        </time>
        <ng-container
          *ngIf="!weekItem.score"
          i18n="@@check-in-history_checkin_no_response"
        >
          No Response
        </ng-container>
      </ng-template>
      <app-accordion-header-rating
        slot="header"
        [rating]="weekItem.score"
        [titleTemplate]="headerRatingTitleTemplate"
        [appearance]="weekItem.appearance"
      ></app-accordion-header-rating>
      <p slot="body">
        <ng-container *ngIf="weekItem.message; else noComments">
          <strong i18n="@@check-in-history_comment">
            Comment:
          </strong>
          {{ weekItem.message }}
        </ng-container>

        <ng-template #noComments i18n="@@check-in-history_no_comment">
          No comment left
        </ng-template>
      </p>
    </app-accordion-panel>
  </app-accordion>
</section>
<app-footer>
  <app-footer-note i18n="@@check-in-history_endOfHistory">
    End of rolling check-in history.
  </app-footer-note>
</app-footer>
