import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslationService } from './translation.service';
import { Store } from '@ngrx/store';
import { AppState, RouteSelectors } from '../../reducers';

@Injectable({
  providedIn: 'root'
})
export class ApplicationTitleService implements OnDestroy {
  private routerSubscription: Subscription;

  constructor(
    private titleService: Title,
    private translationService: TranslationService,
    private store: Store<AppState>
  ) {}

  public track() {
    this.routerSubscription = this.store
      .select(RouteSelectors.selectRouteData)
      .subscribe(routeData => {
        const pageTitle = routeData && routeData.pageTitle;
        this.titleService.setTitle(
          pageTitle
            ? this.translationService.translate(pageTitle) + ' | My Capstone'
            : 'My Capstone'
        );
      });
  }

  public setTitle(pageTitle: string) {
    this.titleService.setTitle(pageTitle + ' | My Capstone');
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
