import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { NewsActions } from '../actions';
import { LayoutActions, UserActions } from '../../core/actions';
import { Post } from '../../core/dataEntities/feed/post';
import moment from 'moment';
import _ from 'lodash';

export const newsFeatureKey = 'news';

export interface NewsState extends EntityState<Post> {
  isNewsLoading: boolean;
  isPostLoading: boolean;
  activePostId: string;
  currentPage: number;
  total: number;
  errorOccurred: boolean;
  lastSeenNewsDate: string;
  unseenNewsCount: number;
  postDetailsErrorOccurred: boolean;
}

export const adapter: EntityAdapter<Post> = createEntityAdapter<Post>();

export const initialState: NewsState = adapter.getInitialState({
  isNewsLoading: false,
  isPostLoading: false,
  currentPage: null,
  total: null,
  errorOccurred: false,
  lastSeenNewsDate: null,
  unseenNewsCount: null,
  activePostId: null,
  postDetailsErrorOccurred: false
});

export const newsReducer = createReducer(
  initialState,
  on(NewsActions.loadNews, (state, action) => {
    if (action.page === 0) {
      return adapter.removeAll({
        ...initialState,
        isNewsLoading: true,
        currentPage: action.page
      });
    }

    return {
      ...state,
      isNewsLoading: true,
      currentPage: action.page
    };
  }),
  on(NewsActions.loadPost, (state, action) => {
    return {
      ...state,
      activePostId: action.postId,
      isPostLoading: true
    };
  }),
  on(NewsActions.postLoaded, (state, action) => {
    const post = _.cloneDeep(action.post);
    if (action.additionalDetails) {
      const details = action.additionalDetails.postLikeDetails.find(
        postLikeDetails => postLikeDetails.postId === post.id
      );
      post.isLiked = details ? details.isLikedByUser : false;
      post.likes = details ? details.likeCount : 0;
    }
    return adapter.addOne(
      { id: action.postId, ...post },
      {
        ...state,
        postDetailsErrorOccurred: false,
        isPostLoading: false
      }
    );
  }),
  on(NewsActions.newsLoaded, (state, action) => {
    let lastLoadedDay = state.entities[state.ids[state.ids.length - 1]]
      ? state.entities[state.ids[state.ids.length - 1]].date
      : null;
    const posts = _.cloneDeep(action.news.posts);
    const lastSeenNewsDate = action.lastSeenNewsDate;

    posts.map((post: Post) => {
      const postDate = moment(post.date);
      if (
        lastLoadedDay === null ||
        !postDate.isSame(moment(lastLoadedDay), 'day')
      ) {
        post.isFirstDayPost = true;
        lastLoadedDay = post.date;
      }

      if (lastSeenNewsDate) {
        post.isUnseenPost = postDate.isSameOrAfter(moment(lastSeenNewsDate));
      }

      const details = action.additionalData.postLikeDetails.find(
        postLikeDetails => postLikeDetails.postId === post.id
      );

      if (post.shift) {
        post.shift.siteInfo = action.userSites.find(
          site => site.subDept === post.shift.siteId
        );
      }

      post.isLiked = details ? details.isLikedByUser : false;
      post.likes = details ? details.likeCount : 0;
      post.canEdit = post.author.id === action.userId;
    });

    if (action.page === 0) {
      return adapter.addMany(posts, {
        ...state,
        isNewsLoading: false,
        currentPage: action.page,
        total: action.news.total
      });
    }

    return adapter.upsertMany(posts, {
      ...state,
      isNewsLoading: false,
      currentPage: action.page,
      total: action.news.total
    });
  }),
  on(NewsActions.unseenNewsCountLoaded, (state, action) => {
    return {
      ...state,
      unseenNewsCount: action.unseenNewsCount,
      lastSeenNewsDate: action.lastSeenNewsDate
    };
  }),
  on(NewsActions.likePost, (state, action) => {
    const post = state.entities[action.postId];
    const updatePost = {
      id: action.postId,
      changes: {
        isLiked: action.isLiked,
        likes: post.likes + (action.isLiked ? 1 : -1)
      }
    };

    return adapter.updateOne(updatePost, state);
  }),
  on(NewsActions.postLiked, (state, action) => {
    const post = state.entities ? state.entities[action.postId] : null;

    if (post && post.isLiked !== action.isLiked) {
      const updatePost = {
        id: action.postId,
        changes: {
          isLiked: action.isLiked,
          likes: post.likes + (action.isLiked ? 1 : -1)
        }
      };

      return adapter.updateOne(updatePost, state);
    }

    return state;
  }),
  on(NewsActions.removeShiftPostFromFeed, (state, action) => {
    return adapter.removeOne(action.postId, state);
  }),
  on(NewsActions.errorOccurredOnNewsLoading, state => {
    return {
      ...state,
      errorOccurred: true
    };
  }),
  on(NewsActions.errorOccurredOnPostLoading, state => {
    return {
      ...state,
      postDetailsErrorOccurred: true,
      isPostLoading: false
    };
  }),
  on(LayoutActions.showError, LayoutActions.apiOffline, state => {
    return {
      ...state,
      isNewsLoading: false,
      isPostLoading: false
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(state: NewsState | undefined, action: Action) {
  return newsReducer(state, action);
}

const { selectAll } = adapter.getSelectors();
const { selectEntities } = adapter.getSelectors();
export const selectAllLoadedNews = selectAll;

export const selectIsNewsLoading = (state: NewsState) => {
  return state.isNewsLoading;
};

export const selectIsPostLoading = (state: NewsState) => {
  return state.isPostLoading;
};

export const selectCurrentPage = (state: NewsState) => {
  return state.currentPage;
};

export const selectTotalPostsCount = (state: NewsState) => {
  return state.total;
};

export const selectUnseenNewsCount = (state: NewsState) => {
  return state.unseenNewsCount;
};

export const selectIsErrorOccurred = (state: NewsState) => {
  return state.errorOccurred;
};

export const selectActivePostId = (state: NewsState) => {
  return state.activePostId;
};

export const selectIsPostErrorOccurred = (state: NewsState) => {
  return state.postDetailsErrorOccurred;
};

export const selectActivePost = createSelector(
  selectActivePostId,
  selectEntities,
  (activePostId, entities) => {
    return entities[activePostId];
  }
);
