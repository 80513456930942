<div
  role="status"
  [ngClass]="{
    alert: true,
    'alert--white': currentAppearance === AlertAppearanceEnum.White,
    'alert--blue': currentAppearance === AlertAppearanceEnum.Blue,
    'alert--yellow': currentAppearance === AlertAppearanceEnum.Yellow,
    'alert--green': currentAppearance === AlertAppearanceEnum.Green
  }"
>
  <div class="alert__top">
    <app-svg-icon [name]="iconName" class="alert__icon"></app-svg-icon>
    <div class="alert__content">
      <h1 class="alert__title" id="top-sheet-header">{{ title }}</h1>
      <p *ngIf="subtitle">{{ subtitle }}</p>
    </div>
  </div>
  <div class="alert__actions">
    <ng-template #dismiss i18n="@@dismiss">Dismiss</ng-template>

    <button
      class="alert__button alert__button--dismiss"
      type="button"
      (click)="onDismissAlert()"
      cdkMonitorElementFocus
    >
      <ng-container *ngIf="dismissTitle; else dismiss">
        {{ dismissTitle }}
      </ng-container>
    </button>

    <ng-container *ngIf="!actionLink">
      <button
        class="alert__button alert__button--action"
        type="button"
        (click)="onAction()"
        cdkMonitorElementFocus
      >
        {{ actionTitle }}
      </button>
    </ng-container>
    <ng-container *ngIf="actionLink">
      <a
        class="alert__button alert__button--action"
        routerLink="{{ actionLink }}"
        (click)="onDismissAlert()"
        cdkMonitorElementFocus
      >
        {{ actionTitle }}
      </a>
    </ng-container>
  </div>
</div>
