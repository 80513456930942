<app-modal-layout (closeModal)="closeModal()">
  <ng-template #taskNumberTemplate i18n="@@job-details_taskNumber">
    PO: {{ taskDetails.po }}
  </ng-template>

  <app-modal-header
    slot="modal-header"
    i18n-primaryText="@@job-details_title"
    primaryText="Unload details"
    [secondaryTextTemplate]="taskNumberTemplate"
    [date]="taskDetails.date"
    [iconName]="SvgIconNameEnum.Truck"
  ></app-modal-header>

  <div slot="modal-content">
    <app-modal-content-row>
      <app-desc-list>
        <app-desc-list-item
          [dataCy]="'siteName'"
          title="Site name"
          i18n-title="@@job-details_siteName"
          [items]="taskDetails.siteName"
        ></app-desc-list-item>
        <ng-template #oneAssociate i18n="@@associateOnTask">
          1 associate on task
        </ng-template>
        <ng-template #multipleAssociates i18n="@@associatesOnTask">
          {{ taskDetails.associatesOnTask.length }} associates on task
        </ng-template>
        <app-desc-list-item
          [dataCy]="'associatesOnTask'"
          [titleTemplate]="
            taskDetails.associatesOnTask.length > 1
              ? multipleAssociates
              : oneAssociate
          "
          [items]="taskDetails.associatesOnTask"
          [scrollable]="true"
        ></app-desc-list-item>
      </app-desc-list>
    </app-modal-content-row>
    <app-modal-content-row>
      <app-desc-list>
        <app-desc-list-item
          [dataCy]="'loadType'"
          title="Load Type"
          i18n-title="@@job-details_loadType"
          [items]="taskDetails.loadType"
          [columns]="2"
        ></app-desc-list-item>
        <app-desc-list-item
          [dataCy]="'taskDetailsDock'"
          title="Dock"
          i18n-title="@@dock"
          [items]="taskDetails.dock"
          [columns]="2"
        ></app-desc-list-item>
        <app-desc-list-item
          [dataCy]="'vendor'"
          title="Vendor"
          i18n-title="@@job-details_vendor"
          [items]="taskDetails.vendorName"
          [columns]="2"
        ></app-desc-list-item>
        <app-desc-list-item
          [dataCy]="'grossRevenue'"
          title="Gross Revenue"
          i18n-title="@@job-details_grossRevenue"
          [items]="taskDetails.grossRevenue | currency"
          [columns]="2"
        ></app-desc-list-item>
      </app-desc-list>
    </app-modal-content-row>

    <table
      cdk-table
      [dataSource]="tableData.cellData"
      class="table table--small"
    >
      <caption class="visually-hidden" i18n="@@job-details_jobDetails">
        Job Details
      </caption>
      <ng-container cdkColumnDef="type">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          scope="col"
          i18n="@@job-details_type"
        >
          Type
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="table__cell table__cell--bold"
        >
          <ng-container *ngIf="element.type === 'net-revenue'">
            <ng-container i18n="@@netRevenue">Net Revenue</ng-container>
          </ng-container>
          <ng-container *ngIf="element.type === 'earnings-percent'">
            <ng-container i18n="@@job-details_associatePay">
              Associate Pay
            </ng-container>
            %
          </ng-container>
          <ng-container *ngIf="element.type === 'split-percent'">
            <ng-container i18n="@@jobSplit">Job Split</ng-container>
          </ng-container>
        </td>
        <td
          cdk-footer-cell
          *cdkFooterCellDef
          class="table__cell table__cell--bold"
        >
          <ng-container i18n="@@finalJobEarnings">
            Final Job Earnings
          </ng-container>
        </td>
      </ng-container>
      <ng-container cdkColumnDef="amount">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          scope="col"
          class="table__cell table__cell--number"
          i18n="@@job-details_data"
        >
          Data
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="table__cell table__cell--number"
        >
          <ng-container *ngIf="element.isCurrency">
            {{ element.amount | currency }}
          </ng-container>
          <ng-container
            *ngIf="
              element.isCurrency === false &&
              element.type === 'earnings-percent' &&
              !isCaliforniaView
            "
          >
            {{ element.amount | number: '1.2-2' }}%
          </ng-container>
          <ng-container
            *ngIf="
              element.isCurrency === false &&
              element.type === 'earnings-percent' &&
              isCaliforniaView
            "
          >
            N/A
          </ng-container>
          <ng-container
            *ngIf="
              element.isCurrency === false &&
              element.type !== 'earnings-percent'
            "
          >
            {{ element.amount | number: '1.2-2' }}%
          </ng-container>
        </td>
        <td
          cdk-footer-cell
          *cdkFooterCellDef
          class="table__cell table__cell--number"
        >
          {{ !isCaliforniaView ? (tableData.footerData | currency) : 'N/A' }}
        </td>
      </ng-container>

      <tr
        cdk-header-row
        *cdkHeaderRowDef="columns"
        [ngStyle]="{ display: 'none' }"
      ></tr>
      <tr cdk-row *cdkRowDef="let row; columns: columns"></tr>
      <tr cdk-footer-row *cdkFooterRowDef="columns"></tr>
    </table>
  </div>
</app-modal-layout>
