import { createAction, props } from '@ngrx/store';
import { PostDetailsResponse } from '../../core/responses/news/postDetailsResponse';
import { Post } from '../../core/dataEntities/feed/post';
import { Feed } from '../../core/dataEntities/feed/feed';
import { DepartmentInfo } from '../../core/responses/managers/subDepartmentsResponse';

export const loadNews = createAction(
  '[News Feed Component] Load News',
  props<{ page: number; itemsPerPage: number }>()
);

export const newsLoaded = createAction(
  '[News Feed Component] News Loaded',
  props<{
    news: Feed;
    page: number;
    lastSeenNewsDate?: string;
    additionalData: PostDetailsResponse;
    userId: string;
    userSites: DepartmentInfo[];
  }>()
);

export const loadPost = createAction(
  '[News Feed Component] Load Post',
  props<{ postId: string }>()
);

export const postLoaded = createAction(
  '[News Feed Component] Post Loaded',
  props<{
    post: Post;
    postId: string;
    additionalDetails?: PostDetailsResponse;
  }>()
);

export const unseenNewsCountLoaded = createAction(
  '[News Feed Component] Unseen News Count Loaded',
  props<{ unseenNewsCount: number; lastSeenNewsDate: string }>()
);

export const likePost = createAction(
  '[News Feed Component] Like/Dislike Post',
  props<{ postId: string; isLiked: boolean }>()
);

export const postLiked = createAction(
  '[News Feed Component] Post Liked/Disliked',
  props<{ postId: string; isLiked: boolean }>()
);

export const errorOccurredOnNewsLoading = createAction(
  '[News Feed Component] Error Occurred'
);

export const errorOccurredOnPostLoading = createAction(
  '[News Feed Component] Post Details Error Occurred'
);

export const removeShiftPostFromFeed = createAction(
  '[News Feed Component] Remove Shift Post',
  props<{ postId: string }>()
);
