import { Component, OnInit, Input } from '@angular/core';
import { ItemType } from '../../enums/ItemType';

@Component({
  selector: 'app-productivity',
  templateUrl: './productivity.component.html',
  styleUrls: ['./productivity.component.scss']
})
export class ProductivityComponent implements OnInit {
  @Input() itemType: string;
  @Input() currentItemsPerHour: number;
  @Input() currentWorkedHours: number;
  @Input() goalItemsPerHour: number;
  @Input() goalWorkedHours: number;
  @Input() linkToGoalEditPage: string;

  itemTypeEnum = ItemType;

  constructor() {}

  ngOnInit() {}
}
