import { DialogComponent } from './dialog/dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { DialogService } from './dialog/dialog.service';
import { BottomSheetService } from './bottom-sheet/bottom-sheet.service';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { TopSheetComponent } from './top-sheet/top-sheet.component';
import { TopSheetService } from './top-sheet/top-sheet.service';

@NgModule({
  declarations: [DialogComponent, BottomSheetComponent, TopSheetComponent],
  entryComponents: [DialogComponent, BottomSheetComponent, TopSheetComponent],
  imports: [CommonModule, OverlayModule, PortalModule],
  exports: [],
  providers: [DialogService, BottomSheetService, TopSheetService]
})
export class ModalModule {}
