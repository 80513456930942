/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./check-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./check-list.component";
var styles_CheckListComponent = [i0.styles];
var RenderType_CheckListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckListComponent, data: {} });
export { RenderType_CheckListComponent as RenderType_CheckListComponent };
function View_CheckListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 0, 0, currVal_0); }); }
function View_CheckListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CheckListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "list__item"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["defaultTitleTemplate", 2]], null, 0, null, View_CheckListComponent_2)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "dt", [["class", "list__title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-svg-icon", [["class", "list__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(5, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckListComponent_3)), i1.ɵdid(7, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "dd", [["class", "list__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.CheckCircle; _ck(_v, 5, 0, currVal_0); var currVal_1 = (_v.context.$implicit.titleTemplate ? _v.context.$implicit.titleTemplate : i1.ɵnov(_v, 2)); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.content; _ck(_v, 9, 0, currVal_2); }); }
export function View_CheckListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dl", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckListComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listItems; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CheckListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-check-list", [], null, null, null, View_CheckListComponent_0, RenderType_CheckListComponent)), i1.ɵdid(1, 114688, null, 1, i6.CheckListComponent, [], null, null), i1.ɵqud(603979776, 1, { listItems: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckListComponentNgFactory = i1.ɵccf("app-check-list", i6.CheckListComponent, View_CheckListComponent_Host_0, {}, {}, []);
export { CheckListComponentNgFactory as CheckListComponentNgFactory };
