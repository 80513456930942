import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Post } from '../../../../core/dataEntities/feed/post';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { RichTextHtmlRendererOptions } from '../../../constants';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { PostCategoryType } from '../../../../news/enums/PostTypes';

@Component({
  selector: 'app-feed-card-layout',
  templateUrl: './feed-card-layout.component.html',
  styleUrls: ['./feed-card-layout.component.scss'],
  animations: [
    trigger('showMore', [
      state('true', style({ height: '130px' })),
      state('false', style({ height: '*' })),
      transition('false <=> true', animate('0.2s ease-in-out'))
    ])
  ]
})
export class FeedCardLayoutComponent implements OnInit {
  @Input() post: Post;
  @Input() ariaPosinset: number;
  @Input() ariaSetsize: number;
  @Input() isPinnedPost = false;
  @Input() extendableBlogPost = false;
  @Input() isExtended = false;

  @ViewChild('contentfulContent', { static: false })
  contentfulContent: ElementRef;

  public summary: string;

  PostCategoryTypeEnum = PostCategoryType;
  constructor() {}

  ngOnInit() {
    this.summary = documentToHtmlString(
      this.post.summary,
      RichTextHtmlRendererOptions
    );
  }
}
