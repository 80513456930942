/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-section-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./page-section-header.component";
var styles_PageSectionHeaderComponent = [i0.styles];
var RenderType_PageSectionHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageSectionHeaderComponent, data: {} });
export { RenderType_PageSectionHeaderComponent as RenderType_PageSectionHeaderComponent };
function View_PageSectionHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
function View_PageSectionHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_PageSectionHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "h2", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { card__title: 0, "card__title--blue": 1, "card__title--green": 2, "card__title--red": 3, "card__title--gray": 4, "card__title--gold": 5 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageSectionHeaderComponent_3)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, (_co.appearance === _co.PageSectionHeaderAppearanceEnum.Blue), (_co.appearance === _co.PageSectionHeaderAppearanceEnum.Green), (_co.appearance === _co.PageSectionHeaderAppearanceEnum.Red), (_co.appearance === _co.PageSectionHeaderAppearanceEnum.Gray), (_co.appearance === _co.PageSectionHeaderAppearanceEnum.Gold)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.titleTemplate ? _co.titleTemplate : i1.ɵnov(_v.parent, 0)); _ck(_v, 5, 0, currVal_1); }, null); }
function View_PageSectionHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subtitle; _ck(_v, 0, 0, currVal_0); }); }
function View_PageSectionHeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_PageSectionHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "card__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageSectionHeaderComponent_6)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.subTitleTemplate ? _co.subTitleTemplate : i1.ɵnov(_v.parent, 3)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PageSectionHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["defaultTitleTemplate", 2]], null, 0, null, View_PageSectionHeaderComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageSectionHeaderComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["defaultSubtitleTemplate", 2]], null, 0, null, View_PageSectionHeaderComponent_4)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageSectionHeaderComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.title || _co.titleTemplate); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.subtitle || _co.subTitleTemplate); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PageSectionHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-section-header", [], null, null, null, View_PageSectionHeaderComponent_0, RenderType_PageSectionHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.PageSectionHeaderComponent, [], null, null)], null, null); }
var PageSectionHeaderComponentNgFactory = i1.ɵccf("app-page-section-header", i3.PageSectionHeaderComponent, View_PageSectionHeaderComponent_Host_0, { title: "title", subtitle: "subtitle", subTitleTemplate: "subTitleTemplate", titleTemplate: "titleTemplate", appearance: "appearance" }, {}, []);
export { PageSectionHeaderComponentNgFactory as PageSectionHeaderComponentNgFactory };
