<ng-template
  #productivitySubtitle
  i18n="@@net-revenue-stats_productivitySubtitle"
>
  Breakdown of your goal revenue compared to your actual revenue per
  {isItemTypePallet(), plural, =1 {pallet} other {case}}.
</ng-template>
<app-page-section-header
  data-cy="netRevenueStatistics"
  i18n-title="@@net-revenue-stats_netRevenueStatsTitle"
  title="Net revenue statistics"
  [subTitleTemplate]="productivitySubtitle"
></app-page-section-header>
<ul class="net-revenue-stats">
  <ng-template #notAvailable>
    <span
      class="net-revenue-stats__count"
      i18n="@@net-revenue-stats_notAvailable"
    >
      N/A
    </span>
  </ng-template>

  <li class="net-revenue-stats__item">
    <span
      *ngIf="goalRevPerItem; else notAvailable"
      class="net-revenue-stats__count"
    >
      {{ goalRevPerItem | currency }}
    </span>
    <span
      *ngIf="itemType == itemTypeEnum.Pallets"
      class="net-revenue-stats__label"
      i18n="@@net-revenue-stats_goalRevenuePerPallet"
    >
      Goal Revenue per pallet
    </span>
    <span
      *ngIf="itemType == itemTypeEnum.Cases"
      class="net-revenue-stats__label"
      i18n="@@net-revenue-stats_goalRevenuePerCase"
    >
      Goal Revenue per case
    </span>
  </li>
  <li class="net-revenue-stats__item">
    <span
      *ngIf="avgRevPerItem; else notAvailable"
      class="net-revenue-stats__count"
    >
      {{ avgRevPerItem | currency }}
    </span>
    <span
      *ngIf="itemType == itemTypeEnum.Pallets"
      class="net-revenue-stats__label"
      i18n="@@net-revenue-stats_avgRevenuePerPallet"
    >
      Avg. Revenue Per Pallet
    </span>
    <span
      *ngIf="itemType == itemTypeEnum.Cases"
      class="net-revenue-stats__label"
      i18n="@@net-revenue-stats_avgRevenuePerCase"
    >
      Avg. Revenue Per Case
    </span>
  </li>
  <li class="net-revenue-stats__item">
    <span
      *ngIf="siteAvgRevPerItem; else notAvailable"
      class="net-revenue-stats__count"
    >
      {{ siteAvgRevPerItem | currency }}
    </span>
    <span
      *ngIf="itemType == itemTypeEnum.Pallets"
      class="net-revenue-stats__label"
      i18n="@@net-revenue-stats_siteAvgRevenuePerPallet"
    >
      Site Avg. Revenue Per Pallet
    </span>
    <span
      *ngIf="itemType == itemTypeEnum.Cases"
      class="net-revenue-stats__label"
      i18n="@@net-revenue-stats_siteAvgRevenuePerCase"
    >
      Site Avg. Revenue Per Case
    </span>
  </li>
</ul>
