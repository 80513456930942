<app-feed-card-layout
  [post]="post"
  [ariaSetsize]="ariaSetsize"
  [ariaPosinset]="ariaPosinset"
  [isPinnedPost]="isPinnedPost"
>
  <div slot="content">
    <div class="shift-info">
      <app-svg-icon
        [name]="SvgIconNameEnum.Calendar"
        class="shift-info__icon"
      ></app-svg-icon>
      <div class="shift-info__content">
        <span class="shift-info__text shift-info__text--capitalize">
          {{ post.shift.startDateTime | date: 'EEEE, MMMM d' }}
        </span>
        <span class="shift-info__text" i18n="@@shift-request-card_hours">
          {{ post.shift.startDateTime | date: 'hh:mm aa' }} -
          {{ endDate | date: 'hh:mm aa' }} ({{ durationInHours }} hours)
        </span>
      </div>
    </div>
    <div class="shift-info">
      <app-svg-icon
        [name]="SvgIconNameEnum.Location"
        class="shift-info__icon"
      ></app-svg-icon>
      <div class="shift-info__content">
        <span class="shift-info__text">
          {{ post.shift.siteInfo.name }}
        </span>
      </div>
    </div>
  </div>
  <ng-container slot="actions">
    <app-button-link
      *ngIf="post.canEdit"
      [link]="['/shifts/edit', post.id]"
      class="button__edit"
      label="Edit Post"
      i18n-label="@@shift-request-card_editPost"
      [iconName]="SvgIconNameEnum.Pencil"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>
    <app-button-link
      class="button__more"
      label="Learn More"
      [link]="['/shifts/details/', post.id]"
      i18n-label="@@learnMore"
      [iconName]="SvgIconNameEnum.Info"
      [iconColorPrimary]="SvgIconColorEnum.MountainMeadow"
      [iconColorSecondary]="SvgIconColorEnum.AquaSpring"
      [appearance]="ButtonAppearanceEnum.Link"
      [color]="ButtonColorEnum.Green"
    ></app-button-link>
  </ng-container>
</app-feed-card-layout>
