import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DisabledGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (route.data.routeEnabled == null || route.data.routeEnabled === true) {
      return true;
    }

    return this.router.navigate(['']).then(() => {
      return false;
    });
  }
}
