import {
  Component,
  ContentChildren,
  forwardRef,
  OnInit,
  QueryList
} from '@angular/core';
import { CheckListItemComponent } from './check-list-item/check-list-item.component';
import { SvgIconName } from '../../enums/SvgIcon';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit {
  @ContentChildren(forwardRef(() => CheckListItemComponent))
  listItems: QueryList<CheckListItemComponent>;

  public SvgIconNameEnum = SvgIconName;

  constructor() {}

  ngOnInit() {}
}
