import { Pipe, PipeTransform, QueryList } from '@angular/core';
import { FormStepDefDirective } from '../directives/form-step-def.directive';

@Pipe({
  name: 'canStepBeEditable'
})
export class CanStepBeEditablePipe implements PipeTransform {
  transform(
    stepIndex: number,
    formSteps: QueryList<FormStepDefDirective>,
    editableStep: FormStepDefDirective,
    ...args: any[]
  ): any {
    let editableStepIndex = formSteps.length;
    if (formSteps) {
      formSteps.forEach((item, index) => {
        if (item === editableStep) {
          editableStepIndex = index;
          return;
        }
      });
    }
    return stepIndex < editableStepIndex;
  }
}
