import {
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { SvgIconName } from '../../../enums/SvgIcon';

@Component({
  selector: 'app-modal-layout',
  templateUrl: './modal-layout.component.html',
  styleUrls: ['./modal-layout.component.scss']
})
export class ModalLayoutComponent {
  SvgIconNameEnum = SvgIconName;
  @Output()
  closeModal = new EventEmitter();

  constructor() {
  }

  dismissModal() {
    this.closeModal.emit(true);
  }
}
