import { Injectable } from '@angular/core';
import { TranslationService } from '../../../../shared/services/translation.service';
import { TranslationMessages } from '../../../../shared/enums/TranslationMessages';
import { TypeOfIDs } from '../enums/TypeOfIDs';
import { SexType } from '../enums/SexType';
// @ts-ignore
import usaAirports from '../../../../../assets/data/usa_airports.json';

@Injectable()
export class FormUtilitiesService {
  constructor(private translationService: TranslationService) {}

  getIsDriverOptions() {
    return [
      {
        value: true,
        label: this.translationService.translate(
          TranslationMessages.PositiveDriverAvailability
        )
      },
      {
        value: false,
        label: this.translationService.translate(
          TranslationMessages.NegativeDriverAvailability
        )
      }
    ];
  }

  getIsDriverInsuranceOptionsOptions() {
    return [
      {
        value: true,
        label: this.translationService.translate(
          TranslationMessages.IsDriverHasInsuranceYes
        )
      },
      {
        value: false,
        label: this.translationService.translate(
          TranslationMessages.IsDriverHasInsuranceNo
        )
      }
    ];
  }

  getTypesOfID() {
    return [
      {
        id: TypeOfIDs.StateID,
        name: this.translationService.translate(TranslationMessages.StateID)
      },
      {
        id: TypeOfIDs.Passport,
        name: this.translationService.translate(TranslationMessages.Passport)
      },
      {
        id: TypeOfIDs.DriversLicense,
        name: this.translationService.translate(
          TranslationMessages.DriversLicense
        )
      },
      {
        id: TypeOfIDs.Resident,
        name: this.translationService.translate(TranslationMessages.Resident)
      },
      {
        id: TypeOfIDs.MilitaryID,
        name: this.translationService.translate(TranslationMessages.MilitaryID)
      }
    ];
  }

  getUsStates() {
    return [
      { id: 'AL', name: 'Alabama (AL)' },
      { id: 'AK', name: 'Alaska (AK)' },
      { id: 'AZ', name: 'Arizona (AZ)' },
      { id: 'AR', name: 'Arkansas (AR)' },
      { id: 'CA', name: 'California (CA)' },
      { id: 'CO', name: 'Colorado (CO)' },
      { id: 'CT', name: 'Connecticut (CT)' },
      { id: 'DE', name: 'Delaware (DE)' },
      { id: 'DC', name: 'District of Columbia (DC)' },
      { id: 'FL', name: 'Florida (FL)' },
      { id: 'GA', name: 'Georgia (GA)' },
      { id: 'HI', name: 'Hawaii (HI)' },
      { id: 'ID', name: 'Idaho (ID)' },
      { id: 'IL', name: 'Illinois (IL)' },
      { id: 'IN', name: 'Indiana (IN)' },
      { id: 'IA', name: 'Iowa (IA)' },
      { id: 'LA', name: 'Louisiana (LA)' },
      { id: 'KS', name: 'Kansas (KS)' },
      { id: 'KY', name: 'Kentucky (KY)' },
      { id: 'ME', name: 'Maine (ME)' },
      { id: 'MD', name: 'Maryland (MD)' },
      { id: 'MA', name: 'Massachusetts (MA)' },
      { id: 'MI', name: 'Michigan (MI)' },
      { id: 'MN', name: 'Minnesota (MN)' },
      { id: 'MS', name: 'Mississippi (MS)' },
      { id: 'MO', name: 'Missouri (MO)' },
      { id: 'MT', name: 'Montana (MT)' },
      { id: 'NE', name: 'Nebraska (NE)' },
      { id: 'NV', name: 'Nevada (NV)' },
      { id: 'NH', name: 'New Hampshire (NH)' },
      { id: 'NJ', name: 'New Jersey (NJ)' },
      { id: 'NM', name: 'New Mexico (NM)' },
      { id: 'NY', name: 'New York (NY)' },
      { id: 'NC', name: 'North Carolina (NC)' },
      { id: 'ND', name: 'North Dakota (ND)' },
      { id: 'OH', name: 'Ohio (OH)' },
      { id: 'OK', name: 'Oklahoma (OK)' },
      { id: 'OR', name: 'Oregon (OR)' },
      { id: 'PA', name: 'Pennsylvania (PA)' },
      { id: 'RI', name: 'Rhode Island (RI)' },
      { id: 'SC', name: 'South Carolina (SC)' },
      { id: 'SD', name: 'South Dakota (SD)' },
      { id: 'TN', name: 'Tennessee (TN)' },
      { id: 'TX', name: 'Texas (TX)' },
      { id: 'UT', name: 'Utah (UT)' },
      { id: 'VT', name: 'Vermont (VT)' },
      { id: 'VA', name: 'Virginia (VA)' },
      { id: 'WA', name: 'Washington (WA)' },
      { id: 'WV', name: 'West Virginia (WV)' },
      { id: 'WI', name: 'Wisconsin (WI)' },
      { id: 'WY', name: 'Wyoming (WY)' },
      { id: 'AS', name: 'American Samoa (AS)' },
      { id: 'GU', name: 'Guam (GU)' },
      { id: 'MP', name: 'Northern Mariana Islands (MP)' },
      { id: 'PR', name: 'Puerto Rico (PR)' },
      { id: 'VI', name: 'Virgin Islands (VI)' }
    ];
  }

  getDocumentTypesForNonDrivers() {
    return [
      {
        photoName: this.translationService.translate(
          TranslationMessages.PhotoOfId
        ),
        disclaimerText: this.translationService.translate(
          TranslationMessages.DocumentDisclaimerText
        )
      }
    ];
  }

  getDocumentTypesForDrivers(skipAction) {
    return [
      {
        photoName: this.translationService.translate(
          TranslationMessages.FrontOfDriverLicence
        ),
        disclaimerText: this.translationService.translate(
          TranslationMessages.DocumentDisclaimerText
        )
      },
      {
        photoName: this.translationService.translate(
          TranslationMessages.DriverInsuranceCard
        ),
        disclaimerText: this.translationService.translate(
          TranslationMessages.DocumentDisclaimerText
        ),
        canSkip: true,
        skipText: this.translationService.translate(
          TranslationMessages.CameraSkipDriverInsuranceCard
        ),
        skipAction
      }
    ];
  }

  getSexOptions() {
    return [
      {
        id: SexType.Male,
        name: this.translationService.translate(TranslationMessages.Male)
      },
      {
        id: SexType.Female,
        name: this.translationService.translate(TranslationMessages.Female)
      }
    ];
  }

  getAirports() {
    return usaAirports.map(airport => {
      return {
        id: airport.name.trim(),
        display: `${airport.name.trim()} (${airport.IATA}) ${airport.city}`
      };
    });
  }
}
