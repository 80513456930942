/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notice.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./notice.component";
var styles_NoticeComponent = [i0.styles];
var RenderType_NoticeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoticeComponent, data: {} });
export { RenderType_NoticeComponent as RenderType_NoticeComponent };
export function View_NoticeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-svg-icon", [["class", "notice__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "notice__text"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NoticeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notice", [], null, null, null, View_NoticeComponent_0, RenderType_NoticeComponent)), i1.ɵdid(1, 49152, null, 0, i5.NoticeComponent, [], null, null)], null, null); }
var NoticeComponentNgFactory = i1.ɵccf("app-notice", i5.NoticeComponent, View_NoticeComponent_Host_0, { iconName: "iconName" }, {}, ["*"]);
export { NoticeComponentNgFactory as NoticeComponentNgFactory };
