<app-tab-header (selectFocusedIndex)="setActiveTab($event)">
  <div
    class="tab-group__tab"
    role="tab"
    [ngClass]="{
      'tab-group__tab': true,
      'tab-group__tab--active': selectedTabIndex === i
    }"
    *ngFor="let tab of tabs; let i = index"
    [id]="getTabLabelId(i)"
    [attr.aria-selected]="selectedTabIndex === i"
    [attr.aria-controls]="getTabContentId(i)"
    [attr.tabIndex]="getTabIndex(i)"
    (click)="setActiveTab(i)"
    appTabLabelWrapper
    cdkMonitorElementFocus
  >
    <ng-template
      *ngIf="tab.labelTemplate"
      [cdkPortalOutlet]="tab.labelPortal"
    ></ng-template>
    <ng-container *ngIf="!tab.labelTemplate">
      {{ tab.textLabel }}
    </ng-container>
  </div>
</app-tab-header>
<div
  class="tab-group__tab-content"
  role="tabpanel"
  [id]="getTabContentId(selectedTabIndex)"
  [attr.aria-labelledby]="getTabLabelId(selectedTabIndex)"
>
  <ng-template [cdkPortalOutlet]="selectedTab?.contentPortal"></ng-template>
</div>
