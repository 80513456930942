import { Component, OnInit, ContentChildren, forwardRef, QueryList } from '@angular/core';
import { DescListItemComponent } from './desc-list-item/desc-list-item.component';

@Component({
  selector: 'app-desc-list',
  templateUrl: './desc-list.component.html',
  styleUrls: ['./desc-list.component.scss']
})
export class DescListComponent implements OnInit {
  @ContentChildren(forwardRef(() => DescListItemComponent))
  listItems: QueryList<DescListItemComponent>;

  constructor() { }

  ngOnInit() {
  }

}
