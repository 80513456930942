/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/a11y";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../svg-icon/svg-icon.component.ngfactory";
import * as i6 from "../svg-icon/svg-icon.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./alert.component";
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_AlertComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Dismiss"]))], null, null); }
function View_AlertComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dismissTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_AlertComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["cdkMonitorElementFocus", ""], ["class", "alert__button alert__button--action"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i2.CdkMonitorFocus, [i1.ElementRef, i2.FocusMonitor], null, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actionTitle; _ck(_v, 3, 0, currVal_0); }); }
function View_AlertComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["cdkMonitorElementFocus", ""], ["class", "alert__button alert__button--action"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onDismissAlert() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i2.CdkMonitorFocus, [i1.ElementRef, i2.FocusMonitor], null, null), i1.ɵdid(3, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.actionLink, ""); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.actionTitle; _ck(_v, 4, 0, currVal_3); }); }
export function View_AlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["role", "status"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { alert: 0, "alert--white": 1, "alert--blue": 2, "alert--yellow": 3, "alert--green": 4 }), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "alert__top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-svg-icon", [["class", "alert__icon"]], null, null, null, i5.View_SvgIconComponent_0, i5.RenderType_SvgIconComponent)), i1.ɵdid(6, 638976, null, 0, i6.SvgIconComponent, [i7.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "alert__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [["class", "alert__title"], ["id", "top-sheet-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 9, "div", [["class", "alert__actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["dismiss", 2]], null, 0, null, View_AlertComponent_2)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["cdkMonitorElementFocus", ""], ["class", "alert__button alert__button--dismiss"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDismissAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 147456, null, 0, i2.CdkMonitorFocus, [i1.ElementRef, i2.FocusMonitor], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_3)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_4)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_5)), i1.ɵdid(21, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, (_co.currentAppearance === _co.AlertAppearanceEnum.White), (_co.currentAppearance === _co.AlertAppearanceEnum.Blue), (_co.currentAppearance === _co.AlertAppearanceEnum.Yellow), (_co.currentAppearance === _co.AlertAppearanceEnum.Green)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.iconName; _ck(_v, 6, 0, currVal_1); var currVal_3 = _co.subtitle; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.dismissTitle; var currVal_5 = i1.ɵnov(_v, 13); _ck(_v, 17, 0, currVal_4, currVal_5); var currVal_6 = !_co.actionLink; _ck(_v, 19, 0, currVal_6); var currVal_7 = _co.actionLink; _ck(_v, 21, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 9, 0, currVal_2); }); }
export function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 638976, null, 0, i8.AlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i1.ɵccf("app-alert", i8.AlertComponent, View_AlertComponent_Host_0, { title: "title", subtitle: "subtitle", iconName: "iconName", appearance: "appearance", actionLink: "actionLink", actionTitle: "actionTitle", dismissTitle: "dismissTitle" }, { closeAlert: "closeAlert" }, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
