import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  AppState,
  selectAllPayHistories,
  selectIsPayHistoryLoading
} from '../../../reducers';
import { WagesActions } from '../../actions';
import { WeekPaySummary } from '../../../core/dataEntities/wages';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { MenuColorAppearance, MenuTitleFont } from '../../../shared/enums/Menu';
import { Features } from '../../../core/enums/Features';
import { Roles } from '../../../core/enums/Roles';

@Component({
  selector: 'app-pay-history',
  templateUrl: './pay-history.component.html',
  styleUrls: ['./pay-history.component.scss']
})
export class PayHistoryComponent implements OnInit {
  public paymentHistory: Observable<WeekPaySummary[]>;
  public isPayHistoryLoading$: Observable<boolean>;
  public isRoadCrewEnabled$: Observable<boolean>;
  public roadCrewRegistrationMissing$: Observable<string>;
  public associateHomeState$: Observable<string>;

  SvgIconNameEnum = SvgIconName;
  PageTitleAppearanceEnum = PageTitleAppearance;
  MenuAppearanceEnum = MenuColorAppearance;
  MenuTitleFontEnum = MenuTitleFont;
  FeaturesEnum = Features;
  RolesEnum = Roles;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.paymentHistory = this.store.select(selectAllPayHistories);
    this.isPayHistoryLoading$ = this.store.select(selectIsPayHistoryLoading);
    this.store.dispatch(WagesActions.loadPayHistory());
  }
}
