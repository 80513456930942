<app-form-field>
  <app-label i18n="@@certification-edit_certificationsLabel">
    Are you certified/trained in...
  </app-label>
  <app-nested-checkbox-list
    [formControl]="formGroup.get('certificationsChecked')"
    [items]="certificationsList"
  ></app-nested-checkbox-list>
</app-form-field>
<app-form-field>
  <app-label i18n="@@certification-edit_additionalCertificationsLabel">
    Any additional skills/certifications
  </app-label>
  <app-text-area
    [formControl]="formGroup.get('additionalCertifications')"
  ></app-text-area>
</app-form-field>
<app-notice class="certification-edit__notice" i18n="@@certification-edit_note">
  Note: Your manager will review, edit, and confirm any certifications added.
</app-notice>
