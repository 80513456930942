/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/expandable-panel/expandable-panel.component.ngfactory";
import * as i3 from "../../components/expandable-panel/expandable-panel.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../shared/components/page-title/page-title.component.ngfactory";
import * as i6 from "../../../shared/components/page-title/page-title.component";
import * as i7 from "../../../shared/components/empty-state/empty-state.component.ngfactory";
import * as i8 from "../../../shared/components/empty-state/empty-state.component";
import * as i9 from "../../../shared/services/translation.service";
import * as i10 from "../../../shared/components/page-header/page-header-container.component.ngfactory";
import * as i11 from "../../../shared/components/page-header/page-header-container.component";
import * as i12 from "@ngrx/store";
import * as i13 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i14 from "../../../shared/components/loader/loader.component";
import * as i15 from "./page-container.component";
import * as i16 from "@angular/router";
var styles_PageContainerComponent = [i0.styles];
var RenderType_PageContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageContainerComponent, data: {} });
export { RenderType_PageContainerComponent as RenderType_PageContainerComponent };
function View_PageContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expandable-panel", [], null, null, null, i2.View_ExpandablePanelComponent_0, i2.RenderType_ExpandablePanelComponent)), i1.ɵdid(1, 49152, null, 0, i3.ExpandablePanelComponent, [], { panel: [0, "panel"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.expandablePanel; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PageContainerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "page__text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.convertToHtmlString(_v.parent.context.$implicit.text.body); _ck(_v, 0, 0, currVal_0); }); }
function View_PageContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageContainerComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageContainerComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.expandablePanel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.text; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PageContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "article", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-page-title", [], null, null, null, i5.View_PageTitleComponent_0, i5.RenderType_PageTitleComponent)), i1.ɵdid(2, 114688, null, 0, i6.PageTitleComponent, [], { title: [0, "title"], appearance: [1, "appearance"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageContainerComponent_2)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf.title; var currVal_1 = _co.PageTitleAppearanceEnum.Blue; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.ngIf.body; _ck(_v, 4, 0, currVal_2); }, null); }
function View_PageContainerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Page Not Found "]))], null, null); }
function View_PageContainerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-empty-state", [], null, null, null, i7.View_EmptyStateComponent_0, i7.RenderType_EmptyStateComponent)), i1.ɵdid(2, 114688, null, 0, i8.EmptyStateComponent, [i9.TranslationService], { emptyStateTemplate: [0, "emptyStateTemplate"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 9); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PageContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-header-container", [], null, null, null, i10.View_PageHeaderContainerComponent_0, i10.RenderType_PageHeaderContainerComponent)), i1.ɵdid(1, 114688, null, 0, i11.PageHeaderContainerComponent, [i12.Store], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 11, "main", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "app-loader", [], [[2, "is-visible", null]], null, null, i13.View_LoaderComponent_0, i13.RenderType_LoaderComponent)), i1.ɵdid(4, 114688, null, 0, i14.LoaderComponent, [], { isLoading: [0, "isLoading"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PageContainerComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["emptyStateMessage", 2]], null, 0, null, View_PageContainerComponent_5)), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_PageContainerComponent_6)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.isPageLoading$)); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.dynamicPage$)); _ck(_v, 7, 0, currVal_2); var currVal_3 = ((i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.isPageLoading$)) === false) && (i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 13).transform(_co.dynamicPage$)) === null)); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).visible; _ck(_v, 3, 0, currVal_0); }); }
export function View_PageContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-container", [], null, null, null, View_PageContainerComponent_0, RenderType_PageContainerComponent)), i1.ɵdid(1, 114688, null, 0, i15.PageContainerComponent, [i16.ActivatedRoute, i12.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageContainerComponentNgFactory = i1.ɵccf("app-page-container", i15.PageContainerComponent, View_PageContainerComponent_Host_0, {}, {}, []);
export { PageContainerComponentNgFactory as PageContainerComponentNgFactory };
