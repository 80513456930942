import { createAction, props } from '@ngrx/store';
import { WeeklyPerformanceResponse } from '../../../../core/responses/goal/weeklyPerformanceResponse';
import { GoalResponse } from '../../../../core/responses/goal/goalResponse';
import { GoalDetails } from '../../../../core/dataEntities/goal/goalDetails';

export const loadWeeklyGoal = createAction(
  '[Goal Component] Load Goal',
  props<{ weekEndingDate: string }>()
);

export const goalLoaded = createAction(
  '[Goal Component] Goal Loaded',
  props<{ goal: GoalResponse }>()
);

export const loadWeeklyPerformance = createAction(
  '[Goal Component] Load Weekly Performance',
  props<{ weekEndingDate: string }>()
);

export const weeklyPerformanceLoaded = createAction(
  '[Goal Component] Weekly Performance Loaded',
  props<{ performance: WeeklyPerformanceResponse }>()
);

export const submitGoal = createAction(
  '[Goal Component] Submit Goal',
  props<{ goal: GoalDetails }>()
);

export const goalSubmitted = createAction(
  '[Goal Component] Goal Submitted',
  props<{ goal: GoalDetails }>()
);

export const toggleGoalLoading = createAction(
  '[Goal Component] Toggle Goal Loading'
);

export const togglePerformanceLoading = createAction(
  '[Goal Component] Toggle Performance Loading'
);
