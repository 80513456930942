import { Component, Input, OnInit } from '@angular/core';
import { ItemType } from '../../enums/ItemType';

@Component({
  selector: 'app-net-revenue-stats',
  templateUrl: './net-revenue-stats.component.html',
  styleUrls: ['./net-revenue-stats.component.scss']
})
export class NetRevenueStatsComponent implements OnInit {

  @Input() itemType: string;
  @Input() goalRevPerItem;
  @Input() avgRevPerItem;
  @Input() siteAvgRevPerItem;

  public itemTypeEnum = ItemType;

  constructor() { }

  ngOnInit() { }

  isItemTypePallet() {
    return this.itemType === ItemType.Pallets ? 1 : 0;
  }
}
