import { NgModule } from '@angular/core';
import { Ng5SliderModule } from 'ng5-slider';
import { SharedComponentsModule } from '../../../shared/components/shared-components/shared-components.module';
import { GoalService } from './services/goal.service';
import { GoalRoutingModule } from './goal-routing.module';
import { GoalComponent } from './goal.component';
import { WeeklyGoalContainerComponent } from './containers/weekly-goal-container/weekly-goal-container.component';
import { WeeklyGoalComponent } from './components/weekly-goal/weekly-goal.component';
import { CurrentGoalComponent } from './components/current-goal/current-goal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { GoalEffects } from './effects/goal.effects';
import { GoalInputCardComponent } from './components/goal-input-card/goal-input-card.component';
import { PipesModule } from '../../../shared/pipes/pipes.module';
import { GoalProgressComponent } from './components/goal-progress/goal-progress.component';
import { NetRevenueStatsComponent } from './components/net-revenue-stats/net-revenue-stats.component';
import { ProductivityComponent } from './components/productivity/productivity.component';
import { InfoBoxModule } from '../../../shared/modules/info-box/info-box.module';
import { GoalSliderComponent } from './components/slider/goal-slider.component';
import { GoalSliderInputComponent } from './components/goal-input/goal-slider-input.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  declarations: [
    GoalComponent,
    WeeklyGoalComponent,
    WeeklyGoalContainerComponent,
    GoalInputCardComponent,
    CurrentGoalComponent,
    GoalProgressComponent,
    ProductivityComponent,
    NetRevenueStatsComponent,
    GoalSliderComponent,
    GoalSliderInputComponent
  ],
  imports: [
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([GoalEffects]),
    PipesModule,
    InfoBoxModule,
    GoalRoutingModule,
    Ng5SliderModule,
    A11yModule
  ],
  providers: [GoalService],
  exports: [
    GoalComponent,
    WeeklyGoalComponent,
    WeeklyGoalContainerComponent,
    GoalInputCardComponent,
    CurrentGoalComponent,
    GoalProgressComponent,
    ProductivityComponent,
    NetRevenueStatsComponent
  ]
})
export class GoalModule {}
