<app-modal-layout (closeModal)="closeModal()">
  <ng-template #secondaryText i18n="@@skip-check-in_skipSecondaryText">
    Skip Your Check-In?
  </ng-template>

  <app-modal-header
    slot="modal-header"
    [secondaryTextTemplate]="secondaryText"
  ></app-modal-header>
  <div slot="modal-content">
    <app-modal-content-row [sideSpacing]="true">
      <h2
        class="skip-check-in__heading"
        i18n="@@skip-check-in_yourCheckInMatters"
      >
        Your check-in matters.
      </h2>
      <p i18n="@@skip-check-in_yourCheckInMattersAnswer">
        Weekly Check-Ins allow you to tell your boss how your week and if you
        need any assistance.
      </p>
    </app-modal-content-row>
    <div class="skip-check-in__actions">
      <ng-template #skipButton i18n="@@skip-check-in_skipForNow">
        Skip for now
      </ng-template>

      <ng-template #backButton i18n="@@skip-check-in_backToCheckIn">
        Back to Check-In
      </ng-template>

      <app-button-link
        (buttonClick)="navigateBack()"
        [labelTemplate]="skipButton"
        [color]="ButtonColorEnum.Red"
        [appearance]="ButtonAppearanceEnum.Link"
      ></app-button-link>

      <app-button-link
        (buttonClick)="closeModal()"
        [labelTemplate]="backButton"
        [appearance]="ButtonAppearanceEnum.Secondary"
      ></app-button-link>
    </div>
  </div>
</app-modal-layout>
