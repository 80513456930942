<app-page-header-container></app-page-header-container>

<app-loader [isLoading]="isMainLoaderVisible$ | async"></app-loader>

<main class="main-container" *ngIf="teamMemberInfo$ | async as teamMemberInfo">
  <ng-container *ngIf="(isTeamMemberPhotoRemoving$ | async) === false">
    <app-associate-page-header
      [userName]="teamMemberInfo.name"
      [apexId]="teamMemberInfo.apexId"
      [isTrainee]="teamMemberInfo.isTrainee"
      [photoSrc]="teamMemberInfo.profilePhoto"
      (deletePhotoEvent)="deletePhoto()"
    ></app-associate-page-header>

    <div class="container">
      <app-team-member-tabs></app-team-member-tabs>

      <app-loader [isLoading]="isWeeklyCheckinLoading$ | async"></app-loader>

      <ng-container
        *ngIf="
          (isWeeklyCheckinLoading$ | async) === false && weeklyCheckinList$
            | async as weeklyCheckinList
        "
      >
        <app-check-in-history
          [lastWeeklyCheckin]="lastWeeklyCheckin$ | async"
          [userName]="teamMemberInfo.name"
          [weeklyCheckinList]="weeklyCheckinList"
        ></app-check-in-history>
      </ng-container>
    </div>
  </ng-container>
</main>

<ng-template #emptyStateTemplate i18n="@@team_member_empty-state">
  Team member not found.
</ng-template>

<app-empty-state
  *ngIf="
    (teamMemberInfo$ | async) === null &&
    (isWeeklyCheckinLoading$ | async) === false
  "
  [emptyStateTemplate]="emptyStateTemplate"
></app-empty-state>
