import { Component, Inject, OnInit } from '@angular/core';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import {
  ButtonAppearance,
  ButtonColor,
  ButtonType
} from '../../../../../shared/enums/ButtonLink';
import { DialogRef } from '../../../../../shared/modules/modal/dialog-ref';
import { DIALOG_DATA } from '../../../../../shared/modules/modal/dialog-config';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-update-location',
  templateUrl: './update-location.component.html',
  styleUrls: ['./update-location.component.scss']
})
export class UpdateLocationComponent implements OnInit {
  SvgIconNameEnum = SvgIconName;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;
  ButtonTypeEnum = ButtonType;

  public form: FormGroup;
  public preferredAirport: string;
  public airports: any;

  constructor(
    private modalRef: DialogRef<UpdateLocationComponent>,
    @Inject(DIALOG_DATA) data: any
  ) {
    this.preferredAirport = data.preferredAirport;
    this.airports = data.airports;
  }

  ngOnInit() {
    this.form = new FormGroup({
      preferredAirport: new FormControl(
        this.preferredAirport,
        Validators.required
      )
    });
  }

  closeModal() {
    this.modalRef.close({ success: false });
  }

  updateAirport() {
    if (this.form.valid) {
      this.modalRef.close({
        success: true,
        data: {
          preferredAirport: this.form.value.preferredAirport
        }
      });
    }
  }
}
