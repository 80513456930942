import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WagesComponent } from './wages.component';
import { PayHistoryComponent } from './containers/pay-history/pay-history.component';
import { PayComponent } from './containers/pay/pay.component';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { TrainingComponent } from './containers/training/training.component';
import { Module } from '../shared/enums/Module';
import { GoalComponent } from './containers/goal/goal.component';
import { GoalPreviewGuard } from './modules/goal/guards/goal-preview.guard';

const routes: Routes = [
  {
    path: 'wages',
    component: WagesComponent,
    canActivate: [AuthenticationGuard],
    data: {
      pageTitle: TranslationMessages.WagesHistoryTitle
    },
    children: [
      {
        path: '',
        component: PayHistoryComponent,
        data: {
          module: Module.Wages,
          showMenuBar: true,
          pageTitle: TranslationMessages.WagesHistoryTitle,
          siteMapKey: SiteMapItemKeys.Wages
        }
      },
      {
        path: ':id/pay',
        component: PayComponent,
        data: {
          module: Module.Wages,
          pageTitle: TranslationMessages.WagesPayTitle,
          siteMapKey: SiteMapItemKeys.WagesDetails
        }
      },
      {
        path: ':id/training',
        component: TrainingComponent,
        data: {
          module: Module.Wages,
          siteMapKey: SiteMapItemKeys.Training
        }
      },
      {
        path: ':id/goal',
        canActivate: [GoalPreviewGuard],
        component: GoalComponent,
        data: {
          module: Module.Wages,
          pageTitle: TranslationMessages.GoalBreakdownTitle,
          siteMapKey: SiteMapItemKeys.Goal
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WagesRoutingModule {}
