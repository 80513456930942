<app-card-layout>
  <app-card-layout-item>
    <app-chip
      [appearance]="ChipAppearanceEnum.Red"
      class="survey-card__chip"
      i18n="@@survey-card_new"
    >
      New
    </app-chip>
    <app-page-section-header
      [title]="survey.title"
      [subtitle]="survey.subtitle"
    ></app-page-section-header>
    <app-button-link
      class="survey-card__button"
      [link]="['/survey/', survey.id]"
      [color]="ButtonColorEnum.Green"
      i18n-label="@@survey-card_takeSurvey"
      label="Take Survey"
    ></app-button-link>
  </app-card-layout-item>
</app-card-layout>
