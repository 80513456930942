import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { OpportunityRow } from '../../view-models/opportunity-row';
import { StringHelpers } from 'src/app/shared/helpers/string.helpers';
import { SvgIconColor, SvgIconName } from '../../../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-opportunities-list',
  templateUrl: './opportunities-list.component.html',
  styleUrls: ['./opportunities-list.component.scss']
})
export class OpportunitiesListComponent implements OnInit {
  StringHelpers = StringHelpers;

  @Input() opportunitiesList: OpportunityRow[];
  @Input() page: any;
  @Input() totalCount: number;

  @Output() openOpportunity = new EventEmitter();
  @Output() changePage = new EventEmitter();

  SvgIconName = SvgIconName;
  SvgIconColor = SvgIconColor;

  constructor() {}

  ngOnInit() {}
}
