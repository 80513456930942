/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/components/accordion/accordion-panel.component.ngfactory";
import * as i3 from "../../../../../../shared/components/accordion/accordion-panel.component";
import * as i4 from "../../../../../../shared/components/accordion/accordion.service";
import * as i5 from "../../../../../../shared/services/analytics-utilities.service";
import * as i6 from "../../../../../../shared/components/accordion/accordion.component.ngfactory";
import * as i7 from "../../../../../../shared/components/accordion/accordion.component";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../../shared/components/form/form-field/form-field.component.ngfactory";
import * as i10 from "../../../../../../shared/components/form/form-field/form-field.component";
import * as i11 from "../../../../../../shared/components/form/root-form/root-form.directive";
import * as i12 from "../../../../../../shared/components/form/signature/signature.component.ngfactory";
import * as i13 from "../../../../../../shared/components/form/signature/signature.component";
import * as i14 from "@angular/forms";
import * as i15 from "../../../../../../shared/components/form/form-field/form-field.control";
import * as i16 from "../../../../../../shared/components/form/validation/validation.component.ngfactory";
import * as i17 from "../../../../../../shared/components/form/validation/validation.component";
import * as i18 from "../../../../../../shared/components/button-link/button-link.component.ngfactory";
import * as i19 from "../../../../../../shared/components/button-link/button-link.component";
import * as i20 from "./signature-edit.component";
var styles_SignatureEditComponent = [i0.styles];
var RenderType_SignatureEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureEditComponent, data: {} });
export { RenderType_SignatureEditComponent as RenderType_SignatureEditComponent };
function View_SignatureEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-accordion-panel", [], null, null, null, i2.View_AccordionPanelComponent_0, i2.RenderType_AccordionPanelComponent)), i1.ɵdid(1, 49152, [[1, 4]], 0, i3.AccordionPanelComponent, [i4.AccordionService, i5.AnalyticsUtilitiesService, i1.ElementRef], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(2, 0, null, 1, 0, "div", [["slot", "body"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.content; _ck(_v, 2, 0, currVal_1); }); }
export function View_SignatureEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "signature-edit__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Capstone Logistics Road Crew Contract\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You have been chosen by Capstone Logistics, Inc. to participate in a Startup, Recovery Project or Special Project. Capstone Logistics is positioned as the best and most professional partner in our industry. This contract is designed to give you the information needed to make this trip a success. Capstone Logistics is counting on YOU to present outstanding work ethic to our partner and new associates. All Capstone Logistics Road Crew Associates are required to abide by the following guidelines:\n"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "app-accordion", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_AccordionComponent_0, i6.RenderType_AccordionComponent)), i1.ɵprd(512, null, i4.AccordionService, i4.AccordionService, []), i1.ɵdid(6, 1294336, null, 1, i7.AccordionComponent, [i4.AccordionService], null, null), i1.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SignatureEditComponent_1)), i1.ɵdid(9, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 14, "app-form-field", [], null, null, null, i9.View_FormFieldComponent_0, i9.RenderType_FormFieldComponent)), i1.ɵdid(11, 114688, null, 3, i10.FormFieldComponent, [[2, i11.RootFormDirective]], { required: [0, "required"] }, null), i1.ɵqud(335544320, 2, { control: 0 }), i1.ɵqud(603979776, 3, { label: 0 }), i1.ɵqud(335544320, 4, { validationContainer: 0 }), i1.ɵprd(2048, null, i10.FORM_FIELD, null, [i10.FormFieldComponent]), (_l()(), i1.ɵeld(16, 0, null, 1, 6, "app-signature", [["placeholder", "Sign on the line with your finger"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_SignatureComponent_0, i12.RenderType_SignatureComponent)), i1.ɵdid(17, 4440064, null, 0, i13.SignatureComponent, [i14.FormBuilder, [2, i10.FORM_FIELD]], { name: [0, "name"], surname: [1, "surname"], date: [2, "date"], placeholder: [3, "placeholder"] }, null), i1.ɵprd(1024, null, i14.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i13.SignatureComponent]), i1.ɵdid(19, 540672, null, 0, i14.FormControlDirective, [[8, null], [8, null], [6, i14.NG_VALUE_ACCESSOR], [2, i14.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i14.NgControl, null, [i14.FormControlDirective]), i1.ɵdid(21, 16384, null, 0, i14.NgControlStatus, [[4, i14.NgControl]], null, null), i1.ɵprd(2048, [[2, 4]], i15.FormFieldControl, null, [i13.SignatureComponent]), (_l()(), i1.ɵeld(23, 0, null, 2, 1, "app-validation", [], null, null, null, i16.View_ValidationComponent_0, i16.RenderType_ValidationComponent)), i1.ɵdid(24, 114688, [[4, 4]], 0, i17.ValidationComponent, [], { errors: [0, "errors"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 2, "div", [["class", "signature-edit__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "app-button-link", [["label", "Submit Registration"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_ButtonLinkComponent_0, i18.RenderType_ButtonLinkComponent)), i1.ɵdid(27, 638976, null, 0, i19.ButtonLinkComponent, [i5.AnalyticsUtilitiesService], { appearance: [0, "appearance"], type: [1, "type"], color: [2, "color"], label: [3, "label"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_0 = _co.termsAndConditions; _ck(_v, 9, 0, currVal_0); var currVal_1 = true; _ck(_v, 11, 0, currVal_1); var currVal_9 = _co.firstName; var currVal_10 = _co.lastName; var currVal_11 = _co.signatureDate; var currVal_12 = "Sign on the line with your finger"; _ck(_v, 17, 0, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = _co.formGroup.get("signature"); _ck(_v, 19, 0, currVal_13); var currVal_14 = _co.formGroup.get("signature").errors; _ck(_v, 24, 0, currVal_14); var currVal_15 = _co.ButtonAppearanceEnum.Primary; var currVal_16 = _co.ButtonTypeEnum.Submit; var currVal_17 = _co.ButtonColorEnum.Blue; var currVal_18 = "Submit Registration"; _ck(_v, 27, 0, currVal_15, currVal_16, currVal_17, currVal_18); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 21).ngClassValid; var currVal_7 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 21).ngClassPending; _ck(_v, 16, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_SignatureEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signature-edit", [], null, null, null, View_SignatureEditComponent_0, RenderType_SignatureEditComponent)), i1.ɵdid(1, 114688, null, 0, i20.SignatureEditComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignatureEditComponentNgFactory = i1.ɵccf("app-signature-edit", i20.SignatureEditComponent, View_SignatureEditComponent_Host_0, { formGroup: "formGroup", termsAndConditions: "termsAndConditions" }, { submitForm: "submitForm" }, []);
export { SignatureEditComponentNgFactory as SignatureEditComponentNgFactory };
