import { AbstractControl, ValidatorFn } from '@angular/forms';

export function DigitsOnlyValidator(): ValidatorFn {
  return (control: AbstractControl): any => {
    if (!control.value) {
      return null;
    }
    const DIGITS_REGEXP = new RegExp(/^\d*$/);
    if (DIGITS_REGEXP.test(control.value)) {
      return null;
    }
    return {
      digitsOnly: true
    };
  };
}
