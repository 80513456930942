/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./label.component";
var styles_LabelComponent = [];
var RenderType_LabelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LabelComponent, data: {} });
export { RenderType_LabelComponent as RenderType_LabelComponent };
export function View_LabelComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_LabelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-label", [], null, null, null, View_LabelComponent_0, RenderType_LabelComponent)), i0.ɵdid(1, 49152, null, 0, i1.LabelComponent, [], null, null)], null, null); }
var LabelComponentNgFactory = i0.ɵccf("app-label", i1.LabelComponent, View_LabelComponent_Host_0, { asLegend: "asLegend", hidden: "hidden" }, {}, ["*"]);
export { LabelComponentNgFactory as LabelComponentNgFactory };
