import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { SvgIconName, SvgIconColor } from '../../enums/SvgIcon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit, OnChanges {
  @Input() name: SvgIconName;
  @Input() description?: string;
  @Input() colorPrimary?: SvgIconColor;
  @Input() colorSecondary?: SvgIconColor;
  iconColors;

  constructor(private sanitized: DomSanitizer) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.colorPrimary || changes.colorSecondary) {
      this.iconColors = this.setIconColors();
    }
  }

  setIconColors() {
    let styles = '';
    if (this.colorPrimary) {
      styles += '--color-icon-primary:' + this.colorPrimary + ';';
    }

    if (this.colorSecondary) {
      styles += '--color-icon-secondary:' + this.colorSecondary + ';';
    }

    return this.sanitized.bypassSecurityTrustStyle(styles);
  }
}
