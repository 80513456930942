import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-desc-list-item',
  templateUrl: './desc-list-item.component.html'
})
export class DescListItemComponent implements OnInit {
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() items: string | string[];
  @Input() columns = 1;
  @Input() scrollable = false;
  @Input() dataCy: string;
  isArray;

  constructor() { }

  ngOnInit() {
    this.isArray = Array.isArray(this.items);
  }

}
