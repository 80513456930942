import { createAction, props } from '@ngrx/store';

import { SurveyResponse } from '../../core/responses/surveys/surveyResponse';
import { SurveySummaryResponse } from '../../core/responses/surveys/surveySummaryResponse';
import { Survey } from '../../core/dataEntities/surveys/survey';

export const loadSurveys = createAction('[Surveys Component] Load Surveys');

export const surveysLoaded = createAction(
  '[Surveys Component] Surveys Loaded',
  props<{ surveys: SurveySummaryResponse[] }>()
);

export const loadSurvey = createAction(
  '[Surveys Component] Load Survey Details',
  props<{ id: string }>()
);

export const surveyLoaded = createAction(
  '[Surveys Component] Loaded Survey Details',
  props<{ survey: SurveyResponse }>()
);

export const submitSurvey = createAction(
  '[Surveys Component] Submit Survey',
  props<{ survey: Survey }>()
);

export const surveySubmitted = createAction(
  '[Surveys Component] Survey Submitted',
  props<{ id: string }>()
);

export const surveysStatusChecked = createAction(
  '[Surveys Component] Surveys Status Checked'
);

export const getIsEachSurveySubmitted = createAction(
  '[Surveys Component] Get Is Each Survey Submitted'
);

export const saveIsEachSurveySubmitted = createAction(
  '[Surveys Component] Save Is Each Survey Submitted',
  props<{
    result: boolean;
  }>()
);
