/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./remainder.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/modal/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../../shared/components/modal/modal-layout/modal-layout.component";
import * as i4 from "../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i5 from "../../../shared/components/svg-icon/svg-icon.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../shared/components/button-link/button-link.component.ngfactory";
import * as i8 from "../../../shared/components/button-link/button-link.component";
import * as i9 from "../../../shared/services/analytics-utilities.service";
import * as i10 from "./remainder.component";
import * as i11 from "@angular/router";
import * as i12 from "../../../shared/modules/modal/dialog-ref";
import * as i13 from "../../../shared/modules/modal/dialog-config";
var styles_RemainderComponent = [i0.styles];
var RenderType_RemainderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RemainderComponent, data: {} });
export { RenderType_RemainderComponent as RenderType_RemainderComponent };
export function View_RemainderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "app-modal-layout", [], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalLayoutComponent, [], null, { closeModal: "closeModal" }), (_l()(), i1.ɵeld(2, 0, null, 1, 13, "div", [["slot", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "modal-header__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-svg-icon", [["class", "modal-content__icon"]], null, null, null, i4.View_SvgIconComponent_0, i4.RenderType_SvgIconComponent)), i1.ɵdid(8, 638976, null, 0, i5.SvgIconComponent, [i6.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "modal-content__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-button-link", [["label", "Maybe later"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ButtonLinkComponent_0, i7.RenderType_ButtonLinkComponent)), i1.ɵdid(13, 638976, null, 0, i8.ButtonLinkComponent, [i9.AnalyticsUtilitiesService], { appearance: [0, "appearance"], size: [1, "size"], label: [2, "label"] }, { buttonClick: "buttonClick" }), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-button-link", [["label", "Take Survey"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.takeSurvey() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ButtonLinkComponent_0, i7.RenderType_ButtonLinkComponent)), i1.ɵdid(15, 638976, null, 0, i8.ButtonLinkComponent, [i9.AnalyticsUtilitiesService], { size: [0, "size"], label: [1, "label"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.SvgIconNameEnum.Survey; _ck(_v, 8, 0, currVal_1); var currVal_3 = _co.ButtonAppearanceEnum.Link; var currVal_4 = _co.ButtonSizeEnum.Small; var currVal_5 = "Maybe later"; _ck(_v, 13, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.ButtonSizeEnum.Small; var currVal_7 = "Take Survey"; _ck(_v, 15, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.remainderHeader; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.data.remainderText; _ck(_v, 10, 0, currVal_2); }); }
export function View_RemainderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-remainder", [], null, null, null, View_RemainderComponent_0, RenderType_RemainderComponent)), i1.ɵdid(1, 114688, null, 0, i10.RemainderComponent, [i11.Router, i12.DialogRef, i13.DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RemainderComponentNgFactory = i1.ɵccf("app-remainder", i10.RemainderComponent, View_RemainderComponent_Host_0, {}, {}, []);
export { RemainderComponentNgFactory as RemainderComponentNgFactory };
