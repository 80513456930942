import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wages',
  templateUrl: './wages.component.html',
  styleUrls: ['./wages.component.scss']
})
export class WagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
