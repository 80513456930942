import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { routerReducer } from '@ngrx/router-store';
import * as fromPayHistory from '../wages/reducers/pay-history.reducer';
import * as fromWeekPay from '../wages/reducers/week-pay.reducer';
import * as fromLayout from '../core/reducers/layout.reducer';
import * as fromTranslations from '../core/reducers/translation.reducer';
import * as fromUser from '../core/reducers/user.reducer';
import { InjectionToken } from '@angular/core';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as fromSurveys from '../surveys/reducers/surveys.reducer';
import * as fromSurveyDetails from '../surveys/reducers/survey-details.reducer';
import * as fromGoal from '../wages/modules/goal/reducers/goal.reducer';
import * as fromPerformance from '../wages/modules/goal/reducers/performance.reducer';
import * as fromOpportunityDetails from '../wages/modules/work/reducers/opportunity-details.reducer';
import * as fromNews from '../news/reducers/news.reducer';
import * as fromResources from '../resources/reducers/resources.reducer';
import * as fromStarsFeed from '../resources/reducers/stars-feed.reducer';
import * as fromShift from '../news/modules/shifts/reducers/shift.reducer';
import * as fromMyTeam from '../my-team/reducers/my-team.reducer';
import * as fromMyTeamMember from '../my-team/reducers/team-member-details.reducer';

export interface AppState {
  [fromPayHistory.payHistoryFeatureKey]: fromPayHistory.WagesState;
  [fromWeekPay.weekPayFeatureKey]: fromWeekPay.WeekPayState;
  [fromLayout.layoutFeatureKey]: fromLayout.LayoutState;
  [fromTranslations.translationsFeatureKey]: fromTranslations.TranslationState;
  [fromUser.userFeatureKey]: fromUser.UserState;
  [fromSurveys.surveysFeatureKey]: fromSurveys.SurveysState;
  [fromSurveyDetails.surveyDetailsFeatureKey]: fromSurveyDetails.SurveyDetailsState;
  [fromGoal.goalFeatureKey]: fromGoal.GoalState;
  [fromPerformance.performanceFeatureKey]: fromPerformance.PerformanceState;
  [fromNews.newsFeatureKey]: fromNews.NewsState;
  [fromResources.resourcesFeatureKey]: fromResources.ResourcesState;
  [fromStarsFeed.starsFeedFeatureKey]: fromStarsFeed.StarsFeedState;
  [fromShift.shiftFeatureKey]: fromShift.ShiftState;
  [fromMyTeamMember.teamMemberDetailsFeatureKey]: fromMyTeamMember.TeamMemberDetailsState;
  [fromOpportunityDetails.opportunityDetailsFeatureKey]: fromOpportunityDetails.OpportunityDetailsState;
  router: fromRouter.RouterReducerState<any>;
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      fromPayHistory.payHistoryFeatureKey,
      fromWeekPay.weekPayFeatureKey,
      fromTranslations.translationsFeatureKey,
      fromUser.userFeatureKey,
      fromSurveyDetails.surveyDetailsFeatureKey,
      fromGoal.goalFeatureKey,
      fromPerformance.performanceFeatureKey,
      fromResources.resourcesFeatureKey,
      fromShift.shiftFeatureKey,
      fromMyTeam.myTeamFeatureKey,
      fromMyTeamMember.teamMemberDetailsFeatureKey,
      fromOpportunityDetails.opportunityDetailsFeatureKey
    ],
    rehydrate: true
  })(reducer);
}

export const reducers = new InjectionToken<ActionReducerMap<AppState, Action>>(
  'Root reducers token',
  {
    factory: () => ({
      [fromPayHistory.payHistoryFeatureKey]: fromPayHistory.reducer,
      [fromWeekPay.weekPayFeatureKey]: fromWeekPay.reducer,
      [fromLayout.layoutFeatureKey]: fromLayout.reducer,
      [fromTranslations.translationsFeatureKey]: fromTranslations.reducer,
      [fromUser.userFeatureKey]: fromUser.reducer,
      [fromSurveys.surveysFeatureKey]: fromSurveys.reducer,
      [fromSurveyDetails.surveyDetailsFeatureKey]: fromSurveyDetails.reducer,
      [fromGoal.goalFeatureKey]: fromGoal.reducer,
      [fromPerformance.performanceFeatureKey]: fromPerformance.reducer,
      [fromNews.newsFeatureKey]: fromNews.reducer,
      [fromResources.resourcesFeatureKey]: fromResources.reducer,
      [fromStarsFeed.starsFeedFeatureKey]: fromStarsFeed.reducer,
      [fromShift.shiftFeatureKey]: fromShift.reducer,
      [fromMyTeamMember.teamMemberDetailsFeatureKey]: fromMyTeamMember.reducer,
      [fromOpportunityDetails.opportunityDetailsFeatureKey]:
        fromOpportunityDetails.reducer,
      router: routerReducer
    })
  }
);
export const selectRouter = createSelector(
  (state: AppState) => state.router,
  value => value
);

const {
  selectCurrentRoute,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl
} = fromRouter.getSelectors(selectRouter);

export const RouteSelectors = {
  selectCurrentRoute: createSelector(state => state, selectCurrentRoute),
  selectQueryParams: createSelector(state => state, selectQueryParams),
  selectQueryParam: createSelector(state => state, selectQueryParam),
  selectRouteParams: createSelector(state => state, selectRouteParams),
  selectRouteParam: createSelector(state => state, selectRouteParam),
  selectRouteData: createSelector(state => state, selectRouteData),
  selectUrl: createSelector(state => state, selectUrl)
};

export const selectActiveModule = createSelector(
  RouteSelectors.selectRouteData,
  data => {
    return data.module ? data.module : null;
  }
);

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer];

export const getPayHistoryState = (state: AppState) =>
  state[fromPayHistory.payHistoryFeatureKey];

export const selectAllPayHistories = createSelector(
  getPayHistoryState,
  fromPayHistory.selectAllPayHistories
);

export const selectIsPayHistoryLoading = createSelector(
  getPayHistoryState,
  fromPayHistory.selectIsPayHistoryLoading
);

export const getWeekPayState = (state: AppState) =>
  state[fromWeekPay.weekPayFeatureKey];

export const selectActiveWeekPayDetails = createSelector(
  getWeekPayState,
  fromWeekPay.selectActiveWeekPayDetails
);

export const selectActiveWeekCompletedTasksCount = createSelector(
  getWeekPayState,
  fromWeekPay.selectCompletedTasksCount
);

export const selectIsPayLoading = createSelector(
  getWeekPayState,
  fromWeekPay.selectIsPayLoading
);

export const getLayoutState = (state: AppState) =>
  state[fromLayout.layoutFeatureKey];

export const getShowSidenav = createSelector(
  getLayoutState,
  fromLayout.getShowSidenav
);

export const getIsAppOnline = createSelector(
  getLayoutState,
  fromLayout.getIsAppOnline
);

export const getErrorMessage = createSelector(
  getLayoutState,
  fromLayout.getErrorMessage
);

export const getInfoMessage = createSelector(
  getLayoutState,
  fromLayout.getInfoMessage
);

export const getSuccessMessage = createSelector(
  getLayoutState,
  fromLayout.getSuccessMessage
);

export const getAppHasNotification = createSelector(
  getLayoutState,
  fromLayout.getAppHasNotification
);

export const getHasMenuNotification = createSelector(
  getLayoutState,
  fromLayout.hasMenuNotification
);

export const getPersistRouteChangeCount = createSelector(
  getLayoutState,
  fromLayout.getPersistRouteChangeCount
);

export const getShowMenuBar = createSelector(
  getLayoutState,
  fromLayout.showMenuBar
);

export const getHideNavigationBar = createSelector(
  getLayoutState,
  fromLayout.hideNavigationBar
);

export const getIsAppLoading = createSelector(
  getLayoutState,
  fromLayout.getIsAppLoading
);

export const getTranslationState = (state: AppState) =>
  state[fromTranslations.translationsFeatureKey];

export const getLanguage = createSelector(
  getTranslationState,
  fromTranslations.getLanguage
);

export const getUserState = (state: AppState) => state[fromUser.userFeatureKey];

export const getIsUserLoggedIn = createSelector(
  getUserState,
  fromUser.getIsUserLoggedIn
);

export const getUserName = createSelector(getUserState, fromUser.getUserName);

export const getUserRoles = createSelector(getUserState, fromUser.getUserRoles);

export const getUserEmail = createSelector(getUserState, fromUser.getUserEmail);

export const getUserEmailPrefix = createSelector(
  getUserState,
  fromUser.getUserEmailPrefix
);

export const selectUserSettings = createSelector(
  getUserState,
  fromUser.selectUserSettings
);

export const getApexId = createSelector(getUserState, fromUser.getApexId);

export const getUserEid = createSelector(getUserState, fromUser.getEid);

export const getUserId = createSelector(getUserState, fromUser.getUserId);

export const getAuthToken = createSelector(getUserState, fromUser.getAuthToken);

export const getHasRoadCrewAccess = createSelector(
  getUserState,
  fromUser.getHasRoadCrewAccess
);

export const getRoadCrewErrorMessage = createSelector(
  getUserState,
  fromUser.getRoadCrewErrorMessage
);

export const getAssociateHomeState = createSelector(
  getUserState,
  fromUser.getAssociateHomeState
);

export const getUserTitle = createSelector(getUserState, fromUser.getUserTitle);

export const getUserTrainingStatus = createSelector(
  getUserState,
  fromUser.selectUserTrainingStatus
);

export const selectIsUserTrainee = createSelector(
  getUserState,
  fromUser.selectIsUserTrainee
);

export const selectUserPhoto = createSelector(
  getUserState,
  fromUser.selectUserPhoto
);

export const selectTempPhoto = createSelector(
  getUserState,
  fromUser.selectTempPhoto
);

export const getUserSitesIds = createSelector(
  getUserState,
  fromUser.getUserSitesIds
);

export const getUserSites = createSelector(getUserState, fromUser.getUserSites);

export const getManagerSubDepartments = createSelector(
  getUserState,
  fromUser.getManagerSubDepartments
);

export const getIsUserProfileLoading = createSelector(
  getUserState,
  fromUser.selectIsUserProfileLoading
);

export const selectWeeklyCheckIns = createSelector(
  getUserState,
  fromUser.selectWeeklyCheckIns
);

export const getUserInfo = createSelector(
  getUserId,
  getUserName,
  getUserTitle,
  (id, name, title) => {
    return {
      id,
      name,
      title
    };
  }
);

export const getAuthTokenWithApexUserId = createSelector(
  getAuthToken,
  getUserEmailPrefix,
  (authToken, userId) => {
    return {
      authToken,
      userId
    };
  }
);

export const getActiveWeek = createSelector(
  getWeekPayState,
  fromWeekPay.selectActiveWeekId
);

export const isUserAgreeWithTerms = createSelector(
  getUserState,
  fromUser.getIsUserAgreeWithTerms
);

export const getIsUserLoggingOut = createSelector(
  getUserState,
  fromUser.getIsUserLoggingOut
);

export const getActiveWeekTotalEarnings = createSelector(
  getWeekPayState,
  fromWeekPay.selectActiveWeekTotalEarning
);

export const isUserAgreeWithTermsAndIsLoggedIn = createSelector(
  isUserAgreeWithTerms,
  getIsUserLoggedIn,
  (isUserAgreeWithTermsVal, isUserLoggedInVal) => {
    return {
      isUserAgreeWithTerms: isUserAgreeWithTermsVal,
      isUserLoggedIn: isUserLoggedInVal
    };
  }
);

export const getSurveysState = (state: AppState) =>
  state[fromSurveys.surveysFeatureKey];

export const selectAllSurveys = createSelector(
  getSurveysState,
  fromSurveys.selectAllSurveys
);

export const selectActiveSurvey = createSelector(selectAllSurveys, surveys => {
  return surveys.length > 0 ? surveys[0] : null;
});

export const getSurveyDetailsState = (state: AppState) =>
  state[fromSurveyDetails.surveyDetailsFeatureKey];

export const getSurvey = createSelector(
  getSurveyDetailsState,
  fromSurveyDetails.selectSurveyDetails
);

export const selectIsSurveyLoading = createSelector(
  getSurveyDetailsState,
  fromSurveyDetails.selectIsSurveyLoading
);

export const selectIsSurveysLoading = createSelector(
  getSurveysState,
  fromSurveys.selectIsSurveysLoading
);

export const selectTotalSurveys = createSelector(
  getSurveysState,
  fromSurveys.selectTotalSurveys
);

export const selectIsEachSurveySubmitted = createSelector(
  getSurveysState,
  fromSurveys.selectIsEachSurveySubmitted
);

export const getGoalState = (state: AppState) => state[fromGoal.goalFeatureKey];

export const selectGoals = createSelector(
  getGoalState,
  fromGoal.selectAllGoals
);

export const getActiveWeekGoal = createSelector(
  getGoalState,
  fromGoal.getActiveWeekGoal
);

export const selectIsGoalLoading = createSelector(
  getGoalState,
  fromGoal.selectIsGoalLoading
);

export const getPerformanceState = (state: AppState) =>
  state[fromPerformance.performanceFeatureKey];

export const selectIsPerformanceLoading = createSelector(
  getPerformanceState,
  fromPerformance.selectIsPerformanceLoading
);

export const getActiveWeekPaySplit = createSelector(
  getPerformanceState,
  fromPerformance.getActiveWeekPaySplit
);

export const getActiveWeekPerformance = createSelector(
  getPerformanceState,
  fromPerformance.getActiveWeekPerformance
);

export const getActiveWeekAssociatePerformance = createSelector(
  getPerformanceState,
  fromPerformance.getActiveWeekAssociatePerformance
);

export const getActiveWeekPerformanceAndGoal = createSelector(
  getActiveWeekPerformance,
  getActiveWeekGoal,
  (performance, goal) => {
    return {
      performance,
      goal
    };
  }
);

export const getNewsState = (state: AppState) => state[fromNews.newsFeatureKey];

export const selectAllLoadedNews = createSelector(
  getNewsState,
  fromNews.selectAllLoadedNews
);

export const selectIsNewsLoading = createSelector(
  getNewsState,
  fromNews.selectIsNewsLoading
);

export const selectIsNewsPostLoading = createSelector(
  getNewsState,
  fromNews.selectIsPostLoading
);

export const selectCurrentPage = createSelector(
  getNewsState,
  fromNews.selectCurrentPage
);

export const selectTotalPostsCount = createSelector(
  getNewsState,
  fromNews.selectTotalPostsCount
);

export const selectUnseenNewsCount = createSelector(
  getNewsState,
  fromNews.selectUnseenNewsCount
);

export const selectIsErrorOccurredOnNewsLoading = createSelector(
  getNewsState,
  fromNews.selectIsErrorOccurred
);

export const selectNewsCurrentPageAndTotalCount = createSelector(
  selectCurrentPage,
  selectTotalPostsCount,
  (currentPage, totalCount) => {
    return {
      currentPage,
      totalCount
    };
  }
);

export const getNewsActivePost = createSelector(
  getNewsState,
  fromNews.selectActivePost
);

export const selectIsErrorOccurredOnNewsPostLoading = createSelector(
  getNewsState,
  fromNews.selectIsPostErrorOccurred
);

export const getResourcesState = (state: AppState) =>
  state[fromResources.resourcesFeatureKey];

export const selectDirectory = createSelector(
  getResourcesState,
  fromResources.selectDirectory
);

export const selectIsDirectoryLoading = createSelector(
  getResourcesState,
  fromResources.selectIsDirectoryLoading
);

export const getStarsFeedState = (state: AppState) =>
  state[fromStarsFeed.starsFeedFeatureKey];

export const selectAllLoadedScriptStars = createSelector(
  getStarsFeedState,
  fromStarsFeed.selectAllLoadedStars
);

export const selectLoadedScriptStars = createSelector(
  selectAllLoadedScriptStars,
  stars => stars.filter(post => post.id !== 'pinned-post')
);

export const selectIsStarsFeedLoading = createSelector(
  getStarsFeedState,
  fromStarsFeed.selectIsStarsFeedLoading
);

export const selectIsStarsPostLoading = createSelector(
  getStarsFeedState,
  fromStarsFeed.selectIsPostLoading
);

export const selectCurrentStarsCurrentPage = createSelector(
  getStarsFeedState,
  fromStarsFeed.selectCurrentPage
);

export const selectTotalStarsPostsCount = createSelector(
  getStarsFeedState,
  fromStarsFeed.selectTotalPostsCount
);

export const selectIsErrorOccurredOnStarsFeedLoading = createSelector(
  getStarsFeedState,
  fromStarsFeed.selectIsErrorOccurred
);

export const selectIsErrorOccurredOnStarsPostLoading = createSelector(
  getStarsFeedState,
  fromStarsFeed.selectIsPostErrorOccurred
);

export const selectStarsFeedCurrentPageAndTotalCount = createSelector(
  selectCurrentStarsCurrentPage,
  selectTotalStarsPostsCount,
  (currentPage, totalCount) => {
    return {
      currentPage,
      totalCount
    };
  }
);

export const getStarsFeedActivePost = createSelector(
  getStarsFeedState,
  fromStarsFeed.selectActivePost
);

export const getActivePost = createSelector(
  selectAllLoadedNews,
  selectAllLoadedScriptStars,
  (news, stars, props) => {
    if (news.find(post => post.id === props.postId)) {
      return news.find(post => post.id === props.postId);
    }

    return stars.find(post => post.id === props.postId);
  }
);

export const selectIsPostLoading = createSelector(
  selectIsNewsPostLoading,
  selectIsStarsPostLoading,
  (isNewsPostLoading, isStarsFeedLoading) => {
    return isNewsPostLoading || isStarsFeedLoading;
  }
);

export const selectIsErrorOccurredOnPostLoading = createSelector(
  selectIsErrorOccurredOnNewsPostLoading,
  selectIsErrorOccurredOnStarsPostLoading,
  (isNewsPostError, isStarsPostError) => {
    return isNewsPostError || isStarsPostError;
  }
);

export const getShiftState = (state: AppState) =>
  state[fromShift.shiftFeatureKey];

export const selectShift = createSelector(getShiftState, fromShift.selectShift);

export const getShiftCount = createSelector(
  getShiftState,
  fromShift.getManagerShiftRequestsCount
);

export const selectIsShiftLoading = createSelector(
  getShiftState,
  fromShift.selectIsShiftLoading
);

export const selectSites = createSelector(getShiftState, fromShift.selectSites);

export const selectAccessSitesDropDownValues = createSelector(
  selectSites,
  sites => {
    if (sites != null) {
      return sites.accessSubDepartments.map(site => {
        return {
          id: site.subDept,
          name: site.name
        };
      });
    }
    return [];
  }
);

export const selectIsSitesLoading = createSelector(
  getShiftState,
  fromShift.selectIsSitesLoading
);

export const selectManagerShifts = createSelector(
  getShiftState,
  fromShift.selectManagerShifts
);

export const selectIsManagerShiftsLoading = createSelector(
  getShiftState,
  fromShift.selectIsManagerShiftsLoading
);

export const getOpportunityDetailsState = (state: AppState) =>
  state[fromOpportunityDetails.opportunityDetailsFeatureKey];

export const selectIsOpportunityLoading = createSelector(
  getOpportunityDetailsState,
  fromOpportunityDetails.selectIsOpportunityLoading
);

export const selectOpportunityDetails = createSelector(
  getOpportunityDetailsState,
  fromOpportunityDetails.selectOpportunityDetails
);
