<app-loader [isLoading]="isPostLoading$ | async"></app-loader>

<article *ngIf="(isPostLoading$ | async) === false && post">
  <app-news-feed-card
    [post]="post"
    [ariaPosinset]="0"
    [ariaSetsize]="0"
    [previewMode]="true"
  ></app-news-feed-card>
</article>

<ng-template #emptyStateTranslation i18n="@@preview-feed-data_noDataFound">
  The resource could not be found. Check if all required fields are filled.
</ng-template>

<app-empty-state
  *ngIf="
    (isPostLoading$ | async) === false &&
    ((isPostErrorOccurred$ | async) || !post)
  "
  [emptyStateTemplate]="emptyStateTranslation"
></app-empty-state>
