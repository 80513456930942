import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map, first } from 'rxjs/operators';
import { AppState } from '../../reducers';
import { Roles } from '../../core/enums/Roles';
import { Observable } from 'rxjs';
import { getUserRoles } from '../../reducers';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  userRoles$: Observable<string[]>;

  constructor(private store: Store<AppState>) {}

  get isAssociate$() {
    this.userRoles$ = this.store.pipe(select(getUserRoles));
    return this.userRoles$.pipe(
      first(),
      map(roles => {
        return roles.includes(Roles.Associate) || roles.includes(Roles.None);
      })
    );
  }

  get isSiteManager$() {
    this.userRoles$ = this.store.pipe(select(getUserRoles));

    return this.userRoles$.pipe(
      first(),
      map(roles => {
        return roles.includes(Roles.SiteManager);
      })
    );
  }

  isAuthorized$(rolesToValidate: string[]): Observable<boolean> {
    this.userRoles$ = this.store.pipe(select(getUserRoles));
    const belongs = element => {
      return rolesToValidate.includes(element);
    };

    return this.userRoles$.pipe(
      first(),
      map(userRoles => {
        return userRoles.some(belongs);
      })
    );
  }
}
