import { Component } from '@angular/core';
import { DialogRef } from '../../../modules/modal/dialog-ref';

@Component({
  selector: 'app-about-weekly-observations',
  templateUrl: './about-weekly-observations.component.html',
  styleUrls: ['./about-weekly-observations.component.scss']
})
export class AboutWeeklyObservationsComponent {
  constructor(private modalRef: DialogRef<AboutWeeklyObservationsComponent>) {}

  closeModal() {
    this.modalRef.close({ success: true });
  }
}
