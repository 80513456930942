<div *ngIf="formControl.value == null || formControl.value[0]?.file == null">
  <img
    class="document__placeholder"
    [src]="errors && isInvalid ? errorStateImage : emptyStateImage"
    alt="Document template"
  />
  <app-button-link
    class="document__button"
    [labelTemplate]="addDocumentButtonLabelTemplate"
    [color]="errors && isInvalid ? ButtonColorEnum.Red : ButtonColorEnum.Blue"
    (buttonClick)="previewDocument()"
    [iconName]="SvgIconNameEnum.CameraAdd"
    [iconColorPrimary]="SvgIconColorEnum.White"
    [iconColorSecondary]="
      errors && isInvalid
        ? SvgIconColorEnum.Cinnabar
        : SvgIconColorEnum.Endeavour
    "
  ></app-button-link>
</div>
<div *ngIf="formControl.value && formControl.value[0]?.file != null">
  <div class="document__image-container">
    <ng-container *ngFor="let value of formControl.value">
      <img class="document__image" [src]="value.file" alt="Document preview" />
    </ng-container>
  </div>
  <app-button-link
    class="document__button"
    [labelTemplate]="previewDocumentButtonLabelTemplate"
    (buttonClick)="previewDocument()"
  ></app-button-link>
</div>
