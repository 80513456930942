<app-filter-modal-layout>
  <ng-container slot="modal-header" i18n="@@job-title-filter_header">
    Filter By Job Title
  </ng-container>
  <ng-container slot="modal-content">
    <div *ngIf="data?.jobTitles && data?.jobTitles.length">
      <button
        *ngIf="
          selectedJobTitles.length &&
          selectedJobTitles?.length === availableFilters.length
        "
        class="job-title-filter__select-button"
        type="button"
        (click)="deselectAll()"
        i18n="@@job-title-filter_deselectAll"
      >
        Deselect All
      </button>
      <button
        *ngIf="
          !selectedJobTitles.length ||
          selectedJobTitles?.length < availableFilters.length
        "
        class="job-title-filter__select-button"
        type="button"
        (click)="selectAll()"
        i18n="@@job-title-filter_selectAll"
      >
        Select All
      </button>
      <div *ngFor="let item of data.jobTitles">
        <app-checkbox-input
          [disabled]="item?.count === 0"
          [ngModel]="arrayHasValue(selectedJobTitles, item?.jobTitle)"
          (ngModelChange)="selectJobTitle(item?.jobTitle)"
        >
          {{ item.jobTitle | translate }} ({{ item.count }})
        </app-checkbox-input>
      </div>
    </div>
    <ng-template #emptyStateMessage i18n="@@job-title-filter_noDataFound">
      There are no filter options.
    </ng-template>

    <app-empty-state
      *ngIf="data?.jobTitles === null || data?.jobTitles.length === 0"
      [emptyStateTemplate]="emptyStateMessage"
      [emptyStateIcon]="SvgIconNameEnum.EmptyStateApplications"
    ></app-empty-state>
  </ng-container>
  <ng-container slot="modal-action">
    <app-button-link
      (buttonClick)="resetFilter()"
      label="Reset Filter"
      i18n-label="@@job-title-filter_resetFilter"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>

    <app-button-link
      (buttonClick)="filter()"
      label="Done"
      i18n-label="@@job-title-filter-filter_done"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>
  </ng-container>
</app-filter-modal-layout>
