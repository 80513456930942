import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasCertificate'
})
export class HasCertificatePipe implements PipeTransform {
  transform(value: any, array: string[]): boolean {
    return array && (array.includes(value) || value === 'noneNeeded');
  }
}
