import { Direction } from '@angular/cdk/bidi';
import {
  ComponentFactoryResolver,
  InjectionToken,
  ViewContainerRef
} from '@angular/core';

export const DIALOG_DEFAULT_OPTIONS = new InjectionToken<DialogConfig>(
  'dialog-default-options'
);
export const DIALOG_DATA = new InjectionToken<any>('DialogData');

// @ts-ignore
export interface DialogPosition {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

export class DialogConfig {
  //  tslint:disable
  id?: string;
  viewContainerRef?: ViewContainerRef;
  componentFactoryResolver?: ComponentFactoryResolver;
  data: any;
  role?: string = 'dialog';
  ariaLabelledBy?: string = 'dialog-header';
  ariaDescribedBy?: string | null = null;
  ariaLabel?: string | null = null;
  hasBackdropClick?: boolean;
  centerHorizontally?: boolean = true;
  centerVertically?: boolean = false;
  hasBackdrop?: boolean = true;
  backdropClass?: string = 'dark-backdrop';
  bottom?: string = '';
  size?: any;
  disableClose?: boolean = false;
  autoFocus?: boolean = true;
  restoreFocus?: boolean = true;

  width?: string = '';
  height?: string = '';
  position?: DialogPosition;
  direction?: Direction;
  disposeOnNavigation?: boolean = true;
  fullWidth?: boolean = false;
  fullHeight?: boolean = false;
  scrollStrategy?: 'close' | 'reposition' | 'block';
  // tslint:enable
}
