import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
  FORM_FIELD,
  FormFieldComponent
} from '../form-field/form-field.component';
import { FormFieldControl } from '../form-field/form-field.control';
import { takeUntil } from 'rxjs/operators';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { SvgIconName } from '../../../enums/SvgIcon';

let nextUniqueId = 0;

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SignatureComponent)
    },
    { provide: FormFieldControl, useExisting: SignatureComponent }
  ]
})
export class SignatureComponent
  implements
    OnInit,
    OnDestroy,
    AfterViewInit,
    ControlValueAccessor,
    FormFieldControl {
  onDestroySubject = new Subject();
  formControl: FormControl;
  signaturePadOptions: any = {};
  canvasWidth = window.innerWidth;

  protected defaultId = `app-signature-${nextUniqueId++}`;
  protected componentId: string;
  public hasValue = false;
  public SvgIconNameEnum = SvgIconName;

  @Input() minWidth = 1;
  @Input() canvasHeight = 145;
  @Input() name;
  @Input() surname;
  @Input() date;
  @Input() placeholder;

  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild('padContainer', { static: false }) public padContainer: ElementRef;

  @HostListener('window:resize')
  onResize() {
    const element = this.padContainer.nativeElement.getBoundingClientRect();
    if (this.signaturePad) {
      this.signaturePad.set('minWidth', this.minWidth);
      this.signaturePad.set('canvasWidth', element.width);
      this.signaturePad.set('canvasHeight', this.canvasHeight);
    }
  }

  constructor(
    fb: FormBuilder,
    @Optional() @Inject(FORM_FIELD) private formField: FormFieldComponent
  ) {
    this.formControl = fb.control('');
    this.id = this.id;
  }

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(value => {
        this.propagateChange(value);
      });
  }

  ngAfterViewInit() {
    this.onResize();
  }

  ngOnDestroy() {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  @Input()
  get id(): string {
    return this.componentId;
  }

  set id(value: string) {
    this.componentId = value || this.defaultId;
  }

  get errorId(): string {
    return `${this.id}-error`;
  }

  public get control() {
    return this.formControl;
  }

  get hasValidation() {
    if (!this.formField) {
      return false;
    }
    return this.formField.hasValidation();
  }

  get isInvalid() {
    if (!this.formField) {
      return false;
    }
    return this.formField.isInvalid();
  }

  writeValue(value) {
    this.formControl.setValue(value, { emitEvent: false });
    setTimeout(() => {
      value ? this.signaturePad.fromDataURL(value) : this.signaturePad.clear();
    });
  }

  onDrawComplete() {
    this.formControl.markAsTouched();
    this.formControl.setValue(this.signaturePad.toDataURL());
  }

  onDrawStart() {
    this.hasValue = true;
  }

  clearSignature() {
    this.formControl.markAsPristine();
    this.formControl.setValue(null);
    this.signaturePad.clear();
    this.hasValue = false;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  private propagateChange = (value: any) => {};
}
