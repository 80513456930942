/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing-page-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../../../shared/components/svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../shared/components/button-link/button-link.component.ngfactory";
import * as i6 from "../../../shared/components/button-link/button-link.component";
import * as i7 from "../../../shared/services/analytics-utilities.service";
import * as i8 from "./landing-page-container.component";
import * as i9 from "@ngrx/store";
import * as i10 from "@angular/router";
import * as i11 from "@azure/msal-angular/dist/msal.service";
import * as i12 from "../../../shared/services/translation.service";
var styles_LandingPageContainerComponent = [i0.styles];
var RenderType_LandingPageContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingPageContainerComponent, data: {} });
export { RenderType_LandingPageContainerComponent as RenderType_LandingPageContainerComponent };
export function View_LandingPageContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "main", [["class", "landing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "landing__header welcome-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-svg-icon", [["class", "welcome-header__icon"], ["description", "Capstone logotype"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(3, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"], description: [1, "description"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "h1", [["class", "welcome-header__heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "welcome-header__subheading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Welcome to "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Capstone"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "landing__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-button-link", [["label", "Log into my account"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonLinkComponent_0, i5.RenderType_ButtonLinkComponent)), i1.ɵdid(11, 638976, null, 0, i6.ButtonLinkComponent, [i7.AnalyticsUtilitiesService], { fullWidth: [0, "fullWidth"], label: [1, "label"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.CapstoneLogo; var currVal_1 = "Capstone logotype"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = true; var currVal_3 = "Log into my account"; _ck(_v, 11, 0, currVal_2, currVal_3); }, null); }
export function View_LandingPageContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing-page-container", [], null, null, null, View_LandingPageContainerComponent_0, RenderType_LandingPageContainerComponent)), i1.ɵdid(1, 114688, null, 0, i8.LandingPageContainerComponent, [i9.Store, i10.ActivatedRoute, i11.MsalService, i12.TranslationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingPageContainerComponentNgFactory = i1.ɵccf("app-landing-page-container", i8.LandingPageContainerComponent, View_LandingPageContainerComponent_Host_0, {}, {}, []);
export { LandingPageContainerComponentNgFactory as LandingPageContainerComponentNgFactory };
