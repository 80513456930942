import { Component, Inject } from '@angular/core';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';
import { DialogRef } from '../../../../../shared/modules/modal/dialog-ref';
import { DIALOG_DATA } from '../../../../../shared/modules/modal/dialog-config';

@Component({
  selector: 'app-confirm-location',
  templateUrl: './confirm-location.component.html',
  styleUrls: ['./confirm-location.component.scss']
})
export class ConfirmLocationComponent {
  SvgIconNameEnum = SvgIconName;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;
  public preferredAirport: string;

  constructor(
    private modalRef: DialogRef<ConfirmLocationComponent>,
    @Inject(DIALOG_DATA) data: any
  ) {
    this.preferredAirport = data.preferredAirport;
  }

  closeModal() {
    this.modalRef.close({ success: false });
  }

  confirmAirport() {
    this.modalRef.close({ success: true, data: { approveAirport: true } });
  }

  changeAirport() {
    this.modalRef.close({ success: true, data: { approveAirport: false } });
  }
}
