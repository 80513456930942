/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icon-text.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./icon-text.component";
var styles_IconTextComponent = [i0.styles];
var RenderType_IconTextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IconTextComponent, data: {} });
export { RenderType_IconTextComponent as RenderType_IconTextComponent };
export function View_IconTextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "icon-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-svg-icon", [["class", "icon-text__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"], colorPrimary: [1, "colorPrimary"], colorSecondary: [2, "colorSecondary"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "icon-text__content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; var currVal_1 = _co.iconColorPrimary; var currVal_2 = _co.iconColorSecondary; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_IconTextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon-text", [], null, null, null, View_IconTextComponent_0, RenderType_IconTextComponent)), i1.ɵdid(1, 114688, null, 0, i5.IconTextComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconTextComponentNgFactory = i1.ɵccf("app-icon-text", i5.IconTextComponent, View_IconTextComponent_Host_0, { iconName: "iconName", iconColorPrimary: "iconColorPrimary", iconColorSecondary: "iconColorSecondary" }, {}, ["*"]);
export { IconTextComponentNgFactory as IconTextComponentNgFactory };
