import { Component } from '@angular/core';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';
import { DialogRef } from '../../../../../shared/modules/modal/dialog-ref';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-skip-insurance',
  templateUrl: './skip-insurance.component.html',
  styleUrls: ['./skip-insurance.component.scss']
})
export class SkipInsuranceComponent {
  SvgIconNameEnum = SvgIconName;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;

  constructor(private modalRef: DialogRef<SkipInsuranceComponent>) {}

  closeModal() {
    this.modalRef.close({ success: false });
  }

  skip() {
    this.modalRef.close({ success: true });
  }
}
