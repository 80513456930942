import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormUtilitiesService } from '../../../services/form-utilities.service';
import { SvgIconName } from '../../../../../../shared/enums/SvgIcon';
import { DocumentType } from '../../../enums/DocumentType';

@Component({
  selector: 'app-licence-edit',
  templateUrl: './licence-edit.component.html',
  styleUrls: ['./licence-edit.component.scss']
})
export class LicenceEditComponent implements OnInit {
  @Input() formGroup: FormGroup;

  driverOptions: any[];
  driverInsuranceOptions: any[];
  typeOfIDOptions: any[];
  stateOptions: any[];
  airports = null;
  selectedState: string;
  documentType: string;
  newYearFromChild: string;
  hasInsurance: boolean;

  SvgIconName = SvgIconName;
  DocumentTypeEnum = DocumentType;
  constructor(private formUtilitiesService: FormUtilitiesService) {}

  ngOnInit() {
    this.driverOptions = this.formUtilitiesService.getIsDriverOptions();
    this.driverInsuranceOptions = this.formUtilitiesService.getIsDriverInsuranceOptionsOptions();
    this.typeOfIDOptions = this.formUtilitiesService.getTypesOfID();
    this.stateOptions = this.formUtilitiesService.getUsStates();
    this.airports = this.formUtilitiesService.getAirports();
    this.documentType = this.formGroup.get('isDriver').value
      ? 'driversLicense'
      : this.formGroup.get('typeOfID').value;

    this.formGroup.get('licenceIssuedState').valueChanges.subscribe(state => {
      this.selectedState = state;
    });

    this.formGroup.get('typeOfID').valueChanges.subscribe(id => {
      this.documentType = id;
    });

    this.hasInsurance = this.formGroup.get('hasInsurance').value;

    this.formGroup.get('isDriver').valueChanges.subscribe(isDriver => {
      if (!isDriver) {
        this.documentType = '';
        this.formGroup.controls['hasInsurance'].setValue(false);
      } else {
        this.documentType = 'driversLicense';
      }
    });

    this.formGroup.get('hasInsurance').valueChanges.subscribe(hasInsurance => {
      this.hasInsurance = hasInsurance;
      this.formGroup.controls['documents'].setValue([]);
      if (!hasInsurance) {
        this.formGroup.controls['insuranceExpirationDate'].setValue(null);
      }
    });
  }

  dateChangeFromChild(data: any) {
    if (data.docType === 'driversLicense') {
      this.formGroup.controls['licenceExpirationDate'].setValue(data.newDate);
    } else if (data.docType === 'insurance') {
      this.formGroup.controls['insuranceExpirationDate'].setValue(data.newDate);
    } else {
      this.formGroup.controls['IDExpirationDate'].setValue(data.newDate);
    }
  }
}
