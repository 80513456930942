import { GoalService } from './goal.service';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  Subject
} from 'rxjs';
import { ItemType } from '../enums/ItemType';
import { select, Store } from '@ngrx/store';
import some from 'lodash/fp/some';
import {
  AppState,
  getActiveWeekGoal,
  getActiveWeekPaySplit,
  getActiveWeekPerformance,
  getActiveWeekTotalEarnings,
  selectIsGoalLoading,
  selectIsPayLoading,
  selectIsPerformanceLoading
} from '../../../../reducers';
import {
  map,
  shareReplay,
  startWith,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { PerformanceDetails } from '../../../../core/dataEntities/goal/performanceDetails';
import { GoalDetails } from '../../../../core/dataEntities/goal/goalDetails';
import { GoalSlidersConfig, SlidersConfig } from '../config/slidersConfig';
import { ValidationStatus } from '../enums/ValidationStatus';
import { GoalValidation } from '../models/GoalValidation';
import { GoalAverages } from '../models/GoalAverages';
import { MathHelpers } from '../../../../shared/helpers/math.helpers';

@Injectable()
export class GoalStoreService implements OnDestroy {
  public goalChanged$ = new BehaviorSubject(null);
  public config: GoalSlidersConfig;
  public performance$: Observable<PerformanceDetails>;
  public associatePaySplit$: Observable<number>;
  public goalDetails$: Observable<GoalDetails>;
  public isPerformanceLoading$: Observable<boolean>;
  public isGoalLoading$: Observable<boolean>;
  public isEarningsLoading$: Observable<boolean>;
  public currentEarning$: Observable<number>;
  public onDestroy = new Subject();
  public hoursValidationState$: Observable<GoalValidation>;
  public palletsPerHourValidationState$: Observable<GoalValidation>;
  public revenuePerPalletValidationState$: Observable<GoalValidation>;
  public casesPerHourValidationState$: Observable<GoalValidation>;
  public revenuePerCaseValidationState$: Observable<GoalValidation>;
  public goalState$: Observable<ValidationStatus>;
  public isGoalValid$: Observable<boolean>;
  public goalAverages$: Observable<GoalAverages>;

  constructor(
    private goalService: GoalService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder
  ) {
    this.goalDetails$ = this.store.select(getActiveWeekGoal);
    this.performance$ = this.store.select(getActiveWeekPerformance, {
      config: SlidersConfig
    });
    this.isPerformanceLoading$ = this.store.select(selectIsPerformanceLoading);
    this.isGoalLoading$ = this.store.select(selectIsGoalLoading);
    this.isEarningsLoading$ = this.store.select(selectIsPayLoading);
    this.currentEarning$ = this.store.pipe(select(getActiveWeekTotalEarnings));
    this.associatePaySplit$ = this.store.pipe(select(getActiveWeekPaySplit));
    this.goalAverages$ = this.performance$.pipe(
      map(performance => {
        return {
          siteAverages: this.goalService.getSiteAverages(performance),
          associateAverages: this.goalService.getAssociateAverages(performance)
        };
      })
    );
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.onDestroy = null;
  }

  createGoalForm(config: GoalSlidersConfig, goalDetails, performance) {
    const formValue = {
      ...goalDetails,
      hours: goalDetails.hours
        ? goalDetails.hours
        : this.goalService
            .getHourPerformanceFallbackValue(performance, config.hours)
            .toFixed(MathHelpers.getNumberPrecisionLength(config.hours.step)),
      casesPerHour: goalDetails.casesPerHour
        ? goalDetails.casesPerHour
        : this.goalService
            .getPerformanceFallbackValue(
              performance,
              'casesPerHour',
              config.casesPerHour
            )
            .toFixed(
              MathHelpers.getNumberPrecisionLength(config.casesPerHour.step)
            ),
      palletsPerHour: goalDetails.palletsPerHour
        ? goalDetails.palletsPerHour
        : this.goalService
            .getPerformanceFallbackValue(
              performance,
              'palletsPerHour',
              config.palletsPerHour
            )
            .toFixed(
              MathHelpers.getNumberPrecisionLength(config.palletsPerHour.step)
            ),
      revenuePerCase: goalDetails.revenuePerCase
        ? goalDetails.revenuePerCase
        : this.goalService
            .getPerformanceFallbackValue(
              performance,
              'revenuePerCase',
              config.revenuePerCase
            )
            .toFixed(
              MathHelpers.getNumberPrecisionLength(config.revenuePerCase.step)
            ),
      revenuePerPallet: goalDetails.revenuePerPallet
        ? goalDetails.revenuePerPallet
        : this.goalService
            .getPerformanceFallbackValue(
              performance,
              'revenuePerPallet',
              config.revenuePerPallet
            )
            .toFixed(
              MathHelpers.getNumberPrecisionLength(config.revenuePerPallet.step)
            )
    };

    const formGroup = this.formBuilder.group({
      id: formValue.id,
      goal: goalDetails.goal
        ? formValue.goal
        : this.goalService.calculateGoal(
            formValue,
            performance.associatePaySplit
          ),
      user: formValue.user,
      hours: [
        formValue.hours,
        [
          Validators.max(config.hours.max),
          Validators.min(config.hours.min),
          this.goalService.getGoalNumberValidatorByStep(config.hours.step)
        ]
      ],
      goalStartDate: formValue.goalStartDate,
      goalEndDate: formValue.goalEndDate,
      palletsPerHour: [
        formValue.palletsPerHour,
        formValue.itemType === ItemType.Pallets
          ? [
              Validators.max(config.palletsPerHour.max),
              Validators.min(config.palletsPerHour.min),
              this.goalService.getGoalNumberValidatorByStep(
                config.palletsPerHour.step
              )
            ]
          : []
      ],
      revenuePerPallet: [
        formValue.revenuePerPallet,
        formValue.itemType === ItemType.Pallets
          ? [
              Validators.max(config.revenuePerPallet.max),
              Validators.min(config.revenuePerPallet.min),
              this.goalService.getGoalNumberValidatorByStep(
                config.revenuePerPallet.step
              )
            ]
          : []
      ],
      casesPerHour: [
        formValue.casesPerHour,
        formValue.itemType === ItemType.Cases
          ? [
              Validators.max(config.casesPerHour.max),
              Validators.min(config.casesPerHour.min),
              this.goalService.getGoalNumberValidatorByStep(
                config.casesPerHour.step
              )
            ]
          : []
      ],
      revenuePerCase: [
        formValue.revenuePerCase,
        formValue.itemType === ItemType.Cases
          ? [
              Validators.max(config.revenuePerCase.max),
              Validators.min(config.revenuePerCase.min),
              this.goalService.getGoalNumberValidatorByStep(
                config.revenuePerCase.step
              )
            ]
          : []
      ],
      itemType: formValue.itemType || ItemType.Pallets,
      lastUpdatedAtDate: formValue.lastUpdatedAtDate
    });
    this.createFormGroupValidations(formGroup, config);

    return formGroup;
  }

  calculateWeeklyGoalByItemType(itemType, formGroup, split) {
    let goalValue;
    if (itemType === ItemType.Cases) {
      goalValue = this.goalService.calculateWeeklyGoal(
        formGroup.get('casesPerHour').value,
        formGroup.get('hours').value,
        formGroup.get('revenuePerCase').value,
        split
      );
    } else {
      goalValue = this.goalService.calculateWeeklyGoal(
        formGroup.get('palletsPerHour').value,
        formGroup.get('hours').value,
        formGroup.get('revenuePerPallet').value,
        split
      );
    }
    return goalValue;
  }

  setGoalParametersByItemType(formGroup, goalParameters, itemType) {
    const itemProperties = this.goalService.getItemPropertiesByItemType(
      itemType
    );

    formGroup.get('hours').setValue(goalParameters.hours, { emitEvent: false });
    formGroup
      .get(itemProperties.itemsPerHour)
      .setValue(goalParameters.itemsPerHour, { emitEvent: false });
    formGroup
      .get(itemProperties.revenuePerItem)
      .setValue(goalParameters.revenuePerItem, { emitEvent: false });
  }

  private createProductivityMetricValidation(
    productivityMetricControl,
    validationFn
  ) {
    return combineLatest(
      productivityMetricControl.valueChanges.pipe(
        startWith(productivityMetricControl.value)
      ),
      this.goalChanged$,
      this.performance$
    ).pipe(
      map(([productivityMetric, goal, performance]) => {
        return validationFn(productivityMetricControl.value, performance);
      }),
      shareReplay(1)
    );
  }

  private createFormGroupValidations(formGroup, config) {
    merge(
      formGroup.get('hours').valueChanges,
      formGroup.get('palletsPerHour').valueChanges,
      formGroup.get('revenuePerPallet').valueChanges,
      formGroup.get('casesPerHour').valueChanges,
      formGroup.get('revenuePerCase').valueChanges
    )
      .pipe(withLatestFrom(this.associatePaySplit$), takeUntil(this.onDestroy))
      .subscribe(([value, associatePaySplit]) => {
        const goalValue = this.calculateWeeklyGoalByItemType(
          formGroup.get('itemType').value,
          formGroup,
          associatePaySplit
        );
        formGroup.patchValue(
          {
            goal: goalValue
          },
          { emitEvent: false }
        );
      });

    formGroup
      .get('itemType')
      .valueChanges.pipe(
        withLatestFrom(this.associatePaySplit$),
        takeUntil(this.onDestroy)
      )
      .subscribe(([itemType, associatePaySplit]) => {
        if (itemType === ItemType.Cases) {
          formGroup
            .get('revenuePerCase')
            .setValidators([
              Validators.max(config.revenuePerCase.max),
              Validators.min(config.revenuePerCase.min),
              this.goalService.getGoalNumberValidatorByStep(
                config.revenuePerCase.step
              )
            ]);
          formGroup
            .get('casesPerHour')
            .setValidators([
              Validators.max(config.casesPerHour.max),
              Validators.min(config.casesPerHour.min),
              this.goalService.getGoalNumberValidatorByStep(
                config.casesPerHour.step
              )
            ]);
          formGroup.get('palletsPerHour').clearValidators();
          formGroup.get('revenuePerPallet').clearValidators();
        } else if (itemType === ItemType.Pallets) {
          formGroup
            .get('revenuePerPallet')
            .setValidators([
              Validators.max(config.revenuePerPallet.max),
              Validators.min(config.revenuePerPallet.min),
              this.goalService.getGoalNumberValidatorByStep(
                config.revenuePerPallet.step
              )
            ]);

          formGroup
            .get('palletsPerHour')
            .setValidators([
              Validators.max(config.palletsPerHour.max),
              Validators.min(config.palletsPerHour.min),
              this.goalService.getGoalNumberValidatorByStep(
                config.palletsPerHour.step
              )
            ]);
          formGroup.get('revenuePerCase').clearValidators();
          formGroup.get('casesPerHour').clearValidators();
        }

        formGroup.get('revenuePerPallet').updateValueAndValidity();
        formGroup.get('revenuePerCase').updateValueAndValidity();
        formGroup.get('casesPerHour').updateValueAndValidity();
        formGroup.get('palletsPerHour').updateValueAndValidity();

        const goalValue = this.calculateWeeklyGoalByItemType(
          itemType,
          formGroup,
          associatePaySplit
        );

        formGroup.patchValue(
          {
            goal: goalValue
          },
          { emitEvent: false }
        );
      });

    this.hoursValidationState$ = this.createProductivityMetricValidation(
      formGroup.get('hours'),
      (productivityMetric, performance) => {
        const performanceHours = this.goalService.getProductivityHoursMetric(
          performance
        );
        return this.goalService.validateWorkingHours(
          performanceHours,
          productivityMetric,
          config.hours
        );
      }
    );

    this.palletsPerHourValidationState$ = this.createProductivityMetricValidation(
      formGroup.get('palletsPerHour'),
      (productivityMetric, performance) => {
        const performanceValue = this.goalService.getProductivityMetric(
          performance,
          'palletsPerHour'
        );
        return this.goalService.validateProductivityMetric(
          performanceValue,
          productivityMetric,
          config.palletsPerHour
        );
      }
    );

    this.revenuePerPalletValidationState$ = this.createProductivityMetricValidation(
      formGroup.get('revenuePerPallet'),
      (productivityMetric, performance) => {
        const performanceValue = this.goalService.getProductivityMetric(
          performance,
          'revenuePerPallet'
        );
        return this.goalService.validateProductivityMetric(
          performanceValue,
          productivityMetric,
          config.revenuePerPallet
        );
      }
    );

    this.casesPerHourValidationState$ = this.createProductivityMetricValidation(
      formGroup.get('casesPerHour'),
      (productivityMetric, performance) => {
        const performanceValue = this.goalService.getProductivityMetric(
          performance,
          'casesPerHour'
        );
        return this.goalService.validateProductivityMetric(
          performanceValue,
          productivityMetric,
          config.casesPerHour
        );
      }
    );

    this.revenuePerCaseValidationState$ = this.createProductivityMetricValidation(
      formGroup.get('revenuePerCase'),
      (productivityMetric, performance) => {
        const performanceValue = this.goalService.getProductivityMetric(
          performance,
          'revenuePerCase'
        );
        return this.goalService.validateProductivityMetric(
          performanceValue,
          productivityMetric,
          config.revenuePerCase
        );
      }
    );

    this.goalState$ = combineLatest(
      this.hoursValidationState$,
      this.palletsPerHourValidationState$,
      this.revenuePerPalletValidationState$,
      this.casesPerHourValidationState$,
      this.revenuePerCaseValidationState$,
      formGroup
        .get('itemType')
        .valueChanges.pipe(startWith(formGroup.get('itemType').value))
    ).pipe(
      map(
        ([
          hoursValidationState,
          palletsPerHourValidationState,
          revenuePerPalletValidationState,
          casesPerHourValidationState,
          revenuePerCaseValidationState,
          itemType
        ]) => {
          let validationStatuses = [hoursValidationState.status];
          if (itemType === ItemType.Cases) {
            validationStatuses = [
              ...validationStatuses,
              casesPerHourValidationState.status,
              revenuePerCaseValidationState.status
            ];
          } else {
            validationStatuses = [
              ...validationStatuses,
              palletsPerHourValidationState.status,
              revenuePerPalletValidationState.status
            ];
          }
          if (some(s => s === ValidationStatus.Error)(validationStatuses)) {
            return ValidationStatus.Error;
          } else if (
            some(s => s === ValidationStatus.Warning)(validationStatuses)
          ) {
            return ValidationStatus.Warning;
          }
          return ValidationStatus.Valid;
        }
      ),
      shareReplay(1)
    );

    this.isGoalValid$ = this.goalState$.pipe(
      map(goalState => {
        return goalState !== ValidationStatus.Error;
      })
    );
  }
}
