<app-modal-layout (closeModal)="closeModal()">
  <ng-template #secondaryText i18n="@@manage-associate-photo_managePhoto">
    Manage Photo
  </ng-template>

  <app-modal-header
    slot="modal-header"
    [primaryText]="data.fullName"
    [secondaryTextTemplate]="secondaryText"
  ></app-modal-header>
  <div slot="modal-content" class="manage-photo">
    <app-modal-content-row class="manage-photo__content" [sideSpacing]="true">
      <app-associate-photo
        class="manage-photo__preview"
        [photo]="data.photo"
        width="96"
      ></app-associate-photo>
      <p *ngIf="!data.photo" i18n="@@@manage-associate-photo_removed">
        You’ve removed {{ data.fullName }}’s photo.
      </p>
    </app-modal-content-row>
    <div class="manage-photo__actions">
      <ng-template #closeButton i18n="@@@manage-associate-photo_close">
        Close
      </ng-template>

      <ng-template
        #removePhotoButton
        i18n="@@@manage-associate-photo_removePhoto"
      >
        Remove Photo
      </ng-template>

      <ng-template #saveButton i18n="@@@manage-associate-photo_saveAndClose">
        Save & Close
      </ng-template>

      <ng-template #undoButton i18n="@@@manage-associate-photo_undo">
        Undo
      </ng-template>

      <app-button-link
        (buttonClick)="data.photo ? closeModal() : save()"
        [labelTemplate]="data.photo ? closeButton : saveButton"
        [appearance]="ButtonAppearanceEnum.Link"
      ></app-button-link>

      <app-button-link
        (buttonClick)="data.photo ? removePhoto() : closeModal()"
        [labelTemplate]="data.photo ? removePhotoButton : undoButton"
        [appearance]="ButtonAppearanceEnum.Link"
        [color]="ButtonColorEnum.Red"
      ></app-button-link>
    </div>
  </div>
</app-modal-layout>
