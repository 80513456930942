/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-bar-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../indicator/indicator.component.ngfactory";
import * as i3 from "../../indicator/indicator.component";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./tab-bar-link.component";
var styles_TabBarLinkComponent = [i0.styles];
var RenderType_TabBarLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabBarLinkComponent, data: {} });
export { RenderType_TabBarLinkComponent as RenderType_TabBarLinkComponent };
function View_TabBarLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-indicator", [], null, null, null, i2.View_IndicatorComponent_0, i2.RenderType_IndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i3.IndicatorComponent, [], null, null)], null, null); }
export function View_TabBarLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["cdkMonitorElementFocus", ""], ["class", "tab-bar-link"], ["routerLinkActive", "tab-bar-link--active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i4.CdkMonitorFocus, [i1.ElementRef, i4.FocusMonitor], null, null), i1.ɵdid(2, 671744, [[2, 4]], 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i5.RouterLinkActive, [i5.Router, i1.ElementRef, i1.Renderer2, [2, i5.RouterLink], [2, i5.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabBarLinkComponent_1)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 2, 0, currVal_2); var currVal_3 = "tab-bar-link--active"; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.indicator; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TabBarLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tab-bar-link", [], null, null, null, View_TabBarLinkComponent_0, RenderType_TabBarLinkComponent)), i1.ɵdid(1, 114688, null, 0, i7.TabBarLinkComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabBarLinkComponentNgFactory = i1.ɵccf("app-tab-bar-link", i7.TabBarLinkComponent, View_TabBarLinkComponent_Host_0, { link: "link", indicator: "indicator" }, {}, ["*"]);
export { TabBarLinkComponentNgFactory as TabBarLinkComponentNgFactory };
