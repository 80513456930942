import { Component, OnInit } from '@angular/core';
import { SvgIconColor, SvgIconName } from '../../../../../shared/enums/SvgIcon';
import { Store } from '@ngrx/store';
import {
  AppState,
  selectIsManagerShiftsLoading,
  selectManagerShifts
} from '../../../../../reducers';
import { ShiftActions } from '../../actions';
import { Observable } from 'rxjs';
import { ShiftListItem } from '../../../../../core/dataEntities/shifts/shiftListItem';

@Component({
  selector: 'app-shift-requests',
  templateUrl: './shift-requests.component.html',
  styleUrls: ['./shift-requests.component.scss']
})
export class ShiftRequestsComponent implements OnInit {
  public managerShifts$: Observable<ShiftListItem[]>;
  public isManagerShiftsLoading$: Observable<boolean>;
  public SvgIconNameEnum = SvgIconName;
  public SvgIconColorEnum = SvgIconColor;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store.dispatch(ShiftActions.loadManagerShifts());
    this.managerShifts$ = this.store.select(selectManagerShifts);
    this.isManagerShiftsLoading$ = this.store.select(
      selectIsManagerShiftsLoading
    );
    this.store.dispatch(ShiftActions.clearShift());
  }
}
