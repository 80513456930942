import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { FocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { AnimationEvent } from '@angular/animations';
import { topSheetAnimations } from './top-sheet-animations';
import { BaseModalContainerComponent } from '../base-modal/base-modal-container.component';
import { DialogConfig } from '../dialog-config';

@Component({
  selector: 'app-top-sheet',
  templateUrl: 'top-sheet.component.html',
  styleUrls: ['top-sheet.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [topSheetAnimations.topSheetState]
})
export class TopSheetComponent extends BaseModalContainerComponent {
  @HostBinding('class.top-sheet-container') get class() {
    return true;
  }
  @HostBinding('attr.tabindex') get tabindex() {
    return '-1';
  }
  @HostBinding('attr.aria-modal') get ariaModal() {
    return 'true';
  }
  @HostBinding('attr.id') get attrId() {
    return this.id;
  }
  @HostBinding('attr.role') get attrRole() {
    return this.config.role;
  }
  @HostBinding('attr.aria-labelledby') get attrAriaLabelBy() {
    return this.config.ariaLabelledBy;
  }
  @HostBinding('attr.aria-label') get attrAriaLabel() {
    return this.config.ariaLabel;
  }
  @HostBinding('attr.aria-describedby') get attrAriaDescribedBy() {
    return this.config.ariaDescribedBy || null;
  }
  @HostBinding('@dialogContainer') get dialogContainer() {
    return this.state;
  }

  state: 'void' | 'enter' | 'exit' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();
  id: string;

  constructor(
    elementRef: ElementRef,
    focusTrapFactory: FocusTrapFactory,
    changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(DOCUMENT) document: any,
    config: DialogConfig
  ) {
    super(elementRef, focusTrapFactory, changeDetectorRef, document, config);
  }
}
