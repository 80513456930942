import { InjectionToken } from '@angular/core';
import { of } from 'rxjs';
import * as _ from 'lodash';

import { LayoutActions } from '../../core/actions';
import { TranslationService } from '../services/translation.service';
import { AppErrorMessages } from './error-messages';

export const APP_ERROR_MESSAGES = new InjectionToken('app.error.messages');

export function errorMessagesFactory(translationService: TranslationService) {
  return _.reduce(
    AppErrorMessages,
    (state, value, key) => {
      return {
        ...state,
        [key]: translationService.translate(value)
      };
    },
    {}
  );
}

export const errorMessagesProvider = {
  provide: APP_ERROR_MESSAGES,
  useFactory: errorMessagesFactory,
  deps: [TranslationService]
};

export function handleAppError(errorCodes) {
  return err => {
    if (!err.error) {
      return of(
        LayoutActions.showError({
          message: errorCodes.unknownError,
          error: err
        })
      );
    }
    let message = errorCodes[err.error.message];
    if (!message) {
      message = errorCodes.unknownError;
    }
    return of(LayoutActions.showError({ message, error: err }));
  };
}
