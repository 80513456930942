<div class="feed-separator">
  <ng-container *ngIf="isFirst">
    <span
      *ngIf="unseenCount | async as unseenNewsCount"
      class="feed-separator__unseen"
      i18n="@@feed-separator_unseen"
    >
      {{ unseenNewsCount }} New
    </span>
  </ng-container>

  <span class="feed-separator__time">
    <span i18n="@@feed-separator_time">
      { daysAgo(date), plural, =0 {Today} =1 {Yesterday} other {}}
    </span>
    <span *ngIf="daysAgo(date) > 1">
      {{ date | date: 'EEEE LLL dd' }}
    </span>
  </span>
</div>
