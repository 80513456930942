import { Component } from '@angular/core';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../shared/enums/ButtonLink';
import { DialogRef } from '../../../shared/modules/modal/dialog-ref';

@Component({
  selector: 'app-skip-check-in',
  templateUrl: './skip-check-in.component.html',
  styleUrls: ['./skip-check-in.component.scss']
})
export class SkipCheckInComponent {
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;

  constructor(private modalRef: DialogRef<SkipCheckInComponent>) {}

  closeModal() {
    this.modalRef.close({ success: false });
  }

  navigateBack() {
    this.modalRef.close({ success: true });
  }
}
