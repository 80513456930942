import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-information-edit',
  templateUrl: './contact-information-edit.component.html',
  styleUrls: ['./contact-information-edit.component.scss']
})
export class ContactInformationEditComponent implements OnInit {
  @Input() formGroup: FormGroup;
  constructor() {}

  ngOnInit() {}
}
