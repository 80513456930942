import { Component, OnInit, Input } from '@angular/core';
import { PageTitleAppearance } from '../../../../enums/PageTitle';
import { AssociateProductionDetails } from '../../../../../core/dataEntities/associate/associateProductionDetails';
import { StatsAppearance } from '../../../../enums/Stats';
import { TranslationService } from '../../../../services/translation.service';
import { TranslationMessages } from '../../../../enums/TranslationMessages';
import { ItemType } from '../../../../../wages/modules/goal/enums/ItemType';

@Component({
  selector: 'app-production-details',
  templateUrl: './production-details.component.html',
  styleUrls: ['./production-details.component.scss']
})
export class ProductionDetailsComponent implements OnInit {
  @Input() productionDetails: AssociateProductionDetails;
  @Input() isAssociateView = false;

  public PageTitleAppearanceEnum = PageTitleAppearance;
  public StatsAppearanceEnum = StatsAppearance;

  public ItemTypeEnum = ItemType;
  public options = [];
  public activeItemType = ItemType.Pallets;

  constructor(translationService: TranslationService) {
    this.options = [
      {
        id: ItemType.Pallets,
        name: translationService.translate(TranslationMessages.PalletsPerHour)
      },
      {
        id: ItemType.Cases,
        name: translationService.translate(TranslationMessages.CasesPerHour)
      }
    ];
  }

  ngOnInit() {}

  onChange(value) {
    this.activeItemType = value;
  }
}
