import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

const oldXHROpen = (window as any).XMLHttpRequest.prototype.open;
(window as any).XMLHttpRequest.prototype.open = function(
  method,
  url,
  async,
  user,
  password
) {
  if (
    url === 'https://dc.services.visualstudio.com/v2/track' &&
    !window.navigator.onLine
  ) {
    oldXHROpen.abort();
  }

  return oldXHROpen.apply(this, arguments);
};
