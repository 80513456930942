<input
  [ngClass]="{
    option__input: true
  }"
  type="radio"
  [id]="inputId"
  [checked]="checked"
  [attr.name]="name"
  [attr.value]="value"
  [attr.aria-invalid]="isInvalid"
  [attr.aria-describedby]="isInvalid ? errorId : null"
  [attr.disabled]="disabled ? '' : null"
  (change)="onInputChange($event)"
/>
<label
  [ngClass]="{
    option__label: true,
    'option__label--dark-red':
      appearance === RatingRatioInputAppearanceEnum.DarkRed,
    'option__label--red': appearance === RatingRatioInputAppearanceEnum.Red,
    'option__label--yellow':
      appearance === RatingRatioInputAppearanceEnum.Yellow,
    'option__label--blue': appearance === RatingRatioInputAppearanceEnum.Blue,
    'option__label--green': appearance === RatingRatioInputAppearanceEnum.Green,
    'option__label--is-touched': radioGroup.isTouched,
    'option__label--invalid': isInvalid
  }"
  [attr.for]="inputId"
>
  <span class="option__content"><ng-content></ng-content></span>
  <app-svg-icon class="option__icon" [name]="iconName"></app-svg-icon>
</label>
