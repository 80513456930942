import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-content-row',
  templateUrl: './modal-content-row.component.html',
  styleUrls: ['./modal-content-row.component.scss']
})
export class ModalContentRowComponent implements OnInit {
  @Input() sideSpacing = false;

  constructor() { }

  ngOnInit() {
  }

}
