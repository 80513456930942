/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-layout-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./card-layout-item.component";
import * as i3 from "../card-layout/card-layout.component";
var styles_CardLayoutItemComponent = [i0.styles];
var RenderType_CardLayoutItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardLayoutItemComponent, data: {} });
export { RenderType_CardLayoutItemComponent as RenderType_CardLayoutItemComponent };
export function View_CardLayoutItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_CardLayoutItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-layout-item", [], [[8, "className", 0]], null, null, View_CardLayoutItemComponent_0, RenderType_CardLayoutItemComponent)), i1.ɵdid(1, 114688, null, 0, i2.CardLayoutItemComponent, [[2, i3.CardLayoutComponent]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var CardLayoutItemComponentNgFactory = i1.ɵccf("app-card-layout-item", i2.CardLayoutItemComponent, View_CardLayoutItemComponent_Host_0, {}, {}, ["*"]);
export { CardLayoutItemComponentNgFactory as CardLayoutItemComponentNgFactory };
