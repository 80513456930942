import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AppState,
  getIsAppOnline,
  selectAllLoadedNews,
  selectIsErrorOccurredOnNewsLoading,
  selectIsNewsLoading,
  selectUnseenNewsCount,
  getIsUserProfileLoading,
  selectTotalPostsCount
} from '../../../reducers';
import { combineLatest, Observable, Subject } from 'rxjs';
import { NewsActions } from '../../actions';
import { filter, takeUntil, map, withLatestFrom } from 'rxjs/operators';
import moment from 'moment';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { Post } from '../../../core/dataEntities/feed/post';
import { PostCategoryType } from '../../enums/PostTypes';

@Component({
  selector: 'app-news-feed-container',
  templateUrl: './news-feed-container.component.html',
  styleUrls: ['./news-feed-container.component.scss']
})
export class NewsFeedContainerComponent implements OnInit, OnDestroy {
  onDestroySubject = new Subject();
  public itemsPerPage = 5;
  public page = 0;
  public totalCount$: Observable<number>;
  public news$: Observable<Post[]>;
  public isNewsFeedLoading$: Observable<boolean>;
  public unseenNewsCount$: Observable<number>;
  public isErrorOccurredOnNewsLoading$: Observable<boolean>;
  public isUserProfileLoading$: Observable<boolean>;
  public isAppOnline$: Observable<boolean>;
  public isNewsDataLoading$: Observable<boolean>;
  public blogPostsExtendable = {};
  PageTitleAppearanceEnum = PageTitleAppearance;
  PostCategoryTypeEnum = PostCategoryType;
  private isScrolledToBottom = new Subject();

  constructor(private store: Store<AppState>, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.isNewsFeedLoading$ = this.store.select(selectIsNewsLoading);
    this.unseenNewsCount$ = this.store.select(selectUnseenNewsCount);
    this.news$ = this.store.select(selectAllLoadedNews);
    this.isUserProfileLoading$ = this.store.select(getIsUserProfileLoading);
    this.isErrorOccurredOnNewsLoading$ = this.store.select(
      selectIsErrorOccurredOnNewsLoading
    );
    this.totalCount$ = this.store.select(selectTotalPostsCount);
    this.isAppOnline$ = this.store.select(getIsAppOnline);

    this.isUserProfileLoading$
      .pipe(
        filter(isLoading => isLoading === false),
        takeUntil(this.onDestroySubject)
      )
      .subscribe(() => {
        this.store.dispatch(
          NewsActions.loadNews({
            page: this.page,
            itemsPerPage: this.itemsPerPage
          })
        );
      });

    this.isNewsDataLoading$ = combineLatest(
      this.isNewsFeedLoading$,
      this.isUserProfileLoading$,
      this.news$
    ).pipe(
      map(([isNewsFeedLoading, isProfileLoading, news]) => {
        return (isNewsFeedLoading || isProfileLoading) && news.length === 0;
      })
    );

    this.isScrolledToBottom
      .pipe(
        withLatestFrom(this.news$, this.isNewsFeedLoading$, this.totalCount$),
        takeUntil(this.onDestroySubject)
      )
      .subscribe(([x, news, isNewsLoading, total]) => {
        if (total > news.length && !isNewsLoading) {
          this.page++;
          this.store.dispatch(
            NewsActions.loadNews({
              page: this.page,
              itemsPerPage: this.itemsPerPage
            })
          );
        }
      });
  }

  daysAgo(date: string) {
    return moment().diff(moment(date), 'days');
  }

  onLikeEvent(data: any) {
    this.store.dispatch(
      NewsActions.likePost({ postId: data.id, isLiked: data.isLiked })
    );
  }

  ngOnDestroy() {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    const position =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    if (document.documentElement.scrollHeight - position <= 60) {
      this.isScrolledToBottom.next(true);
    }
  }

  onContentSizeChange(event) {
    this.blogPostsExtendable[event.postId] = {
      isExtendable: event.isExtendable
    };
    this.cdr.detectChanges();
  }
}
