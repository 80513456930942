<div
  data-cy="progressSlider"
  [ngClass]="{
    progress: true,
    'progress--gray': appearance === ProgressBarAppearanceEnum.Gray,
    'progress--green': appearance === ProgressBarAppearanceEnum.Green
  }"
>
  <span class="progress__current-value">
    {{ prefix }}{{ current | number: '1.2-2' }}{{ suffix }}
  </span>
  <span class="visually-hidden" i18n="@@outOf">out of</span>
  <span class="progress__max-value">
    / {{ prefix }}{{ max | number: '1.2-2' }}{{ suffix }}
  </span>

  <div
    class="progress__bar"
    role="progressbar"
    [attr.aria-valuenow]="percentValue"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <span
      class="progress__bar-value"
      aria-hidden="true"
      [ngStyle]="{ 'width.%': percentValue }"
    ></span>
    <svg
      class="progress__flag"
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M1 1.00002L11 1.00002L8.89014 5.07167L11 9.00001H1L1 1.00002Z"
        fill="#FC5E5E"
      />
      <path
        d="M1 1.05056C1 0.479145 1.47911 1.52584e-05 2.0505 1.52582e-05C2.58248 1.52591e-05 3 0.417553 3 0.949549L3 19L1 19L1 1.05056Z"
        fill="#567FA2"
      />
    </svg>
  </div>
</div>
