/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-internet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./no-internet.component";
var styles_NoInternetComponent = [i0.styles];
var RenderType_NoInternetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoInternetComponent, data: {} });
export { RenderType_NoInternetComponent as RenderType_NoInternetComponent };
export function View_NoInternetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "no-connection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "no-connection__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No internet connection"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "no-connection__link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.message; _ck(_v, 5, 0, currVal_3); }); }
export function View_NoInternetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-internet", [], null, null, null, View_NoInternetComponent_0, RenderType_NoInternetComponent)), i1.ɵdid(1, 49152, null, 0, i4.NoInternetComponent, [], null, null)], null, null); }
var NoInternetComponentNgFactory = i1.ɵccf("app-no-internet", i4.NoInternetComponent, View_NoInternetComponent_Host_0, { message: "message", link: "link" }, {}, []);
export { NoInternetComponentNgFactory as NoInternetComponentNgFactory };
