<div class="icon-text">
  <app-svg-icon
    [name]="iconName"
    [colorPrimary]="iconColorPrimary"
    [colorSecondary]="iconColorSecondary"
    class="icon-text__icon"
  ></app-svg-icon>
  <div class="icon-text__content">
    <ng-content></ng-content>
  </div>
</div>
