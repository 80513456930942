import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { createReducer, Action, on, createSelector } from '@ngrx/store';
import { WagesActions } from '../actions';
import { WeekPayDetails } from '../../core/dataEntities/wages/weekPayDetails';
import moment from 'moment';
import { IdDateFormat } from '../../shared/constants';
import { EarningTypes } from '../../core/enums/EarningTypes';
import { LayoutActions, UserActions } from '../../core/actions';

export const weekPayFeatureKey = 'weekPay';

export interface WeekPayState extends EntityState<WeekPayDetails> {
  activeWeekId: string;
  isPayLoading: boolean;
}

export const adapter: EntityAdapter<WeekPayDetails> = createEntityAdapter<
  WeekPayDetails
>();
export const initialState: WeekPayState = adapter.getInitialState({
  activeWeekId: '',
  isPayLoading: false
});
export const wagesReducer = createReducer(
  initialState,
  on(WagesActions.loadPaymentWeekDetails, (state, action) => {
    return {
      ...state,
      activeWeekId: action.weekEndingDate
    };
  }),
  on(WagesActions.weekPaymentDetailsLoaded, (state, action) => {
    const weekPayResponse = action.weekPay;
    const weekEndingDate = moment(weekPayResponse.weekEndingDate);
    const weekPay: WeekPayDetails = {
      ...action.weekPay,
      id: weekEndingDate.format(IdDateFormat),
      dayEarningsDetails: weekPayResponse.dayEarningsDetails.map(de => {
        let exceptionEarnings = [];
        let hourlyEarningsDetails = [];

        if (de.hourlyEarningsDetails.length > 0) {
          hourlyEarningsDetails = [
            {
              date: de.date,
              earningType: EarningTypes.HourlyEarning,
              earnings: de.totalHourlyDailyEarnings,
              breakdown: de.hourlyEarningsDetails
            }
          ];
        }

        if (de.exceptionEarningsDetails.length > 0) {
          exceptionEarnings = [
            {
              date: de.date,
              earningType: EarningTypes.ExceptionEarning,
              earnings: de.totalExceptionDailyEarnings,
              breakdown: de.exceptionEarningsDetails
            }
          ];
        }

        const productionEarningsDetails = de.productionEarningsDetails.map(
          pe => {
            const associatesOnTask = pe.associatesOnTask
              ? pe.associatesOnTask.split(';').map(e => e.toLowerCase())
              : [];
            return {
              ...pe,
              earningType: EarningTypes.ProductionEarning,
              associatesOnTask
            };
          }
        );

        const earningsDetails = {
          ...de,
          allEarningsDetails: [
            ...productionEarningsDetails,
            ...hourlyEarningsDetails,
            ...exceptionEarnings
          ]
        };
        return earningsDetails;
      })
    };

    return adapter.upsertOne(weekPay, {
      ...state,
      activeWeekId: weekPay.id,
      isPayLoading: false
    });
  }),
  on(WagesActions.togglePaymentDetailsLoading, (state, action) => {
    return {
      ...state,
      isPayLoading: !state.isPayLoading
    };
  }),
  on(LayoutActions.showError, (state, action) => {
    return {
      ...state,
      isPayLoading: false
    };
  }),
  on(LayoutActions.apiOffline, (state, action) => {
    return {
      ...state,
      isPayHistoryLoading: false,
      isPayLoading: false
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(state: WeekPayState | undefined, action: Action) {
  return wagesReducer(state, action);
}

const { selectEntities } = adapter.getSelectors();

export const selectIsPayLoading = (state: WeekPayState) => {
  return state.isPayLoading;
};

export const selectActiveWeekId = (state: WeekPayState) => {
  return state.activeWeekId;
};

export const selectActiveWeekPayDetails = createSelector(
  selectActiveWeekId,
  selectEntities,
  (activeWeekId, entities) => {
    return entities[activeWeekId];
  }
);

export const selectActiveWeekTotalEarning = createSelector(
  selectActiveWeekId,
  selectEntities,
  (activeWeekId, entities) => {
    return entities[activeWeekId] != null
      ? entities[activeWeekId].totalEarnings
      : null;
  }
);

export const selectCompletedTasksCount = createSelector(
  selectActiveWeekPayDetails,
  payDetails => {
    let taskCount = 0;
    payDetails.dayEarningsDetails.forEach(day => {
      taskCount = taskCount + day.allEarningsDetails.length;
    });

    return taskCount;
  }
);
