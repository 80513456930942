import { Component, Inject, OnInit } from '@angular/core';
import { InfoBoxRef } from './info-box-ref';
import { INFOBOX_DATA } from './info-box.config';
import { SvgIconName } from '../../../enums/SvgIcon';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})

export class InfoBoxComponent implements OnInit {
  text = '';
  SvgIconNameEnum = SvgIconName;

  constructor(
    private infoBoxRef: InfoBoxRef<InfoBoxComponent>,
    @Inject(INFOBOX_DATA) public data: any
  ) {
    this.text = data && data.text;
  }

  ngOnInit() {
  }

  dismissTooltip() {
    this.infoBoxRef.close();
  }
}
