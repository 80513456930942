import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { SvgIconName } from '../../enums/SvgIcon';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {
  @Input() emptyStateMessageKey: string;
  @Input() emptyStateTemplate: TemplateRef<any>;
  @Input() emptyStateIcon: SvgIconName = SvgIconName.EmptyState;
  public emptyStateMessage: string;

  constructor(private translationService: TranslationService) {}

  ngOnInit() {
    if (this.emptyStateMessage) {
      this.emptyStateMessage = this.translationService.translate(
        this.emptyStateMessageKey
      );
    }
  }
}
