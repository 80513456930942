/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../loader/loader.component.ngfactory";
import * as i2 from "../loader/loader.component";
import * as i3 from "./loader-wrapper.component";
var styles_LoaderWrapperComponent = [];
var RenderType_LoaderWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoaderWrapperComponent, data: {} });
export { RenderType_LoaderWrapperComponent as RenderType_LoaderWrapperComponent };
export function View_LoaderWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], [[2, "is-visible", null]], null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], { debounceTime: [0, "debounceTime"], isLoading: [1, "isLoading"] }, null)], function (_ck, _v) { var currVal_1 = 0; var currVal_2 = true; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).visible; _ck(_v, 0, 0, currVal_0); }); }
export function View_LoaderWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-wrapper", [], null, null, null, View_LoaderWrapperComponent_0, RenderType_LoaderWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i3.LoaderWrapperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderWrapperComponentNgFactory = i0.ɵccf("app-loader-wrapper", i3.LoaderWrapperComponent, View_LoaderWrapperComponent_Host_0, {}, {}, []);
export { LoaderWrapperComponentNgFactory as LoaderWrapperComponentNgFactory };
