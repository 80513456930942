/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./indicator.component";
var styles_IndicatorComponent = [i0.styles];
var RenderType_IndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IndicatorComponent, data: {} });
export { RenderType_IndicatorComponent as RenderType_IndicatorComponent };
export function View_IndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "indicator"]], null, null, null, null, null))], null, null); }
export function View_IndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-indicator", [], null, null, null, View_IndicatorComponent_0, RenderType_IndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i2.IndicatorComponent, [], null, null)], null, null); }
var IndicatorComponentNgFactory = i1.ɵccf("app-indicator", i2.IndicatorComponent, View_IndicatorComponent_Host_0, {}, {}, []);
export { IndicatorComponentNgFactory as IndicatorComponentNgFactory };
