import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../shared/services/authorization.service";
export class AuthorizationGuard {
    constructor(router, authorizationService) {
        this.router = router;
        this.authorizationService = authorizationService;
    }
    canActivate(route) {
        if (route.data.allowedRoles) {
            return this.authorizationService
                .isAuthorized$(route.data.allowedRoles)
                .pipe(tap(isAuthorized => {
                if (!isAuthorized) {
                    this.router.navigate(['']);
                }
            }));
        }
        return true;
    }
}
AuthorizationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationGuard_Factory() { return new AuthorizationGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthorizationService)); }, token: AuthorizationGuard, providedIn: "root" });
