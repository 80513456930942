import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslationService } from '../../../../../../shared/services/translation.service';

@Component({
  selector: 'app-licence-readonly',
  templateUrl: './licence-readonly.component.html'
})
export class LicenceReadonlyComponent implements OnInit {
  @Input() formGroup: FormGroup;
  constructor(public translationService: TranslationService) {}

  ngOnInit() {}
}
