<ng-container>
  <ng-autocomplete
    #autocomplete
    [attr.id]="id"
    [ngClass]="{
      autocomplete: true,
      'autocomplete--invalid': isInvalid
    }"
    [data]="data"
    [searchKeyword]="displayKey"
    [placeHolder]="placeholder"
    [formControl]="formControl"
    [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate"
    minQueryLength="2"
    debounceTime="100"
    [attr.aria-invalid]="isInvalid"
    [attr.aria-describedby]="isInvalid ? errorId : null"
    [attr.disabled]="disabled ? '' : null"
    (inputFocused)="onInputFocused()"
    (inputCleared)="onInputCleared()"
  ></ng-autocomplete>

  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item[displayKey]"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
  </ng-template>
</ng-container>
