/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/tab-bar/tab-bar-link/tab-bar-link.component.ngfactory";
import * as i2 from "../../../shared/components/tab-bar/tab-bar-link/tab-bar-link.component";
import * as i3 from "../../../shared/components/tab-bar/tab-bar-component/tab-bar.component.ngfactory";
import * as i4 from "../../../shared/components/tab-bar/tab-bar-component/tab-bar.component";
import * as i5 from "@angular/common";
import * as i6 from "./earnings-tabs.component";
var styles_EarningsTabsComponent = [];
var RenderType_EarningsTabsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EarningsTabsComponent, data: {} });
export { RenderType_EarningsTabsComponent as RenderType_EarningsTabsComponent };
function View_EarningsTabsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-tab-bar-link", [["link", "../training"]], null, null, null, i1.View_TabBarLinkComponent_0, i1.RenderType_TabBarLinkComponent)), i0.ɵdid(1, 114688, null, 0, i2.TabBarLinkComponent, [], { link: [0, "link"] }, null), (_l()(), i0.ɵted(-1, 0, [" My Training "]))], function (_ck, _v) { var currVal_0 = "../training"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EarningsTabsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-tab-bar-link", [["link", "../goal"]], null, null, null, i1.View_TabBarLinkComponent_0, i1.RenderType_TabBarLinkComponent)), i0.ɵdid(1, 114688, null, 0, i2.TabBarLinkComponent, [], { link: [0, "link"], indicator: [1, "indicator"] }, null), (_l()(), i0.ɵted(-1, 0, [" My Goals "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "../goal"; var currVal_1 = (_co.goal == null); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EarningsTabsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "app-tab-bar", [], null, null, null, i3.View_TabBarComponent_0, i3.RenderType_TabBarComponent)), i0.ɵdid(1, 114688, null, 0, i4.TabBarComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "app-tab-bar-link", [["link", "../pay"]], null, null, null, i1.View_TabBarLinkComponent_0, i1.RenderType_TabBarLinkComponent)), i0.ɵdid(3, 114688, null, 0, i2.TabBarLinkComponent, [], { link: [0, "link"] }, null), (_l()(), i0.ɵted(-1, 0, [" My Earning "])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_EarningsTabsComponent_2)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_EarningsTabsComponent_3)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "../pay"; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isTrainee; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.isTrainee; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_EarningsTabsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_EarningsTabsComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTabs; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EarningsTabsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-earnings-tabs", [], null, null, null, View_EarningsTabsComponent_0, RenderType_EarningsTabsComponent)), i0.ɵdid(1, 114688, null, 0, i6.EarningsTabsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EarningsTabsComponentNgFactory = i0.ɵccf("app-earnings-tabs", i6.EarningsTabsComponent, View_EarningsTabsComponent_Host_0, { isTrainee: "isTrainee", isHistoricalEarning: "isHistoricalEarning", goal: "goal" }, {}, []);
export { EarningsTabsComponentNgFactory as EarningsTabsComponentNgFactory };
