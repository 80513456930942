/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feed-separator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./feed-separator.component";
var styles_FeedSeparatorComponent = [i0.styles];
var RenderType_FeedSeparatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedSeparatorComponent, data: {} });
export { RenderType_FeedSeparatorComponent as RenderType_FeedSeparatorComponent };
function View_FeedSeparatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "feed-separator__unseen"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " New "]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); }); }
function View_FeedSeparatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FeedSeparatorComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.unseenCount)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_FeedSeparatorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Today"]))], null, null); }
function View_FeedSeparatorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Yesterday"]))], null, null); }
function View_FeedSeparatorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_FeedSeparatorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.date, "EEEE LLL dd")); _ck(_v, 1, 0, currVal_0); }); }
export function View_FeedSeparatorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "feed-separator"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedSeparatorComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 11, "span", [["class", "feed-separator__time"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedSeparatorComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgPluralCase, [[8, "=0"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedSeparatorComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedSeparatorComponent_5)), i1.ɵdid(13, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedSeparatorComponent_6)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFirst; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.daysAgo(_co.date); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.daysAgo(_co.date) > 1); _ck(_v, 15, 0, currVal_2); }, null); }
export function View_FeedSeparatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feed-separator", [], null, null, null, View_FeedSeparatorComponent_0, RenderType_FeedSeparatorComponent)), i1.ɵdid(1, 49152, null, 0, i3.FeedSeparatorComponent, [], null, null)], null, null); }
var FeedSeparatorComponentNgFactory = i1.ɵccf("app-feed-separator", i3.FeedSeparatorComponent, View_FeedSeparatorComponent_Host_0, { date: "date", isFirst: "isFirst", unseenCount: "unseenCount" }, {}, []);
export { FeedSeparatorComponentNgFactory as FeedSeparatorComponentNgFactory };
