/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./accordion.service";
import * as i2 from "./accordion.component";
var styles_AccordionComponent = [];
var RenderType_AccordionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccordionComponent, data: {} });
export { RenderType_AccordionComponent as RenderType_AccordionComponent };
export function View_AccordionComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_AccordionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-accordion", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AccordionComponent_0, RenderType_AccordionComponent)), i0.ɵprd(512, null, i1.AccordionService, i1.AccordionService, []), i0.ɵdid(2, 1294336, null, 1, i2.AccordionComponent, [i1.AccordionService], null, null), i0.ɵqud(603979776, 1, { panels: 1 })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AccordionComponentNgFactory = i0.ɵccf("app-accordion", i2.AccordionComponent, View_AccordionComponent_Host_0, { multi: "multi" }, {}, ["*"]);
export { AccordionComponentNgFactory as AccordionComponentNgFactory };
