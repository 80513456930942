import {
  Inject,
  Injectable,
  Injector,
  Optional,
  TemplateRef
} from '@angular/core';
import { BaseModalContainerComponent } from './base-modal-container.component';
import {
  ComponentType,
  Overlay,
  OverlayConfig,
  OverlayRef
} from '@angular/cdk/overlay';
import {
  ComponentPortal,
  PortalInjector,
  TemplatePortal
} from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { of as observableOf } from 'rxjs';
import { Directionality } from '@angular/cdk/bidi';
import {
  DIALOG_DATA,
  DIALOG_DEFAULT_OPTIONS,
  DialogConfig
} from '../dialog-config';
import { DialogRef } from '../dialog-ref';

@Injectable()
export abstract class BaseModalService {
  protected constructor(
    private overlay: Overlay,
    private injector: Injector,
    @Optional() private location: Location,
    @Optional()
    @Inject(DIALOG_DEFAULT_OPTIONS)
    public defaultOptions: DialogConfig
  ) {}

  abstract open<C extends BaseModalContainerComponent, T, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: DialogConfig
  ): DialogRef<T, R>;

  protected createOverlay(config: DialogConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(dialogConfig: DialogConfig): OverlayConfig {
    const state = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      scrollStrategy:
        dialogConfig.scrollStrategy &&
        dialogConfig.scrollStrategy === 'reposition'
          ? this.overlay.scrollStrategies.reposition()
          : this.overlay.scrollStrategies.block(),
      hasBackdrop: dialogConfig.hasBackdrop,
      disposeOnNavigation: dialogConfig.disposeOnNavigation,
      width: dialogConfig.width,
      height: dialogConfig.height
    });

    if (dialogConfig.backdropClass) {
      state.backdropClass = dialogConfig.backdropClass;
    }

    return state;
  }

  protected attachDialogContainer<T extends BaseModalContainerComponent>(
    overlay: OverlayRef,
    component: ComponentType<T>,
    config: DialogConfig
  ) {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = new PortalInjector(
      userInjector || this.injector,
      new WeakMap([[DialogConfig, config]])
    );
    const containerPortal = new ComponentPortal(
      component,
      config.viewContainerRef,
      injector,
      config.componentFactoryResolver
    );
    const containerRef = overlay.attach<T>(containerPortal);
    return containerRef;
  }

  protected attachDialogContent<T, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    dialogContainer: BaseModalContainerComponent,
    overlayRef: OverlayRef,
    config: DialogConfig
  ): DialogRef<T, R> {
    const dialogRef = new DialogRef<T, R>(
      overlayRef,
      dialogContainer,
      this.location,
      config.id
    );

    if (config.hasBackdrop) {
      overlayRef.backdropClick().subscribe(() => {
        if (!dialogRef.disableClose) {
          dialogRef.close();
        }
      });
    }

    if (componentOrTemplateRef instanceof TemplateRef) {
      dialogContainer.attachTemplatePortal(
        new TemplatePortal<T>(componentOrTemplateRef, null, {
          $implicit: config.data,
          dialogRef
        } as any)
      );
    } else {
      const injector = this.createInjector<T>(
        config,
        dialogRef,
        dialogContainer
      );
      const contentRef = dialogContainer.attachComponentPortal(
        new ComponentPortal(componentOrTemplateRef, undefined, injector)
      );

      dialogRef.componentInstance = contentRef.instance;
    }

    dialogRef.updatePosition(config.position);

    return dialogRef;
  }

  private createInjector<T>(
    config: DialogConfig,
    dialogRef: DialogRef<T>,
    dialogContainer: BaseModalContainerComponent
  ): PortalInjector {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;

    const injectionTokens = new WeakMap<any, any>([
      [BaseModalContainerComponent, dialogContainer],
      [DIALOG_DATA, config.data],
      [DialogRef, dialogRef]
    ]);

    if (
      config.direction &&
      (!userInjector ||
        !userInjector.get<Directionality | null>(Directionality, null))
    ) {
      injectionTokens.set(Directionality, {
        value: config.direction,
        change: observableOf()
      });
    }

    return new PortalInjector(userInjector || this.injector, injectionTokens);
  }

  protected applyConfigDefaults(
    config?: DialogConfig,
    defaultOptions?: DialogConfig
  ): DialogConfig {
    return { ...defaultOptions, ...config };
  }
}

export class DialogResult<T = any> {
  success: boolean;
  data?: T;
}
