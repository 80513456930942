import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-current-goal',
  templateUrl: './current-goal.component.html',
  styleUrls: ['./current-goal.component.scss']
})
export class CurrentGoalComponent {
  @Input() earningGoal: number;
  @Input() infoBoxText: string;
  @Input() associatePaySplit: number;

  constructor() {}
}
