import { Component } from '@angular/core';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';
import { DialogRef } from '../../../../../shared/modules/modal/dialog-ref';

@Component({
  selector: 'app-skip-certifications',
  templateUrl: './skip-certifications.component.html',
  styleUrls: ['./skip-certifications.component.scss']
})
export class SkipCertificationsComponent {
  SvgIconNameEnum = SvgIconName;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;
  constructor(private modalRef: DialogRef<SkipCertificationsComponent>) {}

  closeModal() {
    this.modalRef.close({ success: false });
  }

  skip() {
    this.modalRef.close({ success: true });
  }
}
