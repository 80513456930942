import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function MinDateValidator(date): ValidatorFn {
  return (control: AbstractControl): { [minDate: string]: any } | null => {
    if (control.value === '' || control.value === undefined) {
      return null;
    }
    return moment(date).isAfter(moment(control.value))
      ? { minDate: date }
      : null;
  };
}
