import { Injectable, QueryList } from '@angular/core';
import { FormStepDefDirective } from '../directives/form-step-def.directive';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Injectable()
export class MultiStepFormService {
  private editableStepSubject = new BehaviorSubject<FormStepDefDirective>(null);
  editableStep$ = this.editableStepSubject.asObservable();

  getEditableStep(): FormStepDefDirective {
    return this.editableStepSubject.value;
  }

  setEditableStep(step: FormStepDefDirective) {
    this.editableStepSubject.next(step);
  }

  isEditableStep(step: FormStepDefDirective) {
    return this.editableStepSubject.value === step;
  }

  canGoToNextStep(formGroup: FormGroup) {
    return formGroup.get(this.editableStepSubject.value.name).valid;
  }

  getNextEditableStep(
    formSteps: QueryList<FormStepDefDirective>
  ): FormStepDefDirective {
    const steps = formSteps.toArray();
    let editableIndex = steps.indexOf(this.editableStepSubject.value);
    if (editableIndex < 0) {
      editableIndex = 0;
    }
    if (editableIndex < steps.length - 1) {
      return steps[editableIndex + 1];
    }
    return null;
  }
}
