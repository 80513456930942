import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[appRootForm]'
})
export class RootFormDirective {
  public formDirective: FormGroupDirective;

  constructor(
    private el: ElementRef,
    protected parentForm: FormGroupDirective
  ) {
    this.formDirective = parentForm;
  }

  @HostListener('submit')
  onFormSubmit() {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');

    if (
      invalidControl &&
      invalidControl.children &&
      invalidControl.children[0]
    ) {
      invalidControl.children[0].focus();
    } else if (invalidControl) {
      invalidControl.focus();
    }
  }
}
