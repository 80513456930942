import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss']
})
export class MenuButtonComponent {
  @Input() topRoundedBorder = true;
  @Input() bottomRoundedBorder = true;
  @Input() isExternalUrl = false;
  @Input() link: string | any[];
  @Input() queryParams: any;
  @Output() buttonClick = new EventEmitter();

  constructor() {}

  onClickButton(event) {
    this.buttonClick.emit(event);
  }
}
