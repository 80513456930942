import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import { SurveysActions } from '../actions';
import {
  catchError,
  delay,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import * as _ from 'lodash';

import { LayoutActions, UserActions } from '../../core/actions';
import { Store } from '@ngrx/store';
import {
  AppState,
  getUserId,
  selectActiveSurvey,
  isUserAgreeWithTerms,
  getUserSitesIds,
  selectTotalSurveys
} from '../../reducers';
import { Survey } from '../../core/dataEntities/surveys/survey';
import { SubmitSurveyRequest } from '../../core/request/survey/submitSurveyRequest';
import { EMPTY, of } from 'rxjs';
import { Router } from '@angular/router';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../shared/error-handlers/catch-app-error';
import { TranslationService } from '../../shared/services/translation.service';
import { TranslationMessages } from '../../shared/enums/TranslationMessages';
import { BLOCKS } from '@contentful/rich-text-types';
import { SurveyService } from '../services/mock/survey.service';

@Injectable()
export class SurveysEffects {
  constructor(
    private actions$: Actions,
    private surveysService: SurveyService,
    private store: Store<AppState>,
    private router: Router,
    @Inject(APP_ERROR_MESSAGES) private errorMessages,
    private translation: TranslationService
  ) {}

  loadSurveys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveysActions.loadSurveys),
      withLatestFrom(this.store.select(getUserId)),
      switchMap(([action, userId]) => {
        return this.surveysService.getAvailableSurveys(userId).pipe(
          switchMap(surveys => {
            if (surveys.length) {
              return of(SurveysActions.surveysLoaded({ surveys }));
            }
            return of(SurveysActions.surveysStatusChecked());
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  showSurveyNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveysActions.surveysLoaded),
      switchMap(payload => {
        if (!_.isEmpty(payload.surveys)) {
          return of(LayoutActions.menuNotification()).pipe(delay(200));
        }
        return EMPTY;
      })
    )
  );

  loadSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveysActions.loadSurvey),
      mergeMap(({ id }) => {
        return this.surveysService.getSurvey(id).pipe(
          map(survey => {
            return SurveysActions.surveyLoaded({
              survey
            });
          }),
          catchError(handleAppError(this.errorMessages))
        );
      })
    )
  );

  triggerLoadSurveys$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.appOnInit),
        switchMap(() => this.store.select(isUserAgreeWithTerms)),
        filter(isUserAgree => isUserAgree),
        first(),
        tap(() => {
          this.store.dispatch(SurveysActions.getIsEachSurveySubmitted());
          return this.store.dispatch(SurveysActions.loadSurveys());
        })
      ),
    { dispatch: false }
  );

  afterAgreeWithTermsOpenActiveSurvey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.agreeWithTerms),
        switchMap(() => this.store.select(selectActiveSurvey)),
        filter(activeSurvey => !!activeSurvey),
        first(),
        tap(activeSurvey => {
          return this.router.navigate(['survey', activeSurvey.id]);
        })
      ),
    { dispatch: false }
  );

  surveySubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveysActions.surveySubmitted),
      withLatestFrom(this.store.select(selectTotalSurveys)),
      switchMap(([action, totalSurveys]) => {
        if (totalSurveys === 0) {
          return of(LayoutActions.dismissMenuNotification());
        }
        return EMPTY;
      })
    )
  );

  submitSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveysActions.submitSurvey),
      withLatestFrom(
        this.store.select(getUserId),
        this.store.select(getUserSitesIds)
      ),
      mergeMap(([{ survey }, userId, userSites]) => {
        const subDept = userSites ? userSites[0] : 0;
        const surveyRequest = this.createSurveyRequest(survey, userId, subDept);
        return this.surveysService.submitSurvey(surveyRequest).pipe(
          switchMap(response => {
            if (!response.success) {
              return of(
                LayoutActions.showError({
                  message: this.translation.translate(
                    TranslationMessages.ErrorMessagesUnknownError
                  ),
                  error: null
                })
              );
            }

            this.router.navigate(['']);
            return of(
              LayoutActions.showSuccessMessage({
                title: this.translation.translate(
                  TranslationMessages.SurveySubmitted
                ),
                subtitle: this.translation.translate(
                  TranslationMessages.SurveyThankYou
                ),
                persistRouteChangeCount: 1
              }),
              SurveysActions.surveySubmitted({
                id: survey.id
              }),
              SurveysActions.getIsEachSurveySubmitted()
            );
          }),
          catchError(handleAppError(this.errorMessages))
        );
      })
    )
  );

  getIsEachSurveySubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveysActions.getIsEachSurveySubmitted),
      withLatestFrom(this.store.select(getUserId)),
      switchMap(([action, userId]) => {
        return this.surveysService.getUnsubmittedSurveys(userId).pipe(
          map(result => {
            return SurveysActions.saveIsEachSurveySubmitted({
              result
            });
          })
        );
      })
    )
  );

  createSurveyRequest(
    survey: Survey,
    userId: string,
    subDept: number
  ): SubmitSurveyRequest {
    const responses = survey.questions.map(question => {
      return {
        contentfulQuestionId: question.id,
        question: documentToHtmlString(question.question, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, next) => next(node.content)
          }
        }),
        answer: question.answer
      };
    });

    return {
      contentfulSurveyId: survey.id,
      surveyTitle: survey.id,
      associateGuid: userId,
      subDept,
      responses
    };
  }
}
