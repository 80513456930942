<app-card-layout *ngIf="safetyObservation.length === 0">
  <app-card-layout-item i18n="@@production-details-observations_noFound">
    No observation found
  </app-card-layout-item>
</app-card-layout>
<app-accordion *ngIf="safetyObservation.length > 0">
  <app-accordion-panel
    *ngFor="let observation of safetyObservation; let i = index"
  >
    <app-accordion-header-primary
      slot="header"
      [title]="i + 1 + '. ' + observation.behavior"
      [subtitle]="observation.date | date: 'EEEE, MM/dd'"
    ></app-accordion-header-primary>
    <p slot="body">{{ observation.action }}</p>
  </app-accordion-panel>
</app-accordion>
