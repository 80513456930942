<main class="main-container">
  <app-associate-page-header
    [apexId]="userApexId$ | async"
    [userName]="userName$ | async"
    [isTrainee]="isTrainee$ | async"
    [photoSrc]="photo$ | async"
    [isAssociateView]="true"
  ></app-associate-page-header>

  <div class="container">
    <app-associate-profile-tabs></app-associate-profile-tabs>

    <app-loader [isLoading]="isProductionDetailsLoading$ | async"></app-loader>

    <app-no-internet
      *ngIf="showEmptyState$ | async"
      i18n-message="@@associate-production_backToNews"
      message="Go back to News"
      [link]="'/news'"
    ></app-no-internet>

    <ng-container
      *ngIf="
        (isProductionDetailsLoading$ | async) === false &&
        (productionDetails$ | async) != null
      "
    >
      <ng-template #productionDetails>
        <app-production-details
          [isAssociateView]="true"
          [productionDetails]="productionDetails$ | async"
        ></app-production-details>
      </ng-template>

      <app-trainee-production-details
        *ngIf="isTrainee$ | async; else productionDetails"
        [isAssociateView]="true"
        [productionDetails]="productionDetails$ | async"
      ></app-trainee-production-details>
    </ng-container>
  </div>
</main>
