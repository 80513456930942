<main class="disclaimer">
  <div *ngIf="(isUserInfoLoading$ | async) === false">
    <!-- Empty div: Fix safari sticky behaviour -->
    <div class="disclaimer__header welcome-header">
      <app-svg-icon
        class="welcome-header__icon"
        [name]="SvgIconNameEnum.CapstoneLogo"
        i18n-description="@@CapstoneLogotype"
        description="Capstone logotype"
      ></app-svg-icon>
      <h1 class="welcome-header__heading">
        <span class="welcome-header__subheading" i18n="@@welcomeTo">
          Welcome to
        </span>
        <ng-container i18n="@@myCapstone">My Capstone</ng-container>
      </h1>
    </div>
    <div *ngIf="content" class="disclaimer__terms terms">
      <h2>{{ content?.title }}</h2>
      <div [innerHTML]="content?.content"></div>
    </div>
    <div class="disclaimer__action">
      <app-button-link
        (buttonClick)="decline()"
        [appearance]="ButtonAppearanceEnum.Link"
        i18n-label="@@disclaimer_iDecline"
        label="I decline"
      ></app-button-link>
      <app-button-link
        (buttonClick)="agreeToTerms()"
        i18n-label="@@disclaimer_iAgreeToTerms"
        label="I Agree to the terms"
      ></app-button-link>
    </div>
  </div>
</main>
<app-loader [isLoading]="isUserInfoLoading$ | async"></app-loader>
