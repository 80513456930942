import { Component, Input, OnInit } from '@angular/core';
import { ShiftDetails } from '../../../../../core/dataEntities/shifts/shiftDetails';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import { ShiftContactTypes } from '../../../../../core/enums/ShiftContactTypes';
import moment from 'moment';

@Component({
  selector: 'app-shift-details-card',
  templateUrl: './shift-details-card.component.html',
  styleUrls: ['./shift-details-card.component.scss']
})
export class ShiftDetailsCardComponent implements OnInit {
  @Input() shiftDetails: ShiftDetails;
  @Input() preview = false;
  endDate: string;
  startDate: string;
  durationInHours: string;
  SvgIconNameEnum = SvgIconName;
  ShiftContactTypesEnum = ShiftContactTypes;

  constructor() {}

  ngOnInit() {
    this.endDate = moment(this.shiftDetails.startDateTime)
      .add(this.shiftDetails.duration, 'hours')
      .format();

    this.startDate = moment(this.shiftDetails.startDateTime).format();

    this.shiftDetails.duration === 24
      ? (this.durationInHours = '24:00')
      : (this.durationInHours = moment
          .utc()
          .startOf('day')
          .add({ hours: this.shiftDetails.duration })
          .format('H:mm'));
  }

  openSMSMobile() {
    window.open('sms:' + this.shiftDetails.phoneNumber, '_self');
    return false;
  }
}
