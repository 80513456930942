import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  forwardRef,
  Optional,
  Inject,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import moment from 'moment';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { FormFieldControl } from '../form-field/form-field.control';
import {
  FORM_FIELD,
  FormFieldComponent
} from '../form-field/form-field.component';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RoadCrewApplicationApiService } from 'src/app/wages/modules/work/services/road-crew-application.api.service';
import { DocumentExpirationDate } from 'src/app/core/responses/application-form/roadCrewApplicationResponse';

let nextUniqueId = 0;

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent)
    },
    { provide: FormFieldControl, useExisting: DatePickerComponent }
  ]
})
export class DatePickerComponent
  implements
    OnInit,
    OnDestroy,
    ControlValueAccessor,
    FormFieldControl,
    OnChanges {
  onDestroySubject = new Subject();

  @Input() format = 'YYYY-MM-DD';
  @Input() dayPlaceholder = 'DD';
  @Input() monthPlaceholder = 'MM';
  @Input() yearPlaceholder = 'YY';
  @Input() disabled: boolean;
  @Input() selectedState: string;
  @Input() documentType: string;

  @Output() dateChangeFromChild = new EventEmitter<{
    newDate: string;
    docType: string;
  }>();

  formGroup: FormGroup;
  numOfYears = 11;
  state: string;
  docType: string;

  public years = [];
  public months = [];
  public days = [];

  specialExpDocs: DocumentExpirationDate[];

  protected defaultId = `app-date-picker-${nextUniqueId++}`;
  protected componentId: string;

  constructor(
    fb: FormBuilder,
    private roadCrewApi: RoadCrewApplicationApiService,
    @Optional() @Inject(FORM_FIELD) private formField: FormFieldComponent
  ) {
    this.formGroup = fb.group({
      day: ['', [Validators.required]],
      month: ['', [Validators.required]],
      year: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    // populate the years dropdown with the standard value first
    // in case the getSpecialExpirationDates() endpoint takes awhile to load

    this.calculateYears(this.numOfYears);

    this.roadCrewApi.getSpecialExpirationDates().subscribe(docs => {
      this.specialExpDocs = docs;

      if (this.specialExpDocs !== undefined && this.specialExpDocs !== null) {
        const matchingDocs = this.specialExpDocs.filter(
          doc =>
            doc.document_Type === this.documentType &&
            (doc.state === this.selectedState || doc.state === ' ')
        );

        if (matchingDocs.length > 0) {
          const newNumOfYears = matchingDocs[0].num_Of_Years_Until_Expiration;
          this.calculateYears(newNumOfYears);
        } else {
          this.calculateYears(this.numOfYears);
        }
      } else {
        this.calculateYears(this.numOfYears);
      }
    });

    this.formGroup
      .get('day')
      .valueChanges.pipe(takeUntil(this.onDestroySubject))
      .subscribe(day => {
        this.propagateChange(
          this.constructDate(
            this.formGroup.get('year').value,
            this.formGroup.get('month').value,
            day
          )
        );
        this.propagateTouched();
      });

    this.formGroup
      .get('month')
      .valueChanges.pipe(takeUntil(this.onDestroySubject))
      .subscribe(month => {
        this.propagateChange(
          this.constructDate(
            this.formGroup.get('year').value,
            month,
            this.formGroup.get('day').value
          )
        );
        this.daysDropdownReset(month);
        this.propagateTouched();
      });

    this.formGroup
      .get('year')
      .valueChanges.pipe(takeUntil(this.onDestroySubject))
      .subscribe(year => {
        this.propagateChange(
          this.constructDate(
            year,
            this.formGroup.get('month').value,
            this.formGroup.get('day').value
          )
        );
        this.daysDropdownReset(this.formGroup.get('month').value);
        this.propagateTouched();
      });

    this.days = _.range(1, 32).map(num => {
      return {
        name: _.padStart(num, 2, '0'),
        value: num
      };
    });
    this.months = _.range(1, 13).map(num => {
      return {
        name: _.padStart(num, 2, '0'),
        value: num
      };
    });
  }

  @Input()
  get id(): string {
    return this.componentId;
  }

  set id(value: string) {
    this.componentId = value || this.defaultId;
  }

  get errorId(): string {
    return `${this.id}-error`;
  }

  public get control() {
    return this.formGroup;
  }

  get isInvalid() {
    if (!this.formField) {
      return false;
    }

    return this.formField.isInvalid();
  }

  get hasValidation() {
    if (!this.formField) {
      return false;
    }

    return this.formField.hasValidation();
  }

  calculateYears(numOfYears: number) {
    const currentYear = moment().year();
    this.years = _.range(currentYear, currentYear + numOfYears);

    if (this.formGroup.get('year').value > currentYear + numOfYears) {
      const newDate: string = this.constructDate(
        currentYear + numOfYears - 1,
        this.formGroup.get('month').value,
        this.formGroup.get('day').value
      );

      this._dateChangeFromChild(newDate, this.documentType);

      this.formGroup.setValue(
        {
          day: this.formGroup.get('day').value,
          month: this.formGroup.get('month').value,
          year: currentYear + numOfYears - 1
        },
        { emitEvent: false }
      );
    }
  }

  _dateChangeFromChild(newDate: string, docType: string) {
    this.dateChangeFromChild.emit({ newDate, docType });
  }

  writeValue(value) {
    if (value) {
      const date = moment(value, this.format);
      this.formGroup.setValue(
        {
          day: date.date(),
          month: date.month() + 1,
          year: date.year()
        },
        { emitEvent: false }
      );
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  ngOnDestroy() {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  private constructDate(year, month, day) {
    return moment()
      .date(day)
      .month(month - 1)
      .year(year)
      .format(this.format)
      .toString();
  }

  private propagateChange = (value: any) => {};
  private propagateTouched = () => {};

  ngOnChanges(changes: SimpleChanges) {
    this.state =
      changes.selectedState !== undefined
        ? changes.selectedState.currentValue
        : this.selectedState;
    this.docType =
      changes.documentType !== undefined
        ? changes.documentType.currentValue
        : this.documentType;

    if (this.specialExpDocs !== undefined && this.specialExpDocs !== null) {
      const matchingDocs = this.specialExpDocs.filter(
        doc =>
          doc.document_Type === this.docType &&
          (doc.state === this.state || doc.state === ' ')
      );

      if (matchingDocs.length > 0) {
        const newNumOfYears = matchingDocs[0].num_Of_Years_Until_Expiration;
        this.calculateYears(newNumOfYears);
      } else {
        this.calculateYears(this.numOfYears);
      }
    }
  }

  isLeap(year: number) {
    return new Date(year, 1, 29).getDate() === 29;
  }

  daysDropdownReset(month: number) {
    const isLeapYear = this.isLeap(this.formGroup.get('year').value);

    switch (month) {
      case 1: {
        this.recalculateDays(32);
        break;
      }
      case 2: {
        if (isLeapYear) {
          this.recalculateDays(30);
          if (this.formGroup.get('day').value > 29) {
            this.formGroup.setValue(
              {
                day: 29,
                month: this.formGroup.get('month').value,
                year: this.formGroup.get('year').value
              },
              { emitEvent: false }
            );
          }
        } else {
          this.recalculateDays(29);
          if (this.formGroup.get('day').value > 28) {
            this.formGroup.setValue(
              {
                day: 28,
                month: this.formGroup.get('month').value,
                year: this.formGroup.get('year').value
              },
              { emitEvent: false }
            );
          }
        }
        break;
      }
      case 3: {
        this.recalculateDays(32);
        break;
      }
      case 4: {
        this.recalculateDays(31);
        if (this.formGroup.get('day').value > 30) {
          this.formGroup.setValue(
            {
              day: 30,
              month: this.formGroup.get('month').value,
              year: this.formGroup.get('year').value
            },
            { emitEvent: false }
          );
        }
        break;
      }
      case 5: {
        this.recalculateDays(32);
        break;
      }
      case 6: {
        this.recalculateDays(31);
        if (this.formGroup.get('day').value > 30) {
          this.formGroup.setValue(
            {
              day: 30,
              month: this.formGroup.get('month').value,
              year: this.formGroup.get('year').value
            },
            { emitEvent: false }
          );
        }
        break;
      }
      case 7: {
        this.recalculateDays(32);
        break;
      }
      case 8: {
        this.recalculateDays(32);
        break;
      }
      case 9: {
        this.recalculateDays(31);
        if (this.formGroup.get('day').value > 30) {
          this.formGroup.setValue(
            {
              day: 30,
              month: this.formGroup.get('month').value,
              year: this.formGroup.get('year').value
            },
            { emitEvent: false }
          );
        }
        break;
      }
      case 10: {
        this.recalculateDays(32);
        break;
      }
      case 11: {
        this.recalculateDays(31);
        if (this.formGroup.get('day').value > 30) {
          this.formGroup.setValue(
            {
              day: 30,
              month: this.formGroup.get('month').value,
              year: this.formGroup.get('year').value
            },
            { emitEvent: false }
          );
        }
        break;
      }
      case 12: {
        this.recalculateDays(32);
        break;
      }
      default: {
        this.recalculateDays(32);
        break;
      }
    }
  }

  recalculateDays(days: number) {
    this.days = _.range(1, days).map(num => {
      return {
        name: _.padStart(num, 2, '0'),
        value: num
      };
    });
  }
}
