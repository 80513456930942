<app-page-header-container></app-page-header-container>

<main class="main-container">
  <ng-template #newShiftTitle i18n="@@shift-edit_newPageTitle">
    Fill A Shift
  </ng-template>
  <ng-template #editShiftTitle i18n="@@shift-edit_editPageTitle">
    Edit Shift Details
  </ng-template>
  <app-page-title
    [titleTemplate]="
      (shiftDetails$ | async)?.id ? editShiftTitle : newShiftTitle
    "
  >
    <app-page-subtitle i18n="@@shiftShorthandedNotice">
      Short-handed? Recruit all associates at your site to apply for additional
      shifts.
    </app-page-subtitle>
  </app-page-title>

  <app-loader
    [isLoading]="(isSitesLoading$ | async) || (isShiftLoading$ | async)"
  ></app-loader>

  <ng-container
    *ngIf="
      (isSitesLoading$ | async) === false && (isShiftLoading$ | async) === false
    "
  >
    <div *ngIf="shiftFormGroup as shiftForm" class="shift__container">
      <form
        [formGroup]="shiftForm"
        appRootForm
        (ngSubmit)="onSubmit(shiftForm)"
      >
        <app-form-field [required]="true">
          <app-label i18n="@@shift-edit_sitesDropDownTitle">
            Which Site do you need open shifts?
          </app-label>

          <app-select
            formControlName="siteId"
            [options]="sites$ | async"
          ></app-select>

          <app-validation [errors]="shiftForm.get('siteId').errors">
            <app-validation-message
              *ngIf="shiftForm.get('siteId').errors?.required !== null"
              i18n="@@shift-edit_SiteValidationMessage"
            >
              You must select a site for the shift.
            </app-validation-message>
          </app-validation>
        </app-form-field>

        <app-form-field [required]="true">
          <app-label i18n="@@shift-edit_dateTitle">
            When do you need a shift filled?
          </app-label>
          <app-select
            formControlName="startDate"
            placeholder="Select shift date"
            i18n-placeholder="@@shift-edit_shiftDatePlaceholder"
            [options]="days"
          ></app-select>
          <app-validation [errors]="shiftForm.get('startDate').errors">
            <app-validation-message
              *ngIf="shiftForm.get('startDate').errors?.required !== null"
              [id]="'select-startDate'"
              i18n="@@shift-edit_startDateValidationMessage"
            >
              You must select a date for the shift.
            </app-validation-message>
          </app-validation>
        </app-form-field>

        <div class="shift__input-container">
          <div class="shift__half">
            <app-form-field
              [validation]="startTimeValidation"
              [required]="true"
            >
              <app-label i18n="@@shift-edit_startTimeTitle">
                Shift start time
              </app-label>
              <app-select
                #startTimeSelect
                formControlName="startTime"
                [options]="startTimes"
                [placeholder]="'-- : --  --'"
              ></app-select>
            </app-form-field>
          </div>

          <div class="shift__half">
            <app-form-field [required]="true">
              <app-label i18n="@@shift-edit_endTimeTitle">
                Shift end time
              </app-label>
              <app-select
                formControlName="endTime"
                [options]="endTimes"
                [placeholder]="'-- : --  --'"
              ></app-select>
            </app-form-field>
          </div>
        </div>
        <div class="shift__time-validation-container">
          <app-validation
            #startTimeValidation
            [errors]="shiftForm.get('startTime').errors"
          >
            <app-validation-message
              [id]="startTimeSelect.errorId"
              *ngIf="shiftForm.get('startTime').errors?.required !== null"
              i18n="@@shift-edit_timeValidationMessage"
            >
              You must enter a valid start and end time.
            </app-validation-message>
          </app-validation>
        </div>
        <span
          class="shift__duration"
          *ngIf="this.shiftForm.get('endTime').value"
        >
          Shift Duration: {{ this.shiftForm.get('endTime').value }} hours
        </span>

        <app-form-field>
          <app-label i18n="@@shift-edit_editShiftAssociatesNeeded">
            How many associates do you need?
          </app-label>
          <app-select
            formControlName="associatesNeeded"
            [options]="associatesOptions"
          ></app-select>
        </app-form-field>

        <app-form-field [required]="true">
          <app-label i18n="@@shift-edit_phoneNumber">
            Where Should associates call/text you?
          </app-label>
          <app-text-input
            formControlName="phoneNumber"
            placeholder="EG. (312) 444 5555"
            i18n-placeholder="@@shift-edit_phonePlaceholder"
            inputMask="(000) 000 0000"
            inputType="tel"
          ></app-text-input>
          <app-validation [errors]="shiftForm.get('phoneNumber').errors">
            <app-validation-message
              *ngIf="
                shiftForm.get('phoneNumber').errors?.required !== null ||
                shiftForm.get('phoneNumber').errors?.minLength !== null
              "
              [id]="'input-phoneNumber'"
              i18n="@@shift-edit_phoneNumberValidationMessage"
            >
              Please provide a valid number for associates to contact
            </app-validation-message>
          </app-validation>
        </app-form-field>

        <app-form-field>
          <app-label i18n="@@shift-edit_contactType">
            How do you want to be contacted?
          </app-label>
          <app-select
            formControlName="contactType"
            [options]="contactTypesOptions"
          ></app-select>
        </app-form-field>

        <div *ngIf="!(shiftDetails$ | async).id" class="form-action">
          <app-button-link
            [type]="ButtonTypeEnum.Submit"
            [appearance]="ButtonAppearanceEnum.Secondary"
            i18n-label="@@shift-edit_previewYourShift"
            label="Preview Your Shift Request"
            class="form-action__center"
          ></app-button-link>
        </div>

        <div *ngIf="(shiftDetails$ | async).id" class="form-action">
          <app-button-link
            [link]="backUrl$ | async"
            [isBack]="true"
            [appearance]="ButtonAppearanceEnum.Link"
            i18n-label="@@shift-edit_abandonUpdates"
            label="Abandon Updates"
          ></app-button-link>
          <app-button-link
            [type]="ButtonTypeEnum.Submit"
            [appearance]="ButtonAppearanceEnum.Secondary"
            i18n-label="@@shift-edit_previewUpdates"
            label="Preview Updates"
          ></app-button-link>
        </div>
      </form>
    </div>
  </ng-container>
</main>
