export class StringHelpers {
  static capitalize(value) {
    if (value) {
      return value
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    }
    return '';
  }

  static joinStrings(string1, string2) {
    if (string1 && string2) {
      return `${string1}, ${string2}`;
    } else if (string1 && !string2) {
      return string1;
    } else if (!string1 && string2) {
      return string2;
    }
    return '';
  }
}
