import { Pipe, PipeTransform } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

@Pipe({
  name: 'documentToHtml'
})
export class DocumentToHtmlPipe implements PipeTransform {
  transform(value: any, tag = 'p', ...args: any[]): any {
    return documentToHtmlString(value, {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, next) =>
          `<${tag}>${next(node.content).replace(/\n/g, '<br/>')}</${tag}>`
      }
    });
  }
}
