import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WagesActions } from '../actions';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
  switchMap
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import moment from 'moment';
import { DateFormat, IdDateFormat } from '../../shared/constants';
import { WagesService } from '../../core/contracts/WagesService';
import { Store } from '@ngrx/store';
import { AppState, selectActiveWeekPayDetails } from '../../reducers';
import { GoalService } from '../modules/goal/services/goal.service';
import { GoalActions } from '../modules/goal/actions';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../shared/error-handlers/catch-app-error';

@Injectable()
export class WagesEffects {
  constructor(
    private actions$: Actions,
    private wagesService: WagesService,
    private store: Store<AppState>,
    private goalService: GoalService,
    @Inject(APP_ERROR_MESSAGES) private errorMessages
  ) {}

  loadPayHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WagesActions.loadPayHistory),
      mergeMap(action => {
        return this.wagesService.getWeeklyPayments().pipe(
          map(payHistories => {
            return WagesActions.payHistoryLoaded({ payHistories });
          }),
          catchError(handleAppError(this.errorMessages))
        );
      })
    )
  );

  loadPaymentWeekDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WagesActions.loadPaymentWeekDetails),
      withLatestFrom(this.store.select(selectActiveWeekPayDetails)),
      filter(([{ weekEndingDate }, payDetails]) => !payDetails),
      mergeMap(([{ weekEndingDate }, payDetails]) => {
        this.store.dispatch(WagesActions.togglePaymentDetailsLoading());
        return forkJoin(
          this.wagesService.getWeekPaymentsBreakdown(weekEndingDate),
          this.goalService.getWeeklyPerformance(weekEndingDate)
        ).pipe(
          switchMap(([weekPay, performance]) => {
            return this.goalService
              .getWeeklyGoal(weekEndingDate, performance.associatePaySplit)
              .pipe(
                mergeMap(weeklyGoal => {
                  return of(
                    WagesActions.weekPaymentDetailsLoaded({
                      weekPay: {
                        ...weekPay,
                        lastUpdatedAtDate: moment().format(DateFormat)
                      }
                    }),
                    GoalActions.goalLoaded({
                      goal: weeklyGoal
                    }),
                    GoalActions.weeklyPerformanceLoaded({
                      performance: {
                        ...performance,
                        id: moment(weekPay.weekEndingDate).format(IdDateFormat)
                      }
                    })
                  );
                })
              );
          }),
          catchError(handleAppError(this.errorMessages))
        );
      })
    )
  );
}
