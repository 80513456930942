import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../shared/components/shared-components/shared-components.module';
import { NewsRoutingModule } from './news-routing.module';
import { NewsComponent } from './news.component';
import { NewsFeedContainerComponent } from './containers/news-feed/news-feed-container.component';
import { NewsFeedService } from './services/news-feed.service';
import { EffectsModule } from '@ngrx/effects';
import { NewsEffects } from './effects/news.effects';
import { PreviewFeedCardComponent } from './containers/preview-feed-card/preview-feed-card.component';
import { ShiftsModule } from './modules/shifts/shifts.module';

@NgModule({
  declarations: [
    NewsComponent,
    NewsFeedContainerComponent,
    PreviewFeedCardComponent
  ],
  imports: [
    SharedComponentsModule,
    NewsRoutingModule,
    ShiftsModule,
    EffectsModule.forFeature([NewsEffects])
  ],
  providers: [NewsFeedService],
  exports: [NewsComponent, NewsFeedContainerComponent],
  entryComponents: []
})
export class NewsModule {}
