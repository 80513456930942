import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonAppearance, ButtonColor } from '../../enums/ButtonLink';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnInit, OnDestroy {
  pageChangeSubject = new Subject<number>();
  pageIndexSubject = new BehaviorSubject(0);
  destroySubject = new Subject();
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;
  pageIndexView: number;

  @Input()
  get pageIndex() {
    return this.pageIndexSubject.value;
  }

  set pageIndex(value) {
    this.pageIndexView = value + 1;
    this.pageIndexSubject.next(value);
  }

  @Input() pageSize: number;
  @Input() totalCount: number;

  @Output() pageChange = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.pageChangeSubject
      .pipe(takeUntil(this.destroySubject))
      .subscribe(value => {
        this.pageIndexView = value + 1;
        this.pageChange.emit({
          pageIndex: value,
          pageSize: this.pageSize
        });
      });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
    this.destroySubject = null;
  }

  isFirstPage(): boolean {
    return this.pageIndex === 0;
  }

  isLastPage() {
    return this.getLastPage() === this.pageIndex;
  }

  changePage(value) {
    this.pageChangeSubject.next(this.pageIndex + value);
  }

  private getLastPage(): number {
    return Math.ceil(this.totalCount / this.pageSize) - 1;
  }
}
