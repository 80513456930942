import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import moment from 'moment';
import { IdDateFormat } from '../../../../shared/constants';
import { AppState, selectIsUserTrainee } from '../../../../reducers';
import { Store } from '@ngrx/store';
import { map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoalPreviewGuard implements CanActivate {
  constructor(private router: Router, private store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.store.select(selectIsUserTrainee).pipe(
      first(),
      map(isUserTrainee => {
        if (
          moment(route.params.id, IdDateFormat, true).isValid() &&
          !isUserTrainee
        ) {
          return true;
        }

        this.router.navigate(['wages']);
        return false;
      })
    );
  }
}
