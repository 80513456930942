import { Inject, Injectable } from '@angular/core';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../shared/error-handlers/catch-app-error';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ResourcesActions } from '../actions';
import { ResourcesService } from '../services/resources.service';
import { Associates } from '../../shared/enums/ApiResponseCodes';

@Injectable()
export class ResourcesEffects {
  constructor(
    private actions$: Actions,
    private resourcesService: ResourcesService,
    @Inject(APP_ERROR_MESSAGES) private errorMessages
  ) {}

  loadDirectory = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourcesActions.loadDirectory),
      switchMap(action => {
        return this.resourcesService.getDirectory().pipe(
          map(directory => {
            return ResourcesActions.directoryLoaded({ directory });
          }),
          catchError(err => {
            if (err.error.message === Associates.ASSOCIATE_NOT_FOUND) {
              return of(ResourcesActions.directoryLoaded({ directory: null }));
            }
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );
}
