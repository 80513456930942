<div class="goal-input" [formGroup]="formGroup">
  <div>
    <app-goal-slider-input
      [name]="'inputValue' + goalType"
      [formControlName]="goalType"
      [hasError]="formGroup.get(goalType).invalid"
      (focusEvent)="onGoalFocusEvent($event)"
      (blurEvent)="onGoalTypeValueBlur($event)"
      [validationStatus]="validation?.status"
      [prefix]="prefix"
      [suffix]="suffix"
      [analyticsId]="AnalyticsEventsEnum.GoalSliderInput"
      [analyticsLabel]="goalType"
    ></app-goal-slider-input>
  </div>
  <label
    *ngIf="hasSingleGoalType"
    [attr.for]="'inputValue' + goalType"
    [attr.id]="'sliderValue' + goalType"
    class="goal-input__label"
  >
    {{ goalTypes[0].display | translate }}
  </label>

  <div *ngIf="hasMultipleGoalTypes">
    <label
      [attr.id]="'sliderValue' + goalType"
      [attr.for]="'inputValue' + goalType"
      class="visually-hidden"
    >
      {{
        goalTypesByValue[formGroup.get('itemType').value]?.display | translate
      }}
    </label>
    <label
      [attr.for]="'goalType' + goalType"
      class="visually-hidden"
      i18n="@@changeTypeOfGoal"
    >
      Change type of goal
    </label>
    <select
      data-cy="paletsCasesSelect"
      [ngClass]="{
        'goal-input__select': true,
        'goal-input__select--disabled':
          (goalStoreService.isGoalValid$ | async) === false
      }"
      [attr.disabled]="
        (goalStoreService.isGoalValid$ | async) === false ? true : null
      "
      formControlName="itemType"
      [attr.id]="'goalType' + goalType"
    >
      <option *ngFor="let gt of goalTypes" value="{{ gt.value }}">
        {{ gt.display | translate }}
      </option>
    </select>
  </div>
  <app-goal-slider
    data-cy="revenueSlider"
    class="goal-slider"
    [ngModel]="this.formGroup.get(goalType).value"
    (ngModelChange)="updateFormValue($event)"
    [ngModelOptions]="{ standalone: true }"
    [floor]="min"
    [ceil]="max"
    [step]="step"
    [maxLimit]="maxLimit"
    [siteAverage]="siteAverage"
    [associateAverage]="associateAverage"
    [ariaLabelId]="
      'sliderValue' + goalType + ' ' + 'sliderDescription' + goalType
    "
    [validationStatus]="validation?.status"
    [averageFormat]="averageFormat"
  ></app-goal-slider>
  <span
    class="goal-input__note"
    [id]="'sliderDescription' + goalType"
    *ngIf="validation"
    [ngClass]="{
      'goal-input__note--error':
        validation.status === GoalValidationStatusEnum.Error,
      'goal-input__note--valid':
        validation.status === GoalValidationStatusEnum.Valid,
      'goal-input__note--warning':
        validation.status === GoalValidationStatusEnum.Warning
    }"
  >
    <ng-container [ngSwitch]="validation.status">
      <ng-container *ngSwitchCase="GoalValidationStatusEnum.Valid">
        <ng-container
          *ngIf="validation.equalsSource"
          i18n="@@goal-input-card_goalMatches"
        >
          Your goal matches {validation.source, select, associateAverage {your
          personal average} siteAverage {site average} minValue {minimum value}
          other {}}.
        </ng-container>
        <ng-container
          *ngIf="!validation.equalsSource"
          i18n="@@goal-input-card_goalInGoodRange"
        >
          Your goal is {{ validation.value | number: averageFormat }}
          higher than {validation.source, select, associateAverage {your
          personal} siteAverage {site} other {}} average.
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="GoalValidationStatusEnum.Warning">
        <ng-container
          *ngIf="
            checkIfHasFlag(validation.range, GoalValidationRangeEnum.Above)
          "
          i18n="@@goal-input-card_goalAboveRangeWarning"
        >
          Your goal is {{ validation.value | number: averageFormat }}
          higher than {validation.source, select, associateAverage {your
          personal average} siteAverage {site average} minValue {minimum value}
          other {}}.
        </ng-container>
        <ng-container
          *ngIf="
            checkIfHasFlag(validation.range, GoalValidationRangeEnum.Below)
          "
          i18n="@@goal-input-card_goalBelowRangeWarning"
        >
          Your goal is {{ validation.value | number: averageFormat }}
          lower than {validation.source, select, associateAverage {your
          personal} siteAverage {site} other {}} average.
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="GoalValidationStatusEnum.Error">
        <ng-container
          *ngIf="
            checkIfHasFlag(validation.range, GoalValidationRangeEnum.Above)
          "
          i18n="@@goal-input-card_goalAboveRangeError"
        >
          Your goal is too high.
        </ng-container>
      </ng-container>
    </ng-container>
  </span>
  <app-info-button
    class="goal-input__info"
    [text]="infoBoxText"
  ></app-info-button>
</div>
