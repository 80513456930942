import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import isEmpty from 'lodash/isEmpty';
import { FormFieldControl } from '../form-field/form-field.control';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {
  @Input() errors: any;
  @Input() for: FormFieldControl;
  @Input() shouldShowError: () => boolean;

  constructor() {}

  ngOnInit() {}

  isErrorState(
    control: AbstractControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return !isEmpty(this.errors);
  }
}
