import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslationMessages } from '../enums/TranslationMessages';
import { Locales } from '../../core/enums/Locales';

export class TranslationSet {
  public language: string;
  public values: { [key: string]: string } = {};
}

@Injectable()
export class TranslationService {
  private dictionary: { [key: string]: TranslationSet } = {
    en: {
      language: 'en',
      values: {
        [TranslationMessages.DailyEarningsEmptyStateMessage]:
          'No work history has been recorded this day.',
        [TranslationMessages.NoInternetConnectionMessage]:
          'No Internet Connection',
        [TranslationMessages.PalletsPerHour]: 'Pallets Per Hour',
        [TranslationMessages.CasesPerHour]: 'Cases Per Hour',
        [TranslationMessages.WorkHours]: 'Work Hours',
        [TranslationMessages.RevenuePerPallet]: 'Net Revenue Per Pallet',
        [TranslationMessages.RevenuePerCase]: 'Net Revenue Per Case',
        [TranslationMessages.PalletsPerHourInfoBoxText]:
          'The number of pallets you will handle in an hour.',
        [TranslationMessages.CasesPerHourInfoBoxText]:
          'The number of cases you will handle in an hour.',
        [TranslationMessages.WorkHoursInfoBoxText]:
          'The number of hours worked in a week',
        [TranslationMessages.RevenuePerPalletInfoBoxText]:
          'The amount of revenue, after applicable rebates and processing fees, generated per finished pallet.',
        [TranslationMessages.RevenuePerCaseInfoBoxText]:
          'The amount of revenue, after applicable rebates and processing fees, generated per finished case.',
        [TranslationMessages.CurrentGoalInfoBoxText]:
          'Associate Pay % is based on your last 4-week average.',
        [TranslationMessages.NewsTitle]: 'Newsfeed',
        [TranslationMessages.WagesHistoryTitle]: 'Earnings history',
        [TranslationMessages.WagesPayTitle]: 'Daily earnings breakdown',
        [TranslationMessages.GoalBreakdownTitle]: 'Current progress',
        [TranslationMessages.GoalTitle]: 'Edit weekly goal',
        [TranslationMessages.ResourcesTitle]: 'Resources',
        [TranslationMessages.ResourcesFaqTitle]: 'COVID-19 Info & FAQ',
        [TranslationMessages.ResourcesStarsTitle]: 'S.C.R.I.P.T. Stars',
        [TranslationMessages.ResourcesTeamDirectoryTitle]: 'Team directory',
        [TranslationMessages.ShiftsTitle]: 'Shifts',
        [TranslationMessages.ShiftCreateTitle]: 'Fill a shift',
        [TranslationMessages.ShiftEditTitle]: 'Edit shift',
        [TranslationMessages.ShiftPreviewTitle]: 'Preview shift details',
        [TranslationMessages.ShiftDetailsTitle]: 'Shift details',
        [TranslationMessages.MyTeamTitle]: 'My Team',
        [TranslationMessages.DisclaimerTitle]: 'Terms and conditions',
        [TranslationMessages.AddPwaTitle]:
          'Add the MyCapstone app to home screen',
        [TranslationMessages.AssociateCheckInHistoryTitle]:
          'Associate check-in history',
        [TranslationMessages.AssociateProductionStatsTitle]:
          'Associate production stats',
        [TranslationMessages.SettingsTitle]: 'Settings',
        [TranslationMessages.WeeklyCheckInTitle]: 'Weekly check-in',
        [TranslationMessages.ProductionStatsTitle]: 'Production stats',
        [TranslationMessages.CheckInHistoryTitle]: 'Check-in history',
        [TranslationMessages.CovidTitle]: 'Covid 19',
        [TranslationMessages.RoadCrewTitle]: 'Work Opportunities',
        [TranslationMessages.ErrorMessagesAssociateNotFound]:
          'User Earnings Data Not Found.',
        [TranslationMessages.ErrorMessagesWeekEndingDateNotSaturday]:
          'Week ending date not Saturday.',
        [TranslationMessages.ErrorMessagesEarningsNotAllowedForSite]:
          'Sorry, but under current business rules, California associates can not see their earnings in MyCapstone.',
        [TranslationMessages.ErrorMessagesSurveyAlreadySubmitted]:
          'Survey already submitted for associate.',
        [TranslationMessages.ErrorMessagesSurveyNotFound]: 'Survey not found.',
        [TranslationMessages.ErrorMessagesUnknownError]:
          'An error has occurred. Please try again later.',
        [TranslationMessages.TextMe]: 'Text me',
        [TranslationMessages.CallMe]: 'Call me',
        [TranslationMessages.CallOrTextMe]: 'Text me or call me',
        [TranslationMessages.Associate]: 'Associate',
        [TranslationMessages.Associates]: 'Associates',
        [TranslationMessages.SessionExpired]:
          'Your session has expired due to inactivity.',
        [TranslationMessages.LoggedOut]: 'You have been logged out.',
        [TranslationMessages.ShiftRequestRemoved]: 'Shift Request Removed',
        [TranslationMessages.ShiftRequestPosted]: 'Shift Request Posted!',
        [TranslationMessages.ShiftRequestNotifiedBody]:
          'All qualified associates will be notified.',
        [TranslationMessages.ShiftRequestUpdated]: 'Shift Request updated!',
        [TranslationMessages.SurveySubmitted]:
          'Survey is successfully submitted',
        [TranslationMessages.SurveyThankYou]:
          'Thank you for completing the survey',
        [TranslationMessages.GoalSubmitted]: 'Goal is successfully submitted',
        [TranslationMessages.GoalKeepUp]: 'Keep up the hard work!',
        [TranslationMessages.ErrorUnknown]: 'Unknown error',
        [TranslationMessages.SettingsSaved]: 'Your settings was updated!',
        [TranslationMessages.MyTeamSortByTenure]: 'Tenure',
        [TranslationMessages.MyTeamSortByHoursWorked]: 'Hours Worked',
        [TranslationMessages.MyTeamSortByAssociateName]: 'Associate Name',
        [TranslationMessages.MyTeamSortByCheckInScore]: 'Most Recent Check-In',
        [TranslationMessages.WeeklyCheckInSubmitted]:
          'Weekly Check-In Submitted!',
        [TranslationMessages.ThanksForTheQuckUpdate]:
          'Thanks for the quick update.',

        [TranslationMessages.AssociateStatsLevelTitleTop10]: 'Top 10%',
        [TranslationMessages.AssociateStatsLevelTitleTop20]: 'Top 20%',
        [TranslationMessages.AssociateStatsLevelTitleTop30]: 'Top 30%',
        [TranslationMessages.AssociateStatsLevelTitleTop50]: 'Top 50%',
        [TranslationMessages.AssociateStatsLevelTitleBottom50]: 'Bottom 50%',
        [TranslationMessages.AssociateStatsLevelTitleBottom30]: 'Bottom 30%',
        [TranslationMessages.AssociateStatsLevelTitleBottom20]: 'Bottom 20%',
        [TranslationMessages.AssociateStatsLevelTitleBottom10]: 'Bottom 10%',
        [TranslationMessages.Male]: 'Male',
        [TranslationMessages.Female]: 'Female',
        [TranslationMessages.PositiveDriverAvailability]:
          'I am open to driving for roadcrew',
        [TranslationMessages.NegativeDriverAvailability]:
          'I only want to be a passenger',
        [TranslationMessages.IsDriverHasInsuranceYes]: 'Yes',
        [TranslationMessages.IsDriverHasInsuranceNo]: 'No',
        [TranslationMessages.StateID]: 'State ID',
        [TranslationMessages.Passport]: 'Passport',
        [TranslationMessages.DriversLicense]: 'Driver License',
        [TranslationMessages.Resident]: 'Permanent residence card',
        [TranslationMessages.MilitaryID]: 'Military ID',

        /* certificates section [English] */
        [TranslationMessages.AssociateCertificationTeamLead]: 'Team Lead',
        [TranslationMessages.AssociateCertificationSelectorVoice]:
          'Selector-voice pick',
        [TranslationMessages.AssociateCertificationSelectorRF]:
          'Selector-RF scan',
        [TranslationMessages.AssociateCertificationSingleEPJ]: 'Singe EPJ',
        [TranslationMessages.AssociateCertificationDoubleEPJ]: 'Double EPJ',
        [TranslationMessages.AssociateCertificationTripleEPJ]: 'Triple EPJ',
        [TranslationMessages.AssociateCertificationReachTruckOperator]:
          'Reach Truck Operator',
        [TranslationMessages.AssociateCertificationCherryPicker]:
          'Cherry/Order Picker',
        [TranslationMessages.AssociateCertificationDockStocker]:
          'Dock Stocker/Stand Up Forklift',
        [TranslationMessages.AssociateCertificationSitDownForklift]:
          'Sit Down Forklift',
        [TranslationMessages.AssociateCertificationClampMachine]:
          'Clamp Machine',
        [TranslationMessages.AssociateCertificationSlipMachine]: 'Slip Machine',
        [TranslationMessages.AssociateCertificationYardJockey]: 'Yard Jockey',
        [TranslationMessages.AssociateCertificationNoneNeeded]: 'None Needed',
        /* end of certifiates [English] */

        [TranslationMessages.DocumentDisclaimerText]:
          'Make sure your name, photo, and expiration date are clearly visible.',
        [TranslationMessages.FrontOfDriverLicence]: 'Front Of Driver License',
        [TranslationMessages.DriverInsuranceCard]: 'Driver Insurance Card',
        [TranslationMessages.PhotoOfId]: 'Photo Of ID',
        [TranslationMessages.CameraSkipDriverInsuranceCard]: 'I am not insured',

        [TranslationMessages.AsstTeamLeader]: 'Asst Team Leader',
        [TranslationMessages.Auditor]: 'Auditor',
        [TranslationMessages.BackupTeamLead]: 'Backup Team Lead',
        [TranslationMessages.Baler]: 'Baler',
        [TranslationMessages.Clerk]: 'Clerk',
        [TranslationMessages.ComplianceProgramAdministrator]:
          'Compliance Program Administrator',
        [TranslationMessages.CustomerServiceSupport]:
          'Customer Service Support',
        [TranslationMessages.DriverAssistant]: 'Driver Assistant',
        [TranslationMessages.FieldRecruiter]: 'Field Recruiter',
        [TranslationMessages.ForkliftOperator]: 'Forklift Operator',
        [TranslationMessages.FreightHandler]: 'Freight Handler',
        [TranslationMessages.FreightHandlerRoadCrew]:
          'Freight Handler Road Crew',
        [TranslationMessages.FreightHauler]: 'Freight Hauler',
        [TranslationMessages.Fueler]: 'Fueler',
        [TranslationMessages.Lead]: 'Lead',
        [TranslationMessages.LeadAudit]: 'Lead - Audit',
        [TranslationMessages.LeadI]: 'Lead I',
        [TranslationMessages.LeadIII]: 'Lead III',
        [TranslationMessages.LiftOperator]: 'Lift Operator',
        [TranslationMessages.Loader]: 'Loader',
        [TranslationMessages.Maintenance]: 'Maintenance',
        [TranslationMessages.MaterialHandler]: 'Material Handler',
        [TranslationMessages.OfficeOrClerical]: 'Office/Clerical',
        [TranslationMessages.Painter]: 'Painter',
        [TranslationMessages.PalletHandler]: 'Pallet Handler',
        [TranslationMessages.Palletizer]: 'Palletizer',
        [TranslationMessages.ProductionManager]: 'Production Manager',
        [TranslationMessages.ProductionSupervisor]: 'Production Supervisor',
        [TranslationMessages.Receiver]: 'Receiver',
        [TranslationMessages.ReclaimsSpecialist]: 'Reclaims Specialist',
        [TranslationMessages.Recruiter]: 'Recruiter',
        [TranslationMessages.ReturnsProcessor]: 'Returns Processor',
        [TranslationMessages.Sanitation]: 'Sanitation',
        [TranslationMessages.SchedulingCoordinator]: 'Scheduling Coordinator',
        [TranslationMessages.SeasonalFreightHandler]:
          'Seasonal Freight Handler',
        [TranslationMessages.SecurityOrGuardShack]: 'Security or Guard Shack',
        [TranslationMessages.Selector]: 'Selector',
        [TranslationMessages.SelectorRoadCrew]: 'Selector Road Crew',
        [TranslationMessages.SeniorLeadman]: 'Senior Leadman',
        [TranslationMessages.SiteOpsAdminAsst]: 'Site Ops Admin Asst',
        [TranslationMessages.Spotter]: 'Spotter',
        [TranslationMessages.Switcher]: 'Switcher',
        [TranslationMessages.SwitcherNonCDL]: 'Switcher Non CDL',
        [TranslationMessages.TrailerStripper]: 'Trailer Stripper',
        [TranslationMessages.TravelTeamSelector]: 'Travel Team Selector',
        [TranslationMessages.WarehouseAssociate]: 'Warehouse Associate',
        [TranslationMessages.OpportunityDetailsPageTitle]:
          'Opportunity Details',
        [TranslationMessages.OpportunitiesListPageTitle]: 'Opportunities List',
        [TranslationMessages.RoadCrewApplicationTitle]:
          'Road Crew application form',
        [TranslationMessages.RoadCrewRegistrationComplete]:
          'Road Crew registration complete',
        [TranslationMessages.RegionMidwest]: 'Midwest',
        [TranslationMessages.RegionNorthEast]: 'Northeast',
        [TranslationMessages.RegionSouth]: 'South',
        [TranslationMessages.RegionWest]: 'West',
        [TranslationMessages.OpportunityListSortStartDate]: 'Start Date',
        [TranslationMessages.OpportunityListSortDuration]: 'Duration',
        [TranslationMessages.OpportunityListSortNewest]: 'Recently Posted',
        [TranslationMessages.OpportunityListSortLocation]: 'Location',
        [TranslationMessages.OpportunityNotFound]: 'Opportunity not found',
        [TranslationMessages.OpportunityStartDatePassed]:
          'Opportunity start date already passed',
        [TranslationMessages.OpportunityExpireDatePassed]:
          'Opportunity expire date already passed',
        [TranslationMessages.OpportunityAlreadyRequested]:
          'Opportunity already requested',
        [TranslationMessages.RoadCrewApplicationNotSubmitted]:
          'Road Crew Application is not submitted',
        [TranslationMessages.ErrorMessagesFileUploadFailed]:
          'There was an error while loading file.',
        [TranslationMessages.OpportunityAppliedSuccessfully]:
          'Successfully applied for opportunity.',
        [TranslationMessages.OpportunityApplicationCancelled]:
          'Opportunity application cancelled.',
        /* Can't apply to work opportunity messages */
        [TranslationMessages.RoadCrewCantApplyOne]:
          'You are currently deployed (or pending deployment) on another project.',
        [TranslationMessages.RoadCrewCantApplyTwo]:
          'You do not have any matching certificates.',
        [TranslationMessages.RoadCrewCantApplyThree]:
          'You have applied to another opportunity.',
        [TranslationMessages.RoadCrewCantApplyFour]:
          'This work opportunity has ended.',
        /* End of can't apply messages */
        /* RoadCrew section missing messages */
        [TranslationMessages.RoadCrewCantRegisterOne]:
          'Your home site does not have the Road Crew feature enabled.',
        [TranslationMessages.RoadCrewCantRegisterTwo]:
          'You are marked as "Do Not Deploy" in Apex Services Entry.',
        [TranslationMessages.RoadCrewCantRegisterThree]:
          'Your access role is not allowed to register for Road Crew.'
        /* End of missing road crew section messages */
      }
    },
    es: {
      language: 'es',
      values: {
        [TranslationMessages.DailyEarningsEmptyStateMessage]:
          'No se ha registrado ningún historial laboral este día.',
        [TranslationMessages.NoInternetConnectionMessage]:
          'Sin conexión a Internet',
        [TranslationMessages.PalletsPerHour]: 'Palets por hora',
        [TranslationMessages.CasesPerHour]: 'Casos por hora',
        [TranslationMessages.WorkHours]: 'Horas laborales',
        [TranslationMessages.RevenuePerPallet]: 'Ingresos netos por palet',
        [TranslationMessages.RevenuePerCase]: 'Ingresos netos por caso',
        [TranslationMessages.PalletsPerHourInfoBoxText]:
          'El número de paletas que manejaras cada hora.',
        [TranslationMessages.CasesPerHourInfoBoxText]:
          'El número de cajas que manejaras cada hora.',
        [TranslationMessages.WorkHoursInfoBoxText]:
          'El número de horas trabajadas en una semana',
        [TranslationMessages.RevenuePerPalletInfoBoxText]:
          'La cantidad de ingresos, después de el reembolso y tarifas de proceso, generados por pallet finalizada.',
        [TranslationMessages.RevenuePerCaseInfoBoxText]:
          'La cantidad de ingresos, después de los rebates/reembolso y tarifas de proceso, generados por caja finalizada.',
        [TranslationMessages.CurrentGoalInfoBoxText]:
          'Porcentaje del pago del asociado es basado en el promedio de las últimas 4 semanas.',
        [TranslationMessages.NewsTitle]: 'Noticias',
        [TranslationMessages.WagesHistoryTitle]: 'Historial de ganancias',
        [TranslationMessages.WagesPayTitle]: 'Detalles de ganancias diarias',
        [TranslationMessages.GoalBreakdownTitle]: 'Progreso actual',
        [TranslationMessages.GoalTitle]: 'Editar objetivo semanal',
        [TranslationMessages.ResourcesTitle]: 'Recursos',
        [TranslationMessages.ResourcesFaqTitle]:
          'Información De COVID-19 Y Preguntas Frecuentes',
        [TranslationMessages.ResourcesStarsTitle]: 'Estrella S.C.R.I.P.T',
        [TranslationMessages.ResourcesTeamDirectoryTitle]:
          'Directorio de Equipo',
        [TranslationMessages.ShiftsTitle]: 'Turno',
        [TranslationMessages.ShiftCreateTitle]: 'Llenar el turno',
        [TranslationMessages.ShiftEditTitle]: 'Editar el turno',
        [TranslationMessages.ShiftPreviewTitle]:
          'Vista Previa - Detalles de Turno',
        [TranslationMessages.ShiftDetailsTitle]: 'Detalles de Turno',
        [TranslationMessages.MyTeamTitle]: 'My Team',
        [TranslationMessages.DisclaimerTitle]: 'Terms and conditions',
        [TranslationMessages.AddPwaTitle]:
          'Add the MyCapstone app to home screen',
        [TranslationMessages.AssociateCheckInHistoryTitle]:
          'Associate check-in history',
        [TranslationMessages.AssociateProductionStatsTitle]:
          'Associate production stats',
        [TranslationMessages.SettingsTitle]: 'Settings',
        [TranslationMessages.WeeklyCheckInTitle]: 'Weekly check-in',
        [TranslationMessages.ProductionStatsTitle]: 'Production stats',
        [TranslationMessages.CheckInHistoryTitle]: 'Check-in history',
        [TranslationMessages.CovidTitle]: 'Covid 19',
        [TranslationMessages.RoadCrewTitle]: 'Oportunidades de Trabajo',
        [TranslationMessages.ErrorMessagesAssociateNotFound]:
          'Datos de ingresos de usuario no fueron encontrados.',
        [TranslationMessages.ErrorMessagesWeekEndingDateNotSaturday]:
          'Ultimo día de la semana no es sábado.',
        [TranslationMessages.ErrorMessagesEarningsNotAllowedForSite]:
          'Lo sentimos, pero según las reglas comerciales actuales, los asociados de California no pueden ver sus ganancias en MyCapstone.',
        [TranslationMessages.ErrorMessagesSurveyAlreadySubmitted]:
          'Encuesta ya fue entregada por el asociado.',
        [TranslationMessages.ErrorMessagesSurveyNotFound]:
          'Encuesta no fue encontrada.',
        [TranslationMessages.ErrorMessagesUnknownError]:
          'Se ha producido un error. Por favor inténtelo de nuevo más tarde.',
        [TranslationMessages.TextMe]: 'Envíame un mensaje de texto',
        [TranslationMessages.CallMe]: 'Llama me',
        [TranslationMessages.CallOrTextMe]:
          'Enviame un mensaje de texto o llama me',
        [TranslationMessages.Associate]: 'Asociado',
        [TranslationMessages.Associates]: 'Asociado',
        [TranslationMessages.SessionExpired]:
          'Su sesión has sido terminada por inactividad.',
        [TranslationMessages.LoggedOut]: 'Usted ha sido desconectado.',
        [TranslationMessages.ShiftRequestRemoved]:
          'Solicitud de turno has sido removida.',
        [TranslationMessages.ShiftRequestPosted]:
          '¡Solicitud de turno ha sido publicada!',
        [TranslationMessages.ShiftRequestNotifiedBody]:
          'Todos los asociados cualificados serán notificados.',
        [TranslationMessages.ShiftRequestUpdated]:
          '¡Solicitud de turno has sido actualizada!',
        [TranslationMessages.SurveySubmitted]:
          'Encuesta ha sido enviada exitosamente',
        [TranslationMessages.SurveyThankYou]:
          'Gracias por completar la encuesta',
        [TranslationMessages.GoalSubmitted]:
          'Objetivo ha sido enviado exitosamente',
        [TranslationMessages.GoalKeepUp]: '¡Mantenga su gran trabajo!',
        [TranslationMessages.ErrorUnknown]: 'Error desconocido',
        [TranslationMessages.SettingsSaved]:
          'Tus configuraciones han sido actualizadas!',
        [TranslationMessages.MyTeamSortByTenure]: 'Antigüedad de trabajo',
        [TranslationMessages.MyTeamSortByHoursWorked]: 'Horas trabajadas',
        [TranslationMessages.MyTeamSortByAssociateName]: 'Nombre de Asociado',
        [TranslationMessages.MyTeamSortByCheckInScore]: 'Most Recent Check-In',
        [TranslationMessages.WeeklyCheckInSubmitted]:
          'Check-in Semanal enviado!',
        [TranslationMessages.ThanksForTheQuckUpdate]:
          'Gracias por la rápida actualización.',
        [TranslationMessages.AssociateStatsLevelTitleTop10]: 'Mejor 10%',
        [TranslationMessages.AssociateStatsLevelTitleTop20]: 'Mejor 20%',
        [TranslationMessages.AssociateStatsLevelTitleTop30]: 'Mejor 30%',
        [TranslationMessages.AssociateStatsLevelTitleTop50]: 'Mejor 50%',
        [TranslationMessages.AssociateStatsLevelTitleBottom50]: 'Ultimos 50%',
        [TranslationMessages.AssociateStatsLevelTitleBottom30]: 'Ultimos 30%',
        [TranslationMessages.AssociateStatsLevelTitleBottom20]: 'Ultimos 20%',
        [TranslationMessages.AssociateStatsLevelTitleBottom10]: 'Ultimos 10%',

        [TranslationMessages.Male]: 'Male',
        [TranslationMessages.Female]: 'Female',
        [TranslationMessages.WarehouseAssociate]: 'Warehouse Associate',
        [TranslationMessages.PositiveDriverAvailability]:
          'Estoy abierto a conducir para Road Crew',
        [TranslationMessages.NegativeDriverAvailability]:
          'Solo quiero ser pasajero',
        [TranslationMessages.IsDriverHasInsuranceYes]: 'Sí',
        [TranslationMessages.IsDriverHasInsuranceNo]: 'No',
        [TranslationMessages.StateID]: 'Id del Estado',
        [TranslationMessages.Passport]: 'Pasaporte',
        [TranslationMessages.DriversLicense]: 'Licencia de Conducir',
        [TranslationMessages.Resident]: 'Tarjeta de residencia permanente',
        [TranslationMessages.MilitaryID]: 'ID Militar',
        /*certifications [Spanish]*/
        [TranslationMessages.AssociateCertificationTeamLead]:
          'Capitan del equipo',
        [TranslationMessages.AssociateCertificationSelectorVoice]:
          'Selección de voz de selector',
        [TranslationMessages.AssociateCertificationSelectorRF]: 'Selección RF',
        [TranslationMessages.AssociateCertificationSingleEPJ]: 'EPJ Soltero',
        [TranslationMessages.AssociateCertificationDoubleEPJ]: 'EPJ Doble',
        [TranslationMessages.AssociateCertificationTripleEPJ]: 'EPJ Triple',
        [TranslationMessages.AssociateCertificationReachTruckOperator]:
          'Operador de carretilla retráctil',
        [TranslationMessages.AssociateCertificationCherryPicker]:
          'Cosechador de Cerezas',
        [TranslationMessages.AssociateCertificationDockStocker]:
          'Stocker de muelle/Carretilla elevadora de pie',
        [TranslationMessages.AssociateCertificationSitDownForklift]:
          'Siéntese montacargas',
        [TranslationMessages.AssociateCertificationClampMachine]:
          'Máquina de sujeción',
        [TranslationMessages.AssociateCertificationSlipMachine]:
          'Máquina de deslizamiento',
        [TranslationMessages.AssociateCertificationYardJockey]:
          'Jockey de patio',
        [TranslationMessages.AssociateCertificationNoneNeeded]:
          'Ninguno necesario',
        /*end of certifications [Spanish]*/
        [TranslationMessages.DocumentDisclaimerText]:
          'Asegúrate de que tu nombre, foto, y fecha de expiración estén claramente visibles',
        [TranslationMessages.FrontOfDriverLicence]:
          'Frente de la Licencia de Conducir',
        [TranslationMessages.DriverInsuranceCard]:
          'Tarjeta de seguro de conductor',
        [TranslationMessages.PhotoOfId]: 'Foto del ID',
        [TranslationMessages.CameraSkipDriverInsuranceCard]:
          'No estoy asegurado',
        [TranslationMessages.AsstTeamLeader]: 'Asistente de Líder de Equipo',
        [TranslationMessages.Auditor]: 'Auditor',
        [TranslationMessages.BackupTeamLead]: 'Respaldo de Líder de Equipo',
        [TranslationMessages.Baler]: 'Embaladora',
        [TranslationMessages.Clerk]: 'Empleado Clericó',
        [TranslationMessages.ComplianceProgramAdministrator]:
          'Administrador de Programa de Cumplimiento',
        [TranslationMessages.CustomerServiceSupport]:
          'Servicio de Atención al Cliente',
        [TranslationMessages.DriverAssistant]: 'Asistente de Conductor',
        [TranslationMessages.FieldRecruiter]: 'Recluto de Sector',
        [TranslationMessages.ForkliftOperator]: 'Operador de Montacargas',
        [TranslationMessages.FreightHandler]: 'Manipulador de Cargas',
        [TranslationMessages.FreightHandlerRoadCrew]:
          'Manipulador de Road Crew',
        [TranslationMessages.FreightHauler]: 'Manipulador de Transportación',
        [TranslationMessages.Fueler]: 'Combustible',
        [TranslationMessages.Lead]: 'Líder',
        [TranslationMessages.LeadAudit]: 'Líder de Auditoria',
        [TranslationMessages.LeadI]: 'Líder 1',
        [TranslationMessages.LeadIII]: 'Líder 3',
        [TranslationMessages.LiftOperator]: 'Operador de grúa',
        [TranslationMessages.Loader]: 'Cargador',
        [TranslationMessages.Maintenance]: 'Mantenimiento',
        [TranslationMessages.MaterialHandler]: 'Manipulador de Materiales',
        [TranslationMessages.OfficeOrClerical]: 'Oficina/Clericó',
        [TranslationMessages.Painter]: 'Pintor',
        [TranslationMessages.PalletHandler]: 'Manipulador de Paletas',
        [TranslationMessages.Palletizer]: 'Paletizador',
        [TranslationMessages.ProductionManager]: 'Manager de Producción',
        [TranslationMessages.ProductionSupervisor]:
          'Supervisor(a) de produccion',
        [TranslationMessages.Receiver]: 'Receptor',
        [TranslationMessages.ReclaimsSpecialist]: 'Especialista de Reclamos',
        [TranslationMessages.Recruiter]: 'Reclutador/Reclutadora',
        [TranslationMessages.ReturnsProcessor]: 'Procesador de devoluciones',
        [TranslationMessages.Sanitation]: 'Saneamiento',
        [TranslationMessages.SchedulingCoordinator]: 'Coordinador de Horarios',
        [TranslationMessages.SeasonalFreightHandler]:
          'Manipulador de Cargas de Temporada',
        [TranslationMessages.SecurityOrGuardShack]:
          'Seguridad o Guardia de Cabaña',
        [TranslationMessages.Selector]: 'Selector',
        [TranslationMessages.SelectorRoadCrew]: 'Selector de Road Crew',
        [TranslationMessages.SeniorLeadman]: 'Líder Superior',
        [TranslationMessages.SiteOpsAdminAsst]:
          'Asistente de Administración de Operación Local',
        [TranslationMessages.Spotter]: 'Observador',
        [TranslationMessages.Switcher]: 'Conmutador',
        [TranslationMessages.SwitcherNonCDL]: 'Conmutador sin CDL',
        [TranslationMessages.TrailerStripper]: 'Removedor de Tráiler/Remolque',
        [TranslationMessages.TravelTeamSelector]:
          'Seleccionador de Equipo de Viaje',
        [TranslationMessages.WarehouseAssociate]: 'Asociado de Bodega',
        [TranslationMessages.OpportunityDetailsPageTitle]:
          'Detalles de Oportunidad',
        [TranslationMessages.OpportunitiesListPageTitle]:
          'Lista de Oportunidades',
        [TranslationMessages.RoadCrewApplicationTitle]:
          'Forma de Aplicación de Road Crew',
        [TranslationMessages.RoadCrewRegistrationComplete]:
          'Registración de Road Crew Completa',

        [TranslationMessages.RegionMidwest]: 'Medio Oeste',
        [TranslationMessages.RegionNorthEast]: 'Norte Este',
        [TranslationMessages.RegionSouth]: 'Sur',
        [TranslationMessages.RegionWest]: 'Oeste',
        [TranslationMessages.OpportunityListSortStartDate]: 'Fecha de Inicio',
        [TranslationMessages.OpportunityListSortDuration]: 'Duración',
        [TranslationMessages.OpportunityListSortNewest]:
          'Publicado Recientemente',
        [TranslationMessages.OpportunityListSortLocation]: 'Ubicación',
        [TranslationMessages.OpportunityNotFound]: 'Oportunidad no encontrada',
        [TranslationMessages.OpportunityStartDatePassed]:
          'Fecha de Inicio de la Oportunidad ya paso',
        [TranslationMessages.OpportunityExpireDatePassed]:
          'Fecha de Expiración de la Oportunidad ya paso',
        [TranslationMessages.OpportunityAlreadyRequested]:
          'Oportunidad ya solicitada',
        [TranslationMessages.RoadCrewApplicationNotSubmitted]:
          'La aplicación para el Road Crew no ha sido entregada',
        [TranslationMessages.ErrorMessagesFileUploadFailed]:
          'There was an error while loading file.',
        [TranslationMessages.OpportunityAppliedSuccessfully]:
          'Successfully applied for opportunity.',
        [TranslationMessages.OpportunityApplicationCancelled]:
          'Opportunity application cancelled.',
        /* Can't apply to work opportunity messages */
        [TranslationMessages.RoadCrewCantApplyOne]:
          'Actualmente está implementado (o pendiente de implementación) en otro proyecto.',
        [TranslationMessages.RoadCrewCantApplyTwo]:
          'No tienes ningún certificado que coincida.',
        [TranslationMessages.RoadCrewCantApplyThree]:
          'Ha solicitado otra oportunidad.',
        [TranslationMessages.RoadCrewCantApplyFour]:
          'Esta oportunidad de trabajo ha terminado.',
        /* End of can't apply messages */
        /* RoadCrew section missing messages */
        [TranslationMessages.RoadCrewCantRegisterOne]:
          'Su sitio de inicio no tiene habilitada la función Road Crew.',
        [TranslationMessages.RoadCrewCantRegisterTwo]:
          'Está marcado como "No implementar" en la Apex Services Entry.',
        [TranslationMessages.RoadCrewCantRegisterThree]:
          'Su rol de acceso no puede registrarse en Road Crew.'
        /* End of missing road crew section messages */
      }
    }
  };

  constructor(@Inject(LOCALE_ID) protected localeId: string) {}

  translate(value: string): string {
    if (this.dictionary[this.localeId] != null) {
      return this.dictionary[this.localeId].values[value];
    }
  }

  getLanguage() {
    const lang = localStorage.getItem('language');
    return lang ? lang : Locales.en;
  }

  setLanguage(locale: string) {
    localStorage.setItem('language', locale);
  }
}
