/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../../../../../shared/components/svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./upload-preview.component";
var styles_UploadPreviewComponent = [i0.styles];
var RenderType_UploadPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadPreviewComponent, data: {} });
export { RenderType_UploadPreviewComponent as RenderType_UploadPreviewComponent };
function View_UploadPreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-svg-icon", [["class", "upload-preview__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "upload-preview__error"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "upload-preview__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "upload-preview__subtext"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.error; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.text; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.subtext; _ck(_v, 8, 0, currVal_3); }); }
function View_UploadPreviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "preview"], ["class", "upload-preview__image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 0, 0, currVal_0); }); }
function View_UploadPreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadPreviewComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "upload-preview__edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-svg-icon", [["class", "upload-preview__edit-icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(5, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.SvgIconNameEnum.Pencil; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_UploadPreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "upload-preview": 0, "upload-preview--blue": 1, "upload-preview--red": 2, "upload-preview--white": 3, "is-focused": 4 }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["class", "upload-preview__box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadPreviewComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadPreviewComponent_2)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, ((_co.UploadPreviewAppearanceEnum.Blue === _co.appearance) && !_co.image), ((_co.UploadPreviewAppearanceEnum.Red === _co.appearance) && !_co.image), ((_co.UploadPreviewAppearanceEnum.White === _co.appearance) && _co.image), _co.focused); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.image; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.image; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_UploadPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-preview", [], null, null, null, View_UploadPreviewComponent_0, RenderType_UploadPreviewComponent)), i1.ɵdid(1, 114688, null, 0, i6.UploadPreviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadPreviewComponentNgFactory = i1.ɵccf("app-upload-preview", i6.UploadPreviewComponent, View_UploadPreviewComponent_Host_0, { appearance: "appearance", iconName: "iconName", text: "text", subtext: "subtext", error: "error", image: "image", focused: "focused" }, { buttonClick: "buttonClick" }, []);
export { UploadPreviewComponentNgFactory as UploadPreviewComponentNgFactory };
