import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { GoalDetails } from '../../../../core/dataEntities/goal/goalDetails';
import { ItemType } from '../enums/ItemType';
import { ApiGatewayService } from '../../../../shared/services/api.gateway.service';
import { Store } from '@ngrx/store';
import {
  AppState,
  getAuthToken,
  getAuthTokenWithApexUserId
} from '../../../../reducers';
import { WeeklyPerformanceResponse } from '../../../../core/responses/goal/weeklyPerformanceResponse';
import { first, map, switchMap } from 'rxjs/operators';
import { GoalResponse } from '../../../../core/responses/goal/goalResponse';
import { GoalSubmitRequest } from '../../../../core/request/goal/goalSubmitRequest';
import { GoalSubmitResponse } from '../../../../core/responses/goal/goalSubmitResponse';
import { PerformanceDetails } from '../../../../core/dataEntities/goal/performanceDetails';
import moment from 'moment';
import { DateFormat, IdDateFormat } from '../../../../shared/constants';
import { ProductivityMetricConfig } from '../config/slidersConfig';
import { GoalValidation } from '../models/GoalValidation';
import { GoalValidationRange } from '../enums/GoalValidationRange';
import { ValidationStatus } from '../enums/ValidationStatus';
import { MathHelpers } from '../../../../shared/helpers/math.helpers';
import { PrecisionValidator } from '../../../../shared/validators/precision.validator';
import { DigitsOnlyValidator } from '../../../../shared/validators/digits-only.validator';
import { PerformanceMetrics } from '../models/PerformanceMetrics';
import { GoalValidationSource } from '../enums/GoalValidationSource';

@Injectable({
  providedIn: 'root'
})
export class GoalService {
  constructor(
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>
  ) {}

  getWeeklyPerformance(
    weekEndingDate: string
  ): Observable<WeeklyPerformanceResponse> {
    return this.store.select(getAuthTokenWithApexUserId).pipe(
      first(),
      switchMap(({ authToken, userId }) => {
        if (!userId) {
          throw new Error('User not found.');
        }

        return this.apiGateway.get<WeeklyPerformanceResponse>(
          `associates/${userId}/earnings/performance/weekly/${weekEndingDate}`,
          authToken
        );
      })
    );
  }

  getWeeklyGoal(
    weekEndingDate: string,
    associatePaySplit: number
  ): Observable<GoalResponse> {
    return this.store.select(getAuthTokenWithApexUserId).pipe(
      first(),
      switchMap(({ authToken, userId }) => {
        if (!userId) {
          throw new Error('User not found.');
        }

        return this.apiGateway
          .get<GoalResponse>(
            `associates/${userId}/earnings/goal/weekly/${weekEndingDate}`,
            authToken
          )
          .pipe(
            map(response => {
              const weeklyGoal = {
                id: weekEndingDate,
                lastUpdatedAtDate: moment().format(DateFormat),
                ...response
              };

              if (!_.isEmpty(response)) {
                weeklyGoal.goal = this.calculateGoal(
                  weeklyGoal,
                  associatePaySplit
                );
              } else {
                weeklyGoal.goal = null;
                weeklyGoal.user = userId;
                weeklyGoal.goalStartDate = moment().format(IdDateFormat);
                weeklyGoal.goalEndDate = null;
                weeklyGoal.itemType = ItemType.Pallets;
              }

              return weeklyGoal;
            })
          );
      })
    );
  }

  submitGoal(
    goal: GoalSubmitRequest,
    weekEndingDate: string,
    associatePaySplit: number
  ): Observable<GoalDetails> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .post<GoalSubmitResponse>(
            `associates/earnings/goal/weekly`,
            authToken,
            goal
          )
          .map(response => {
            const goalDetails = {
              id: weekEndingDate,
              revenuePerPallet: response.revenuePerPallet,
              revenuePerCase: response.revenuePerCase,
              hours: response.hours,
              palletsPerHour: response.palletsPerHour,
              casesPerHour: response.casesPerHour,
              goalStartDate: response.goalStartDate,
              user: response.user,
              itemType: response.itemType,
              lastUpdatedAtDate: moment().format(DateFormat)
            } as GoalDetails;

            goalDetails.goal = this.calculateGoal(
              goalDetails,
              associatePaySplit
            );

            return goalDetails;
          });
      })
    );
  }

  calculateGoal(goal: GoalDetails, associatePaySplit: number) {
    let result = goal.casesPerHour * goal.revenuePerCase;
    if (goal.itemType === ItemType.Pallets) {
      result = goal.palletsPerHour * goal.revenuePerPallet;
    }
    result = result * associatePaySplit;
    return parseFloat((result * goal.hours).toFixed(2));
  }

  calculateWeeklyGoal(iph: number, wh: number, rpi: number, split: number) {
    const value = iph * wh * rpi * split;
    return parseFloat(value.toFixed(2));
  }

  getItemPropertiesByItemType(itemType) {
    let itemsPerHour;
    let revenuePerItem;

    if (itemType === ItemType.Pallets) {
      itemsPerHour = 'palletsPerHour';
      revenuePerItem = 'revenuePerPallet';
    } else {
      itemsPerHour = 'casesPerHour';
      revenuePerItem = 'revenuePerCase';
    }
    return {
      itemsPerHour,
      revenuePerItem
    };
  }

  getFormInitValues(
    goalDetails: GoalDetails,
    performance: PerformanceDetails
  ): GoalDetails {
    return {
      ...goalDetails,
      casesPerHour: goalDetails.casesPerHour
        ? goalDetails.casesPerHour
        : performance.associateProductivityAverage.productivityMetrics
            .casesPerHour
        ? performance.associateProductivityAverage.productivityMetrics
            .casesPerHour
        : performance.siteProductivityAverage.productivityMetrics.casesPerHour,
      palletsPerHour: goalDetails.palletsPerHour
        ? goalDetails.palletsPerHour
        : performance.associateProductivityAverage.productivityMetrics
            .palletsPerHour
        ? performance.associateProductivityAverage.productivityMetrics
            .palletsPerHour
        : performance.siteProductivityAverage.productivityMetrics
            .palletsPerHour,
      hours: goalDetails.hours
        ? goalDetails.hours
        : performance.associateProductivityAverage.hours
        ? performance.associateProductivityAverage.hours
        : performance.siteProductivityAverage.hours,
      revenuePerCase: goalDetails.revenuePerCase
        ? goalDetails.revenuePerCase
        : performance.associateProductivityAverage.productivityMetrics
            .revenuePerCase
        ? performance.associateProductivityAverage.productivityMetrics
            .revenuePerCase
        : performance.siteProductivityAverage.productivityMetrics
            .revenuePerCase,
      revenuePerPallet: goalDetails.revenuePerPallet
        ? goalDetails.revenuePerPallet
        : performance.associateProductivityAverage.productivityMetrics
            .revenuePerPallet
        ? performance.associateProductivityAverage.productivityMetrics
            .revenuePerPallet
        : performance.siteProductivityAverage.productivityMetrics
            .revenuePerPallet
    };
  }

  getSiteAverages(performance: PerformanceDetails) {
    return {
      casesPerHour:
        performance.siteProductivityAverage.productivityMetrics.casesPerHour,
      palletsPerHour:
        performance.siteProductivityAverage.productivityMetrics.palletsPerHour,
      revenuePerCase:
        performance.siteProductivityAverage.productivityMetrics.revenuePerCase,
      revenuePerPallet:
        performance.siteProductivityAverage.productivityMetrics
          .revenuePerPallet,
      hours: performance.siteProductivityAverage.hours
    };
  }

  getAssociateAverages(performance: PerformanceDetails) {
    return {
      casesPerHour:
        performance.associateProductivityAverage.productivityMetrics
          .casesPerHour,
      palletsPerHour:
        performance.associateProductivityAverage.productivityMetrics
          .palletsPerHour,
      revenuePerCase:
        performance.associateProductivityAverage.productivityMetrics
          .revenuePerCase,
      revenuePerPallet:
        performance.associateProductivityAverage.productivityMetrics
          .revenuePerPallet,
      hours: performance.associateProductivityAverage.hours
    };
  }

  getPerformanceMetrics(
    associateAverageValue,
    siteAverageValue,
    valueName
  ): PerformanceMetrics {
    if (!associateAverageValue && !siteAverageValue) {
      return null;
    }

    return {
      associateAverage: associateAverageValue[valueName],
      siteAverage: siteAverageValue[valueName]
    };
  }

  validateBySourceAverage(
    associateAverage: number,
    value: number,
    source: GoalValidationSource,
    validateByPercentage: boolean,
    config: ProductivityMetricConfig
  ) {
    const performanceValue = associateAverage;
    const valueEqualsSource = Math.abs(value - performanceValue) < config.step;
    /* tslint:disable:no-bitwise */
    if (valueEqualsSource) {
      return {
        range: GoalValidationRange.None,
        status: ValidationStatus.Valid,
        source,
        equalsSource: true,
        value: 0
      };
    } else if (value >= performanceValue) {
      const diff = value - performanceValue;
      if (validateByPercentage) {
        const percentageDiff = MathHelpers.getPercentageDiff(
          value,
          performanceValue
        );
        if (percentageDiff >= 20 && percentageDiff < 50) {
          return {
            range: GoalValidationRange.Above | GoalValidationRange.Above20Pct,
            status: ValidationStatus.Warning,
            source,
            equalsSource: false,
            value: diff
          };
        } else if (percentageDiff >= 50) {
          return {
            range: GoalValidationRange.Above | GoalValidationRange.Above50Pct,
            status: ValidationStatus.Error,
            source,
            equalsSource: false,
            value: diff
          };
        }
      }

      return {
        range: GoalValidationRange.Above,
        status: ValidationStatus.Valid,
        source,
        equalsSource: false,
        value: diff
      };
    } else {
      const diff = performanceValue - value;
      return {
        range: GoalValidationRange.Below,
        status: ValidationStatus.Warning,
        source,
        equalsSource: false,
        value: diff
      };
    }
    /* tslint:enable:no-bitwise */
  }

  validateProductivityMetric(
    performance: PerformanceMetrics,
    value: number,
    config: ProductivityMetricConfig
  ): GoalValidation {
    if (!performance.associateAverage && !performance.siteAverage) {
      return this.validateBySourceAverage(
        config.min,
        value,
        GoalValidationSource.MinValue,
        true,
        config
      );
    }

    if (!performance.associateAverage && performance.siteAverage) {
      return this.validateBySourceAverage(
        performance.siteAverage,
        value,
        GoalValidationSource.SiteAverage,
        true,
        config
      );
    }

    if (performance.associateAverage >= performance.siteAverage) {
      return this.validateBySourceAverage(
        performance.associateAverage,
        value,
        GoalValidationSource.AssociateAverage,
        true,
        config
      );
    }

    if (
      value >= performance.associateAverage &&
      value < performance.siteAverage
    ) {
      return this.validateBySourceAverage(
        performance.associateAverage,
        value,
        GoalValidationSource.AssociateAverage,
        false,
        config
      );
    }

    if (value >= performance.siteAverage) {
      return this.validateBySourceAverage(
        performance.siteAverage,
        value,
        GoalValidationSource.SiteAverage,
        true,
        config
      );
    }

    return this.validateBySourceAverage(
      performance.associateAverage,
      value,
      GoalValidationSource.AssociateAverage,
      false,
      config
    );
  }

  validateWorkingHours(performance, value, config: ProductivityMetricConfig) {
    if (!performance.associateAverage && !performance.siteAverage) {
      return this.validateBySourceAverage(
        config.min,
        value,
        GoalValidationSource.MinValue,
        false,
        config
      );
    }

    if (!performance.associateAverage && performance.siteAverage) {
      return this.validateBySourceAverage(
        performance.siteAverage,
        value,
        GoalValidationSource.SiteAverage,
        false,
        config
      );
    }

    if (performance.associateAverage >= performance.siteAverage) {
      return this.validateBySourceAverage(
        performance.associateAverage,
        value,
        GoalValidationSource.AssociateAverage,
        false,
        config
      );
    }
    if (
      value >= performance.associateAverage &&
      value < performance.siteAverage
    ) {
      return this.validateBySourceAverage(
        performance.associateAverage,
        value,
        GoalValidationSource.AssociateAverage,
        false,
        config
      );
    }
    if (value >= performance.siteAverage) {
      return this.validateBySourceAverage(
        performance.siteAverage,
        value,
        GoalValidationSource.SiteAverage,
        false,
        config
      );
    }

    return this.validateBySourceAverage(
      performance.associateAverage,
      value,
      GoalValidationSource.AssociateAverage,
      false,
      config
    );
  }

  getHourPerformanceFallbackValue(
    performance: PerformanceDetails,
    config: ProductivityMetricConfig
  ) {
    const performanceValue = this.getPerformanceMetrics(
      performance.associateProductivityAverage,
      performance.siteProductivityAverage,
      'hours'
    );
    return performanceValue.associateAverage
      ? performanceValue.associateAverage
      : performanceValue.siteAverage
      ? performanceValue.siteAverage
      : config.min;
  }

  getPerformanceFallbackValue(
    performance: PerformanceDetails,
    valueName,
    config: ProductivityMetricConfig
  ) {
    const performanceValue = this.getPerformanceMetrics(
      performance.associateProductivityAverage.productivityMetrics,
      performance.siteProductivityAverage.productivityMetrics,
      valueName
    );

    return performanceValue.associateAverage
      ? performanceValue.associateAverage
      : performanceValue.siteAverage
      ? performanceValue.siteAverage
      : config.min;
  }

  getProductivityHoursMetric(performance: PerformanceDetails) {
    if (!performance) {
      return null;
    }
    return this.getPerformanceMetrics(
      performance.associateProductivityAverage,
      performance.siteProductivityAverage,
      'hours'
    );
  }

  getProductivityMetric(performance: PerformanceDetails, metric) {
    if (!performance) {
      return null;
    }
    return this.getPerformanceMetrics(
      performance.associateProductivityAverage.productivityMetrics,
      performance.siteProductivityAverage.productivityMetrics,
      metric
    );
  }

  getGoalNumberValidatorByStep(stepValue) {
    const precision = MathHelpers.getNumberPrecisionLength(stepValue);
    if (precision) {
      return PrecisionValidator(precision);
    }
    return DigitsOnlyValidator();
  }
}
