<header class="header">
  <button
    class="menu-button"
    (click)="openMenu.emit('')"
    cdkMonitorElementFocus
  >
    <app-svg-icon
      [name]="SvgIconNameEnum.Menu"
      class="menu-button__icon"
    ></app-svg-icon>
    <span i18n="@@menu-bar_menu" data-cy="menu">Menu</span>
  </button>
  <div class="logo">
    <app-svg-icon
      class="logo__icon"
      [name]="SvgIconNameEnum.CapstoneLogo"
      i18n-description="@@CapstoneLogotype"
      description="Capstone logotype"
    ></app-svg-icon>
    <span class="logo__label" i18n="@@myCapstone">My Capstone</span>
  </div>
</header>
