import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-wrapper',
  templateUrl: './loader-wrapper.component.html'
})
export class LoaderWrapperComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
