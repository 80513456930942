import { Observable } from 'rxjs';
import { UserInfoDetails } from '../../core/dataEntities/user/userInfoDetails';
import { AppState, getAuthToken } from '../../reducers';
import { first, switchMap, map } from 'rxjs/operators';
import { UserInfoDetailsResponse } from '../../core/responses/user/userInfoDetailsResponse';
import isEmpty from 'lodash/fp/isEmpty';
import { UserSettings } from '../../core/dataEntities/user/userSettings';
import { Injectable } from '@angular/core';
import { ApiGatewayService } from './api.gateway.service';
import { Store } from '@ngrx/store';
import { TranslationService } from './translation.service';
import moment from 'moment';
import { IdDateFormat } from '../constants';
import _ from 'lodash';
import { AssociatesService } from './associates.service';
import { DateTimeUtils } from '../utils/DateTimeUtils';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>,
    private translationService: TranslationService,
    private associateService: AssociatesService
  ) {}

  getUserInfo(userId: string): Observable<UserInfoDetails> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .get<UserInfoDetailsResponse>(`associates/${userId}`, authToken)
          .pipe(
            map((response: UserInfoDetailsResponse) => {
              if (isEmpty(response)) {
                return {
                  apexId: null,
                  eid: null,
                  hasRoadCrewAccess: false,
                  roadCrewErrorMessage: '',
                  fullName: null,
                  position: null,
                  homeSite: null,
                  siteState: null,
                  sitePartner: null,
                  siteAddress: null,
                  siteName: null,
                  responsibleSubDepartments: {
                    managedSubDepartments: [],
                    accessSubDepartments: []
                  },
                  weeklyCheckIns: {
                    currentWeek: false,
                    currentWeekDate: '',
                    pastWeek: false,
                    pastWeekDate: ''
                  }
                } as UserInfoDetails;
              }

              return {
                ...response,
                preference: response.preference
                  ? {
                      ...response.preference,
                      language: response.preference.language
                        ? response.preference.language
                        : this.translationService.getLanguage()
                    }
                  : {
                      phoneNumber: null,
                      language: this.translationService.getLanguage(),
                      textOptIn: null
                    },
                weeklyCheckIns: this.getCheckInStatus(response),
                isTrainee: this.associateService.isAssociateTrainee(
                  response.tenure,
                  response.trainingStatus
                ),
                trainingStatus: this.associateService.calculateTrainingStatus(
                  response.tenure,
                  response.trainingStatus
                )
              } as UserInfoDetails;
            })
          );
      })
    );
  }

  saveUserSettings(userId, userSettings: UserSettings): Observable<any> {
    const requestBody = {
      azureGuid: userId,
      phoneNumber: userSettings.phoneNumber,
      language: userSettings.language,
      textOptIn: userSettings.textOptIn
    };

    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.put(
          `associates/preference`,
          authToken,
          requestBody
        );
      })
    );
  }

  private getCheckInStatus(userInfo: UserInfoDetailsResponse) {
    const currentWeekEndDate = DateTimeUtils.getCurrentWeekEndDate();
    const pastWeekEndDate = DateTimeUtils.getPastWeekEndDate();
    const dayOfTheWeek = DateTimeUtils.getDayOfTheWeek();

    if (
      dayOfTheWeek >= 1 &&
      dayOfTheWeek <= 3 &&
      !_.first(
        userInfo.weeklyCheckIns.filter(
          checkIn =>
            moment.utc(checkIn.weekEndingDate).format(IdDateFormat) ===
            pastWeekEndDate
        )
      ).hasSubmitted
    ) {
      return {
        currentWeek: false,
        currentWeekDate: currentWeekEndDate,
        pastWeek: true,
        pastWeekDate: pastWeekEndDate
      };
    }

    if (
      dayOfTheWeek >= 5 &&
      dayOfTheWeek <= 7 &&
      !_.first(
        userInfo.weeklyCheckIns.filter(
          checkIn =>
            moment.utc(checkIn.weekEndingDate).format(IdDateFormat) ===
            currentWeekEndDate
        )
      ).hasSubmitted
    ) {
      return {
        currentWeek: true,
        currentWeekDate: currentWeekEndDate,
        pastWeek: false,
        pastWeekDate: pastWeekEndDate
      };
    }

    return {
      currentWeek: false,
      currentWeekDate: currentWeekEndDate,
      pastWeek: false,
      pastWeekDate: pastWeekEndDate
    };
  }
}
