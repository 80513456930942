<h3
  class="signature-edit__heading"
  i18n="@@signature-edit_AcknowledgementTitle"
>
  Capstone Logistics Road Crew Contract
</h3>
<p i18n="@@signature-edit_AcknowledgementContent">
  You have been chosen by Capstone Logistics, Inc. to participate in a Startup,
  Recovery Project or Special Project. Capstone Logistics is positioned as the
  best and most professional partner in our industry. This contract is designed
  to give you the information needed to make this trip a success. Capstone
  Logistics is counting on YOU to present outstanding work ethic to our partner
  and new associates. All Capstone Logistics Road Crew Associates are required
  to abide by the following guidelines:
</p>
<app-accordion>
  <app-accordion-panel
    *ngFor="let terms of termsAndConditions"
    [title]="terms.title"
  >
    <div slot="body" [innerHTML]="terms.content"></div>
  </app-accordion-panel>
</app-accordion>
<app-form-field [required]="true">
  <app-signature
    [formControl]="formGroup.get('signature')"
    [name]="firstName"
    [surname]="lastName"
    [date]="signatureDate"
    i18n-placeholder="@@signature-edit_signaturePlaceholder"
    placeholder="Sign on the line with your finger"
  ></app-signature>
  <app-validation [errors]="formGroup.get('signature').errors"></app-validation>
</app-form-field>

<div class="signature-edit__action">
  <app-button-link
    (buttonClick)="onSubmit()"
    label="Submit Registration"
    i18n-label="@@signature-edit_submitRegistration"
    [appearance]="ButtonAppearanceEnum.Primary"
    [color]="ButtonColorEnum.Blue"
    [type]="ButtonTypeEnum.Submit"
  ></app-button-link>
</div>
