import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslationService } from '../../../../../../shared/services/translation.service';

@Component({
  selector: 'app-personal-details-readonly',
  templateUrl: './personal-details-readonly.component.html'
})
export class PersonalDetailsReadonlyComponent implements OnInit {
  @Input() formGroup: FormGroup;
  constructor(public translationService: TranslationService) {}

  ngOnInit() {}
}
