import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit
} from '@angular/core';
import Hammer from 'hammerjs';
import { Sidebar } from 'ng-sidebar';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import * as fromRoot from 'src/app/reducers';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements AfterViewInit, OnInit {
  @Input()
  isDrawerOpen;

  @Output()
  drawerClosed = new EventEmitter();

  @ViewChild(Sidebar, { static: true }) drawer: Sidebar;

  @ViewChild('content', { static: false }) content: ElementRef;

  hideNavigationBar$: Observable<boolean>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.hideNavigationBar$ = this.store.select(fromRoot.getHideNavigationBar);
  }

  ngAfterViewInit() {
    const hammertime = new Hammer(this.content.nativeElement, {
      touchAction: 'pan-y'
    });

    hammertime.get('swipe').set({ direction: Hammer.DIRECTION_ALL });
    hammertime.on('swipeleft', event => {
      if (this.drawer.opened && event.pointerType !== 'mouse') {
        this.drawer.close();
      }
    });
  }
}
