import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadPreviewAppearance } from '../../enums/UploadPreview';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-upload-preview',
  templateUrl: './upload-preview.component.html',
  styleUrls: ['./upload-preview.component.scss']
})
export class UploadPreviewComponent implements OnInit {
  @Input() appearance = UploadPreviewAppearance.White;
  @Input() iconName: SvgIconName;
  @Input() text: string;
  @Input() subtext: string;
  @Input() error: string;
  @Input() image: string;
  @Input() focused = false;
  @Output()
  buttonClick = new EventEmitter();

  public UploadPreviewAppearanceEnum = UploadPreviewAppearance;
  public SvgIconNameEnum = SvgIconName;

  constructor() {}

  ngOnInit() {}

  onClickButton(event) {
    this.buttonClick.emit(event);
  }
}
