import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  AppState,
  getIsAppOnline,
  getManagerSubDepartments
} from '../../../reducers';
import {
  isEndOfMyTeamList,
  isMyTeamLoading,
  selectAllMyTeamMembers,
  selectMyTeamFilter,
  selectMyTeamPage,
  selectMyTeamSort
} from '../../reducers/my-team.reducer';
import { TeamMemberRow } from '../../view-models/team-member-record';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { DepartmentInfo } from '../../../core/responses/managers/subDepartmentsResponse';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { MyTeamActions } from '../../actions';
import { MyTeamSortOptions } from '../../../core/enums/MyTeamSortOptions';
import { TranslationService } from '../../../shared/services/translation.service';
import { TranslationMessages } from '../../../shared/enums/TranslationMessages';
import { Settings } from '../../../core/constants/settings';

@Component({
  selector: 'app-my-team-members',
  templateUrl: './my-team-members.component.html',
  styleUrls: ['./my-team-members.component.scss']
})
export class MyTeamMembersComponent implements OnInit, OnDestroy {
  onDestroySubject = new Subject();
  public myTeam$: Observable<TeamMemberRow[]>;
  public managerSubDepartments$: Observable<DepartmentInfo[]>;
  public isMyTeamLoading$: Observable<boolean>;
  public isEndOfList$: Observable<boolean>;
  public myTeamFilter$: Observable<any>;
  public myTeamSort$: Observable<any>;
  public showEmptyState$: Observable<boolean>;
  public isAppOnline$: Observable<boolean>;
  public shouldShowManagerSubDepartments$: Observable<boolean>;

  public sortOptions: any[];
  PageTitleAppearanceEnum = PageTitleAppearance;
  MyTeamSortOptionsEnum = MyTeamSortOptions;
  private isScrolledToBottom = new Subject();
  Settings = Settings;

  constructor(
    private store: Store<AppState>,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    this.myTeam$ = this.store.select(selectAllMyTeamMembers);
    this.isMyTeamLoading$ = this.store.select(isMyTeamLoading);
    this.isEndOfList$ = this.store.select(isEndOfMyTeamList);
    this.myTeamFilter$ = this.store.select(selectMyTeamFilter);
    this.myTeamSort$ = this.store.select(selectMyTeamSort);
    this.managerSubDepartments$ = this.store.select(getManagerSubDepartments);
    this.isAppOnline$ = this.store.select(getIsAppOnline);

    this.shouldShowManagerSubDepartments$ = this.managerSubDepartments$.pipe(
      map(managerSubDepartments => {
        return managerSubDepartments.length > 1;
      })
    );

    this.sortOptions = [
      {
        id: MyTeamSortOptions.Tenure,
        name: this.translationService.translate(
          TranslationMessages.MyTeamSortByTenure
        )
      },
      {
        id: MyTeamSortOptions.HoursWorked,
        name: this.translationService.translate(
          TranslationMessages.MyTeamSortByHoursWorked
        )
      },
      {
        id: MyTeamSortOptions.AssociateName,
        name: this.translationService.translate(
          TranslationMessages.MyTeamSortByAssociateName
        )
      },
      {
        id: MyTeamSortOptions.LastWeeklyCheckInScore,
        name: this.translationService.translate(
          TranslationMessages.MyTeamSortByCheckInScore
        )
      }
    ];

    this.isScrolledToBottom
      .pipe(
        withLatestFrom(
          this.isEndOfList$,
          this.isMyTeamLoading$,
          this.store.select(selectMyTeamPage)
        ),
        takeUntil(this.onDestroySubject)
      )
      .subscribe(([x, isEndOfList, isTeamLoading, page]) => {
        if (!isEndOfList && !isTeamLoading) {
          this.store.dispatch(
            MyTeamActions.changeMyTeamPage({
              pageIndex: page.pageIndex + 1,
              pageSize: page.pageSize
            })
          );
        }
      });

    this.store
      .select(getManagerSubDepartments)
      .pipe(
        withLatestFrom(this.myTeamFilter$),
        takeUntil(this.onDestroySubject)
      )
      .subscribe(([departments, filter]) => {
        if (departments.length && !filter.site) {
          this.store.dispatch(
            MyTeamActions.changeSiteFilter({ site: departments[0].subDept })
          );
        }
      });

    this.showEmptyState$ = combineLatest(this.myTeam$, this.isAppOnline$).pipe(
      map(([myTeam, isAppOnline]) => {
        return myTeam.length === 0 && !isAppOnline;
      })
    );
  }

  onSiteChange(site) {
    this.store.dispatch(MyTeamActions.changeSiteFilter({ site }));
  }

  onSearchQueryChange(query) {
    this.store.dispatch(MyTeamActions.changeQueryFilter({ query }));
  }

  onSortChange(sort) {
    this.store.dispatch(MyTeamActions.changeMyTeamSort(sort));
  }

  ngOnDestroy() {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    const position =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    if (document.documentElement.scrollHeight - position <= 60) {
      this.isScrolledToBottom.next(true);
    }
  }
}
