import { select } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
export class AuthenticationGuard {
    constructor(router, store) {
        this.router = router;
        this.store = store;
    }
    canActivate() {
        return this.store
            .pipe(select(fromRoot.isUserAgreeWithTermsAndIsLoggedIn))
            .map(({ isUserAgreeWithTerms, isUserLoggedIn }) => {
            if (!isUserLoggedIn) {
                this.router.navigate(['']);
                return false;
            }
            if (!isUserAgreeWithTerms) {
                this.router.navigate(['disclaimer']);
                return false;
            }
            return true;
        });
    }
}
AuthenticationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationGuard_Factory() { return new AuthenticationGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store)); }, token: AuthenticationGuard, providedIn: "root" });
