<app-page-header (click)="openSkip()"></app-page-header>

<main class="main-container" *ngIf="checkInFormGroup as checkInForm">
  <ng-template #notSkippedTitle i18n="@@weekly-check-in_weeklyCheckInTitle">
    Weekly Check-In
  </ng-template>
  <ng-template #alreadySkippedTitle i18n="@@weekly-check-in_dontForget">
    Don't Forget!
  </ng-template>
  <app-page-title
    data-cy="pageHeaderTitle"
    [titleTemplate]="isCheckInSkipped ? alreadySkippedTitle : notSkippedTitle"
    [appearance]="
      isCheckInSkipped
        ? PageTitleAppearanceEnum.Yellow
        : PageTitleAppearanceEnum.Blue
    "
  >
    <app-page-subtitle>
      <time>
        {{ startDay | date: 'MM/dd' }}
      </time>
      -
      <time>
        {{ endDay | date: 'MM/dd' }}
      </time>
    </app-page-subtitle>
    <app-page-subtitle
      i18n="@@weekly-check-in_beforeYouContinue"
      *ngIf="!isCheckInSkipped"
    >
      Before you continue tell your manager how your week went!
    </app-page-subtitle>
    <app-page-subtitle
      i18n="@@weekly-check-in_yourSiteManagerUsesYourCheckIn"
      *ngIf="isCheckInSkipped"
    >
      Your site manager uses your check-ins to help improve your experience.
    </app-page-subtitle>
  </app-page-title>

  <form
    class="weekly-check-in main-content"
    [formGroup]="checkInForm"
    appRootForm
    (ngSubmit)="onSubmit(checkInForm); isSubmitting = true"
  >
    <app-form-field>
      <app-label>
        <span
          class="weekly-check-in__rating-label"
          i18n="@@weekly-check-in_howWasYourWeek"
        >
          How was your week?
        </span>
      </app-label>
      <app-radio-group
        formControlName="score"
        [direction]="RadioGroupDirectionEnum.Row"
      >
        <app-rating-radio-input
          [value]="1"
          [appearance]="RatingRatioInputAppearanceEnum.DarkRed"
        >
          1
        </app-rating-radio-input>
        <app-rating-radio-input
          [value]="2"
          [appearance]="RatingRatioInputAppearanceEnum.Red"
        >
          2
        </app-rating-radio-input>
        <app-rating-radio-input
          [value]="3"
          [appearance]="RatingRatioInputAppearanceEnum.Yellow"
        >
          3
        </app-rating-radio-input>
        <app-rating-radio-input
          [value]="4"
          [appearance]="RatingRatioInputAppearanceEnum.Blue"
        >
          4
        </app-rating-radio-input>
        <app-rating-radio-input
          [value]="5"
          [appearance]="RatingRatioInputAppearanceEnum.Green"
        >
          5
        </app-rating-radio-input>
      </app-radio-group>
      <app-validation [errors]="checkInForm.get('score').errors">
        <app-validation-message
          *ngIf="checkInForm.get('score').errors?.required !== null"
          [id]="'input-score'"
          i18n="@@weekly-check-in_enterRatingForYourWeek"
        >
          Enter a rating for your week.
        </app-validation-message>
      </app-validation>
    </app-form-field>
    <app-form-field
      *ngIf="checkInFormGroup.get('score').value !== null"
      @enterLeaveTrigger
      class="weekly-check-in__textarea"
    >
      <app-label
        *ngIf="
          checkInForm.get('score').value === 1 ||
          checkInForm.get('score').value === 2
        "
        i18n="@@weekly-check-in_whatCanYourManagerDoToHelp"
      >
        What can your manager do to help?
      </app-label>
      <app-label
        *ngIf="checkInForm.get('score').value === 3"
        i18n="@@weekly-check-in_whatWouldMakeNextWeekBetter"
      >
        What would make next week better?
      </app-label>
      <app-label
        *ngIf="checkInForm.get('score').value === 4"
        i18n="@@weekly-check-in_anySpecialHighlights"
      >
        Glad to hear it! Any special highlights?
      </app-label>
      <app-label
        *ngIf="checkInForm.get('score').value === 5"
        i18n="@@weekly-check-in_whatMadeYourWeekGreat"
      >
        Awesome! What made your week great?
      </app-label>
      <app-text-area
        formControlName="message"
        [maxLength]="500"
        placeholder="(optional)"
      ></app-text-area>
      <app-validation [errors]="checkInForm.get('message').errors">
        <app-validation-message
          *ngIf="checkInForm.get('message').errors?.maxLength !== null"
          [id]="'textarea-message'"
          i18n="@@weekly-check-in_commentIsTooLong"
        >
          Your Comment is too long. Maximum is 500 characters
        </app-validation-message>
      </app-validation>
    </app-form-field>
    <button
      class="weekly-check-in__about-checkin"
      type="button"
      (click)="openAbout()"
      i18n="@@weekly-check-in_whatAreWeeklyCheckIns"
    >
      What are Weekly Check-Ins?
    </button>
    <div class="weekly-check-in__form-action">
      <app-button-link
        [type]="ButtonTypeEnum.Submit"
        label="Submit Check-in"
        i18n-label="@@weekly-check-in_submit"
        [disabled]="!checkInFormGroup.valid || isSubmitting"
      ></app-button-link>
    </div>
  </form>
</main>
