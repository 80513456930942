import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shifts',
  styleUrls: ['./shifts.component.scss'],
  templateUrl: './shifts.component.html'
})
export class ShiftsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
