<ng-container *ngIf="label">
  <label
    *ngIf="!label?.asLegend; else legendTemplate"
    [ngClass]="{
      label: true,
      'label--required': required,
      'label--invalid': isInvalid(),
      'label--hidden': label?.hidden
    }"
    [attr.for]="control?.id"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </label>

  <ng-template #legendTemplate>
    <legend
      [ngClass]="{
        label: true,
        'label--required': required,
        'label--invalid': isInvalid(),
        'label--hidden': label?.hidden
      }"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </legend>
  </ng-template>

  <ng-template #content>
    <ng-content select="app-label"></ng-content>
  </ng-template>
</ng-container>
<div
  [ngClass]="{
    'input-container': true,
    'input-container--has-validation-message': validationContainer,
    'input-container--narrow': narrow
  }"
>
  <ng-content></ng-content>
</div>

<div *ngIf="isInvalid() && validationContainer" class="validation-container">
  <ng-content select="app-validation"></ng-content>
</div>
