import { getAuthToken, getUserInfo } from '../../../../reducers';
import { first, map, switchMap } from 'rxjs/operators';
import { ShiftContactTypes } from '../../../../core/enums/ShiftContactTypes';
import moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/content-delivery.service";
import * as i2 from "../../../../shared/services/api.gateway.service";
import * as i3 from "@ngrx/store";
export class ShiftService {
    constructor(contentDeliveryService, apiGateway, store, localeId) {
        this.contentDeliveryService = contentDeliveryService;
        this.apiGateway = apiGateway;
        this.store = store;
        this.contentLocale = localeId;
    }
    getShift(id) {
        if (id === null) {
            return this.store.select(getUserInfo).pipe(first(), map(userInfo => {
                return {
                    id: null,
                    manager: {
                        id: userInfo.id,
                        firstName: userInfo.name,
                        title: userInfo.title
                    },
                    phoneNumber: null,
                    contactType: ShiftContactTypes.Text,
                    associatesNeeded: 1,
                    siteId: null,
                    startDateTime: null,
                    duration: null,
                    slug: ''
                };
            }));
        }
        return this.contentDeliveryService.getPost(id).pipe(first(), map(post => {
            return post.shift;
        }));
    }
    getShiftCount(managerId) {
        return this.contentDeliveryService.getShiftCount(managerId).pipe(first(), map(count => {
            return count;
        }));
    }
    postShift(shift) {
        const postData = this.mapShiftRequest(shift);
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.post(`comms/newsfeed/newsentry`, authToken, postData);
        }));
    }
    updateShift(shift) {
        const postData = this.mapShiftRequest(shift);
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.put(`comms/newsfeed/newsentry/${shift.id}`, authToken, postData);
        }));
    }
    getManagerSites(userId) {
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.get(`managers/${userId}/SubDepts`, authToken);
        }));
    }
    getManagerShifts(managerId) {
        const query = {
            'fields.author.sys.contentType.sys.id': 'author',
            'fields.category.sys.contentType.sys.id': 'newsCategory',
            'fields.author.fields.authorId': managerId,
            'fields.category.fields.key': 'workOpportunity'
        };
        return this.contentDeliveryService.getNews(0, 50, query).pipe(map((feed) => {
            return feed.posts.map(post => {
                return {
                    id: post.id,
                    shiftDate: post.shift.startDateTime,
                    createdAt: post.date
                };
            });
        }));
    }
    removeShift(postId) {
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.delete(`comms/newsfeed/entry/newsentry/${postId}/delete`, authToken);
        }));
    }
    generateDays() {
        const days = [];
        const startDateTime = moment();
        startDateTime.locale(this.contentLocale);
        for (let i = 0; i < 14; i++) {
            const day = startDateTime.clone().add(i, 'day');
            days.push({
                id: day.format('YYYY-MM-DD'),
                name: day.format('dddd MMM Do, YYYY')
            });
        }
        return days;
    }
    generateStartTime(startDate) {
        const startTimes = [];
        const startDateTime = startDate
            ? moment(startDate).isSame(new Date(), 'day')
                ? this.nearestMinutes(30, moment())
                : moment(startDate)
            : this.nearestMinutes(30, moment().startOf('day'));
        const endDateTime = startDateTime.clone().endOf('day');
        while (startDateTime < endDateTime) {
            startTimes.push({
                id: startDateTime.format('HH:mm'),
                name: startDateTime.format('hh:mm A')
            });
            startDateTime.add(0.5, 'hours');
        }
        return startTimes;
    }
    generateEndTime(startDate, startTime) {
        const endTimes = [];
        let durationInHours = 0;
        const startDateTime = startDate !== null && startTime !== null
            ? moment(`${startDate} ${startTime}`, 'YYYY-mm-DD HH:mm')
            : this.nearestMinutes(30, moment());
        const endDateTime = startDateTime.clone().add(1, 'day');
        while (startDateTime < endDateTime) {
            durationInHours = durationInHours + 0.5;
            startDateTime.add(0.5, 'hour');
            endTimes.push({
                id: durationInHours,
                name: `${this.nearestMinutes(15, startDateTime).format('hh:mm A')}`
            });
        }
        return endTimes;
    }
    nearestMinutes(interval, someMoment) {
        const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
        return someMoment
            .clone()
            .minute(roundedMinutes)
            .second(0);
    }
    mapShiftRequest(shift) {
        return {
            // tslint:disable-next-line:max-line-length
            slug: `open-shift-for-site-${shift.siteId.toString()}-${moment(shift.startDateTime).format('YYYY-MM-DD')}`,
            authorId: shift.manager.id,
            sites: [shift.siteId.toString()],
            startDateTime: moment(shift.startDateTime)
                .utc()
                .toISOString(),
            duration: shift.duration,
            endDate: moment(shift.startDateTime)
                .add(shift.duration)
                .format('YYYY-MM-DD'),
            phoneNumber: shift.phoneNumber,
            numberOfAssociates: shift.associatesNeeded,
            authorRole: shift.authorRole,
            reachBy: shift.contactType === ShiftContactTypes.Both
                ? ['Text', 'Call']
                : shift.contactType === ShiftContactTypes.Call
                    ? ['Call']
                    : ['Text']
        };
    }
}
ShiftService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShiftService_Factory() { return new ShiftService(i0.ɵɵinject(i1.ContentDeliveryService), i0.ɵɵinject(i2.ApiGatewayService), i0.ɵɵinject(i3.Store), i0.ɵɵinject(i0.LOCALE_ID)); }, token: ShiftService, providedIn: "root" });
