import { Component, Input, OnInit } from '@angular/core';
import { AppState, getIsAppOnline } from '../../../../../reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectApplicationForm,
  selectIsEachOpportunityReviewed,
  selectApplicationFormStatus,
  selectIsOpportunitiesLoading,
  selectOpenShifts,
  selectPendingOpportunities,
  selectRoadCrewOpenings,
  selectRoadCrewOpeningsCount,
  selectUpdatedOpportunities,
  rejectionReason
} from '../../reducers/opportunities.reducer';
import { OpenShiftListItem } from '../../../../../core/dataEntities/opportunities/openShiftListItem';
import * as OpportunitiesActions from '../../actions/opportunities.actions';
import { OpportunityListItem } from '../../../../../core/dataEntities/opportunities/opportunityListItem';
import { PageTitleAppearance } from '../../../../../shared/enums/PageTitle';
import { AppliedOpportunityListItem } from '../../../../../core/dataEntities/opportunities/appliedOpportunityListItem';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CardLayoutAppearance } from '../../../../../shared/enums/CardLayout';
import { PageSectionHeaderAppearance } from '../../../../../shared/enums/PageSectionHeader';
import { ApplicationFormStatus } from '../../../../../core/enums/ApplicationFormStatus';
import { OpportunityType } from '../../../../../core/enums/OpportunityType';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})
export class OpportunitiesComponent implements OnInit {
  PageTitleAppearanceEnum = PageTitleAppearance;
  CardLayoutAppearanceEnum = CardLayoutAppearance;
  PageSectionHeaderAppearanceEnum = PageSectionHeaderAppearance;
  ApplicationFormStatusEnum = ApplicationFormStatus;
  RejectionReasonStr = '';

  isOpportunitiesLoading$: Observable<boolean>;
  openShifts$: Observable<OpenShiftListItem[]>;
  roadCrewOpenings$: Observable<OpportunityListItem[]>;
  roadCrewOpeningsCount$: Observable<number>;
  updatedOpportunities$: Observable<AppliedOpportunityListItem[]>;
  pendingOpportunities$: Observable<AppliedOpportunityListItem[]>;
  hasUpdatedItems$: Observable<boolean>;
  hasApplicationFormSubmitted$: Observable<boolean>;
  applicationFormStatus$: Observable<ApplicationFormStatus>;
  rejectionReason$: Observable<string>;
  isAppOnline$: Observable<boolean>;

  @Input() associateHomeState: string;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit() {
    this.isOpportunitiesLoading$ = this.store.select(
      selectIsOpportunitiesLoading
    );
    this.openShifts$ = this.store.select(selectOpenShifts);
    this.roadCrewOpenings$ = this.store.select(selectRoadCrewOpenings);
    this.roadCrewOpeningsCount$ = this.store.select(
      selectRoadCrewOpeningsCount
    );
    this.updatedOpportunities$ = this.store.select(selectUpdatedOpportunities);
    this.pendingOpportunities$ = this.store.select(selectPendingOpportunities);
    this.applicationFormStatus$ = this.store.select(
      selectApplicationFormStatus
    );

    this.rejectionReason$ = this.store.select(rejectionReason);

    this.hasUpdatedItems$ = this.store
      .select(selectIsEachOpportunityReviewed)
      .pipe(map(reviewed => !reviewed));

    this.hasApplicationFormSubmitted$ = this.store
      .select(selectApplicationForm)
      .pipe(map(applicationForm => !!applicationForm));

    this.isAppOnline$ = this.store.select(getIsAppOnline);

    this.isAppOnline$.subscribe(v => {
      if (v) {
        this.store.dispatch(OpportunitiesActions.loadOpportunities());
      }
    });
  }

  openItem(item) {
    if (item && item.opportunityType === OpportunityType.RoadCrew) {
      this.store.dispatch(OpportunitiesActions.openOpportunity(item));
    } else if (item && item.opportunityType === OpportunityType.Shift) {
      this.router.navigate(['/shifts', 'details', item.opportunityId]);
    }
  }

  onOpenAllList() {
    this.router.navigate(['/opportunities']);
  }

  openApplicationForm() {
    this.store.dispatch(OpportunitiesActions.openApplicationForm());
  }
}
