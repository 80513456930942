import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { SvgIconColor } from '../../../enums/SvgIcon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-menu-item-image',
  templateUrl: './menu-item-image.component.html',
  styleUrls: ['./menu-item-image.component.scss']
})
export class MenuItemImageComponent implements OnInit, OnChanges {
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() imageSrc: string;

  public bgColor: SvgIconColor;
  public firstChar: string;
  public secondChar: string;
  public photo = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.photo = this.sanitizer.bypassSecurityTrustResourceUrl(
      'data:image/jpg;base64,' + this.imageSrc
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lastName || changes.firstName) {
      this.firstChar = this.firstName.charAt(0);
      this.secondChar = this.lastName.charAt(0);
      this.bgColor = this.getColor();
    }
  }

  getColor() {
    switch (this.secondChar.toLowerCase()) {
      case 'i':
      case 'r':
        return SvgIconColor.Emperor;
      case 'b':
      case 'k':
      case 't':
        return SvgIconColor.Carnation;
      case 'c':
      case 'l':
      case 'u':
        return SvgIconColor.Endeavour;
      case 'd':
      case 'm':
      case 'v':
        return SvgIconColor.Marigold;
      case 'e':
      case 'n':
      case 'w':
        return SvgIconColor.Horizon;
      case 'f':
      case 'o':
      case 'x':
        return SvgIconColor.Lochmara;
      case 'g':
      case 'p':
      case 'y':
        return SvgIconColor.Cinnabar;
      case 'h':
      case 'q':
      case 'z':
        return SvgIconColor.Watercourse;
      case 'a':
      case 'j':
      case 's':
      default:
        return SvgIconColor.MountainMeadow;
    }
  }
}
