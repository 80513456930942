export enum TrainingStatus {
  NoRecordFound = 'NoRecordFound',
  WeekOneTraining = 'WeekOneTraining',
  WeekTwoTraining = 'WeekTwoTraining',
  WeekThreeTraining = 'WeekThreeTraining',
  WeekFourTraining = 'WeekFourTraining',
  WeekFiveTraining = 'WeekFiveTraining',
  WeekSixTraining = 'WeekSixTraining',
  FinishedTraining = 'FinishedTraining'
}
