<app-page-header-container
  [appearance]="PageHeaderAppearanceEnum.Green"
></app-page-header-container>
<main *ngIf="shiftDetails$ | async as shiftDetails" class="main-container">
  <ng-template #previewTitle i18n="@@shift-preview_pageTitle">
    Preview: shift request
  </ng-template>
  <app-page-title
    [titleTemplate]="previewTitle"
    [appearance]="PageTitleAppearanceEnum.Green"
  ></app-page-title>
  <div class="preview">
    <app-notice class="preview__notice" i18n="@@shift-preview_noticeText">
      <strong>This is a preview page.</strong>
      Once posted, associates will be notified next time they open MyCapstone.
      You can edit/delete the shift request at any time.
    </app-notice>
    <app-shift-details-card
      [shiftDetails]="shiftDetails"
      [preview]="true"
    ></app-shift-details-card>
    <div class="preview__action">
      <ng-template #editRequest i18n="@@shift-preview_editRequest">
        Edit Shift Request
      </ng-template>

      <app-button-link
        [labelTemplate]="editRequest"
        [link]="
          shiftDetails.id
            ? ['/shifts/edit', shiftDetails.id]
            : ['/shifts/create']
        "
        [appearance]="ButtonAppearanceEnum.Link"
      ></app-button-link>

      <ng-template #publishRequest i18n="@@shift-preview_publishRequest">
        Publish Shift Request
      </ng-template>

      <app-button-link
        [labelTemplate]="publishRequest"
        (buttonClick)="publishShift()"
        [appearance]="ButtonAppearanceEnum.Primary"
        [color]="ButtonColorEnum.Green"
      ></app-button-link>
    </div>
  </div>
</main>
