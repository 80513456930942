/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./certification-readonly.component";
var styles_CertificationReadonlyComponent = [];
var RenderType_CertificationReadonlyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CertificationReadonlyComponent, data: {} });
export { RenderType_CertificationReadonlyComponent as RenderType_CertificationReadonlyComponent };
function View_CertificationReadonlyComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["No\n  certifications marked"]))], null, null); }
function View_CertificationReadonlyComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["certification marked"]))], null, null); }
function View_CertificationReadonlyComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["certifications\n  marked"]))], null, null); }
function View_CertificationReadonlyComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "p", [["class", "read-only-details"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 7, null, null, null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i1.NgPlural, [i1.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificationReadonlyComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgPluralCase, [[8, "=0"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificationReadonlyComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgPluralCase, [[8, "=1"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificationReadonlyComponent_4)), i0.ɵdid(9, 16384, null, 0, i1.NgPluralCase, [[8, "other"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.formGroup.get("certificationsChecked").value.length; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formGroup.get("certificationsChecked").value.length; _ck(_v, 1, 0, currVal_0); }); }
export function View_CertificationReadonlyComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CertificationReadonlyComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formGroup.get("certificationsChecked").value; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CertificationReadonlyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-certification-readonly", [], null, null, null, View_CertificationReadonlyComponent_0, RenderType_CertificationReadonlyComponent)), i0.ɵdid(1, 114688, null, 0, i2.CertificationReadonlyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CertificationReadonlyComponentNgFactory = i0.ɵccf("app-certification-readonly", i2.CertificationReadonlyComponent, View_CertificationReadonlyComponent_Host_0, { formGroup: "formGroup" }, {}, []);
export { CertificationReadonlyComponentNgFactory as CertificationReadonlyComponentNgFactory };
