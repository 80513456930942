import { Routes } from '@angular/router';
import { AuthenticationGuard } from '../../../core/guards/authenticationGuard/authentication.guard';
import { WeeklyGoalContainerComponent } from './containers/weekly-goal-container/weekly-goal-container.component';
import { GoalEditGuard } from './guards/goal-edit.guard';
import { TranslationMessages } from '../../../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../../../core/enums/SiteMapItemKeys';
import { Module } from '../../../shared/enums/Module';
const ɵ0 = {
    module: Module.Wages,
    hideNavigationBar: true,
    pageTitle: TranslationMessages.GoalTitle,
    siteMapKey: SiteMapItemKeys.GoalEdit
};
const routes = [
    {
        path: 'goal/:week',
        component: WeeklyGoalContainerComponent,
        canActivate: [AuthenticationGuard, GoalEditGuard],
        data: ɵ0
    }
];
export class GoalRoutingModule {
}
export { ɵ0 };
