<app-tab-bar *ngIf="showTabs">
  <app-tab-bar-link link="../pay" i18n="@@earnings-tabs_MyEarnings">
    My Earning
  </app-tab-bar-link>
  <app-tab-bar-link
    *ngIf="isTrainee"
    link="../training"
    i18n="@@earnings-tabs_MyTrainings"
  >
    My Training
  </app-tab-bar-link>
  <app-tab-bar-link
    *ngIf="!isTrainee"
    link="../goal"
    [indicator]="goal == null"
    i18n="@@earnings-tabs_MyGoals"
  >
    My Goals
  </app-tab-bar-link>
</app-tab-bar>
