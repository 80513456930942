/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./menu-header.component";
var styles_MenuHeaderComponent = [i0.styles];
var RenderType_MenuHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuHeaderComponent, data: {} });
export { RenderType_MenuHeaderComponent as RenderType_MenuHeaderComponent };
function View_MenuHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MenuHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "menu-header__action"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuHeaderComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actionTemplate; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MenuHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "menu-header": 0, "menu-header--top-rounded-border": 1, "menu-header--bottom-rounded-border": 2 }), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuHeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, _co.topRoundedBorder, _co.bottomRoundedBorder); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.actionTemplate; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_MenuHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-header", [], null, null, null, View_MenuHeaderComponent_0, RenderType_MenuHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.MenuHeaderComponent, [], null, null)], null, null); }
var MenuHeaderComponentNgFactory = i1.ɵccf("app-menu-header", i3.MenuHeaderComponent, View_MenuHeaderComponent_Host_0, { actionTemplate: "actionTemplate", topRoundedBorder: "topRoundedBorder", bottomRoundedBorder: "bottomRoundedBorder" }, {}, ["*"]);
export { MenuHeaderComponentNgFactory as MenuHeaderComponentNgFactory };
