/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/tab-bar/tab-bar-component/tab-bar.component.ngfactory";
import * as i2 from "../../../shared/components/tab-bar/tab-bar-component/tab-bar.component";
import * as i3 from "../../../shared/components/tab-bar/tab-bar-link/tab-bar-link.component.ngfactory";
import * as i4 from "../../../shared/components/tab-bar/tab-bar-link/tab-bar-link.component";
import * as i5 from "@angular/common";
import * as i6 from "./associate-profile-tabs.component";
import * as i7 from "@ngrx/store";
var styles_AssociateProfileTabsComponent = [];
var RenderType_AssociateProfileTabsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AssociateProfileTabsComponent, data: {} });
export { RenderType_AssociateProfileTabsComponent as RenderType_AssociateProfileTabsComponent };
export function View_AssociateProfileTabsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "app-tab-bar", [], null, null, null, i1.View_TabBarComponent_0, i1.RenderType_TabBarComponent)), i0.ɵdid(1, 114688, null, 0, i2.TabBarComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "app-tab-bar-link", [["link", "../production"]], null, null, null, i3.View_TabBarLinkComponent_0, i3.RenderType_TabBarLinkComponent)), i0.ɵdid(3, 114688, null, 0, i4.TabBarLinkComponent, [], { link: [0, "link"] }, null), (_l()(), i0.ɵted(-1, 0, [" Production "])), (_l()(), i0.ɵeld(5, 0, null, 0, 3, "app-tab-bar-link", [["link", "../check-ins"]], null, null, null, i3.View_TabBarLinkComponent_0, i3.RenderType_TabBarLinkComponent)), i0.ɵdid(6, 114688, null, 0, i4.TabBarLinkComponent, [], { link: [0, "link"], indicator: [1, "indicator"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, 0, [" Check-ins "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "../production"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "../check-ins"; var currVal_2 = (i0.ɵunv(_v, 6, 1, i0.ɵnov(_v, 7).transform(_co.eachSurveySubmitted$)) === false); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_AssociateProfileTabsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-associate-profile-tabs", [], null, null, null, View_AssociateProfileTabsComponent_0, RenderType_AssociateProfileTabsComponent)), i0.ɵdid(1, 114688, null, 0, i6.AssociateProfileTabsComponent, [i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssociateProfileTabsComponentNgFactory = i0.ɵccf("app-associate-profile-tabs", i6.AssociateProfileTabsComponent, View_AssociateProfileTabsComponent_Host_0, {}, {}, []);
export { AssociateProfileTabsComponentNgFactory as AssociateProfileTabsComponentNgFactory };
