/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./earnings-summary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../../../shared/components/svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./earnings-summary.component";
var styles_EarningsSummaryComponent = [i0.styles];
var RenderType_EarningsSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EarningsSummaryComponent, data: {} });
export { RenderType_EarningsSummaryComponent as RenderType_EarningsSummaryComponent };
function View_EarningsSummaryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "summary__item summary__item--blue"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-svg-icon", [["class", "summary__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "summary__title"], ["data-cy", "hourlyEarningsTotalTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Hourly Earnings Total "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "summary__currency"], ["data-cy", "hourlyEarningsTotalCurrency"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵppd(7, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.Clock; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), _co.weekPayDetails.hourlyEarningsTotal)); _ck(_v, 6, 0, currVal_1); }); }
function View_EarningsSummaryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "summary__item summary__item--dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-svg-icon", [["class", "summary__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "summary__title"], ["data-cy", "exceptionEarningsTotalTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Exception Earnings Total "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "summary__currency"], ["data-cy", "exceptionEarningsTotalCurrency"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵppd(7, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.TriangleTick; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), _co.weekPayDetails.exceptionEarningsTotal)); _ck(_v, 6, 0, currVal_1); }); }
function View_EarningsSummaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ul", [["class", "summary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "li", [["class", "summary__item summary__item--green"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-svg-icon", [["class", "summary__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(3, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "summary__title"], ["data-cy", "productionEarningsTotalTitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Production Earnings Total "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "summary__currency"], ["data-cy", "productionEarningsTotalCurrency"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵppd(8, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EarningsSummaryComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EarningsSummaryComponent_3)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.Truck; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.weekPayDetails.hourlyEarningsTotal; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.weekPayDetails.exceptionEarningsTotal; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.weekPayDetails.productionEarningsTotal)); _ck(_v, 7, 0, currVal_1); }); }
export function View_EarningsSummaryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EarningsSummaryComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.weekPayDetails; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EarningsSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-earnings-summary", [], null, null, null, View_EarningsSummaryComponent_0, RenderType_EarningsSummaryComponent)), i1.ɵdid(1, 49152, null, 0, i6.EarningsSummaryComponent, [], null, null)], null, null); }
var EarningsSummaryComponentNgFactory = i1.ɵccf("app-earnings-summary", i6.EarningsSummaryComponent, View_EarningsSummaryComponent_Host_0, { weekPayDetails: "weekPayDetails" }, {}, []);
export { EarningsSummaryComponentNgFactory as EarningsSummaryComponentNgFactory };
