import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AppState,
  getIsAppOnline,
  getSurvey,
  selectIsSurveyLoading
} from '../../../reducers';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Survey } from '../../../core/dataEntities/surveys/survey';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { SurveyFormService } from '../../services/surveyForm.service';
import { map, shareReplay } from 'rxjs/operators';
import { SurveysActions } from '../../actions';
import { ButtonType } from '../../../shared/enums/ButtonLink';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { PageHeaderType } from 'src/app/shared/enums/PageHeader';

@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.scss']
})
export class SurveyDetailsComponent implements OnInit {
  subscription: Subscription;

  public surveyForm$: Observable<FormGroup>;
  public isSurveyLoading$: Observable<boolean>;
  public showEmptyState$: Observable<boolean>;
  public isAppOnline$: Observable<boolean>;
  public surveyDetails$: Observable<Survey>;

  form: FormGroup;

  SvgIconNameEnum = SvgIconName;
  ButtonTypeEnum = ButtonType;
  PageHeaderTypeEnum = PageHeaderType;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private formService: SurveyFormService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.isSurveyLoading$ = this.store.select(selectIsSurveyLoading);
    this.surveyDetails$ = this.store.select(getSurvey);
    this.isAppOnline$ = this.store.select(getIsAppOnline);

    this.showEmptyState$ = combineLatest(
      this.surveyDetails$,
      this.isAppOnline$
    ).pipe(
      map(([surveyDetails, isAppOnline]) => {
        return !surveyDetails && !isAppOnline;
      })
    );

    this.surveyForm$ = this.surveyDetails$.pipe(
      map((survey: Survey) => {
        return this.formService.createSurveyForm(survey);
      }),
      shareReplay(1)
    );

    this.subscription = this.route.paramMap.subscribe((params: ParamMap) => {
      return this.store.dispatch(
        SurveysActions.loadSurvey({
          id: params.get('id')
        })
      );
    });
  }

  onSubmit(surveyForm: FormGroup) {
    if (!surveyForm.valid) {
      const invalidControl = this.el.nativeElement.querySelector(
        '.ng-invalid .form-question'
      );

      if (invalidControl) {
        invalidControl.focus();
      }
    }

    if (surveyForm.valid) {
      this.store.dispatch(
        SurveysActions.submitSurvey({
          survey: surveyForm.getRawValue()
        })
      );
    }
  }
}
