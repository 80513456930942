import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageTitleAppearance } from '../../../../../shared/enums/PageTitle';
import { Observable, Subject } from 'rxjs';
import {
  AppState,
  getApexId,
  getHasRoadCrewAccess
} from '../../../../../reducers';
import { Store } from '@ngrx/store';
import {
  selectAllOpportunities,
  selectIsOpportunitiesListLoading,
  selectOpportunitiesListPage,
  selectOpportunitiesListFilter,
  selectOpportunitiesListTotalCount,
  selectOpportunitiesListSort,
  selectIsOpportunitiesFilterSet
} from '../../reducers/opportunities-list.reducer';
import * as OpportunitiesListActions from '../../actions/opportunities-list.actions';
import { OpportunityRow } from '../../view-models/opportunity-row';
import * as OpportunitiesActions from '../../actions/opportunities.actions';
import { OpportunityType } from '../../../../../core/enums/OpportunityType';
import { FilterType } from '../../enums/FilterType';
import { TranslationMessages } from '../../../../../shared/enums/TranslationMessages';
import { TranslationService } from '../../../../../shared/services/translation.service';
import { OpportunityListSortOptions } from '../../../../../core/enums/OpportunityListSortOptions';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-opportunities-list-container',
  templateUrl: './opportunities-list-container.component.html',
  styleUrls: ['./opportunities-list-container.component.scss']
})
export class OpportunitiesListContainerComponent implements OnInit, OnDestroy {
  PageTitleAppearanceEnum = PageTitleAppearance;
  SvgIconNameEnum = SvgIconName;
  public sortOptions: any;
  public showAppLoader$: Observable<boolean>;
  public totalCount$: Observable<number>;
  public opportunitiesList$: Observable<OpportunityRow[]>;
  public opportunitiesListPage$: Observable<any>;
  public filter$: Observable<any>;
  public sort$: Observable<any>;
  public userApexId$: Observable<number>;
  public isOpportunitiesFilterSet$: Observable<boolean>;

  onDestroySubject = new Subject();

  constructor(
    private store: Store<AppState>,
    private translationService: TranslationService,
    private router: Router
  ) {
    this.sortOptions = [
      {
        id: OpportunityListSortOptions.StartDate,
        name: this.translationService.translate(
          TranslationMessages.OpportunityListSortStartDate
        )
      },
      {
        id: OpportunityListSortOptions.Duration,
        name: this.translationService.translate(
          TranslationMessages.OpportunityListSortDuration
        )
      },
      {
        id: OpportunityListSortOptions.Newest,
        name: this.translationService.translate(
          TranslationMessages.OpportunityListSortNewest
        )
      },
      {
        id: OpportunityListSortOptions.Location,
        name: this.translationService.translate(
          TranslationMessages.OpportunityListSortLocation
        )
      }
    ];
  }

  ngOnInit() {
    this.showAppLoader$ = this.store.select(selectIsOpportunitiesListLoading);
    this.totalCount$ = this.store.select(selectOpportunitiesListTotalCount);
    this.opportunitiesList$ = this.store.select(selectAllOpportunities);
    this.opportunitiesListPage$ = this.store.select(
      selectOpportunitiesListPage
    );
    this.filter$ = this.store.select(selectOpportunitiesListFilter);
    this.sort$ = this.store.select(selectOpportunitiesListSort);
    this.userApexId$ = this.store.select(getApexId);
    this.isOpportunitiesFilterSet$ = this.store.select(
      selectIsOpportunitiesFilterSet
    );

    this.store
      .select(getHasRoadCrewAccess)
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(value => {
        if (!value) {
          this.router.navigate(['news']);
        }
      });

    this.store.dispatch(OpportunitiesListActions.loadOpportunitiesList());
  }

  ngOnDestroy() {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  onOpenOpportunity(opportunity) {
    this.store.dispatch(
      OpportunitiesActions.openOpportunity({
        id: opportunity.otherId,
        opportunityId: opportunity.opportunityID,
        opportunityType: OpportunityType.RoadCrew
      })
    );
  }

  onChangePage(page) {
    this.store.dispatch(
      OpportunitiesListActions.changeOpportunitiesListPage({
        pageIndex: page.pageIndex,
        pageSize: page.pageSize
      })
    );
  }

  onFilterChange(filter) {
    this.store.dispatch(
      OpportunitiesListActions.changeOpportunitiesListFilter({ filter })
    );
  }

  onOpenFilter(filterType) {
    switch (filterType) {
      case FilterType.Duration:
        this.store.dispatch(OpportunitiesListActions.openDurationFilter());
        break;
      case FilterType.JobTitle:
        this.store.dispatch(OpportunitiesListActions.openJobTitleFilter());
        break;
      case FilterType.LocationState:
        this.store.dispatch(OpportunitiesListActions.openRegionFilter());
        break;
      default:
        break;
    }
  }

  onClearFilter() {
    this.store.dispatch(OpportunitiesListActions.clearFilters());
  }

  onSortChange(event) {
    this.store.dispatch(
      OpportunitiesListActions.changeOpportunitiesListSort({
        active: event.active,
        direction: event.direction
      })
    );
  }

  onSearchQueryChange(query) {
    this.store.dispatch(
      OpportunitiesListActions.changeOpportunitiesListQueryFilter({ query })
    );
  }
}
