import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { combineLatest, Observable } from 'rxjs';
import {
  selectPage,
  selectPageIsLoading
} from '../../reducers/dynamic-content.reducer';
import { DynamicPage } from '../../../core/dataEntities/dynamicContent/page';
import { ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { DynamicContentActions } from '../../actions';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { RichTextHtmlRendererOptions } from '../../../shared/constants';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss']
})
export class PageContainerComponent implements OnInit {
  private slug: string;
  public PageTitleAppearanceEnum = PageTitleAppearance;
  public isPageLoading$: Observable<boolean>;
  public dynamicPage$: Observable<DynamicPage>;

  constructor(private route: ActivatedRoute, private store: Store<AppState>) {
    this.route.paramMap.pipe(first()).subscribe(params => {
      this.slug = params.get('slug');
      this.store.dispatch(
        DynamicContentActions.loadPage({ slug: params.get('slug') })
      );
    });
  }

  ngOnInit() {
    this.isPageLoading$ = this.store.select(selectPageIsLoading);
    this.dynamicPage$ = combineLatest(
      this.isPageLoading$,
      this.store.select(selectPage, { slug: this.slug })
    ).pipe(
      map(([isPageLoading, page]) => {
        if (isPageLoading) {
          return null;
        }
        return page;
      })
    );
  }

  convertToHtmlString(data: any) {
    return documentToHtmlString(data, RichTextHtmlRendererOptions);
  }
}
