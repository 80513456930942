<div class="qr-code">
  <button
    class="qr-code__button"
    type="button"
    data-cy="qr-code"
    (click)="showQRCode()"
    cdkMonitorElementFocus
  >
    <span
      class="visually-hidden"
      i18n="@@associate-checkin-history-container_showMyQrCode"
    >
      Show my QR code
    </span>
    <app-svg-icon
      [name]="SvgIconNameEnum.QRCode"
      class="qr-code__code-icon"
    ></app-svg-icon>
    <span class="qr-code__expand">
      <app-svg-icon
        [name]="SvgIconNameEnum.ExpandArrows"
        class="qr-code__expand-icon"
      ></app-svg-icon>
    </span>
  </button>
</div>
