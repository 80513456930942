import { Routes } from '@angular/router';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { ResourcesContainerComponent } from './containers/resources-container/resources-container.component';
import { ResourcesComponent } from './resources.component';
import { TeamDirectoryComponent } from './components/team-directory/team-directory.component';
import { StarsFeedContainerComponent } from './containers/stars-feed/stars-feed-container.component';
import { BlogPostComponent } from '../shared/components/feed/blog-post/blog-post.component';
import { FeedType } from '../shared/enums/FeedType';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
const ɵ0 = {
    showMenuBar: true,
    pageTitle: TranslationMessages.ResourcesTitle
}, ɵ1 = {
    pageTitle: TranslationMessages.ResourcesTitle,
    siteMapKey: SiteMapItemKeys.Resources
}, ɵ2 = {
    pageTitle: TranslationMessages.ResourcesTeamDirectoryTitle,
    siteMapKey: SiteMapItemKeys.ResourcesTeam
}, ɵ3 = {
    showMenuBar: false,
    pageTitle: TranslationMessages.ResourcesStarsTitle,
    siteMapKey: SiteMapItemKeys.ResourcesStars
}, ɵ4 = {
    feedType: FeedType.Stars,
    pageTitle: TranslationMessages.ResourcesStarsTitle,
    siteMapKey: SiteMapItemKeys.ResourcesStar
};
const routes = [
    {
        path: 'resources',
        component: ResourcesComponent,
        canActivate: [AuthenticationGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: ResourcesContainerComponent,
                data: ɵ1
            },
            {
                path: 'team-directory',
                component: TeamDirectoryComponent,
                data: ɵ2
            },
            {
                path: 'stars',
                component: StarsFeedContainerComponent,
                data: ɵ3
            },
            {
                path: 'stars/:id',
                component: BlogPostComponent,
                data: ɵ4
            }
        ]
    }
];
export class ResourcesRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
