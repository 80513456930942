import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ComponentRef,
  EmbeddedViewRef, EventEmitter,
  HostBinding, HostListener, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { infoBoxAnimations } from './info-box.animations';
import {AnimationEvent} from '@angular/animations';
@Component({
  selector: 'app-info-box-container',
  templateUrl: 'info-box-container.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [infoBoxAnimations.infoBoxSheetState],
})
export class InfoBoxContainerComponent extends BasePortalOutlet {
  @HostBinding('@infoBoxContainer') get infoBoxContainer() { return this.state; }

  state: 'void' | 'enter' | 'exit' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();
  id: string;

  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;

  constructor(
    private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this.portalOutlet.hasAttached()) {
      throw Error('Component Portal already attached');
    }

    return this.portalOutlet.attachComponentPortal(portal);
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    if (this.portalOutlet.hasAttached()) {
      throw Error('Template Portal already attached');
    }

    return this.portalOutlet.attachTemplatePortal(portal);
  }

  @HostListener('@infoBoxContainer.done', ['$event'])
  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  @HostListener('@infoBoxContainer.start', ['$event'])
  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation(): void {
    this.state = 'exit';
    this.changeDetectorRef.markForCheck();
  }
}
