/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-link-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i4 from "../../../shared/components/svg-icon/svg-icon.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/router";
import * as i7 from "./profile-link-item.component";
var styles_ProfileLinkItemComponent = [i0.styles];
var RenderType_ProfileLinkItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileLinkItemComponent, data: {} });
export { RenderType_ProfileLinkItemComponent as RenderType_ProfileLinkItemComponent };
function View_ProfileLinkItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
function View_ProfileLinkItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ProfileLinkItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "profile-link-item__title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileLinkItemComponent_4)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.titleTemplate ? _co.titleTemplate : i1.ɵnov(_v.parent.parent, 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProfileLinkItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-svg-icon", [["class", "profile-link-item__icon"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(1, 638976, null, 0, i4.SvgIconComponent, [i5.DomSanitizer], { name: [0, "name"], colorPrimary: [1, "colorPrimary"], colorSecondary: [2, "colorSecondary"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileLinkItemComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; var currVal_1 = _co.iconColorPrimary; var currVal_2 = _co.iconColorSecondary; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.title || _co.titleTemplate); _ck(_v, 3, 0, currVal_3); }, null); }
function View_ProfileLinkItemComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ProfileLinkItemComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onLinkClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "profile-link-item": 0, "profile-link-item--red": 1 }), i1.ɵdid(4, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileLinkItemComponent_6)), i1.ɵdid(7, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, true, (_co.appearance === _co.ProfileLinkItemAppearanceEnum.Red)); _ck(_v, 2, 0, currVal_2); var currVal_3 = _ck(_v, 5, 0, _co.link); _ck(_v, 4, 0, currVal_3); var currVal_4 = i1.ɵnov(_v.parent, 1); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ProfileLinkItemComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ProfileLinkItemComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickButton($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "profile-link-item": 0, "profile-link-item--red": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileLinkItemComponent_8)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, (_co.appearance === _co.ProfileLinkItemAppearanceEnum.Red)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v.parent, 1); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ProfileLinkItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["defaultTitleTemplate", 2]], null, 0, null, View_ProfileLinkItemComponent_1)), (_l()(), i1.ɵand(0, [["item", 2]], null, 0, null, View_ProfileLinkItemComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileLinkItemComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileLinkItemComponent_7)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.link; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ProfileLinkItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-link-item", [], null, null, null, View_ProfileLinkItemComponent_0, RenderType_ProfileLinkItemComponent)), i1.ɵdid(1, 114688, null, 0, i7.ProfileLinkItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileLinkItemComponentNgFactory = i1.ɵccf("app-profile-link-item", i7.ProfileLinkItemComponent, View_ProfileLinkItemComponent_Host_0, { title: "title", titleTemplate: "titleTemplate", link: "link", iconName: "iconName", iconColorPrimary: "iconColorPrimary", iconColorSecondary: "iconColorSecondary", appearance: "appearance" }, { buttonClick: "buttonClick", linkClick: "linkClick" }, []);
export { ProfileLinkItemComponentNgFactory as ProfileLinkItemComponentNgFactory };
