import { OpportunityRow } from '../view-models/opportunity-row';
import { createDataTableActions } from '../../../../shared/data-table';
import { createAction, props } from '@ngrx/store';

const entity = 'Opportunities List';

const {
  loadItems,
  itemsLoaded,
  changePage,
  changeSort,
  changeFilter
} = createDataTableActions<OpportunityRow>(entity);

export const loadOpportunitiesList = loadItems;
export const opportunitiesListLoaded = itemsLoaded;
export const changeOpportunitiesListFilter = changeFilter;
export const changeOpportunitiesListSort = changeSort;
export const changeOpportunitiesListPage = changePage;

export const toggleOpportunitiesListLoading = createAction(
  '[Opportunities List] Opportunities List Loading',
  props<{ isLoading: boolean }>()
);

export const openDurationFilter = createAction(
  '[Opportunities List] Open duration filter'
);

export const openJobTitleFilter = createAction(
  '[Opportunities List] Open job title filter'
);

export const openRegionFilter = createAction(
  '[Opportunities List] Open region filter'
);

export const clearFilters = createAction('[Opportunities List] Clear filters');

export const changeOpportunitiesListQueryFilter = createAction(
  '[Opportunities List] Change query filter',
  props<{ query: string }>()
);
