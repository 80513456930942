/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./licence-readonly.component";
import * as i2 from "../../../../../../shared/services/translation.service";
var styles_LicenceReadonlyComponent = [];
var RenderType_LicenceReadonlyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LicenceReadonlyComponent, data: {} });
export { RenderType_LicenceReadonlyComponent as RenderType_LicenceReadonlyComponent };
export function View_LicenceReadonlyComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "read-only-details"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [["class", "read-only-details"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.formGroup.get("isDriver").value ? "Available as a driver" : "Not available as a driver"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.formGroup.get("isDriver").value ? (_co.formGroup.get("documents").value ? "Driver License included" : "Driver License not included") : (_co.formGroup.get("typeOfID").value ? (_co.translationService.translate(_co.formGroup.get("typeOfID").value) + " details included") : "ID details not included")); _ck(_v, 3, 0, currVal_1); }); }
export function View_LicenceReadonlyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-licence-readonly", [], null, null, null, View_LicenceReadonlyComponent_0, RenderType_LicenceReadonlyComponent)), i0.ɵdid(1, 114688, null, 0, i1.LicenceReadonlyComponent, [i2.TranslationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LicenceReadonlyComponentNgFactory = i0.ɵccf("app-licence-readonly", i1.LicenceReadonlyComponent, View_LicenceReadonlyComponent_Host_0, { formGroup: "formGroup" }, {}, []);
export { LicenceReadonlyComponentNgFactory as LicenceReadonlyComponentNgFactory };
