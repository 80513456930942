/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-header-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./profile-header-container.component";
import * as i4 from "@ngrx/store";
var styles_ProfileHeaderContainerComponent = [i0.styles];
var RenderType_ProfileHeaderContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileHeaderContainerComponent, data: {} });
export { RenderType_ProfileHeaderContainerComponent as RenderType_ProfileHeaderContainerComponent };
function View_ProfileHeaderContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "profile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "profile__name"], ["data-cy", "profileName"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.userName$)); _ck(_v, 2, 0, currVal_0); }); }
export function View_ProfileHeaderContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProfileHeaderContainerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.isUserLoggedIn$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProfileHeaderContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-header-container", [], null, null, null, View_ProfileHeaderContainerComponent_0, RenderType_ProfileHeaderContainerComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProfileHeaderContainerComponent, [i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileHeaderContainerComponentNgFactory = i1.ɵccf("app-profile-header-container", i3.ProfileHeaderContainerComponent, View_ProfileHeaderContainerComponent_Host_0, {}, {}, []);
export { ProfileHeaderContainerComponentNgFactory as ProfileHeaderContainerComponentNgFactory };
