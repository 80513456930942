import { Component, Input, OnInit } from '@angular/core';
import { SafetyObservation } from '../../../../../core/dataEntities/profile/safetyProfileDetails';

@Component({
  selector: 'app-production-details-observations',
  templateUrl: './production-details-observations.component.html'
})
export class ProductionDetailsObservationsComponent implements OnInit {
  @Input() safetyObservation: SafetyObservation[] = [];

  constructor() {}

  ngOnInit() {}
}
