import { Component, OnInit } from '@angular/core';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { combineLatest, Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  getUserName,
  getApexId,
  selectWeeklyCheckIns,
  selectIsUserTrainee,
  getIsAppOnline,
  selectUserPhoto
} from '../../../reducers';
import { AppState } from '../../../reducers';
import { ProfileActions } from '../../actions';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';

import {
  WeeklyCheckinList,
  WeeklyCheckinListItem
} from '../../../core/dataEntities/profile/weeklyCheckinList';
import {
  selectIsWeeklyCheckinLoading,
  selectLastCheckIn,
  selectWeeklyCheckinList
} from '../../reducers/profile.reducer';

import { ButtonColor } from '../../../shared/enums/ButtonLink';
import { map } from 'rxjs/operators';
import { Features } from '../../../core/enums/Features';

@Component({
  selector: 'app-associate-check-in-history',
  templateUrl: './associate-check-in-history.component.html',
  styleUrls: ['./associate-check-in-history.component.scss']
})
export class AssociateCheckInHistoryComponent implements OnInit {
  onDestroySubject = new Subject();
  public SvgIconNameEnum = SvgIconName;
  public PageTitleAppearanceEnum = PageTitleAppearance;
  public ButtonColorEnum = ButtonColor;
  public Features = Features;

  userName$: Observable<string>;
  userApexId$: Observable<number>;
  isTrainee$: Observable<boolean>;
  isWeeklyCheckinLoading$: Observable<boolean>;
  weeklyCheckinList$: Observable<WeeklyCheckinList>;
  lastWeeklyCheckin$: Observable<WeeklyCheckinListItem>;
  weeklyCheckIns$: Observable<any>;
  showEmptyState$: Observable<boolean>;
  isAppOnline$: Observable<boolean>;
  photo$: Observable<string>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store.dispatch(ProfileActions.loadWeeklyCheckinList());
    this.isAppOnline$ = this.store.select(getIsAppOnline);
    this.userName$ = this.store.pipe(select(getUserName));
    this.userApexId$ = this.store.pipe(select(getApexId));
    this.isTrainee$ = this.store.pipe(select(selectIsUserTrainee));
    this.isWeeklyCheckinLoading$ = this.store.pipe(
      select(selectIsWeeklyCheckinLoading)
    );
    this.weeklyCheckIns$ = this.store.pipe(select(selectWeeklyCheckIns));
    this.weeklyCheckinList$ = this.store.pipe(select(selectWeeklyCheckinList));
    this.lastWeeklyCheckin$ = this.store.pipe(select(selectLastCheckIn));
    this.photo$ = this.store.pipe(select(selectUserPhoto));

    this.showEmptyState$ = combineLatest(
      this.weeklyCheckinList$,
      this.isAppOnline$
    ).pipe(
      map(([weeklyCheckinList, isAppOnline]) => {
        return !weeklyCheckinList && !isAppOnline;
      })
    );
  }
}
