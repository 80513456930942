<app-earnings-header
  [isFutureProcessing]="isFutureProcessing$ | async"
  [isHistoricalEarning]="isHistoricalEarning$ | async"
  [weekPayDetails]="weekPayDetails$ | async"
></app-earnings-header>
<main class="main-container pay">
  <app-no-internet
    *ngIf="showEmptyState$ | async"
    i18n-message="@@pay_backToEarningHistory"
    message="Go back to Earnings History"
    [link]="'/wages'"
  ></app-no-internet>

  <app-earnings-summary
    [weekPayDetails]="weekPayDetails$ | async"
  ></app-earnings-summary>

  <app-earnings-tabs
    *ngIf="(isPayLoading$ | async) === false && (goalDetails$ | async)"
    class="pay__tabs"
    [isHistoricalEarning]="isHistoricalEarning$ | async"
    [isTrainee]="isTrainee$ | async"
    [goal]="(goalDetails$ | async).goal"
  ></app-earnings-tabs>

  <app-loader [isLoading]="isPayLoading$ | async"></app-loader>

  <ng-container *ngIf="weekPayDetails$ | async as weekPayDetails">
    <ng-template #dailyEarningsBreakdown i18n="@@pay_dailyEarningsBreakdown">
      Daily Earnings Breakdown
    </ng-template>
    <ng-template #noEarningsThisWeek i18n="@@pay_noEarningsThisWeek">
      No Earnings This Week
    </ng-template>
    <app-page-title
      data-cy="dailyEarningsBreakdownHeading"
      [appearance]="PageTitleAppearanceEnum.Transparent"
      [titleTemplate]="
        weekPayDetails.dayEarningsDetails.length
          ? dailyEarningsBreakdown
          : noEarningsThisWeek
      "
    >
      <app-page-subtitle data-cy="lastUpdatedSection">
        <span i18n="@@pay_lastUpdated">
          Last updated
        </span>
        : {{ weekPayDetails.lastUpdatedAtDate | date: 'EEEE, MMMM dd' }}
      </app-page-subtitle>
      <app-page-subtitle data-cy="earningsDescriptionSection">
        <ng-container
          *ngIf="weekPayDetails.dayEarningsDetails.length"
          i18n="@@pay_dailyEarningsBreakdownSubtitle"
        >
          All earnings are estimated and are subject to change prior to payday.
          Displayed earnings are estimations and not promise of final pay.
        </ng-container>
        <ng-container
          *ngIf="!weekPayDetails.dayEarningsDetails.length"
          i18n="@@pay_noEarningsThisWeekSubtitle"
        >
          Our records show that there is no history of work completed on this
          week.
        </ng-container>
      </app-page-subtitle>
    </app-page-title>

    <div
      *ngIf="weekPayDetails.dayEarningsDetails.length"
      class="pay__container"
    >
      <app-accordion>
        <app-accordion-panel
          *ngFor="let dayEarning of weekPayDetails.dayEarningsDetails"
          [title]="dayEarning.date | date: 'EEEE MM/dd'"
          [subtitle]="
            dayEarning.newCaliforniaPayModelUsed
              ? dayEarning.totalExceptionDailyEarnings > 0 ||
                dayEarning.totalHourlyDailyEarnings > 0
                ? (dayEarning.totalExceptionDailyEarnings +
                    dayEarning.totalHourlyDailyEarnings | currency)
                : ''
              : (dayEarning.totalDailyEarnings | currency)
          "
          [bodySpacing]="false"
          [analyticsId]="AnalyticsEventsEnum.WeekDayPayDetails"
          [analyticsLabel]="dayEarning.date | date: 'EEEE'"
        >
          <app-earnings-breakdown-table
            slot="body"
            [dayEarnings]="dayEarning"
          ></app-earnings-breakdown-table>
        </app-accordion-panel>
      </app-accordion>
      <app-footer>
        <app-footer-note
          data-cy="endOfEarningsHistoryThisWeek"
          i18n="@@pay_endOfEarnings"
        >
          End of earnings history this week
        </app-footer-note>
      </app-footer>
    </div>
    <ng-template #emptyStateTranslation i18n="@@pay_noDataFound">
      Think there was an error? Contact us: help@capstonelogistics.com
    </ng-template>

    <app-empty-state
      *ngIf="!weekPayDetails.dayEarningsDetails.length"
      [emptyStateTemplate]="emptyStateTranslation"
    ></app-empty-state>
  </ng-container>
</main>
