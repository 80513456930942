<div class="sort-search">
  <label
    class="sort-search__label"
    for="sort-select"
    i18n="@@sort-search_sort-search-label"
  >
    {{ !isSearchOpen ? 'Sort by' : 'Search by City or Skillset Name' }}
  </label>
  <div [@openCloseSort]="!isSearchOpen" class="sort-search__sort sort">
    <button class="sort__button" type="button" (click)="toggleSortDirection()">
      <app-svg-icon
        class="sort__button-icon"
        [name]="
          sort?.direction === SortDirectionsEnum.Desc
            ? SvgIconNameEnum.SortDescending
            : SvgIconNameEnum.SortAscending
        "
      ></app-svg-icon>
      <span class="visually-hidden" i18n="@@sort-search_sortByTitle">
        sort by {{ activeSortName }} in
        {{
          sort?.direction === SortDirectionsEnum.Desc
            ? 'ascending'
            : 'descending'
        }}
        order
      </span>
    </button>
    <select
      class="sort__select"
      id="sort-select"
      [ngModel]="sort?.active"
      (ngModelChange)="onSortByChange($event)"
    >
      <option *ngFor="let so of sortByOptions" [ngValue]="so.id">
        {{ so.name }}
      </option>
    </select>
    <button class="sort__open-search" type="button" (click)="openSearch()">
      <app-svg-icon
        class="sort__open-search-icon"
        [name]="SvgIconNameEnum.Search"
      ></app-svg-icon>
      <span class="visually-hidden" i18n="@@sort-search_openSearch">
        open search
      </span>
    </button>
  </div>
  <div
    [@openCloseSearch]="isSearchOpen"
    class="sort-search__search search"
    role="search"
    [attr.hidden]="isSearchOpen ? null : 'true'"
  >
    <app-svg-icon
      class="search__icon"
      [name]="SvgIconNameEnum.Search"
    ></app-svg-icon>
    <input
      #searchInput
      type="search"
      class="search__input"
      [ngModel]="searchQuery"
      (ngModelChange)="onSearchQueryChange($event)"
      [attr.tabindex]="isSearchOpen ? '0' : '-1'"
    />
    <button
      type="button"
      class="search__button"
      (click)="closeSearch()"
      [attr.tabindex]="isSearchOpen ? '0' : '-1'"
    >
      <app-svg-icon
        class="search__button-icon"
        [name]="SvgIconNameEnum.Close"
      ></app-svg-icon>
      <span class="visually-hidden" i18n="@@sort-search_closeSearch">
        close search
      </span>
    </button>
  </div>
</div>
