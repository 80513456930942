<main>
  <app-page-title
    title="COVID 19"
    i18n-title="@@covid-container_title"
    [appearance]="PageTitleAppearanceEnum.Warning"
  >
    <app-page-subtitle i18n="@@covid-container_subtitle">
      Workplace Safety
    </app-page-subtitle>
  </app-page-title>
  <div class="covid">
    <app-menu>
      <app-menu-item
        title="Workplace Guidelines"
        i18n-title="@@covid-container_workplace-guidelines-title"
        subTitle="How to work safely during Covid-19"
        i18n-subTitle="@@covid-container_safety-subTitle"
        [link]="['/content', 'covid_workplace_guidelines']"
        [iconName]="SvgIconNameEnum.Mask"
        [iconColorPrimary]="SvgIconColorEnum.Marigold"
        [iconColorSecondary]="SvgIconColorEnum.SweetCorn"
      ></app-menu-item>
      <app-menu-item
        title="Check Your Symptoms"
        i18n-title="@@covid-container_symptoms-title"
        subTitle="CDC guidelines and symptom checker"
        i18n-subTitle="@@covid-container_symptoms-subTitle"
        [link]="
          'https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html'
        "
        [isExternalUrl]="true"
        [iconName]="SvgIconNameEnum.PersonWSymptoms"
        [iconColorPrimary]="SvgIconColorEnum.Watercourse"
        [iconColorSecondary]="SvgIconColorEnum.MintyFog"
      ></app-menu-item>
      <app-menu-item
        title="COVID-19 Vaccine"
        i18n-title="@@covid-container_vaccine-title"
        subTitle="Frequently Asked Questions"
        i18n-subTitle="@@covid-container_vaccine-subTitle"
        [link]="['/content', 'covid_vaccine']"
        [iconName]="SvgIconNameEnum.PersonWQuestionMark"
        [iconColorPrimary]="SvgIconColorEnum.Marigold"
        [iconColorSecondary]="SvgIconColorEnum.SweetCorn"
      ></app-menu-item>
    </app-menu>
  </div>
</main>
