<textarea
  [ngClass]="{
    textarea: true,
    'textarea--invalid': isInvalid
  }"
  [attr.id]="id"
  [formControl]="formControl"
  [attr.aria-invalid]="isInvalid"
  [attr.aria-describedby]="isInvalid ? errorId : null"
  [attr.disabled]="disabled ? '' : null"
  placeholder="{{ placeholder }}"
></textarea>
<span class="max-value" *ngIf="maxLength">
  {{ this.formControl.value.toString().length }} / {{ maxLength }}
</span>
