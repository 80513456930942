import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageHeaderAppearance, PageHeaderType } from '../../enums/PageHeader';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { Observable } from 'rxjs';
import { selectNavigationBackUrl } from '../../../core/reducers/navigation.reducer';

@Component({
  selector: 'app-page-header-container',
  templateUrl: 'page-header-container.component.html'
})
export class PageHeaderContainerComponent implements OnInit {
  @Input() type: PageHeaderType = PageHeaderType.Back;
  @Input() appearance: PageHeaderAppearance = PageHeaderAppearance.Blue;
  @Input() isHeader = true;
  @Output() isClicked = new EventEmitter();

  backUrl$: Observable<string>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.backUrl$ = this.store.select(selectNavigationBackUrl);
  }
}
