import { Component, OnInit, Input } from '@angular/core';
import { SvgIconName } from '../../../enums/SvgIcon';
import {
  PostCategoryType,
  PostSafetyTipType
} from '../../../../news/enums/PostTypes';

@Component({
  selector: 'app-post-image',
  templateUrl: './post-image.component.html',
  styleUrls: ['./post-image.component.scss']
})
export class PostImageComponent implements OnInit {
  @Input() image: string;
  @Input() title: string;
  @Input() type: PostCategoryType;
  @Input() subtype?: any;
  @Input() postId?: string;
  SvgIconNameEnum = SvgIconName;
  PostCategoryTypeEnum = PostCategoryType;
  PostSafetyTipTypeEnum = PostSafetyTipType;

  get imageUrl() {
    if (!this.image) {
      return;
    }
    return `${this.image}?fm=jpg&w=1100&q=85`;
  }

  constructor() {}

  ngOnInit() {}
}
