<main class="main-container">
  <app-associate-page-header
    [apexId]="userApexId$ | async"
    [userName]="userName$ | async"
    [isTrainee]="isTrainee$ | async"
    [photoSrc]="photo$ | async"
    [isAssociateView]="true"
  ></app-associate-page-header>

  <div class="associate-check-in">
    <app-associate-profile-tabs></app-associate-profile-tabs>

    <app-loader [isLoading]="isWeeklyCheckinLoading$ | async"></app-loader>

    <app-surveys *appFeatureToggle="Features.Surveys"></app-surveys>

    <app-no-internet
      *ngIf="showEmptyState$ | async"
      i18n-message="@@associate-check-in_backToNews"
      message="Go back to News"
      [link]="'/news'"
    ></app-no-internet>

    <ng-container
      *ngIf="
        (isWeeklyCheckinLoading$ | async) === false &&
        (weeklyCheckinList$ | async) != null
      "
    >
      <ng-container *ngIf="weeklyCheckinList$ | async as weeklyCheckinList">
        <div class="associate-check-in__reminder">
          <app-card-layout
            *ngIf="
              (weeklyCheckIns$ | async).currentWeek === true ||
              (weeklyCheckIns$ | async).pastWeek === true
            "
          >
            <app-card-layout-item>
              <app-page-section-header
                *ngIf="(weeklyCheckIns$ | async).currentWeek === true"
                title="Complete this week's check-in"
                i18n-title="@@associate-checkin-history_completeThisWeekCheckin"
              ></app-page-section-header>
              <app-page-section-header
                *ngIf="(weeklyCheckIns$ | async).pastWeek === true"
                title="Complete last week Check-In"
                i18n-title="@@associate-checkin-history_completeLastWeekCheckin"
              ></app-page-section-header>
              <p
                *ngIf="(weeklyCheckIns$ | async).currentWeek === true"
                i18n="@@associate-checkin-history_dontForgetCurrentWeek"
              >
                Don't forget to let your manager know how your week went!
              </p>
              <p
                *ngIf="(weeklyCheckIns$ | async).pastWeek === true"
                i18n="@@associate-checkin-history-container_dontForgetPastWeek"
              >
                You skipped your check in on
                {{ (weeklyCheckIns$ | async).pastWeekDate | date: 'MM/dd' }}.
                Don't forget to let your manager know how last week went!
              </p>
              <app-button-link
                class="associate-check-in__reminder-button"
                [link]="[
                  '/check-in',
                  (weeklyCheckIns$ | async).pastWeek
                    ? (weeklyCheckIns$ | async).pastWeekDate
                    : (weeklyCheckIns$ | async).currentWeekDate
                ]"
                [color]="ButtonColorEnum.Green"
                i18n-label="@@associate-checkin-history_completeCheckIn"
                label="Complete Check-In"
              ></app-button-link>
            </app-card-layout-item>
          </app-card-layout>
        </div>

        <app-check-in-history
          [lastWeeklyCheckin]="lastWeeklyCheckin$ | async"
          [userName]="userName$ | async"
          [weeklyCheckinList]="weeklyCheckinList"
        ></app-check-in-history>
      </ng-container>
    </ng-container>
  </div>
</main>
