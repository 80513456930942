import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
  ViewContainerRef,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { File as DataFile } from '../../../core/dataEntities/shared/file';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';
import { LayoutActions } from '../../../core/actions';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from '../../services/translation.service';
import { TranslationMessages } from '../../enums/TranslationMessages';

let nextUniqueId = 0;

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html'
})
export class FileUploaderComponent implements OnInit, AfterViewInit, OnDestroy {
  private fileReader: FileReader;
  private fileLoading: File;
  private fileLoadingIndex: number;
  private onDestroySubject = new Subject();
  protected defaultId = `file-input-${++nextUniqueId}`;

  @Input() buttonTemplate: TemplateRef<any>;
  @Input() buttonLabel: string;
  @Input() previewTemplate: TemplateRef<any>;
  @Input() errorTemplate: TemplateRef<any>;
  @Input() multiple = false;
  @Input() fileType: string;
  @Input() uploadedFiles: DataFile[];
  @Output() filesUploaded = new EventEmitter();

  public files: DataFile[];
  public previewModeSubject = new BehaviorSubject(false);
  public errorModeSubject = new BehaviorSubject(false);
  private fileUploadedSubject = new BehaviorSubject(null);
  public focused = false;

  @ViewChild('uploader', { static: false }) public uploader: ElementRef;
  @ViewChild('defaultButtonTemplate', { read: TemplateRef, static: false })
  public defaultButtonTemplate: TemplateRef<any>;
  @ViewChild('defaultPreviewTemplate', {
    read: TemplateRef,
    static: false
  })
  public defaultPreviewTemplate: TemplateRef<any>;
  @ViewChild('defaultErrorTemplate', { read: TemplateRef, static: false })
  public defaultErrorTemplate: TemplateRef<any>;
  @ViewChild('templateContainer', { read: ViewContainerRef, static: false })
  public container: ViewContainerRef;

  get inputId(): string {
    return this.defaultId;
  }

  constructor(
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    this.fileReader = new FileReader();
    this.fileReader.addEventListener(
      'load',
      this.onFileLoaded.bind(this),
      false
    );
    this.fileReader.addEventListener('error', this.onFileLoadingError);
    if (this.uploadedFiles) {
      this.files = Array.isArray(this.uploadedFiles)
        ? this.uploadedFiles
        : [this.uploadedFiles];
      this.previewModeSubject.next(true);
    }
    combineLatest(
      this.previewModeSubject,
      this.errorModeSubject,
      this.fileUploadedSubject
    )
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(() => {
        this.setTemplate();
      });
  }

  ngAfterViewInit() {
    this.setTemplate();
  }

  manageFocus(value) {
    this.focused = value;
    this.setTemplate();
  }

  setTemplate() {
    if (this.container) {
      this.container.clear();
      this.container.createEmbeddedView(
        this.getTemplate(),
        {
          $implicit: {
            files: this.files,
            multiple: this.multiple,
            openUploadPrompt: this.openUploadPrompt.bind(this),
            focused: this.focused
          }
        },
        0
      );
      this.cdr.detectChanges();
    }
  }

  getTemplate() {
    if (this.errorModeSubject.value) {
      return this.errorTemplate
        ? this.errorTemplate
        : this.defaultErrorTemplate;
    } else if (this.previewModeSubject.value) {
      return this.previewTemplate
        ? this.previewTemplate
        : this.defaultPreviewTemplate;
    } else {
      return this.buttonTemplate
        ? this.buttonTemplate
        : this.defaultButtonTemplate;
    }
  }

  openUploadPrompt() {
    this.uploader.nativeElement.focus();
    this.uploader.nativeElement.click();
  }

  onUpload() {
    this.files = [];
    this.fileLoadingIndex = 0;
    this.fileLoading = this.uploader.nativeElement.files[
      this.fileLoadingIndex++
    ];
    if (this.fileLoading && this.checkFileAvailability(this.fileLoading)) {
      this.fileReader.readAsDataURL(this.fileLoading);
      this.errorModeSubject.next(false);
    } else {
      this.errorModeSubject.next(true);
    }
  }

  onFileLoaded() {
    if (this.fileLoading.size > 2000000) {
      this.resizeImage(this.fileReader.result.toString(), 1000, 1000).then(
        imgResized => {
          this.completeFileUpload(imgResized);
        }
      );
    } else {
      this.completeFileUpload(this.fileReader.result.toString());
    }
  }

  completeFileUpload(imgStr: string) {
    this.files.push({
      name: this.fileLoading.name,
      size: this.fileLoading.size,
      type: this.fileLoading.type,
      data: imgStr,
      modifiedDate: new Date(this.fileLoading.lastModified)
    });
    this.fileUploadedSubject.next(this.fileLoading.name);
    if (
      !this.multiple ||
      this.uploader.nativeElement.files.length === this.fileLoadingIndex
    ) {
      this.previewModeSubject.next(true);
      this.filesUploaded.emit(this.files);
    } else {
      this.fileLoading = this.uploader.nativeElement.files[
        this.fileLoadingIndex++
      ];
      this.fileReader.readAsDataURL(this.fileLoading);
    }
  }

  checkFileAvailability(file: File) {
    if (file) {
      return this.fileType.split(',').indexOf(file.type) !== -1;
    }
  }

  onFileLoadingError(err) {
    this.store.dispatch(
      LayoutActions.showError({
        message: this.translationService.translate(
          TranslationMessages.ErrorMessagesAssociateNotFound
        ),
        error: err
      })
    );
  }

  ngOnDestroy(): void {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  private resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
    return new Promise<string>((resolve, reject) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
      img.onerror = error => reject(error);
    });
  }
}
