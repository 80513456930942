import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { SidebarModule } from 'ng-sidebar';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';

import { SharedComponentsModule } from '../shared/components/shared-components/shared-components.module';
import { MyTeamComponent } from './my-team.component';
import { MyTeamRoutingModule } from './my-team-routing.module';
import * as fromMyTeam from './reducers/my-team.reducer';
import { MyTeamEffects } from './effects/my-team.effects';
import { MyTeamMembersComponent } from './containers/my-team-members/my-team-members.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MyTeamMemberCheckInComponent } from './containers/my-team-member-check-in/my-team-member-check-in.component';
import { MyTeamMemberProductionComponent } from './containers/my-team-member-production/my-team-member-production.component';
import { TeamMemberTabsComponent } from './components/team-member-tabs/team-member-tabs.component';
import { ManageAssociatePhotoComponent } from './components/manage-associate-photo/manage-associate-photo.component';
import { DirectivesModule } from '../core/directives/directives.module';

@NgModule({
  declarations: [
    MyTeamComponent,
    MyTeamMembersComponent,
    MyTeamMemberProductionComponent,
    MyTeamMemberCheckInComponent,
    TeamMemberTabsComponent,
    ManageAssociatePhotoComponent
  ],
  imports: [
    SharedComponentsModule,
    MyTeamRoutingModule,
    ScrollingModule,
    SidebarModule.forRoot(),
    StoreModule.forFeature(fromMyTeam.myTeamFeatureKey, fromMyTeam.reducer),
    EffectsModule.forFeature([MyTeamEffects]),
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule
  ],
  providers: [],
  exports: [],
  entryComponents: [ManageAssociatePhotoComponent]
})
export class MyTeamModule {}
