import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '../../../shared/modules/modal/dialog-config';
import qrcode from 'qrcode-generator';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {
  public innerHtml: any;

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private sanitized: DomSanitizer
  ) {}

  ngOnInit() {
    const qr = qrcode(0, 'M');
    qr.addData(
      `${this.data.apexId.toString()};${moment()
        .utc()
        .toISOString()}`
    );
    qr.make();
    this.innerHtml = this.sanitized.bypassSecurityTrustHtml(
      qr.createSvgTag(1, 1)
    );
  }
}
