import {AbstractControl, ValidatorFn} from '@angular/forms';

export function PrecisionValidator(precision): ValidatorFn {
  return (control: AbstractControl): any => {
    if (!control.value) {
      return null;
    }
    const precisionRegexpString = `^\\d*(?:[.,]\\d{0,${precision}})?$`;
    const PRECISION_REGEXP = new RegExp(precisionRegexpString);
    if (PRECISION_REGEXP.test(control.value)) {
      return null;
    }
    return {
      priceInvalid: true
    };
  };
}
