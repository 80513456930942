import { createDataTableActions } from '../../shared/data-table';
import { TeamMemberRow } from '../view-models/team-member-record';
import { createAction, props } from '@ngrx/store';
import { WeeklyCheckinList } from '../../core/dataEntities/profile/weeklyCheckinList';
import { AssociateProductionDetails } from '../../core/dataEntities/associate/associateProductionDetails';
import { TeamMemberInfo } from '../view-models/team-member-details';

const entity = 'My Team';

const {
  loadItems,
  itemsLoaded,
  changePage,
  changeSort,
  changeFilter
} = createDataTableActions<TeamMemberRow>(entity);

export const loadMyTeam = loadItems;
export const myTeamLoaded = itemsLoaded;
export const changeMyTeamFilter = changeFilter;
export const changeMyTeamSort = changeSort;
export const changeMyTeamPage = changePage;

export const toggleMyTeamIsLoading = createAction(
  '[My Team Component] My Team Loading',
  props<{ isLoading: boolean }>()
);

export const toggleTeamMemberProductionIsLoading = createAction(
  '[My Team Component] My Team Member Production Is Loading',
  props<{ isLoading: boolean }>()
);

export const changeSiteFilter = createAction(
  '[My Team Component] Change Site Filter',
  props<{ site: number }>()
);

export const changeQueryFilter = createAction(
  '[My Team Component] Change Query Filter',
  props<{ query: number }>()
);

export const myTeamMemberDetailsLoaded = createAction(
  '[My Team Component] My Team Member Data loaded',
  props<{
    id: string;
    apexId: number;
    name: string;
    isTrainee: boolean;
    profilePhoto: string;
  }>()
);

export const loadTeamMemberCheckIns = createAction(
  '[My Team Component] Load My Team Member Check-Ins',
  props<{ associateId: string }>()
);

export const toggleTeamMemberCheckInsIsLoading = createAction(
  '[My Team Component] My Team Member Check-Ins Is Loading',
  props<{ isLoading: boolean }>()
);

export const teamMemberCheckInsLoaded = createAction(
  '[My Team Component] Team Member Check-Ins Loaded',
  props<{
    associateId: string;
    weeklyCheckinList: WeeklyCheckinList;
  }>()
);

export const loadTeamMemberProduction = createAction(
  '[My Team Component] Load My Team Member Production',
  props<{ associateId: string }>()
);

export const teamMemberProductionLoaded = createAction(
  '[My Team Component] Team Member Production Details Loaded',
  props<{
    associateId: string;
    productionDetails: AssociateProductionDetails;
  }>()
);

export const teamMemberDetailsNotFound = createAction(
  '[My Team Component] Team Member Not Found'
);

export const deleteTeamMemberPhoto = createAction(
  '[My Team Component] Delete Team Member Photo',
  props<{ teamMember: TeamMemberInfo }>()
);

export const toggleTeamMemberPhotoIsRemoving = createAction(
  '[My Team Component] Team Member Photo is Deleting',
  props<{ apexId: number; isRemoving: boolean }>()
);

export const teamMemberPhotoDeleted = createAction(
  '[My Team Component] Team Member Photo Deleted',
  props<{ apexId: number }>()
);
