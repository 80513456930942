import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

let nextUniqueId = 0;

@Component({
  selector: 'app-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleSelectComponent {
  protected uid = `simple-select-${nextUniqueId++}`;
  protected componentId: string;

  @Input()
  get id(): string {
    return this.componentId;
  }

  set id(value: string) {
    this.componentId = value || this.uid;
  }

  @Input() value: any;
  @Input() label = 'Change productivity view';
  @Input() name: string;
  @Input() options: any[];
  @Input() valueKey = 'id';
  @Input() labelKey = 'name';

  @Output() valueChange = new EventEmitter<any>();

  constructor() {
    this.id = this.id;
  }

  updateValue(value) {
    this.value = value;
    this.valueChange.emit(value);
  }
}
