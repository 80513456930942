<app-filter-modal-layout>
  <ng-container slot="modal-header" i18n="@@date-filter_header">
    Filter By Start Date
  </ng-container>
  <ng-container slot="modal-content" class="date-filter">
    <button
      type="button"
      class="date-filter__select-button"
      (click)="setTodayDate()"
      i18n="@@date-filter_selectTodaysDate"
    >
      Select Todays Date
    </button>
    <h2 class="date-filter__subheading" i18n="@@date-filter_jobStartsAfter">
      Job Starts After:
    </h2>
    <div class="mydatepicker">
      <input
        #dp="angular-mydatepicker"
        angular-mydatepicker
        class="input-box"
        type="hidden"
        name="mydate"
        (rangeDateSelection)="dateChanged($event)"
        [(ngModel)]="date"
        [options]="myDpOptions"
      />
    </div>
    <app-loader [isLoading]="countsLoading"></app-loader>

    <p *ngIf="!countsLoading" class="date-filter__summary">
      {{ totalCount }} {totalCount, plural, =1 {opportunity} other
      {opportunities}} start on/after
      {{ date.dateRange.beginJsDate | date: 'MMMM d, y' }}
    </p>
  </ng-container>
  <ng-container slot="modal-action">
    <app-button-link
      (buttonClick)="resetFilter()"
      label="Reset Filter"
      i18n-label="@@date-filter_resetFilter"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>

    <app-button-link
      (buttonClick)="filter()"
      label="Done"
      i18n-label="@@date-filter_done"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>
  </ng-container>
</app-filter-modal-layout>
