<app-earnings-header
  [isFutureProcessing]="isFutureProcessing$ | async"
  [isHistoricalEarning]="isHistoricalEarning$ | async"
  [weekPayDetails]="weekPayDetails$ | async"
></app-earnings-header>
<main class="main-container goal">
  <app-earnings-summary
    [weekPayDetails]="weekPayDetails$ | async"
  ></app-earnings-summary>

  <app-earnings-tabs
    *ngIf="(isPayLoading$ | async) === false && (goalDetails$ | async)"
    class="goal__tabs"
    [isHistoricalEarning]="isHistoricalEarning$ | async"
    [isTrainee]="isTrainee$ | async"
    [goal]="(goalDetails$ | async).goal"
  ></app-earnings-tabs>

  <app-loader
    [isLoading]="isPayLoading$ | async"
    *ngIf="isPayLoading$ | async"
  ></app-loader>

  <div
    *ngIf="
      (weekPayDetails$ | async) &&
      (goalDetails$ | async) &&
      (performance$ | async)
    "
    class="goal__container"
  >
    <ng-container *ngIf="weekPayDetails$ | async as weekPayDetails">
      <app-card-layout *ngIf="goalDetails$ | async as goalDetails">
        <app-card-layout-item>
          <app-page-title
            title="My Weekly Goals"
            i18n-title="@@goal_title"
            [narrow]="true"
            [appearance]="PageTitleAppearanceEnum.Transparent"
          ></app-page-title>
        </app-card-layout-item>
        <app-card-layout-item>
          <ng-template #currentWeek i18n="@@goal_currentWeek">
            Current Week:
            {{ weekPayDetails.weekStartingDate | date: 'dd/MM/yy' }} -
            {{ weekPayDetails.weekEndingDate | date: 'dd/MM/yy' }}
          </ng-template>
          <ng-template #pastWeek i18n="@@goal_pastWeek">
            Past Week:
            {{ weekPayDetails.weekStartingDate | date: 'dd/MM/yy' }} -
            {{ weekPayDetails.weekEndingDate | date: 'dd/MM/yy' }}
          </ng-template>
          <app-page-section-header
            *ngIf="goalDetails.goal != null"
            title="Weekly earning goal"
            i18n-title="@@goal_weekly-earning-goal"
            [subTitleTemplate]="
              (isHistoricalEarning$ | async) === true ? pastWeek : currentWeek
            "
          ></app-page-section-header>
          <app-weekly-goal
            *ngIf="goalDetails$ | async as goalDetails"
            [isHistoricalWeek]="isHistoricalEarning$ | async"
            [goalDetails]="goalDetails"
            [totalEarnings]="weekPayDetails.totalEarnings"
          ></app-weekly-goal>
        </app-card-layout-item>
        <app-card-layout-item *ngIf="goalDetails.goal != null">
          <app-productivity
            [currentItemsPerHour]="currentItemsPerHour"
            [goalItemsPerHour]="goalItemsPerHour"
            [currentWorkedHours]="currentWorkedHours"
            [goalWorkedHours]="goalDetails.hours"
            [itemType]="goalDetails.itemType"
            [linkToGoalEditPage]="linkToGoalPage"
          ></app-productivity>
        </app-card-layout-item>
        <app-card-layout-item *ngIf="goalDetails.goal != null">
          <app-net-revenue-stats
            [itemType]="goalDetails.itemType"
            [avgRevPerItem]="netRevenueStats?.avgRevenuePerItem"
            [goalRevPerItem]="netRevenueStats?.goalRevenuePerItem"
            [siteAvgRevPerItem]="netRevenueStats?.siteAvgRevenuePerItem"
          ></app-net-revenue-stats>
          <ng-template #historicalWeek i18n="@@goal_editThisWeek">
            Edit Goals For This Week
          </ng-template>
          <ng-template #currentWeek i18n="@@goal_editGoal">
            Edit My Goals
          </ng-template>
          <app-button-link
            class="goal__edit"
            data-cy="editMyGoalsButton"
            [color]="ButtonColorEnum.Green"
            [type]="ButtonTypeEnum.Button"
            (buttonClick)="editGoal()"
            [labelTemplate]="isHistoricalGoal ? historicalWeek : currentWeek"
          ></app-button-link>
        </app-card-layout-item>
      </app-card-layout>
    </ng-container>
    <app-footer>
      <app-footer-note>
        <ng-container i18n="@@lastUpdated">Last updated</ng-container>
        {{ ' ' }}
        <time [attr.datetime]="(weekPayDetails$ | async).lastUpdatedAtDate">
          {{ (weekPayDetails$ | async).lastUpdatedAtDate | date }}
        </time>
      </app-footer-note>
    </app-footer>
  </div>
</main>
