/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./card-layout.component";
var styles_CardLayoutComponent = [i0.styles];
var RenderType_CardLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardLayoutComponent, data: {} });
export { RenderType_CardLayoutComponent as RenderType_CardLayoutComponent };
export function View_CardLayoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_CardLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-layout", [], [[8, "className", 0]], null, null, View_CardLayoutComponent_0, RenderType_CardLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i2.CardLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var CardLayoutComponentNgFactory = i1.ɵccf("app-card-layout", i2.CardLayoutComponent, View_CardLayoutComponent_Host_0, { appearance: "appearance" }, {}, ["*"]);
export { CardLayoutComponentNgFactory as CardLayoutComponentNgFactory };
