import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { SettingsContainerComponent } from './containers/settings-container/settings-container.component';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { WeeklyCheckInComponent } from './containers/weekly-check-in/weekly-check-in.component';
import { AuthorizationGuard } from '../core/guards/authorizationGuard/authorization.guard';
import { Roles } from '../core/enums/Roles';
import { AssociateCheckInHistoryComponent } from './containers/associate-check-in-history/associate-check-in-history.component';
import { AssociateProductionComponent } from './containers/associate-production/associate-production.component';
import { ProfileComponent } from './profile.component';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { Module } from '../shared/enums/Module';
import { PhotoPreviewComponent } from './containers/photo-preview/photo-preview.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      canActivate: [AuthenticationGuard, AuthorizationGuard],
      allowedRoles: [Roles.Associate]
    },
    children: [
      {
        path: 'check-ins',
        component: AssociateCheckInHistoryComponent,
        data: {
          showMenuBar: true,
          module: Module.MyProfile,
          siteMapKey: SiteMapItemKeys.AssociateCheckInHistory,
          pageTitle: TranslationMessages.CheckInHistoryTitle
        }
      },
      {
        path: 'production',
        component: AssociateProductionComponent,
        data: {
          showMenuBar: true,
          module: Module.MyProfile,
          siteMapKey: SiteMapItemKeys.AssociateProduction,
          pageTitle: TranslationMessages.ProductionStatsTitle
        }
      },
      {
        path: 'photo',
        children: [
          {
            path: 'preview',
            component: PhotoPreviewComponent,
            data: {
              hideNavigationBar: true,
              module: Module.MyProfile,
              siteMapKey: SiteMapItemKeys.TakePhoto
            }
          }
        ]
      }
    ]
  },
  {
    path: 'settings',
    component: SettingsContainerComponent,
    canActivate: [AuthenticationGuard],
    data: {
      hideNavigationBar: true,
      siteMapKey: SiteMapItemKeys.Settings,
      pageTitle: TranslationMessages.SettingsTitle
    }
  },
  {
    path: 'check-in/:week',
    component: WeeklyCheckInComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {
      hideNavigationBar: true,
      siteMapKey: SiteMapItemKeys.WeeklyCheckIn,
      allowedRoles: [Roles.Associate],
      pageTitle: TranslationMessages.WeeklyCheckInTitle
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule {}
