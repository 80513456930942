import {
  Component,
  OnInit,
  QueryList,
  AfterContentInit,
  ContentChildren,
  HostListener,
  forwardRef,
  OnDestroy,
  Input
} from '@angular/core';
import { AccordionPanelComponent } from './accordion-panel.component';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { AccordionService } from './accordion.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  providers: [AccordionService]
})
export class AccordionComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() multi = false;
  onDestroySubject = new Subject();
  @ContentChildren(forwardRef(() => AccordionPanelComponent))
  panels: QueryList<AccordionPanelComponent>;

  keyManager: FocusKeyManager<AccordionPanelComponent>;

  constructor(private accordion: AccordionService) {}

  ngOnInit() {
    this.accordion.activePanel$
      .pipe(
        filter(ep => !!ep),
        takeUntil(this.onDestroySubject)
      )
      .subscribe(panel => {
        if (this.keyManager.activeItem !== panel) {
          this.keyManager.setActiveItem(panel);
        }
      });

    this.accordion.isMulti = this.multi;
  }

  ngOnDestroy() {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event) {
    this.keyManager.onKeydown(event);
  }

  ngAfterContentInit() {
    this.keyManager = new FocusKeyManager(this.panels).withWrap();
  }
}
