<app-page-header-container></app-page-header-container>

<main class="main-container">
  <ng-template #starsFeed i18n="@@stars-feed_pageTitle">
    S.C.R.I.P.T. Stars
  </ng-template>
  <app-page-title
    [titleTemplate]="starsFeed"
    [appearance]="PageTitleAppearanceEnum.Transparent"
    data-cy="pageHeaderTitle"
  ></app-page-title>

  <app-loader [isLoading]="showAppLoader$ | async"></app-loader>
  <div class="stars-feed">
    <div
      class="pinned"
      *ngIf="
        ((isStarsFeedLoading$ | async) === false || page > 0) && pinnedPost
      "
    >
      <app-news-feed-card
        [FeedType]="FeedTypeEnum.Stars"
        [post]="getPinnedPost()"
        [showLike]="false"
        [isPinnedPost]="true"
      ></app-news-feed-card>
      <h2 class="pinned__heading" i18n="@@stars-feed_heading">
        Latest S.C.R.I.P.T. Star Recipients
      </h2>
    </div>

    <ng-container>
      <div
        role="feed"
        *ngIf="(starsFeed$ | async).length > 0"
        [attr.aria-busy]="
          (isStarsFeedLoading$ | async) && page > 0 ? 'true' : 'false'
        "
      >
        <ng-container *ngFor="let post of starsFeed$ | async; let i = index">
          <app-feed-separator
            *ngIf="post.isFirstDayPost"
            [date]="post.date"
            [isFirst]="i === 0"
          ></app-feed-separator>
          <app-news-feed-card
            [FeedType]="FeedTypeEnum.Stars"
            [post]="post"
            [ariaPosinset]="i + 1"
            [ariaSetsize]="(starsFeed$ | async)?.length"
            [extendableBlogPost]="!!blogPostsExtendable[post.id]?.isExtendable"
            (postLiked)="onLikeEvent($event)"
            (contentSizeChanged)="onContentSizeChange($event)"
          ></app-news-feed-card>
        </ng-container>
      </div>

      <div
        *ngIf="
          (isAppOnline$ | async) &&
          (isErrorOccurredOnStarsFeedLoading$ | async) === false &&
          total !== (starsFeed$ | async)?.length
        "
        class="news-feed__loader"
      >
        <app-loader
          [debounceTime]="0"
          [isLoading]="(isStarsFeedLoading$ | async) && page > 0"
        ></app-loader>
      </div>
    </ng-container>
  </div>

  <app-footer *ngIf="total === (starsFeed$ | async)?.length">
    <app-footer-note>
      <ng-container i18n="@@youHaveSeenItAll">You've seen it all!</ng-container>
    </app-footer-note>
    <app-footer-note>
      <ng-container i18n="@@news-feed_veryBeginningOfTheStarsFeed">
        This is the very beginning of the S.C.R.I.P.T. Stars Feed
      </ng-container>
    </app-footer-note>
  </app-footer>

  <app-footer
    *ngIf="
      (isErrorOccurredOnStarsFeedLoading$ | async) ||
      (isAppOnline$ | async) === false
    "
  >
    <app-footer-note>
      <ng-container i18n="@@weHadTroubleOnLoadingTheNextPost">
        We had trouble loading the next post.
      </ng-container>
    </app-footer-note>
    <app-footer-note>
      <ng-container i18n="@@stars-feed_checkInternetConnection">
        Check your connection and
        <a href="resources/stars">try again</a>
      </ng-container>
    </app-footer-note>
  </app-footer>
</main>
