import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AnonymousGatewayService {
  private http: HttpClient;

  constructor(httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
  }

  get<T>(url) {
    return this.request<T>('GET', url, null);
  }

  request<T>(
    method: 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS' | 'POST' | 'PUT',
    url: string,
    body = null
  ): Observable<T> {
    return this.http.request(method, url, {
      body,
      responseType: 'text'
    }) as any;
  }
}
