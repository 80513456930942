import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { DynamicContentComponent } from './dynamic-content.component';
import { PageContainerComponent } from './containers/page-container/page-container.component';

const routes: Routes = [
  {
    path: 'content',
    component: DynamicContentComponent,
    data: {
      showMenuBar: true
    },
    children: [
      {
        path: ':slug',
        component: PageContainerComponent,
        data: {
          hideNavigationBar: true,
          siteMapKey: SiteMapItemKeys.DynamicContent
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DynamicContentRoutingModule {}
