import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ContentDeliveryService } from '../../shared/services/content-delivery.service';
import { ApiGatewayService } from '../../shared/services/api.gateway.service';
import { Store } from '@ngrx/store';
import { AppState, getAuthTokenWithApexUserId } from '../../reducers';
import { Observable } from 'rxjs';
import { Feed } from '../../core/dataEntities/feed/feed';
import { FeedService } from '../../shared/services/feed.service';
import { Post } from '../../core/dataEntities/feed/post';
import { first, switchMap } from 'rxjs/operators';
import { DirectoryResponse } from '../../core/responses/resources/directoryResponse';
import { StaticContentService } from '../../core/contracts/StaticContentService';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService extends FeedService {
  public pinnedPostId = 'pinned-post';
  readonly contentLocale: string;

  constructor(
    private contentDeliveryService: ContentDeliveryService,
    private apiGateway: ApiGatewayService,
    private staticContentService: StaticContentService,
    private store: Store<AppState>,
    @Inject(LOCALE_ID) localeId: string
  ) {
    super(apiGateway, store);
    this.contentLocale = localeId;
  }

  starsFeed(page: number, take: number): Observable<Feed> {
    const query = {
      'fields.category.sys.contentType.sys.id': 'newsCategory',
      'fields.category.fields.key[match]': 'starAward'
    };

    return this.contentDeliveryService.getNews(page * take, take, query);
  }

  loadPost(postId: string): Observable<Post> {
    if (postId === this.pinnedPostId) {
      return this.loadPinnedPost();
    }
    return this.contentDeliveryService.getPost(postId);
  }

  loadPinnedPost() {
    return this.staticContentService.getScriptPinnedPost();
  }

  getDirectory(): Observable<DirectoryResponse> {
    return this.store.select(getAuthTokenWithApexUserId).pipe(
      first(),
      switchMap(({ authToken, userId }) => {
        if (!userId) {
          throw new Error('User not found.');
        }
        return this.apiGateway.get<DirectoryResponse>(
          `siteresources/associates/${userId}/directory`,
          authToken
        );
      })
    );
  }
}
