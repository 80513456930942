import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { ContentDeliveryService } from '../../shared/services/content-delivery.service';
import { ApiGatewayService } from '../../shared/services/api.gateway.service';
import {
  AppState,
  getAuthTokenWithApexUserId,
  getIsUserProfileLoading,
  getUserSitesIds
} from '../../reducers';
import { first, map, switchMap, mergeMap, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NewsFeedLastSeenResponse } from '../../core/responses/news/newsFeedLastSeenResponse';
import { Post } from '../../core/dataEntities/feed/post';
import { Feed } from '../../core/dataEntities/feed/feed';
import { FeedService } from '../../shared/services/feed.service';

@Injectable({
  providedIn: 'root'
})
export class NewsFeedService extends FeedService {
  constructor(
    private contentDeliveryService: ContentDeliveryService,
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>
  ) {
    super(apiGateway, store);
  }

  loadNewsFeed(page: number, take: number): Observable<Feed> {
    return combineLatest(
      this.store.select(getUserSitesIds),
      this.store.select(getIsUserProfileLoading)
    ).pipe(
      filter(([userSitesIds, isProfileInfoLoading]) => !isProfileInfoLoading),
      first(),
      mergeMap(([userSitesIds]) => {
        const query = {
          'fields.sites[in]': `All,${
            userSitesIds !== null ? userSitesIds.join(',') : ''
          }`
        };

        return this.contentDeliveryService.getNews(
          page * take,
          take,
          userSitesIds ? query : null
        );
      })
    );
  }

  loadPost(postId: string): Observable<Post> {
    return this.contentDeliveryService.getPost(postId);
  }

  loadPostPreview(postId: string): Observable<Post> {
    return this.contentDeliveryService.getPostPreview(postId);
  }

  getTotalNewPostCount(lastSeenDate): Observable<number> {
    return combineLatest(
      this.store.select(getUserSitesIds),
      this.store.select(getIsUserProfileLoading)
    ).pipe(
      filter(([userSitesIds, isProfileInfoLoading]) => !isProfileInfoLoading),
      first(),
      mergeMap(([userSitesIds]) => {
        return this.contentDeliveryService.getNewsCount(
          lastSeenDate,
          userSitesIds ? userSitesIds : ''
        );
      })
    );
  }

  postNewsFeedLastSeen(): Observable<any> {
    return this.store.select(getAuthTokenWithApexUserId).pipe(
      first(),
      switchMap(({ authToken, userId }) => {
        if (!userId) {
          throw new Error('User not found.');
        }
        return this.apiGateway.put<any>(
          `comms/newsfeed/posts/lastSeen/associate/${userId}`,
          authToken,
          {}
        );
      })
    );
  }

  getNewsFeedLastSeen(): Observable<string> {
    return this.store.select(getAuthTokenWithApexUserId).pipe(
      first(),
      switchMap(({ authToken, userId }) => {
        if (!userId) {
          throw new Error('User not found.');
        }

        return this.apiGateway
          .get<NewsFeedLastSeenResponse>(
            `comms/newsfeed/posts/lastSeen/associate/${userId}`,
            authToken
          )
          .pipe(
            map(response => {
              return response.lastSeen;
            })
          );
      })
    );
  }
}
