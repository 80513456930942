import { createReducer, Action } from '@ngrx/store';

import { produceOn } from '../../shared/ngrx-immutable';
import { TranslationActions } from '../../core/actions';

export const translationsFeatureKey = 'translations';

export interface TranslationState {
  language: string;
}

export const initialState: TranslationState = {
  language: 'en'
};

export const translationReducer = createReducer(
  initialState,
  produceOn(TranslationActions.setLanguage, (draft, payload) => {
    draft.language = payload.language;
  })
);

export function reducer(state: TranslationState | undefined, action: Action) {
  return translationReducer(state, action);
}

export const getLanguage = (state: TranslationState) => {
  return state.language;
};
