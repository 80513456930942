<div class="nested-checkbox" *ngFor="let control of formArray.controls">
  <app-checkbox-input
    [formControl]="control.get('checked')"
    [name]="control.get('label').value"
    [id]="control.get('id').value"
  >
    {{ control.get('label').value }}
  </app-checkbox-input>
  <div class="nested-checkbox__children" *ngIf="control.get('checked').value">
    <ng-container *ngFor="let childControl of control.get('children').controls">
      <app-checkbox-input
        [formControl]="childControl.get('checked')"
        [name]="childControl.get('label').value"
        [id]="childControl.get('id').value"
      >
        {{ childControl.get('label').value }}
      </app-checkbox-input>
    </ng-container>
  </div>
</div>
