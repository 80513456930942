import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { createReducer, Action, on, createSelector } from '@ngrx/store';
import { LayoutActions, UserActions } from '../../core/actions';
import { Survey } from '../../core/dataEntities/surveys/survey';
import { SurveysActions } from '../actions';

export const surveyDetailsFeatureKey = 'surveyDetails';

export interface SurveyDetailsState extends EntityState<Survey> {
  activeSurveyId?: string;
  isSurveyLoading: boolean;
}

export const adapter: EntityAdapter<Survey> = createEntityAdapter<Survey>();

export const initialState: SurveyDetailsState = adapter.getInitialState({
  activeSurveyId: null,
  isSurveyLoading: false
});

export const surveyDetailsReducer = createReducer(
  initialState,
  on(SurveysActions.loadSurvey, (state, action) => {
    return {
      ...state,
      isSurveyLoading: true
    };
  }),
  on(SurveysActions.surveyLoaded, (state, action) => {
    const surveyResponse = action.survey;
    const survey = {
      ...surveyResponse,
      questions: surveyResponse.questions.map(q => {
        return {
          ...q,
          answer: null
        };
      })
    };
    return adapter.upsertOne(survey, {
      ...state,
      activeSurveyId: survey.id,
      isSurveyLoading: false
    });
  }),
  on(LayoutActions.showError, LayoutActions.apiOffline, state => {
    return {
      ...state,
      isSurveyLoading: false
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(state: SurveyDetailsState | undefined, action: Action) {
  return surveyDetailsReducer(state, action);
}

const { selectEntities } = adapter.getSelectors();

export const selectIsSurveyLoading = (state: SurveyDetailsState) => {
  return state.isSurveyLoading;
};

export const selectActiveSurveyId = (state: SurveyDetailsState) => {
  return state.activeSurveyId;
};

export const selectSurveyDetails = createSelector(
  selectActiveSurveyId,
  selectEntities,
  (id, entities) => {
    return entities[id];
  }
);
