<div
  class="option"
  [ngClass]="{
    option: true,
    'option--has-validation': hasValidation
  }"
>
  <input
    [ngClass]="{
      option__input: true
    }"
    type="checkbox"
    [formControl]="formControl"
    [attr.name]="name"
    [attr.id]="id"
    [attr.required]="required ? '' : null"
    [attr.aria-invalid]="isInvalid"
    [attr.aria-describedby]="isInvalid ? errorId : null"
  />
  <label
    [ngClass]="{
      option__label: true,
      'option__label--align-top': CheckBoxInputAlignEnum.Top === align
    }"
    [attr.for]="id"
  >
    <ng-content></ng-content>
  </label>
</div>
