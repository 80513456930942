/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/button-link/button-link.component.ngfactory";
import * as i3 from "../../../../../shared/components/button-link/button-link.component";
import * as i4 from "../../../../../shared/services/analytics-utilities.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "./document.component";
import * as i8 from "../../../../../shared/components/form/form-field/form-field.control";
import * as i9 from "../../../../../shared/modules/modal/bottom-sheet/bottom-sheet.service";
import * as i10 from "../../../../../shared/components/form/form-field/form-field.component";
var styles_DocumentComponent = [i0.styles];
var RenderType_DocumentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentComponent, data: {} });
export { RenderType_DocumentComponent as RenderType_DocumentComponent };
function View_DocumentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Document template"], ["class", "document__placeholder"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-button-link", [["class", "document__button"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.previewDocument() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonLinkComponent_0, i2.RenderType_ButtonLinkComponent)), i1.ɵdid(3, 638976, null, 0, i3.ButtonLinkComponent, [i4.AnalyticsUtilitiesService], { color: [0, "color"], labelTemplate: [1, "labelTemplate"], iconName: [2, "iconName"], iconColorPrimary: [3, "iconColorPrimary"], iconColorSecondary: [4, "iconColorSecondary"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.errors && _co.isInvalid) ? _co.ButtonColorEnum.Red : _co.ButtonColorEnum.Blue); var currVal_2 = _co.addDocumentButtonLabelTemplate; var currVal_3 = _co.SvgIconNameEnum.CameraAdd; var currVal_4 = _co.SvgIconColorEnum.White; var currVal_5 = ((_co.errors && _co.isInvalid) ? _co.SvgIconColorEnum.Cinnabar : _co.SvgIconColorEnum.Endeavour); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.errors && _co.isInvalid) ? _co.errorStateImage : _co.emptyStateImage); _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Document preview"], ["class", "document__image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.file; _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "document__image-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentComponent_3)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-button-link", [["class", "document__button"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.previewDocument() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonLinkComponent_0, i2.RenderType_ButtonLinkComponent)), i1.ɵdid(5, 638976, null, 0, i3.ButtonLinkComponent, [i4.AnalyticsUtilitiesService], { labelTemplate: [0, "labelTemplate"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formControl.value; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.previewDocumentButtonLabelTemplate; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_DocumentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.formControl.value == null) || (((_co.formControl.value[0] == null) ? null : _co.formControl.value[0].file) == null)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.formControl.value && (((_co.formControl.value[0] == null) ? null : _co.formControl.value[0].file) != null)); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_DocumentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-document", [], null, null, null, View_DocumentComponent_0, RenderType_DocumentComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DocumentComponent]), i1.ɵprd(6144, null, i8.FormFieldControl, null, [i7.DocumentComponent]), i1.ɵdid(3, 245760, null, 0, i7.DocumentComponent, [i6.FormBuilder, i9.BottomSheetService, [2, i10.FORM_FIELD]], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var DocumentComponentNgFactory = i1.ɵccf("app-document", i7.DocumentComponent, View_DocumentComponent_Host_0, { addDocumentButtonLabelTemplate: "addDocumentButtonLabelTemplate", previewDocumentButtonLabelTemplate: "previewDocumentButtonLabelTemplate", documents: "documents", documentsType: "documentsType", errors: "errors", hasInsurance: "hasInsurance", id: "id" }, {}, []);
export { DocumentComponentNgFactory as DocumentComponentNgFactory };
