import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import { SvgIconColor, SvgIconName } from '../../../shared/enums/SvgIcon';
import { ProfileLinkItemAppearance } from '../../enums/ProfileLinkItem';

@Component({
  selector: 'app-profile-link-item',
  templateUrl: './profile-link-item.component.html',
  styleUrls: ['./profile-link-item.component.scss']
})
export class ProfileLinkItemComponent implements OnInit {
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() link: string;
  @Input() iconName: SvgIconName;
  @Input() iconColorPrimary: SvgIconColor;
  @Input() iconColorSecondary: SvgIconColor;
  @Input() appearance = ProfileLinkItemAppearance.Blue;
  @Output() buttonClick = new EventEmitter();
  @Output() linkClick = new EventEmitter();

  ProfileLinkItemAppearanceEnum = ProfileLinkItemAppearance;

  constructor() {}

  ngOnInit() {}

  onClickButton(event) {
    this.buttonClick.emit(event);
  }

  onLinkClick(event) {
    this.linkClick.emit(event);
  }
}
