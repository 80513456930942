import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (route.data.allowedRoles) {
      return this.authorizationService
        .isAuthorized$(route.data.allowedRoles)
        .pipe(
          tap(isAuthorized => {
            if (!isAuthorized) {
              this.router.navigate(['']);
            }
          })
        );
    }

    return true;
  }
}
