import { ItemType } from '../enums/ItemType';
import { TranslationMessages } from '../../../../shared/enums/TranslationMessages';

export interface ProductivityMetricConfig {
  min: number;
  max: number;
  step: number;
  maxLimitPercent?: number;
  infoBoxText: string;
  selectors?: SelectorType[];
  itemTypeMap?: {
    pallets: SelectorType[];
    cases: SelectorType[];
  };
}

export interface GoalSlidersConfig {
  currentGoal: {
    infoBoxText: string;
  };
  hours: ProductivityMetricConfig;
  palletsPerHour: ProductivityMetricConfig;
  casesPerHour: ProductivityMetricConfig;
  revenuePerPallet: ProductivityMetricConfig;
  revenuePerCase: ProductivityMetricConfig;
}

interface SelectorType {
  value: string;
  display: string;
}

export const SlidersConfig: GoalSlidersConfig = {
  currentGoal: {
    infoBoxText: TranslationMessages.CurrentGoalInfoBoxText
  },
  palletsPerHour: {
    min: 1,
    max: 75,
    step: 1,
    maxLimitPercent: 200,
    selectors: [
      { value: ItemType.Pallets, display: TranslationMessages.PalletsPerHour },
      { value: ItemType.Cases, display: TranslationMessages.CasesPerHour }
    ],
    infoBoxText: TranslationMessages.PalletsPerHourInfoBoxText
  },
  casesPerHour: {
    min: 1,
    max: 4000,
    step: 1,
    maxLimitPercent: 200,
    selectors: [
      { value: ItemType.Pallets, display: TranslationMessages.PalletsPerHour },
      { value: ItemType.Cases, display: TranslationMessages.CasesPerHour }
    ],
    infoBoxText: TranslationMessages.CasesPerHourInfoBoxText
  },
  hours: {
    min: 1,
    max: 40,
    step: 1,
    maxLimitPercent: 200,
    selectors: [{ value: 'hours', display: TranslationMessages.WorkHours }],
    infoBoxText: TranslationMessages.WorkHoursInfoBoxText
  },
  revenuePerPallet: {
    min: 0.5,
    max: 8.5,
    step: 0.01,
    maxLimitPercent: 200,
    itemTypeMap: {
      pallets: [
        {
          value: ItemType.Pallets,
          display: TranslationMessages.RevenuePerPallet
        }
      ],
      cases: [{ value: ItemType.Cases, display: TranslationMessages.RevenuePerCase }]
    },
    infoBoxText: TranslationMessages.RevenuePerPalletInfoBoxText
  },
  revenuePerCase: {
    min: 0.01,
    max: 2.0,
    step: 0.01,
    maxLimitPercent: 200,
    itemTypeMap: {
      pallets: [
        {
          value: ItemType.Pallets,
          display: TranslationMessages.RevenuePerPallet
        }
      ],
      cases: [{ value: ItemType.Cases, display: TranslationMessages.RevenuePerCase }]
    },
    infoBoxText: TranslationMessages.RevenuePerCaseInfoBoxText
  }
};
