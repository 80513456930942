import { EntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { difference, includes, map } from 'lodash/fp';
import { DataTableRow } from '..';
import { DataTableBaseAdapter } from '../data-table-base/data-table-base-adapter';
import { ServerDataTableState } from './server-data-table-state';
import { SelectAllState } from '../enums/select-all-state';

export interface ServerDataTableAdapterOptions {
  infinitePage?: boolean;
}

export class ServerDataTableAdapter<
  T extends DataTableRow
> extends DataTableBaseAdapter<T, ServerDataTableState<T>> {
  private defaultOptions: ServerDataTableAdapterOptions = {
    infinitePage: false
  };
  private options;

  constructor(
    adapter: EntityAdapter<T>,
    options: ServerDataTableAdapterOptions = {}
  ) {
    super(adapter);

    this.options = {
      ...this.defaultOptions,
      ...options
    };
  }

  getInitialState(state): ServerDataTableState<T> {
    return super.getInitialState({
      ...state,
      currentItems: []
    });
  }

  getItemsLoadedState(action, state) {
    const newState = super.getItemsLoadedState(action, state);
    let currentItems = [];
    const newPageItems = map(item => item.id)(action.items);
    if (this.options.infinitePage && action.fillCurrentItems) {
      currentItems = [...state.currentItems, ...newPageItems];
    } else {
      currentItems = [...newPageItems];
    }

    return {
      ...newState,
      currentItems
    };
  }

  updateListItem(state, id, changes) {
    const update = {
      id,
      changes: {
        ...changes
      }
    };

    return this.adapter.updateOne(update, state);
  }

  createDataTableSelectionStateSelector(): (
    state: ServerDataTableState<T>
  ) => any {
    const selectCurrentPageItemsIds = (state: ServerDataTableState<T>) => {
      return state.currentItems;
    };
    const selectSelectedDataTableItemsIds = (
      state: ServerDataTableState<T>
    ) => {
      return state.selectedItems;
    };

    return createSelector(
      selectCurrentPageItemsIds,
      selectSelectedDataTableItemsIds,
      (currentPageItemIds, selectedItemsIds) => {
        const currentAndSelectedDifference = difference(
          currentPageItemIds,
          selectedItemsIds
        ).length;
        const everySelected = currentAndSelectedDifference === 0;
        const fewSelected =
          currentAndSelectedDifference < currentPageItemIds.length &&
          currentAndSelectedDifference !== 0;
        if (everySelected) {
          return SelectAllState.All;
        } else if (fewSelected) {
          return SelectAllState.Indeterminate;
        } else {
          return SelectAllState.None;
        }
      }
    );
  }

  createCurrentItemsSelector(): (state: ServerDataTableState<T>) => T[] {
    const selectEntities = (state: ServerDataTableState<T>) => {
      return state.entities;
    };
    const selectCurrentPageItemsIds = (state: ServerDataTableState<T>) => {
      return state.currentItems;
    };

    const selectCurrentItems = createSelector(
      selectEntities,
      selectCurrentPageItemsIds,
      (entities, currentPageItemsIds) => {
        return map(id => entities && entities[id])(currentPageItemsIds);
      }
    );

    const selectSelectedDataTableItemsIds = (
      state: ServerDataTableState<T>
    ) => {
      return state.selectedItems;
    };

    return createSelector(
      selectCurrentItems,
      selectSelectedDataTableItemsIds,
      (currentPageEntities, selectedItemsIds) => {
        return map(e => ({
          ...e,
          selected: includes(e.id)(selectedItemsIds)
        }))(currentPageEntities);
      }
    );
  }
}
