import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from '../../shared/services/authorization.service';
import { first } from 'rxjs/operators';

@Directive({
  selector: '[appIfHasPermission]'
})
export class IfHasPermissionDirective {
  private availableFor = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorizationService: AuthorizationService
  ) {}

  @Input()
  set appIfHasPermission(groups) {
    this.availableFor = groups;
    this.updateView();
  }

  private updateView() {
    this.authorizationService
      .isAuthorized$(this.availableFor)
      .pipe(first())
      .subscribe(isAuthorized => {
        if (isAuthorized) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }
}
