import { Observable } from 'rxjs';
import { RoadCrewStaticContent } from '../../shared/enums/RoadCrewStaticContent';

export abstract class StaticContentService {
  abstract getRoadCrewStaticContent(
    fragments: RoadCrewStaticContent[]
  ): Observable<any>;

  abstract getLoginTermsAndConditions(): Observable<any>;

  abstract getScriptPinnedPost(): Observable<any>;
}
