import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectTempPhoto } from '../../../reducers';
import { Observable } from 'rxjs';
import { UserActions } from '../../../core/actions';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { ButtonAppearance } from '../../../shared/enums/ButtonLink';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AssociatePhotoComponent } from '../../../shared/components/associate-photo/associate-photo.component';

@Component({
  selector: 'app-photo-preview',
  templateUrl: './photo-preview.component.html',
  styleUrls: ['./photo-preview.component.scss'],
  animations: [
    trigger('slideInPhoto', [
      state('false', style({ transform: 'translateY(-50px)', opacity: 0 })),
      state('true', style({ transform: 'translateY(0)', opacity: '100%' })),
      transition('false <=> true', animate('0.6s ease-in-out'))
    ]),
    trigger('slideInDetails', [
      state('false', style({ transform: 'translateY(50px)', opacity: 0 })),
      state('true', style({ transform: 'translateY(0)', opacity: '100%' })),
      transition('false <=> true', animate('0.6s ease-in-out'))
    ])
  ]
})
export class PhotoPreviewComponent implements OnInit, AfterViewInit {
  public PageTitleAppearanceEnum = PageTitleAppearance;
  public ButtonAppearanceEnum = ButtonAppearance;
  public SvgIconNameEnum = SvgIconName;
  tempPhoto$: Observable<string>;

  triggerAnimation = false;

  @ViewChild(AssociatePhotoComponent, { static: false })
  associatePhotoComponent: AssociatePhotoComponent;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.tempPhoto$ = this.store.select(selectTempPhoto);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.triggerAnimation = true;
    });
  }

  retake() {
    this.associatePhotoComponent.openUploadPrompt();
  }

  save() {
    this.store.dispatch(UserActions.savePhoto());
  }
}
