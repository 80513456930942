/* tslint:disable:no-bitwise */
﻿export enum GoalValidationRange {
  None = 0,
  Above = 1 << 0,       // 00001
  Below = 1 << 1,       // 00010
  Above20Pct = 1 << 2,  // 00100
  Above50Pct = 1 << 3,  // 01000
  Below50Pct = 1 << 4   // 10000
}
/* tslint:enable:no-bitwise */
