import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../shared/components/shared-components/shared-components.module';
import { StaticContentRoutingModule } from './static-content-routing.module';
import { PwaNotificationContainerComponent } from './containers/pwa-notification-container/pwa-notification-container.component';
import { DisclaimerContainerComponent } from './containers/disclaimer-container/disclaimer-container.component';
import { LandingPageContainerComponent } from './containers/landing-page-container/landing-page-container.component';
import { CovidContainerComponent } from './containers/covid-container/covid-container.component';

@NgModule({
  declarations: [
    PwaNotificationContainerComponent,
    DisclaimerContainerComponent,
    LandingPageContainerComponent,
    CovidContainerComponent
  ],
  imports: [SharedComponentsModule, StaticContentRoutingModule],
  exports: [],
  entryComponents: []
})
export class StaticContentModule {}
