<app-card-layout
  *ngIf="
    opportunityDetails.status &&
    opportunityDetails.status !== JobStatusEnum.Canceled
  "
>
  <app-card-layout-item>
    <ng-template #applicationUnderReview>
      <app-page-section-header
        title="Application: Under Review"
        i18n-title="@@opportunity-details_underReviewTitle"
        subtitle="Your application is currently being reviewed. We’ll notify you when
        we’ve made a staffing decision."
        i18n-subtitle="@@opportunity-details_underReviewSubTitle"
      ></app-page-section-header>
      <div class="opportunity-details__icon-container">
        <app-svg-icon
          class="opportunity-details__icon"
          [name]="SvgIconEnum.Hourglass"
        ></app-svg-icon>
      </div>
      <div class="opportunity-details__actions">
        <app-button-link
          label="Update My Info"
          [appearance]="ButtonAppearanceEnum.Link"
          (buttonClick)="updateDetails.emit()"
        ></app-button-link>
        <app-button-link
          label="Cancel Application"
          [appearance]="ButtonAppearanceEnum.Secondary"
          [color]="ButtonColorEnum.Red"
          (buttonClick)="cancel.emit()"
        ></app-button-link>
      </div>
    </ng-template>
    <ng-template #applicationApproved>
      <ng-template #applicationApprovedSubtitle>
        <p i18n="@@opportunity-details_approvedDetails">
          You’ll be emailed an itinerary at
          <strong>{{ userEmail }}</strong>
          from the travel team. Your site manager or the project lead can
          provide more instructions.
        </p>
      </ng-template>
      <app-page-section-header
        title="Application: Approved"
        i18n-title="@@opportunity-details_ApprovedTitle"
        [subTitleTemplate]="applicationApprovedSubtitle"
        [appearance]="PageSectionHeaderAppearanceEnum.Green"
      ></app-page-section-header>
      <p
        *ngIf="opportunityDetails.responsiblePerson?.name"
        i18n="@@opportunity-details_underReviewDetails"
      >
        If you have any questions, reach out to
        <strong>{{ opportunityDetails.responsiblePerson?.name }}</strong>
        at
        <a
          class="opportunity-details__link"
          href="tel:{{ opportunityDetails.responsiblePerson?.phone }}"
        >
          {{ opportunityDetails.responsiblePerson?.phone }}
        </a>
        <ng-container *ngIf="opportunityDetails.responsiblePerson?.email">
          or
          <a
            class="opportunity-details__link"
            href="mailto:{{ opportunityDetails.responsiblePerson?.email }}"
          >
            {{ opportunityDetails.responsiblePerson?.email }}
          </a>
        </ng-container>
        .
      </p>
      <app-svg-icon
        class="opportunity-details__icon"
        [name]="SvgIconEnum.Approved"
      ></app-svg-icon>
    </ng-template>
    <ng-template #applicationCompleted>
      <ng-template #applicationCompletedSubtitle>
        <p i18n="@@opportunity-details_completedDetails">
          Congratulations! You have successfully completed your deployment to
          {{ opportunityDetails.finalLocation }}
        </p>
      </ng-template>
      <app-page-section-header
        title="Application: Completed"
        i18n-title="@@opportunity-details_CompletedTitle"
        [subTitleTemplate]="applicationCompletedSubtitle"
        [appearance]="PageSectionHeaderAppearanceEnum.Gold"
      ></app-page-section-header>
      <p
        *ngIf="opportunityDetails.responsiblePerson?.name"
        i18n="@@opportunity-details_underReviewDetails"
      >
        If you have any questions, reach out to
        <strong>{{ opportunityDetails.responsiblePerson?.name }}</strong>
        at
        <a
          class="opportunity-details__link"
          href="tel:{{ opportunityDetails.responsiblePerson?.phone }}"
        >
          {{ opportunityDetails.responsiblePerson?.phone }}
        </a>
        <ng-container *ngIf="opportunityDetails.responsiblePerson?.email">
          or
          <a
            class="opportunity-details__link"
            href="mailto:{{ opportunityDetails.responsiblePerson?.email }}"
          >
            {{ opportunityDetails.responsiblePerson?.email }}
          </a>
        </ng-container>
        .
      </p>
      <app-svg-icon
        class="opportunity-details__icon"
        style="margin: 0.5rem auto;"
        [name]="SvgIconEnum.Completed"
      ></app-svg-icon>
    </ng-template>
    <ng-template #applicationNotSelected>
      <app-page-section-header
        title="Application: Not selected"
        i18n-title="@@opportunity-details_NotSelectedTitle"
        subtitle="You were not selected for this Road Crew opportunity."
        i18n-subtitle="@@opportunity-details_NotSelectedSubTitle"
        [appearance]="PageSectionHeaderAppearanceEnum.Red"
      ></app-page-section-header>
      <p
        *ngIf="opportunityDetails.statusMessage"
        class="opportunity-details__feedback"
      >
        {{ opportunityDetails.statusMessage }}
      </p>
    </ng-template>
    <ng-template #applicationDetailedNeeded>
      <app-page-section-header
        title="Application: additional details needed"
        i18n-title="@@opportunity-details_detailsNeededTitle"
        subtitle="Your application needs additional details before you can be considered."
        i18n-subtitle="@@opportunity-details_detailsNeededSubTitle"
        [appearance]="PageSectionHeaderAppearanceEnum.Red"
      ></app-page-section-header>
      <p
        *ngIf="opportunityDetails.statusMessage"
        class="opportunity-details__feedback"
      >
        {{ opportunityDetails.statusMessage }}
      </p>
      <app-button-link
        class="opportunity-details__button"
        label="Update My Info"
        i18n-label="@@opportunity-details_updateMyInfo"
        (buttonClick)="updateDetails.emit()"
      ></app-button-link>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="
        opportunityDetails.status === JobStatusEnum.Approved
          ? applicationApproved
          : opportunityDetails.status === JobStatusEnum.Completed
          ? applicationCompleted
          : opportunityDetails.status === JobStatusEnum.Rejected &&
            opportunityDetails.subStatus === JobSubStatusEnum.NotSelected
          ? applicationNotSelected
          : opportunityDetails.status === JobStatusEnum.Rejected &&
            opportunityDetails.subStatus === JobSubStatusEnum.DetailsNeeded
          ? applicationDetailedNeeded
          : applicationUnderReview
      "
    ></ng-container>
  </app-card-layout-item>
</app-card-layout>
<app-card-layout>
  <app-card-layout-item>
    <ng-template #title>
      <ng-container
        *ngIf="
          opportunityDetails?.duration && opportunityDetails?.duration !== '0'
        "
        i18n="@@opportunity-details_weeks"
      >
        {{ opportunityDetails?.duration }}+ {opportunityDetails?.duration,
        plural, =1 {Week} other {Weeks}}
      </ng-container>
      <ng-container
        *ngIf="
          opportunityDetails?.duration && opportunityDetails?.duration === '0'
        "
        i18n="@@opportunity-details_weeks_alt"
      >
        Less Than a Week
      </ng-container>
      <ng-container i18n="@@opportunity-details_road-crew-job">
        Road Crew Job
      </ng-container>
    </ng-template>

    <app-page-section-header [titleTemplate]="title"></app-page-section-header>

    <app-icon-text
      *ngIf="opportunityDetails?.startDate && opportunityDetails?.endDate"
      [iconName]="SvgIconEnum.Calendar"
      [iconColorPrimary]="SvgIconColorEnum.Lochmara"
      [iconColorSecondary]="SvgIconColorEnum.Spindle"
    >
      <div i18n="@@opportunity-details_begins">
        <p>Begins {{ opportunityDetails?.startDate | date: 'MM/dd/yy' }}</p>
        <p>Ends {{ opportunityDetails?.endDate | date: 'MM/dd/yy' }}</p>
        <p>Expires {{ opportunityDetails?.expireDate | date: 'MM/dd/yy' }}</p>
      </div>
    </app-icon-text>
    <app-icon-text
      *ngIf="
        opportunityDetails?.daysOfWeek &&
        (opportunityDetails?.daysOfWeek)[0] !== ''
      "
      [iconName]="SvgIconEnum.Clock"
      [iconColorPrimary]="SvgIconColorEnum.Lochmara"
      [iconColorSecondary]="SvgIconColorEnum.Spindle"
    >
      {{ opportunityDetails?.daysOfWeek | join: ', ' }}
    </app-icon-text>
    <app-icon-text
      *ngIf="
        opportunityDetails?.location && !opportunityDetails.postingBySkillset
      "
      [iconName]="SvgIconEnum.Car"
      [iconColorPrimary]="SvgIconColorEnum.Lochmara"
    >
      {{ opportunityDetails?.location.siteLocation }}
    </app-icon-text>
    <app-icon-text
      *ngIf="
        opportunityDetails?.location && opportunityDetails.postingBySkillset
      "
      [iconName]="SvgIconEnum.Airplane"
      [iconColorPrimary]="SvgIconColorEnum.Lochmara"
    >
      <span i18n="@@opportunity-details_national">National</span>
    </app-icon-text>
  </app-card-layout-item>

  <app-card-layout-item
    *ngIf="
      opportunityDetails.status === JobStatusEnum.Approved &&
      opportunityDetails.finalLocation !== null &&
      opportunityDetails.finalLocation !== ''
    "
  >
    <app-page-section-header
      title="Final location"
      i18n-title="@@opportunity-details_final_location"
    ></app-page-section-header>
    <app-icon-text
      [iconName]="SvgIconEnum.Location"
      [iconColorPrimary]="SvgIconColorEnum.Lochmara"
    >
      {{ opportunityDetails?.finalLocation }}
    </app-icon-text>
  </app-card-layout-item>

  <app-card-layout-item
    *ngIf="
      opportunityDetails.status === JobStatusEnum.Completed &&
      opportunityDetails.finalLocation !== null &&
      opportunityDetails.finalLocation !== ''
    "
  >
    <app-page-section-header
      title="Final location"
      i18n-title="@@opportunity-details_final_location_completed"
    ></app-page-section-header>
    <app-icon-text
      [iconName]="SvgIconEnum.Location"
      [iconColorPrimary]="SvgIconColorEnum.Lochmara"
    >
      {{ opportunityDetails?.finalLocation }}
    </app-icon-text>
  </app-card-layout-item>

  <app-card-layout-item
    *ngIf="
      opportunityDetails.status === JobStatusEnum.Rejected &&
      opportunityDetails.rejectionReason !== null &&
      opportunityDetails.rejectionReason !== ''
    "
  >
    <app-page-section-header
      title="Rejection Reason"
      i18n-title="@@opportunity-details_rejection-reason"
    ></app-page-section-header>
    <app-icon-text
      [iconName]="SvgIconEnum.PeopleWCross"
      [iconColorPrimary]="SvgIconColorEnum.Lochmara"
    >
      {{ opportunityDetails?.rejectionReason }}
    </app-icon-text>
  </app-card-layout-item>

  <app-card-layout-item *ngIf="opportunityDetails?.description">
    <app-page-section-header
      title="Description"
      i18n-title="@@opportunity-details_description"
    ></app-page-section-header>
    {{ opportunityDetails?.description }}
  </app-card-layout-item>

  <app-card-layout-item>
    <app-page-section-header
      title="Open Positions & Certifications"
      i18n-title="@@opportunity-details_positionsTitle"
    ></app-page-section-header>
    <app-open-positions
      [openPositions]="opportunityDetails.positions"
      [userCertificates]="userCertificates"
    ></app-open-positions>
  </app-card-layout-item>
  <ng-container
    *ngIf="
      !opportunityDetails.status ||
      opportunityDetails.status === JobStatusEnum.Canceled
    "
  >
    <app-card-layout-item
      *ngIf="
        hasApplicationFormSubmitted &&
        applicationFormStatus === ApplicationFormStatusEnum.Approved
      "
    >
      <app-page-section-header
        title="Application"
        i18n-title="@@opportunity-details_applicationTitle"
        subtitle="Note: Associates can apply only to one Road Crew opening at a time."
        i18n-subtitle="@@opportunity-details_applicationSubtitle"
      ></app-page-section-header>
      <div class="opportunity-details__actions">
        <app-button-link
          (buttonClick)="updateDetails.emit()"
          label="Update My Details"
          i18n-label="@@opportunity-details_updateMyDetails"
          [appearance]="ButtonAppearanceEnum.Link"
        ></app-button-link>
        <p *ngIf="!opportunityDetails.canApply" class="lblCantApply">
          {{ opportunityDetails.canApplyMessage | translate }}
        </p>
        <app-button-link
          (buttonClick)="applyToOpportunity()"
          label="Quick Apply"
          i18n-label="@@opportunity-details_quickApplyLabel"
          [appearance]="ButtonAppearanceEnum.Primary"
          [disabled]="!opportunityDetails.canApply"
          [color]="ButtonColorEnum.Green"
        ></app-button-link>
      </div>
    </app-card-layout-item>
    <app-card-layout-item
      *ngIf="
        applicationFormStatus ===
          ApplicationFormStatusEnum.RejectedCanNotReapply ||
        applicationFormStatus ===
          ApplicationFormStatusEnum.RejectedCanReapply ||
        applicationFormStatus === ApplicationFormStatusEnum.Applied
      "
    >
      <app-page-section-header
        title="Application"
        i18n-title="@@opportunity-details_applicationTitle"
        [subTitleTemplate]="applicationOnHoldDescriptionTemplate"
      ></app-page-section-header>
      <div
        class="opportunity-details__actions opportunity-details__actions--right"
      >
        <app-button-link
          label="Quick Apply"
          i18n-label="@@opportunity-details_quickApplyLabel"
          [appearance]="ButtonAppearanceEnum.Primary"
          disabled="true"
          [color]="ButtonColorEnum.Green"
        ></app-button-link>
      </div>
    </app-card-layout-item>
    <app-card-layout-item *ngIf="!hasApplicationFormSubmitted">
      <app-page-section-header
        title="Application"
        i18n-title="@@opportunity-details_applicationTitle"
        subtitle="Associates are required to register Road Crew before they can apply for specific positions."
        i18n-subtitle="@@opportunity-details_application-subtitleRoadCrew"
      ></app-page-section-header>
      <div class="opportunity-details__actions">
        <app-button-link
          (buttonClick)="register.emit()"
          label="Register for Road Crew"
          i18n-label="@@opportunity-details_registerForRoadCrew"
          [appearance]="ButtonAppearanceEnum.Link"
          [color]="ButtonColorEnum.Green"
        ></app-button-link>
        <app-button-link
          label="Quick Apply"
          i18n-label="@@opportunity-details_quickApplyLabel"
          [appearance]="ButtonAppearanceEnum.Primary"
          disabled="true"
          [color]="ButtonColorEnum.Green"
        ></app-button-link>
      </div>
    </app-card-layout-item>
  </ng-container>
</app-card-layout>
<ng-template #applicationOnHoldDescriptionTemplate>
  <p class="opportunity-details__warning">
    <app-svg-icon
      [name]="SvgIconEnum.Warning"
      class="opportunity-details__warning-icon"
    ></app-svg-icon>
    <span i18n="@@opportunity-details_applicationOnHoldDescription">
      Your road crew registration hasn't been approved yet. Speak to your
      manager to learn more.
    </span>
  </p>
</ng-template>
