import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import * as fromRoot from '../../../reducers';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private store: Store<AppState>) {}

  canActivate() {
    return this.store
      .pipe(select(fromRoot.isUserAgreeWithTermsAndIsLoggedIn))
      .map(({ isUserAgreeWithTerms, isUserLoggedIn }) => {
        if (!isUserLoggedIn) {
          this.router.navigate(['']);
          return false;
        }

        if (!isUserAgreeWithTerms) {
          this.router.navigate(['disclaimer']);
          return false;
        }

        return true;
      });
  }
}
