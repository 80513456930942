<app-form-field [required]="true">
  <app-label i18n="@@contact-information-edit_email">Email Address</app-label>
  <app-text-input
    [placeholder]="'example@gmail.com'"
    [formControl]="formGroup.get('email')"
  ></app-text-input>
  <app-validation [errors]="formGroup.get('email').errors">
    <app-validation-message
      *ngIf="
        formGroup.get('email').errors?.required ||
        formGroup.get('email').errors?.email
      "
      i18n="@@contact-information-edit_emailRequired"
    >
      Enter a valid email address
    </app-validation-message>
  </app-validation>
</app-form-field>
<app-form-field [required]="true">
  <app-label i18n="@@contact-information-edit_phoneNumber">
    Cell Phone Number
  </app-label>
  <app-text-input
    [placeholder]="'(111)222-1234'"
    inputMask="(000)000-0000"
    [formControl]="formGroup.get('phoneNumber')"
  ></app-text-input>
  <app-validation [errors]="formGroup.get('phoneNumber').errors">
    <app-validation-message
      *ngIf="
        formGroup.get('phoneNumber').errors?.required ||
        formGroup.get('phoneNumber').errors?.pattern
      "
      i18n="@@contact-information-edit_phoneNumberRequired"
    >
      Enter valid cell phone number
    </app-validation-message>
  </app-validation>
</app-form-field>
<app-form-field>
  <app-label i18n="@@contact-information-edit_homePhoneNumber">
    Home Phone Number
  </app-label>
  <app-text-input
    [placeholder]="'(111)222-1234'"
    inputMask="(000)000-0000"
    [formControl]="formGroup.get('homePhoneNumber')"
  ></app-text-input>
  <app-validation [errors]="formGroup.get('homePhoneNumber').errors">
    <app-validation-message
      *ngIf="formGroup.get('homePhoneNumber').errors?.pattern"
      i18n="@@contact-information-edit_homePhoneNumberValid"
    >
      Enter valid home phone number
    </app-validation-message>
  </app-validation>
</app-form-field>
<div class="contact-info-separator"></div>
<app-page-section-header
  title="Emergency Contact"
  i18n-title="@@contact-information-edit_emergencyContact"
  subtitle="In the case of an unexpected emergency, who should we reach out to while
  you're on the road?"
  i18n-subtitle="@@contact-information-edit_emergencyContactInformation"
></app-page-section-header>
<app-form-field [required]="true">
  <app-label i18n="@@contact-information-edit_emergencyContactName">
    Emergency Contact Name
  </app-label>
  <app-text-input
    [placeholder]="'Jane Doe'"
    [formControl]="formGroup.get('emergencyContactName')"
  ></app-text-input>
  <app-validation [errors]="formGroup.get('emergencyContactName').errors">
    <app-validation-message
      *ngIf="formGroup.get('emergencyContactName').errors?.required"
      i18n="@@contact-information-edit_emergencyContactNameRequired"
    >
      Enter an emergency contact name
    </app-validation-message>
  </app-validation>
</app-form-field>
<app-form-field [required]="true">
  <app-label i18n="@@contact-information-edit_emergencyContactPhoneNumber">
    Contact Phone
  </app-label>
  <app-text-input
    [placeholder]="'(111)222-1234'"
    inputMask="(000)000-0000"
    [formControl]="formGroup.get('emergencyContactPhoneNumber')"
  ></app-text-input>
  <app-validation
    [errors]="formGroup.get('emergencyContactPhoneNumber').errors"
  >
    <app-validation-message
      *ngIf="
        formGroup.get('phoneNumber').errors?.required ||
        formGroup.get('phoneNumber').errors?.pattern
      "
      i18n="@@contact-information-edit_emergencyContactPhoneNumberRequired"
    >
      Enter a valid emergency contact phone number.
    </app-validation-message>
  </app-validation>
</app-form-field>
<app-form-field [required]="true">
  <app-label i18n="@@contact-information-edit_emergencyContactRelationship">
    Relationship
  </app-label>
  <app-text-input
    i18n-placeholder="@@contact-information-edit_emergencyContactPlaceholder"
    placeholder="e.g. Spouse, parent, friend etc."
    [formControl]="formGroup.get('emergencyContactRelationship')"
  ></app-text-input>
  <app-validation
    [errors]="formGroup.get('emergencyContactRelationship').errors"
  >
    <app-validation-message
      *ngIf="formGroup.get('emergencyContactRelationship').errors?.required"
      i18n="@@contact-information-edit_emergencyContactRelationshipRequired"
    >
      How do you know this person?
    </app-validation-message>
  </app-validation>
</app-form-field>
