import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppliedOpportunityListItem } from '../../../../../core/dataEntities/opportunities/appliedOpportunityListItem';
import { ChipAppearance } from '../../../../../shared/enums/Chip';
import { OpportunityType } from '../../../../../core/enums/OpportunityType';
import { SvgIconColor, SvgIconName } from '../../../../../shared/enums/SvgIcon';
import { StringHelpers } from '../../../../../shared/helpers/string.helpers';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent {
  ChipAppearanceEnum = ChipAppearance;
  SvgIconNameEnum = SvgIconName;
  SvgIconColorEnum = SvgIconColor;
  StringHelpers = StringHelpers;

  @Input() applicationUpdates: AppliedOpportunityListItem[];
  @Input() pendingUpdates: AppliedOpportunityListItem[];
  @Output() openItem = new EventEmitter();

  constructor() {}

  openDetails(item) {
    this.openItem.emit({
      id: item.otherId,
      opportunityId: item.opportunityID,
      opportunityType: OpportunityType.RoadCrew,
      isNew: item.isNew
    });
  }
}
