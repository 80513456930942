import { Component, Input, TemplateRef } from '@angular/core';
import { PageSectionHeaderAppearance } from '../../enums/PageSectionHeader';

@Component({
  selector: 'app-page-section-header',
  templateUrl: './page-section-header.component.html',
  styleUrls: ['./page-section-header.component.scss']
})
export class PageSectionHeaderComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() subTitleTemplate: TemplateRef<any>;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() appearance = PageSectionHeaderAppearance.Blue;

  public PageSectionHeaderAppearanceEnum = PageSectionHeaderAppearance;

  constructor() {}
}
