export enum SiteMapItemKeys {
  Root = 'Root',
  Wages = 'Wages',
  WagesDetails = 'WagesDetails',
  Training = 'Training',
  Goal = 'Goal',
  GoalEdit = 'GoalEdit',
  NewsFeed = 'NewsFeed',
  NewsDetails = 'NewsDetails',
  PreviewNewsPost = 'PreviewNewsPost',
  ShiftRequests = 'ShiftRequests',
  ShiftCreate = 'ShiftCreate',
  ShiftEdit = 'ShiftEdit',
  ShiftPreview = 'ShiftPreview',
  ShiftDetails = 'ShiftDetails',
  Settings = 'Settings',
  Survey = 'Survey',
  Opportunity = 'Opportunity',
  LandingPage = 'Landing',
  Disclaimer = 'Disclaimer',
  Resources = 'Resources',
  ResourcesFAQ = 'ResourcesFAQ',
  ResourcesTeam = 'ResourcesTeam',
  ResourcesStars = 'ResourcesStars',
  ResourcesStar = 'ResourcesStar',
  MyTeam = 'MyTeam',
  MyTeamMemberProduction = 'MyTeamMemberProduction',
  MyTeamMemberCheckInHistory = 'MyTeamMemberCheckInHistory',
  WeeklyCheckIn = 'WeeklyCheckIn',
  AssociateCheckInHistory = 'AssociateCheckInHistory',
  AssociateProduction = 'AssociateProduction',
  AddPwa = 'AddPwa',
  DynamicContent = 'DynamicContent',
  Covid = 'Covid',
  TakePhoto = 'TakePhoto',
  OpportunityDetails = 'OpportunityDetails',
  OpportunitiesList = 'OpportunitiesList',
  RoadCrewApplication = 'RoadCrewApplication',
  RoadCrew = 'RoadCrew'
}
