<main class="main-container">
  <app-page-title
    data-cy="payHistoryHeading"
    [appearance]="PageTitleAppearanceEnum.Transparent"
    title="Earnings History"
    i18n-title="@@pay-history_earningsHistory"
  ></app-page-title>
  <app-loader [isLoading]="isPayHistoryLoading$ | async"></app-loader>

  <div *ngIf="(isPayHistoryLoading$ | async) === false" class="pay-history">
    <ng-container *ngIf="(paymentHistory | async).length != 0">
      <app-menu data-cy="payHistoryList">
        <ng-container
          *ngFor="let payment of paymentHistory | async; let i = index"
        >
          <ng-template
            #currentWeekSubTitleTemplate
            i18n="@@pay-history_currentWeek"
          >
            Current Week:
            <time [attr.datetime]="payment.weekStartDate">
              {{ payment.weekStartDate | date: 'MM/dd' }}
            </time>
            -
            <time [attr.datetime]="payment.weekEndingDate">
              {{ payment.weekEndingDate | date: 'MM/dd' }}
            </time>
          </ng-template>
          <ng-template #subTitleTemplate>
            <ng-container i18n="@@pay-history_weeksAgo">
              {payment.weeksAgo, plural, =1 {Last week: } =2 {Two weeks ago: }
              other {}}
            </ng-container>
            <time [attr.datetime]="payment.weekStartDate">
              {{ payment.weekStartDate | date: 'MM/dd' }}
            </time>
            -
            <time [attr.datetime]="payment.weekEndingDate">
              {{ payment.weekEndingDate | date: 'MM/dd' }}
            </time>
          </ng-template>
          <app-menu-item
            [title]="payment.earnings | currency"
            [subTitleTemplate]="
              payment.isCurrentWeek
                ? currentWeekSubTitleTemplate
                : subTitleTemplate
            "
            [link]="['/wages', payment.id, 'pay']"
            [isHighlighted]="payment.isCurrentWeek"
            [appearance]="MenuAppearanceEnum.Green"
            [titleFont]="MenuTitleFontEnum.Roboto"
            [dataCy]="payment.isCurrentWeek ? 'currentWorkWeek' : 'workWeek'"
          ></app-menu-item>
        </ng-container>
      </app-menu>

      <app-footer *appFeatureToggle="'!' + FeaturesEnum.Opportunities">
        <ng-container *ngIf="(isRoadCrewEnabled$ | async) === false">
          <app-footer-note
            data-cy="earningsHistoryEndTitle"
            i18n="@@pay-history_endOfHistory"
          >
            End of earnings history records
          </app-footer-note>
        </ng-container>
      </app-footer>
    </ng-container>

    <ng-template #emptyStateTranslation i18n="@@pay-history_noDataFound">
      No recent work history is available at the moment.
    </ng-template>

    <app-empty-state
      *ngIf="(paymentHistory | async).length == 0"
      [emptyStateTemplate]="emptyStateTranslation"
    ></app-empty-state>
  </div>
</main>
