import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import { LayoutActions, UserActions } from '../../core/actions';
import { ProfileActions } from '../actions';
import { WeeklyCheckinList } from '../../core/dataEntities/profile/weeklyCheckinList';
import _ from 'lodash';
import { AssociateProductionDetails } from '../../core/dataEntities/associate/associateProductionDetails';

export const profileFeatureKey = 'profile';

export interface ProfileState {
  weeklyCheckinList: WeeklyCheckinList;
  productionDetails: AssociateProductionDetails;
  isWeeklyCheckinLoading: boolean;
  isProductionDetailsLoading: boolean;
}

export const initialState: ProfileState = {
  weeklyCheckinList: null,
  productionDetails: null,
  isWeeklyCheckinLoading: false,
  isProductionDetailsLoading: false
};

export const profileReducer = createReducer(
  initialState,
  on(ProfileActions.loadWeeklyCheckinList, (state, action) => {
    return {
      ...state,
      isWeeklyCheckinLoading: true
    };
  }),
  on(ProfileActions.weeklyCheckinListLoaded, (state, action) => {
    return {
      ...state,
      weeklyCheckinList: action.weeklyCheckinList,
      isWeeklyCheckinLoading: false
    };
  }),
  on(LayoutActions.showError, LayoutActions.apiOffline, state => {
    return {
      ...state,
      isWeeklyCheckinLoading: false
    };
  }),
  on(ProfileActions.loadProductionDetails, (state, action) => {
    return {
      ...state,
      isProductionDetailsLoading: true
    };
  }),
  on(ProfileActions.productionDetailsLoaded, (state, action) => {
    return {
      ...state,
      productionDetails: action.productionDetails,
      isProductionDetailsLoading: false
    };
  }),
  on(ProfileActions.toggleProductionDetailsIsLoading, (state, action) => {
    return {
      ...state,
      isProductionDetailsLoading: action.isLoading
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(state: ProfileState | undefined, action: Action) {
  return profileReducer(state, action);
}

export const getProfileState = createFeatureSelector<ProfileState>(
  profileFeatureKey
);

export const selectIsWeeklyCheckinLoading = createSelector(
  getProfileState,
  (state: ProfileState) => state.isWeeklyCheckinLoading
);

export const selectWeeklyCheckinList = createSelector(
  getProfileState,
  (state: ProfileState) => state.weeklyCheckinList
);

export const selectLastCheckIn = createSelector(getProfileState, state => {
  const checkIns = state.weeklyCheckinList.weeklyCheckIns;
  if (checkIns.length === 0) {
    return {
      associateGuid: null,
      subDept: null,
      appearance: null,
      message: null,
      score: null,
      weekStartDate: null,
      weekEndingDate: null
    };
  }

  return _.first(_.orderBy(checkIns, ['weekEndingDate'], ['desc']));
});

export const selectIsProductionDetailsLoading = createSelector(
  getProfileState,
  (state: ProfileState) => state.isProductionDetailsLoading
);

export const selectProductionDetails = createSelector(
  getProfileState,
  (state: ProfileState) => state.productionDetails
);
