<main class="main-container">
  <ng-template #shiftRequestsTitle i18n="@@shift-requests_pageTitle">
    Shift requests
  </ng-template>
  <ng-template #emptyState i18n="@@shift-requests_emptyState">
    You have no open shift requests.
  </ng-template>
  <app-page-title [titleTemplate]="shiftRequestsTitle"></app-page-title>

  <div class="shift-requests">
    <div class="shift-requests__card">
      <app-card-layout>
        <app-card-layout-item>
          <h2
            class="shift-requests__title"
            i18n="@@shift-requests_newShiftRequests"
          >
            New shift requests
          </h2>
          <app-svg-icon
            [name]="SvgIconNameEnum.PeopleWCross"
            class="shift-requests__icon"
          ></app-svg-icon>
          <p class="shift-requests__text" i18n="@@shiftShorthandedNotice">
            Short-handed? Recruit all associates at your site to apply for
            additional shifts.
          </p>

          <ng-template #createShift i18n="@@shift-requests_createShiftRequest">
            Create Shift Request
          </ng-template>
          <app-button-link
            [link]="'create'"
            [labelTemplate]="createShift"
          ></app-button-link>
        </app-card-layout-item>
      </app-card-layout>
    </div>

    <h2 class="shift-requests__title" i18n="@@shift-requests_yourOpenShifts">
      Your open shifts
    </h2>
    <app-menu
      *ngIf="
        (isManagerShiftsLoading$ | async) === false &&
        (managerShifts$ | async)?.length > 0
      "
    >
      <ng-container *ngFor="let shift of managerShifts$ | async">
        <ng-template #shiftItemTitle i18n="@@shift-requests_itemTitle">
          Shift openings: {{ shift.shiftDate | date: 'MM/dd/yy' }}
        </ng-template>

        <ng-template #shiftItemSubTitle i18n="@@shift-requests_itemSubTitle">
          Created: {{ shift.createdAt | date: 'hh:mm a MM/dd/yy' }}
        </ng-template>

        <app-menu-item
          [titleTemplate]="shiftItemTitle"
          [subTitleTemplate]="shiftItemSubTitle"
          [link]="['/shifts/details', shift.id]"
          [iconName]="SvgIconNameEnum.Shift"
          [iconColorPrimary]="SvgIconColorEnum.Cinnabar"
          [iconColorSecondary]="SvgIconColorEnum.MonaLisa"
        ></app-menu-item>
      </ng-container>
    </app-menu>
    <app-empty-state
      *ngIf="
        (managerShifts$ | async)?.length === 0 &&
        (isManagerShiftsLoading$ | async) === false
      "
      [emptyStateTemplate]="emptyState"
    ></app-empty-state>
  </div>
  <app-loader [isLoading]="isManagerShiftsLoading$ | async"></app-loader>
</main>
