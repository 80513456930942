import { Pipe, PipeTransform } from '@angular/core';
import { StatsLevel } from '../../../../enums/StatsLevel';
import { TranslationService } from '../../../../services/translation.service';
import { TranslationMessages } from '../../../../enums/TranslationMessages';

@Pipe({
  name: 'associateStatsLevelTitle'
})
export class AssociateStatsLevelTitlePipe implements PipeTransform {
  constructor(private translation: TranslationService) {}

  transform(value: StatsLevel, ...args: any[]): string {
    switch (value) {
      case StatsLevel.Top10:
        return this.translation.translate(
          TranslationMessages.AssociateStatsLevelTitleTop10
        );
      case StatsLevel.Top20:
        return this.translation.translate(
          TranslationMessages.AssociateStatsLevelTitleTop20
        );
      case StatsLevel.Top30:
        return this.translation.translate(
          TranslationMessages.AssociateStatsLevelTitleTop30
        );
      case StatsLevel.Top50:
        return this.translation.translate(
          TranslationMessages.AssociateStatsLevelTitleTop50
        );
      case StatsLevel.Bottom50:
        return this.translation.translate(
          TranslationMessages.AssociateStatsLevelTitleBottom50
        );
      case StatsLevel.Bottom30:
        return this.translation.translate(
          TranslationMessages.AssociateStatsLevelTitleBottom30
        );
      case StatsLevel.Bottom20:
        return this.translation.translate(
          TranslationMessages.AssociateStatsLevelTitleBottom20
        );
      case StatsLevel.Bottom10:
        return this.translation.translate(
          TranslationMessages.AssociateStatsLevelTitleBottom10
        );
      default:
        return '';
    }
  }
}
