import { createEntityAdapter } from '@ngrx/entity';
import {
  ServerDataTableAdapter,
  ServerDataTableAdapterOptions
} from './server-data-table-adapter/server-data-table-adapter';
import {
  ClientDataTableAdapter,
  ColumnSortConfig,
  DataTableFilter
} from './client-data-table-adapter/client-data-table-adapter';

import { DataTableRow } from './data-table-base/data-table-row';

export * from './data-table-base/data-table-actions';
export * from './data-table-base/data-table-row';
export * from './data-table-base/sort-direction';
export * from './data-table-base/sort';
export * from './server-data-table-adapter/server-data-table-adapter';
export * from './server-data-table-adapter/server-data-table-state';
export * from './client-data-table-adapter/client-data-table-adapter';
export * from './client-data-table-adapter/client-data-table-state';

export function createServerDataTableAdapter<T extends DataTableRow>(
  options: ServerDataTableAdapterOptions = {}
): ServerDataTableAdapter<T> {
  const entityAdapter = createEntityAdapter<T>();
  return new ServerDataTableAdapter<T>(entityAdapter, options);
}

export function createClientDataTableAdapter<T extends DataTableRow>(
  sort: ColumnSortConfig<T> = {},
  filter: DataTableFilter<T> = null
): ClientDataTableAdapter<T> {
  const entityAdapter = createEntityAdapter<T>();
  return new ClientDataTableAdapter<T>(entityAdapter, sort, filter);
}
