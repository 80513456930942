import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SexType } from '../enums/SexType';
import { JobTitles } from '../enums/JobTitles';
import { RequiredIf } from '../../../../shared/validators/conditionally-required.validator';
import { RoadCrewApplication } from '../../../../core/dataEntities/applicationForm/roadCrewApplication';
import { TypeOfIDs } from '../enums/TypeOfIDs';
import { MinDateValidator } from '../../../../shared/validators/min-date.validator';
import moment from 'moment';

@Injectable()
export class ApplicationFormService {
  today = moment
    // obscure(meaning that timezone is missing) dates in `.utc()` are treated as utc, so user's 'today' is accurate
    .utc(moment().format('YYYY-MM-DD'))
    .startOf('day')
    .toJSON();

  constructor(private fb: FormBuilder) {}

  getApplicationFormGroup(form?: RoadCrewApplication): FormGroup {
    if (!form) {
      form = this.createApplicationFormObject();
    }

    const applicationForm = this.fb.group({
      personalDetails: this.fb.group({
        firstName: this.fb.control(
          form.personalInfo.firstName,
          Validators.required
        ),
        lastName: this.fb.control(
          form.personalInfo.lastName,
          Validators.required
        ),
        sex: this.fb.control(
          form.personalInfo.sex || SexType.Male,
          Validators.required
        ),
        jobTitle: this.fb.control(
          form.personalInfo.jobTitle || JobTitles.WarehouseAssociate,
          Validators.required
        )
      }),
      contactInfo: this.fb.group({
        email: this.fb.control(form.contactInfo.email || '', [
          Validators.required,
          Validators.email
        ]),
        phoneNumber: this.fb.control(form.contactInfo.phone || '', [
          Validators.required,
          Validators.pattern('^[0-9]{10}$')
        ]),
        homePhoneNumber: this.fb.control(
          form.contactInfo.homePhone || '',
          Validators.pattern('^[0-9]{10}$')
        ),
        emergencyContactName: this.fb.control(
          form.contactInfo.emergencyContactName,
          Validators.required
        ),
        emergencyContactPhoneNumber: this.fb.control(
          form.contactInfo.emergencyContactPhone || '',
          [Validators.required, Validators.pattern('^[0-9]{10}$')]
        ),
        emergencyContactRelationship: this.fb.control(
          form.contactInfo.emergencyContactRelationship,
          Validators.required
        )
      }),
      licence: this.fb.group(
        {
          preferredAirport: this.fb.control(
            form.travelInfo.preferredAirport,
            Validators.required
          ),
          isDriver: this.fb.control(
            form.travelInfo.availableAsDriver == null
              ? true
              : form.travelInfo.availableAsDriver,
            Validators.required
          ),
          hasInsurance: this.fb.control(
            form.travelInfo.hasInsurance == null
              ? true
              : form.travelInfo.hasInsurance,
            Validators.required
          ),
          rejectionReason: '',
          documents: this.fb.control(
            (form.travelInfo.document.documentPhotos &&
              form.travelInfo.document.documentPhotos.map(d => {
                return { file: d, name: null };
              })) ||
              [],
            Validators.required
          ),
          licenceIssuedState: this.fb.control(
            form.travelInfo.document['licenseIssuedState'] || ''
          ),
          licenceNumber: this.fb.control(
            form.travelInfo.document['licenseNumber'] || ''
          ),
          licenceExpirationDate: this.fb.control(
            form.travelInfo.document['licenseExpirationDate'] || '',
            MinDateValidator(this.today)
          ),
          insuranceExpirationDate: this.fb.control(
            form.travelInfo.document['insuranceExpirationDate'] || '',
            form.travelInfo.hasInsurance ? MinDateValidator(this.today) : null
          ),
          typeOfID: this.fb.control(form.travelInfo.document['idType'] || ''),
          IDExpirationDate: this.fb.control(
            form.travelInfo.document['idExpirationDate'] || '',
            MinDateValidator(this.today)
          )
        },
        {
          validators: [
            RequiredIf(
              [
                'licenceIssuedState',
                'licenceNumber',
                'licenceExpirationDate',
                'insuranceExpirationDate'
              ],
              formGroup =>
                formGroup.value.isDriver && formGroup.value.hasInsurance
            ),
            RequiredIf(
              ['licenceIssuedState', 'licenceNumber', 'licenceExpirationDate'],
              formGroup =>
                formGroup.value.isDriver && !formGroup.value.hasInsurance
            ),
            RequiredIf(
              ['typeOfID', 'IDExpirationDate'],
              formGroup => !formGroup.value.isDriver
            )
          ]
        }
      ),
      certifications: this.fb.group({
        certificationsChecked: this.fb.control(
          form.certificateInfo.certificates || []
        ),
        additionalCertifications: this.fb.control(
          form.certificateInfo.additionalCertificates || ''
        )
      }),
      signature: this.fb.group({
        signature: this.fb.control(
          form.signatureInfo.signature || '',
          Validators.required
        ),
        signDate: this.fb.control(form.signatureInfo.signDate)
      })
    });
    applicationForm
      .get('licence')
      .get('isDriver')
      .valueChanges.subscribe(value => {
        this.clearLicenseFormDetails(applicationForm, value);
      });
    return applicationForm;
  }

  createApplicationFormObject(): RoadCrewApplication {
    return {
      personalInfo: {
        firstName: '',
        lastName: '',
        sex: '',
        jobTitle: ''
      },
      contactInfo: {
        email: '',
        phone: '',
        homePhone: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
        emergencyContactRelationship: ''
      },
      travelInfo: {
        preferredAirport: '',
        rejectionReason: '',
        availableAsDriver: null,
        hasInsurance: null,
        document: {
          documentPhotos: [],
          licenseIssuedState: '',
          licenseExpirationDate: null,
          licenseNumber: '',
          insuranceExpirationDate: null,
          idType: '',
          idExpirationDate: null
        }
      },
      certificateInfo: {
        certificates: [],
        additionalCertificates: ''
      },
      signatureInfo: {
        signature: '',
        signDate: null
      }
    };
  }

  getApplicationFormObject(form: FormGroup): RoadCrewApplication {
    if (form.value) {
      return {
        personalInfo: form.value.personalDetails,
        contactInfo: {
          email: form.value.contactInfo.email,
          phone: form.value.contactInfo.phoneNumber,
          homePhone: form.value.contactInfo.homePhoneNumber,
          emergencyContactName: form.value.contactInfo.emergencyContactName,
          emergencyContactPhone:
            form.value.contactInfo.emergencyContactPhoneNumber,
          emergencyContactRelationship:
            form.value.contactInfo.emergencyContactRelationship
        },
        travelInfo: {
          preferredAirport: form.value.licence.preferredAirport,
          availableAsDriver: form.value.licence.isDriver,
          hasInsurance: form.value.licence.hasInsurance,
          rejectionReason: form.value.licence.rejectionReason,
          document: form.value.licence.isDriver
            ? {
                documentPhotos: form.value.licence.documents,
                licenseIssuedState: form.value.licence.licenceIssuedState,
                licenseExpirationDate: form.value.licence.licenceExpirationDate,
                licenseNumber: form.value.licence.licenceNumber,
                insuranceExpirationDate:
                  form.value.licence.insuranceExpirationDate,
                idType: TypeOfIDs.DriversLicense
              }
            : {
                documentPhotos: form.value.licence.documents,
                idType: form.value.licence.typeOfID,
                idExpirationDate: form.value.licence.IDExpirationDate
              }
        },
        certificateInfo: {
          certificates: form.value.certifications.certificationsChecked,
          additionalCertificates:
            form.value.certifications.additionalCertifications
        },
        signatureInfo: {
          signature: form.value.signature.signature,
          signDate: form.value.signature.signDate
        }
      };
    } else {
      return null;
    }
  }

  clearLicenseFormDetails(form: FormGroup, isDriver: boolean) {
    const licenceFormGroup = form.get('licence');
    if (!isDriver) {
      licenceFormGroup.get('licenceNumber').setValue('', { emitEvent: false });
      licenceFormGroup
        .get('licenceIssuedState')
        .setValue('', { emitEvent: false });
      licenceFormGroup
        .get('licenceExpirationDate')
        .setValue('', { emitEvent: false });
      licenceFormGroup
        .get('insuranceExpirationDate')
        .setValue('', { emitEvent: false });
    } else {
      licenceFormGroup.get('typeOfID').setValue('', { emitEvent: false });
      licenceFormGroup
        .get('IDExpirationDate')
        .setValue('', { emitEvent: false });
    }
    licenceFormGroup.get('documents').setValue('', { emitEvent: false });
  }

  // Mocked form data used only for storybook to go faster through whole form
  getExampleApplicationForm(): FormGroup {
    const applicationForm = this.fb.group({
      personalDetails: this.fb.group({
        firstName: this.fb.control('John', Validators.required),
        lastName: this.fb.control('Second', Validators.required),
        sex: this.fb.control(SexType.Male, Validators.required),
        jobTitle: this.fb.control(
          JobTitles.WarehouseAssociate,
          Validators.required
        )
      }),
      contactInfo: this.fb.group({
        email: this.fb.control('john@gmail.com', [
          Validators.required,
          Validators.email
        ]),
        phoneNumber: this.fb.control('0000000000', [
          Validators.required,
          Validators.pattern('^[0-9]{10}$')
        ]),
        homePhoneNumber: this.fb.control(
          null,
          Validators.pattern('^[0-9]{10}$')
        ),
        emergencyContactName: this.fb.control('Michael', Validators.required),
        emergencyContactPhoneNumber: this.fb.control('0000000000', [
          Validators.required,
          Validators.pattern('^[0-9]{10}$')
        ]),
        emergencyContactRelationship: this.fb.control(
          'Brother',
          Validators.required
        )
      }),
      licence: this.fb.group(
        {
          preferredAirport: this.fb.control(
            'Felker Army Air Field',
            Validators.required
          ),
          rejectionReason: '',
          isDriver: this.fb.control(false, Validators.required),
          hasInsurance: this.fb.control(false),
          documents: this.fb.control(''),
          licenceIssuedState: this.fb.control(''),
          licenceNumber: this.fb.control(''),
          licenceExpirationDate: this.fb.control(''),
          insuranceExpirationDate: this.fb.control(''),
          typeOfID: this.fb.control('passport'),
          IDExpirationDate: this.fb.control('2020-06-06')
        },
        {
          validators: [
            RequiredIf(
              [
                'licenceIssuedState',
                'licenceNumber',
                'licenceExpirationDate',
                'insuranceExpirationDate'
              ],
              formGroup =>
                formGroup.value.isDriver && formGroup.value.hasInsurance
            ),
            RequiredIf(
              ['typeOfID', 'IDExpirationDate'],
              formGroup => !formGroup.value.isDriver
            )
          ]
        }
      ),
      certifications: this.fb.group({
        certificationsChecked: this.fb.control([]),
        additionalCertifications: this.fb.control('')
      }),
      signature: this.fb.group({
        signature: this.fb.control('', Validators.required),
        signDate: this.fb.control('')
      })
    });
    applicationForm
      .get('licence')
      .get('isDriver')
      .valueChanges.subscribe(value => {
        this.clearLicenseFormDetails(applicationForm, value);
      });
    return applicationForm;
  }
}
