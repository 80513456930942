import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '../../../../../shared/modules/modal/dialog-config';
import { DialogRef } from '../../../../../shared/modules/modal/dialog-ref';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';

@Component({
  selector: 'app-shift-delete-modal',
  templateUrl: './shift-delete-modal.component.html',
  styleUrls: ['./shift-delete-modal.component.scss']
})
export class ShiftDeleteModalComponent implements OnInit {
  public ButtonAppearanceEnum = ButtonAppearance;
  public ButtonColorEnum = ButtonColor;

  constructor(
    private modalRef: DialogRef<ShiftDeleteModalComponent>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  deleteShift() {
    this.modalRef.close({ success: true });
  }

  closeModal() {
    this.modalRef.close({ success: false });
  }
}
