import { Component, OnInit, Input } from '@angular/core';
import { AssociateProductionDetails } from '../../../../../core/dataEntities/associate/associateProductionDetails';
import { PageTitleAppearance } from '../../../../enums/PageTitle';
import { StatsAppearance } from '../../../../enums/Stats';
import { ItemType } from '../../../../../wages/modules/goal/enums/ItemType';
import { TranslationService } from '../../../../services/translation.service';
import { TranslationMessages } from '../../../../enums/TranslationMessages';
import { ButtonSize } from '../../../../enums/ButtonLink';
import { AboutWeeklyObservationsComponent } from '../../about-weekly-observations/about-weekly-observations.component';
import { BottomSheetService } from '../../../../modules/modal/bottom-sheet/bottom-sheet.service';

@Component({
  selector: 'app-trainee-production-details',
  templateUrl: './trainee-production-details.component.html',
  styleUrls: ['./trainee-production-details.component.scss']
})
export class TraineeProductionDetailsComponent implements OnInit {
  public ButtonSizeEnum = ButtonSize;

  @Input() productionDetails: AssociateProductionDetails;
  @Input() isAssociateView = false;
  @Input() associateName = null;

  public PageTitleAppearanceEnum = PageTitleAppearance;
  public StatsAppearanceEnum = StatsAppearance;
  public ItemTypeEnum = ItemType;
  public options = [];
  public activeItemType = ItemType.Pallets;

  constructor(
    translationService: TranslationService,
    private bottomSheetService: BottomSheetService
  ) {
    this.options = [
      {
        id: ItemType.Pallets,
        name: translationService.translate(TranslationMessages.PalletsPerHour)
      },
      {
        id: ItemType.Cases,
        name: translationService.translate(TranslationMessages.CasesPerHour)
      }
    ];
  }

  ngOnInit() {}

  onChange(value) {
    this.activeItemType = value;
  }

  openAboutObservations() {
    this.bottomSheetService.open(AboutWeeklyObservationsComponent);
  }
}
