import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedComponentsModule } from '../shared/components/shared-components/shared-components.module';
import * as fromPayHistory from './reducers/pay-history.reducer';
import * as fromWeekPay from './reducers/week-pay.reducer';
import { WagesEffects } from './effects/wages.effects';
import { WagesComponent } from './wages.component';
import { WagesRoutingModule } from './wages-routing.module';
import { EarningsBreakdownTableComponent } from './components/earnings-breakdown-table/earnings-breakdown-table.component';
import { PayHistoryComponent } from './containers/pay-history/pay-history.component';
import { PayComponent } from './containers/pay/pay.component';
import { JobDetailsCardComponent } from './components/job-details-card/job-details-card.component';
import { ModalLayoutComponent } from '../shared/components/modal/modal-layout/modal-layout.component';
import { HourlyEarningsDetailsCardComponent } from './components/hourly-earnings-details-card/hourly-earnings-details-card.component';
import { ExceptionEarningsDetailsCardComponent } from './components/exception-earnings-details-card/exception-earnings-details-card.component';
import { ModalModule } from '../shared/modules/modal/modal.module';
import { GoalModule } from './modules/goal/goal.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ServiceEarningsDetailsCardComponent } from './components/service-earnings-details-card/service-earnings-details-card.component';
import { WagesService } from '../core/contracts/WagesService';
import { WagesApiService } from './services/wages.api.service';
import { EarningsTabsComponent } from './components/earnings-tabs/earnings-tabs.component';
import { TrainingComponent } from './containers/training/training.component';
import { EarningsHeaderComponent } from './components/earnings-header/earnings-header.component';
import { EarningsSummaryComponent } from './components/earnings-summary/earnings-summary.component';
import { GoalComponent } from './containers/goal/goal.component';
import { WorkModule } from './modules/work/work.module';
import { DirectivesModule } from '../core/directives/directives.module';
import { OpportunitiesParentComponent } from './modules/work/containers/opportunities-parent/opportunities-parent.component';

@NgModule({
  declarations: [
    WagesComponent,
    PayHistoryComponent,
    PayComponent,
    EarningsBreakdownTableComponent,
    JobDetailsCardComponent,
    HourlyEarningsDetailsCardComponent,
    ExceptionEarningsDetailsCardComponent,
    ServiceEarningsDetailsCardComponent,
    TrainingComponent,
    EarningsTabsComponent,
    EarningsHeaderComponent,
    EarningsSummaryComponent,
    GoalComponent,
    OpportunitiesParentComponent
  ],
  imports: [
    ModalModule,
    SharedComponentsModule,
    StoreModule.forFeature(
      fromPayHistory.payHistoryFeatureKey,
      fromPayHistory.reducer
    ),
    StoreModule.forFeature(fromWeekPay.weekPayFeatureKey, fromWeekPay.reducer),
    EffectsModule.forFeature([WagesEffects]),
    PipesModule,
    GoalModule,
    WagesRoutingModule,
    WorkModule,
    DirectivesModule
  ],
  providers: [
    {
      provide: WagesService,
      useClass: WagesApiService
    }
  ],
  exports: [
    WagesComponent,
    PayHistoryComponent,
    PayComponent,
    ModalLayoutComponent
  ],
  entryComponents: [
    JobDetailsCardComponent,
    HourlyEarningsDetailsCardComponent,
    ExceptionEarningsDetailsCardComponent,
    ServiceEarningsDetailsCardComponent
  ]
})
export class WagesModule {}
