<ng-sidebar-container
  [ngClass]="{
    'sidebar-container': true,
    'sidebar-container--open': isDrawerOpen
  }"
>
  <ng-sidebar
    #drawer
    [mode]="'over'"
    [opened]="isDrawerOpen"
    [closeOnClickOutside]="true"
    [showBackdrop]="true"
    [trapFocus]="true"
    (onClosed)="drawerClosed?.emit()"
    [keyClose]="true"
    data-cy="accountPage"
  >
    <div class="drawer" #content>
      <ng-content select="[slot='drawer']"></ng-content>
    </div>
  </ng-sidebar>
  <div class="page-content" ng-sidebar-content>
    <ng-content></ng-content>
    <app-navigation-bar
      *ngIf="(hideNavigationBar$ | async) === false"
    ></app-navigation-bar>
  </div>
</ng-sidebar-container>
