import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ButtonAppearance,
  ButtonColor,
  ButtonType
} from '../../../../../../shared/enums/ButtonLink';
import moment from 'moment';
import { IdDateFormat } from '../../../../../../shared/constants';

@Component({
  selector: 'app-signature-edit',
  templateUrl: './signature-edit.component.html',
  styleUrls: ['./signature-edit.component.scss']
})
export class SignatureEditComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() termsAndConditions: any;
  @Output() submitForm = new EventEmitter();
  isDriver: boolean;
  firstName: string;
  lastName: string;
  signatureDate: Date;
  homeState: string;
  specialStates: Array<string> = ['CA', 'NH', 'WA'];
  public ButtonAppearanceEnum = ButtonAppearance;
  public ButtonColorEnum = ButtonColor;
  public ButtonTypeEnum = ButtonType;
  constructor() {}

  ngOnInit() {
    if (this.formGroup.parent) {
      this.firstName = this.formGroup.parent
        .get('personalDetails')
        .get('firstName').value;
      this.lastName = this.formGroup.parent
        .get('personalDetails')
        .get('lastName').value;
      this.isDriver = this.formGroup.parent
        .get('licence')
        .get('isDriver').value;
      this.signatureDate = new Date();
      this.homeState = this.formGroup.parent
        .get('licence')
        .get('licenceIssuedState').value;

      if (this.isDriver) {
        if (!this.specialStates.includes(this.homeState)) {
          const filteredArray = this.termsAndConditions.filter(
            st => st.id !== 'mvrCA' && st.id !== 'mvrNH' && st.id !== 'mvrWA'
          );

          this.termsAndConditions = filteredArray;
        } else {
          if (this.homeState === 'CA') {
            const filteredArray = this.termsAndConditions.filter(
              st =>
                st.id !== 'mvrStandard' &&
                st.id !== 'mvrNH' &&
                st.id !== 'mvrWA'
            );
            this.termsAndConditions = filteredArray;
          } else if (this.homeState === 'NH') {
            const filteredArray = this.termsAndConditions.filter(
              st =>
                st.id !== 'mvrStandard' &&
                st.id !== 'mvrCA' &&
                st.id !== 'mvrWA'
            );
            this.termsAndConditions = filteredArray;
          } else {
            const filteredArray = this.termsAndConditions.filter(
              st =>
                st.id !== 'mvrStandard' &&
                st.id !== 'mvrCA' &&
                st.id !== 'mvrNH'
            );
            this.termsAndConditions = filteredArray;
          }
        }
      } else {
        const filteredArray = this.termsAndConditions.filter(
          st =>
            st.id !== 'mvrCA' &&
            st.id !== 'mvrNH' &&
            st.id !== 'mvrWA' &&
            st.id !== 'mvrStandard'
        );
        this.termsAndConditions = filteredArray;
      }
    }
  }
  onSubmit() {
    this.formGroup
      .get('signDate')
      .setValue(moment.utc(this.signatureDate).format(IdDateFormat));
    this.submitForm.emit();
  }
}
