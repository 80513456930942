import { Component, OnInit, Inject } from '@angular/core';
import { ButtonAppearance, ButtonSize } from '../../../shared/enums/ButtonLink';
import { Router } from '@angular/router';
import {DIALOG_DATA} from '../../../shared/modules/modal/dialog-config';
import {DialogRef} from '../../../shared/modules/modal/dialog-ref';
import { SvgIconName } from '../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-remainder',
  templateUrl: './remainder.component.html',
  styleUrls: ['./remainder.component.scss']
})
export class RemainderComponent implements OnInit {
  public ButtonAppearanceEnum = ButtonAppearance;
  public ButtonSizeEnum = ButtonSize;
  public SvgIconNameEnum = SvgIconName;

  constructor(
    private router: Router,
    private modalRef: DialogRef<RemainderComponent>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  closeModal() {
    this.modalRef.close();
  }

  cancel() {
    this.modalRef.close();
  }

  takeSurvey() {
    this.modalRef.close();
    this.router.navigate(['/survey', this.data.surveyId]);
  }
}
