import { OnDestroy } from '@angular/core';
import { RouteSelectors } from '../../reducers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "./translation.service";
import * as i3 from "@ngrx/store";
export class ApplicationTitleService {
    constructor(titleService, translationService, store) {
        this.titleService = titleService;
        this.translationService = translationService;
        this.store = store;
    }
    track() {
        this.routerSubscription = this.store
            .select(RouteSelectors.selectRouteData)
            .subscribe(routeData => {
            const pageTitle = routeData && routeData.pageTitle;
            this.titleService.setTitle(pageTitle
                ? this.translationService.translate(pageTitle) + ' | My Capstone'
                : 'My Capstone');
        });
    }
    setTitle(pageTitle) {
        this.titleService.setTitle(pageTitle + ' | My Capstone');
    }
    ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }
}
ApplicationTitleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationTitleService_Factory() { return new ApplicationTitleService(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i2.TranslationService), i0.ɵɵinject(i3.Store)); }, token: ApplicationTitleService, providedIn: "root" });
