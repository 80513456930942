<button
  class="associate-photo"
  type="button"
  [ngStyle]="{ width: width / 14 + 'rem' }"
  [attr.disabled]="canAddPhoto || (canDeletePhoto && photo) ? null : true"
  (click)="photo ? editPhoto($event) : openUploadPrompt()"
>
  <span class="associate-photo__content">
    <img *ngIf="photo" [src]="photoSrc" alt="Associate Profile" />

    <ng-container *ngIf="!photo">
      <app-svg-icon
        class="associate-photo__icon"
        [name]="
          canAddPhoto ? SvgIconNameEnum.CameraAdd : SvgIconNameEnum.CameraDenied
        "
      ></app-svg-icon>
    </ng-container>
  </span>
</button>

<app-file-uploader
  class="associate-photo-uploader visually-hidden"
  [uploadedFiles]="files"
  fileType="image/jpg,image/jpeg,image/png"
  (filesUploaded)="changePhoto($event)"
></app-file-uploader>
