<app-page-header-container></app-page-header-container>

<main class="main-container">
  <ng-template #teamDirectory i18n="@@team-directory_pageTitle">
    Team Directory
  </ng-template>
  <app-page-title
    [titleTemplate]="teamDirectory"
    [appearance]="PageTitleAppearanceEnum.Transparent"
    data-cy="pageHeaderTitle"
  ></app-page-title>

  <app-loader [isLoading]="isDirectoryLoading$ | async"></app-loader>

  <div
    *ngIf="
      (isDirectoryLoading$ | async) === false &&
      (directory$ | async) as directory
    "
    class="team-directory"
  >
    <h2 class="team-directory__heading" i18n="@@team-directory_subtitle">
      Department or Category
    </h2>
    <p i18n="@@team-directory_description">
      Use the contact information below to reach out to a managerial member of
      your team if you need assistant or have a question.
    </p>

    <app-accordion>
      <app-accordion-panel *ngFor="let contact of directory.contacts">
        <app-accordion-header-primary
          slot="header"
          [title]="contact.fullName"
          [subtitle]="contact.title"
        ></app-accordion-header-primary>
        <app-contact-info slot="body" [contact]="contact"></app-contact-info>
      </app-accordion-panel>
    </app-accordion>
  </div>

  <ng-template #emptyStateMessage i18n="@@team-directory_noDataFound">
    No directory data found.
  </ng-template>

  <app-empty-state
    *ngIf="
      (isDirectoryLoading$ | async) === false && (directory$ | async) === null
    "
    [emptyStateTemplate]="emptyStateMessage"
  ></app-empty-state>
</main>
