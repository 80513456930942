import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormUtilitiesService } from '../../../services/form-utilities.service';

@Component({
  selector: 'app-personal-details-edit',
  templateUrl: './personal-details-edit.component.html'
})
export class PersonalDetailsEditComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() jobTitleOptions: any[];
  sexOptions: any[];

  constructor(private formUtilitiesService: FormUtilitiesService) {}

  ngOnInit() {
    this.sexOptions = this.formUtilitiesService.getSexOptions();
  }
}
