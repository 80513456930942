import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DynamicPage } from '../../core/dataEntities/dynamicContent/page';
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import { DynamicContentActions } from '../actions';
import { UserActions } from '../../core/actions';

export const dynamicContentFeatureKey = 'dynamicContent';

export const adapter: EntityAdapter<DynamicPage> = createEntityAdapter<
  DynamicPage
>();

export interface DynamicContentState extends EntityState<DynamicPage> {
  isDynamicPageLoading: boolean;
}

export const initialState: DynamicContentState = adapter.getInitialState({
  isDynamicPageLoading: false
});

export const dynamicContentReducer = createReducer(
  initialState,
  on(DynamicContentActions.loadPage, (state, action) => {
    return {
      ...state,
      isDynamicPageLoading: true
    };
  }),
  on(DynamicContentActions.pageLoaded, (state, action) => {
    if (action.page) {
      return adapter.addOne(
        { id: action.page.slug, ...action.page },
        {
          ...state
        }
      );
    }
    return {
      ...state
    };
  }),
  on(DynamicContentActions.toggleDynamicContentIsLoading, (state, action) => {
    return {
      ...state,
      isDynamicPageLoading: action.isLoading
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(
  state: DynamicContentState | undefined,
  action: Action
) {
  return dynamicContentReducer(state, action);
}

export const getDynamicContentState = createFeatureSelector<
  DynamicContentState
>(dynamicContentFeatureKey);

const { selectEntities } = adapter.getSelectors(getDynamicContentState);

export const selectPage = createSelector(selectEntities, (entities, prop) => {
  return entities[prop.slug] ? entities[prop.slug] : null;
});

export const selectPageIsLoading = createSelector(
  getDynamicContentState,
  (state: DynamicContentState) => {
    return state.isDynamicPageLoading;
  }
);
