export enum SvgIconName {
  Airplane = 'Airplane',
  AppIcon = 'AppIcon',
  Approved = 'Approved',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRightFilled = 'ArrowRightFilled',
  Banknote = 'Banknote',
  BanknoteBold = 'BanknoteBold',
  Bell = 'Bell',
  Boxes = 'Boxes',
  Car = 'Car',
  Calendar = 'Calendar',
  CalendarEdit = 'CalendarEdit',
  CameraAdd = 'CameraAdd',
  CameraDenied = 'CameraDenied',
  CameraSwitch = 'CameraSwitch',
  CapstoneLogo = 'CapstoneLogo',
  Caution = 'Caution',
  CautionLocation = 'CautionLocation',
  CheckCircle = 'CheckCircle',
  Chevron = 'Chevron',
  Clock = 'Clock',
  Close = 'Close',
  Completed = 'Completed',
  Conversation = 'Conversation',
  Earnings = 'Earnings',
  Email = 'Email',
  EmptyState = 'EmptyState',
  EmptyStateApplications = 'EmptyStateApplications',
  ExpandArrows = 'ExpandArrows',
  External = 'External',
  FileInsuranceLicense = 'FileInsuranceLicense',
  FileDriverLicense = 'FileDriverLicense',
  FileError = 'FileError',
  Filter = 'Filter',
  HalfBanknote = 'HalfBanknote',
  Hourglass = 'Hourglass',
  Info = 'Info',
  Like = 'Like',
  Location = 'Location',
  Logout = 'Logout',
  Mask = 'Mask',
  Megaphone = 'Megaphone',
  Menu = 'Menu',
  MessageBubble = 'MessageBubble',
  MyTeam = 'MyTeam',
  Newspaper = 'Newspaper',
  PeopleWCross = 'PeopleWCross',
  Pencil = 'Pencil',
  Person = 'Person',
  PersonWQuestionMark = 'PersonWQuestionMark',
  PersonWStar = 'PersonWStar',
  PersonWSymptoms = 'PersonWSymptoms',
  Phone = 'Phone',
  PinWMap = 'PinWMap',
  PwaNotification = 'PwaNotification',
  Search = 'Search',
  Settings = 'Settings',
  Shift = 'Shift',
  SiteAverage = 'SiteAverage',
  Smile1 = 'Smile1',
  Smile2 = 'Smile2',
  Smile3 = 'Smile3',
  Smile4 = 'Smile4',
  Smile5 = 'Smile5',
  SortAscending = 'SortAscending',
  SortDescending = 'SortDescending',
  Star = 'Star',
  Survey = 'Survey',
  TriangleTick = 'TriangleTick',
  Truck = 'Truck',
  Virus = 'Virus',
  QRCode = 'QRCode',
  Warning = 'Warning'
}

// Please keep in sync with styles/base/_colors.scss
export enum SvgIconColor {
  Transparent = 'transparent',
  Black = '#000',
  White = '#fff',
  Alabaster = '#f8f8f8',
  BonJour = '#eae7e7',
  Silver = '#c9c3c3',
  DustyGray = '#9e9393',
  Empress = '#777273',
  Emperor = '#565353',
  MineShaft = '#3f3c3c',
  RegalBlue = '#003e73',
  Endeavour = '#00539b',
  Lochmara = '#0076bd',
  Horizon = '#567fa2',
  Danube = '#74abd9',
  Spindle = '#b0cfe9',
  LinkWater = '#dcebf6',
  Watercourse = '#056044',
  Genoa = '#138865',
  MountainMeadow = '#1ab688',
  MintyFog = '#1fc49e',
  Bermuda = '#7dd5bb',
  AquaSpring = '#e8f8f3',
  SanguineBrown = '#883636',
  Cinnabar = '#ee3c3a',
  Carnation = '#fc5e5e',
  MonaLisa = '#ff9c9c',
  Azalea = '#fadddd',
  Marigold = '#b79029',
  Saffron = '#f6c237',
  BrightSun = '#f8cd41',
  SweetCorn = '#fadc8d',
  Bianca = '#fcfaf4'
}
