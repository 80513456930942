<ul *ngIf="weekPayDetails" class="summary">
  <li class="summary__item summary__item--green">
    <app-svg-icon
      [name]="SvgIconNameEnum.Truck"
      class="summary__icon"
    ></app-svg-icon>
    <span
      class="summary__title"
      i18n="@@pay_productionEarningsTotal"
      data-cy="productionEarningsTotalTitle"
    >
      Production Earnings Total
    </span>
    <span class="summary__currency" data-cy="productionEarningsTotalCurrency">
      {{ weekPayDetails.productionEarningsTotal | currency }}
    </span>
  </li>
  <li
    *ngIf="weekPayDetails.hourlyEarningsTotal"
    class="summary__item summary__item--blue"
  >
    <app-svg-icon
      [name]="SvgIconNameEnum.Clock"
      class="summary__icon"
    ></app-svg-icon>
    <span
      class="summary__title"
      i18n="@@pay_hourlyEarningsTotal"
      data-cy="hourlyEarningsTotalTitle"
    >
      Hourly Earnings Total
    </span>
    <span class="summary__currency" data-cy="hourlyEarningsTotalCurrency">
      {{ weekPayDetails.hourlyEarningsTotal | currency }}
    </span>
  </li>
  <li
    *ngIf="weekPayDetails.exceptionEarningsTotal"
    class="summary__item summary__item--dark"
  >
    <app-svg-icon
      [name]="SvgIconNameEnum.TriangleTick"
      class="summary__icon"
    ></app-svg-icon>
    <span
      class="summary__title"
      i18n="@@pay_exceptionEarningsTotal"
      data-cy="exceptionEarningsTotalTitle"
    >
      Exception Earnings Total
    </span>
    <span class="summary__currency" data-cy="exceptionEarningsTotalCurrency">
      {{ weekPayDetails.exceptionEarningsTotal | currency }}
    </span>
  </li>
</ul>
