/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-delete-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/modal/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../../../../shared/components/modal/modal-layout/modal-layout.component";
import * as i4 from "../../../../../shared/components/modal/modal-header/modal-header.component.ngfactory";
import * as i5 from "../../../../../shared/components/modal/modal-header/modal-header.component";
import * as i6 from "../../../../../shared/components/modal/modal-content-row/modal-content-row.component.ngfactory";
import * as i7 from "../../../../../shared/components/modal/modal-content-row/modal-content-row.component";
import * as i8 from "../../../../../shared/components/button-link/button-link.component.ngfactory";
import * as i9 from "../../../../../shared/components/button-link/button-link.component";
import * as i10 from "../../../../../shared/services/analytics-utilities.service";
import * as i11 from "./shift-delete-modal.component";
import * as i12 from "../../../../../shared/modules/modal/dialog-ref";
import * as i13 from "../../../../../shared/modules/modal/dialog-config";
var styles_ShiftDeleteModalComponent = [i0.styles];
var RenderType_ShiftDeleteModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftDeleteModalComponent, data: {} });
export { RenderType_ShiftDeleteModalComponent as RenderType_ShiftDeleteModalComponent };
function View_ShiftDeleteModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Close shift "]))], null, null); }
function View_ShiftDeleteModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Nevermind "]))], null, null); }
function View_ShiftDeleteModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Close Shift Request "]))], null, null); }
export function View_ShiftDeleteModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "app-modal-layout", [], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalLayoutComponent, [], null, { closeModal: "closeModal" }), (_l()(), i1.ɵand(0, [["secondaryText", 2]], null, 0, null, View_ShiftDeleteModalComponent_1)), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-modal-header", [["slot", "modal-header"]], null, null, null, i4.View_ModalHeaderComponent_0, i4.RenderType_ModalHeaderComponent)), i1.ɵdid(4, 114688, null, 0, i5.ModalHeaderComponent, [], { secondaryTextTemplate: [0, "secondaryTextTemplate"] }, null), (_l()(), i1.ɵeld(5, 0, null, 1, 13, "div", [["slot", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "app-modal-content-row", [], null, null, null, i6.View_ModalContentRowComponent_0, i6.RenderType_ModalContentRowComponent)), i1.ɵdid(7, 114688, null, 0, i7.ModalContentRowComponent, [], { sideSpacing: [0, "sideSpacing"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Delete this shift request will remove the post from the Newsfeed. Any associates who haven\u2019t been notified yet will not see this shift request. "])), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This action can\u2019t be undone. "])), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "shift-delete__actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["cancelButton", 2]], null, 0, null, View_ShiftDeleteModalComponent_2)), (_l()(), i1.ɵand(0, [["deleteButton", 2]], null, 0, null, View_ShiftDeleteModalComponent_3)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-button-link", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonLinkComponent_0, i8.RenderType_ButtonLinkComponent)), i1.ɵdid(16, 638976, null, 0, i9.ButtonLinkComponent, [i10.AnalyticsUtilitiesService], { appearance: [0, "appearance"], labelTemplate: [1, "labelTemplate"] }, { buttonClick: "buttonClick" }), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-button-link", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.deleteShift() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonLinkComponent_0, i8.RenderType_ButtonLinkComponent)), i1.ɵdid(18, 638976, null, 0, i9.ButtonLinkComponent, [i10.AnalyticsUtilitiesService], { appearance: [0, "appearance"], color: [1, "color"], labelTemplate: [2, "labelTemplate"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2); _ck(_v, 4, 0, currVal_0); var currVal_1 = true; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.ButtonAppearanceEnum.Link; var currVal_3 = i1.ɵnov(_v, 13); _ck(_v, 16, 0, currVal_2, currVal_3); var currVal_4 = _co.ButtonAppearanceEnum.Secondary; var currVal_5 = _co.ButtonColorEnum.Red; var currVal_6 = i1.ɵnov(_v, 14); _ck(_v, 18, 0, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ShiftDeleteModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shift-delete-modal", [], null, null, null, View_ShiftDeleteModalComponent_0, RenderType_ShiftDeleteModalComponent)), i1.ɵdid(1, 114688, null, 0, i11.ShiftDeleteModalComponent, [i12.DialogRef, i13.DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShiftDeleteModalComponentNgFactory = i1.ɵccf("app-shift-delete-modal", i11.ShiftDeleteModalComponent, View_ShiftDeleteModalComponent_Host_0, {}, {}, []);
export { ShiftDeleteModalComponentNgFactory as ShiftDeleteModalComponentNgFactory };
