import { getAuthToken } from '../../reducers';
import { first, switchMap, map } from 'rxjs/operators';
import isEmpty from 'lodash/fp/isEmpty';
import moment from 'moment';
import { IdDateFormat } from '../constants';
import _ from 'lodash';
import { DateTimeUtils } from '../utils/DateTimeUtils';
import * as i0 from "@angular/core";
import * as i1 from "./api.gateway.service";
import * as i2 from "@ngrx/store";
import * as i3 from "./translation.service";
import * as i4 from "./associates.service";
export class UserService {
    constructor(apiGateway, store, translationService, associateService) {
        this.apiGateway = apiGateway;
        this.store = store;
        this.translationService = translationService;
        this.associateService = associateService;
    }
    getUserInfo(userId) {
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway
                .get(`associates/${userId}`, authToken)
                .pipe(map((response) => {
                if (isEmpty(response)) {
                    return {
                        apexId: null,
                        eid: null,
                        hasRoadCrewAccess: false,
                        roadCrewErrorMessage: '',
                        fullName: null,
                        position: null,
                        homeSite: null,
                        siteState: null,
                        sitePartner: null,
                        siteAddress: null,
                        siteName: null,
                        responsibleSubDepartments: {
                            managedSubDepartments: [],
                            accessSubDepartments: []
                        },
                        weeklyCheckIns: {
                            currentWeek: false,
                            currentWeekDate: '',
                            pastWeek: false,
                            pastWeekDate: ''
                        }
                    };
                }
                return Object.assign({}, response, { preference: response.preference
                        ? Object.assign({}, response.preference, { language: response.preference.language
                                ? response.preference.language
                                : this.translationService.getLanguage() }) : {
                        phoneNumber: null,
                        language: this.translationService.getLanguage(),
                        textOptIn: null
                    }, weeklyCheckIns: this.getCheckInStatus(response), isTrainee: this.associateService.isAssociateTrainee(response.tenure, response.trainingStatus), trainingStatus: this.associateService.calculateTrainingStatus(response.tenure, response.trainingStatus) });
            }));
        }));
    }
    saveUserSettings(userId, userSettings) {
        const requestBody = {
            azureGuid: userId,
            phoneNumber: userSettings.phoneNumber,
            language: userSettings.language,
            textOptIn: userSettings.textOptIn
        };
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.put(`associates/preference`, authToken, requestBody);
        }));
    }
    getCheckInStatus(userInfo) {
        const currentWeekEndDate = DateTimeUtils.getCurrentWeekEndDate();
        const pastWeekEndDate = DateTimeUtils.getPastWeekEndDate();
        const dayOfTheWeek = DateTimeUtils.getDayOfTheWeek();
        if (dayOfTheWeek >= 1 &&
            dayOfTheWeek <= 3 &&
            !_.first(userInfo.weeklyCheckIns.filter(checkIn => moment.utc(checkIn.weekEndingDate).format(IdDateFormat) ===
                pastWeekEndDate)).hasSubmitted) {
            return {
                currentWeek: false,
                currentWeekDate: currentWeekEndDate,
                pastWeek: true,
                pastWeekDate: pastWeekEndDate
            };
        }
        if (dayOfTheWeek >= 5 &&
            dayOfTheWeek <= 7 &&
            !_.first(userInfo.weeklyCheckIns.filter(checkIn => moment.utc(checkIn.weekEndingDate).format(IdDateFormat) ===
                currentWeekEndDate)).hasSubmitted) {
            return {
                currentWeek: true,
                currentWeekDate: currentWeekEndDate,
                pastWeek: false,
                pastWeekDate: pastWeekEndDate
            };
        }
        return {
            currentWeek: false,
            currentWeekDate: currentWeekEndDate,
            pastWeek: false,
            pastWeekDate: pastWeekEndDate
        };
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.ApiGatewayService), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.TranslationService), i0.ɵɵinject(i4.AssociatesService)); }, token: UserService, providedIn: "root" });
