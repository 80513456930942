<input
  #uploader
  [id]="inputId"
  type="file"
  class="visually-hidden"
  [accept]="fileType"
  (change)="onUpload()"
  (focusin)="manageFocus(true)"
  (focusout)="manageFocus(false)"
/>
<label [for]="inputId">
  <ng-container #templateContainer></ng-container>
</label>
<ng-template #defaultButtonTemplate>
  <app-button-link
    [label]="buttonLabel"
    (buttonClick)="openUploadPrompt()"
  ></app-button-link>
</ng-template>

<ng-template #defaultPreviewTemplate>
  <ng-container *ngIf="files[0]">
    <img [src]="files[0]?.data" alt="defaultPreview" />
  </ng-container>
</ng-template>

<ng-template #defaultErrorTemplate>
  <app-button-link
    [label]="buttonLabel"
    (buttonClick)="openUploadPrompt()"
  ></app-button-link>
  <p>Wrong file type</p>
</ng-template>
