<app-modal-layout (closeModal)="closeModal()">
  <div slot="modal-content">
    <div class="modal-header">
      <h1 class="modal-header__heading">{{ data.remainderHeader }}</h1>
    </div>
    <div class="modal-content">
      <app-svg-icon [name]="SvgIconNameEnum.Survey" class="modal-content__icon"></app-svg-icon>
      <p class="modal-content__text">{{ data.remainderText }}</p>
    </div>
    <div class="modal-footer">
      <app-button-link
        (buttonClick)="cancel()"
        [size]="ButtonSizeEnum.Small"
        [appearance]="ButtonAppearanceEnum.Link"
        i18n-label="@@remainder_maybeLater"
        label="Maybe later"
      ></app-button-link>
      <app-button-link
        (buttonClick)="takeSurvey()"
        [size]="ButtonSizeEnum.Small"
        i18n-label="@@remainder_takeSurvey"
        label="Take Survey"
      ></app-button-link>
    </div>
  </div>
</app-modal-layout>
