<div class="stats-item">
  <span
    [ngClass]="{
      'stats-item__value': true,
      'stats-item__value--red':
        appearance === StatsAppearanceEnum.Red && value != null,
      'stats-item__value--dark-red':
        appearance === StatsAppearanceEnum.DarkRed && value != null,
      'stats-item__value--yellow':
        appearance === StatsAppearanceEnum.Yellow && value != null,
      'stats-item__value--blue':
        appearance === StatsAppearanceEnum.Blue && value != null,
      'stats-item__value--green':
        appearance === StatsAppearanceEnum.Green && value != null
    }"
  >
    <span
      [ngClass]="{
        'stats-item__value-suffix': true,
        'stats-item__value-suffix--colored': coloredSuffixPrefix
      }"
    >
      {{ valuePrefix }}
    </span>
    <span>{{ value != null ? (value | number: numberFormat) : '--' }}</span>
    <span
      [ngClass]="{
        'stats-item__value-suffix': true,
        'stats-item__value-suffix--colored': coloredSuffixPrefix
      }"
    >
      {{ valueSuffix }}
    </span>
  </span>

  <ng-template #defaultTitleTemplate>
    {{ title }}
  </ng-template>

  <span class="stats-item__value-title">
    <ng-container
      *ngTemplateOutlet="titleTemplate ? titleTemplate : defaultTitleTemplate"
    ></ng-container>
  </span>
  <app-chip *ngIf="chipTitle" [appearance]="chipAppearance">
    {{ chipTitle }}
  </app-chip>
</div>
