import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class AnalyticsUtilitiesService {
    constructor(document) {
        this.document = document;
    }
    customAnalyticsEvent(analyticsId, data) {
        const customEvent = new CustomEvent('customAnalytics', {
            detail: {
                analyticsId,
                analyticsData: data
            }
        });
        this.document.dispatchEvent(customEvent);
    }
}
AnalyticsUtilitiesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsUtilitiesService_Factory() { return new AnalyticsUtilitiesService(i0.ɵɵinject(i1.DOCUMENT)); }, token: AnalyticsUtilitiesService, providedIn: "root" });
