import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-check-list-item',
  templateUrl: './check-list-item.component.html'
})
export class CheckListItemComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @ViewChild('contentWrapper', { static: false }) innerContent: ElementRef;

  public content;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.content = this.innerContent.nativeElement.innerHTML;
  }
}
