import { Injectable } from '@angular/core';
import { RoadCrewApplicationService } from '../../../../core/contracts/RoadCrewApplicationService';
import { Observable } from 'rxjs';
import {
  DocumentExpirationDate,
  RoadCrewApplicationResponse
} from '../../../../core/responses/application-form/roadCrewApplicationResponse';
import { AppState, getAuthToken } from '../../../../reducers';
import { first, switchMap } from 'rxjs/operators';
import { ApiGatewayService } from '../../../../shared/services/api.gateway.service';
import { Store } from '@ngrx/store';
import { ApplicationFormStatus } from '../../../../core/enums/ApplicationFormStatus';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class RoadCrewApplicationApiService
  implements RoadCrewApplicationService {
  roadCrewApiEndpoint = 'roadcrew';

  constructor(
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>
  ) {}

  getApplication(associateId: number): Observable<RoadCrewApplicationResponse> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.get<any>(
          `${this.roadCrewApiEndpoint}/${associateId}/application`,
          authToken
        );
      })
    );
  }

  getSpecialExpirationDates(): Observable<DocumentExpirationDate[]> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.get<any>(
          `${this.roadCrewApiEndpoint}/special-expiration-dates`,
          authToken
        );
      })
    );
  }

  submitApplication(associateId: number, body: any): Observable<any> {
    const requestBody = {
      ...this.mapApplicationFields(body),
      eid: associateId
    };

    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.post<any>(
          `${this.roadCrewApiEndpoint}/register`,
          authToken,
          requestBody
        );
      })
    );
  }

  private mapApplicationFields(form) {
    if (!form) {
      return {};
    }
    const mappedForm = {
      first_name: form.personalInfo && form.personalInfo.firstName,
      last_name: form.personalInfo && form.personalInfo.lastName,
      sex: form.personalInfo && form.personalInfo.sex,
      job_title: form.personalInfo && form.personalInfo.jobTitle,
      email_address: form.contactInfo && form.contactInfo.email,
      cell_phone_number: form.contactInfo && form.contactInfo.phone,
      home_phone_number: form.contactInfo && form.contactInfo.homePhone,
      emergency_contact_name:
        form.contactInfo && form.contactInfo.emergencyContactName,
      contact_phone: form.contactInfo && form.contactInfo.emergencyContactPhone,
      relationship:
        form.contactInfo && form.contactInfo.emergencyContactRelationship,
      preferred_airport_departure:
        form.travelInfo && form.travelInfo.preferredAirport,
      available_as_a_driver:
        form.travelInfo && form.travelInfo.availableAsDriver,
      has_insurance: form.travelInfo && form.travelInfo.hasInsurance,
      type_of_id:
        form.travelInfo &&
        form.travelInfo.document &&
        form.travelInfo.document.idType,
      license_expiration_date:
        form.travelInfo &&
        form.travelInfo.document &&
        form.travelInfo.document.licenseExpirationDate,
      insurance_expiration_date:
        form.travelInfo &&
        form.travelInfo.document &&
        form.travelInfo.document.insuranceExpirationDate,
      license_number:
        form.travelInfo &&
        form.travelInfo.document &&
        form.travelInfo.document.licenseNumber,
      license_issue_in:
        form.travelInfo &&
        form.travelInfo.document &&
        form.travelInfo.document.licenseIssuedState,
      certifications:
        form.certificateInfo &&
        form.certificateInfo.certificates &&
        form.certificateInfo.certificates.join(', '),
      additional_skills:
        form.certificateInfo && form.certificateInfo.additionalCertificates,
      license_image:
        form.travelInfo &&
        form.travelInfo.document &&
        form.travelInfo.document.documentPhotos &&
        form.travelInfo.document.documentPhotos.length === 2
          ? form.travelInfo.document.documentPhotos[0].file
          : !form.travelInfo.availableAsDriver &&
            form.travelInfo.document.idType === 'driversLicense'
          ? form.travelInfo.document.documentPhotos[0].file
          : null,
      id_image:
        form.travelInfo &&
        form.travelInfo.document &&
        form.travelInfo.document.documentPhotos &&
        form.travelInfo.document.documentPhotos.length === 2
          ? form.travelInfo.document.documentPhotos[1].file
          : !form.travelInfo.availableAsDriver &&
            form.travelInfo.document.idType === 'driversLicense'
          ? null
          : form.travelInfo.document.documentPhotos[0].file,
      id_expiration_date:
        form.travelInfo &&
        form.travelInfo.document &&
        form.travelInfo.document.idExpirationDate,
      signature: form.signatureInfo.signature,
      signature_date: form.signatureInfo.signDate,
      approval_state: ApplicationFormStatus.Applied
    };
    return mappedForm;
  }
}
