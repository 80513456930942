import { createAction, props } from '@ngrx/store';

export const navigateBack = createAction(
  '[Navigation] Navigate Back',
  props<{
    replaceUrl?: boolean;
  }>()
);

export const navigatedBack = createAction('[Navigation] Navigated Back');

export const navigatedForward = createAction(
  '[Navigation] Navigated Forward',
  props<{
    url: string;
    replaceAppUrl?: boolean;
  }>()
);

export const navigationInitialized = createAction(
  '[Navigation] Navigation initialized',
  props<{
    url: string;
    parentsUrl: string[];
  }>()
);
