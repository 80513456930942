/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./personal-details-readonly.component";
import * as i2 from "../../../../../../shared/services/translation.service";
var styles_PersonalDetailsReadonlyComponent = [];
var RenderType_PersonalDetailsReadonlyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PersonalDetailsReadonlyComponent, data: {} });
export { RenderType_PersonalDetailsReadonlyComponent as RenderType_PersonalDetailsReadonlyComponent };
export function View_PersonalDetailsReadonlyComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "p", [["class", "read-only-details"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "read-only-details__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "read-only-details__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "p", [["class", "read-only-details"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "read-only-details__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "read-only-details__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formGroup.get("firstName").value; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.formGroup.get("lastName").value; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.formGroup.get("sex").value && _co.translationService.translate(_co.formGroup.get("sex").value)); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.formGroup.get("jobTitle").value && _co.translationService.translate(_co.formGroup.get("jobTitle").value)); _ck(_v, 9, 0, currVal_3); }); }
export function View_PersonalDetailsReadonlyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-personal-details-readonly", [], null, null, null, View_PersonalDetailsReadonlyComponent_0, RenderType_PersonalDetailsReadonlyComponent)), i0.ɵdid(1, 114688, null, 0, i1.PersonalDetailsReadonlyComponent, [i2.TranslationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonalDetailsReadonlyComponentNgFactory = i0.ɵccf("app-personal-details-readonly", i1.PersonalDetailsReadonlyComponent, View_PersonalDetailsReadonlyComponent_Host_0, { formGroup: "formGroup" }, {}, []);
export { PersonalDetailsReadonlyComponentNgFactory as PersonalDetailsReadonlyComponentNgFactory };
