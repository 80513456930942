export enum SliderAppearance {
  Default = 'Default',
  Red = 'Red',
  Yellow = 'Yellow'
}

export enum SliderAverage {
  Personal,
  Site,
  All,
  None
}
