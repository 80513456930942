import {
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
  FORM_FIELD,
  FormFieldComponent
} from '../form-field/form-field.component';
import { FormFieldControl } from '../form-field/form-field.control';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

let nextUniqueId = 0;

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextAreaComponent)
    },
    { provide: FormFieldControl, useExisting: TextAreaComponent }
  ]
})
export class TextAreaComponent
  implements OnInit, OnDestroy, ControlValueAccessor, FormFieldControl {
  protected defaultId = `app-text-area-${nextUniqueId++}`;
  protected componentId: string;
  formControl: FormControl;
  onDestroySubject = new Subject();

  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() maxLength = null;

  constructor(
    fb: FormBuilder,
    @Optional() @Inject(FORM_FIELD) private formField: FormFieldComponent
  ) {
    this.formControl = fb.control('');
  }

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(value => {
        this.propagateChange(value);
      });
  }

  @Input()
  get id(): string {
    return this.componentId;
  }
  set id(value: string) {
    this.componentId = value || this.defaultId;
  }

  get errorId(): string {
    return `${this.id}-error`;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  writeValue(value) {
    this.formControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  public get control() {
    return this.formControl;
  }

  get isInvalid() {
    if (!this.formField) {
      return false;
    }
    return this.formField.isInvalid();
  }

  ngOnDestroy() {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  private propagateChange = (value: any) => {};
}
