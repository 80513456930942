/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../../components/svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./info-button.component";
import * as i6 from "./info-box.service";
var styles_InfoButtonComponent = [i0.styles];
var RenderType_InfoButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoButtonComponent, data: {} });
export { RenderType_InfoButtonComponent as RenderType_InfoButtonComponent };
export function View_InfoButtonComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { triggerButton: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["trigger", 1]], null, 4, "button", [["class", "info-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["More information"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-svg-icon", [["class", "info-button__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(5, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"], colorPrimary: [1, "colorPrimary"], colorSecondary: [2, "colorSecondary"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.Info; var currVal_1 = _co.SvgIconColorEnum.Emperor; var currVal_2 = _co.SvgIconColorEnum.White; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_InfoButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-button", [], null, null, null, View_InfoButtonComponent_0, RenderType_InfoButtonComponent)), i1.ɵdid(1, 49152, null, 0, i5.InfoButtonComponent, [i6.InfoBoxService], null, null)], null, null); }
var InfoButtonComponentNgFactory = i1.ɵccf("app-info-button", i5.InfoButtonComponent, View_InfoButtonComponent_Host_0, { text: "text" }, {}, []);
export { InfoButtonComponentNgFactory as InfoButtonComponentNgFactory };
