import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { SvgIconName } from '../../enums/SvgIcon';
import { DomSanitizer } from '@angular/platform-browser';
import { ChangePhotoComponent } from '../../../profile/components/change-photo/change-photo.component';
import { BottomSheetService } from '../../modules/modal/bottom-sheet/bottom-sheet.service';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { UserActions } from '../../../core/actions';

@Component({
  selector: 'app-associate-photo',
  templateUrl: './associate-photo.component.html',
  styleUrls: ['./associate-photo.component.scss']
})
export class AssociatePhotoComponent implements OnInit, OnChanges {
  public SvgIconNameEnum = SvgIconName;
  public photoSrc = null;
  public files = null;
  basePNG = 'data:image/png;base64,';
  baseJPG = 'data:image/jpg;base64,';
  baseJPEG = 'data:image/jpeg;base64,';
  @Input() photo = null;
  @Input() canAddPhoto = false;
  @Input() canDeletePhoto = false;
  @Input() width: number;

  @Output() deletePhotoEvent = new EventEmitter();
  @Output() addPhotoEvent = new EventEmitter();

  @ViewChild(FileUploaderComponent, { static: false })
  fileUploaderComponent: FileUploaderComponent;

  constructor(
    private sanitizer: DomSanitizer,
    private bottomSheetService: BottomSheetService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.setupPhotoFile();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.photo.currentValue !== changes.photo.previousValue) {
      this.setupPhotoFile();
    }
  }

  editPhoto(event) {
    if (this.canAddPhoto) {
      if (this.photo) {
        this.bottomSheetService
          .open(ChangePhotoComponent, {
            data: {
              fileInputId: this.fileUploaderComponent.inputId
            }
          })
          .afterClosed()
          .subscribe(value => {
            setTimeout(() => {
              if (value) {
                if (value.success && value.data.removePhoto) {
                  this.store.dispatch(UserActions.removePhoto());
                }
              }
            });
          });
      }
    } else if (this.canDeletePhoto && this.photo != null) {
      this.deletePhotoEvent.emit(event);
    }
  }

  changePhoto(event) {
    this.store.dispatch(
      UserActions.takePhoto({ photo: this.handlePhotoData(event[0].data) })
    );
  }

  openUploadPrompt() {
    this.fileUploaderComponent.openUploadPrompt();
  }

  handlePhotoData(photo) {
    let photoString = '';
    if (photo.indexOf(this.baseJPG) !== -1) {
      photoString = photo.replace(this.baseJPG, '');
    } else if (photo.indexOf(this.basePNG) !== -1) {
      photoString = photo.replace(this.basePNG, '');
    } else if (photo.indexOf(this.baseJPEG) !== -1) {
      photoString = photo.replace(this.baseJPEG, '');
    }
    return photoString;
  }

  private setupPhotoFile() {
    this.photoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.baseJPG + this.photo
    );
    this.files = [{ data: this.photoSrc }];
  }
}
