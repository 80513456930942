/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../svg-icon/svg-icon.component.ngfactory";
import * as i5 from "../svg-icon/svg-icon.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./page-header.component";
var styles_PageHeaderComponent = [i0.styles];
var RenderType_PageHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageHeaderComponent, data: {} });
export { RenderType_PageHeaderComponent as RenderType_PageHeaderComponent };
function View_PageHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["class", "page-header__link"], ["data-cy", "backButton"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], state: [1, "state"], routerLink: [2, "routerLink"] }, null), i1.ɵpod(2, { navigateBack: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-svg-icon", [["class", "page-header__link-icon"]], null, null, null, i4.View_SvgIconComponent_0, i4.RenderType_SvgIconComponent)), i1.ɵdid(4, 638976, null, 0, i5.SvgIconComponent, [i6.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "page-header__link-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Back "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.queryParams; var currVal_3 = _ck(_v, 2, 0, true); var currVal_4 = _co.link; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.SvgIconNameEnum.ArrowLeft; _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PageHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["class", "page-header__link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { state: [0, "state"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(2, { navigateBack: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-svg-icon", [["class", "page-header__link-icon"]], null, null, null, i4.View_SvgIconComponent_0, i4.RenderType_SvgIconComponent)), i1.ɵdid(4, 638976, null, 0, i5.SvgIconComponent, [i6.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "page-header__link-title"], ["data-cy", "closeButton"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Close "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, true); var currVal_3 = _co.link; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _co.SvgIconNameEnum.Close; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_PageHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], [[1, "role", 0]], null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "page-header": 0, "page-header--blue": 1, "page-header--transparent": 2, "page-header--green": 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageHeaderComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageHeaderComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, true, (_co.appearance === _co.PageHeaderAppearanceEnum.Blue), (_co.appearance === _co.PageHeaderAppearanceEnum.Transparent), (_co.appearance === _co.PageHeaderAppearanceEnum.Green)); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.type === _co.PageHeaderTypeEnum.Back); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.type === _co.PageHeaderTypeEnum.Close); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isHeader ? "banner" : null); _ck(_v, 0, 0, currVal_0); }); }
export function View_PageHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-header", [], null, null, null, View_PageHeaderComponent_0, RenderType_PageHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i7.PageHeaderComponent, [], null, null)], null, null); }
var PageHeaderComponentNgFactory = i1.ɵccf("app-page-header", i7.PageHeaderComponent, View_PageHeaderComponent_Host_0, { link: "link", queryParams: "queryParams", type: "type", appearance: "appearance", isHeader: "isHeader" }, { isClicked: "isClicked" }, []);
export { PageHeaderComponentNgFactory as PageHeaderComponentNgFactory };
