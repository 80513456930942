<app-page-header-container
  [type]="PageHeaderTypeEnum.Close"
></app-page-header-container>

<main class="main-container">
  <app-no-internet
    *ngIf="showEmptyState$ | async"
    i18n-message="@@survey-details_backToEarningHistory"
    message="Go back to Earnings History"
    [link]="'/wages'"
  ></app-no-internet>

  <ng-container *ngIf="surveyForm$ | async as surveyFormGroup">
    <ng-container *ngIf="surveyFormGroup.get('id').value">
      <app-page-title [title]="surveyFormGroup.get('title').value">
        <app-page-subtitle>
          {{ surveyFormGroup.get('subtitle').value }}
        </app-page-subtitle>
      </app-page-title>
      <div class="survey">
        <span class="survey__required-note" aria-hidden="true">
          <span class="survey__required-note-asterisk">*</span>
          <span i18n="@@required">Required</span>
        </span>
        <form
          [formGroup]="surveyFormGroup"
          appRootForm
          (ngSubmit)="onSubmit(surveyFormGroup)"
        >
          <ng-container
            *ngFor="
              let question of surveyFormGroup.get('questions').controls;
              let i = index
            "
          >
            <app-survey-question [formGroup]="question"></app-survey-question>
          </ng-container>
          <div class="survey__form-action">
            <app-button-link
              [type]="ButtonTypeEnum.Submit"
              i18n-label="@@survey-details_submitSurvey"
              label="Submit survey"
            ></app-button-link>
          </div>
        </form>
      </div>
    </ng-container>
  </ng-container>
</main>
