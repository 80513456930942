<app-page-header-container></app-page-header-container>
<main class="main-container">
  <app-page-title
    data-cy="pageHeaderTitle"
    title="My Team"
    i18n-title="@@myTeam_pageTitle"
    [appearance]="PageTitleAppearanceEnum.Transparent"
  ></app-page-title>
  <div class="main-content my-team">
    <app-select
      *ngIf="shouldShowManagerSubDepartments$ | async"
      class="my-team__site-select"
      valueKey="subDept"
      [ngModel]="(myTeamFilter$ | async)?.site"
      (ngModelChange)="onSiteChange($event)"
      [options]="managerSubDepartments$ | async"
    ></app-select>

    <app-sort-search
      class="my-team__site-select"
      [sortByOptions]="sortOptions"
      [sort]="myTeamSort$ | async"
      (sortChange)="onSortChange($event)"
      [searchQuery]="(myTeamFilter$ | async)?.query"
      (searchQueryChange)="onSearchQueryChange($event)"
    ></app-sort-search>

    <app-loader
      [isLoading]="
        (isMyTeamLoading$ | async) && (myTeam$ | async)?.length === 0
      "
    ></app-loader>
    <app-menu>
      <ng-container *ngFor="let teamMember of myTeam$ | async">
        <ng-template #hoursWorked i18n="@@myTeam_hours">
          {{ teamMember.hoursWorked }} {teamMember.hoursWorked, plural, =1
          {hour} other {hours}}
        </ng-template>

        <ng-template #checkInScore>
          {{
            teamMember.lastWeeklyCheckInScore
              ? teamMember.lastWeeklyCheckInScore
              : '--'
          }}
          / 5
        </ng-template>

        <ng-template #tenure i18n="@@myTeam_days">
          {{ teamMember.tenure }} {teamMember.tenure, plural, =1 {day} other
          {days}}
        </ng-template>

        <ng-template #trainee i18n="@@myTeam_trainee">
          trainee
        </ng-template>

        <ng-template #imageTemplate>
          <app-menu-item-image
            [firstName]="teamMember.firstName"
            [lastName]="teamMember.lastName"
            [imageSrc]="teamMember.profilePhoto"
          ></app-menu-item-image>
        </ng-template>

        <app-menu-item
          [title]="teamMember.fullName"
          [subTitleTemplate]="
            (myTeamSort$ | async).active ==
            MyTeamSortOptionsEnum.LastWeeklyCheckInScore
              ? checkInScore
              : (myTeamSort$ | async).active ==
                MyTeamSortOptionsEnum.HoursWorked
              ? hoursWorked
              : tenure
          "
          [subTitleColor]="teamMember.subtitleColor"
          [chipTitleTemplate]="teamMember.isTrainee ? trainee : null"
          [link]="[teamMember.azureObjectId, 'production']"
          [imageTemplate]="imageTemplate"
        ></app-menu-item>
      </ng-container>
    </app-menu>

    <app-no-internet
      *ngIf="showEmptyState$ | async"
      i18n-message="@@my-team_backToNews"
      message="Go back to News"
      [link]="'/news'"
    ></app-no-internet>

    <app-footer
      *ngIf="(isEndOfList$ | async) && (isMyTeamLoading$ | async) === false"
    >
      <app-footer-note
        data-cy="earningsHistoryEndTitle"
        i18n="@@my-team_endOfHistory"
      >
        End of employee list
      </app-footer-note>
    </app-footer>

    <div
      *ngIf="(isEndOfList$ | async) === false"
      class="my-team__loader-container"
    >
      <app-loader
        [debounceTime]="0"
        [isLoading]="
          (isMyTeamLoading$ | async) && (myTeam$ | async)?.length > 0
        "
      ></app-loader>
    </div>
  </div>
</main>
