import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as contentful from 'contentful';

@Injectable()
export class ContentfulClientFactory {
  PREVIEW_HOST = 'preview.contentful.com';
  constructor() {}

  create() {
    const client = contentful.createClient({
      space: environment.contentDelivery.space,
      environment: environment.contentDelivery.environment,
      accessToken: environment.contentDelivery.accessToken,
      retryOnError: false
    });

    return client;
  }

  createPreviewClient() {
    const previewClient = contentful.createClient({
      space: environment.contentDelivery.space,
      environment: environment.contentDelivery.environment,
      accessToken: environment.contentDelivery.previewAccessToken,
      host: this.PREVIEW_HOST
    });
    return previewClient;
  }
}
