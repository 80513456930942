import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState, selectAllSurveys } from '../../../reducers';
import { Store } from '@ngrx/store';
import { SurveySummary } from '../../../core/dataEntities/surveys/surveySummary';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit {
  public surveys$: Observable<SurveySummary[]>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.surveys$ = this.store.select(selectAllSurveys);
  }
}
