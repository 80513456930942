import { select } from '@ngrx/store';
import { map, first } from 'rxjs/operators';
import { Roles } from '../../core/enums/Roles';
import { getUserRoles } from '../../reducers';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AuthorizationService {
    constructor(store) {
        this.store = store;
    }
    get isAssociate$() {
        this.userRoles$ = this.store.pipe(select(getUserRoles));
        return this.userRoles$.pipe(first(), map(roles => {
            return roles.includes(Roles.Associate) || roles.includes(Roles.None);
        }));
    }
    get isSiteManager$() {
        this.userRoles$ = this.store.pipe(select(getUserRoles));
        return this.userRoles$.pipe(first(), map(roles => {
            return roles.includes(Roles.SiteManager);
        }));
    }
    isAuthorized$(rolesToValidate) {
        this.userRoles$ = this.store.pipe(select(getUserRoles));
        const belongs = element => {
            return rolesToValidate.includes(element);
        };
        return this.userRoles$.pipe(first(), map(userRoles => {
            return userRoles.some(belongs);
        }));
    }
}
AuthorizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationService_Factory() { return new AuthorizationService(i0.ɵɵinject(i1.Store)); }, token: AuthorizationService, providedIn: "root" });
