import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { AccordionPanelComponent } from '../accordion-panel.component';
import { StatsAppearance } from '../../../enums/Stats';

@Component({
  selector: 'app-accordion-header-rating',
  templateUrl: './accordion-header-rating.component.html',
  styleUrls: ['./accordion-header-rating.component.scss']
})
export class AccordionHeaderRatingComponent implements OnInit {
  @Input() rating: number;
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() appearance: StatsAppearance;

  public StatsAppearanceEnum = StatsAppearance;

  constructor(public panel: AccordionPanelComponent) {}

  ngOnInit() {}
}
