import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, selectUserSettings } from 'src/app/reducers';
import { PageTitleAppearance } from 'src/app/shared/enums/PageTitle';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';
import { UserSettings } from '../../../core/dataEntities/user/userSettings';
import { ButtonAppearance, ButtonType } from '../../../shared/enums/ButtonLink';
import { LayoutActions, UserActions } from '../../../core/actions';
import { Locales } from '../../../core/enums/Locales';
import { ActivatedRoute } from '@angular/router';
import { TranslationMessages } from '../../../shared/enums/TranslationMessages';
import { TranslationService } from '../../../shared/services/translation.service';

@Component({
  selector: 'app-settings-container',
  templateUrl: './settings-container.component.html',
  styleUrls: ['./settings-container.component.scss']
})
export class SettingsContainerComponent implements OnInit, OnDestroy {
  PageTitleAppearanceEnum = PageTitleAppearance;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonTypeEnum = ButtonType;
  LocalesEnum = Locales;
  userSettings$: Observable<UserSettings>;
  onDestroySubject = new Subject();

  public settingsFormGroup: FormGroup;

  constructor(
    private store: Store<AppState>,
    @Inject(LOCALE_ID) public localeId: string,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private translationService: TranslationService
  ) {
    this.settingsFormGroup = this.fb.group({
      phoneNumber: [null, [Validators.maxLength(10), Validators.minLength(10)]],
      textOptIn: [false],
      language: [null, [Validators.required]]
    });
  }

  ngOnInit() {
    this.userSettings$ = this.store.pipe(select(selectUserSettings));

    this.userSettings$.pipe(first()).subscribe(userSettings => {
      this.settingsFormGroup.setValue({
        phoneNumber: userSettings.phoneNumber,
        textOptIn:
          userSettings.textOptIn !== null ? userSettings.textOptIn : false,
        language: userSettings.language
      });

      if (userSettings.textOptIn === true) {
        this.settingsFormGroup
          .get('phoneNumber')
          .setValidators([
            Validators.required,
            Validators.maxLength(10),
            Validators.minLength(10)
          ]);
        this.settingsFormGroup.get('phoneNumber').updateValueAndValidity();
      }

      this.settingsFormGroup
        .get('textOptIn')
        .valueChanges.pipe(takeUntil(this.onDestroySubject))
        .subscribe(() => {
          if (this.settingsFormGroup.get('textOptIn').value === true) {
            this.settingsFormGroup
              .get('phoneNumber')
              .setValidators([
                Validators.required,
                Validators.maxLength(10),
                Validators.minLength(10)
              ]);
            this.settingsFormGroup.get('phoneNumber').updateValueAndValidity();
          } else {
            this.settingsFormGroup
              .get('phoneNumber')
              .setValidators([
                Validators.maxLength(10),
                Validators.minLength(10)
              ]);
            this.settingsFormGroup.get('phoneNumber').updateValueAndValidity();
          }
        });
    });

    this.route.queryParams.subscribe(params => {
      if (params.languageChange) {
        const isLanguageChanged = JSON.parse(params.languageChange);

        if (isLanguageChanged) {
          this.store.dispatch(
            LayoutActions.showSuccessMessage({
              title: this.translationService.translate(
                TranslationMessages.SettingsSaved
              )
            })
          );
        }
      }
    });
  }

  onSubmit(form) {
    if (form.valid) {
      const userSettings = {
        phoneNumber: form.get('phoneNumber').value,
        language: form.get('language').value,
        textOptIn: form.get('textOptIn').value
      } as UserSettings;

      this.store.dispatch(UserActions.saveUserSettings({ userSettings }));
    }
  }

  ngOnDestroy() {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }
}
