<div class="date-picker">
  <select
    [ngClass]="{
      'date-picker__select': true,
      'date-picker__select--invalid': isInvalid
    }"
    [attr.id]="id + 'month'"
    attr.name="day"
    [formControl]="formGroup.get('month')"
    [attr.aria-invalid]="isInvalid"
    [attr.aria-describedby]="isInvalid ? errorId : null"
    [attr.disabled]="disabled ? '' : null"
  >
    <option value="" selected hidden>{{ monthPlaceholder }}</option>
    <option [ngValue]="month.value" *ngFor="let month of months">
      {{ month.name }}
    </option>
  </select>

  <select
    [ngClass]="{
      'date-picker__select': true,
      'date-picker__select--invalid': isInvalid
    }"
    [attr.id]="id + 'day'"
    attr.name="day"
    [formControl]="formGroup.get('day')"
    [attr.aria-invalid]="isInvalid"
    [attr.aria-describedby]="isInvalid ? errorId : null"
    [attr.disabled]="disabled ? '' : null"
  >
    <option value="" selected hidden>{{ dayPlaceholder }}</option>
    <option [ngValue]="day.value" *ngFor="let day of days">
      {{ day.name }}
    </option>
  </select>

  <select
    [ngClass]="{
      'date-picker__select': true,
      'date-picker__select--invalid': isInvalid
    }"
    [attr.id]="id + 'year'"
    attr.name="day"
    [formControl]="formGroup.get('year')"
    [attr.aria-invalid]="isInvalid"
    [attr.aria-describedby]="isInvalid ? errorId : null"
    [attr.disabled]="disabled ? '' : null"
  >
    <option value="" selected hidden>{{ yearPlaceholder }}</option>
    <option [ngValue]="year" *ngFor="let year of years">
      {{ year }}
    </option>
  </select>
</div>
