/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/tab-bar/tab-bar-component/tab-bar.component.ngfactory";
import * as i2 from "../../../shared/components/tab-bar/tab-bar-component/tab-bar.component";
import * as i3 from "../../../shared/components/tab-bar/tab-bar-link/tab-bar-link.component.ngfactory";
import * as i4 from "../../../shared/components/tab-bar/tab-bar-link/tab-bar-link.component";
import * as i5 from "./team-member-tabs.component";
var styles_TeamMemberTabsComponent = [];
var RenderType_TeamMemberTabsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TeamMemberTabsComponent, data: {} });
export { RenderType_TeamMemberTabsComponent as RenderType_TeamMemberTabsComponent };
export function View_TeamMemberTabsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-tab-bar", [], null, null, null, i1.View_TabBarComponent_0, i1.RenderType_TabBarComponent)), i0.ɵdid(1, 114688, null, 0, i2.TabBarComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "app-tab-bar-link", [], null, null, null, i3.View_TabBarLinkComponent_0, i3.RenderType_TabBarLinkComponent)), i0.ɵdid(3, 114688, null, 0, i4.TabBarLinkComponent, [], { link: [0, "link"] }, null), (_l()(), i0.ɵted(-1, 0, [" Production "])), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "app-tab-bar-link", [], null, null, null, i3.View_TabBarLinkComponent_0, i3.RenderType_TabBarLinkComponent)), i0.ɵdid(6, 114688, null, 0, i4.TabBarLinkComponent, [], { link: [0, "link"] }, null), (_l()(), i0.ɵted(-1, 0, [" Check-Ins "]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "../production"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "../check-ins"; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_TeamMemberTabsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-team-member-tabs", [], null, null, null, View_TeamMemberTabsComponent_0, RenderType_TeamMemberTabsComponent)), i0.ɵdid(1, 49152, null, 0, i5.TeamMemberTabsComponent, [], null, null)], null, null); }
var TeamMemberTabsComponentNgFactory = i0.ɵccf("app-team-member-tabs", i5.TeamMemberTabsComponent, View_TeamMemberTabsComponent_Host_0, {}, {}, []);
export { TeamMemberTabsComponentNgFactory as TeamMemberTabsComponentNgFactory };
