<app-page-header-container></app-page-header-container>

<main class="main-container">
  <app-page-title
    data-cy="pageHeaderTitle"
    [appearance]="PageTitleAppearanceEnum.Transparent"
    title="Easily Access Your Workloads"
    i18n-title="@@pwa-notification_pageTitle"
  ></app-page-title>

  <div class="pwa-notification">
    <div class="description">
      <div class="description__logo">
        <app-svg-icon
          [name]="SvgIconName.CapstoneLogo"
          class="description__logo-icon"
        ></app-svg-icon>
      </div>
      <p class="description__text" i18n="@@pwa-notification_description">
        Always keep your earnings and info one tap away. Add the MyCapstone app
        to your mobile home screen.
      </p>
    </div>

    <div class="bookmark">
      <h2 class="bookmark__title" i18n="@@pwa-notification_bookmark">
        Bookmark in {{ browserName }}
      </h2>

      <template
        #bookmarkDescription
        i18n="@@pwa-notification_bookmarkDescription"
      >
        Tap the share icon in your browser window and tap
      </template>
      <template #addToHomeScreen i18n="@@pwa-notification_addToHomeScreen">
        Add To Home Screen
      </template>
      <p>
        {{ bookmarkDescription.innerText }}
        <span class="bookmark__bold">
          "{{ addToHomeScreen.innerText }}
          <span class="bookmark__icon"></span>
          "
        </span>
      </p>
      <app-svg-icon
        [name]="SvgIconName.ArrowDown"
        class="bookmark__arrow"
      ></app-svg-icon>
    </div>
  </div>
</main>
