import moment from 'moment';
import { IdDateFormat } from '../../../../shared/constants';
import { selectIsUserTrainee } from '../../../../reducers';
import { map, first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
export class GoalPreviewGuard {
    constructor(router, store) {
        this.router = router;
        this.store = store;
    }
    canActivate(route) {
        return this.store.select(selectIsUserTrainee).pipe(first(), map(isUserTrainee => {
            if (moment(route.params.id, IdDateFormat, true).isValid() &&
                !isUserTrainee) {
                return true;
            }
            this.router.navigate(['wages']);
            return false;
        }));
    }
}
GoalPreviewGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoalPreviewGuard_Factory() { return new GoalPreviewGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store)); }, token: GoalPreviewGuard, providedIn: "root" });
