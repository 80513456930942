import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  AppState,
  getUserId,
  selectManagerShifts,
  selectShift
} from '../../../../reducers';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../../../shared/error-handlers/catch-app-error';
import { ShiftActions } from '../actions';
import {
  catchError,
  finalize,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { ShiftService } from '../services/shift.service';
import { forkJoin, of } from 'rxjs';
import { LayoutActions, UserActions } from '../../../../core/actions';
import { Router } from '@angular/router';
import { NewsActions } from '../../../actions';
import { TranslationService } from '../../../../shared/services/translation.service';
import { TranslationMessages } from '../../../../shared/enums/TranslationMessages';
import { SiteService } from '../../../../shared/services/site.service';

@Injectable()
export class ShiftEffects {
  constructor(
    private actions$: Actions,
    private shiftService: ShiftService,
    private siteService: SiteService,
    private store: Store<AppState>,
    private router: Router,
    @Inject(APP_ERROR_MESSAGES) private errorMessages,
    @Inject(LOCALE_ID) protected localeId: string,
    private translation: TranslationService
  ) {}

  loadShiftAndSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftActions.loadShiftAndSites),
      withLatestFrom(this.store.select(getUserId)),
      mergeMap(([action, userId]) => {
        return forkJoin([
          this.shiftService.getShift(action.shiftId),
          this.shiftService.getManagerSites(userId)
        ]).pipe(
          switchMap(([shift, sites]) => {
            return of(
              ShiftActions.shiftLoaded({ shift }),
              ShiftActions.sitesListLoaded({ response: sites })
            );
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );
  loadShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftActions.loadShift),
      withLatestFrom(this.store.select(selectShift)),
      switchMap(([action, shiftDraft]) => {
        if (
          shiftDraft != null &&
          shiftDraft.id === null &&
          action.id === null
        ) {
          return of(ShiftActions.shiftLoaded({ shift: shiftDraft }));
        }
        return this.shiftService.getShift(action.id).pipe(
          switchMap(shift => {
            if (shift.siteId) {
              return this.siteService.getSiteInfo(shift.siteId).pipe(
                switchMap(siteInfo => {
                  shift.siteInfo = siteInfo;
                  return of(ShiftActions.shiftLoaded({ shift }));
                })
              );
            } else {
              return of(ShiftActions.shiftLoaded({ shift }));
            }
          })
        );
      }),
      catchError(error => {
        if (error.name === 'EmptyError') {
          return of(ShiftActions.errorOccuredOnShiftLoading());
        }
        return handleAppError(this.errorMessages);
      })
    )
  );
  createShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftActions.createShift),
      tap(() =>
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }))
      ),
      mergeMap(action => {
        return this.shiftService.postShift(action.shift).pipe(
          switchMap(response => {
            if (response.sys !== null && response.sys.id !== null) {
              return of(
                LayoutActions.showSuccessMessage({
                  title: this.translation.translate(
                    TranslationMessages.ShiftRequestPosted
                  ),
                  subtitle: this.translation.translate(
                    TranslationMessages.ShiftRequestNotifiedBody
                  ),
                  persistRouteChangeCount: 1
                }),
                ShiftActions.shiftCreated()
              );
            }
          }),
          finalize(() =>
            this.store.dispatch(
              LayoutActions.toggleAppLoading({ loading: false })
            )
          )
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );
  updateShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftActions.updateShift),
      tap(() =>
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }))
      ),
      switchMap(action => {
        return this.shiftService.updateShift(action.shift).pipe(
          switchMap(response => {
            if (response.sys !== null && response.sys.id !== null) {
              return of(
                LayoutActions.showSuccessMessage({
                  title: this.translation.translate(
                    TranslationMessages.ShiftRequestUpdated
                  ),
                  subtitle: this.translation.translate(
                    TranslationMessages.ShiftRequestNotifiedBody
                  ),
                  persistRouteChangeCount: 1
                }),
                ShiftActions.shiftUpdated({ shiftId: action.shift.id })
              );
            }
          }),
          finalize(() =>
            this.store.dispatch(
              LayoutActions.toggleAppLoading({ loading: false })
            )
          )
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );
  shiftCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShiftActions.shiftCreated),
        tap(() => {
          return this.router.navigate(['/shifts']);
        })
      ),
    { dispatch: false }
  );
  previewShift$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShiftActions.previewShift),
        tap(() => {
          this.router.navigate(['shifts/preview']);
        })
      ),
    { dispatch: false }
  );
  shiftUpdated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShiftActions.shiftUpdated),
        tap(action => {
          return this.router.navigate(['/shifts/details', action.shiftId]);
        })
      ),
    { dispatch: false }
  );
  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftActions.loadSites),
      withLatestFrom(this.store.select(getUserId)),
      mergeMap(([action, userId]) => {
        return this.shiftService.getManagerSites(userId).pipe(
          switchMap(response => {
            return of(ShiftActions.sitesListLoaded({ response }));
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );
  removeShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftActions.removeShift),
      tap(() =>
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }))
      ),
      switchMap(action => {
        return this.shiftService.removeShift(action.shiftId).pipe(
          switchMap(response => {
            return of(
              NewsActions.removeShiftPostFromFeed({ postId: action.shiftId }),
              ShiftActions.shiftRemoved({ shiftId: action.shiftId }),
              LayoutActions.showInfoMessage({
                message: this.translation.translate(
                  TranslationMessages.ShiftRequestRemoved
                ),
                persistRouteChangeCount: 1
              })
            );
          }),
          finalize(() =>
            this.store.dispatch(
              LayoutActions.toggleAppLoading({ loading: false })
            )
          )
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );
  shiftRemoved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShiftActions.shiftRemoved),
        tap(action => {
          return this.router.navigate(['/shifts']);
        })
      ),
    { dispatch: false }
  );
  getShiftCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.agreeWithTerms),
      withLatestFrom(this.store.select(getUserId)),
      mergeMap(([action, userId]) => {
        return this.shiftService.getShiftCount(userId).pipe(
          switchMap(response => {
            return of(ShiftActions.shiftCountLoaded({ response }));
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );
  loadManagerShifts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftActions.loadManagerShifts),
      withLatestFrom(
        this.store.select(getUserId),
        this.store.select(selectManagerShifts)
      ),
      switchMap(([action, userId, managerShifts]) => {
        if (managerShifts != null) {
          return of(
            ShiftActions.managerShiftsLoaded({ shifts: managerShifts })
          );
        }

        return this.shiftService.getManagerShifts(userId).pipe(
          switchMap(shifts => {
            return of(ShiftActions.managerShiftsLoaded({ shifts }));
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );
}
