import { select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { switchMap, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
import * as i3 from "../services/mock/survey.service";
export class SurveyGuard {
    constructor(router, store, surveysService) {
        this.router = router;
        this.store = store;
        this.surveysService = surveysService;
    }
    canActivate(route) {
        return this.store.pipe(select(fromRoot.getUserId), switchMap(userId => {
            return this.surveysService.getAvailableSurveys(userId).pipe(map(surveys => {
                const activeSurvey = surveys.find(s => s.id === route.params.id);
                if (activeSurvey) {
                    return true;
                }
                this.router.navigate(['']);
                return false;
            }));
        }));
    }
}
SurveyGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SurveyGuard_Factory() { return new SurveyGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.SurveyService)); }, token: SurveyGuard, providedIn: "root" });
