<app-modal-layout (closeModal)="closeModal()">
  <ng-template #secondaryText i18n="@@about-check-in_closeModalSecondaryText">
    About Check-Ins
  </ng-template>

  <app-modal-header
    slot="modal-header"
    [secondaryTextTemplate]="secondaryText"
  ></app-modal-header>
  <div slot="modal-content">
    <app-modal-content-row [sideSpacing]="true">
      <h2
        class="about-check-in__heading"
        i18n="@@about-check-in_whatAreWeeklyCheckIn"
      >
        What's are weekly Check-In?
      </h2>
      <p i18n="@@about-check-in_whatAreWeeklyCheckInAnswer">
        Weekly Check-Ins are a quickly way to get your feedback on how your week
        went
      </p>
      <h2
        class="about-check-in__heading"
        i18n="@@about-check-in_whatHappensWhenIFillOne"
      >
        What happens when I fill one out?
      </h2>
      <p i18n="@@about-check-in_whatHappensWhenIFillOneAnswer">
        Your site manager can see every associate's check-in score. This helps
        them keep track of who's feeling successful and who can use some help.
      </p>
    </app-modal-content-row>
  </div>
</app-modal-layout>
