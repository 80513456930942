/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./surveys.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/survey-card/survey-card.component.ngfactory";
import * as i3 from "../../components/survey-card/survey-card.component";
import * as i4 from "@angular/common";
import * as i5 from "./surveys.component";
import * as i6 from "@ngrx/store";
var styles_SurveysComponent = [i0.styles];
var RenderType_SurveysComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SurveysComponent, data: {} });
export { RenderType_SurveysComponent as RenderType_SurveysComponent };
function View_SurveysComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "survey-list__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-survey-card", [], null, null, null, i2.View_SurveyCardComponent_0, i2.RenderType_SurveyCardComponent)), i1.ɵdid(2, 49152, null, 0, i3.SurveyCardComponent, [], { survey: [0, "survey"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SurveysComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["class", "survey-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SurveysComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.surveys$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SurveysComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_SurveysComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.surveys$)).length != 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SurveysComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-surveys", [], null, null, null, View_SurveysComponent_0, RenderType_SurveysComponent)), i1.ɵdid(1, 114688, null, 0, i5.SurveysComponent, [i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SurveysComponentNgFactory = i1.ɵccf("app-surveys", i5.SurveysComponent, View_SurveysComponent_Host_0, {}, {}, []);
export { SurveysComponentNgFactory as SurveysComponentNgFactory };
