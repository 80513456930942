/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multi-step-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./pipes/get-next-editable-step-title.pipe";
import * as i4 from "./pipes/can-step-be-editable.pipe";
import * as i5 from "./services/multi-step-form.service";
import * as i6 from "./multi-step-form.component";
var styles_MultiStepFormComponent = [i0.styles];
var RenderType_MultiStepFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultiStepFormComponent, data: {} });
export { RenderType_MultiStepFormComponent as RenderType_MultiStepFormComponent };
function View_MultiStepFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MultiStepFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 4, null, View_MultiStepFormComponent_3)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵppd(4, 2), i1.ɵpod(5, { step: 0, stepForm: 1, nextStepTitle: 2 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, _v.parent.context.$implicit, _co.formGroup.get(_v.parent.context.$implicit.name), i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.multiStepFormService.editableStep$)), _co.formSteps))); var currVal_1 = ((_v.parent.context.$implicit.editableDef == null) ? null : _v.parent.context.$implicit.editableDef.template); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MultiStepFormComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MultiStepFormComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 4, null, View_MultiStepFormComponent_5)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵppd(3, 3), i1.ɵpod(4, { step: 0, stepForm: 1, canStepBeEditable: 2 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _v.parent.context.$implicit, _co.formGroup.get(_v.parent.context.$implicit.name), i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 1), _v.parent.context.index, _co.formSteps, i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.multiStepFormService.editableStep$))))); var currVal_1 = ((_v.parent.context.$implicit.readOnlyDef == null) ? null : _v.parent.context.$implicit.readOnlyDef.template); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MultiStepFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "section", [], [[1, "id", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "multi-step-form": 0, "is-active": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultiStepFormComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["readOnly", 2]], null, 0, null, View_MultiStepFormComponent_4))], function (_ck, _v) { var currVal_1 = _ck(_v, 4, 0, true, _v.context.$implicit.isEditable); _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.isEditable; var currVal_3 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
export function View_MultiStepFormComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.GetNextEditableStepTitlePipe, []), i1.ɵpid(0, i4.CanStepBeEditablePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultiStepFormComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formSteps; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MultiStepFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-multi-step-form", [], null, null, null, View_MultiStepFormComponent_0, RenderType_MultiStepFormComponent)), i1.ɵprd(512, null, i5.MultiStepFormService, i5.MultiStepFormService, []), i1.ɵdid(2, 114688, null, 1, i6.MultiStepFormComponent, [i5.MultiStepFormService], null, null), i1.ɵqud(603979776, 1, { formSteps: 1 })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var MultiStepFormComponentNgFactory = i1.ɵccf("app-multi-step-form", i6.MultiStepFormComponent, View_MultiStepFormComponent_Host_0, { formGroup: "formGroup" }, {}, []);
export { MultiStepFormComponentNgFactory as MultiStepFormComponentNgFactory };
