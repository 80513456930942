<main class="main-container">
  <ng-template #newsFeed i18n="@@news-feed_pageTitle">Newsfeed</ng-template>
  <app-page-title
    class="visually-hidden"
    [titleTemplate]="newsFeed"
    [appearance]="PageTitleAppearanceEnum.Transparent"
    data-cy="pageHeaderTitle"
  ></app-page-title>

  <app-loader [isLoading]="isNewsDataLoading$ | async"></app-loader>

  <ng-container>
    <div
      class="news-feed"
      role="feed"
      [attr.aria-busy]="
        (isNewsFeedLoading$ | async) && page > 0 ? 'true' : 'false'
      "
    >
      <ng-container *ngFor="let post of news$ | async; let i = index">
        <app-feed-separator
          *ngIf="post.isFirstDayPost"
          [date]="post.date"
          [isFirst]="i === 0"
          [unseenCount]="unseenNewsCount$"
        ></app-feed-separator>

        <app-shift-request-card
          *ngIf="post.category === PostCategoryTypeEnum.WorkOpportunity"
          [post]="post"
          [ariaPosinset]="i + 1"
          [ariaSetsize]="(news$ | async)?.length"
        ></app-shift-request-card>

        <app-news-feed-card
          *ngIf="post.category !== PostCategoryTypeEnum.WorkOpportunity"
          [post]="post"
          [ariaPosinset]="i + 1"
          [ariaSetsize]="(news$ | async)?.length"
          [extendableBlogPost]="!!blogPostsExtendable[post.id]?.isExtendable"
          (postLiked)="onLikeEvent($event)"
          (contentSizeChanged)="onContentSizeChange($event)"
        ></app-news-feed-card>
      </ng-container>
    </div>

    <div
      *ngIf="
        (isNewsDataLoading$ | async) === false &&
        (isAppOnline$ | async) &&
        (isErrorOccurredOnNewsLoading$ | async) === false &&
        (totalCount$ | async) !== (news$ | async)?.length
      "
      class="news-feed__loader"
    >
      <app-loader
        [debounceTime]="0"
        [isLoading]="(isNewsFeedLoading$ | async) && page > 0"
      ></app-loader>
    </div>
  </ng-container>

  <app-footer *ngIf="(totalCount$ | async) === (news$ | async)?.length">
    <app-footer-note>
      <ng-container i18n="@@youHaveSeenItAll">You've seen it all!</ng-container>
    </app-footer-note>
    <app-footer-note>
      <ng-container i18n="@@news-feed_veryBeginningOfTheNewsFeed">
        This is the very beginning of the Newsfeed.
      </ng-container>
    </app-footer-note>
  </app-footer>

  <app-footer
    *ngIf="
      (isErrorOccurredOnNewsLoading$ | async) ||
      (isAppOnline$ | async) === false
    "
  >
    <app-footer-note>
      <ng-container i18n="@@weHadTroubleOnLoadingTheNextPost">
        We had trouble loading the next post.
      </ng-container>
    </app-footer-note>
    <app-footer-note>
      <ng-container i18n="@@news-feed_checkInternetConnection">
        Check your connection and
        <a href="news">try again</a>
      </ng-container>
    </app-footer-note>
  </app-footer>
</main>
