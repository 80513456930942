<div class="signature" #padContainer>
  <signature-pad
    class="signature__pad"
    [attr.aria-labelledby]="id"
    [options]="signaturePadOptions"
    (onEndEvent)="onDrawComplete()"
    (onBeginEvent)="onDrawStart()"
  ></signature-pad>
  <div class="signature__bottom">
    <div class="signature__action">
      <button
        [ngClass]="{
          signature__clear: true,
          'signature__clear--blue': hasValue
        }"
        type="button"
        (click)="clearSignature()"
        cdkMonitorElementFocus
      >
        <app-svg-icon
          class="signature__icon"
          [name]="SvgIconNameEnum.Close"
        ></app-svg-icon>
        <span class="visually-hidden">Clear</span>
      </button>
      <div
        [id]="id"
        *ngIf="placeholder"
        [ngClass]="{
          signature__placeholder: true,
          'signature__placeholder--invalid': isInvalid,
          'is-visible': !hasValue
        }"
      >
        ({{ placeholder }})
      </div>
    </div>
    <div class="signature__details">
      <span>{{ name }} {{ surname }}</span>
      <time [attr.datetime]="date">{{ date | date: 'MM/dd/yy' }}</time>
    </div>
  </div>
</div>
