import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Survey } from '../../core/dataEntities/surveys/survey';
import { Action, createReducer, on } from '@ngrx/store';
import { LayoutActions, UserActions } from '../../core/actions';
import { SurveysActions } from '../actions';
import { SurveySummary } from '../../core/dataEntities/surveys/surveySummary';

export const surveysFeatureKey = 'surveys';

export const adapter: EntityAdapter<SurveySummary> = createEntityAdapter<
  SurveySummary
>();

export interface SurveysState extends EntityState<SurveySummary> {
  isSurveysLoading: boolean;
  isEachSurveySubmitted: boolean;
}

export const initialState: SurveysState = adapter.getInitialState({
  isSurveysLoading: false,
  isEachSurveySubmitted: true
});

export const surveysReducer = createReducer(
  initialState,
  on(SurveysActions.loadSurveys, (state, action) => {
    return {
      ...state,
      isSurveysLoading: true
    };
  }),
  on(SurveysActions.surveysLoaded, (state, action) => {
    const surveys = action.surveys.map(survey => {
      return {
        ...survey
      } as Survey;
    });
    const emptySurveysState = adapter.removeAll(state);
    return adapter.addMany(surveys, {
      ...emptySurveysState,
      isSurveysLoading: false
    });
  }),
  on(SurveysActions.surveysStatusChecked, state => {
    return {
      ...state,
      isSurveysLoading: false
    };
  }),
  on(SurveysActions.surveySubmitted, (state, action) => {
    const newSurveysState = adapter.removeOne(action.id, state);
    return {
      ...newSurveysState,
      isSurveysLoading: false
    };
  }),
  on(SurveysActions.saveIsEachSurveySubmitted, (state, action) => {
    return {
      ...state,
      isEachSurveySubmitted: !action.result
    };
  }),
  on(LayoutActions.showError, LayoutActions.apiOffline, state => {
    return {
      ...state,
      isSurveysLoading: false
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(state: SurveysState | undefined, action: Action) {
  return surveysReducer(state, action);
}

const { selectAll, selectTotal } = adapter.getSelectors();
export const selectAllSurveys = selectAll;

export const selectIsSurveysLoading = (state: SurveysState) => {
  return state.isSurveysLoading;
};

export const selectTotalSurveys = selectTotal;

export const selectIsEachSurveySubmitted = state => {
  return state.isEachSurveySubmitted;
};
