<app-modal-layout (closeModal)="closeModal()">
  <app-modal-header
    slot="modal-header"
    i18n-primaryText="@@change-photo_changeYour"
    primaryText="Change Your"
    i18n-secondaryText="@@change-photo_profilePhoto"
    secondaryText="Profile Photo"
  ></app-modal-header>
  <div slot="modal-content" class="change-photo">
    <div class="change-photo__actions">
      <app-button-link
        (buttonClick)="deletePhoto()"
        i18n-label="@@change-photo_remove"
        label="Remove Photo"
        [color]="ButtonColor.Red"
        [appearance]="ButtonAppearanceEnum.Link"
      ></app-button-link>

      <app-button-link
        (buttonClick)="replacePhoto()"
        i18n-label="@@change-photo_replace"
        label="Replace Photo"
        [appearance]="ButtonAppearanceEnum.Primary"
      ></app-button-link>
    </div>
  </div>
</app-modal-layout>
