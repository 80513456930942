import { Action, createReducer, on } from '@ngrx/store';
import { LayoutActions, UserActions } from '../actions';
import _ from 'lodash';
import { Roles } from '../enums/Roles';
import { DepartmentInfo } from '../responses/managers/subDepartmentsResponse';
import { UserSettings } from '../dataEntities/user/userSettings';
import { ProfileActions } from '../../profile/actions';
import { TrainingStatus } from '../../shared/enums/TrainingStatus';

export const userFeatureKey = 'user';

export interface UserState {
  isUserLoggedIn: boolean;
  isUserLoggingOut: boolean;
  isUserAgreeWithTerms: boolean;
  isUserProfileLoading: boolean;
  id: string;
  email: string;
  name: string;
  token: string;
  roles?: string[];
  apexId?: number;
  hasRoadCrewAccess: boolean;
  roadCrewErrorMessage: string;
  eid?: number;
  siteInfo: {
    homeSite?: number;
    siteAddress?: string;
    siteName?: string;
  };
  managerInfo?: {
    responsibleSubDepartments: {
      managedSubDepartments: DepartmentInfo[];
      accessSubDepartments: DepartmentInfo[];
    };
  };
  position?: string;
  userSettings: UserSettings;
  weeklyCheckIns: {
    currentWeek: boolean;
    currentWeekDate: string;
    pastWeek: boolean;
    pastWeekDate: string;
  };
  isTrainee: boolean;
  trainingStatus: TrainingStatus;
  photo: {
    profilePhoto?: string;
    tempPhoto: string;
  };
  siteState: string;
}

export const initialState: UserState = {
  isUserLoggedIn: false,
  isUserLoggingOut: false,
  isUserAgreeWithTerms: false,
  isUserProfileLoading: true,
  id: '',
  email: '',
  name: '',
  token: '',
  roles: [],
  hasRoadCrewAccess: false,
  roadCrewErrorMessage: '',
  siteInfo: {
    homeSite: null,
    siteAddress: null,
    siteName: null
  },
  managerInfo: null,
  apexId: null,
  eid: null,
  position: null,
  userSettings: null,
  weeklyCheckIns: null,
  isTrainee: false,
  trainingStatus: null,
  photo: {
    profilePhoto: null,
    tempPhoto: null
  },
  siteState: ''
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.userLogin, (state, action) => {
    return {
      ...state,
      isUserLoggedIn: true,
      id: action.user.id,
      name: action.user.name,
      email: action.user.email,
      token: action.user.token,
      roles:
        action.user.roles !== null &&
        action.user.roles !== undefined &&
        action.user.roles.length > 0
          ? action.user.roles
          : ['None']
    };
  }),
  on(UserActions.setAuthToken, (state, action) => {
    return {
      ...state,
      token: action.token
    };
  }),
  on(UserActions.agreeWithTerms, state => ({
    ...state,
    isUserAgreeWithTerms: true
  })),
  on(LayoutActions.appOnInit, state => ({
    ...state,
    isUserLoggingOut: false
  })),
  on(UserActions.userLogout, state => ({
    ...state,
    isUserLoggedIn: false,
    isUserLoggingOut: true,
    isUserAgreeWithTerms: false,
    isUserProfileLoading: true,
    email: '',
    id: '',
    name: '',
    token: '',
    roles: [],
    hasRoadCrewAccess: false,
    roadCrewErrorMessage: '',
    siteInfo: {
      homeSite: null,
      siteAddress: null,
      siteName: null
    },
    managerInfo: null,
    apexId: null,
    position: null,
    userSettings: null,
    weeklyCheckIns: null,
    isTrainee: false,
    trainingStatus: null,
    photo: {
      profilePhoto: null,
      tempPhoto: null
    },
    siteState: ''
  })),
  on(UserActions.userInfoLoaded, (state, action) => {
    const managerInfo = action.userInfo.responsibleSubDepartments
      .accessSubDepartments
      ? {
          responsibleSubDepartments: action.userInfo.responsibleSubDepartments
        }
      : null;

    return {
      ...state,
      isUserProfileLoading: false,
      hasRoadCrewAccess:
        action.userInfo.eid != null
          ? action.userInfo.hasRoadCrewAccess
            ? true
            : false
          : true,
      siteState: action.userInfo.siteState,
      roadCrewErrorMessage: action.userInfo.roadCrewErrorMessage,
      apexId: action.userInfo.apexId,
      eid: action.userInfo.eid,
      siteInfo: {
        homeSite: action.userInfo.homeSite,
        siteAddress: action.userInfo.siteAddress,
        siteName: action.userInfo.siteName
      },
      managerInfo,
      position: action.userInfo.position,
      userSettings: {
        phoneNumber: action.userInfo.preference.phoneNumber
          ? action.userInfo.preference.phoneNumber.replace(/[-()]/g, '')
          : null,
        language: action.userInfo.preference.language,
        textOptIn: action.userInfo.preference.textOptIn
      },
      weeklyCheckIns: action.userInfo.weeklyCheckIns,
      isTrainee: action.userInfo.isTrainee,
      trainingStatus: action.userInfo.trainingStatus,
      photo: {
        profilePhoto: action.userInfo.profilePhoto,
        tempPhoto: null
      }
    };
  }),
  on(UserActions.userSettingsSaved, (state, action) => {
    return {
      ...state,
      userSettings: action.userSettings
    };
  }),
  on(ProfileActions.weeklyCheckInSaved, (state, action) => {
    return {
      ...state,
      weeklyCheckIns: {
        currentWeekDate: state.weeklyCheckIns.currentWeekDate,
        currentWeek:
          action.date === state.weeklyCheckIns.currentWeekDate
            ? false
            : state.weeklyCheckIns.currentWeek,
        pastWeekDate: state.weeklyCheckIns.pastWeekDate,
        pastWeek:
          action.date === state.weeklyCheckIns.pastWeekDate
            ? false
            : state.weeklyCheckIns.pastWeek
      }
    };
  }),
  on(UserActions.takePhoto, (state, action) => {
    return {
      ...state,
      photo: {
        ...state.photo,
        tempPhoto: action.photo
      }
    };
  }),
  on(UserActions.photoSaved, (state, action) => {
    return {
      ...state,
      photo: {
        profilePhoto: action.photo,
        tempPhoto: null
      }
    };
  }),
  on(UserActions.photoRemoved, (state, action) => {
    return {
      ...state,
      photo: {
        profilePhoto: null,
        tempPhoto: null
      }
    };
  })
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

export const getIsUserLoggedIn = (state: UserState) => {
  return state.isUserLoggedIn;
};

export const getUserName = (state: UserState) => {
  return state.name;
};

export const getUserRoles = (state: UserState) => {
  return state.roles !== undefined &&
    state.roles !== null &&
    state.roles.length > 0
    ? state.roles
    : ['None'];
};

export const getUserEmail = (state: UserState) => {
  return state.email;
};

export const getUserId = (state: UserState) => {
  return state.id;
};

export const getUserEmailPrefix = (state: UserState) => {
  if (state.email != null && !_.isEmpty(state.email)) {
    return state.email.match(/(.*)(@capstonelogistics.com)/)[1];
  }
  return state.email;
};

export const getApexId = (state: UserState) => {
  return state.apexId;
};

export const getEid = (state: UserState) => {
  return state.eid;
};

export const getIsUserAgreeWithTerms = (state: UserState) => {
  return state.isUserAgreeWithTerms;
};

export const getAuthToken = (state: UserState) => {
  return state.token;
};
export const getHasRoadCrewAccess = (state: UserState) => {
  return state.hasRoadCrewAccess;
};

export const getRoadCrewErrorMessage = (state: UserState) => {
  return state.roadCrewErrorMessage;
};

export const getAssociateHomeState = (state: UserState) => {
  return state.siteState;
};

export const getIsUserLoggingOut = (state: UserState) => {
  return state.isUserLoggingOut;
};

export const getUserSitesIds = (state: UserState) => {
  if (
    state.managerInfo &&
    state.managerInfo.responsibleSubDepartments.accessSubDepartments.length > 0
  ) {
    return state.managerInfo.responsibleSubDepartments.accessSubDepartments.map(
      site => site.subDept
    );
  }
  return [state.siteInfo.homeSite];
};

export const getManagerSubDepartments = (state: UserState) => {
  if (
    state.managerInfo.responsibleSubDepartments.accessSubDepartments.length > 0
  ) {
    return state.managerInfo.responsibleSubDepartments.accessSubDepartments;
  }

  return [];
};

export const getUserSites = (state: UserState) => {
  if (
    state.managerInfo &&
    state.managerInfo.responsibleSubDepartments.accessSubDepartments.length > 0
  ) {
    return state.managerInfo.responsibleSubDepartments.accessSubDepartments.map(
      site => site
    );
  }
  return [
    {
      subDept: state.siteInfo.homeSite,
      name: state.siteInfo.siteName,
      fullAddress: state.siteInfo.siteAddress
    } as DepartmentInfo
  ];
};

export const getSiteInfo = (state: UserState) => {
  return state.siteInfo;
};

export const selectUserSettings = (state: UserState) => {
  return state.userSettings;
};

export const selectIsUserProfileLoading = (state: UserState) => {
  return state.isUserProfileLoading;
};

export const selectWeeklyCheckIns = (state: UserState) => {
  return state.weeklyCheckIns;
};

export const selectIsUserTrainee = (state: UserState) => {
  return state.isTrainee;
};

export const selectUserTrainingStatus = (state: UserState) => {
  return state.trainingStatus;
};

export const selectUserPhoto = (state: UserState) => {
  return state.photo.profilePhoto;
};

export const selectTempPhoto = (state: UserState) => {
  return state.photo.tempPhoto;
};

export const getUserTitle = (state: UserState) => {
  if (state.roles.length === 0) {
    return 'Associate';
  }

  if (state.roles.includes(Roles.SiteManager)) {
    return 'Site Manager';
  }

  if (state.roles.includes(Roles.SiteLeads)) {
    return 'Site Lead';
  }

  if (state.roles.includes(Roles.SiteClerks)) {
    return 'Site Clerk';
  }

  if (state.roles.includes(Roles.SiteSupervisor)) {
    return 'Site Supervisor';
  }

  if (state.roles.includes(Roles.None)) {
    return 'None';
  }
};
