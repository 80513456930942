<nav class="navigation-bar">
  <app-navigation-link
    title="Earnings"
    i18n-title="@@navigation-bar_earnings"
    [link]="'/wages'"
    [iconName]="SvgIconNameEnum.BanknoteBold"
    [appearance]="activeModule$ | async"
    [module]="ModuleEnum.Wages"
  ></app-navigation-link>
  <app-navigation-link
    *appFeatureToggle="Features.Opportunities"
    title="Road Crew"
    i18n-title="@@navigation-bar_road-crew"
    [link]="'/roadcrew'"
    [iconName]="SvgIconNameEnum.Truck"
    [appearance]="activeModule$ | async"
    [module]="ModuleEnum.RoadCrew"
    [indicator]="(isEachOpportunityReviewed$ | async) === false"
  ></app-navigation-link>
  <app-navigation-link
    title="Newsfeed"
    i18n-title="@@navigation-bar_newsFeed"
    [link]="'/news'"
    [iconName]="SvgIconNameEnum.Newspaper"
    [appearance]="activeModule$ | async"
    [module]="ModuleEnum.News"
  ></app-navigation-link>
  <app-navigation-link
    *appIfHasPermission="[Roles.Associate]"
    title="My Profile"
    i18n-title="@@navigation-bar_myProfile"
    [link]="'profile/production'"
    [iconName]="SvgIconNameEnum.PersonWStar"
    [appearance]="activeModule$ | async"
    [module]="ModuleEnum.MyProfile"
    [indicator]="(isEachSurveySubmitted$ | async) === false"
  ></app-navigation-link>
  <app-navigation-link
    *appIfHasPermission="[Roles.SiteManager, Roles.SiteSupervisor]"
    title="My Team"
    i18n-title="@@navigation-bar_myTeam"
    [link]="'/my-team'"
    [iconName]="SvgIconNameEnum.MyTeam"
    [appearance]="activeModule$ | async"
    [module]="ModuleEnum.MyTeam"
  ></app-navigation-link>
  <app-navigation-link
    *appFeatureToggle="Features.Covid"
    title="COVID-19"
    i18n-title="@@navigation-bar_covid"
    [link]="'covid'"
    [iconName]="SvgIconNameEnum.Virus"
    [appearance]="activeModule$ | async"
    [module]="ModuleEnum.Covid"
  ></app-navigation-link>
</nav>
