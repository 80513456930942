import { OpenShiftListItem } from '../../../../core/dataEntities/opportunities/openShiftListItem';
import { OpportunitiesActions } from '../actions';
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import { UserActions } from '../../../../core/actions';
import { OpportunityListItem } from '../../../../core/dataEntities/opportunities/opportunityListItem';
import { AppliedOpportunityListItem } from '../../../../core/dataEntities/opportunities/appliedOpportunityListItem';
import { RoadCrewApplication } from '../../../../core/dataEntities/applicationForm/roadCrewApplication';
import { ApplicationFormStatus } from '../../../../core/enums/ApplicationFormStatus';

export const opportunitiesFeatureKey = 'opportunities';

export interface OpportunitiesState {
  openShifts: OpenShiftListItem[];
  roadCrewOpenings: OpportunityListItem[];
  roadCrewOpeningsCount: number;
  updatedOpportunities: AppliedOpportunityListItem[];
  pendingOpportunities: AppliedOpportunityListItem[];
  isOpportunitiesLoading: boolean;
  applicationForm: RoadCrewApplication;
  applicationFormStatus: ApplicationFormStatus;
  rejectionReason: string;
  isEachOpportunityReviewed: boolean;
}

export const initialState: OpportunitiesState = {
  openShifts: null,
  roadCrewOpenings: null,
  roadCrewOpeningsCount: null,
  updatedOpportunities: null,
  pendingOpportunities: null,
  isOpportunitiesLoading: false,
  applicationForm: null,
  applicationFormStatus: null,
  rejectionReason: '',
  isEachOpportunityReviewed: true
};

export const opportunitiesReducer = createReducer(
  initialState,
  on(OpportunitiesActions.loadOpportunities, (state, action) => {
    return {
      ...state,
      isOpportunitiesLoading: true
    };
  }),
  on(OpportunitiesActions.allOpportunitiesLoaded, (state, action) => {
    return {
      ...state,
      roadCrewOpenings: action.roadCrewOpenings,
      roadCrewOpeningsCount: action.roadCrewOpeningsCount,
      updatedOpportunities: action.updatedOpportunities,
      pendingOpportunities: action.pendingOpportunities,
      isOpportunitiesLoading: false
    };
  }),
  on(OpportunitiesActions.saveApplicationForm, (state, action) => {
    return {
      ...state,
      applicationForm: action.form
    };
  }),
  on(OpportunitiesActions.applicationFormLoaded, (state, action) => {
    return {
      ...state,
      applicationForm: action.form,
      applicationFormStatus: action.formStatus,
      rejectionReason:
        action.form != null ? action.form.travelInfo.rejectionReason : ''
    };
  }),
  on(OpportunitiesActions.saveIsEachOpportunityReviewed, (state, action) => {
    return {
      ...state,
      isEachOpportunityReviewed: action.result
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(state: OpportunitiesState | undefined, action: Action) {
  return opportunitiesReducer(state, action);
}

export const getOpportunitiesState = createFeatureSelector<OpportunitiesState>(
  opportunitiesFeatureKey
);

export const selectIsOpportunitiesLoading = createSelector(
  getOpportunitiesState,
  (state: OpportunitiesState) => state.isOpportunitiesLoading
);

export const selectOpenShifts = createSelector(
  getOpportunitiesState,
  (state: OpportunitiesState) => state.openShifts
);

export const selectRoadCrewOpenings = createSelector(
  getOpportunitiesState,
  (state: OpportunitiesState) => state.roadCrewOpenings
);

export const selectRoadCrewOpeningsCount = createSelector(
  getOpportunitiesState,
  (state: OpportunitiesState) => state.roadCrewOpeningsCount
);

export const selectUpdatedOpportunities = createSelector(
  getOpportunitiesState,
  (state: OpportunitiesState) => state.updatedOpportunities
);

export const selectPendingOpportunities = createSelector(
  getOpportunitiesState,
  (state: OpportunitiesState) => state.pendingOpportunities
);

export const selectApplicationForm = createSelector(
  getOpportunitiesState,
  (state: OpportunitiesState) => state.applicationForm
);

export const selectIsEachOpportunityReviewed = createSelector(
  getOpportunitiesState,
  state => state.isEachOpportunityReviewed
);

export const selectApplicationFormStatus = createSelector(
  getOpportunitiesState,
  state => state.applicationFormStatus
);

export const rejectionReason = createSelector(
  getOpportunitiesState,
  state => state.rejectionReason
);
