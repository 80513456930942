<div
  [ngClass]="{
    'accordion-panel': true,
    'accordion-panel--expanded': isOpen,
    'accordion-panel--disabled': disabled
  }"
>
  <button
    class="accordion-panel__header"
    #panelTrigger
    cdkMonitorElementFocus
    (click)="onClick()"
    (focus)="onFocus()"
    [attr.id]="id"
    [attr.aria-expanded]="isOpen"
    [attr.aria-controls]="sectionId"
    [attr.disabled]="disabled ? 'true' : null"
    [attr.tabindex]="disabled ? '-1' : null"
  >
    <ng-container *ngIf="title; else customTemplate">
      <span class="accordion-panel__header-content">
        <span class="accordion-panel__header-title">{{ title }}</span>
        <span class="accordion-panel__header-subtitle">{{ subtitle }}</span>
      </span>
    </ng-container>
    <ng-template #customTemplate>
      <ng-content select="[slot='header']"></ng-content>
    </ng-template>
    <app-svg-icon
      [name]="SvgIconNameEnum.Chevron"
      class="accordion-panel__header-icon"
    ></app-svg-icon>
  </button>
  <div
    [ngClass]="{
      'accordion-panel__body': true,
      'accordion-panel__body--spacing': bodySpacing
    }"
    role="region"
    [attr.id]="sectionId"
    [attr.aria-labelledby]="id"
    [@openClose]="isOpen ? 'open' : 'close'"
  >
    <ng-content select="[slot='body']"></ng-content>
  </div>
</div>
