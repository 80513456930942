<span
  [ngClass]="{
    chip: true,
    'chip--green': appearance === ChipAppearanceEnum.Green,
    'chip--blue': appearance === ChipAppearanceEnum.Blue,
    'chip--yellow': appearance === ChipAppearanceEnum.Yellow,
    'chip--red': appearance === ChipAppearanceEnum.Red
  }"
>
  <ng-content></ng-content>
</span>
