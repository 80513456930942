/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-photo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/modal/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../../shared/components/modal/modal-layout/modal-layout.component";
import * as i4 from "../../../shared/components/modal/modal-header/modal-header.component.ngfactory";
import * as i5 from "../../../shared/components/modal/modal-header/modal-header.component";
import * as i6 from "../../../shared/components/button-link/button-link.component.ngfactory";
import * as i7 from "../../../shared/components/button-link/button-link.component";
import * as i8 from "../../../shared/services/analytics-utilities.service";
import * as i9 from "./change-photo.component";
import * as i10 from "../../../shared/modules/modal/dialog-ref";
import * as i11 from "../../../shared/modules/modal/dialog-config";
var styles_ChangePhotoComponent = [i0.styles];
var RenderType_ChangePhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangePhotoComponent, data: {} });
export { RenderType_ChangePhotoComponent as RenderType_ChangePhotoComponent };
export function View_ChangePhotoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "app-modal-layout", [], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalLayoutComponent, [], null, { closeModal: "closeModal" }), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-modal-header", [["primaryText", "Change Your"], ["secondaryText", "Profile Photo"], ["slot", "modal-header"]], null, null, null, i4.View_ModalHeaderComponent_0, i4.RenderType_ModalHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i5.ModalHeaderComponent, [], { primaryText: [0, "primaryText"], secondaryText: [1, "secondaryText"] }, null), (_l()(), i1.ɵeld(4, 0, null, 1, 5, "div", [["class", "change-photo"], ["slot", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "change-photo__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-button-link", [["label", "Remove Photo"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.deletePhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonLinkComponent_0, i6.RenderType_ButtonLinkComponent)), i1.ɵdid(7, 638976, null, 0, i7.ButtonLinkComponent, [i8.AnalyticsUtilitiesService], { appearance: [0, "appearance"], color: [1, "color"], label: [2, "label"] }, { buttonClick: "buttonClick" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-button-link", [["label", "Replace Photo"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.replacePhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonLinkComponent_0, i6.RenderType_ButtonLinkComponent)), i1.ɵdid(9, 638976, null, 0, i7.ButtonLinkComponent, [i8.AnalyticsUtilitiesService], { appearance: [0, "appearance"], label: [1, "label"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Change Your"; var currVal_1 = "Profile Photo"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.ButtonAppearanceEnum.Link; var currVal_3 = _co.ButtonColor.Red; var currVal_4 = "Remove Photo"; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.ButtonAppearanceEnum.Primary; var currVal_6 = "Replace Photo"; _ck(_v, 9, 0, currVal_5, currVal_6); }, null); }
export function View_ChangePhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-change-photo", [], null, null, null, View_ChangePhotoComponent_0, RenderType_ChangePhotoComponent)), i1.ɵdid(1, 49152, null, 0, i9.ChangePhotoComponent, [i10.DialogRef, i11.DIALOG_DATA], null, null)], null, null); }
var ChangePhotoComponentNgFactory = i1.ɵccf("app-change-photo", i9.ChangePhotoComponent, View_ChangePhotoComponent_Host_0, {}, {}, []);
export { ChangePhotoComponentNgFactory as ChangePhotoComponentNgFactory };
