import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TeamMemberDetails } from '../view-models/team-member-details';
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import { MyTeamActions } from '../actions';
import { AppState } from '../../reducers';
import _ from 'lodash';
import { UserActions } from '../../core/actions';

export const teamMemberDetailsFeatureKey = 'teamMemberDetails';

export interface TeamMemberDetailsState extends EntityState<TeamMemberDetails> {
  activeTeamMemberId: string;
  isWeeklyCheckInsLoading: boolean;
  isProductionDetailsLoading: boolean;
  isTeamMemberPhotoRemoving: boolean;
}

export const adapter: EntityAdapter<TeamMemberDetails> = createEntityAdapter<
  TeamMemberDetails
>();
export const initialState: TeamMemberDetailsState = adapter.getInitialState({
  activeTeamMemberId: '',
  isWeeklyCheckInsLoading: false,
  isProductionDetailsLoading: false,
  isTeamMemberPhotoRemoving: false
});

export const teamMemberDetailsReducer = createReducer(
  initialState,
  on(MyTeamActions.loadTeamMemberCheckIns, (state, action) => {
    return {
      ...state,
      activeTeamMemberId: action.associateId,
      isWeeklyCheckInsLoading: true
    };
  }),
  on(MyTeamActions.loadTeamMemberProduction, (state, action) => {
    return {
      ...state,
      activeTeamMemberId: action.associateId,
      isProductionDetailsLoading: true
    };
  }),
  on(MyTeamActions.myTeamMemberDetailsLoaded, (state, action) => {
    const member = state.entities[action.id];
    if (!member) {
      return adapter.upsertOne(
        {
          id: action.id,
          teamMemberInfo: {
            id: action.id,
            isTrainee: action.isTrainee,
            apexId: action.apexId,
            name: action.name,
            profilePhoto: action.profilePhoto
          }
        } as TeamMemberDetails,
        state
      );
    }

    return adapter.updateOne(
      {
        id: action.id,
        changes: {
          id: action.id,
          teamMemberInfo: {
            id: action.id,
            isTrainee: action.isTrainee,
            apexId: action.apexId,
            name: action.name,
            profilePhoto: action.profilePhoto
          }
        }
      },
      state
    );
  }),
  on(MyTeamActions.teamMemberCheckInsLoaded, (state, action) => {
    const updateTeamMember = {
      id: action.associateId,
      changes: {
        weeklyCheckInList: action.weeklyCheckinList
      }
    };

    return adapter.updateOne(updateTeamMember, state);
  }),
  on(MyTeamActions.toggleTeamMemberCheckInsIsLoading, (state, actions) => {
    return {
      ...state,
      isWeeklyCheckInsLoading: actions.isLoading
    };
  }),
  on(MyTeamActions.teamMemberProductionLoaded, (state, action) => {
    const updateTeamMember = {
      id: action.associateId,
      changes: {
        productionDetails: action.productionDetails
      }
    };

    return adapter.updateOne(updateTeamMember, state);
  }),
  on(MyTeamActions.toggleTeamMemberProductionIsLoading, (state, actions) => {
    return {
      ...state,
      isProductionDetailsLoading: actions.isLoading
    };
  }),
  on(MyTeamActions.teamMemberDetailsNotFound, (state, action) => {
    const member = state.entities[state.activeTeamMemberId];
    if (!member) {
      return adapter.upsertOne(
        {
          id: state.activeTeamMemberId,
          teamMemberInfo: null
        } as TeamMemberDetails,
        state
      );
    }

    return adapter.updateOne(
      {
        id: state.activeTeamMemberId,
        changes: {
          id: state.activeTeamMemberId,
          teamMemberInfo: null
        }
      },
      state
    );
  }),
  on(MyTeamActions.toggleTeamMemberPhotoIsRemoving, (state, action) => ({
    ...state,
    isTeamMemberPhotoRemoving: action.isRemoving
  })),
  on(MyTeamActions.teamMemberPhotoDeleted, (state, action) => {
    const member = state.entities[state.activeTeamMemberId];
    return adapter.updateOne(
      {
        id: state.activeTeamMemberId,
        changes: {
          id: state.activeTeamMemberId,
          teamMemberInfo: {
            ...member.teamMemberInfo,
            profilePhoto: null
          }
        }
      },
      state
    );
  }),
  on(UserActions.userLogout, (state, action) => ({
    ...initialState
  }))
);

export function reducer(
  state: TeamMemberDetailsState | undefined,
  action: Action
) {
  return teamMemberDetailsReducer(state, action);
}

export const selectFeature = createFeatureSelector<
  AppState,
  TeamMemberDetailsState
>(teamMemberDetailsFeatureKey);

export const selectActiveTeamMemberInfo = createSelector(
  selectFeature,
  state => {
    return state.entities[state.activeTeamMemberId]
      ? state.entities[state.activeTeamMemberId].teamMemberInfo
      : null;
  }
);

export const selectActiveTeamMemberCheckIns = createSelector(
  selectFeature,
  state => {
    return state.entities[state.activeTeamMemberId]
      ? state.entities[state.activeTeamMemberId].weeklyCheckInList
      : null;
  }
);

export const selectIsTeamMemberCheckInsLoading = createSelector(
  selectFeature,
  state => {
    return state.isWeeklyCheckInsLoading;
  }
);

export const selectActiveTeamMemberLastCheckIn = createSelector(
  selectFeature,
  state => {
    const checkIns =
      state.entities[state.activeTeamMemberId].weeklyCheckInList.weeklyCheckIns;
    if (checkIns.length === 0) {
      return {
        associateGuid: null,
        subDept: null,
        appearance: null,
        message: null,
        score: null,
        weekStartDate: null,
        weekEndingDate: null
      };
    }

    return _.first(_.orderBy(checkIns, ['weekEndingDate'], ['desc']));
  }
);

export const selectIsTeamMemberProductionLoading = createSelector(
  selectFeature,
  state => {
    return state.isProductionDetailsLoading;
  }
);

export const selectActiveTeamMemberProduction = createSelector(
  selectFeature,
  state => {
    return state.entities[state.activeTeamMemberId]
      ? state.entities[state.activeTeamMemberId].productionDetails
      : null;
  }
);

export const selectIsTeamMemberPhotoRemoving = createSelector(
  selectFeature,
  state => {
    return state.isTeamMemberPhotoRemoving;
  }
);
