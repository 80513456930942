import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {
  ButtonAppearance,
  ButtonSize,
  ButtonType,
  ButtonIconSize,
  ButtonColor
} from '../../enums/ButtonLink';
import { SvgIconColor, SvgIconName } from '../../enums/SvgIcon';
import { AnalyticsUtilitiesService } from '../../services/analytics-utilities.service';
import { AnalyticsActions } from '../../enums/AnalyticsActions';
import { AnalyticsCategories } from '../../enums/AnalyticsCategories';

@Component({
  selector: 'app-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss']
})
export class ButtonLinkComponent implements OnInit, OnChanges {
  @Input() appearance: ButtonAppearance = ButtonAppearance.Primary;
  @Input() type: ButtonType = ButtonType.Button;
  @Input() size: ButtonSize = ButtonSize.Default;
  @Input() color: ButtonColor = ButtonColor.Blue;
  @Input() fullWidth = false;
  @Input() isExternalUrl = false;
  @Input() link: string | string[];
  @Input() queryParams: any;
  @Input() label: string;
  @Input() labelTemplate: TemplateRef<any>;
  @Input() iconName: SvgIconName;
  @Input() iconColorPrimary: SvgIconColor;
  @Input() iconColorSecondary: SvgIconColor;
  @Input() iconSize = ButtonIconSize.Regular;
  @Input() disabled = false;
  @Input() ariaDescribedBy: string;
  @Input() isBack = false;
  @Output() buttonClick = new EventEmitter();

  @Input() analyticsId: string;
  @Input() analyticsLabel: any;

  ButtonAppearanceEnum = ButtonAppearance;
  ButtonSizeEnum = ButtonSize;
  ButtonIconSizeEnum = ButtonIconSize;
  ButtonColorEnum = ButtonColor;
  SvgIconNameEnum = SvgIconName;

  public appearanceClass: {};

  constructor(private analyticsUtilities: AnalyticsUtilitiesService) {}

  ngOnInit() {
    this.setAppearanceClass();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.size ||
      changes.fullWidth ||
      changes.appearance ||
      changes.disabled ||
      changes.color
    ) {
      this.setAppearanceClass();
    }
  }

  onClickButton(event) {
    this.buttonClick.emit(event);
    if (this.analyticsId) {
      this.analyticsUtilities.customAnalyticsEvent(this.analyticsId, {
        label: this.analyticsLabel,
        action: AnalyticsActions.Click,
        category: AnalyticsCategories.ButtonLink
      });
    }
  }

  setAppearanceClass() {
    this.appearanceClass = {
      button: true,
      'button--small': this.size === this.ButtonSizeEnum.Small,
      'button--fullwidth': this.fullWidth,
      'button--primary': this.appearance === this.ButtonAppearanceEnum.Primary,
      'button--secondary':
        this.appearance === this.ButtonAppearanceEnum.Secondary,
      'button--link': this.appearance === this.ButtonAppearanceEnum.Link,
      'button--blue': this.color === this.ButtonColorEnum.Blue,
      'button--red': this.color === this.ButtonColorEnum.Red,
      'button--green': this.color === this.ButtonColorEnum.Green,
      'button--disabled': this.disabled
    };
  }
}
