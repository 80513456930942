<app-page-header-container></app-page-header-container>

<main class="main-container" *ngIf="settingsFormGroup as settingsForm">
  <ng-template #settings i18n="@@settings">Settings</ng-template>
  <app-page-title
    data-cy="pageHeaderTitle"
    [titleTemplate]="settings"
    [appearance]="PageTitleAppearanceEnum.Transparent"
  ></app-page-title>

  <form
    class="settings"
    [formGroup]="settingsForm"
    appRootForm
    (ngSubmit)="onSubmit(settingsForm)"
  >
    <app-card-layout>
      <app-card-layout-item>
        <app-page-section-header
          i18n-title="@@settings_smsTextAlerts"
          title="SMS Text Alerts"
          i18n-subtitle="@@settings_smsAlertLabel"
          subtitle="Get instant updates and work opportunities. We'll never spam you share your number with anyone."
        ></app-page-section-header>

        <app-form-field>
          <app-label [hidden]="true" i18n="@@settings_phoneNumber">
            Phone number
          </app-label>
          <app-text-input
            formControlName="phoneNumber"
            placeholder="EG. (312) 444 5555"
            i18n-placeholder="@@shift-edit_phonePlaceholder"
            inputMask="(000) 000 0000"
            inputType="tel"
          ></app-text-input>

          <app-validation [errors]="settingsForm.get('phoneNumber').errors">
            <app-validation-message
              *ngIf="settingsForm.get('phoneNumber').errors?.minLength !== null"
              [id]="'input-phoneNumber'"
              i18n="@@settings_phoneNumberValidationMessage"
            >
              Provide valid contact number.
            </app-validation-message>
          </app-validation>
        </app-form-field>

        <app-form-field [narrow]="true">
          <app-checkbox-input
            formControlName="textOptIn"
            i18n="@@settings_textMeImportantAlerts"
          >
            Text me important alerts
          </app-checkbox-input>
        </app-form-field>
        <p class="settings__notice" i18n="@@settings_textOptInMessage">
          Without text alerts, you'll miss out on new work opportunities and
          important alerts.
        </p>
      </app-card-layout-item>
    </app-card-layout>

    <app-card-layout>
      <app-card-layout-item>
        <app-page-section-header
          i18n-title="@@settings_language"
          title="Language"
        ></app-page-section-header>
        <app-form-field [narrow]="true">
          <app-radio-group formControlName="language">
            <app-radio-input [value]="LocalesEnum.en">
              English
            </app-radio-input>
            <app-radio-input [value]="LocalesEnum.es">
              Español
            </app-radio-input>
          </app-radio-group>
        </app-form-field>
      </app-card-layout-item>
    </app-card-layout>
    <div class="settings__form-action">
      <app-button-link
        [type]="ButtonTypeEnum.Submit"
        i18n-label="@@settings_saveSettings"
        label="Save Settings"
      ></app-button-link>
    </div>
  </form>
</main>
