import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ContentDeliveryService } from '../../../../shared/services/content-delivery.service';
import { ShiftDetails } from '../../../../core/dataEntities/shifts/shiftDetails';
import { Observable } from 'rxjs';
import { ApiGatewayService } from '../../../../shared/services/api.gateway.service';
import { Store } from '@ngrx/store';
import { AppState, getAuthToken, getUserInfo } from '../../../../reducers';
import { first, map, switchMap } from 'rxjs/operators';
import { ShiftPostRequest } from '../../../../core/request/shift/shiftPostRequest';
import { SubDepartmentsResponse } from '../../../../core/responses/managers/subDepartmentsResponse';
import { ShiftContactTypes } from '../../../../core/enums/ShiftContactTypes';
import moment from 'moment';
import { Feed } from '../../../../core/dataEntities/feed/feed';
import { ShiftListItem } from '../../../../core/dataEntities/shifts/shiftListItem';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  readonly contentLocale: string;

  constructor(
    private contentDeliveryService: ContentDeliveryService,
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>,
    @Inject(LOCALE_ID) localeId: string
  ) {
    this.contentLocale = localeId;
  }

  getShift(id?: string): Observable<ShiftDetails> {
    if (id === null) {
      return this.store.select(getUserInfo).pipe(
        first(),
        map(userInfo => {
          return {
            id: null,
            manager: {
              id: userInfo.id,
              firstName: userInfo.name,
              title: userInfo.title
            },
            phoneNumber: null,
            contactType: ShiftContactTypes.Text,
            associatesNeeded: 1,
            siteId: null,
            startDateTime: null,
            duration: null,
            slug: ''
          } as ShiftDetails;
        })
      );
    }

    return this.contentDeliveryService.getPost(id).pipe(
      first(),
      map(post => {
        return post.shift;
      })
    );
  }

  getShiftCount(managerId?: string): Observable<number> {
    return this.contentDeliveryService.getShiftCount(managerId).pipe(
      first(),
      map(count => {
        return count;
      })
    );
  }

  postShift(shift: ShiftDetails): Observable<any> {
    const postData = this.mapShiftRequest(shift);

    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.post<any>(
          `comms/newsfeed/newsentry`,
          authToken,
          postData
        );
      })
    );
  }

  updateShift(shift: ShiftDetails): Observable<any> {
    const postData = this.mapShiftRequest(shift);

    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.put<any>(
          `comms/newsfeed/newsentry/${shift.id}`,
          authToken,
          postData
        );
      })
    );
  }

  getManagerSites(userId: string): Observable<SubDepartmentsResponse> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.get<SubDepartmentsResponse>(
          `managers/${userId}/SubDepts`,
          authToken
        );
      })
    );
  }

  getManagerShifts(managerId: string): Observable<ShiftListItem[]> {
    const query = {
      'fields.author.sys.contentType.sys.id': 'author',
      'fields.category.sys.contentType.sys.id': 'newsCategory',
      'fields.author.fields.authorId': managerId,
      'fields.category.fields.key': 'workOpportunity'
    };

    return this.contentDeliveryService.getNews(0, 50, query).pipe(
      map((feed: Feed) => {
        return feed.posts.map(post => {
          return {
            id: post.id,
            shiftDate: post.shift.startDateTime,
            createdAt: post.date
          } as ShiftListItem;
        });
      })
    );
  }

  removeShift(postId: string): Observable<any> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.delete<SubDepartmentsResponse>(
          `comms/newsfeed/entry/newsentry/${postId}/delete`,
          authToken
        );
      })
    );
  }

  generateDays(): any[] {
    const days = [];
    const startDateTime = moment();
    startDateTime.locale(this.contentLocale);
    for (let i = 0; i < 14; i++) {
      const day = startDateTime.clone().add(i, 'day');
      days.push({
        id: day.format('YYYY-MM-DD'),
        name: day.format('dddd MMM Do, YYYY')
      });
    }

    return days;
  }

  generateStartTime(startDate: string): any[] {
    const startTimes = [];
    const startDateTime = startDate
      ? moment(startDate).isSame(new Date(), 'day')
        ? this.nearestMinutes(30, moment())
        : moment(startDate)
      : this.nearestMinutes(30, moment().startOf('day'));
    const endDateTime = startDateTime.clone().endOf('day');

    while (startDateTime < endDateTime) {
      startTimes.push({
        id: startDateTime.format('HH:mm'),
        name: startDateTime.format('hh:mm A')
      });
      startDateTime.add(0.5, 'hours');
    }

    return startTimes;
  }

  generateEndTime(startDate: string, startTime: string): any[] {
    const endTimes = [];
    let durationInHours = 0;
    const startDateTime =
      startDate !== null && startTime !== null
        ? moment(`${startDate} ${startTime}`, 'YYYY-mm-DD HH:mm')
        : this.nearestMinutes(30, moment());

    const endDateTime = startDateTime.clone().add(1, 'day');

    while (startDateTime < endDateTime) {
      durationInHours = durationInHours + 0.5;
      startDateTime.add(0.5, 'hour');
      endTimes.push({
        id: durationInHours,
        name: `${this.nearestMinutes(15, startDateTime).format('hh:mm A')}`
      });
    }

    return endTimes;
  }

  nearestMinutes(interval, someMoment) {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment
      .clone()
      .minute(roundedMinutes)
      .second(0);
  }

  private mapShiftRequest(shift: ShiftDetails): ShiftPostRequest {
    return {
      // tslint:disable-next-line:max-line-length
      slug: `open-shift-for-site-${shift.siteId.toString()}-${moment(
        shift.startDateTime
      ).format('YYYY-MM-DD')}`,
      authorId: shift.manager.id,
      sites: [shift.siteId.toString()],
      startDateTime: moment(shift.startDateTime)
        .utc()
        .toISOString(),
      duration: shift.duration,
      endDate: moment(shift.startDateTime)
        .add(shift.duration)
        .format('YYYY-MM-DD'),
      phoneNumber: shift.phoneNumber,
      numberOfAssociates: shift.associatesNeeded,
      authorRole: shift.authorRole,
      reachBy:
        shift.contactType === ShiftContactTypes.Both
          ? ['Text', 'Call']
          : shift.contactType === ShiftContactTypes.Call
          ? ['Call']
          : ['Text']
    } as ShiftPostRequest;
  }
}
