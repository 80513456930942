/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/a11y";
import * as i3 from "@angular/common";
import * as i4 from "../../svg-icon/svg-icon.component.ngfactory";
import * as i5 from "../../svg-icon/svg-icon.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./modal-layout.component";
var styles_ModalLayoutComponent = [i0.styles];
var RenderType_ModalLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalLayoutComponent, data: {} });
export { RenderType_ModalLayoutComponent as RenderType_ModalLayoutComponent };
export function View_ModalLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["cdkTrapFocus", ""], ["class", "modal-layout"], ["data-cy", "modal"]], null, null, null, null, null)), i1.ɵdid(1, 1458176, null, 0, i2.CdkTrapFocus, [i1.ElementRef, i2.FocusTrapFactory, i3.DOCUMENT], { enabled: [0, "enabled"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["class", "modal-close-button"], ["data-cy", "closeModalButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close modal"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-svg-icon", [["class", "modal-close-button__icon"]], null, null, null, i4.View_SvgIconComponent_0, i4.RenderType_SvgIconComponent)), i1.ɵdid(7, 638976, null, 0, i5.SvgIconComponent, [i6.DomSanitizer], { name: [0, "name"] }, null), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.SvgIconNameEnum.Close; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_ModalLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-layout", [], null, null, null, View_ModalLayoutComponent_0, RenderType_ModalLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i7.ModalLayoutComponent, [], null, null)], null, null); }
var ModalLayoutComponentNgFactory = i1.ɵccf("app-modal-layout", i7.ModalLayoutComponent, View_ModalLayoutComponent_Host_0, {}, { closeModal: "closeModal" }, ["[slot='modal-header']", "[slot='modal-content']"]);
export { ModalLayoutComponentNgFactory as ModalLayoutComponentNgFactory };
