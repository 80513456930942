import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ShiftEditComponent } from './containers/shift-edit/shift-edit.component';
import { ShiftsComponent } from './shifts.component';
import { AuthenticationGuard } from '../../../core/guards/authenticationGuard/authentication.guard';
import { ShiftPreviewComponent } from './containers/shift-preview/shift-preview.component';
import { ShiftDetailsComponent } from './containers/shift-details/shift-details.component';
import { ShiftRequestsComponent } from './containers/shift-requests/shift-requests.component';
import { TranslationMessages } from '../../../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../../../core/enums/SiteMapItemKeys';

const routes: Routes = [
  {
    path: 'shifts',
    component: ShiftsComponent,
    canActivate: [AuthenticationGuard],
    data: {
      module: 'shifts'
    },
    children: [
      {
        path: '',
        component: ShiftRequestsComponent,
        data: {
          showMenuBar: true,
          pageTitle: TranslationMessages.ShiftsTitle,
          siteMapKey: SiteMapItemKeys.ShiftRequests
        }
      },
      {
        path: 'create',
        component: ShiftEditComponent,
        data: {
          showMenuBar: false,
          hideNavigationBar: true,
          pageTitle: TranslationMessages.ShiftCreateTitle,
          siteMapKey: SiteMapItemKeys.ShiftCreate
        }
      },
      {
        path: 'edit/:id',
        component: ShiftEditComponent,
        data: {
          showMenuBar: false,
          hideNavigationBar: true,
          pageTitle: TranslationMessages.ShiftEditTitle,
          siteMapKey: SiteMapItemKeys.ShiftEdit
        }
      },
      {
        path: 'preview',
        component: ShiftPreviewComponent,
        data: {
          showMenuBar: false,
          hideNavigationBar: true,
          pageTitle: TranslationMessages.ShiftPreviewTitle,
          siteMapKey: SiteMapItemKeys.ShiftPreview
        }
      },
      {
        path: 'details/:id',
        component: ShiftDetailsComponent,
        data: {
          showMenuBar: false,
          pageTitle: TranslationMessages.ShiftDetailsTitle,
          siteMapKey: SiteMapItemKeys.ShiftDetails
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShiftsRoutingModule {}
