import { Component, Inject, AfterViewInit } from '@angular/core';
import { PageTitleAppearance } from '../../../../../shared/enums/PageTitle';
import { PageHeaderAppearance } from '../../../../../shared/enums/PageHeader';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import { DialogRef } from '../../../../../shared/modules/modal/dialog-ref';
import { DIALOG_DATA } from '../../../../../shared/modules/modal/dialog-config';
import { ButtonAppearance } from '../../../../../shared/enums/ButtonLink';
import { CameraType } from '../../../../../shared/enums/CameraType';
import { File } from '../../../../../core/dataEntities/shared/file';
import { DocumentType } from '../../enums/DocumentType';
import { UploadPreviewAppearance } from '../../enums/UploadPreview';

@Component({
  selector: 'app-document-copy',
  templateUrl: './document-copy.component.html',
  styleUrls: ['./document-copy.component.scss']
})
export class DocumentCopyComponent implements AfterViewInit {
  PageTitleAppearanceEnum = PageTitleAppearance;
  PageHeaderAppearanceEnum = PageHeaderAppearance;
  SvgIconNameEnum = SvgIconName;
  ButtonAppearanceEnum = ButtonAppearance;
  UploadPreviewAppearanceEnum = UploadPreviewAppearance;
  CameraType = CameraType;

  public documents: File[];
  public documentsType: DocumentType;
  public DocumentTypeEnum = DocumentType;
  public hasInsurance: boolean;
  public saveDisabled = true;
  constructor(
    private modalRef: DialogRef<DocumentCopyComponent>,
    @Inject(DIALOG_DATA) data: any
  ) {
    this.documents = data.documents ? data.documents : [];
    this.documentsType = data.documentsType;
    this.hasInsurance = data.hasInsurance;
  }
  ngAfterViewInit() {}

  onDriverLicenseUpload(event) {
    this.documents[0] = event[0];
    if (!this.hasInsurance) {
      this.saveDisabled = false;
    } else {
      if (this.documents[0] !== undefined && this.documents[1] !== undefined) {
        this.saveDisabled = false;
      }
    }
  }

  onInsuranceLicenseUpload(event) {
    this.documents[1] = event[0];
    if (this.documents[0] !== undefined && this.documents[1] !== undefined) {
      this.saveDisabled = false;
    }
  }

  onIDUpload(event) {
    this.documents[0] = event[0];
    this.saveDisabled = false;
  }

  save() {
    if (!this.documents[0]) {
      this.modalRef.close({ success: false });
    } else if (
      this.documentsType === DocumentType.ID ||
      this.documents[1] != null
    ) {
      this.modalRef.close({ success: true, data: this.documents });
    } else if (
      this.documentsType === DocumentType.License &&
      !this.hasInsurance &&
      this.documents[1] == null
    ) {
      this.modalRef.close({ success: true, data: this.documents });
    } else {
      this.modalRef.close({ success: false, data: this.documents });
    }
  }

  closeModal() {
    this.documents = this.documents.map(document => {
      return {
        ...document,
        data: null
      };
    });
    this.modalRef.close({ success: false });
  }
}
