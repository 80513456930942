import { Component, Input } from '@angular/core';
import { SvgIconName } from '../../enums/SvgIcon';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent {
  @Input() iconName: SvgIconName = SvgIconName.Info;

  constructor() {}
}
