import { Component, Input, OnInit } from '@angular/core';
import { JobPosition } from '../../../../../core/responses/opportunities/opportunityDetailsResponse';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-open-positions',
  templateUrl: './open-positions.component.html',
  styleUrls: ['./open-positions.component.scss']
})
export class OpenPositionsComponent implements OnInit {
  @Input() openPositions: JobPosition[];
  @Input() userCertificates: string[];

  public SvgIconNameEnum = SvgIconName;

  constructor() {}

  ngOnInit() {}
}
