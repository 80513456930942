import { Component, Input, OnInit } from '@angular/core';
import { TrainingStatus } from '../../../enums/TrainingStatus';

@Component({
  selector: 'app-weekly-training',
  templateUrl: './weekly-training.component.html',
  styleUrls: ['./weekly-training.component.scss']
})
export class WeeklyTrainingComponent implements OnInit {
  @Input() trainingStatus: TrainingStatus;
  @Input() marginBottom = false;

  public TrainingStatusEnum = TrainingStatus;

  constructor() {}

  ngOnInit() {}
}
