import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { StatsAppearance } from '../../../enums/Stats';
import { ChipAppearance } from '../../../enums/Chip';

@Component({
  selector: 'app-stats-item',
  templateUrl: './stats-item.component.html',
  styleUrls: ['./stats-item.component.scss']
})
export class StatsItemComponent implements OnInit {
  @Input() value: number;
  @Input() numberFormat = '0.0-2';
  @Input() valuePrefix: string;
  @Input() valueSuffix: string;
  @Input() coloredSuffixPrefix = false;
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() chipTitle: string;
  @Input() appearance: StatsAppearance;
  @Input() chipAppearance: ChipAppearance;

  public StatsAppearanceEnum = StatsAppearance;

  constructor() {}

  ngOnInit() {}
}
