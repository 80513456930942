import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SurveySummaryResponse } from '../../../core/responses/surveys/surveySummaryResponse';
import { SurveyResponse } from '../../../core/responses/surveys/surveyResponse';
import { SubmitSurveyRequest } from '../../../core/request/survey/submitSurveyRequest';
import { ApiGatewayService } from '../../../shared/services/api.gateway.service';
import { SurveyStatusResponse } from '../../../core/responses/surveys/surveyStatusResponse';
import { Surveys } from '../../../shared/enums/ApiResponseCodes';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { AppState } from '../../../reducers';
import { first, map, switchMap } from 'rxjs/operators';
import moment from 'moment';
import { ContentDeliveryService } from '../../../shared/services/content-delivery.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  private surveysRemaindersKey = 'surveysRemainders';
  private remainderFrequencyInHours = 24;

  constructor(
    private apiGateway: ApiGatewayService,
    private contentDeliveryService: ContentDeliveryService,
    private store: Store<AppState>
  ) {}

  getAvailableSurveys(userId: string): Observable<SurveySummaryResponse[]> {
    return this.store.select(fromRoot.getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.get<{
          currentSurveys: SurveySummaryResponse[];
        }>(`/comms/surveys/contentful/current/${userId}`, authToken);
      }),
      map(response => {
        const surveys = response.currentSurveys || [];
        return surveys.map((s: any) => {
          return {
            id: s.contentfulId,
            title: s.title,
            subtitle: s.subtitle
          };
        });
      })
    );
  }

  getSurvey(id: string): Observable<SurveyResponse> {
    return this.contentDeliveryService.getSurvey(id);
  }

  getUnsubmittedSurveys(userId: string): Observable<any> {
    return this.store.select(fromRoot.getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .get<any>(`comms/surveys/contentful/current/${userId}`, authToken)
          .pipe(
            map(response => {
              return (
                response &&
                response.currentSurveys &&
                !!response.currentSurveys.length
              );
            })
          );
      })
    );
  }

  submitSurvey(survey: SubmitSurveyRequest): Observable<any> {
    return this.store.select(fromRoot.getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .post<SurveyStatusResponse>(
            `comms/surveys/contentful/submission`,
            authToken,
            survey
          )
          .map(response => {
            return {
              success:
                response.message === Surveys.SURVEY_SUBMITTED_SUCCESSFULLY
            };
          });
      })
    );
  }

  showSurveyRemainder(userId: string, surveyId: string): boolean {
    let remainders = this.getSurveysRemaindersFromLocalStorage();
    const now = moment.utc();

    const lastRemainder = remainders.find(
      user => user.userId === userId && user.surveyId === surveyId
    );

    if (lastRemainder == null) {
      remainders.push({
        userId,
        surveyId,
        lastNotifyDate: now.toISOString()
      });

      localStorage.setItem(
        this.surveysRemaindersKey,
        JSON.stringify(remainders)
      );

      return true;
    }

    const durationDiff = moment
      .duration(now.diff(moment(lastRemainder.lastNotifyDate)))
      .asHours();

    if (durationDiff > this.remainderFrequencyInHours) {
      remainders = remainders.map(user =>
        user.userId === userId && user.surveyId === surveyId
          ? { ...user, lastNotifyDate: now.toISOString() }
          : user
      );

      localStorage.setItem(
        this.surveysRemaindersKey,
        JSON.stringify(remainders)
      );

      return true;
    }

    return false;
  }

  getSurveyRemainderInfo(surveyId: number) {
    if (surveyId === 1) {
      return {
        surveyId: '1',
        remainderHeader: '2019 Associate Survey',
        remainderText:
          'Your input is important. Please take this quick survey and provide your feedback.'
      };
    }
  }

  private getSurveysRemaindersFromLocalStorage() {
    const surveys = localStorage.getItem(this.surveysRemaindersKey);

    if (surveys == null) {
      return [];
    }

    return JSON.parse(localStorage.getItem(this.surveysRemaindersKey)) as Array<
      SurveyRemainder
    >;
  }
}

class SurveyRemainder {
  userId: string;
  surveyId: string;
  lastNotifyDate: string;
}
