import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Optional,
  Inject,
  OnDestroy
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { FormFieldControl } from '../../form-field/form-field.control';
import { RadioInputComponent } from './radio-input/radio-input.component';
import {
  FORM_FIELD,
  FormFieldComponent
} from '../../form-field/form-field.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RadioGroupDirection } from '../../../../enums/RadioGroup';
import { RatingRadioInputComponent } from '../../../../../profile/components/rating-radio-input/rating-radio-input.component';

let nextUniqueId = 0;

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RadioGroupComponent)
    },
    { provide: FormFieldControl, useExisting: RadioGroupComponent }
  ]
})
export class RadioGroupComponent
  implements OnInit, OnDestroy, ControlValueAccessor, FormFieldControl {
  public isTouched = false;

  @Input() direction = RadioGroupDirection.Column;
  @Input() value: any;

  @Input()
  get selected() {
    return this.value;
  }

  formControl: FormControl;
  onDestroySubject = new Subject();
  protected defaultId = `radio-group-${++nextUniqueId}`;
  protected componentId: string;
  public RadioGroupDirectionEnum = RadioGroupDirection;

  constructor(
    fb: FormBuilder,
    @Optional() @Inject(FORM_FIELD) private formField: FormFieldComponent
  ) {
    this.formControl = fb.control(null);
    this.id = this.id;
  }

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(value => {
        this.propagateChange(value);
      });
  }

  @Input()
  get id(): string {
    return this.componentId;
  }

  set id(value: string) {
    this.componentId = value || this.defaultId;
  }

  get errorId(): string {
    return `${this.id}-error`;
  }

  public get control() {
    return this.formControl;
  }

  setSelected(
    selected: RadioInputComponent | RatingRadioInputComponent | null
  ) {
    this.value = selected ? selected.value : null;
    selected.checked = true;
  }

  setTouched() {
    this.isTouched = true;
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  ngOnDestroy() {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  get isInvalid() {
    if (!this.formField) {
      return false;
    }

    return this.formField.isInvalid();
  }

  registerOnTouched(fn: any): void {}

  propagateChange = (value: any) => {};
}
