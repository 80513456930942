import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalLayoutComponent } from '../modal/modal-layout/modal-layout.component';
import { ButtonLinkComponent } from '../button-link/button-link.component';
import { EmptyStateComponent } from '../empty-state/empty-state.component';
import { LoaderComponent } from '../loader/loader.component';
import {
  AccordionComponent,
  AccordionHeaderPrimaryComponent,
  AccordionHeaderRatingComponent,
  AccordionPanelComponent
} from '../accordion';
import { CdkTableModule } from '@angular/cdk/table';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { SvgDefsComponent } from '../svg-defs/svg-defs.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CardLayoutComponent,
  CardLayoutItemComponent,
  CardLayoutTimelineComponent
} from '../card-layout';
import { RouterModule } from '@angular/router';
import { NoInternetComponent } from '../no-internet/no-internet.component';
import { FooterComponent } from '../footer/footer.component';
import { FooterNoteComponent } from '../footer/footer-note/footer-note.component';
import { GoalGaugeComponent } from '../goal-gauge/goal-gauge.component';
import { ModalHeaderComponent } from '../modal/modal-header/modal-header.component';
import { GoalGaugeService } from '../goal-gauge/goal-gauge.service';
import { PageTitleComponent } from '../page-title/page-title.component';
import { PageSubtitleComponent } from '../page-title/page-subtitle/page-subtitle.component';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { DescListComponent } from '../modal/desc-list/desc-list.component';
import { DescListItemComponent } from '../modal/desc-list/desc-list-item/desc-list-item.component';
import { ModalContentRowComponent } from '../modal/modal-content-row/modal-content-row.component';
import {
  MenuButtonComponent,
  MenuComponent,
  MenuHeaderComponent,
  MenuItemComponent,
  MenuItemImageComponent
} from '../menu';
import { BlogPostComponent } from '../feed/blog-post/blog-post.component';
import { NewsFeedCardComponent } from '../feed/news-feed-card/news-feed-card.component';
import { PostCategoryComponent } from '../feed/post-category/post-category.component';
import { PostImageComponent } from '../feed/post-image/post-image.component';
import { PostProfileComponent } from '../feed/post-profile/post-profile.component';
import { NoticeComponent } from '../notice/notice.component';
import { ShiftRequestCardComponent } from '../feed/shift-request-card/shift-request-card.component';
import { FeedCardLayoutComponent } from '../feed/feed-card-layout/feed-card-layout.component';
import { TextInputComponent } from '../form/text-input/text-input.component';
import { NgxMaskModule } from 'ngx-mask';
import { ChipComponent } from '../chip/chip.component';
import { PageHeaderContainerComponent } from '../page-header/page-header-container.component';
import { ValidationComponent } from '../form/validation/validation.component';
import { ValidationMessageComponent } from '../form/validation/validation-message/validation-message.component';
import { LabelComponent } from '../form/label/label.component';
import { CheckboxInputComponent } from '../form/checkbox-input/checkbox-input.component';
import { SelectComponent } from '../form/select/select.component';
import { FormFieldComponent } from '../form/form-field/form-field.component';
import { AlertComponent } from '../alert/alert.component';
import { RadioGroupComponent } from '../form/radio/radio-group/radio-group.component';
import { RadioInputComponent } from '../form/radio/radio-group/radio-input/radio-input.component';
import { TextAreaComponent } from '../form/text-area/text-area.component';
import { PageSectionHeaderComponent } from '../page-section-header/page-section-header.component';
import { StatsComponent, StatsItemComponent } from '../stats';
import { CheckInHistoryComponent } from '../profile/check-in-history/check-in-history.component';
import { AssociatePageHeaderComponent } from '../profile/associate-page-header/associate-page-header.component';
import { AssociateQrCodeComponent } from '../profile/associate-qr-code/associate-qr-code.component';
import { TabBarComponent, TabBarLinkComponent } from '../tab-bar';
import { ProductionDetailsComponent } from '../profile/production-details/associate/production-details.component';
import { AssociateStatsLevelAppearancePipe } from '../profile/production-details/pipes/associate-stats-level-appearance.pipe';
import { AssociateStatsAppearancePipe } from '../profile/production-details/pipes/associate-stats-appearance.pipe';
import { AssociateStatsLevelTitlePipe } from '../profile/production-details/pipes/associate-stats-level-title.pipe';
import { SimpleSelectComponent } from '../profile/simple-select/simple-select.component';
import { AboutWeeklyObservationsComponent } from '../profile/about-weekly-observations/about-weekly-observations.component';
import { WeeklyTrainingComponent } from '../profile/weekly-training/weekly-training.component';
import { CheckListComponent, CheckListItemComponent } from '../check-list';
import { TraineeProductionDetailsComponent } from '../profile/production-details/trainee/trainee-production-details.component';
import { ProductionDetailsObservationsComponent } from '../profile/production-details/observations/production-details-observations.component';
import { ProductionDetailsLayoutComponent } from '../profile/production-details/layout/production-details-layout.component';
import { TraineeWorkWeekPipe } from '../profile/production-details/pipes/trainee-work-week.pipe';
import { AssociatePhotoComponent } from '../associate-photo/associate-photo.component';
import { RootFormDirective } from '../form/root-form/root-form.directive';
import { DatePickerComponent } from '../form/date-picker/date-picker.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignatureComponent } from '../form/signature/signature.component';
import { NestedCheckboxListComponent } from '../form/nested-checkbox-list/nested-checkbox-list.component';
import { FeedSeparatorComponent } from '../feed/feed-separator/feed-separator.component';
import { LoaderWrapperComponent } from '../loader-wrapper/loader-wrapper.component';
import { IconTextComponent } from '../icon-text/icon-text.component';
import { AutocompleteComponent } from '../form/autocomplete/autocomplete.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { PaginatorComponent } from '../paginator/paginator.component';
import { SortSearchComponent } from '../sort-search/sort-search.component';
import { TabsModule } from '../tabs/tabs.module';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { IndicatorComponent } from '../indicator/indicator.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { RoadCrewApplicationApiService } from 'src/app/wages/modules/work/services/road-crew-application.api.service';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    ModalLayoutComponent,
    ButtonLinkComponent,
    EmptyStateComponent,
    LoaderComponent,
    AccordionComponent,
    AccordionPanelComponent,
    AccordionHeaderRatingComponent,
    AccordionHeaderPrimaryComponent,
    SvgIconComponent,
    SvgDefsComponent,
    ProgressBarComponent,
    CardLayoutComponent,
    CardLayoutItemComponent,
    CardLayoutTimelineComponent,
    NoInternetComponent,
    FooterComponent,
    FooterNoteComponent,
    GoalGaugeComponent,
    ModalHeaderComponent,
    PageTitleComponent,
    PageSubtitleComponent,
    PageHeaderComponent,
    PageHeaderContainerComponent,
    DescListComponent,
    DescListItemComponent,
    ModalContentRowComponent,
    MenuComponent,
    MenuItemComponent,
    MenuItemImageComponent,
    MenuHeaderComponent,
    MenuButtonComponent,
    BlogPostComponent,
    NewsFeedCardComponent,
    PostCategoryComponent,
    PostImageComponent,
    PostProfileComponent,
    NoticeComponent,
    ShiftRequestCardComponent,
    FeedCardLayoutComponent,
    TextInputComponent,
    ValidationComponent,
    ValidationMessageComponent,
    ChipComponent,
    AlertComponent,
    FormFieldComponent,
    ChipComponent,
    LabelComponent,
    CheckboxInputComponent,
    SelectComponent,
    RadioGroupComponent,
    RadioInputComponent,
    TextAreaComponent,
    PageSectionHeaderComponent,
    StatsComponent,
    StatsItemComponent,
    CheckInHistoryComponent,
    AssociateQrCodeComponent,
    AssociatePageHeaderComponent,
    TabBarComponent,
    TabBarLinkComponent,
    ProductionDetailsComponent,
    ProductionDetailsLayoutComponent,
    TraineeWorkWeekPipe,
    TraineeProductionDetailsComponent,
    ProductionDetailsObservationsComponent,
    AssociateStatsLevelAppearancePipe,
    AssociateStatsAppearancePipe,
    AssociateStatsLevelTitlePipe,
    SimpleSelectComponent,
    AboutWeeklyObservationsComponent,
    WeeklyTrainingComponent,
    CheckListComponent,
    CheckListItemComponent,
    AssociatePhotoComponent,
    CheckListItemComponent,
    RootFormDirective,
    LoaderWrapperComponent,
    DatePickerComponent,
    SignatureComponent,
    NestedCheckboxListComponent,
    FeedSeparatorComponent,
    IconTextComponent,
    AutocompleteComponent,
    PaginatorComponent,
    SortSearchComponent,
    FileUploaderComponent,
    IndicatorComponent
  ],
  entryComponents: [
    ModalLayoutComponent,
    AboutWeeklyObservationsComponent,
    LoaderWrapperComponent
  ],
  providers: [GoalGaugeService, RoadCrewApplicationApiService],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    OverlayModule,
    A11yModule,
    CdkTableModule,
    RouterModule,
    NgxMaskModule,
    SignaturePadModule,
    AutocompleteLibModule,
    TabsModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  exports: [
    CommonModule,
    OverlayModule,
    ModalLayoutComponent,
    ButtonLinkComponent,
    EmptyStateComponent,
    AccordionComponent,
    AccordionPanelComponent,
    AccordionHeaderRatingComponent,
    AccordionHeaderPrimaryComponent,
    SvgIconComponent,
    SvgDefsComponent,
    LoaderComponent,
    CdkTableModule,
    ProgressBarComponent,
    CardLayoutComponent,
    CardLayoutItemComponent,
    CardLayoutTimelineComponent,
    NoInternetComponent,
    FooterComponent,
    FooterNoteComponent,
    GoalGaugeComponent,
    ModalHeaderComponent,
    PageTitleComponent,
    PageSubtitleComponent,
    PageHeaderComponent,
    PageHeaderContainerComponent,
    DescListComponent,
    DescListItemComponent,
    ModalContentRowComponent,
    MenuComponent,
    MenuItemComponent,
    MenuItemImageComponent,
    MenuHeaderComponent,
    MenuButtonComponent,
    BlogPostComponent,
    NewsFeedCardComponent,
    PostCategoryComponent,
    PostImageComponent,
    PostProfileComponent,
    NoticeComponent,
    ShiftRequestCardComponent,
    FeedCardLayoutComponent,
    TextInputComponent,
    ValidationComponent,
    ValidationMessageComponent,
    ChipComponent,
    AlertComponent,
    ChipComponent,
    FormFieldComponent,
    LabelComponent,
    CheckboxInputComponent,
    SelectComponent,
    RadioGroupComponent,
    RadioInputComponent,
    TextAreaComponent,
    PageSectionHeaderComponent,
    StatsComponent,
    StatsItemComponent,
    CheckInHistoryComponent,
    AssociateQrCodeComponent,
    AssociatePageHeaderComponent,
    ProductionDetailsComponent,
    TraineeProductionDetailsComponent,
    ProductionDetailsObservationsComponent,
    AssociateStatsLevelAppearancePipe,
    AssociateStatsAppearancePipe,
    AssociateStatsLevelTitlePipe,
    TabBarComponent,
    TabBarLinkComponent,
    SimpleSelectComponent,
    WeeklyTrainingComponent,
    CheckListComponent,
    CheckListItemComponent,
    TraineeWorkWeekPipe,
    AssociatePhotoComponent,
    TraineeWorkWeekPipe,
    RootFormDirective,
    DatePickerComponent,
    SignatureComponent,
    NestedCheckboxListComponent,
    FeedSeparatorComponent,
    IconTextComponent,
    AutocompleteComponent,
    PaginatorComponent,
    SortSearchComponent,
    FileUploaderComponent,
    IndicatorComponent
  ]
})
export class SharedComponentsModule {}
