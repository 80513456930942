import { BaseModalService } from '../base-modal/base-modal.service';
import {
  Inject,
  Injectable,
  Injector,
  Optional,
  TemplateRef
} from '@angular/core';
import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { DIALOG_DEFAULT_OPTIONS, DialogConfig } from '../dialog-config';
import { DialogRef } from '../dialog-ref';

@Injectable()
export class DialogService extends BaseModalService {
  constructor(
    overlay: Overlay,
    injector: Injector,
    @Optional() location: Location,
    @Optional() @Inject(DIALOG_DEFAULT_OPTIONS) private config: DialogConfig
  ) {
    super(overlay, injector, location, config);
  }

  open<T, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: DialogConfig
  ): DialogRef<T, R> {
    config = this.applyConfigDefaults(
      config,
      this.config || new DialogConfig()
    );

    const overlayRef = this.createOverlay(config);
    const dialogContainer = this.attachDialogContainer(
      overlayRef,
      DialogComponent,
      config
    );

    const dialogRef = this.attachDialogContent<T, R>(
      componentOrTemplateRef,
      dialogContainer.instance,
      overlayRef,
      config
    );

    return dialogRef;
  }
}
