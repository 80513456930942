<div
  data-cy="notificationBar"
  [ngClass]="{
    'notification-bar': true,
    'notification-bar--show-over': showOver()
  }"
>
  <div
    *ngIf="noActionNotification"
    @enterLeaveTrigger
    role="status"
    class="notification-bar__container notification-bar__container--noaction"
  >
    <p class="notification-bar__title">
      {{ noActionNotification.title }}
      <span
        *ngIf="noActionNotification.subtitle"
        class="notification-bar__subtitle"
      >
        {{ noActionNotification.subtitle }}
      </span>
    </p>
  </div>
  <div
    *ngIf="errorNotification"
    role="alert"
    class="notification-bar__container notification-bar__container--error"
    @enterLeaveTrigger
  >
    <p class="notification-bar__title">
      {{ errorNotification.title }}
      <span
        *ngIf="errorNotification.subtitle"
        class="notification-bar__subtitle"
      >
        {{ errorNotification.subtitle }}
      </span>
    </p>
    <button class="notification-bar__button" (click)="onDismissError()">
      <app-svg-icon
        [name]="SvgIconNameEnum.Close"
        class="notification-bar__icon"
      ></app-svg-icon>
      <span class="visually-hidden" i18n="@@dismiss">Dismiss</span>
    </button>
  </div>
  <div
    *ngIf="infoNotification"
    role="status"
    class="notification-bar__container notification-bar__container--info"
    @enterLeaveTrigger
  >
    <p class="notification-bar__title">
      {{ infoNotification.title }}
      <span
        *ngIf="infoNotification.subtitle"
        class="notification-bar__subtitle"
      >
        {{ infoNotification.subtitle }}
      </span>
    </p>
    <button class="notification-bar__button" (click)="onDismissInfo()">
      <app-svg-icon
        [name]="SvgIconNameEnum.Close"
        class="notification-bar__icon"
      ></app-svg-icon>
      <span class="visually-hidden" i18n="@@dismiss">Dismiss</span>
    </button>
  </div>
  <div
    *ngIf="successNotification"
    @enterLeaveTrigger
    role="status"
    class="notification-bar__container notification-bar__container--success"
  >
    <p class="notification-bar__title">
      {{ successNotification.title }}
      <span
        *ngIf="successNotification.subtitle"
        class="notification-bar__subtitle"
      >
        {{ successNotification.subtitle }}
      </span>
    </p>
    <button class="notification-bar__button" (click)="onDismissSuccess()">
      <app-svg-icon
        [name]="SvgIconNameEnum.Close"
        class="notification-bar__icon"
      ></app-svg-icon>
      <span class="visually-hidden" i18n="@@dismiss">Dismiss</span>
    </button>
  </div>
</div>
