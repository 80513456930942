<address>
  <div class="contact">
    <app-svg-icon
      [name]="SvgIconEnum.Location"
      class="contact__icon"
    ></app-svg-icon>
    <span *ngIf="contact.officeLocation" class="contact__text">
      {{ contact.officeLocation }}
    </span>
    <span
      *ngIf="!contact.officeLocation"
      class="contact__text"
      i18n="@@contact-info_officeLocationUnavailable"
    >
      Office location unavailable
    </span>
  </div>
  <div class="contact">
    <app-svg-icon
      [name]="SvgIconEnum.Email"
      class="contact__icon"
    ></app-svg-icon>
    <a
      *ngIf="contact.email"
      class="contact__link"
      href="mailto:{{ contact.email }}"
    >
      {{ contact.email }}
    </a>
    <span
      *ngIf="!contact.email"
      class="contact__text"
      i18n="@@contact-info_emailUnavailable"
    >
      Email address unavailable
    </span>
  </div>
  <div
    *ngIf="contact.hierarchyTitle === SiteHierarchyEnum.SiteManager"
    class="contact"
  >
    <app-svg-icon
      [name]="SvgIconEnum.Phone"
      class="contact__icon"
    ></app-svg-icon>
    <a
      *ngIf="contact.officeNumber"
      class="contact__link"
      href="tel:{{ contact.officeNumber }}"
    >
      {{ contact.officeNumber | mask: '(000) 000 - 0000' }}
    </a>
    <span
      *ngIf="!contact.officeNumber"
      class="contact__text"
      i18n="@@contact-info_phoneUnavailable"
    >
      Phone number unavailable
    </span>
  </div>
</address>
