import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, getAuthToken } from '../../reducers';
import { first, map, switchMap } from 'rxjs/operators';
import { ApiGatewayService } from './api.gateway.service';
import moment from 'moment';
import {
  WeeklyCheckinListItemResponse,
  WeeklyCheckinListResponse
} from '../../core/responses/profile/weeklyCheckinListResponse';
import { StatsAppearance } from '../enums/Stats';
import { WeeklyCheckinListItem } from '../../core/dataEntities/profile/weeklyCheckinList';
import { DateTimeUtils } from '../utils/DateTimeUtils';
import { DateFormat } from '../constants';
import { StatsLevel } from '../enums/StatsLevel';
import { AssociateProductionDetails } from '../../core/dataEntities/associate/associateProductionDetails';
import { AssociateProductionResponse } from '../../core/responses/managers/associateProductionResponse';
import { TrainingStatus } from '../enums/TrainingStatus';
import { MathHelpers } from '../helpers/math.helpers';
import { Settings } from '../../core/constants/settings';

@Injectable({
  providedIn: 'root'
})
export class AssociatesService {
  private traineeGoalPercentageByWeek = {
    [TrainingStatus.WeekOneTraining]: 60,
    [TrainingStatus.WeekTwoTraining]: 70,
    [TrainingStatus.WeekThreeTraining]: 80,
    [TrainingStatus.WeekFourTraining]: 85,
    [TrainingStatus.WeekFiveTraining]: 90,
    [TrainingStatus.WeekSixTraining]: 95
  };
  private associatePhoneReminderKey = 'associatePhoneReminder';

  constructor(
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>
  ) {}

  getAssociateWeeklyCheckinList(
    userId: string
  ): Observable<WeeklyCheckinListResponse> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        if (!userId) {
          throw new Error('User not found.');
        }

        return this.apiGateway.get<WeeklyCheckinListResponse>(
          `associates/${userId}/weeklyCheckIns`,
          authToken
        );
      })
    );
  }

  shouldShowMissingPhoneReminder(userId: string): boolean {
    let remainders = this.getPhoneRemaindersFromLocalStorage();
    const now = moment.utc();

    const lastRemainder = remainders.find(user => user.userId === userId);

    if (lastRemainder == null) {
      remainders.push({
        userId,
        lastNotifyDate: now.toISOString()
      });

      localStorage.setItem(
        this.associatePhoneReminderKey,
        JSON.stringify(remainders)
      );

      return true;
    }

    if (!moment(lastRemainder.lastNotifyDate).isSame(new Date(), 'week')) {
      remainders = remainders.map(user =>
        user.userId === userId
          ? { ...user, lastNotifyDate: now.toISOString() }
          : user
      );

      localStorage.setItem(
        this.associatePhoneReminderKey,
        JSON.stringify(remainders)
      );

      return true;
    }

    return false;
  }

  getAssociateProduction(
    userId: string
  ): Observable<AssociateProductionDetails> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .get<AssociateProductionResponse>(
            `managers/team/associate/${userId}`,
            authToken
          )
          .pipe(
            map((production: AssociateProductionResponse) => {
              const isTrainee = this.isAssociateTrainee(
                production.teamMember.tenure,
                production.teamMember.trainingStatus
              );
              const productivityAverage = isTrainee
                ? null
                : this.getProductivityAverage(production.productivitySummary);

              const currentWeekProductivity = this.getCurrentWeekProductivity(
                isTrainee,
                production
              );

              return {
                productivityAverage,
                siteProductivityAverage:
                  production.productivitySummary.siteProductivityAverage,
                currentWeekProductivity,
                violationCount: production.safetyObservationViolationsCount,
                safetyObservations:
                  production.weeklySafetyObservations.safetyObservations,
                associateInfo: {
                  fullName: production.teamMember.fullName,
                  apexId: production.teamMember.apexId,
                  isTrainee,
                  trainingStatus: this.calculateTrainingStatus(
                    production.teamMember.tenure,
                    production.teamMember.trainingStatus
                  ),
                  profilePhoto: production.teamMember.profilePhoto
                },
                associatePaySplit:
                  production.productivitySummary.associatePaySplit
              } as AssociateProductionDetails;
            })
          );
      })
    );
  }

  getRollingAverageAppearance(rollingAverage): StatsAppearance {
    if (!rollingAverage) {
      return null;
    }
    if (rollingAverage >= 4.5) {
      return StatsAppearance.Green;
    } else if (rollingAverage >= 3.5 && rollingAverage < 4.5) {
      return StatsAppearance.Blue;
    } else if (rollingAverage >= 2.5 && rollingAverage < 3.5) {
      return StatsAppearance.Yellow;
    } else if (rollingAverage >= 1.5 && rollingAverage < 2.5) {
      return StatsAppearance.Red;
    } else if (rollingAverage < 1.5) {
      return StatsAppearance.DarkRed;
    }
  }

  mapWeeklyCheckinListItem(
    item: WeeklyCheckinListItemResponse
  ): WeeklyCheckinListItem {
    return {
      weekStartDate: DateTimeUtils.getWeekStartDateByEndDate(
        item.weekEndingDate
      ).format(DateFormat),
      weekEndingDate: item.weekEndingDate,
      appearance: this.getCheckinAppearance(item.score),
      score: item.score,
      message: item.message,
      subDept: item.subDept,
      associateGuid: item.associateGuid
    } as WeeklyCheckinListItem;
  }

  saveProfilePhoto(associateId: string, photo: string) {
    const requestBody = {
      ProfilePhoto: photo
    };

    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.put(
          `associates/profilePhoto/${associateId}`,
          authToken,
          requestBody
        );
      })
    );
  }

  deletePhoto(associateId: string) {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.delete(
          `associates/profilePhoto/${associateId}`,
          authToken
        );
      })
    );
  }

  isAssociateTrainee(tenure: number, trainingStatus: string) {
    return (
      tenure <= Settings.TraineeTenure &&
      trainingStatus !== TrainingStatus.NoRecordFound
    );
  }

  calculateTrainingStatus(tenure: number, trainingStatus: string) {
    if (
      tenure <= Settings.TraineeTenure &&
      trainingStatus === TrainingStatus.FinishedTraining
    ) {
      switch (Math.ceil(tenure / 7)) {
        case 0:
        case 1:
          return TrainingStatus.WeekOneTraining;
        case 2:
          return TrainingStatus.WeekTwoTraining;
        case 3:
          return TrainingStatus.WeekThreeTraining;
        case 4:
          return TrainingStatus.WeekFourTraining;
        case 5:
          return TrainingStatus.WeekFiveTraining;
        case 6:
          return TrainingStatus.WeekSixTraining;
        default:
          return TrainingStatus.FinishedTraining;
      }
    }

    return trainingStatus;
  }

  private getCurrentWeekProductivity(
    isTrainee: boolean,
    associateProductionDetails: AssociateProductionResponse
  ) {
    const productivitySummary = associateProductionDetails.productivitySummary;
    const currentWeekProductivity =
      productivitySummary.associateCurrentWeekProductivity;
    const siteProductivityAverage = productivitySummary.siteProductivityAverage;
    if (!isTrainee) {
      return {
        hours: productivitySummary.associateCurrentWeekProductivity.hours
      };
    }
    const goalPercentage =
      this.traineeGoalPercentageByWeek[
        associateProductionDetails.teamMember.trainingStatus
      ] || 100;

    return {
      hours: productivitySummary.associateCurrentWeekProductivity.hours,
      goalPercentage,
      casesPerHour: currentWeekProductivity.productivityMetrics.casesPerHour,
      casesPerHourGoal: this.calculateGoal(
        siteProductivityAverage.productivityMetrics.casesPerHour,
        goalPercentage
      ),
      palletsPerHour:
        currentWeekProductivity.productivityMetrics.palletsPerHour,
      palletsPerHourGoal: this.calculateGoal(
        siteProductivityAverage.productivityMetrics.palletsPerHour,
        goalPercentage
      )
    };
  }

  private calculateGoal(value, percentage) {
    const sourceValue = value || 0;
    const percentageValue = MathHelpers.calculatePercentage(
      sourceValue,
      percentage
    );
    return Math.round(percentageValue);
  }

  private getProductivityAverage(productivitySummary) {
    const associateProductivityAvg =
      productivitySummary.associateProductivityAverage;
    const siteProductivityAvg = productivitySummary.siteProductivityAverage;
    return {
      casesPerHour: associateProductivityAvg.productivityMetrics.casesPerHour,
      casesPerHourLevel: this.getStatsLevel(
        associateProductivityAvg.productivityMetrics.casesPerHour,
        siteProductivityAvg.productivityMetrics.casesPerHour
      ),
      palletsPerHour:
        associateProductivityAvg.productivityMetrics.palletsPerHour,
      palletsPerHourLevel: this.getStatsLevel(
        associateProductivityAvg.productivityMetrics.palletsPerHour,
        siteProductivityAvg.productivityMetrics.palletsPerHour
      ),
      onTruck: associateProductivityAvg.onTruckPercentage * 100,
      onTruckLevel: this.getStatsLevel(
        associateProductivityAvg.onTruckPercentage,
        siteProductivityAvg.onTruckPercentage
      )
    };
  }

  private getStatsLevel(
    associateAverage: number,
    siteAverage: number
  ): StatsLevel {
    const ranges = [
      {
        min: siteAverage * 0.9,
        max: associateAverage * 1.01,
        stats: StatsLevel.Top10
      },
      {
        min: siteAverage * 0.8,
        max: siteAverage * 0.9,
        stats: StatsLevel.Top20
      },
      {
        min: siteAverage * 0.7,
        max: siteAverage * 0.8,
        stats: StatsLevel.Top30
      },
      {
        min: siteAverage * 0.5,
        max: siteAverage * 0.7,
        stats: StatsLevel.Top50
      },
      {
        min: siteAverage * 0.3,
        max: siteAverage * 0.5,
        stats: StatsLevel.Bottom50
      },
      {
        min: siteAverage * 0.2,
        max: siteAverage * 0.3,
        stats: StatsLevel.Bottom30
      },
      {
        min: siteAverage * 0.1,
        max: siteAverage * 0.2,
        stats: StatsLevel.Bottom20
      },
      {
        min: 0,
        max: siteAverage * 0.1,
        stats: StatsLevel.Bottom10
      }
    ];
    const range = ranges.find(
      rng => rng.min <= associateAverage && rng.max > associateAverage
    );

    return range ? range.stats : StatsLevel.Bottom10;
  }

  private getCheckinAppearance(value): StatsAppearance {
    if (!value) {
      return null;
    }
    if (value === 5) {
      return StatsAppearance.Green;
    } else if (value === 4) {
      return StatsAppearance.Blue;
    } else if (value === 3) {
      return StatsAppearance.Yellow;
    } else if (value === 2) {
      return StatsAppearance.Red;
    } else if (value === 1) {
      return StatsAppearance.DarkRed;
    }
  }

  private getPhoneRemaindersFromLocalStorage() {
    const remainder = localStorage.getItem(this.associatePhoneReminderKey);

    if (remainder == null) {
      return [];
    }

    return JSON.parse(
      localStorage.getItem(this.associatePhoneReminderKey)
    ) as Array<PhoneRemainder>;
  }
}

class PhoneRemainder {
  userId: string;
  lastNotifyDate: string;
}
