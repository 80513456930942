/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./productivity.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/page-section-header/page-section-header.component.ngfactory";
import * as i3 from "../../../../../shared/components/page-section-header/page-section-header.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "../../../../../shared/components/goal-gauge/goal-gauge.component.ngfactory";
import * as i8 from "../../../../../shared/components/goal-gauge/goal-gauge.component";
import * as i9 from "../../../../../shared/components/goal-gauge/goal-gauge.service";
import * as i10 from "./productivity.component";
var styles_ProductivityComponent = [i0.styles];
var RenderType_ProductivityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductivityComponent, data: {} });
export { RenderType_ProductivityComponent as RenderType_ProductivityComponent };
function View_ProductivityComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Pallets per hour "]))], null, null); }
function View_ProductivityComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Cases per hour "]))], null, null); }
export function View_ProductivityComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-section-header", [["data-cy", "productivityThisWeek"], ["subtitle", "See how you are measuring up to the productivity and hourly goals you set."], ["title", "Productivity this week"]], null, null, null, i2.View_PageSectionHeaderComponent_0, i2.RenderType_PageSectionHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.PageSectionHeaderComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "productivity"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "productivity__item"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["gaugePalletsLabel", 2]], null, 0, null, View_ProductivityComponent_1)), (_l()(), i1.ɵand(0, [["gaugeCasesLabel", 2]], null, 0, null, View_ProductivityComponent_2)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "productivity__link-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [["cdkMonitorElementFocus", ""], ["class", "productivity__image-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(9, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-goal-gauge", [["suffix", "avg."]], [[8, "id", 0]], null, null, i7.View_GoalGaugeComponent_0, i7.RenderType_GoalGaugeComponent)), i1.ɵdid(11, 638976, null, 0, i8.GoalGaugeComponent, [i9.GoalGaugeService], { current: [0, "current"], max: [1, "max"], suffix: [2, "suffix"], isAverageScale: [3, "isAverageScale"], labelTemplate: [4, "labelTemplate"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "productivity__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "productivity__link-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "a", [["cdkMonitorElementFocus", ""], ["class", "productivity__image-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(16, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-goal-gauge", [["label", "Hours worked"], ["suffix", "so far"]], [[8, "id", 0]], null, null, i7.View_GoalGaugeComponent_0, i7.RenderType_GoalGaugeComponent)), i1.ɵdid(18, 638976, null, 0, i8.GoalGaugeComponent, [i9.GoalGaugeService], { current: [0, "current"], max: [1, "max"], label: [2, "label"], suffix: [3, "suffix"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Productivity this week"; var currVal_1 = "See how you are measuring up to the productivity and hourly goals you set."; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.linkToGoalEditPage; _ck(_v, 8, 0, currVal_4); var currVal_6 = _co.currentItemsPerHour; var currVal_7 = _co.goalItemsPerHour; var currVal_8 = "avg."; var currVal_9 = true; var currVal_10 = ((_co.itemType === _co.itemTypeEnum.Pallets) ? i1.ɵnov(_v, 4) : i1.ɵnov(_v, 5)); _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_13 = _co.linkToGoalEditPage; _ck(_v, 15, 0, currVal_13); var currVal_15 = _co.currentWorkedHours; var currVal_16 = _co.goalWorkedHours; var currVal_17 = "Hours worked"; var currVal_18 = "so far"; _ck(_v, 18, 0, currVal_15, currVal_16, currVal_17, currVal_18); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 8).target; var currVal_3 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = "items"; _ck(_v, 10, 0, currVal_5); var currVal_11 = i1.ɵnov(_v, 15).target; var currVal_12 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_11, currVal_12); var currVal_14 = "hours"; _ck(_v, 17, 0, currVal_14); }); }
export function View_ProductivityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-productivity", [], null, null, null, View_ProductivityComponent_0, RenderType_ProductivityComponent)), i1.ɵdid(1, 114688, null, 0, i10.ProductivityComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductivityComponentNgFactory = i1.ɵccf("app-productivity", i10.ProductivityComponent, View_ProductivityComponent_Host_0, { itemType: "itemType", currentItemsPerHour: "currentItemsPerHour", currentWorkedHours: "currentWorkedHours", goalItemsPerHour: "goalItemsPerHour", goalWorkedHours: "goalWorkedHours", linkToGoalEditPage: "linkToGoalEditPage" }, {}, []);
export { ProductivityComponentNgFactory as ProductivityComponentNgFactory };
