export enum StatsLevel {
  Top10,
  Top20,
  Top30,
  Top50,
  Bottom50,
  Bottom30,
  Bottom20,
  Bottom10
}
