<app-page-header-container></app-page-header-container>

<main class="main-container">
  <ng-template #opportunitiesList>
    <div>
      {{ totalCount$ | async }}
      <span i18n="@@opportunities-list_pageTitle">
        Road Crew Opportunities
      </span>
    </div>
  </ng-template>
  <app-page-title
    [titleTemplate]="opportunitiesList"
    [appearance]="PageTitleAppearanceEnum.Transparent"
    data-cy="pageHeaderTitle"
  ></app-page-title>

  <app-sort-search
    class="opportunities-list-container__sort"
    [sortByOptions]="sortOptions"
    [sort]="sort$ | async"
    [searchQuery]="(filter$ | async)?.query"
    (sortChange)="onSortChange($event)"
    (searchQueryChange)="onSearchQueryChange($event)"
  ></app-sort-search>

  <app-opportunities-list-filter
    (openFilter)="onOpenFilter($event)"
    (clearFilter)="onClearFilter()"
    (filterChange)="onFilterChange($event)"
    [filter]="filter$ | async"
    [totalCount]="totalCount$ | async"
    [userApexId]="userApexId$ | async"
  ></app-opportunities-list-filter>

  <app-opportunities-list
    *ngIf="(showAppLoader$ | async) === false"
    [opportunitiesList]="opportunitiesList$ | async"
    [page]="opportunitiesListPage$ | async"
    [totalCount]="totalCount$ | async"
    (openOpportunity)="onOpenOpportunity($event)"
    (changePage)="onChangePage($event)"
  ></app-opportunities-list>

  <ng-template #emptyStateMessage i18n="@@opportunities-list_noDataFound">
    No openings found.
  </ng-template>

  <ng-template
    #emptyStateMessageWithFilters
    i18n="@@opportunities-list_noDataFoundWithFilters"
  >
    There are no openings that match your filters.
  </ng-template>

  <app-empty-state
    *ngIf="
      (opportunitiesList$ | async)?.length === 0 &&
      (showAppLoader$ | async) === false
    "
    [emptyStateTemplate]="
      (isOpportunitiesFilterSet$ | async)
        ? emptyStateMessageWithFilters
        : emptyStateMessage
    "
    [emptyStateIcon]="
      (isOpportunitiesFilterSet$ | async)
        ? SvgIconNameEnum.EmptyStateApplications
        : SvgIconNameEnum.EmptyState
    "
  ></app-empty-state>

  <app-loader [isLoading]="showAppLoader$ | async"></app-loader>
</main>
