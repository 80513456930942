import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { AssociateProductionDetails } from '../../../core/dataEntities/associate/associateProductionDetails';
import { TeamMemberInfo } from '../../view-models/team-member-details';
import {
  selectActiveTeamMemberInfo,
  selectActiveTeamMemberProduction,
  selectIsTeamMemberPhotoRemoving,
  selectIsTeamMemberProductionLoading
} from '../../reducers/team-member-details.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { MyTeamActions } from '../../actions';
import { Features } from '../../../core/enums/Features';

@Component({
  selector: 'app-my-team-member-production',
  templateUrl: './my-team-member-production.component.html',
  styleUrls: ['./my-team-member-production.component.scss']
})
export class MyTeamMemberProductionComponent implements OnInit {
  public Features = Features;
  teamMemberInfo$: Observable<TeamMemberInfo>;
  isProductionDetailsLoading$: Observable<boolean>;
  isTeamMemberPhotoRemoving$: Observable<boolean>;
  isMainLoaderVisible$: Observable<boolean>;
  productionDetails$: Observable<AssociateProductionDetails>;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
    this.route.paramMap.pipe(first()).subscribe((params: ParamMap) => {
      this.store.dispatch(
        MyTeamActions.loadTeamMemberProduction({
          associateId: params.get('id')
        })
      );
    });
  }

  ngOnInit() {
    this.isProductionDetailsLoading$ = this.store.select(
      selectIsTeamMemberProductionLoading
    );
    this.productionDetails$ = this.store.select(
      selectActiveTeamMemberProduction
    );
    this.isTeamMemberPhotoRemoving$ = this.store.select(
      selectIsTeamMemberPhotoRemoving
    );
    this.teamMemberInfo$ = this.store.select(selectActiveTeamMemberInfo);

    this.isMainLoaderVisible$ = combineLatest(
      this.isProductionDetailsLoading$,
      this.teamMemberInfo$,
      this.isTeamMemberPhotoRemoving$
    ).pipe(
      map(
        ([
          isProductionDetailsLoading,
          teamMemberInfo,
          isTeamMemberPhotoRemoving
        ]) =>
          (isProductionDetailsLoading && teamMemberInfo === null) ||
          isTeamMemberPhotoRemoving === true
      )
    );
  }

  deletePhoto() {
    this.teamMemberInfo$.pipe(first()).subscribe(teamMemberInfo => {
      this.store.dispatch(
        MyTeamActions.deleteTeamMemberPhoto({
          teamMember: teamMemberInfo
        })
      );
    });
  }
}
