import { Routes } from '@angular/router';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { DynamicContentComponent } from './dynamic-content.component';
import { PageContainerComponent } from './containers/page-container/page-container.component';
const ɵ0 = {
    showMenuBar: true
}, ɵ1 = {
    hideNavigationBar: true,
    siteMapKey: SiteMapItemKeys.DynamicContent
};
const routes = [
    {
        path: 'content',
        component: DynamicContentComponent,
        data: ɵ0,
        children: [
            {
                path: ':slug',
                component: PageContainerComponent,
                data: ɵ1
            }
        ]
    }
];
export class DynamicContentRoutingModule {
}
export { ɵ0, ɵ1 };
