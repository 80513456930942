/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pay-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/components/menu/menu-item/menu-item.component.ngfactory";
import * as i4 from "../../../shared/components/menu/menu-item/menu-item.component";
import * as i5 from "../../../shared/components/footer/footer-note/footer-note.component.ngfactory";
import * as i6 from "../../../shared/components/footer/footer-note/footer-note.component";
import * as i7 from "../../../shared/components/footer/footer.component.ngfactory";
import * as i8 from "../../../shared/components/footer/footer.component";
import * as i9 from "../../../shared/components/menu/menu.component.ngfactory";
import * as i10 from "../../../shared/components/menu/menu.component";
import * as i11 from "../../../core/directives/feature-toggle.directive";
import * as i12 from "../../../core/services/environment.service";
import * as i13 from "../../../shared/components/empty-state/empty-state.component.ngfactory";
import * as i14 from "../../../shared/components/empty-state/empty-state.component";
import * as i15 from "../../../shared/services/translation.service";
import * as i16 from "../../../shared/components/page-title/page-title.component.ngfactory";
import * as i17 from "../../../shared/components/page-title/page-title.component";
import * as i18 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i19 from "../../../shared/components/loader/loader.component";
import * as i20 from "./pay-history.component";
import * as i21 from "@ngrx/store";
var styles_PayHistoryComponent = [i0.styles];
var RenderType_PayHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PayHistoryComponent, data: {} });
export { RenderType_PayHistoryComponent as RenderType_PayHistoryComponent };
function View_PayHistoryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Current Week: "])), (_l()(), i1.ɵeld(1, 0, null, null, 2, "time", [], [[1, "datetime", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 2), (_l()(), i1.ɵted(-1, null, [" - "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "time", [], [[1, "datetime", 0]], null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵppd(7, 2)], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.weekStartDate; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.$implicit.weekStartDate, "MM/dd")); _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.weekEndingDate; _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.$implicit.weekEndingDate, "MM/dd")); _ck(_v, 6, 0, currVal_3); }); }
function View_PayHistoryComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Last week: "]))], null, null); }
function View_PayHistoryComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Two weeks ago: "]))], null, null); }
function View_PayHistoryComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_PayHistoryComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayHistoryComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayHistoryComponent_7)), i1.ɵdid(6, 16384, null, 0, i2.NgPluralCase, [[8, "=2"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayHistoryComponent_8)), i1.ɵdid(8, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "time", [], [[1, "datetime", 0]], null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵppd(11, 2), (_l()(), i1.ɵted(-1, null, [" - "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "time", [], [[1, "datetime", 0]], null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵppd(15, 2)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.weeksAgo; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.weekStartDate; _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.$implicit.weekStartDate, "MM/dd")); _ck(_v, 10, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.weekEndingDate; _ck(_v, 13, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.$implicit.weekEndingDate, "MM/dd")); _ck(_v, 14, 0, currVal_4); }); }
function View_PayHistoryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(0, [["currentWeekSubTitleTemplate", 2]], null, 0, null, View_PayHistoryComponent_4)), (_l()(), i1.ɵand(0, [["subTitleTemplate", 2]], null, 0, null, View_PayHistoryComponent_5)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "app-menu-item", [], null, null, null, i3.View_MenuItemComponent_0, i3.RenderType_MenuItemComponent)), i1.ɵdid(4, 114688, [[1, 4]], 0, i4.MenuItemComponent, [], { title: [0, "title"], subTitleTemplate: [1, "subTitleTemplate"], titleFont: [2, "titleFont"], appearance: [3, "appearance"], isHighlighted: [4, "isHighlighted"], link: [5, "link"], dataCy: [6, "dataCy"] }, null), i1.ɵppd(5, 1), i1.ɵpad(6, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 1), _v.context.$implicit.earnings)); var currVal_1 = (_v.context.$implicit.isCurrentWeek ? i1.ɵnov(_v, 1) : i1.ɵnov(_v, 2)); var currVal_2 = _co.MenuTitleFontEnum.Roboto; var currVal_3 = _co.MenuAppearanceEnum.Green; var currVal_4 = _v.context.$implicit.isCurrentWeek; var currVal_5 = _ck(_v, 6, 0, "/wages", _v.context.$implicit.id, "pay"); var currVal_6 = (_v.context.$implicit.isCurrentWeek ? "currentWorkWeek" : "workWeek"); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PayHistoryComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-footer-note", [["data-cy", "earningsHistoryEndTitle"]], null, null, null, i5.View_FooterNoteComponent_0, i5.RenderType_FooterNoteComponent)), i1.ɵdid(2, 114688, [[2, 4]], 0, i6.FooterNoteComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" End of earnings history records "]))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_PayHistoryComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 1, i8.FooterComponent, [], null, null), i1.ɵqud(603979776, 2, { notes: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_PayHistoryComponent_10)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.isRoadCrewEnabled$)) === false); _ck(_v, 4, 0, currVal_0); }, null); }
function View_PayHistoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "app-menu", [["data-cy", "payHistoryList"]], null, null, null, i9.View_MenuComponent_0, i9.RenderType_MenuComponent)), i1.ɵdid(2, 114688, null, 1, i10.MenuComponent, [], null, null), i1.ɵqud(603979776, 1, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PayHistoryComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayHistoryComponent_9)), i1.ɵdid(8, 81920, null, 0, i11.FeatureToggleDirective, [i1.TemplateRef, i1.ViewContainerRef, i12.EnvironmentService], { appFeatureToggle: [0, "appFeatureToggle"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.paymentHistory)); _ck(_v, 5, 0, currVal_0); var currVal_1 = ("!" + _co.FeaturesEnum.Opportunities); _ck(_v, 8, 0, currVal_1); }, null); }
function View_PayHistoryComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" No recent work history is available at the moment. "]))], null, null); }
function View_PayHistoryComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-state", [], null, null, null, i13.View_EmptyStateComponent_0, i13.RenderType_EmptyStateComponent)), i1.ɵdid(1, 114688, null, 0, i14.EmptyStateComponent, [i15.TranslationService], { emptyStateTemplate: [0, "emptyStateTemplate"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 4); _ck(_v, 1, 0, currVal_0); }, null); }
function View_PayHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "pay-history"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PayHistoryComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["emptyStateTranslation", 2]], null, 0, null, View_PayHistoryComponent_11)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PayHistoryComponent_12)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.paymentHistory)).length != 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.paymentHistory)).length == 0); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_PayHistoryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 8, "main", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-page-title", [["data-cy", "payHistoryHeading"], ["title", "Earnings History"]], null, null, null, i16.View_PageTitleComponent_0, i16.RenderType_PageTitleComponent)), i1.ɵdid(4, 114688, null, 0, i17.PageTitleComponent, [], { title: [0, "title"], appearance: [1, "appearance"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-loader", [], [[2, "is-visible", null]], null, null, i18.View_LoaderComponent_0, i18.RenderType_LoaderComponent)), i1.ɵdid(6, 114688, null, 0, i19.LoaderComponent, [], { isLoading: [0, "isLoading"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PayHistoryComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Earnings History"; var currVal_1 = _co.PageTitleAppearanceEnum.Transparent; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.isPayHistoryLoading$)); _ck(_v, 6, 0, currVal_3); var currVal_4 = (i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.isPayHistoryLoading$)) === false); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 6).visible; _ck(_v, 5, 0, currVal_2); }); }
export function View_PayHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pay-history", [], null, null, null, View_PayHistoryComponent_0, RenderType_PayHistoryComponent)), i1.ɵdid(1, 114688, null, 0, i20.PayHistoryComponent, [i21.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PayHistoryComponentNgFactory = i1.ɵccf("app-pay-history", i20.PayHistoryComponent, View_PayHistoryComponent_Host_0, {}, {}, []);
export { PayHistoryComponentNgFactory as PayHistoryComponentNgFactory };
