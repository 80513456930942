import { Component, OnInit } from '@angular/core';
import { PageHeaderAppearance } from '../../../../../shared/enums/PageHeader';
import { PageTitleAppearance } from '../../../../../shared/enums/PageTitle';
import { ButtonAppearance } from '../../../../../shared/enums/ButtonLink';
import { MenuColorAppearance } from '../../../../../shared/enums/Menu';
import { SvgIconColor, SvgIconName } from '../../../../../shared/enums/SvgIcon';
import moment from 'moment';
import {
  AppState,
  getUserId,
  selectIsShiftLoading,
  selectShift
} from '../../../../../reducers';
import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import { ShiftDetails } from '../../../../../core/dataEntities/shifts/shiftDetails';
import { ShiftActions } from '../../actions';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { ShiftDeleteModalComponent } from '../../components/shift-delete-modal/shift-delete-modal.component';
import { BottomSheetService } from '../../../../../shared/modules/modal/bottom-sheet/bottom-sheet.service';

@Component({
  selector: 'app-shift-details',
  templateUrl: './shift-details.component.html',
  styleUrls: ['./shift-details.component.scss']
})
export class ShiftDetailsComponent implements OnInit {
  public shiftDetails: ShiftDetails = null;
  public isShiftDetailsLoading$: Observable<boolean>;
  PageHeaderAppearanceEnum = PageHeaderAppearance;
  PageTitleAppearanceEnum = PageTitleAppearance;
  ButtonAppearanceEnum = ButtonAppearance;
  MenuColorAppearanceEnum = MenuColorAppearance;
  SvgIconNameEnum = SvgIconName;
  SvgIconColorEnum = SvgIconColor;
  public endDate: string;
  public canEdit = false;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private bottomSheetService: BottomSheetService
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(first()).subscribe(params => {
      this.store.dispatch(ShiftActions.loadShift({ id: params.get('id') }));
    });
    this.isShiftDetailsLoading$ = this.store.select(selectIsShiftLoading);

    combineLatest(
      this.store.select(selectShift),
      this.store.select(getUserId),
      this.isShiftDetailsLoading$
    )
      .pipe(
        filter(
          ([shift, userId, isShiftLoading]) =>
            shift && userId !== null && !isShiftLoading
        ),
        first()
      )
      .subscribe(([shift, userId]) => {
        this.shiftDetails = shift;
        this.endDate = moment(shift.startDateTime)
          .add(shift.duration, 'hours')
          .format();
        this.canEdit = shift.manager.id === userId;
      });
  }

  openDeleteModal() {
    this.bottomSheetService
      .open(ShiftDeleteModalComponent, {
        data: this.shiftDetails.id
      })
      .afterClosed()
      .pipe(first())
      .subscribe(confirmation => {
        if (confirmation && confirmation.success) {
          this.store.dispatch(
            ShiftActions.removeShift({ shiftId: this.shiftDetails.id })
          );
        }
      });
  }
}
