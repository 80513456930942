<app-page-header-container></app-page-header-container>

<main class="main-container">
  <app-loader
    [isLoading]="
      isPerformanceLoading$ || isGoalLoading$ || isEarningsLoading$ | async
    "
  ></app-loader>

  <div
    *ngIf="
      (isPerformanceLoading$ || isGoalLoading$ || isEarningsLoading$
        | async) === false && goalFormGroup
    "
  >
    <app-page-title
      title="Edit weekly goals"
      i18n-title="@@weekly-goal_pageTitle"
    ></app-page-title>

    <div class="weekly-goal">
      <p
        class="weekly-goal__header heading"
        i18n="@@weekly-goal_weeklyGoalLegendNote"
      >
        Take control of your earnings. Plan your success around your
        <span class="heading__bold">
          personal track-record
          <app-svg-icon
            class="heading__icon"
            [name]="SvgIconNameEnum.Person"
          ></app-svg-icon>
        </span>
        and your
        <span class="heading__bold">
          site average
          <app-svg-icon
            class="heading__icon"
            [name]="SvgIconNameEnum.SiteAverage"
          ></app-svg-icon>
        </span>
        .
      </p>
      <form
        [formGroup]="goalFormGroup"
        appRootForm
        (ngSubmit)="onSubmit(goalFormGroup)"
        *ngIf="goalStoreService.goalAverages$ | async as goalAverages"
      >
        <app-current-goal
          [earningGoal]="goalFormGroup.get('goal').value"
          [infoBoxText]="config.currentGoal.infoBoxText | translate"
          [associatePaySplit]="associatePaySplit$ | async"
        ></app-current-goal>
        <app-card-layout>
          <app-card-layout-item>
            <app-goal-input-card
              *ngIf="
                goalFormGroup.get('itemType').value === ItemTypeEnum.Pallets
              "
              [formGroup]="goalFormGroup"
              goalType="palletsPerHour"
              [min]="config.palletsPerHour.min"
              [max]="config.palletsPerHour.max"
              [step]="config.palletsPerHour.step"
              [associateAverage]="goalAverages.associateAverages.palletsPerHour"
              [siteAverage]="goalAverages.siteAverages.palletsPerHour"
              [validation]="
                goalStoreService.palletsPerHourValidationState$ | async
              "
              averageFormat="1.0-0"
              [infoBoxText]="config.palletsPerHour.infoBoxText | translate"
              [goalTypes]="config.palletsPerHour.selectors"
              [isGoalValid]="goalStoreService.isGoalValid$ | async"
              i18n-suffix="@@weekly-goal_palletsPerHourSuffix"
              suffix="Pallets/hr"
              [maxLimitPercent]="config.palletsPerHour.maxLimitPercent"
            ></app-goal-input-card>
            <app-goal-input-card
              *ngIf="goalFormGroup.get('itemType').value === ItemTypeEnum.Cases"
              [formGroup]="goalFormGroup"
              goalType="casesPerHour"
              [min]="config.casesPerHour.min"
              [max]="config.casesPerHour.max"
              [step]="config.casesPerHour.step"
              [associateAverage]="goalAverages.associateAverages.casesPerHour"
              [siteAverage]="goalAverages.siteAverages.casesPerHour"
              [validation]="
                goalStoreService.casesPerHourValidationState$ | async
              "
              averageFormat="1.0-0"
              [infoBoxText]="config.casesPerHour.infoBoxText | translate"
              [goalTypes]="config.casesPerHour.selectors"
              [isGoalValid]="goalStoreService.isGoalValid$ | async"
              i18n-suffix="@@weekly-goal_casesPerHourSuffix"
              suffix="Cases/hr"
              [maxLimitPercent]="config.casesPerHour.maxLimitPercent"
            ></app-goal-input-card>
          </app-card-layout-item>
          <app-card-layout-item>
            <app-goal-input-card
              [formGroup]="goalFormGroup"
              goalType="hours"
              [min]="config.hours.min"
              [max]="config.hours.max"
              [step]="config.hours.step"
              [associateAverage]="goalAverages.associateAverages.hours"
              [siteAverage]="goalAverages.siteAverages.hours"
              [validation]="goalStoreService.hoursValidationState$ | async"
              averageFormat="1.0-0"
              [infoBoxText]="config.hours.infoBoxText | translate"
              [goalTypes]="config.hours.selectors"
              [isGoalValid]="goalStoreService.isGoalValid$ | async"
              i18n-suffix="@@weekly-goal_hoursSuffix"
              suffix="hours"
              [maxLimitPercent]="config.hours.maxLimitPercent"
            ></app-goal-input-card>
          </app-card-layout-item>
          <app-card-layout-item>
            <app-goal-input-card
              *ngIf="
                goalFormGroup.get('itemType').value === ItemTypeEnum.Pallets
              "
              [formGroup]="goalFormGroup"
              goalType="revenuePerPallet"
              [min]="config.revenuePerPallet.min"
              [max]="config.revenuePerPallet.max"
              [step]="config.revenuePerPallet.step"
              [associateAverage]="
                goalAverages.associateAverages.revenuePerPallet
              "
              [siteAverage]="goalAverages.siteAverages.revenuePerPallet"
              [validation]="
                goalStoreService.revenuePerPalletValidationState$ | async
              "
              averageFormat="1.1-2"
              [infoBoxText]="config.revenuePerPallet.infoBoxText | translate"
              [goalTypes]="
                config.revenuePerPallet.itemTypeMap[
                  goalFormGroup.get('itemType').value
                ]
              "
              [isGoalValid]="goalStoreService.isGoalValid$ | async"
              prefix="$"
              i18n-suffix="@@weekly-goal_palletRevenueSuffix"
              suffix="/Pallet"
              [maxLimitPercent]="config.revenuePerPallet.maxLimitPercent"
            ></app-goal-input-card>
            <app-goal-input-card
              *ngIf="goalFormGroup.get('itemType').value === ItemTypeEnum.Cases"
              [formGroup]="goalFormGroup"
              goalType="revenuePerCase"
              [min]="config.revenuePerCase.min"
              [max]="config.revenuePerCase.max"
              [step]="config.revenuePerCase.step"
              [associateAverage]="goalAverages.associateAverages.revenuePerCase"
              [siteAverage]="goalAverages.siteAverages.revenuePerCase"
              [validation]="
                goalStoreService.revenuePerCaseValidationState$ | async
              "
              averageFormat="1.1-2"
              [infoBoxText]="config.revenuePerCase.infoBoxText | translate"
              [goalTypes]="
                config.revenuePerCase.itemTypeMap[
                  goalFormGroup.get('itemType').value
                ]
              "
              [isGoalValid]="goalStoreService.isGoalValid$ | async"
              i18n-suffix="@@weekly-goal_caseRevenueSuffix"
              suffix="Case"
              prefix="$"
              [maxLimitPercent]="config.revenuePerCase.maxLimitPercent"
            ></app-goal-input-card>
          </app-card-layout-item>
          <app-card-layout-item>
            <div class="form__submit">
              <app-button-link
                data-cy="saveGoalButton"
                [disabled]="
                  (goalStoreService.isGoalValid$ | async) === false ||
                  !goalFormGroup.valid
                "
                [type]="ButtonTypeEnum.Submit"
                [ariaDescribedBy]="'goal-note'"
                i18n-label="@@weekly-goal_saveGoal"
                label="Save Goal"
                [analyticsId]="AnalyticsEventsEnum.SaveGoalButton"
              ></app-button-link>
              <span
                id="goal-note"
                [ngClass]="{
                  'form__goal-note': true,
                  'form__goal-note--error':
                    (goalStoreService.goalState$ | async) ===
                    ValidationStatusEnum.Error,
                  'form__goal-note--warning':
                    (goalStoreService.goalState$ | async) ===
                    ValidationStatusEnum.Warning
                }"
              >
                <ng-container
                  *ngIf="
                    (goalStoreService.goalState$ | async) ===
                    ValidationStatusEnum.Error
                  "
                  i18n="@@weekly-goal_weeklyGoalError"
                >
                  Your goal may be unrealistic. Adjust goals to set yourself up
                  for success.
                </ng-container>
                <ng-container
                  *ngIf="
                    (goalStoreService.goalState$ | async) ===
                    ValidationStatusEnum.Warning
                  "
                  i18n="@@weekly-goal_weeklyGoalWarning"
                >
                  Some of your goals appear low or difficult. You can always
                  connect with your supervisor to get advice on goal setting.
                </ng-container>
              </span>
            </div>
          </app-card-layout-item>
        </app-card-layout>
      </form>
      <app-footer>
        <app-footer-note i18n="@@weekly-goal_weeklyGoalFooterNoteLine1">
          The information above is presented as an estimate and is not a
          guarantee or promise of actual earnings.
        </app-footer-note>
        <app-footer-note i18n="@@weekly-goal_weeklyGoalFooterNoteLine2">
          Calculations are based on your historical pay %.
        </app-footer-note>
      </app-footer>
    </div>
  </div>
</main>
