<app-page-header-container
  [appearance]="PageHeaderAppearanceEnum.Green"
></app-page-header-container>
<main class="main-container">
  <ng-template #detailsTitleEdit i18n="@@shift-details_pageTitleEdit">
    Manage Shift Details
  </ng-template>
  <ng-template #detailsTitle i18n="@@shift-details_pageTitle">
    New Shift Opening
  </ng-template>
  <app-page-title
    [titleTemplate]="canEdit ? detailsTitleEdit : detailsTitle"
    [appearance]="PageTitleAppearanceEnum.Green"
  ></app-page-title>

  <app-loader
    [isLoading]="shiftDetails === null && (isShiftDetailsLoading$ | async)"
  ></app-loader>

  <div class="shift-details" *ngIf="shiftDetails">
    <app-notice
      *ngIf="canEdit; else associateNotice"
      class="shift-details__notice"
      i18n="@@shift-details_managerTitle"
    >
      When associates view this page, they’ll be able to directly call and/or
      text you at {{ shiftDetails.phoneNumber }}.
    </app-notice>

    <ng-template #associateNotice>
      <p class="shift-details__title" i18n="@@shift-details_associateTitle">
        {{ shiftDetails.manager.firstName }}
        {{ shiftDetails.manager.lastName }} needs
        {{ shiftDetails.associatesNeeded }} additional warehouse associates. See
        details below:
      </p>
    </ng-template>

    <app-shift-details-card
      [shiftDetails]="shiftDetails"
      [preview]="canEdit"
    ></app-shift-details-card>
    <ng-container *ngIf="canEdit">
      <h2
        class="shift-details__manager-title"
        i18n="@@shift-details_manageShiftRequest"
      >
        Manage Shift Request
      </h2>
      <app-menu>
        <app-menu-item
          title="Edit Shift Details"
          i18n-title="@@shift-details_editShiftDetails"
          subTitle="Change shift date and time"
          i18n-subTitle="@@shift-details_editShiftDetailsSubtitle"
          [link]="['/shifts/edit', shiftDetails.id]"
          [iconName]="SvgIconNameEnum.Calendar"
          [iconColorPrimary]="SvgIconColorEnum.Lochmara"
          [iconColorSecondary]="SvgIconColorEnum.Spindle"
        ></app-menu-item>
        <app-menu-item
          title="Close Shift Request"
          i18n-title="@@shift-details_closeShiftRequest"
          subTitle="Remove shift request from Newsfeed"
          i18n-subTitle="@@shift-details_closeShiftRequestSubtitle"
          (buttonClick)="openDeleteModal()"
          [iconName]="SvgIconNameEnum.Close"
          [appearance]="MenuColorAppearanceEnum.Red"
        ></app-menu-item>
      </app-menu>
    </ng-container>
    <app-footer>
      <app-footer-note i18n="@@shift-details_thisShiftExpire">
        This shift opening will automatically expire on
        {{ endDate | date: "EEEE, MMMM d 'at' h:mm aa" }}
      </app-footer-note>
    </app-footer>
  </div>

  <ng-template #emptyStateMessage i18n="@@shift-details_noDataFound">
    Shift not found.
  </ng-template>

  <app-empty-state
    *ngIf="shiftDetails === null && (isShiftDetailsLoading$ | async) === false"
    [emptyStateTemplate]="emptyStateMessage"
  ></app-empty-state>
</main>
