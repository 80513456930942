<svg version="1.1" xmlns="http://www.w3.org/2000/svg">
  <style>
    circle,
    path,
    rect {
      transition: var(--icon-transition);
    }
  </style>
  <defs>
    <symbol
      [attr.id]="SvgIconNameEnum.ArrowDown"
      viewBox="0 0 18 48"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path
        d="M17.1,39.6L9,47.6l-8.1-8.1c-0.6-0.6-0.6-1.5,0-2.1s1.5-0.6,2.1,0l4.4,4.4V2c0-0.8,0.7-1.5,1.5-1.5
        s1.5,0.7,1.5,1.5v39.9l4.4-4.4c0.6-0.6,1.5-0.6,2.1,0S17.7,39,17.1,39.6z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.ArrowLeft"
      viewBox="0 0 30 30"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path
        transform="translate(-2,-3)"
        d="M25 17V19H13L17 23L16 25L9 18L16 11L17 13L13 17H25Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.ArrowRightFilled"
      viewBox="0 0 12 24"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path d="M4 18V6L10 11.7391L4 18Z" />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Banknote"
      viewBox="0 0 24 24"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path
        d="M22,6l-0.9,1H22c0.5,0,1,0.5,1,1v4.6c-1.4-0.2-2.5,0-3.3,0.8c-1.2,1.1-1.4,3.1-1.1,4.6H2c-0.5,0-1-0.5-1-1v-4.9
        c0.2,0,0.4,0,0.5,0c1,0,1.8-0.3,2.4-0.9C5,10.2,5.2,8.5,5.1,7H12V6H2C0.9,6,0,6.9,0,8v9c0,1.1,0.9,2,2,2h20c1.1,0,2-0.9,2-2V8
        C24,6.9,23.1,6,22,6z M1,8c0-0.5,0.5-1,1-1h2.1c0.1,1.2,0,2.7-0.9,3.5C2.7,11,2,11.2,1,11.1V8z M23,17c0,0.5-0.5,1-1,1h-2.4
        c-0.2-1.2-0.1-3,0.8-3.9c0.6-0.6,1.5-0.7,2.6-0.5V17z"
      />
      <path
        d="M14.5,14.1c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.8,0.2
        v1h-0.8v-1c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.5-0.7s-0.2-0.6-0.2-1h1.2c0,0.3,0,0.5,0.1,0.6
        c0.1,0.2,0.2,0.3,0.3,0.4s0.2,0.2,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.4,0.3-0.7
        c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.6-0.2c-0.3-0.1-0.6-0.2-0.9-0.4
        c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.2-0.3-0.4-0.4-0.6C10,11.3,10,11.1,10,10.8c0-0.3,0.1-0.5,0.1-0.8c0.1-0.2,0.2-0.4,0.4-0.6
        c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.2,0.8-0.2V7.7h0.8v1.1c0.3,0,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.6,0.5
        c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.1,0.9h-1.2c0-0.2,0-0.4-0.1-0.6c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.3
        s-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4
        c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0.1,0.6,0.2,0.9,0.4
        c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.3,0.4,0.4,0.6C14.4,13.6,14.5,13.8,14.5,14.1z"
      />
      <path
        class="st0"
        d="M23.4,2.3L20.9,5l-1.8,2l-2.5,2.7L14,7l-0.4-0.5c-0.1-0.1-0.2-0.4-0.1-0.5c0-0.1,0.1-0.1,0.1-0.2
        c0.2-0.2,0.5-0.2,0.7,0L14.5,6l0.9,1l1.2,1.3L17.7,7l0.9-1l0.9-1l3.1-3.3c0.2-0.2,0.5-0.2,0.7,0C23.5,1.8,23.5,2.1,23.4,2.3z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.BanknoteBold"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <g transform="translate(1,0)">
        <path
          fill="var(--color-icon-secondary)"
          d="M14.9,18c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.6-0.2
          c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0.1-0.7
          c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.2,0.8-0.2v-1.1h0.8v1.1c0.3,0,0.5,0.1,0.8,0.2
          c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.2,0.3,0.4,0.4,0.7C16,14.4,16,14.6,16,15h-1.2c0-0.2,0-0.4-0.1-0.6c0-0.2-0.1-0.3-0.2-0.4
          c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
          c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.4,0.2,0.6,0.2
          c0.3,0.1,0.6,0.2,0.9,0.4c0.3,0.1,0.5,0.3,0.7,0.4c0.2,0.2,0.3,0.4,0.4,0.6C16,17.5,16,17.7,16,18c0,0.3,0,0.5-0.1,0.8
          c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.8,0.2v0.9h-0.8V20c-0.3,0-0.5-0.1-0.8-0.2
          c-0.2-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.5-0.7c-0.1-0.3-0.2-0.6-0.2-1h1.2c0,0.3,0,0.5,0.1,0.6c0.1,0.2,0.2,0.3,0.3,0.4
          c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0.1,0.4,0.1c0.4,0,0.7-0.1,0.9-0.3C14.8,18.6,14.9,18.3,14.9,18z"
        />
        <path
          fill="var(--color-icon-primary)"
          d="M26,8.7v7.2c-1.5,0-2.5,0.6-3.1,1.2c-1.3,1.2-1.6,3.2-1.5,4.9H2v-5.8c0.3,0,0.5,0,0.8,0c1.5,0,2.5-0.6,3.1-1.1
          C7,14,7.4,12.3,7.4,10.7h6.3l-1.7-2H2c-1.1,0-2,0.9-2,2V22c0,1.1,0.9,2,2,2h24c1.1,0,2-0.9,2-2V10.7C28,9.6,27.1,8.7,26,8.7z
           M2,10.7h3.4c0,1.2-0.3,2.3-0.9,3c-0.5,0.5-1.4,0.7-2.5,0.5V10.7z M26,22h-2.6c-0.1-1.3,0.2-2.7,0.9-3.5c0.4-0.4,1-0.6,1.7-0.6V22z"
        />
        <path
          fill="var(--color-icon-secondary)"
          d="M27.7,3.3c0.4,0.4,0.4,1,0.1,1.4l-8.3,9l-3.8-4.1c-0.4-0.4-0.3-1,0.1-1.4c0.4-0.4,1-0.3,1.4,0.1l2.3,2.5
		      l6.8-7.4C26.7,2.9,27.3,2.9,27.7,3.3z"
        />
      </g>
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Bell"
      viewBox="0 0 16 16"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path
        d="M8 14.6667C8.73334 14.6667 9.33334 14.0667 9.33334 13.3334H6.66667C6.66667 14.0667 7.26 14.6667 8 14.6667ZM12
        10.6667V7.33342C12 5.28675 10.9067 3.57341 9 3.12008V2.66675C9 2.11341 8.55334 1.66675 8 1.66675C7.44667 1.66675
        7 2.11341 7 2.66675V3.12008C5.08667 3.57341 4.00001 5.28008 4.00001 7.33342V10.6667L2.66667
        12.0001V12.6667H13.3333V12.0001L12 10.6667Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Car"
      viewBox="0 0 50 50"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <g>
        <path
          d="M45.961,18.702c-0.033-0.038-0.061-0.075-0.1-0.112l-1.717-1.657c1.424-0.323,2.957-1.516,2.957-2.74
      c0-1.426-1.979-1.932-3.668-1.932c-1.766,0-1.971,1.21-1.992,2.065l-4.43-4.271c-0.9-0.891-2.607-1.592-3.883-1.592H24.5h-0.002
      h-8.63c-1.275,0-2.981,0.701-3.882,1.592l-4.429,4.271c-0.023-0.855-0.228-2.065-1.992-2.065c-1.691,0-3.669,0.506-3.669,1.932
      c0,1.224,1.534,2.417,2.958,2.74l-1.717,1.657c-0.039,0.037-0.066,0.074-0.1,0.112C1.2,20.272,0,23.184,0,25.297v6.279
      c0,1.524,0.601,2.907,1.572,3.938v2.435c0,1.424,1.192,2.585,2.658,2.585h3.214c1.466,0,2.657-1.159,2.657-2.585v-0.623h14.397
      H24.5h14.396v0.623c0,1.426,1.19,2.585,2.658,2.585h3.213c1.467,0,2.657-1.161,2.657-2.585v-2.435
      c0.972-1.031,1.572-2.414,1.572-3.938v-6.279C48.998,23.184,47.798,20.272,45.961,18.702z M13.613,11.953
      c0.623-0.519,1.712-0.913,2.255-0.913h8.63H24.5h8.63c0.543,0,1.632,0.394,2.255,0.913l5.809,5.63H24.5h-0.002H7.805L13.613,11.953
      z M1.993,24.347c0-1.546,1.21-2.801,2.704-2.801c1.493,0,6.372,2.864,6.372,4.41s-4.879,1.188-6.372,1.188
      C3.203,27.144,1.993,25.894,1.993,24.347z M10.102,34.573H9.587H9.072l-3.055,0.005c-0.848-0.264-1.446-0.572-1.869-0.903
      c-0.214-0.167-0.378-0.341-0.506-0.514c-0.129-0.175-0.223-0.347-0.284-0.519c-0.38-1.074,0.405-2.061,0.405-2.061h5.214
      l3.476,3.99L10.102,34.573L10.102,34.573z M31.996,34.575H24.5h-0.002h-7.496c-1.563,0-2.832-1.269-2.832-2.831h10.328H24.5h10.328
      C34.828,33.308,33.559,34.575,31.996,34.575z M32.654,29.812H24.5h-0.002h-8.154c-1.7,0-3.08-2.096-3.08-4.681h11.234H24.5h11.234
      C35.734,27.717,34.354,29.812,32.654,29.812z M45.641,32.644c-0.062,0.172-0.156,0.344-0.285,0.518
      c-0.127,0.173-0.291,0.347-0.506,0.514c-0.422,0.331-1.021,0.641-1.869,0.903l-3.055-0.005h-0.515h-0.515h-2.353l3.478-3.99h5.213
      C45.234,30.583,46.02,31.568,45.641,32.644z M44.301,27.144c-1.492,0-6.371,0.356-6.371-1.188s4.879-4.41,6.371-4.41
      c1.494,0,2.704,1.255,2.704,2.801C47.005,25.892,45.795,27.144,44.301,27.144z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Calendar"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <rect
        fill="var(--color-icon-secondary)"
        x="5"
        y="6"
        width="20"
        height="5"
      />
      <path
        fill="var(--color-icon-primary)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 6H8C6.89543 6 6 6.89543 6 8V22C6 23.1046 6.89543 24 8 24H22C23.1046 24 24 23.1046 24 22V8C24 6.89543 23.1046
        6 22 6ZM8 4C5.79086 4 4 5.79086 4 8V22C4 24.2091 5.79086 26 8 26H22C24.2091 26 26 24.2091 26 22V8C26 5.79086 24.2091 4 22 4H8Z"
      />
      <path fill="var(--color-icon-primary)" d="M5 11L25 11L25 12L5 12L5 11Z" />
      <circle fill="var(--color-icon-primary)" cx="20.5" cy="4.5" r="1.5" />
      <circle fill="var(--color-icon-primary)" cx="9.5" cy="4.5" r="1.5" />
      <path
        fill="var(--color-icon-primary)"
        d="M14.75 15.8107V21.5H16.25V14.845C16.25 13.865 15.0651 13.3742 14.3722 14.0672L12.4697 15.9697L13.5303 17.0303L14.75 15.8107Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.CapstoneLogo"
      viewBox="0 0 69 39"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path d="M31.56 0.0999756L35.63 6.21998L68.07 9.70998L31.56 0.0999756Z" />
      <path d="M35.08 6.28L30.89 0L0.48999 12.75L35.08 6.28Z" />
      <path d="M35.85 6.75L43.08 29.58L68.23 10.23L35.85 6.75Z" />
      <path d="M42.7 30.11L0.22 13.31L0 13.35V22.96L42.7 38.85V30.11Z" />
      <path d="M68.95 10.31L43.2 30.11V38.8L69 18.96V10.31H68.95Z" />
      <path d="M35.33 6.73999L1.14001 13.14L42.55 29.51L35.33 6.73999Z" />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Chevron"
      viewBox="0 0 12 12"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <g transform="translate(0.5, -1)">
        <rect
          width="1.71721"
          height="8"
          transform="matrix(0.707108 0.707105 -0.707108 0.707105 10.0984 4.44141)"
        />
        <rect
          width="1.77179"
          height="8"
          transform="matrix(-0.707105 0.707108 -0.707105 -0.707108 6.91565 10.0547)"
        />
      </g>
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Clock"
      viewBox="0 0 24 24"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <g transform="translate(3,1)">
        <path
          d="M18,5.8l-2.1-2.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l0,0l-1.3,1.3c-1.1-0.7-2.3-1.2-3.7-1.3V4.8
          C10.4,4.4,11,3.5,11,2.5C11,1.1,9.9,0,8.5,0S6,1.1,6,2.5c0,1,0.6,1.9,1.5,2.3v0.3C3.3,5.6,0,9.2,0,13.5C0,18.2,3.8,22,8.5,22
          c4.7,0,8.5-3.8,8.5-8.5c0-1.9-0.6-3.6-1.7-5l1.2-1.2c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7
          C18.3,6.3,18.2,6,18,5.8z M15.2,5.8l0.7,0.7l-1,1l-0.7-0.7L15.2,5.8z M7,2.5C7,1.7,7.7,1,8.5,1S10,1.7,10,2.5
          c0,0.4-0.2,0.8-0.5,1.1V3.5c0-0.6-0.4-1-1-1s-1,0.4-1,1v0.1C7.2,3.3,7,2.9,7,2.5z M9,21v-0.5C9,20.2,8.8,20,8.5,20S8,20.2,8,20.5
          V21c-3.7-0.2-6.7-3.2-7-7h0.5C1.8,14,2,13.8,2,13.5S1.8,13,1.5,13H1c0.2-3.6,3.1-6.6,6.7-7C7.8,6.2,7.9,6.3,8,6.3v0.2
          C8,6.8,8.2,7,8.5,7S9,6.8,9,6.5V6.3C9.1,6.3,9.2,6.2,9.3,6c3.6,0.4,6.4,3.3,6.7,7h-0.5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5H16
          C15.7,17.7,12.7,20.7,9,21z"
        />
        <path
          d="M9,13.3V8.5C9,8.2,8.8,8,8.5,8S8,8.2,8,8.5v5.2l4.1,4.1c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1
          c0.2-0.2,0.2-0.5,0-0.7L9,13.3z"
        />
      </g>
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Close"
      viewBox="0 0 30 30"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <g transform="translate(-2, -3)">
        <rect
          x="23.3645"
          y="10"
          width="3"
          height="21"
          transform="rotate(45 23.3645 10)"
        />
        <rect
          x="25.4856"
          y="24.8494"
          width="3"
          height="21"
          transform="rotate(135 25.4856 24.8494)"
        />
      </g>
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Completed"
      viewBox="0 0 475 475"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <g>
        <g fill="#fee45a">
          <path
            d="m129.116 85.945h-10.156c-16.957 0-30.704-13.747-30.704-30.704v-16.52c0-3.545 2.873-6.418 6.418-6.418h3.738c16.957 0 30.704 13.747 30.704 30.704z"
          />
          <path
            d="m98.833 114.498 4.712 8.997c7.868 15.022 26.424 20.821 41.445 12.953l14.634-7.665c3.14-1.645 4.352-5.523 2.708-8.663l-1.735-3.312c-7.868-15.022-26.424-20.821-41.445-12.953z"
          />
          <path
            d="m74.771 144.467h-10.157c-16.957 0-30.704-13.747-30.704-30.704v-16.52c0-3.545 2.873-6.418 6.418-6.418h3.738c16.957 0 30.704 13.747 30.704 30.704v22.938z"
          />
          <path
            d="m44.251 232.304h-10.157c-16.957 0-30.704-13.747-30.704-30.704v-16.52c0-3.545 2.873-6.418 6.418-6.418h3.738c16.957 0 30.704 13.747 30.704 30.704v22.938z"
          />
          <path
            d="m45.254 252.882 9.639 3.201c16.093 5.344 33.472-3.37 38.816-19.463l5.206-15.678c1.117-3.364-.704-6.997-4.068-8.114l-3.548-1.178c-16.093-5.344-33.472 3.37-38.816 19.463z"
          />
          <path
            d="m69.625 351.706 10.079-1.253c16.828-2.091 28.775-17.428 26.683-34.256l-2.037-16.394c-.437-3.518-3.643-6.015-7.161-5.578l-3.71.461c-16.828 2.091-28.775 17.428-26.683 34.256z"
          />
          <path
            d="m55.322 321.388-9.502 3.587c-15.865 5.989-33.581-2.016-39.57-17.88l-5.835-15.455c-1.252-3.316.421-7.019 3.738-8.271l3.497-1.32c15.865-5.989 33.581 2.016 39.57 17.88z"
          />
          <path
            d="m94.489 387.771-7.182 7.182c-11.991 11.991-31.432 11.991-43.422 0l-11.681-11.681c-2.506-2.506-2.506-6.57 0-9.076l2.643-2.643c11.991-11.991 31.432-11.991 43.422 0z"
          />
          <path
            d="m158.347 437.266-4.958 8.864c-8.278 14.8-26.986 20.087-41.786 11.81l-14.418-8.064c-3.094-1.73-4.199-5.641-2.469-8.734l1.825-3.263c8.278-14.8 26.986-20.087 41.786-11.81z"
          />
          <path
            d="m188.216 448.961 5.078-8.796c8.479-14.686 3.447-33.464-11.239-41.943l-14.306-8.26c-3.07-1.772-6.995-.721-8.767 2.349l-1.869 3.237c-8.479 14.686-3.447 33.464 11.239 41.943z"
          />
          <path
            d="m368.833 85.945h10.156c16.957 0 30.704-13.747 30.704-30.704v-16.52c0-3.545-2.873-6.418-6.418-6.418h-3.738c-16.957 0-30.704 13.747-30.704 30.704z"
          />
          <path
            d="m399.115 114.498-4.712 8.997c-7.868 15.022-26.424 20.821-41.445 12.953l-14.634-7.665c-3.14-1.645-4.352-5.523-2.707-8.663l1.734-3.312c7.868-15.022 26.424-20.821 41.445-12.953z"
          />
          <path
            d="m423.177 144.467h10.156c16.957 0 30.704-13.747 30.704-30.704v-16.52c0-3.545-2.873-6.418-6.418-6.418h-3.738c-16.957 0-30.704 13.747-30.704 30.704z"
          />
          <path
            d="m453.698 232.304h10.156c16.957 0 30.704-13.747 30.704-30.704v-16.52c0-3.545-2.873-6.418-6.418-6.418h-3.738c-16.957 0-30.704 13.747-30.704 30.704z"
          />
          <path
            d="m452.694 252.882-9.639 3.201c-16.093 5.344-33.472-3.37-38.816-19.463l-5.206-15.678c-1.117-3.364.704-6.997 4.068-8.114l3.548-1.178c16.093-5.344 33.472 3.37 38.816 19.463z"
          />
          <path
            d="m428.323 351.706-10.079-1.252c-16.828-2.091-28.775-17.428-26.683-34.256l2.037-16.394c.437-3.518 3.643-6.015 7.161-5.578l3.71.461c16.828 2.091 28.775 17.428 26.683 34.256z"
          />
          <path
            d="m442.626 321.388 9.502 3.587c15.865 5.989 33.581-2.016 39.57-17.88l5.835-15.455c1.252-3.316-.421-7.019-3.738-8.271l-3.497-1.32c-15.865-5.989-33.581 2.016-39.57 17.88z"
          />
          <path
            d="m403.46 387.771 7.182 7.182c11.991 11.991 31.432 11.991 43.422 0l11.681-11.681c2.506-2.506 2.506-6.57 0-9.076l-2.643-2.643c-11.991-11.991-31.432-11.991-43.422 0z"
          />
          <path
            d="m339.601 437.266 4.958 8.864c8.278 14.8 26.986 20.087 41.786 11.81l14.418-8.064c3.094-1.73 4.199-5.641 2.469-8.734l-1.825-3.263c-8.278-14.8-26.986-20.087-41.786-11.81z"
          />
          <path
            d="m309.732 448.961-5.078-8.796c-8.479-14.686-3.447-33.464 11.239-41.943l14.306-8.26c3.07-1.772 6.995-.721 8.767 2.349l1.869 3.237c8.479 14.686 3.447 33.464-11.239 41.943z"
          />
        </g>
        <path
          d="m261.533 119.589 25.13 50.92c2.04 4.133 5.983 6.998 10.545 7.661l56.193 8.165c11.487 1.669 16.073 15.785 7.762 23.888l-40.662 39.636c-3.301 3.217-4.807 7.853-4.028 12.396l9.599 55.966c1.962 11.44-10.046 20.165-20.32 14.763l-50.261-26.424c-4.08-2.145-8.954-2.145-13.034 0l-50.261 26.424c-10.274 5.401-22.282-3.323-20.32-14.763l9.599-55.966c.779-4.543-.727-9.179-4.028-12.396l-40.662-39.636c-8.312-8.102-3.725-22.218 7.762-23.888l56.193-8.165c4.561-.663 8.505-3.528 10.545-7.661l25.13-50.92c5.138-10.409 19.98-10.409 25.118 0z"
          fill="#fed402"
        />
        <g>
          <path
            d="m230.838 277.296c.779-4.543-.727-9.179-4.028-12.396l-40.662-39.636c-8.312-8.102-3.725-22.218 7.762-23.888l56.193-8.165c4.561-.663 8.505-3.528 10.545-7.661l16.719-33.877-15.835-32.085c-5.137-10.409-19.98-10.409-25.117 0l-25.13 50.92c-2.04 4.133-5.983 6.998-10.545 7.661l-56.193 8.165c-11.487 1.669-16.073 15.785-7.762 23.888l40.662 39.636c3.301 3.217 4.807 7.853 4.028 12.396l-9.599 55.966c-1.962 11.44 10.046 20.165 20.32 14.763l31.974-16.81z"
            fill="#fac600"
          />
        </g>
      </g>
      <g>
        <path
          d="m248.974 465.645c-117.02 0-212.223-95.203-212.223-212.223 0-43.872 13.276-85.976 38.392-121.762 24.548-34.976 58.549-61.47 98.326-76.619 3.874-1.474 8.205.469 9.678 4.339 1.474 3.871-.469 8.204-4.339 9.678-36.966 14.078-68.567 38.705-91.387 71.218-23.335 33.248-35.669 72.373-35.669 113.145 0 108.749 88.474 197.223 197.223 197.223s197.223-88.474 197.223-197.223c0-40.772-12.334-79.896-35.669-113.145-22.82-32.514-54.421-57.14-91.387-71.218-3.871-1.474-5.813-5.807-4.339-9.678s5.807-5.814 9.678-4.339c39.777 15.148 73.778 41.643 98.326 76.619 25.116 35.786 38.392 77.89 38.392 121.762-.002 117.02-95.205 212.223-212.225 212.223z"
          fill="#fed402"
        />
      </g>
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Conversation"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <g transform="translate(-1,0)">
        <path
          fill="var(--color-icon-secondary)"
          d="M28.1,4.2h-11V0l-2.5,1.9C13,3,12,4.7,11.8,6.6c0,0.2,0,0.4-0.1,0.5c0,0.1,0,0.3,0,0.4v6.4c0,1.7,1.3,3,3,3
          h13.4c1.6,0,3-1.3,3-3V7.2C31.1,5.6,29.7,4.2,28.1,4.2z M17.1,11.6c-0.6,0-1.1-0.5-1.1-1c0-0.6,0.5-1.1,1.1-1.1
          c0.6,0,1.1,0.5,1.1,1.1C18.2,11.2,17.7,11.6,17.1,11.6z M21.4,11.6c-0.6,0-1.1-0.5-1.1-1c0-0.6,0.5-1.1,1.1-1.1
          c0.6,0,1.1,0.5,1.1,1.1C22.5,11.2,22,11.6,21.4,11.6z M25.7,11.6c-0.6,0-1.1-0.5-1.1-1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1
          C26.8,11.2,26.3,11.6,25.7,11.6z"
        />
        <path
          fill="var(--color-icon-primary)"
          d="M17.3,13.6H3.9c-1.6,0-3,1.3-3,3v6.7c0,1.7,1.4,3,3,3h11V30l3.2-2.6c1-0.8,1.6-1.8,1.9-2.9c0,0,0,0,0-0.1
          c0.1-0.5,0.2-0.9,0.2-1.4v-6.4C20.3,15,19,13.6,17.3,13.6z M6.3,21c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
          c0.6,0,1.1,0.5,1.1,1.1C7.4,20.5,6.9,21,6.3,21z M10.6,21C10,21,9.5,20.5,9.5,20c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1
          C11.7,20.5,11.2,21,10.6,21z M13.8,20c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
          C14.3,21,13.8,20.5,13.8,20z"
        />
      </g>
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.EmptyState"
      viewBox="0 0 78 78"
      aria-hidden="true"
    >
      <circle cx="39" cy="39" r="39" fill="#A6CDE4" />
      <rect
        x="16"
        y="15"
        width="45"
        height="51"
        rx="3"
        fill="white"
        stroke="#565353"
        stroke-width="2"
      />
      <mask id="path-3-inside-1" fill="white">
        <rect x="24" y="10" width="29" height="11" rx="2" />
      </mask>
      <rect
        x="24"
        y="10"
        width="29"
        height="11"
        rx="2"
        fill="white"
        stroke="#565353"
        stroke-width="6"
        mask="url(#path-3-inside-1)"
      />
      <rect
        x="25"
        y="11"
        width="27"
        height="9"
        rx="1"
        fill="#7DD5BB"
        stroke="#565353"
        stroke-width="2"
      />
      <rect
        x="21"
        y="38"
        width="9"
        height="9"
        rx="1"
        fill="#7DD5BB"
        stroke="#565353"
        stroke-width="2"
      />
      <rect
        x="21"
        y="51"
        width="9"
        height="9"
        rx="1"
        fill="#7DD5BB"
        stroke="#565353"
        stroke-width="2"
      />
      <rect x="20" y="24" width="37" height="11" rx="2" fill="#1AB688" />
      <path
        d="M31 10C31 7.23858 33.2386 5 36 5H41C43.7614 5 46 7.23858 46 10C46 10.5523 45.5523 11 45 11H32C31.4477 11 31 10.5523 31 10Z"
        stroke="#565353"
        stroke-width="2"
      />
      <line
        x1="35"
        y1="42"
        x2="56"
        y2="42"
        stroke="#565353"
        stroke-width="2"
        stroke-linecap="round"
      />
      <line
        x1="35"
        y1="49"
        x2="56"
        y2="49"
        stroke="#565353"
        stroke-width="2"
        stroke-linecap="round"
      />
      <line
        x1="35"
        y1="56"
        x2="56"
        y2="56"
        stroke="#565353"
        stroke-width="2"
        stroke-linecap="round"
      />
      <circle cx="54" cy="55" r="15" fill="#A6CDE4" />
      <path
        d="M62 63L73 74"
        stroke="#565353"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M67 68L72 73"
        stroke="#565353"
        stroke-width="6"
        stroke-linecap="round"
      />
      <circle
        cx="54"
        cy="55"
        r="12"
        fill="white"
        stroke="#565353"
        stroke-width="2"
      />
      <path
        d="M54 49V55.5L58 59.5"
        stroke="#565353"
        stroke-width="2"
        stroke-linecap="round"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.ExpandArrows"
      viewBox="0 0 18 18"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path
        d="M13.0001 7.33992C13.0001 8.06828 13.0001 8.80858 13.0001 9.53694C13.0001 9.65634 12.9762 9.66828 12.8686 9.66828C12.6175
        9.66828 12.3783 9.66828 12.1272 9.66828C12.0196 9.66828 11.9956 9.6444 11.9956 9.53694C11.9956 8.70112 11.9956 7.8653 11.9956
        7.02948C11.9956 7.00559 11.9956 6.99365 11.9956 6.96977C11.9956 6.92201 11.9956 6.86231 11.9358 6.83843C11.8761 6.81455
        11.8521 6.85037 11.8163 6.88619C10.178 8.54589 8.52777 10.1817 6.88951 11.8175C6.85363 11.8534 6.82972 11.8772 6.85363
        11.9369C6.87755 11.9847 6.92538 11.9966 6.97322 11.9966C7.45154 11.9966 7.92987 11.9966 8.40819 11.9966C8.79085 11.9966
        9.17351 11.9966 9.55617 11.9966C9.65184 11.9966 9.67576 12.0205 9.67576 12.116C9.67576 12.3668 9.67576 12.6175 9.67576
        12.8802C9.67576 12.9877 9.65184 12.9996 9.55617 12.9996C8.08532 12.9996 6.61447 12.9996 5.15558 12.9996C5.13166 12.9996
        5.10774 12.9996 5.08383 12.9996C5.04795 12.9877 5.01208 12.9638 5.01208 12.928C5.01208 12.9041 5.01208 12.8922 5.01208
        12.8683C5.01208 11.3996 5.01208 9.94291 5.01208 8.47425C5.00012 8.36679 5.02404 8.34291 5.13166 8.34291C5.38278 8.34291
        5.6339 8.34291 5.87307 8.34291C5.98069 8.34291 6.00461 8.36679 6.00461 8.47425C6.00461 9.33395 6.00461 10.1817 6.00461
        11.0414C6.00461 11.0653 6.00461 11.0772 6.00461 11.1011C6.01656 11.1608 6.08831 11.1966 6.13615 11.1608C6.16006 11.1489
        6.17202 11.1369 6.18398 11.1131C7.82224 9.47724 9.44855 7.84141 11.0868 6.2056C11.0988 6.19366 11.1107 6.18171 11.1227
        6.16977C11.1347 6.14589 11.1466 6.11007 11.1347 6.08619C11.1227 6.06231 11.0868 6.03843 11.0629 6.02649C11.0509 6.01455
        11.027 6.02649 11.0031 6.02649C10.1541 6.02649 9.2931 6.02649 8.44407 6.02649C8.33644 6.02649 8.31253 6.00261 8.31253
        5.89515C8.31253 5.6444 8.31253 5.39366 8.31253 5.14291C8.31253 5.03545 8.33644 5.01157 8.43211 5.01157C9.91492 5.01157
        11.3858 5.01157 12.8686 5.01157C12.9642 5.01157 12.9882 5.03545 12.9882 5.14291C13.0001 5.85933 13.0001 6.59962 13.0001 7.33992Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.External"
      viewBox="0 0 24 24"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path
        d="M3 3H8.41176V5H5V18H18V14.5882H20V20H3V3ZM16.5858 5H12V3H20V11H18V6.41421L11.7071 12.7071L10.2929 11.2929L16.5858 5Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Hourglass"
      viewBox="0 0 54 54"
      aria-hidden="true"
    >
      <path
        fill="#7DD5BB"
        d="M25.4394 25.2378L26.9999 26.7983L28.5607 25.2378C29.0818 24.7167 29.5444 24.1993 29.9551 23.6769H24.0447C24.4557
        24.199 24.918 24.7164 25.4394 25.2378Z"
      />
      <path
        fill="#7DD5BB"
        d="M32.8153 39.4616C32.8153 38.3467 32.7419 37.3364 32.5917 36.386L26.9996 32.1923L21.4082 36.3857C21.258 37.3358
        21.1846 38.3457 21.1846 39.4616H32.8153Z"
      />
      <path
        fill="#565353"
        d="M35.3077 14.5385V13.7077H36.9692V12.0461H17.0308V13.7077H18.6923V14.5385C18.6923 20.3538 20.5436 23.866 23.6772
        27C23.6769 27 23.6769 27.0003 23.6772 27.0003C23.6772 27.0003 23.6772 27.0003 23.6769 27.0003C20.5213 30.1559 18.6926
        33.6461 18.6926 39.4615V40.2923H17.0311V41.9538H36.9696V40.2923H35.3084V39.4622C35.308 33.6461 33.4571 30.134 30.3231
        27C30.3234 27 30.3234 27 30.3234 27C33.479 23.8441 35.3077 20.3538 35.3077 14.5385ZM29.1484 28.175C31.4941 30.5208
        32.8167 32.9094 33.356 35.9208C33.5511 37.0108 33.6465 38.1805 33.6465 39.4619V40.2923H20.3538V39.4615C20.3538 38.1801
        20.4492 37.0104 20.6443 35.9205C21.1836 32.9091 22.5062 30.5208 24.8516 28.1754L26.027 27L24.852 25.8249C21.6571 22.6298
        20.3538 19.3599 20.3538 14.5385V13.7077H33.6461V14.5385C33.6461 19.3606 32.3428 22.6305 29.1484 25.8253L27.9733 27L29.1484 28.175Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Info"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <circle cx="15" cy="15" r="14" fill="var(--color-icon-primary)" />
      <circle cx="15" cy="15" r="12" fill="var(--color-icon-secondary)" />
      <rect
        x="14"
        y="14"
        width="2"
        height="7"
        fill="var(--color-icon-primary)"
      />
      <rect
        x="14"
        y="10"
        width="2"
        height="2"
        fill="var(--color-icon-primary)"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Like"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fill="var(--color-icon-secondary)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 22H5V10H1V22ZM23 11C23 9.9 22.1 9 21 9H14.69L15.64 4.43L15.67 4.11C15.67 3.7 15.5 3.32 15.23 3.05L14.17
        2L7.59 8.59C7.22 8.95 7 9.45 7 10V20C7 21.1 7.9 22 9 22H18C18.83 22 19.54 21.5 19.84 20.78L22.86 13.73C22.95 13.5
        23 13.26 23 13V11.09L22.99 11.08L23 11Z"
      />
      <path
        fill="var(--color-icon-primary)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 22H5V10H1V22ZM7.59 8.59C7.22 8.95 7 9.45 7 10V20C7 21.1 7.9 22 9 22H18C18.83 22 19.54 21.5 19.84 20.78L22.86
        13.73C22.95 13.5 23 13.26 23 13V11.09L22.99 11.08L23 11C23 9.9 22.1 9 21 9H14.69L15.64 4.43L15.67 4.11C15.67 3.88374
        15.6182 3.66661 15.5264 3.47207C15.471 3.35448 15.4009 3.24514 15.3188 3.14702C15.2906 3.11332 15.2609 3.08094 15.23
        3.05L14.17 2L7.59 8.59ZM13.3415 5.66036L9 10.0084V20H17.9984L21 12.9928V11.9184L20.8841 11.8025L20.9844 11H12.2315L13.3415 5.66036Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Logout"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <path fill="var(--color-icon-secondary)" d="M5 6H10V8H7V22H10V24H5V6Z" />
      <path
        fill="var(--color-icon-primary)"
        d="M25 14V16H13L17 20L16 22L9 15L16 8L17 10L13 14H25Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Megaphone"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <path
        fill="var(--color-icon-secondary)"
        d="M18.3,22.6c-1.6,0.9-3.7,0.4-4.6-1.2c-0.2-0.4-0.4-0.9-0.4-1.3l-3.4,2l-0.8-1.3c-0.2,0.3-0.4,0.5-0.8,0.7
        c-0.3,0.2-0.7,0.3-1.1,0.3c-0.8,0-1.5-0.4-2-1.1l-1.1-2c-0.6-1.1-0.2-2.5,0.8-3.1c0.3-0.2,0.6-0.3,1-0.3l-0.8-1.3l9.1-5.2l4.7,8.1
        l-0.2,0.1c0.4,0.3,0.7,0.6,0.9,1C20.5,19.6,20,21.7,18.3,22.6z"
      />
      <path
        fill="var(--color-icon-secondary)"
        d="M23.1,8.4c-0.5-0.8-0.2-1.9,0.6-2.3c0.8-0.5,1.9-0.2,2.3,0.6l0.6,1c0.5,0.8,0.2,1.9-0.6,2.3	c-0.8,0.5-1.9,0.2-2.3-0.6L23.1,8.4z"
      />
      <path
        fill="#ffffff"
        d="M14.4,9.5l4.3-6.2l0.8,0.5c3.9,2.2,6.1,6.4,6,10.9l-0.1,2l-7-0.3L14.4,9.5z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M20,2.9L18.4,2l-3.7,5.4l0-0.1L3.8,13.6l5.7,9.8l1-0.6c0,0,0,0,0,0l3,5.1c0.3,0.5,0.9,0.6,1.4,0.4l3.4-2
        c0.4-0.2,0.6-0.7,0.5-1.1l-1.5-6c0-0.1-0.1-0.2-0.1-0.3l2.8-1.6l6.5,0.3l0.1-3C26.7,9.9,24.2,5.3,20,2.9z M10.2,20.7l-3.7-6.3
        l7.1-4.1l3.7,6.3L10.2,20.7z M24.4,15.6l-5.1-0.2l-3.5-6.1L19,4.6l0,0c3.6,2.1,5.6,5.9,5.5,10L24.4,15.6z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Menu"
      viewBox="0 0 30 30"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <g transform="translate(5, 5)">
        <rect x="1.01947" y="6" width="18.05" height="2" />
        <rect x="1.01947" y="12" width="12.0333" height="2" />
      </g>
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Newspaper"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <rect fill="#ffffff" x="7" y="7" width="19" height="17" />
      <path
        fill="var(--color-icon-primary)"
        d="M5,5v4H1v14c0,1.3,0.8,2.4,2,2.8C3.2,25.9,3.5,26,3.8,26c0.1,0,0.1,0,0.2,0h21c1.7,0,3-1.3,3-3V5H5z M26,23 c0,0.5-0.5,1-1,1H7V7h19V23z"
      />
      <rect
        fill="var(--color-icon-secondary)"
        x="9"
        y="9"
        class="st2"
        width="15"
        height="6"
      />
      <rect
        fill="var(--color-icon-secondary)"
        x="9"
        y="17"
        class="st2"
        width="15"
        height="2"
      />
      <rect
        fill="var(--color-icon-secondary)"
        x="9"
        y="20"
        class="st2"
        width="15"
        height="2"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.PersonWQuestionMark"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <circle fill="var(--color-icon-primary)" cx="8" cy="12" r="5" />
      <circle fill="var(--color-icon-secondary)" cx="8" cy="12" r="3" />
      <path
        fill="var(--color-icon-primary)"
        d="M8 20C5.94968 20 4.1876 21.2341 3.41604 23H12.584C11.8124 21.2341 10.0503 20 8 20ZM14.7101 23C13.8496 20.1085
        11.171 18 8 18C4.82898 18 2.15043 20.1085 1.28988 23C1.10128 23.6337 1 24.305 1 25H15C15 24.305 14.8987 23.6337 14.7101 23Z"
      />
      <path
        fill="#ffffff"
        d="M16 7C16 5.34315 17.3431 4 19 4H25C26.6569 4 28 5.34315 28 7V19C28 20.6569 26.6569 22 25 22H19C17.3431 22 16 20.6569 16 19V7Z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M16 7C16 5.34315 17.3431 4 19 4H25C26.6569 4 28 5.34315 28 7V19C28 20.6569 26.6569 22 25 22H19C17.3431 22 16
        20.6569 16 19V7ZM21.3931 13.9976C21.3823 14.1694 21.377 14.3789 21.377 14.626H22.7197C22.7197 14.4827 22.7233 14.361
        22.7305 14.2607C22.7376 14.1569 22.7537 14.062 22.7788 13.9761C22.8075 13.8901 22.8522 13.806 22.9131 13.7236C22.974
        13.6413 23.0581 13.5464 23.1655 13.439C23.341 13.285 23.5146 13.1274 23.6865 12.9663C23.862 12.8016 24.0177 12.6297
        24.1538 12.4507C24.2899 12.2681 24.4009 12.0729 24.4868 11.8652C24.5728 11.654 24.6157 11.4212 24.6157 11.167C24.6157
        10.8232 24.5584 10.5207 24.4438 10.2593C24.3293 9.99788 24.1628 9.77946 23.9443 9.604C23.7295 9.42497 23.4645 9.29069
        23.1494 9.20117C22.8343 9.11165 22.478 9.06689 22.0806 9.06689C21.7261 9.06689 21.3966 9.11165 21.0923 9.20117C20.7879
        9.28711 20.5229 9.4196 20.2974 9.59863C20.0718 9.77767 19.8927 10.0033 19.7603 10.2754C19.6278 10.5439 19.5597 10.8608
        19.5562 11.2261H21.0547C21.0583 11.0614 21.0887 10.9199 21.146 10.8018C21.2069 10.6836 21.2839 10.5869 21.377
        10.5117C21.4736 10.4365 21.5828 10.3828 21.7046 10.3506C21.8263 10.3148 21.9517 10.2969 22.0806 10.2969C22.4172
        10.2969 22.6732 10.3721 22.8486 10.5225C23.0241 10.6729 23.1118 10.911 23.1118 11.2368C23.1118 11.3729 23.0814 11.509
        23.0205 11.645C22.9596 11.7811 22.8791 11.9154 22.7788 12.0479C22.6821 12.1768 22.5729 12.3039 22.4512 12.4292C22.3294
        12.5509 22.2077 12.6673 22.0859 12.7783C21.9176 12.9108 21.7869 13.0361 21.6938 13.1543C21.6007 13.2689 21.5309
        13.396 21.4844 13.5356C21.4378 13.6717 21.4074 13.8257 21.3931 13.9976ZM21.2158 15.9258C21.1764 16.026 21.1567
        16.1353 21.1567 16.2534C21.1567 16.368 21.1764 16.4754 21.2158 16.5757C21.2552 16.6759 21.3125 16.7619 21.3877
        16.8335C21.4629 16.9051 21.5542 16.9606 21.6616 17C21.769 17.043 21.8908 17.0645 22.0269 17.0645C22.1593
        17.0645 22.2793 17.043 22.3867 17C22.4977 16.9606 22.5908 16.9051 22.666 16.8335C22.7412 16.7619 22.7985
        16.6759 22.8379 16.5757C22.8809 16.4754 22.9023 16.368 22.9023 16.2534C22.9023 16.1353 22.8809 16.026 22.8379
        15.9258C22.7985 15.8219 22.7412 15.7324 22.666 15.6572C22.5908 15.582 22.4977 15.5247 22.3867 15.4854C22.2793
        15.4424 22.1593 15.4209 22.0269 15.4209C21.8908 15.4209 21.769 15.4424 21.6616 15.4854C21.5542 15.5247 21.4629
        15.582 21.3877 15.6572C21.3125 15.7324 21.2552 15.8219 21.2158 15.9258Z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M12 17L15.2322 20.2322C16.3641 21.3641 17.8993 22 19.5 22L17.5 20L17 17H12Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.PersonWStar"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <circle fill="var(--color-icon-primary)" cx="8" cy="14" r="5" />
      <circle fill="var(--color-icon-secondary)" cx="8" cy="14" r="3" />
      <path
        fill="var(--color-icon-primary)"
        d="M8 20C4.13401 20 1 23.134 1 27H15C15 23.134 11.866 20 8 20Z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M19.6039 1.8097C19.9711 1.06805 21.0289 1.06805 21.3961 1.8097L23.065 5.17988C23.2107 5.47398 23.4913 5.67791
        23.816 5.72553L27.537 6.27128C28.3558 6.39138 28.6827 7.39739 28.0908 7.97585L25.4013 10.6045C25.1666 10.8339
        25.0594 11.1638 25.1145 11.4874L25.7452 15.1948C25.8841 16.0107 25.0283 16.6325 24.2952 16.2483L20.964
        14.5027C20.6735 14.3504 20.3265 14.3504 20.0358 14.5027L16.7048 16.2483C15.9717 16.6325 15.1159 16.0107 15.2548
        15.1948L15.8856 11.4874C15.9406 11.1638 15.8334 10.8339 15.5987 10.6045L12.9092 7.97585C12.3173 7.39739 12.6442
        6.39138 13.463 6.27128L17.184 5.72553C17.5087 5.67791 17.7893 5.47398 17.935 5.17988L19.6039 1.8097Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Person"
      fill="var(--color-icon-primary)"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <circle cx="15.1923" cy="9.46154" r="6.46154" />
      <path
        d="M15.1923 17.7692C10.6041 17.7692 6.88461 21.902 6.88461 27H23.5C23.5 21.902 19.7805 17.7692 15.1923 17.7692Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.PinWMap"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <path
        fill="var(--color-icon-secondary)"
        d="M28,28H2c-0.8,0-1.3-0.9-0.8-1.6l4.5-6C5.9,20.1,6.2,20,6.5,20h3.9c1,1.2,1.9,2.2,2.6,2.8
        c1.1,1.1,3,1.1,4.1-0.1c0.5-0.6,1.4-1.5,2.3-2.7h4.1c0.3,0,0.6,0.1,0.8,0.4l4.5,6C29.3,27.1,28.8,28,28,28z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M15,2c-3.9,0-7,3.3-7,7.3c0,5.4,4.6,10.4,6.3,12.1c0.4,0.3,1,0.3,1.3,0c1.7-1.8,6.4-7.1,6.4-12.1
        C22,5.3,18.9,2,15,2z M15,13c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S17.2,13,15,13z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.PwaNotification"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <path
        fill="var(--color-icon-secondary)"
        d="M24,7v3.2c0,0.1,0,0.2-0.1,0.4c-0.1,0.3-0.4,0.4-0.7,0.4l-3.4,0v14.3c0,0.2,0,0.4-0.2,0.5
        c-0.2,0.2-0.6,0.3-0.8,0.2c-0.3-0.2-0.6-0.4-0.9-0.6c-1.7-1.1-3.3-2.2-4.9-3.3c-0.1,0-0.1,0-0.1,0c-1.9,1.3-3.8,2.5-5.7,3.8
        C6.9,26,6.7,26,6.5,26C6.2,25.9,6,25.6,6,25.3V6.8c0-0.6,0.1-1.1,0.5-1.6c0.3-0.5,0.7-0.8,1.3-1C8.1,4.1,8.5,4,8.8,4h12.3
        c0.7,0,1.3,0.2,1.8,0.6c0.5,0.4,0.9,1,1,1.7C24,6.5,24,6.8,24,7z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M12.2,13.3C12.3,13.3,12.3,13.3,12.2,13.3c1.2-1.3,2.4-2.4,3.6-3.6c0.2-0.2,0.4-0.3,0.7-0.2
        c0.4,0.1,0.6,0.6,0.4,1c0,0.1-0.1,0.2-0.2,0.2c-1.4,1.4-2.7,2.7-4.1,4.1c-0.3,0.3-0.7,0.3-1,0c-0.7-0.7-1.3-1.3-2-2
        c-0.3-0.3-0.3-0.6-0.1-0.9c0.3-0.3,0.7-0.4,1.1-0.1C11.2,12.3,11.6,12.8,12.2,13.3C12.2,13.3,12.2,13.3,12.2,13.3z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M22.6,9.5c-0.9,0-1.8,0-2.8,0c0,0,0-0.1,0-0.1c0-0.9,0-1.7,0-2.6c0-0.6,0.3-1,0.8-1.3c0.5-0.2,1.1-0.2,1.5,0.2
        c0.3,0.3,0.5,0.6,0.5,1C22.6,7.6,22.6,8.5,22.6,9.5C22.6,9.5,22.6,9.5,22.6,9.5z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.QRCode"
      viewBox="0 0 30 30"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <rect x="2" y="14" width="2" height="2" rx="1" />
      <rect x="5" y="14" width="2" height="2" rx="1" />
      <rect x="11" y="14" width="2" height="2" rx="1" />
      <rect x="14" y="14" width="2" height="2" rx="1" />
      <rect x="20" y="14" width="2" height="2" rx="1" />
      <rect x="23" y="14" width="2" height="2" rx="1" />
      <rect x="14" y="17" width="2" height="2" rx="1" />
      <rect x="17" y="17" width="2" height="2" rx="1" />
      <rect x="23" y="17" width="2" height="2" rx="1" />
      <rect x="26" y="17" width="2" height="2" rx="1" />
      <rect x="14" y="23" width="2" height="2" rx="1" />
      <rect x="20" y="23" width="2" height="2" rx="1" />
      <rect x="26" y="23" width="2" height="2" rx="1" />
      <rect x="17" y="20" width="2" height="2" rx="1" />
      <rect x="20" y="20" width="2" height="2" rx="1" />
      <rect x="23" y="20" width="2" height="2" rx="1" />
      <rect x="14" y="26" width="2" height="2" rx="1" />
      <rect x="17" y="26" width="2" height="2" rx="1" />
      <rect x="20" y="26" width="2" height="2" rx="1" />
      <rect x="23" y="26" width="2" height="2" rx="1" />
      <rect x="14" y="11" width="2" height="2" rx="1" />
      <rect x="14" y="8" width="2" height="2" rx="1" />
      <rect x="14" y="2" width="2" height="2" rx="1" />
      <rect x="6" y="21" width="3" height="3" rx="1.5" />
      <rect x="21" y="6" width="3" height="3" rx="1.5" />
      <path
        d="M11,13H4c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h7c1.1,0,2,0.9,2,2v7C13,12.1,12.1,13,11,13z M4,4L4,4v7h7V4H4z"
      />
      <path
        d="M11,28H4c-1.1,0-2-0.9-2-2v-7c0-1.1,0.9-2,2-2h7c1.1,0,2,0.9,2,2v7C13,27.1,12.1,28,11,28z M4,19L4,19v7h7v-7 H4z"
      />
      <path
        d="M26,13h-7c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h7c1.1,0,2,0.9,2,2v7C28,12.1,27.1,13,26,13z M19,4L19,4v7h7V4	H19z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Settings"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <path
        fill="var(--color-icon-secondary)"
        d="M12 22H3C2.44772 22 2 22.4477 2 23C2 23.5523 2.44772 24 3 24H12V22ZM17 24H27C27.5523 24 28 23.5523 28 23C28 22.4477 27.5523 22 27 22H17V24Z"
      />
      <path
        fill="var(--color-icon-secondary)"
        d="M5 14H3C2.44772 14 2 14.4477 2 15C2 15.5523 2.44772 16 3 16H5V14ZM10 16H27C27.5523 16 28 15.5523 28 15C28 14.4477 27.5523 14 27 14H10V16Z"
      />
      <path
        fill="var(--color-icon-secondary)"
        d="M20 6H3C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H20V6ZM25 8H27C27.5523 8 28 7.55228 28 7C28 6.44772 27.5523 6 27 6H25V8Z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M16 24.5V21.5C16 20.6716 15.3284 20 14.5 20C13.6716 20 13 20.6716 13 21.5V24.5C13 25.3284 13.6716 26 14.5 26C15.3284 26 16 25.3284 16 24.5Z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M9 16.5V13.5C9 12.6716 8.32843 12 7.5 12C6.67157 12 6 12.6716 6 13.5V16.5C6 17.3284 6.67157 18 7.5 18C8.32843 18 9 17.3284 9 16.5Z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M24 8.5V5.5C24 4.67157 23.3284 4 22.5 4C21.6716 4 21 4.67157 21 5.5V8.5C21 9.32843 21.6716 10 22.5 10C23.3284 10 24 9.32843 24 8.5Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.SiteAverage"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <circle
        fill="var(--color-icon-primary)"
        cx="21.5455"
        cy="8.45455"
        r="5.45455"
      />
      <circle
        fill="var(--color-icon-primary)"
        cx="21.5455"
        cy="21.5455"
        r="5.45455"
      />
      <circle
        fill="var(--color-icon-primary)"
        cx="8.45455"
        cy="8.45455"
        r="5.45455"
      />
      <circle
        fill="var(--color-icon-primary)"
        cx="8.45455"
        cy="21.5455"
        r="5.45455"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Star"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <path
        fill="var(--color-icon-secondary)"
        d="M21.6,17.1c-0.3,0.3-0.4,0.7-0.3,1l0.9,5.5c0.2,1-0.8,1.7-1.7,1.2l-5-2.6c-0.3-0.2-0.8-0.2-1.1,0l-5,2.6
        c-0.9,0.5-1.9-0.3-1.7-1.2l0.9-5.5c0.1-0.4-0.1-0.8-0.3-1l-4-3.9c-0.7-0.7-0.3-1.9,0.7-2l5.5-0.8c0.4-0.1,0.7-0.3,0.9-0.6l2.5-5
        c0.4-0.9,1.7-0.9,2.1,0l2.5,5c0.2,0.3,0.5,0.6,0.9,0.6l5.5,0.8c1,0.1,1.4,1.3,0.7,2L21.6,17.1z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M25.6,13.1c0.7-0.7,0.3-1.9-0.7-2l-5.5-0.8c-0.4-0.1-0.7-0.3-0.9-0.6l-2.5-5c-0.4-0.9-1.7-0.9-2.1,0l-2.5,5
        c-0.2,0.3-0.5,0.6-0.9,0.6L5,11.1c-1,0.1-1.4,1.3-0.7,2l4,3.9c0.3,0.3,0.4,0.7,0.3,1l-0.9,5.5c-0.2,1,0.8,1.7,1.7,1.2l5-2.6
        c0.3-0.2,0.8-0.2,1.1,0l5,2.6c0.9,0.5,1.9-0.3,1.7-1.2l-0.9-5.5c-0.1-0.4,0.1-0.8,0.3-1L25.6,13.1z M20.2,15
        c-0.7,0.7-1.1,1.8-0.9,2.8l0.7,3.9l-3.5-1.8c-0.9-0.5-2-0.5-3,0L10,22.3l0.7-3.9c0.2-1-0.2-2.1-0.9-2.8l-2.8-2.8l3.9-0.6
        c1-0.1,1.9-0.8,2.4-1.7L15,7l1.8,3.5c0.5,0.9,1.3,1.6,2.4,1.7l3.9,0.6L20.2,15.6z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Survey"
      viewBox="0 0 66 66"
      aria-hidden="true"
    >
      <circle cx="33" cy="33" r="33" fill="#a6cde4" />
      <rect
        x="13.69"
        y="12.85"
        width="37.77"
        height="42.85"
        rx="3"
        fill="#fff"
      />
      <path
        fill="#565353"
        d="M48.46,56.69H16.69a4,4,0,0,1-4-4V15.85a4,4,0,0,1,4-4H48.46a4,4,0,0,1,4,4V52.69A4,4,0,0,1,48.46,56.69ZM16.69,13.85a2,2,0,0,0-2,2V52.69a2,2,0,0,0,2,2H48.46a2,2,0,0,0,2-2V15.85a2,2,0,0,0-2-2Z"
      />
      <rect x="20.31" y="8.46" width="24.54" height="9.31" rx="2" fill="#fff" />
      <rect
        x="21.31"
        y="9.46"
        width="22.54"
        height="7.31"
        rx="1"
        fill="#7dd5bb"
      />
      <path
        fill="#565353"
        d="M42.85,17.77H22.31a2,2,0,0,1-2-2V10.46a2,2,0,0,1,2-2H42.85a2,2,0,0,1,2,2v5.31A2,2,0,0,1,42.85,17.77ZM22.31,10.46h0v5.31H42.85V10.46Z"
      />
      <rect
        x="17.92"
        y="32.31"
        width="7.31"
        height="7.31"
        rx="1"
        fill="#7dd5bb"
      />
      <path
        fill="#565353"
        d="M24.23,40.62H18.92a2,2,0,0,1-2-2V33.31a2,2,0,0,1,2-2h5.31a2,2,0,0,1,2,2v5.31A2,2,0,0,1,24.23,40.62Zm-5.31-7.31h0v5.31h5.31V33.31Z"
      />
      <rect
        x="17.92"
        y="43.31"
        width="7.31"
        height="7.31"
        rx="1"
        fill="#7dd5bb"
      />
      <path
        fill="#565353"
        d="M24.23,51.62H18.92a2,2,0,0,1-2-2V44.31a2,2,0,0,1,2-2h5.31a2,2,0,0,1,2,2v5.31A2,2,0,0,1,24.23,51.62Zm-5.31-7.31h0v5.31h5.31V44.31Z"
      />
      <rect
        x="16.92"
        y="20.31"
        width="31.31"
        height="9.31"
        rx="2"
        fill="#1ab688"
      />
      <path
        fill="#565353"
        d="M38.08,10.15h-11a1.7,1.7,0,0,1-1.7-1.69,5.09,5.09,0,0,1,5.08-5.08h4.23a5.09,5.09,0,0,1,5.08,5.08A1.69,1.69,0,0,1,38.08,10.15Zm-10.68-2H37.75a3.07,3.07,0,0,0-3.06-2.77H30.46A3.08,3.08,0,0,0,27.4,8.15Z"
      />
      <path
        fill="#565353"
        d="M47.23,36.38H29.77a1,1,0,0,1,0-2H47.23a1,1,0,0,1,0,2Z"
      />
      <path
        fill="#565353"
        d="M47.23,42.31H29.77a1,1,0,1,1,0-2H47.23a1,1,0,0,1,0,2Z"
      />
      <path
        fill="#565353"
        d="M47.23,48.23H29.77a1,1,0,0,1,0-2H47.23a1,1,0,0,1,0,2Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.TriangleTick"
      fill="var(--color-icon-primary)"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <g transform="translate(-0.5,1)">
        <path
          d="M21.6,15.7L18.3,10l-0.7,0.8l3.2,5.5c0.2,0.3,0.2,0.7,0,1c-0.2,0.3-0.5,0.5-0.9,0.5H5.1
          c-0.4,0-0.7-0.2-0.9-0.5c-0.2-0.3-0.2-0.7,0-1l7.4-12.8C11.8,3.2,12.1,3,12.5,3s0.7,0.2,0.9,0.5L16,8.1l0.7-0.8L14.2,3
          c-0.4-0.6-1-1-1.7-1s-1.4,0.4-1.7,1L3.4,15.7c-0.4,0.6-0.4,1.4,0,2c0.4,0.6,1,1,1.7,1h14.7c0.7,0,1.4-0.4,1.7-1
          C22,17.1,22,16.4,21.6,15.7z"
        />
        <path
          d="M12.6,13.3l-2.2-2.4c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7l2.9,3.2L17,9.9l0.7-0.8l1.6-1.8
          c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0l-1.4,1.5L16.5,9L12.6,13.3z"
        />
      </g>
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Truck"
      fill="var(--color-icon-primary)"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <g transform="translate(0,4)">
        <path
          d="M20.7,3H17V0H0v13h2.6v0.1c0,1.6,1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9c0,0,0-0.1,0-0.1H16v0.1
          c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9V13H24V6.7L20.7,3z M7.3,13.1c0,1-0.9,1.9-1.9,1.9s-1.9-0.8-1.9-1.9c0,0,0-0.1,0-0.1
          c0-0.4,0.1-0.7,0.4-1c0.3-0.5,0.9-0.8,1.5-0.8S6.6,11.5,7,12c0.2,0.3,0.3,0.6,0.4,1C7.3,13,7.3,13.1,7.3,13.1z M8.1,12
          c-0.4-1-1.5-1.8-2.7-1.8S3.2,11,2.8,12H1V1h15v2v9H8.1z M20.8,13.1c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9c0,0,0-0.1,0-0.1
          c0-0.4,0.1-0.7,0.4-1c0.3-0.5,0.9-0.8,1.5-0.8s1.2,0.3,1.5,0.8c0.2,0.3,0.3,0.6,0.4,1C20.8,13,20.8,13.1,20.8,13.1z M23,12h-1.5
          c-0.4-1-1.5-1.8-2.6-1.8c-0.7,0-1.4,0.3-1.9,0.7V9h6V12z M23,8h-6V4h3.3L23,7.1V8z"
        />
        <rect x="12" y="2.8" width="1" height="6.6" />
        <rect x="8" y="2.8" width="1" height="6.6" />
        <rect x="3.7" y="2.8" width="1" height="6.6" />
      </g>
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Warning"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <path
        fill="var(--color-icon-primary)"
        d="M13.2802 3.89679C14.0552 2.59144 15.9448 2.59144 16.7198 3.89679L28.1969 23.229C28.9884 24.5622 28.0275 26.25
        26.4771 26.25H3.52286C1.97244 26.25 1.01162 24.5622 1.8031 23.229L13.2802 3.89679Z"
      />
      <rect
        fill="#ffffff"
        x="16"
        y="17"
        width="2"
        height="7"
        transform="rotate(-180 16 17)"
      />
      <rect
        fill="#ffffff"
        x="16"
        y="21"
        width="2"
        height="2"
        transform="rotate(-180 16 21)"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Location"
      fill="var(--color-icon-primary)"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3377 21.3808C9.59231 19.6747 5 14.6725 5 9.27273C5 5.25611 8.13401 2 12 2C15.866 2 19 5.25611 19 9.27273C19
        14.2054 14.3631 19.5395 12.6371 21.3541C12.2821 21.7274 11.706 21.7409 11.3377 21.3808ZM12 13C9.79086 13 8 11.2091
        8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9C16 11.2091 14.2091 13 12 13Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Email"
      fill="var(--color-icon-primary)"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.5 7.35714L21.5 16.6429C21.5 17.1354 21.3084 17.6078 20.9675 17.9561C20.6265 18.3043 20.164 18.5 19.6818 18.5L5.31818 18.5C4.83597 18.5 4.37351 18.3043 4.03253 17.9561C3.69156 17.6078 3.5 17.1354 3.5 16.6429L3.5 7.35714C3.5 6.8646 3.69156 6.39223 4.03253 6.04394C4.37351 5.69566 4.83597 5.5 5.31818 5.5L19.6818 5.5C20.6909 5.5 21.5 6.32643 21.5 7.35714Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 12.895L6 9V9.93616L12.5 14L19 9.93616V9L12.5 12.895Z"
        fill="white"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Phone"
      fill="var(--color-icon-primary)"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.4867 15.7194L16.5491 14.0319C16.3809 13.9602 16.1939 13.9451 16.0164 13.9889C15.8389 14.0326 15.6804 14.1329 15.5647 14.2745L13.8209 16.405C11.0842 15.1147 8.88181 12.9123 7.59149 10.1756L9.72199 8.43177C9.86391 8.31634 9.96436 8.15782 10.0081 7.9802C10.0519 7.80258 10.0366 7.61553 9.96457 7.44738L8.27705 3.50981C8.19798 3.32854 8.05815 3.18054 7.88165 3.09133C7.70516 3.00213 7.50307 2.9773 7.31023 3.02113L3.65392 3.86489C3.468 3.90782 3.30212 4.01251 3.18336 4.16186C3.06459 4.3112 2.99996 4.4964 3 4.68721C3 13.7049 10.3091 21 19.3128 21C19.5037 21.0001 19.6889 20.9355 19.8384 20.8168C19.9878 20.698 20.0925 20.5321 20.1355 20.3461L20.9792 16.6898C21.0228 16.496 20.9974 16.2931 20.9075 16.116C20.8176 15.9389 20.6688 15.7987 20.4867 15.7194Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Boxes"
      viewBox="0 0 24 24"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 19H1V20H23V19ZM0 18V21H24V18H0Z"
      />
      <path d="M3 19H4V20H3V19Z" />
      <path d="M20 19H21V20H20V19Z" />
      <path d="M9 19H10V20H9V19Z" />
      <path d="M14 19H15V20H14V19Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 12H3V18H10V12ZM2 11V19H11V11H2Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 2H3V8H10V2ZM2 1V9H11V1H2Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 12H14V18H21V12ZM13 11V19H22V11H13Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 2H14V8H21V2ZM13 1V9H22V1H13Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 12H5V14H8V12ZM4 11V15H9V11H4Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 2H5V4H8V2ZM4 1V5H9V1H4Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 12H16V14H19V12ZM15 11V15H20V11H15Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 2H16V4H19V2ZM15 1V5H20V1H15Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Shift"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <circle fill="var(--color-icon-primary)" cx="8" cy="14" r="5" />
      <circle fill="var(--color-icon-secondary)" cx="8" cy="14" r="3" />
      <path
        fill="var(--color-icon-primary)"
        d="M8 20C4.13401 20 1 23.134 1 27H15C15 23.134 11.866 20 8 20Z"
      />
      <rect
        fill="var(--color-icon-primary)"
        x="20"
        y="2"
        width="4"
        height="12"
      />
      <rect
        fill="var(--color-icon-primary)"
        x="28"
        y="6"
        width="4"
        height="12"
        transform="rotate(90 28 6)"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.MessageBubble"
      viewBox="0 0 24 24"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path
        d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM9 11H7V9H9V11ZM13 11H11V9H13V11ZM17 11H15V9H17V11Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.HalfBanknote"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fill="var(--color-icon-secondary)"
        d="M3,8c0-1.1,0.9-2,2-2h16v13H5c-1.1,0-2-0.9-2-2V8z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M9,8c0,2.8-2,5-4.5,5c-0.5,0-1-0.1-1.5-0.3V8c0-1.1,0.9-2,2-2h3.6C8.9,6.6,9,7.3,9,8z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M16.8,13.7c0.1,0.1,0.1,0.1,0.1,0.2l0,0c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.6,0.2
        c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.3-0.1-0.5v-0.2h-1.9v0.2
        c0,0.4,0.1,0.8,0.2,1.1l0,0c0.1,0.3,0.3,0.6,0.5,0.8l0,0c0.2,0.2,0.5,0.4,0.8,0.5l0,0c0.2,0.1,0.4,0.1,0.6,0.2v1h1.3v-1
        c0.2,0,0.5-0.1,0.7-0.2l0,0c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,0.4-0.4,0.5-0.7l0,0c0.1-0.3,0.2-0.6,0.2-0.9c0-0.3-0.1-0.6-0.2-0.9
        c-0.1-0.3-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.4-0.7-0.5c-0.3-0.1-0.6-0.3-0.9-0.4l0,0c-0.2-0.1-0.4-0.1-0.5-0.2
        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.1,0.1-0.2l0,0
        c0,0,0.1-0.1,0.2-0.1l0,0c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4,0.1,0.5,0.2l0,0c0.1,0.1,0.2,0.4,0.2,0.7v0.2h1.9v-0.2c0-0.3,0-0.7-0.2-1
        c-0.1-0.3-0.2-0.5-0.4-0.8C18,9.1,17.8,9,17.6,8.8c-0.2-0.1-0.4-0.2-0.7-0.2V7.5h-1.3v1.1c-0.2,0-0.5,0.1-0.7,0.2
        c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.4-0.5,0.7c-0.1,0.3-0.2,0.5-0.2,0.9c0,0.3,0.1,0.7,0.2,0.9l0,0c0.1,0.3,0.3,0.5,0.5,0.7
        l0,0c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.6,0.3,0.9,0.4l0,0c0.2,0.1,0.4,0.1,0.5,0.2l0,0C16.6,13.5,16.7,13.6,16.8,13.7L16.8,13.7z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.CalendarEdit"
      viewBox="0 0 30 30"
      aria-hidden="true"
    >
      <rect
        fill="var(--color-icon-secondary)"
        x="5"
        y="6"
        width="20"
        height="5"
      />
      <path
        fill="var(--color-icon-primary)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 6H8C6.89543 6 6 6.89543 6 8V22C6 23.1046 6.89543 24 8 24H22C23.1046 24 24 23.1046 24 22V8C24 6.89543 23.1046 6 22 6ZM8 4C5.79086 4 4 5.79086 4 8V22C4 24.2091 5.79086 26 8 26H22C24.2091 26 26 24.2091 26 22V8C26 5.79086 24.2091 4 22 4H8Z"
      />
      <path fill="var(--color-icon-primary)" d="M5 11L25 11L25 12L5 12L5 11Z" />
      <circle fill="var(--color-icon-primary)" cx="20.5" cy="4.5" r="1.5" />
      <circle fill="var(--color-icon-primary)" cx="9.5" cy="4.5" r="1.5" />
      <path
        fill="var(--color-icon-primary)"
        d="M18.9513 13.3053C18.5407 12.8947 17.871 12.8988 17.4554 13.3144L16.4059 14.3638L18.6362 16.5941L19.6856 15.5446C20.1012 15.129 20.1053 14.4593 19.6947 14.0487L18.9513 13.3053ZM17.7352 17.4951L15.5049 15.2648L11 19.7698V22H13.2302L17.7352 17.4951Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.Pencil"
      viewBox="0 0 24 24"
      fill="var(--color-icon-primary)"
      aria-hidden="true"
    >
      <path
        d="M18.1356 4.54274C17.4057 3.81282 16.2151 3.82006 15.4762 4.5589L13.6105 6.42461L17.5754 10.3895L19.4411 8.52378C20.1799 7.78494 20.1872 6.59428 19.4573 5.86437L18.1356 4.54274ZM15.9736 11.9912L12.0088 8.02636L4 16.0351V20H7.96488L15.9736 11.9912Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.PeopleWCross"
      viewBox="0 0 78 78"
      aria-hidden="true"
    >
      <path
        fill="var(--color-icon-secondary)"
        d="M14.6755 52.0001C14.6587 52 14.6418 52 14.625 52C6.54784 52 0 59.2754 0 68.25L9.75 68.25C9.75 62.2371 11.5644 56.6479 14.6755 52.0001Z"
      />
      <path
        fill="var(--color-icon-secondary)"
        d="M24.2986 43.3624C19.4023 39.2123 16.2846 33.0283 16.2503 26.1152C15.7195 26.0393 15.1768 26 14.625 26C8.34276 26 3.25 31.0928 3.25 37.375C3.25 43.6572 8.34276 48.75 14.625 48.75C18.7104 48.75 22.2927 46.5963 24.2986 43.3624Z"
      />
      <path
        fill="var(--color-icon-secondary)"
        d="M63.3245 52.0001C63.3413 52 63.3582 52 63.375 52C71.4522 52 78 59.2754 78 68.25L68.25 68.25C68.25 62.2371 66.4356 56.6479 63.3245 52.0001Z"
      />
      <path
        fill="var(--color-icon-secondary)"
        d="M53.7014 43.3624C58.5977 39.2123 61.7154 33.0283 61.7497 26.1152C62.2805 26.0393 62.8232 26 63.375 26C69.6572 26 74.75 31.0928 74.75 37.375C74.75 43.6572 69.6572 48.75 63.375 48.75C59.2896 48.75 55.7073 46.5963 53.7014 43.3624Z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M55.25 26C55.25 34.9746 47.9746 42.25 39 42.25C30.0254 42.25 22.75 34.9746 22.75 26C22.75 17.0254 30.0254 9.75 39 9.75C47.9746 9.75 55.25 17.0254 55.25 26Z"
      />
      <path
        fill="var(--color-icon-primary)"
        d="M39 45.5C26.4355 45.5 16.25 55.6855 16.25 68.25H61.75C61.75 55.6855 51.5645 45.5 39 45.5Z"
      />
      <path
        fill="#fff"
        d="M40.8605 16.713V24.503H48.2515V27.98H40.8605V35.808H37.0605V27.98H29.7075V24.503H37.0605V16.713H40.8605Z"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.AppIcon"
      aria-hidden="true"
      viewBox="0 0 72 72"
      fill="none"
    >
      <rect x="7" y="7" width="58" height="58" rx="7" fill="#00539B" />
      <path
        d="M36.8265 28.7403L34.3217 24.9739L56.7911 30.8882L36.8265 28.7403Z"
        fill="white"
        stroke="#00539B"
        stroke-width="0.25"
      />
      <path
        d="M14.4067 32.8827L33.5408 24.8577L36.1781 28.8104L14.4067 32.8827Z"
        fill="white"
        stroke="#00539B"
        stroke-width="0.25"
      />
      <path
        d="M37.0789 29.3573L58.2133 31.6286L41.7979 44.2583L37.0789 29.3573Z"
        fill="white"
        stroke="#00539B"
        stroke-width="0.25"
      />
      <path
        d="M41.3535 50.48L13.0737 39.9562V33.7265L13.083 33.7248L41.3535 44.9053V50.48Z"
        fill="white"
        stroke="#00539B"
        stroke-width="0.25"
      />
      <path
        d="M41.9377 50.3727V44.8819L58.9259 31.8192V37.309L41.9377 50.3727Z"
        fill="white"
        stroke="#00539B"
        stroke-width="0.25"
      />
      <path
        d="M41.1799 44.2065L14.1598 33.525L36.4688 29.349L41.1799 44.2065Z"
        fill="white"
        stroke="#00539B"
        stroke-width="0.25"
      />
    </symbol>

    <symbol
      [attr.id]="SvgIconNameEnum.MyTeam"
      aria-hidden="true"
      viewBox="0 0 30 30"
    >
      <circle cx="8" cy="6" r="4" fill="var(--color-icon-secondary)" />
      <circle cx="22" cy="6" r="4" fill="var(--color-icon-secondary)" />
      <path
        d="M8 21C4.68629 21 2 23.6863 2 27H14C14 23.6863 11.3137 21 8 21Z"
        fill="var(--color-icon-primary)"
      />
      <path
        d="M8 11C4.68629 11 2 13.6863 2 17H14C14 13.6863 11.3137 11 8 11Z"
        fill="var(--color-icon-secondary)"
      />
      <path
        d="M22 21C18.6863 21 16 23.6863 16 27H28C28 23.6863 25.3137 21 22 21Z"
        fill="var(--color-icon-primary)"
      />
      <path
        d="M22 11C18.6863 11 16 13.6863 16 17H28C28 13.6863 25.3137 11 22 11Z"
        fill="var(--color-icon-secondary)"
      />
      <circle cx="8" cy="16" r="4" fill="var(--color-icon-primary)" />
      <circle cx="22" cy="16" r="4" fill="var(--color-icon-primary)" />
    </symbol>
  </defs>

  <symbol
    [attr.id]="SvgIconNameEnum.SortDescending"
    viewBox="0 0 30 30"
    fill="var(--color-icon-primary)"
    aria-hidden="true"
  >
    <path
      d="M19 4L21 4L21 13.6923L25 9.38462L27 10.4615L20 18L13 10.4615L15 9.38462L19 13.6923L19 4Z"
    />
    <rect x="4" y="24" width="16" height="2" />
    <rect x="4" y="19" width="12" height="2" />
    <rect x="4" y="14" width="8" height="2" />
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.SortAscending"
    viewBox="0 0 30 30"
    fill="var(--color-icon-primary)"
    aria-hidden="true"
  >
    <path
      d="M21 26L19 26L19 16.3077L15 20.6154L13 19.5385L20 12L27 19.5385L25 20.6154L21 16.3077L21 26Z"
    />
    <rect width="16" height="2" transform="matrix(1 0 0 -1 4 6)" />
    <rect width="12" height="2" transform="matrix(1 0 0 -1 4 11)" />
    <rect width="8" height="2" transform="matrix(1 0 0 -1 4 16)" />
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.Search"
    viewBox="0 0 30 30"
    fill="var(--color-icon-primary)"
    aria-hidden="true"
  >
    <circle cx="14" cy="11" r="3" />
    <path
      d="M14,4c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S19,4,14,4z M14,20c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S17.9,20,14,20z"
    />
    <path d="M14,15c-2.8,0-5,2.2-5,5h10C19,17.2,16.8,15,14,15z" />
    <path
      d="M22.9,24.1c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1L22.9,24.1z M18.6,19.7l4.4,4.4l2.1-2.1l-4.4-4.4 L18.6,19.7z"
    />
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.Earnings"
    viewBox="0 0 72 72"
    fill="none"
    aria-hidden="true"
  >
    <path
      d="M68.831 67.076C69.4276 69.5889 67.5219 72 64.9392 72L8.80622 72C6.30614 72 4.41875 69.7322 4.87272 67.2737L16.6905 3.27366C17.0407 1.37673 18.695 3.14416e-06 20.624 3.3128e-06L49.7449 5.85864e-06C51.5981 6.02065e-06 53.2087 1.27296 53.6368 3.07605L68.831 67.076Z"
      fill="#7DD5BB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M66 50C67.1046 50 68 50.8954 68 52V61.276V65V68.42C68 69.1841 67.1777 69.6659 66.5111 69.2923L62.4206 67H40C38.8954 67 38 66.1046 38 65V52C38 50.8954 38.8954 50 40 50H66Z"
      fill="#138865"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 10C6.79086 10 5 11.7909 5 14V35.5V42V49.101C5 50.6259 6.63822 51.5899 7.97129 50.8493L16.7 46H63C65.2091 46 67 44.2091 67 42V14C67 11.7909 65.2091 10 63 10H9Z"
      fill="#138865"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 6C9.79086 6 8 7.79086 8 10V31.5V38V45.101C8 46.6259 9.63822 47.5899 10.9713 46.8493L19.7 42H66C68.2091 42 70 40.2091 70 38V10C70 7.79086 68.2091 6 66 6H12Z"
      fill="white"
    />
    <path
      d="M10.9713 46.8493L11.4569 47.7234H11.4569L10.9713 46.8493ZM19.7 42V41H19.4409L19.2144 41.1258L19.7 42ZM9 10C9 8.34315 10.3431 7 12 7V5C9.23858 5 7 7.23858 7 10H9ZM9 31.5V10H7V31.5H9ZM9 38V31.5H7V38H9ZM9 45.101V38H7V45.101H9ZM10.4856 45.9751C9.81911 46.3454 9 45.8635 9 45.101H7C7 47.3884 9.45734 48.8343 11.4569 47.7234L10.4856 45.9751ZM19.2144 41.1258L10.4856 45.9751L11.4569 47.7234L20.1856 42.8742L19.2144 41.1258ZM66 41H19.7V43H66V41ZM69 38C69 39.6569 67.6569 41 66 41V43C68.7614 43 71 40.7614 71 38H69ZM69 10V38H71V10H69ZM66 7C67.6569 7 69 8.34315 69 10H71C71 7.23858 68.7614 5 66 5V7ZM12 7H66V5H12V7Z"
      fill="#138865"
    />
    <rect x="16" y="14" width="5" height="12" rx="1" fill="#138865" />
    <rect
      width="5"
      height="5"
      rx="1"
      transform="matrix(1 0 0 -1 16 34)"
      fill="#138865"
    />
    <rect x="25" y="14" width="33" height="4" rx="1" fill="#1AB688" />
    <rect x="25" y="22" width="39" height="4" rx="1" fill="#1AB688" />
    <rect x="25" y="30" width="26" height="4" rx="1" fill="#1AB688" />
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.Smile1"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <circle fill="var(--color-icon-primary)" cx="15" cy="15" r="13" />
    <circle fill="var(--color-icon-secondary)" cx="15" cy="15" r="11" />
    <path
      fill="var(--color-icon-primary)"
      d="M20.6,19.9c-3.5-1.7-7.6-1.7-11.1,0l-0.9-1.8c4-2,8.9-2,12.9,0L20.6,19.9z"
    />
    <path
      fill="var(--color-icon-primary)"
      d="M18.0574 11.4779C18.2717 12.3517 19.0601 13 20 13C21.1046 13 22 12.1046 22 11C22 10.4329 21.7639 9.92084 21.3847 9.55687L18.0574 11.4779Z"
    />
    <path
      fill="var(--color-icon-primary)"
      d="M8.60901 9.56293C8.23346 9.92651 8 10.436 8 11C8 12.1046 8.89543 13 10 13C10.9368 13 11.7232 12.3559 11.9405 11.4863L8.60901 9.56293Z"
    />
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.Smile2"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <circle fill="var(--color-icon-primary)" cx="15" cy="15" r="13" />
    <circle fill="var(--color-icon-secondary)" cx="15" cy="15" r="11" />
    <rect fill="var(--color-icon-primary)" x="9" y="18" width="12" height="2" />
    <path
      fill="var(--color-icon-primary)"
      d="M18.2676 10C18.0974 10.2942 18 10.6357 18 11C18 12.1046 18.8954 13 20 13C21.1046 13 22 12.1046 22 11C22 10.6357 21.9026 10.2942 21.7324 10H18.2676Z"
    />
    <path
      fill="var(--color-icon-primary)"
      d="M8.26756 10C8.09739 10.2942 8 10.6357 8 11C8 12.1046 8.89543 13 10 13C11.1046 13 12 12.1046 12 11C12 10.6357 11.9026 10.2942 11.7324 10H8.26756Z"
    />
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.Smile3"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <circle fill="var(--color-icon-primary)" cx="15" cy="15" r="13" />
    <circle fill="var(--color-icon-secondary)" cx="15" cy="15" r="11" />
    <circle fill="var(--color-icon-primary)" cx="20" cy="11" r="2" />
    <circle fill="var(--color-icon-primary)" cx="10" cy="11" r="2" />
    <path
      fill="var(--color-icon-primary)"
      d="M15,21c-2.1,0-4.3-0.3-6.3-1l0.6-1.9c3.7,1.2,7.7,1.2,11.4,0l0.6,1.9C19.3,20.6,17.1,21,15,21z"
    />
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.Smile4"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <circle fill="var(--color-icon-primary)" cx="15" cy="15" r="13" />
    <circle fill="var(--color-icon-secondary)" cx="15" cy="15" r="11" />
    <path
      fill="var(--color-icon-primary)"
      d="M15,24c-4.4,0-8-3.6-8-8h2c0,3.3,2.7,6,6,6s6-2.7,6-6h2C23,20.4,19.4,24,15,24z"
    />
    <circle fill="var(--color-icon-primary)" cx="20" cy="11" r="2" />
    <circle fill="var(--color-icon-primary)" cx="10" cy="11" r="2" />
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.Smile5"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <circle fill="var(--color-icon-primary)" cx="15" cy="15" r="13" />
    <circle fill="var(--color-icon-secondary)" cx="15" cy="15" r="11" />
    <path
      fill="var(--color-icon-primary)"
      d="M23 16C23 20.4183 19.4183 24 15 24C10.5817 24 7 20.4183 7 16C7 16 10.5817 16 15 16C19.4183 16 23 16 23 16Z"
    />
    <circle fill="var(--color-icon-primary)" cx="20" cy="11" r="2" />
    <circle fill="var(--color-icon-primary)" cx="10" cy="11" r="2" />
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.CheckCircle"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <circle fill="var(--color-icon-primary)" cx="15" cy="15" r="13" />
    <circle fill="var(--color-icon-secondary)" cx="15" cy="15" r="11" />
    <path
      fill="var(--color-icon-primary)"
      d="M21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L14.3839 20.0303C13.8957 20.5185 13.1043 20.5185 12.6161 20.0303L9.29289 16.7071C8.90237 16.3166 8.90237 15.6834 9.29289 15.2929C9.68342 14.9024 10.3166 14.9024 10.7071 15.2929L13.5 18.0858L20.2929 11.2929C20.6834 10.9024 21.3166 10.9024 21.7071 11.2929Z"
    />
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.Mask"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <path
      fill="var(--color-icon-secondary)"
      d="M5,9h20c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V11C3,9.9,3.9,9,5,9z"
    />
    <path
      fill="var(--color-icon-primary)"
      d="M8,15h14c0.6,0,1,0.4,1,1l0,0c0,0.6-0.4,1-1,1H8c-0.6,0-1-0.4-1-1l0,0C7,15.4,7.4,15,8,15z"
    />
    <path
      fill="var(--color-icon-primary)"
      d="M25,21H5c-2.8,0-5-2.2-5-5s2.2-5,5-5h20c2.8,0,5,2.2,5,5S27.8,21,25,21z M5,13c-1.7,0-3,1.3-3,3s1.3,3,3,3h20
		c1.7,0,3-1.3,3-3s-1.3-3-3-3H5z"
    />
    <path
      fill="var(--color-icon-secondary)"
      d="M25,23H5c-1.1,0-2-0.9-2-2V11c0-1.1,0.9-2,2-2h20c1.1,0,2,0.9,2,2v10C27,22.1,26.1,23,25,23z M5,11L5,11v10h20
		V11H5z"
    />
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.PersonWSymptoms"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <g fill="var(--color-icon-primary)">
      <rect x="5" y="16" width="2" height="2" />
      <rect x="17" y="3" width="2" height="2" />
      <rect x="14" y="3" width="2" height="2" />
      <rect x="11" y="3" width="2" height="2" />
      <rect x="8" y="16" width="2" height="2" />
      <rect x="20" y="3" width="2" height="2" />
      <rect x="11" y="16" width="2" height="2" />
      <rect x="23" y="3" width="2" height="2" />
      <rect x="14" y="16" width="2" height="2" />
      <rect x="23" y="6" width="2" height="2" />
    </g>
    <circle fill="var(--color-icon-secondary)" cx="23" cy="14" r="5" />
    <circle fill="var(--color-icon-primary)" cx="23" cy="14" r="3" />
    <path
      fill="var(--color-icon-primary)"
      d="M23 20C19.134 20 16 23.134 16 27H30C30 23.134 26.866 20 23 20Z"
    />
    <circle fill="var(--color-icon-secondary)" cx="5.5" cy="5.5" r="5.5" />
    <circle fill="var(--color-icon-secondary)" cx="5.5" cy="24.5" r="5.5" />
    <path
      fill="var(--color-icon-primary)"
      d="M9.25032 21.8207C9.64084 22.2113 9.64084 22.8444 9.25032 23.235L6.94037 25.5449C6.14652 26.3388 4.92561 26.0378 4.45882 25.198L2.7071 26.9497C2.31658 27.3403 1.68341 27.3403 1.29289 26.9497C0.902362 26.5592 0.902362 25.9261 1.29289 25.5355L3.60283 23.2256C4.39668 22.4317 5.6176 22.7327 6.08439 23.5725L7.8361 21.8207C8.22663 21.4302 8.85979 21.4302 9.25032 21.8207Z"
    />
    <rect
      fill="var(--color-icon-primary)"
      x="4.5"
      y="2"
      width="2"
      height="7"
      rx="1"
    />
    <rect
      fill="var(--color-icon-primary)"
      x="9"
      y="4.5"
      width="2"
      height="7"
      rx="1"
      transform="rotate(90 9 4.5)"
    />
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.Virus"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <path
      d="M13.4681 4.61639C14.0411 3.26359 15.9589 3.26359 16.5319 4.61639C16.7572 5.1483 17.4431 5.29398 17.865 4.89939C18.9365 3.89722 20.686 4.68594 20.6623 6.15284C20.653 6.73052 21.2203 7.1458 21.7665 6.95745C23.154 6.479 24.4304 7.91362 23.8146 9.24584C23.5719 9.77075 23.9208 10.382 24.4968 10.4334C25.9615 10.5641 26.5489 12.3923 25.4429 13.3614C25.0075 13.7429 25.0797 14.4428 25.585 14.7253C26.8701 15.4436 26.6716 17.3532 25.2648 17.7876C24.712 17.9583 24.4975 18.6284 24.8439 19.0918C25.7234 20.2684 24.7715 21.9363 23.3135 21.7581C22.7398 21.688 22.2714 22.2131 22.4 22.7766C22.7266 24.2068 21.18 25.3442 19.9234 24.5872C19.4286 24.2891 18.7877 24.5759 18.6774 25.1429C18.397 26.5837 16.5218 26.9876 15.6814 25.7841C15.3507 25.3104 14.6493 25.3104 14.3186 25.7841C13.4782 26.9876 11.603 26.5837 11.3226 25.1429C11.2123 24.5759 10.5714 24.2891 10.0766 24.5872C8.81999 25.3442 7.27343 24.2068 7.59996 22.7766C7.7286 22.2131 7.26023 21.688 6.68652 21.7581C5.22846 21.9363 4.27665 20.2684 5.15611 19.0918C5.50253 18.6284 5.28802 17.9583 4.73517 17.7876C3.32845 17.3532 3.12987 15.4436 4.41498 14.7253C4.92029 14.4428 4.99248 13.7429 4.55709 13.3614C3.45109 12.3923 4.03849 10.5641 5.5032 10.4334C6.07919 10.382 6.42809 9.77075 6.18545 9.24584C5.56962 7.91362 6.84604 6.479 8.23353 6.95745C8.77972 7.1458 9.34701 6.73052 9.33769 6.15284C9.31403 4.68594 11.0635 3.89722 12.135 4.89939C12.5569 5.29398 13.2428 5.1483 13.4681 4.61639Z"
      fill="var(--color-icon-primary)"
    />
    <circle
      cx="20.1286"
      cy="13.072"
      r="2"
      transform="rotate(-13.9299 20.1286 13.072)"
      fill="var(--color-icon-secondary)"
    />
    <circle
      cx="10.4227"
      cy="15.4793"
      r="2"
      transform="rotate(-13.9299 10.4227 15.4793)"
      fill="var(--color-icon-secondary)"
    />
    <circle
      cx="14.0719"
      cy="9.42266"
      r="2"
      transform="rotate(-13.9299 14.0719 9.42266)"
      fill="var(--color-icon-secondary)"
    />
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.CameraAdd"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <circle fill="var(--color-icon-primary)" cx="14" cy="16" r="6" />
    <circle fill="var(--color-icon-secondary)" cx="14" cy="16" r="4" />
    <rect
      fill="var(--color-icon-primary)"
      x="22"
      y="15"
      width="6"
      height="2"
      rx="1"
    />
    <rect
      fill="var(--color-icon-primary)"
      x="26"
      y="13"
      width="6"
      height="2"
      rx="1"
      transform="rotate(90 26 13)"
    />
    <path
      fill="var(--color-icon-primary)"
      d="M8.33531 8.1521C8.37661 7.98348 8.45308 7.80662 8.58056 7.62814C8.86213 7.23395 9.31674 7 9.80116 7H18.1988C18.6833 7 19.1379 7.23395 19.4194 7.62814C19.5469 7.80662 19.6234 7.98348 19.6647 8.1521C19.9009 9.11641 20.7765 10 22 10C23.1046 10 24 10.8954 24 12H26C26 9.79086 24.2091 8 22 8C21.8106 8 21.6523 7.8602 21.6073 7.67628C21.5054 7.26046 21.3219 6.85062 21.0469 6.46567C20.3899 5.54588 19.3292 5 18.1988 5H9.80116C8.67083 5 7.61009 5.54588 6.9531 6.46567C6.67813 6.85062 6.49458 7.26046 6.39273 7.67628C6.34768 7.8602 6.18936 8 6 8C3.79086 8 2 9.79086 2 12V21C2 23.2091 3.79086 25 6 25H22C24.2091 25 26 23.2091 26 21V20H24V21C24 22.1046 23.1046 23 22 23H6C4.89543 23 4 22.1046 4 21V12C4 10.8954 4.89543 10 6 10C7.22348 10 8.09911 9.11641 8.33531 8.1521Z"
    />
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.CameraDenied"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <path
      fill="var(--color-icon-secondary)"
      d="M16.7,12.4L11,16v0c0-2.2,1.8-4,4-4C15.6,12,16.2,12.1,16.7,12.4z"
    />
    <path
      fill="var(--color-icon-secondary)"
      d="M19,16c0,2.2-1.8,4-4,4c-0.7,0-1.4-0.2-2-0.5l6-3.8C19,15.8,19,15.9,19,16z"
    />
    <path
      fill="var(--color-icon-primary)"
      d="M21,16c0,3.3-2.7,6-6,6c-1.4,0-2.8-0.5-3.8-1.4l1.8-1.2c0.6,0.3,1.3,0.5,2,0.5c2.2,0,4-1.8,4-4
		c0-0.1,0-0.2,0-0.3l1.8-1.2C20.9,15,21,15.5,21,16z"
    />
    <path
      fill="var(--color-icon-primary)"
      d="M18.6,11.2l-1.9,1.2C16.2,12.1,15.6,12,15,12c-2.2,0-4,1.8-4,4v0l-1.9,1.2C9,16.8,9,16.4,9,16c0-3.3,2.7-6,6-6
		C16.4,10,17.6,10.5,18.6,11.2z"
    />

    <path
      fill="var(--color-icon-primary)"
      d="M26.8,10.8L25,11.9c0,0,0,0.1,0,0.1v9c0,1.1-0.9,2-2,2H7.5l-2.4,1.5C5.7,24.8,6.3,25,7,25h16c2.2,0,4-1.8,4-4
		v-9C27,11.6,26.9,11.1,26.8,10.8z M23,8c-0.2,0-0.3-0.1-0.4-0.3c-0.1-0.4-0.3-0.8-0.6-1.2C21.4,5.5,20.3,5,19.2,5h-8.4
		C9.7,5,8.6,5.5,8,6.5C7.7,6.8,7.5,7.3,7.4,7.7C7.3,7.9,7.2,8,7,8c-2.2,0-4,1.8-4,4v9c0,0,0,0.1,0,0.1l2-1.3V12c0-1.1,0.9-2,2-2
		c1.2,0,2.1-0.9,2.3-1.8c0-0.2,0.1-0.3,0.2-0.5C9.9,7.2,10.3,7,10.8,7h8.4c0.5,0,0.9,0.2,1.2,0.6c0.1,0.2,0.2,0.3,0.2,0.5
		c0.1,0.5,0.4,0.9,0.8,1.3L23.6,8C23.4,8,23.2,8,23,8z"
    />

    <path
      fill="var(--color-icon-primary)"
      d="M29.3,7.6c-0.1,0.3-0.2,0.5-0.4,0.6L2,25.3c-0.2,0.1-0.5,0.2-0.8,0.1C1,25.3,0.8,25.2,0.6,25
		c-0.3-0.5-0.2-1.1,0.3-1.4l26.8-17c0.5-0.3,1.1-0.2,1.4,0.3C29.3,7.1,29.4,7.4,29.3,7.6z"
    />
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.CameraSwitch"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <path
      fill="var(--color-icon-primary)"
      d="M15 9C14.4696 9 13.9609 8.78929 13.5858 8.41421C13.2107 8.03914 13 7.53043 13 7C13 6.46957 13.2107 5.96086 13.5858 5.58579C13.9609 5.21071 14.4696 5 15 5C16.09 5 17 5.9 17 7C17 7.53043 16.7893 8.03914 16.4142 8.41421C16.0391 8.78929 15.5304 9 15 9ZM20 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V19C8 19.5304 8.21071 20.0391 8.58579 20.4142C8.96086 20.7893 9.46957 21 10 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19V5C22 4.46957 21.7893 3.96086 21.4142 3.58579C21.0391 3.21071 20.5304 3 20 3V3ZM17 23V25H22V23H17ZM13 23H8V25H13V27L16 24L13 21V23Z"
    />
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.Caution"
    viewBox="0 0 72 72"
    aria-hidden="true"
  >
    <g transform="translate(-0.5)">
      <path
        fill="#FC5E5E"
        d="M5.15569 67.5L67.8443 67.5C70.8614 67.5 72.7924 64.287 71.3764 61.6228L40.0321 2.646C38.5279 -0.184367 34.4721 -0.184375 32.9679 2.646L1.62355 61.6228C0.207596 64.287 2.13857 67.5 5.15569 67.5Z"
      />
      <path
        fill="#883636"
        d="M3.5 24C3.5 21.7909 5.29086 20 7.5 20H61.5C63.7091 20 65.5 21.7909 65.5 24V52C65.5 54.2091 63.7091 56 61.5 56H7.5C5.29086 56 3.5 54.2091 3.5 52V24Z"
      />
      <path
        fill="white"
        d="M6.5 20C6.5 17.7909 8.29086 16 10.5 16H64.5C66.7091 16 68.5 17.7909 68.5 20V48C68.5 50.2091 66.7091 52 64.5 52H10.5C8.29086 52 6.5 50.2091 6.5 48V20Z"
      />
      <path
        fill="#883636"
        d="M10.5 17H64.5V15H10.5V17ZM67.5 20V48H69.5V20H67.5ZM64.5 51H10.5V53H64.5V51ZM7.5 48V20H5.5V48H7.5ZM10.5 51C8.84315 51 7.5 49.6569 7.5 48H5.5C5.5 50.7614 7.73858 53 10.5 53V51ZM67.5 48C67.5 49.6569 66.1569 51 64.5 51V53C67.2614 53 69.5 50.7614 69.5 48H67.5ZM64.5 17C66.1569 17 67.5 18.3431 67.5 20H69.5C69.5 17.2386 67.2614 15 64.5 15V17ZM10.5 15C7.73858 15 5.5 17.2386 5.5 20H7.5C7.5 18.3431 8.84315 17 10.5 17V15Z"
      />
      <rect fill="#883636" x="14.5" y="24" width="5" height="12" rx="1" />
      <rect
        fill="#883636"
        width="5"
        height="5"
        rx="1"
        transform="matrix(1 0 0 -1 14.5 44)"
      />
      <rect fill="#EE3C3A" x="23.5" y="24" width="33" height="4" rx="1" />
      <rect fill="#EE3C3A" x="23.5" y="32" width="39" height="4" rx="1" />
      <rect fill="#EE3C3A" x="23.5" y="40" width="26" height="4" rx="1" />
    </g>
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.Airplane"
    viewBox="0 0 22 18"
    fill="var(--color-icon-primary)"
    aria-hidden="true"
  >
    <path
      d="M1.50009 15.9999H20.5001V17.9999H1.50009V15.9999ZM21.0701 6.63995C20.8601 5.83995 20.0301 5.35995 19.2301 5.57995L13.9201 6.99995L7.00009 0.569946L5.09009 1.07995L9.23009 8.24995L4.26009 9.57995L2.29009 8.03995L0.840088 8.42995L2.66009 11.5899L3.43009 12.9199L5.03009 12.4999L10.3401 11.0699L14.6901 9.90995L20.0001 8.49995C20.8101 8.25995 21.2801 7.43995 21.0701 6.63995Z"
    />
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.Approved"
    viewBox="0 0 78 78"
    fill="none"
    aria-hidden="true"
  >
    <rect y="7" width="52" height="60" rx="4" fill="#7DD5BB" />
    <path
      d="M72 70C72 72.2091 70.2091 74 68 74L15.3505 74C12.4067 74 10.4714 70.9275 11.7428 68.2724L41.9118 5.27238C42.5768 3.88372 43.9798 3 45.5195 3L68 2.99999C70.2091 2.99999 72 4.79086 72 6.99999L72 70Z"
      fill="#1AB688"
    />
    <g filter="url(#filter0_d)">
      <rect
        x="14"
        y="33.3712"
        width="12.7579"
        height="28.7053"
        rx="2"
        transform="rotate(-8.30878 14 33.3712)"
        fill="#E8F8F3"
      />
    </g>
    <g filter="url(#filter1_d)">
      <path
        d="M61.0847 25.4856C63.5437 25.1265 65.853 26.8683 66.2163 29.3562L66.8769 33.8798C66.9628 34.4679 66.9303 35.0271 66.8051 35.5767L62.3826 52.5083C61.9498 54.2347 60.5277 55.5974 58.6723 55.8684L38.5528 58.8067C36.0937 59.1658 33.7845 57.424 33.4211 54.936L30.118 32.318C29.9363 31.074 30.263 29.8713 30.9712 28.9363L43.504 11.8828L46.2205 13.9116C46.9132 14.4342 47.4188 15.2382 47.5542 16.1655L47.5928 16.8991L46.9787 27.5456L61.0847 25.4856Z"
        fill="#E8F8F3"
      />
    </g>
    <circle cx="23" cy="37" r="2" fill="#138865" />
    <defs>
      <filter
        id="filter0_d"
        x="11"
        y="31.5276"
        width="19.7721"
        height="34.2476"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="-3" dy="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0745098 0 0 0 0 0.533333 0 0 0 0 0.396078 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="24.4755"
        y="9"
        width="45.3743"
        height="54.4596"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="-3" dy="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0745098 0 0 0 0 0.533333 0 0 0 0 0.396078 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.CautionLocation"
    viewBox="0 0 72 72"
    fill="none"
    aria-hidden="true"
  >
    <path
      d="M36 33.775C33.7267 33.775 31.5465 32.8663 29.9391 31.2488C28.3316 29.6313 27.4286 27.4375 27.4286 25.15C27.4286 22.8625 28.3316 20.6687 29.9391 19.0512C31.5465 17.4337 33.7267 16.525 36 16.525C38.2733 16.525 40.4535 17.4337 42.0609 19.0512C43.6684 20.6687 44.5714 22.8625 44.5714 25.15C44.5714 26.2827 44.3497 27.4042 43.919 28.4506C43.4882 29.4971 42.8568 30.4479 42.0609 31.2488C41.265 32.0497 40.3201 32.685 39.2801 33.1185C38.2402 33.5519 37.1256 33.775 36 33.775ZM36 1C29.6348 1 23.5303 3.54437 19.0294 8.07337C14.5286 12.6024 12 18.745 12 25.15C12 43.2625 36 70 36 70C36 70 60 43.2625 60 25.15C60 18.745 57.4714 12.6024 52.9706 8.07337C48.4697 3.54437 42.3652 1 36 1Z"
      fill="#FC5E5E"
    />
    <path
      d="M3.5 25C3.5 22.7909 5.29086 21 7.5 21H61.5C63.7091 21 65.5 22.7909 65.5 25V53C65.5 55.2091 63.7091 57 61.5 57H7.5C5.29086 57 3.5 55.2091 3.5 53V25Z"
      fill="#883636"
    />
    <path
      d="M6.5 21C6.5 18.7909 8.29086 17 10.5 17H64.5C66.7091 17 68.5 18.7909 68.5 21V49C68.5 51.2091 66.7091 53 64.5 53H10.5C8.29086 53 6.5 51.2091 6.5 49V21Z"
      fill="white"
    />
    <path
      d="M10.5 18H64.5V16H10.5V18ZM67.5 21V49H69.5V21H67.5ZM64.5 52H10.5V54H64.5V52ZM7.5 49V21H5.5V49H7.5ZM10.5 52C8.84315 52 7.5 50.6569 7.5 49H5.5C5.5 51.7614 7.73858 54 10.5 54V52ZM67.5 49C67.5 50.6569 66.1569 52 64.5 52V54C67.2614 54 69.5 51.7614 69.5 49H67.5ZM64.5 18C66.1569 18 67.5 19.3431 67.5 21H69.5C69.5 18.2386 67.2614 16 64.5 16V18ZM10.5 16C7.73858 16 5.5 18.2386 5.5 21H7.5C7.5 19.3431 8.84315 18 10.5 18V16Z"
      fill="#883636"
    />
    <rect x="14.5" y="25" width="5" height="12" rx="1" fill="#883636" />
    <rect
      width="5"
      height="5"
      rx="1"
      transform="matrix(1 0 0 -1 14.5 45)"
      fill="#883636"
    />
    <rect x="23.5" y="25" width="33" height="4" rx="1" fill="#EE3C3A" />
    <rect x="23.5" y="33" width="39" height="4" rx="1" fill="#EE3C3A" />
    <rect x="23.5" y="41" width="26" height="4" rx="1" fill="#EE3C3A" />
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.Filter"
    viewBox="0 0 30 30"
    aria-hidden="true"
  >
    <rect
      fill="var(--color-icon-primary)"
      x="5"
      y="10"
      width="20"
      height="2"
      rx="1"
    />
    <rect
      fill="var(--color-icon-primary)"
      x="5"
      y="18"
      width="20"
      height="2"
      rx="1"
    />

    <circle fill="var(--color-icon-primary)" cx="18" cy="19" r="4" />
    <circle fill="#ffffff" cx="18" cy="19" r="2" />

    <circle fill="var(--color-icon-primary)" cx="9" cy="11" r="4" />
    <circle fill="#ffffff" cx="9" cy="11" r="2" />
  </symbol>
  <symbol
    [attr.id]="SvgIconNameEnum.EmptyStateApplications"
    viewBox="0 0 72 72"
    fill="none"
    aria-hidden="true"
  >
    <path
      d="M68.831 67.076C69.4276 69.5889 67.5219 72 64.9392 72L8.80622 72C6.30614 72 4.41875 69.7322 4.87272 67.2737L16.6905 3.27366C17.0407 1.37673 18.695 3.14416e-06 20.624 3.3128e-06L49.7449 5.85864e-06C51.5981 6.02065e-06 53.2087 1.27296 53.6368 3.07605L68.831 67.076Z"
      fill="#C9C3C3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34 16C22.9543 16 14 24.9543 14 36C14 47.0457 22.9543 56 34 56C38.3653 56 42.404 54.6014 45.6924 52.2279L48.1584 54.6939C47.3249 55.8653 47.4335 57.5011 48.484 58.5517L55.4568 65.5245C56.6284 66.6961 58.5279 66.6961 59.6995 65.5245L62.5245 62.6995C63.6961 61.5279 63.6961 59.6284 62.5245 58.4568L55.5517 51.484C54.5011 50.4335 52.8654 50.3249 51.6939 51.1583L49.3533 48.8178C52.2542 45.3468 54 40.8773 54 36C54 24.9543 45.0457 16 34 16Z"
      fill="#9E9393"
    />
    <path
      d="M16 34C16 24.0589 24.0589 16 34 16C43.9411 16 52 24.0589 52 34C52 43.9411 43.9411 52 34 52C24.0589 52 16 43.9411 16 34Z"
      fill="white"
    />
    <path
      d="M34 50.5C24.8873 50.5 17.5 43.1127 17.5 34H14.5C14.5 44.7696 23.2304 53.5 34 53.5V50.5ZM50.5 34C50.5 43.1127 43.1127 50.5 34 50.5V53.5C44.7696 53.5 53.5 44.7696 53.5 34H50.5ZM34 17.5C43.1127 17.5 50.5 24.8873 50.5 34H53.5C53.5 23.2304 44.7696 14.5 34 14.5V17.5ZM34 14.5C23.2304 14.5 14.5 23.2304 14.5 34H17.5C17.5 24.8873 24.8873 17.5 34 17.5V14.5Z"
      fill="#565353"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.891 33.0615C41.4965 31.5997 41.655 29.9911 41.3463 28.4393C41.0376 26.8874 40.2757 25.462 39.1568 24.3431C38.038 23.2243 36.6126 22.4624 35.0607 22.1537C33.5089 21.845 31.9003 22.0035 30.4385 22.609C28.9767 23.2145 27.7273 24.2398 26.8482 25.5554C26.1551 26.5928 25.7168 27.7738 25.5624 29.0026C25.4936 29.5506 25.9477 30 26.5 30H29.5C30.0523 30 30.4844 29.5403 30.6651 29.0184C30.7485 28.7777 30.8626 28.5473 31.0056 28.3333C31.3352 27.8399 31.8038 27.4554 32.3519 27.2284C32.9001 27.0013 33.5033 26.9419 34.0853 27.0576C34.6672 27.1734 35.2018 27.4591 35.6213 27.8787C36.0409 28.2982 36.3266 28.8328 36.4424 29.4147C36.4706 29.5566 36.4884 29.6998 36.4959 29.8431C36.4709 29.9753 36.4284 30.3519 36.3785 30.8453C36.3484 30.9477 36.3128 31.0488 36.2716 31.1481C36.0446 31.6962 35.6601 32.1648 35.1667 32.4944C34.9527 32.6374 34.7223 32.7515 34.4816 32.8349C34.3728 32.8726 34.2667 32.9212 34.1668 32.9795C33.407 33.2434 32.6038 33.3735 31.9965 33.4376C31.4473 33.4957 31 33.9478 31 34.5001V38.5001C31 39.0524 31.4477 39.5001 32 39.5001H35C35.5523 39.5001 36 39.0524 36 38.5001V37.5993C36.6844 37.3742 37.339 37.0564 37.9446 36.6518C39.2601 35.7727 40.2855 34.5233 40.891 33.0615ZM31 45.5C31 46.0523 31.4477 46.5 32 46.5H35C35.5523 46.5 36 46.0523 36 45.5V42.5C36 41.9477 35.5523 41.5 35 41.5H32C31.4477 41.5 31 41.9477 31 42.5V45.5Z"
      fill="#565353"
    />
    <rect
      x="44"
      y="48.5356"
      width="5"
      height="20"
      rx="1"
      transform="rotate(-45 44 48.5356)"
      fill="#565353"
    />
    <rect
      x="46.3627"
      y="54.4304"
      width="9.9952"
      height="15.8611"
      rx="3"
      transform="rotate(-45 46.3627 54.4304)"
      fill="#565353"
    />
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.FileInsuranceLicense"
    viewBox="0 0 72 72"
    fill="none"
    aria-hidden="true"
  >
    <g transform="translate(0,10)">
      <rect
        x="2"
        y="18"
        width="60.5815"
        height="35.9494"
        rx="4"
        transform="rotate(-16.94 2 18)"
        fill="white"
      />
      <rect
        x="27.8198"
        y="13.2715"
        width="19"
        height="6"
        rx="3"
        transform="rotate(-16.94 27.8198 13.2715)"
        fill="#DCEBF6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3442 26.2783L10.9156 25.5643C10.3451 24.6139 9.32253 24.0318 8.23082 23.9983C8.6764 25.3822 9.95776 26.272 11.3442 26.2783ZM8.12384 22.4059C8.33656 21.2322 9.18256 20.216 10.4021 19.8446C11.6132 19.4757 12.8729 19.84 13.704 20.6832L13.5574 20.7661C11.9097 21.6971 10.0777 22.2551 8.19073 22.4007L8.12384 22.4059ZM14.5103 22.0488C13.5649 22.6767 13.0006 23.7653 13.0587 24.9115L13.1027 25.7786C14.329 25.0134 14.9433 23.4979 14.5103 22.0488ZM15.6648 21.6785C16.3889 24.056 15.0487 26.5703 12.6712 27.2944C10.2938 28.0186 7.77944 26.6783 7.05531 24.3008C6.33117 21.9234 7.67144 19.4091 10.0489 18.6849C12.4263 17.9608 14.9407 19.3011 15.6648 21.6785Z"
        fill="#0076BD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3002 18.9065C19.2345 18.6908 19.1864 18.473 19.1554 18.2541L21.9339 16.5217L23.72 22.3857C21.4598 21.9844 19.8268 20.6356 19.3002 18.9065ZM18.0702 19.2812C17.8911 18.6931 17.8113 18.0945 17.8223 17.4994L21.5279 15.1888L25.8923 15.0413C26.2332 15.5293 26.5006 16.0708 26.6797 16.6588C27.4525 19.196 26.3751 21.9298 24.1502 23.7983C21.2615 23.4873 18.843 21.8183 18.0702 19.2812Z"
        fill="#0076BD"
      />
      <rect
        x="9.30029"
        y="31.4082"
        width="53"
        height="10"
        rx="4"
        transform="rotate(-16.94 9.30029 31.4082)"
        fill="#DCEBF6"
      />
      <rect
        x="13.2998"
        y="44.5391"
        width="27.2413"
        height="4.03926"
        rx="2.01963"
        transform="rotate(-16.94 13.2998 44.5391)"
        fill="#DCEBF6"
      />
    </g>
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.FileError"
    viewBox="0 0 72 72"
    fill="var(--color-icon-primary)"
    aria-hidden="true"
  >
    <g>
      <path
        class="st0"
        d="M36,32.5c-3,0-5.5,2.5-5.5,5.5c0,1.3,0.4,2.4,1.1,3.4l7.7-7.7C38.4,32.9,37.3,32.5,36,32.5z"
      />
      <path
        class="st0"
        d="M33.1,42.7c0.8,0.5,1.8,0.8,2.9,0.8c3,0,5.5-2.5,5.5-5.5c0-1-0.3-2-0.8-2.9L33.1,42.7z"
      />
      <path
        class="st0"
        d="M51.5,13.5h-7l11,11v-7C55.5,15.3,53.7,13.5,51.5,13.5z"
      />
      <path
        class="st0"
        d="M44.5,20.5v-7h-24c-2.2,0-4,1.8-4,4v37c0,2.2,1.8,4,4,4h31c2.2,0,4-1.8,4-4v-30h-7
		C46.3,24.5,44.5,22.7,44.5,20.5z M36,45.5c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5S40.1,45.5,36,45.5z"
      />
    </g>
  </symbol>

  <symbol
    [attr.id]="SvgIconNameEnum.FileDriverLicense"
    viewBox="0 0 72 72"
    fill="none"
  >
    <g transform="translate(0,10)">
      <rect
        x="2"
        y="17.6523"
        width="60.5815"
        height="35.9494"
        rx="4"
        transform="rotate(-16.9405 2 17.6523)"
        fill="white"
      />
      <rect
        x="6.05322"
        y="19.7969"
        width="41.4719"
        height="6.46282"
        rx="3.23141"
        transform="rotate(-16.9405 6.05322 19.7969)"
        fill="#DCEBF6"
      />
      <rect
        x="25.8735"
        y="23.4707"
        width="36.1862"
        height="12.9256"
        rx="4"
        transform="rotate(-16.9405 25.8735 23.4707)"
        fill="#DCEBF6"
      />
      <rect
        x="30.5815"
        y="38.9258"
        width="27.2413"
        height="4.03926"
        rx="2.01963"
        transform="rotate(-16.9405 30.5815 38.9258)"
        fill="#DCEBF6"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="8"
        y="24"
        width="22"
        height="25"
      >
        <rect
          x="8.87793"
          y="29.0684"
          width="15.4955"
          height="19.7924"
          rx="4"
          transform="rotate(-16.9405 8.87793 29.0684)"
          fill="#DCEBF6"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="8.87793"
          y="29.0684"
          width="15.4955"
          height="19.7924"
          rx="4"
          transform="rotate(-16.9405 8.87793 29.0684)"
          fill="#DCEBF6"
        />
        <ellipse
          cx="18.649"
          cy="34.556"
          rx="4.5279"
          ry="4.49827"
          transform="rotate(-16.9405 18.649 34.556)"
          fill="#00539B"
        />
        <ellipse
          cx="22.202"
          cy="46.222"
          rx="6.5403"
          ry="6.4975"
          transform="rotate(-16.9405 22.202 46.222)"
          fill="#00539B"
        />
      </g>
    </g>
  </symbol>
</svg>
