import { Component, OnInit, Input } from '@angular/core';
import { ContactDetails } from '../../../../core/dataEntities/resources/directory';
import { SvgIconName} from '../../../../shared/enums/SvgIcon';
import { SiteHierarchyTitle } from '../../../../core/enums/SiteHierarchyTitle';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {

  @Input() contact: ContactDetails;

  SvgIconEnum = SvgIconName;
  SiteHierarchyEnum = SiteHierarchyTitle;
  constructor() { }

  ngOnInit() {
  }
}
