import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../shared/error-handlers/catch-app-error';
import { DynamicContentActions } from '../../dynamic-content/actions';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { ContentDeliveryService } from '../../shared/services/content-delivery.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

@Injectable()
export class DynamicContentEffects {
  constructor(
    private actions$: Actions,
    private contentDeliveryService: ContentDeliveryService,
    private store: Store<AppState>,
    @Inject(APP_ERROR_MESSAGES) private errorMessages
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DynamicContentActions.loadPage),
      switchMap(action => {
        return this.contentDeliveryService.getPage(action.slug).pipe(
          map(page => {
            return DynamicContentActions.pageLoaded({
              page
            });
          }),
          catchError(handleAppError(this.errorMessages)),
          finalize(() =>
            this.store.dispatch(
              DynamicContentActions.toggleDynamicContentIsLoading({
                isLoading: false
              })
            )
          )
        );
      })
    )
  );
}
