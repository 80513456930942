import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  AppState,
  getHasRoadCrewAccess,
  getUserEmail,
  selectIsOpportunityLoading,
  selectOpportunityDetails
} from '../../../../../reducers';
import { OpportunityDetails } from '../../../../../core/dataEntities/opportunities/opportunityDetails';
import { PageHeaderAppearance } from '../../../../../shared/enums/PageHeader';
import { PageTitleAppearance } from '../../../../../shared/enums/PageTitle';
import { RoadCrewApplication } from '../../../../../core/dataEntities/applicationForm/roadCrewApplication';
import { OpportunitiesActions } from '../../actions';
import {
  selectApplicationForm,
  selectApplicationFormStatus
} from '../../reducers/opportunities.reducer';
import { DialogService } from '../../../../../shared/modules/modal/dialog/dialog.service';
import { ConfirmLocationComponent } from '../../components/confirm-location/confirm-location.component';
import { UpdateLocationComponent } from '../../components/update-location/update-location.component';
import { takeUntil } from 'rxjs/operators';
import {
  CancelApplicationComponent,
  CancelType
} from '../../components/cancel-application/cancel-application.component';
import { FormUtilitiesService } from '../../services/form-utilities.service';
import { ApplicationFormStatus } from '../../../../../core/enums/ApplicationFormStatus';
import { StringHelpers } from '../../../../../shared/helpers/string.helpers';

@Component({
  selector: 'app-opportunities-details',
  templateUrl: './opportunities-details-container.component.html',
  styleUrls: ['./opportunities-details-container.component.scss']
})
export class OpportunitiesDetailsContainerComponent
  implements OnInit, OnDestroy {
  subscription: Subscription;
  PageHeaderAppearanceEnum = PageHeaderAppearance;
  PageTitleAppearanceEnum = PageTitleAppearance;
  StringHelpers = StringHelpers;

  public opportunityDetails$: Observable<OpportunityDetails>;
  public isOpportunityDetailsLoading$: Observable<boolean>;
  public userEmail$: Observable<string>;
  public applicationForm: RoadCrewApplication;
  public applicationFormStatus$: Observable<ApplicationFormStatus>;

  private destroySubject = new Subject();
  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private formUtilitiesService: FormUtilitiesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.opportunityDetails$ = this.store.select(selectOpportunityDetails);
    this.isOpportunityDetailsLoading$ = this.store.select(
      selectIsOpportunityLoading
    );
    this.userEmail$ = this.store.select(getUserEmail);
    this.store
      .select(selectApplicationForm)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(applicationForm => {
        this.applicationForm = applicationForm;
      });
    this.applicationFormStatus$ = this.store.select(
      selectApplicationFormStatus
    );

    this.store
      .select(getHasRoadCrewAccess)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(value => {
        if (!value) {
          this.router.navigate(['news']);
        }
      });

    this.store.dispatch(OpportunitiesActions.loadApplicationForm());
    this.subscription = combineLatest(
      this.route.paramMap,
      this.route.queryParamMap
    ).subscribe(([params, queryParams]) => {
      return this.store.dispatch(
        OpportunitiesActions.loadOpportunityDetails({
          id: params.get('id'),
          otherId: queryParams.get('id')
        })
      );
    });
  }
  ngOnDestroy() {
    this.destroySubject.next(null);
    this.destroySubject.complete();
    this.destroySubject = null;
  }

  openApplicationForm() {
    this.store.dispatch(OpportunitiesActions.openApplicationForm());
  }

  applyForOpportunity() {
    const config = {
      data: {
        preferredAirport:
          this.applicationForm &&
          this.applicationForm.travelInfo &&
          this.applicationForm.travelInfo.preferredAirport
      }
    };
    this.dialogService
      .open(ConfirmLocationComponent, config)
      .afterClosed()
      .subscribe(result => {
        if (result && result.success) {
          if (result.data.approveAirport) {
            this.store.dispatch(
              OpportunitiesActions.applyForOpportunity({
                preferredAirport: config.data.preferredAirport
              })
            );
          } else {
            this.openUpdateLocationModal(config);
          }
        }
      });
  }

  openUpdateLocationModal(config: any) {
    config = {
      ...config,
      data: {
        ...config.data,
        airports: this.formUtilitiesService.getAirports()
      }
    };
    this.dialogService
      .open(UpdateLocationComponent, config)
      .afterClosed()
      .subscribe(result => {
        if (result && result.success) {
          this.store.dispatch(
            OpportunitiesActions.applyForOpportunity({
              preferredAirport: result.data.preferredAirport
            })
          );
        }
      });
  }

  cancelAppliedOpportunity() {
    this.dialogService
      .open(CancelApplicationComponent, {
        data: {
          cancelType: CancelType.Opportunity
        }
      })
      .afterClosed()
      .subscribe(result => {
        if (result && result.success) {
          this.store.dispatch(OpportunitiesActions.cancelAppliedOpportunity());
        }
      });
  }
}
