import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutActions } from '../../../core/actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { MsalService } from '@azure/msal-angular';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { TranslationService } from '../../../shared/services/translation.service';
import { TranslationMessages } from '../../../shared/enums/TranslationMessages';

@Component({
  selector: 'app-landing-page-container',
  templateUrl: './landing-page-container.component.html',
  styleUrls: ['./landing-page-container.component.scss']
})
export class LandingPageContainerComponent implements OnInit {
  SvgIconNameEnum = SvgIconName;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private authService: MsalService,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.isAutoLoggedOut) {
        const isAutoLoggedOut = JSON.parse(params.isAutoLoggedOut);

        if (isAutoLoggedOut) {
          this.store.dispatch(
            LayoutActions.showInfoMessage({
              message: this.translationService.translate(
                TranslationMessages.SessionExpired
              ),
              showOver: true
            })
          );
        } else {
          this.store.dispatch(
            LayoutActions.showInfoMessage({
              message: this.translationService.translate(
                TranslationMessages.LoggedOut
              ),
              showOver: true
            })
          );
        }
      }
    });
  }

  login() {
    this.authService.loginRedirect();
  }
}
