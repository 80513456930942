import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import * as fromRoot from '../../reducers';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { SurveyService } from '../services/mock/survey.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private surveysService: SurveyService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(fromRoot.getUserId),
      switchMap(userId => {
        return this.surveysService.getAvailableSurveys(userId).pipe(
          map(surveys => {
            const activeSurvey = surveys.find(s => s.id === route.params.id);
            if (activeSurvey) {
              return true;
            }
            this.router.navigate(['']);
            return false;
          })
        );
      })
    );
  }
}
