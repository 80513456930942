/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tab.component";
import * as i2 from "../services/tab-group.service";
var styles_TabComponent = [];
var RenderType_TabComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TabComponent, data: {} });
export { RenderType_TabComponent as RenderType_TabComponent };
function View_TabComponent_1(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_TabComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { implicitContent: 0 }), (_l()(), i0.ɵand(0, [[1, 2]], null, 0, null, View_TabComponent_1))], null, null); }
export function View_TabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "app-tab", [], null, null, null, View_TabComponent_0, RenderType_TabComponent)), i0.ɵdid(1, 114688, null, 1, i1.TabComponent, [i0.ViewContainerRef, i2.TabGroupService], null, null), i0.ɵqud(335544320, 1, { labelTemplate: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabComponentNgFactory = i0.ɵccf("app-tab", i1.TabComponent, View_TabComponent_Host_0, { textLabel: "textLabel", isActive: "isActive" }, {}, ["*"]);
export { TabComponentNgFactory as TabComponentNgFactory };
