import { filter, forEach, includes, map, remove, some } from 'lodash/fp';
import { EntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { DataTableBaseState } from './data-table-state';
import { DataTableRow } from './data-table-row';
import { DataTableStateSelectors } from './selectors';

export abstract class DataTableBaseAdapter<
  T extends DataTableRow,
  S extends DataTableBaseState<T>
> {
  firstPage = {
    pageSize: 10,
    pageIndex: 0
  };

  protected constructor(protected adapter: EntityAdapter<T>) {}

  abstract createDataTableSelectionStateSelector(): (state: S) => any;

  abstract createCurrentItemsSelector(): (state: S) => T[];

  afterChangeFilters(action, state: S) {
    return state;
  }

  afterItemsLoaded(action, state: S) {
    return state;
  }

  getInitialState(state): S {
    return this.adapter.getInitialState({
      totalCount: 0,
      page: this.firstPage,
      sort: {
        active: null,
        direction: null
      },
      filter: {},
      selectedItems: [],
      ...state
    });
  }

  itemsLoaded(action, state) {
    const newState = this.adapter.upsertMany(
      action.items,
      this.getItemsLoadedState(action, state)
    );
    return this.afterItemsLoaded(action, newState);
  }

  getItemsLoadedState(action, state) {
    return {
      ...state,
      totalCount: action.totalCount
    };
  }

  changePage(action, state) {
    return {
      ...state,
      page: { pageIndex: action.pageIndex, pageSize: action.pageSize }
    };
  }

  changeSort(action, state) {
    return {
      ...state,
      sort: { active: action.active, direction: action.direction },
      page: {
        ...state.page,
        pageIndex: 0
      }
    };
  }

  changeFilters(action, state: S) {
    const newState = {
      ...state,
      filter: { ...action.filter },
      page: {
        ...state.page,
        pageIndex: 0
      }
    };
    return this.afterChangeFilters(action, newState);
  }

  toggleSelectItem(action, state) {
    let selectedItems = state.selectedItems;
    const item = state.entities[action.item.id];
    const violationIsSelected = includes(action.item.id)(selectedItems);

    const itemUpdate = {
      id: item.id,
      changes: {
        selected: action.selected
      }
    };

    if (action.selected && !violationIsSelected) {
      selectedItems = [...selectedItems, action.item.id];
    } else if (!action.selected && violationIsSelected) {
      selectedItems = remove(i => i === action.item.id)(selectedItems);
    }

    return this.adapter.updateOne(itemUpdate as any, {
      ...state,
      selectedItems
    });
  }

  toggleSelectAllItems(action, state) {
    const currentPageItems = filter(e =>
      includes(e.id)(state.currentPageItems)
    )(state.entities);

    let updatedSelections = [];

    if (action.selectAll) {
      const selectedItems = new Set(state.selectedItems);
      forEach(e => {
        selectedItems.add(e.id);
      })(currentPageItems);
      updatedSelections = Array.from(selectedItems);
    } else {
      updatedSelections = filter(e => {
        return !some(pageItem => pageItem.id === e)(currentPageItems);
      })(state.selectedItems);
    }
    const itemsToUpdate = map(item => ({
      id: item.id,
      changes: { selected: action.selectAll }
    }))(currentPageItems);
    return this.adapter.updateMany([...itemsToUpdate], {
      ...state,
      selectedItems: updatedSelections
    });
  }

  unselectAllItems(state) {
    const itemsToUpdate = map(item => ({
      id: item.id,
      changes: { selected: false }
    }))(state.entities);

    return this.adapter.updateMany([...itemsToUpdate], {
      ...state,
      selectedItems: []
    });
  }

  getSelectors(): DataTableStateSelectors<T, S> {
    const { selectAll } = this.adapter.getSelectors();
    const selectEntities = (state: S) => {
      return state.entities;
    };

    const selectDataTotalCount = (state: S) => {
      return state.totalCount;
    };
    const selectDataTablePage = (state: S) => {
      return {
        ...state.page,
        totalCount: state.totalCount
      };
    };
    const selectDataTableSort = (state: S) => {
      return state.sort;
    };
    const selectDataTableFilter = (state: S) => {
      return state.filter;
    };
    const selectSelectedDataTableItemsIds = (state: S) => {
      return state.selectedItems;
    };

    const selectSelectedDataTableItems = createSelector(
      selectEntities,
      selectSelectedDataTableItemsIds,
      (entities, selectedItemsIds) => {
        return map(id => entities && entities[id])(selectedItemsIds);
      }
    );

    const selectSelectedDataTableItemsCount = (state: S) => {
      return state.selectedItems.length;
    };

    const selectDataTableSelectionState = this.createDataTableSelectionStateSelector();
    const selectCurrentItems = this.createCurrentItemsSelector();

    return {
      selectAllItems: selectAll,
      selectEntities,
      selectDataTotalCount,
      selectDataTablePage,
      selectDataTableSort,
      selectDataTableFilter,
      selectSelectedDataTableItems,
      selectSelectedDataTableItemsIds,
      selectSelectedDataTableItemsCount,
      selectDataTableSelectionState,
      selectCurrentItems
    };
  }
}
