import { Pipe, PipeTransform, QueryList } from '@angular/core';
import { FormStepDefDirective } from '../directives/form-step-def.directive';

@Pipe({
  name: 'getNextEditableStepTitle'
})
export class GetNextEditableStepTitlePipe implements PipeTransform {
  transform(
    editableStep: FormStepDefDirective,
    formSteps: QueryList<FormStepDefDirective>,
    ...args: any[]
  ): any {
    const steps = formSteps.toArray();
    let editableIndex = steps.indexOf(editableStep);
    if (editableIndex < 0) {
      editableIndex = 0;
    }
    if (editableIndex < steps.length - 1) {
      return steps[editableIndex + 1].title;
    }
    return null;
  }
}
