/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tab-header.component";
var styles_TabHeaderComponent = [i0.styles];
var RenderType_TabHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabHeaderComponent, data: {} });
export { RenderType_TabHeaderComponent as RenderType_TabHeaderComponent };
export function View_TabHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["tabListContainer", 1]], null, 2, "div", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, [["tabList", 1]], null, 1, "div", [["class", "tab-header__tab-list"], ["role", "tablist"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_TabHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-tab-header", [], null, null, null, View_TabHeaderComponent_0, RenderType_TabHeaderComponent)), i1.ɵdid(1, 4440064, null, 1, i2.TabHeaderComponent, [], null, null), i1.ɵqud(603979776, 1, { tabLabels: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabHeaderComponentNgFactory = i1.ɵccf("app-tab-header", i2.TabHeaderComponent, View_TabHeaderComponent_Host_0, { selectedIndex: "selectedIndex" }, { selectIndex: "selectIndex", selectFocusedIndex: "selectFocusedIndex", indexFocused: "indexFocused" }, ["*"]);
export { TabHeaderComponentNgFactory as TabHeaderComponentNgFactory };
