/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multi-step-form-readonly-step.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./multi-step-form-readonly-step.component";
var styles_MultiStepFormReadonlyStepComponent = [i0.styles];
var RenderType_MultiStepFormReadonlyStepComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultiStepFormReadonlyStepComponent, data: {} });
export { RenderType_MultiStepFormReadonlyStepComponent as RenderType_MultiStepFormReadonlyStepComponent };
function View_MultiStepFormReadonlyStepComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_MultiStepFormReadonlyStepComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "multi-step-readonly__edit"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editStep.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Edit "]))], null, null); }
export function View_MultiStepFormReadonlyStepComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "multi-step-readonly"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "multi-step-readonly__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultiStepFormReadonlyStepComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultiStepFormReadonlyStepComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showDetails; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isStepEditable; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_MultiStepFormReadonlyStepComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-multi-step-form-readonly-step", [], null, null, null, View_MultiStepFormReadonlyStepComponent_0, RenderType_MultiStepFormReadonlyStepComponent)), i1.ɵdid(1, 114688, null, 0, i3.MultiStepFormReadonlyStepComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultiStepFormReadonlyStepComponentNgFactory = i1.ɵccf("app-multi-step-form-readonly-step", i3.MultiStepFormReadonlyStepComponent, View_MultiStepFormReadonlyStepComponent_Host_0, { title: "title", isStepEditable: "isStepEditable", showDetails: "showDetails" }, { editStep: "editStep" }, ["*"]);
export { MultiStepFormReadonlyStepComponentNgFactory as MultiStepFormReadonlyStepComponentNgFactory };
