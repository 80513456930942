/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./paginator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/a11y";
import * as i3 from "@angular/common";
import * as i4 from "./paginator.component";
var styles_PaginatorComponent = [i0.styles];
var RenderType_PaginatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaginatorComponent, data: {} });
export { RenderType_PaginatorComponent as RenderType_PaginatorComponent };
function View_PaginatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["cdkMonitorElementFocus", ""], ["class", "paginator__button paginator__button--prev"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePage((0 - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.CdkMonitorFocus, [i1.ElementRef, i2.FocusMonitor], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to previous page"]))], null, null); }
function View_PaginatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["cdkMonitorElementFocus", ""], ["class", "paginator__button paginator__button--next"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePage(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.CdkMonitorFocus, [i1.ElementRef, i2.FocusMonitor], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to next page"]))], null, null); }
export function View_PaginatorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "paginator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "paginator__side paginator__side--left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "paginator__middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " ", " ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["of"])), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "paginator__side paginator__side--right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginatorComponent_2)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isFirstPage(); _ck(_v, 3, 0, currVal_0); var currVal_5 = !_co.isLastPage(); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.pageIndexView * _co.pageSize) - _co.pageSize) + 1); var currVal_2 = " - "; var currVal_3 = (((_co.pageIndexView * _co.pageSize) > _co.totalCount) ? _co.totalCount : (_co.pageIndexView * _co.pageSize)); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.totalCount; _ck(_v, 8, 0, currVal_4); }); }
export function View_PaginatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-paginator", [], null, null, null, View_PaginatorComponent_0, RenderType_PaginatorComponent)), i1.ɵdid(1, 245760, null, 0, i4.PaginatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginatorComponentNgFactory = i1.ɵccf("app-paginator", i4.PaginatorComponent, View_PaginatorComponent_Host_0, { pageIndex: "pageIndex", pageSize: "pageSize", totalCount: "totalCount" }, { pageChange: "pageChange" }, []);
export { PaginatorComponentNgFactory as PaginatorComponentNgFactory };
