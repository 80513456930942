import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-certification-edit',
  templateUrl: './certification-edit.component.html',
  styleUrls: ['./certification-edit.component.scss']
})
export class CertificationEditComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() certificationsList: any[];

  constructor() {}

  ngOnInit() {}
}
