<app-card-layout>
  <app-card-layout-item>
    <ng-content select="[slot='productivity']"></ng-content>
  </app-card-layout-item>
  <app-card-layout-item>
    <app-page-section-header
      i18n-title="@@production-details-layout_associatePerformance"
      title="Associate performance"
    ></app-page-section-header>
    <ng-content select="[slot='performance']"></ng-content>
  </app-card-layout-item>
</app-card-layout>

<section class="production-details-layout__observation">
  <app-page-section-header
    i18n-title="@@production-details-layout_weeklyObservation"
    title="Weekly safety observation"
  ></app-page-section-header>
  <ng-content select="[slot='observation']"></ng-content>
</section>
