<header class="earnings-header">
  <app-page-header-container
    class="earnings-header__header"
    [appearance]="PageHeaderAppearanceEnum.Transparent"
    [isHeader]="false"
  ></app-page-header-container>

  <div *ngIf="weekPayDetails" class="earnings-header__main">
    <h1 class="heading">
      <span
        *ngIf="isHistoricalEarning === false"
        class="heading__title"
        i18n="@@pay_soFarThisWeekYouveEarned"
        data-cy="thisWeekEarnings"
      >
        So far this week you've earned
      </span>
      <span
        *ngIf="isHistoricalEarning"
        class="heading__title"
        data-cy="anyWeekEarnings"
      >
        <ng-container i18n="@@pay_from">From</ng-container>
        {{ weekPayDetails.weekStartingDate | date: 'MMM. dd' }}th
        <ng-container i18n="@@pay_to">to</ng-container>
        {{ weekPayDetails.weekEndingDate | date: 'MMM. dd' }}th
        <ng-container i18n="@@pay_youEarned">you earned</ng-container>
      </span>
      <span class="heading__amount" data-cy="weekEarningsAmount">
        <span class="heading__amount--sign">$</span>
        {{ weekPayDetails.totalEarnings | number: '1.2-2' }}
      </span>
    </h1>
    <span
      *ngIf="isHistoricalEarning === false"
      class="earnings-header__note"
      i18n="@@pay_estimated"
      data-cy="estimated"
    >
      (estimated)
    </span>
    <p class="earnings-header__payment" data-cy="paymentInfo">
      <ng-container
        *ngIf="isHistoricalEarning && isFutureProcessing === false"
        i18n="@@pay_paymentProcessedOn"
      >
        Payment processed on
      </ng-container>
      <ng-container
        *ngIf="isHistoricalEarning === false"
        i18n="@@pay_toBeProcessedAndPaidOn"
      >
        To be processed and paid on
      </ng-container>
      <ng-container
        *ngIf="isHistoricalEarning && isFutureProcessing"
        i18n="@@pay_willBeProcessedOn"
      >
        Payment will be processed on
      </ng-container>
      <time
        class="earnings-header__payment-date"
        [attr.datetime]="weekPayDetails.paymentProcessDate"
      >
        {{ weekPayDetails.paymentProcessDate | date: 'EEEE, MMMM dd' }}
      </time>
    </p>
    <div
      *ngIf="this.weekPayDetails.newCaliforniaPayModelUsed === true"
      class="earnings-header__payment"
      data-cy="paymentInfoCaliforniaOnly"
    >
      <span class="heading__title_alt" i18n="@@pay_californiaStatsIntro">
        California-Only Stats
      </span>
      <span
        class="earnings-header__payment-date"
        i18n="@@pay_californiaStatsPercentToGoal"
      >
        Percent To Goal:
        {{ this.weekPayDetails.percentToGoal * 100 | number: '1.2-2' }}%
      </span>
      <span
        class="earnings-header__payment-date"
        *ngIf="isHistoricalEarning === true"
        i18n="@@pay_californiaStatsIncentivePay"
      >
        Incentive Pay: ${{ this.weekPayDetails.incentivePay | number: '1.2-2' }}
      </span>
      <span
        class="earnings-header__payment-date"
        i18n="@@pay_californiaStatsProductionBaseWageRegular"
        *ngIf="weekPayDetails.isLead !== true"
      >
        Production Base Wage: ${{
          this.weekPayDetails.productionBaseWage | number: '1.2-2'
        }}
      </span>
      <span
        class="earnings-header__payment-date"
        i18n="@@pay_californiaStatsProductionBaseWageLead"
        *ngIf="weekPayDetails.isLead === true"
      >
        Lead Production Base Wage: ${{
          this.weekPayDetails.productionBaseWage | number: '1.2-2'
        }}
      </span>
    </div>
  </div>
</header>
