import {
  Component,
  ContentChildren,
  forwardRef,
  Input,
  OnInit,
  QueryList
} from '@angular/core';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { SvgIconName } from '../../enums/SvgIcon';
import { MenuColorAppearance, MenuTitleFont } from '../../enums/Menu';
import { ChipAppearance } from '../../enums/Chip';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() topRoundedBorder = true;
  @Input() bottomRoundedBorder = true;

  @ContentChildren(forwardRef(() => MenuItemComponent))
  menuItems: QueryList<MenuItemComponent>;

  SvgIconNameEnum = SvgIconName;
  MenuColorAppearanceEnum = MenuColorAppearance;
  MenuTitleFontEnum = MenuTitleFont;
  ChipAppearanceEnum = ChipAppearance;

  constructor() {}

  ngOnInit() {}
}
