import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import { OpportunityRow } from '../view-models/opportunity-row';
import { OpportunitiesListActions } from '../actions';
import {
  createServerDataTableAdapter,
  ServerDataTableState
} from '../../../../shared/data-table';
import { SortDirections } from '../../../../core/enums/SortDirections';
import { OpportunityListSortOptions } from '../../../../core/enums/OpportunityListSortOptions';
import { every, isEmpty, isArray } from 'lodash';

export const opportunitiesListFeatureKey = 'opportunitiesList';

export interface OpportunitiesListState {
  opportunitiesList: ServerDataTableState<OpportunityRow>;
  isLoading: boolean;
}

export const defaultOpportunitiesListFilter = {
  duration: [],
  jobTitle: [],
  locationState: [],
  startDateFrom: '',
  query: ''
};

export const adapter = createServerDataTableAdapter<OpportunityRow>({
  infinitePage: false
});
export const initialState: OpportunitiesListState = {
  opportunitiesList: adapter.getInitialState({
    sort: {
      active: OpportunityListSortOptions.Newest,
      direction: SortDirections.Asc
    },
    page: {
      pageIndex: 0,
      pageSize: 25
    },
    filter: {
      ...defaultOpportunitiesListFilter
    }
  }),
  isLoading: false
};

export const opportunitiesListReducer = createReducer(
  initialState,
  on(OpportunitiesListActions.opportunitiesListLoaded, (state, action) => ({
    ...state,
    opportunitiesList: adapter.itemsLoaded(action, state.opportunitiesList)
  })),
  on(OpportunitiesListActions.changeOpportunitiesListPage, (state, action) => ({
    ...state,
    opportunitiesList: adapter.changePage(action, state.opportunitiesList)
  })),
  on(OpportunitiesListActions.changeOpportunitiesListSort, (state, action) => ({
    ...state,
    opportunitiesList: adapter.changeSort(action, state.opportunitiesList)
  })),
  on(
    OpportunitiesListActions.changeOpportunitiesListFilter,
    (state, action) => ({
      ...state,
      opportunitiesList: adapter.changeFilters(action, state.opportunitiesList)
    })
  ),
  on(
    OpportunitiesListActions.toggleOpportunitiesListLoading,
    (state, action) => ({
      ...state,
      isLoading: action.isLoading
    })
  ),
  on(OpportunitiesListActions.clearFilters, (state, action) => ({
    ...state,
    opportunitiesList: {
      ...state.opportunitiesList,
      filter: { ...defaultOpportunitiesListFilter }
    }
  }))
);

export function reducer(
  state: OpportunitiesListState | undefined,
  action: Action
) {
  return opportunitiesListReducer(state, action);
}

const {
  selectDataTablePage,
  selectDataTableFilter,
  selectDataTableSort,
  selectCurrentItems,
  selectDataTotalCount
} = adapter.getSelectors();

const getOpportunitiesState = createFeatureSelector<OpportunitiesListState>(
  opportunitiesListFeatureKey
);

export const getOpportunitiesListState = createSelector(
  getOpportunitiesState,
  state => state.opportunitiesList
);

export const selectAllOpportunities = createSelector(
  getOpportunitiesListState,
  selectCurrentItems
);
export const selectOpportunitiesListFilter = createSelector(
  getOpportunitiesListState,
  selectDataTableFilter
);

export const selectOpportunitiesListSort = createSelector(
  getOpportunitiesListState,
  selectDataTableSort
);
export const selectOpportunitiesListPage = createSelector(
  getOpportunitiesListState,
  selectDataTablePage
);

export const selectOpportunitiesListTotalCount = createSelector(
  getOpportunitiesListState,
  selectDataTotalCount
);

export const selectIsOpportunitiesListLoading = createSelector(
  getOpportunitiesState,
  state => state.isLoading
);

export const selectIsOpportunitiesFilterSet = createSelector(
  selectOpportunitiesListFilter,
  filter => {
    return !every(
      filter,
      item => (isArray(item) && isEmpty(item)) || item === null || item === ''
    );
  }
);
