<div
  [ngClass]="{
    slider: true,
    'slider--red': validationStatus === GoalValidationStatusEnum.Error,
    'slider--yellow': validationStatus === GoalValidationStatusEnum.Warning
  }"
  [formGroup]="formGroup"
>
  <span [attr.id]="options.ariaLabelledBy" class="visually-hidden">
    {{ label }}
  </span>
  <ng5-slider
    [value]="formGroup.get('value').value"
    [options]="options"
    (userChange)="onValueChange($event)"
  ></ng5-slider>
  <div class="slider__average-container">
    <button
      type="button"
      *ngIf="siteAverage"
      [ngStyle]="{ 'left.%': siteAveragePercentValue }"
      [ngClass]="{
        'slider__average-button': true,
        'slider__average-button--top': true,
        'slider__average-button--active':
          activeAverage === SliderAverageEnum.Site ||
          activeAverage === SliderAverageEnum.All
      }"
      (click)="setSliderValue(siteAverageFormatted)"
      cdkMonitorElementFocus
    >
      <app-svg-icon
        [name]="SvgIconNameEnum.SiteAverage"
        class="slider__average-icon"
      ></app-svg-icon>
      <span class="visually-hidden" i18n="@@slider_selectSiteAverage">
        Select site average {{ siteAverageFormatted }}
      </span>
    </button>
    <button
      *ngIf="associateAverage"
      type="button"
      [ngStyle]="{ 'left.%': associateAveragePercentValue }"
      [ngClass]="{
        'slider__average-button': true,
        'slider__average-button--bottom': true,
        'slider__average-button--active':
          activeAverage === SliderAverageEnum.Personal ||
          activeAverage === SliderAverageEnum.All
      }"
      (click)="setSliderValue(associateAverageFormatted)"
      cdkMonitorElementFocus
    >
      <app-svg-icon
        [name]="SvgIconNameEnum.Person"
        class="slider__average-icon"
      ></app-svg-icon>
      <span class="visually-hidden" i18n="@@slider_selectPersonalAverage">
        Select personal average {{ associateAverageFormatted }}
      </span>
    </button>
    <div class="slider__average-height-placeholder"></div>
  </div>
</div>
