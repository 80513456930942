export enum MenuColorAppearance {
  Green = 'Green',
  Red = 'Red',
  Black = 'Black',
  Yellow = 'Yellow'
}

export enum MenuTitleFont {
  Lato = 'Lato',
  Roboto = 'Roboto'
}
