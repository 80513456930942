/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./opportunities-list-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i4 from "../../../../../shared/components/svg-icon/svg-icon.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "./opportunities-list-filter.component";
import * as i8 from "../../../../../shared/modules/modal/bottom-sheet/bottom-sheet.service";
var styles_OpportunitiesListFilterComponent = [i0.styles];
var RenderType_OpportunitiesListFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OpportunitiesListFilterComponent, data: {} });
export { RenderType_OpportunitiesListFilterComponent as RenderType_OpportunitiesListFilterComponent };
function View_OpportunitiesListFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Start Date "]))], null, null); }
function View_OpportunitiesListFilterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Starts on/after ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.filter.startDateFrom, "MM/dd")); _ck(_v, 1, 0, currVal_0); }); }
function View_OpportunitiesListFilterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filter.duration.length; _ck(_v, 1, 0, currVal_0); }); }
function View_OpportunitiesListFilterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filter.jobTitle.length; _ck(_v, 1, 0, currVal_0); }); }
function View_OpportunitiesListFilterComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" (", ") "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filter.locationState.length; _ck(_v, 1, 0, currVal_0); }); }
export function View_OpportunitiesListFilterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 38, "div", [["class", "filter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-svg-icon", [["class", "filter__icon"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(3, 638976, null, 0, i4.SvgIconComponent, [i5.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "button", [["cdkMonitorElementFocus", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filterByDate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(7, { filter__button: 0, "is-applied": 1 }), i1.ɵdid(8, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListFilterComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListFilterComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 7, "button", [["cdkMonitorElementFocus", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filterByDuration() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(16, { filter__button: 0, "is-applied": 1 }), i1.ɵdid(17, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, null), (_l()(), i1.ɵted(-1, null, [" Duration "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListFilterComponent_3)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 7, "button", [["cdkMonitorElementFocus", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filterByJobTitle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(23, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(24, { filter__button: 0, "is-applied": 1 }), i1.ɵdid(25, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, null), (_l()(), i1.ɵted(-1, null, [" Job Title "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListFilterComponent_4)), i1.ɵdid(28, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 7, "button", [["cdkMonitorElementFocus", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filterByRegion() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(31, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(32, { filter__button: 0, "is-applied": 1 }), i1.ɵdid(33, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, null), (_l()(), i1.ɵted(-1, null, [" Region "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpportunitiesListFilterComponent_5)), i1.ɵdid(36, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(37, 0, null, null, 2, "button", [["cdkMonitorElementFocus", ""], ["class", "filter__button filter__button--clear"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearFilters() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(38, 147456, null, 0, i6.CdkMonitorFocus, [i1.ElementRef, i6.FocusMonitor], null, null), (_l()(), i1.ɵted(-1, null, [" Clear Filters "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.Filter; _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, true, !!_co.filter.startDateFrom); _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.filter.startDateFrom; _ck(_v, 10, 0, currVal_2); var currVal_3 = !!_co.filter.startDateFrom; _ck(_v, 12, 0, currVal_3); var currVal_4 = _ck(_v, 16, 0, true, !!_co.filter.duration.length); _ck(_v, 15, 0, currVal_4); var currVal_5 = _co.filter.duration.length; _ck(_v, 20, 0, currVal_5); var currVal_6 = _ck(_v, 24, 0, true, !!_co.filter.jobTitle.length); _ck(_v, 23, 0, currVal_6); var currVal_7 = _co.filter.jobTitle.length; _ck(_v, 28, 0, currVal_7); var currVal_8 = _ck(_v, 32, 0, true, !!_co.filter.locationState.length); _ck(_v, 31, 0, currVal_8); var currVal_9 = _co.filter.locationState.length; _ck(_v, 36, 0, currVal_9); }, null); }
export function View_OpportunitiesListFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-opportunities-list-filter", [], null, null, null, View_OpportunitiesListFilterComponent_0, RenderType_OpportunitiesListFilterComponent)), i1.ɵdid(1, 49152, null, 0, i7.OpportunitiesListFilterComponent, [i8.BottomSheetService], null, null)], null, null); }
var OpportunitiesListFilterComponentNgFactory = i1.ɵccf("app-opportunities-list-filter", i7.OpportunitiesListFilterComponent, View_OpportunitiesListFilterComponent_Host_0, { filter: "filter", totalCount: "totalCount", userApexId: "userApexId" }, { filterChange: "filterChange", openFilter: "openFilter", clearFilter: "clearFilter" }, []);
export { OpportunitiesListFilterComponentNgFactory as OpportunitiesListFilterComponentNgFactory };
