import { Component, Input, OnInit } from '@angular/core';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import moment from 'moment';
import { ProgressBarAppearance } from '../../../../../shared/enums/ProgressBar';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';
import { GoalDetails } from '../../../../../core/dataEntities/goal/goalDetails';

@Component({
  selector: 'app-weekly-goal',
  templateUrl: './weekly-goal.component.html',
  styleUrls: ['./weekly-goal.component.scss']
})
export class WeeklyGoalComponent implements OnInit {
  @Input() totalEarnings: number;
  @Input() isHistoricalWeek: boolean;
  @Input() goalDetails: GoalDetails;

  public SvgIconNameEnum = SvgIconName;
  public ButtonAppearanceEnum = ButtonAppearance;
  public ButtonColorEnum = ButtonColor;
  public progressBarAppearance = ProgressBarAppearance;
  public daysLeft: number;
  public percentage: number;

  constructor() {}

  ngOnInit() {
    this.daysLeft =
      moment(this.goalDetails.id).diff(moment().utc(), 'days') + 1;
    this.percentage = (this.totalEarnings * 100) / this.goalDetails.goal;
  }
}
