<main>
  <ng-template #pageTitle i18n="@@resources-container_pageTitle">
    Resources
  </ng-template>
  <app-page-title
    class="visually-hidden"
    [titleTemplate]="pageTitle"
  ></app-page-title>
  <app-menu class="resources">
    <app-menu-item
      title="S.C.R.I.P.T. Stars"
      i18n-title="@@resources-container_scriptStars"
      subTitle="See the Capstone Hall of Fame"
      i18n-subTitle="@@resources-container_scriptStarsSubtitle"
      [iconName]="SvgIconNameEnum.PersonWStar"
      [iconColorPrimary]="SvgIconColorEnum.Endeavour"
      [iconColorSecondary]="SvgIconColorEnum.Spindle"
      [link]="'stars'"
      dataCy="scriptStars"
    ></app-menu-item>
    <app-menu-item
      title="FAQ"
      i18n-title="@@resources-container_employeeFAQ"
      subTitle="Your handbook to success at Capstone"
      i18n-subTitle="@@resources-container_employeeFAQSubtitle"
      [iconName]="SvgIconNameEnum.Info"
      [iconColorPrimary]="SvgIconColorEnum.Endeavour"
      [iconColorSecondary]="SvgIconColorEnum.Spindle"
      [link]="['/content', 'associate-faq']"
      dataCy="employeeFAQ"
    ></app-menu-item>
    <app-menu-item
      title="Job Openings"
      i18n-title="@@resources-container_jobOpenings"
      subTitle="View and share Capstone opportunities"
      i18n-subTitle="@@resources-container_jobOpeningsSubtitle"
      [iconName]="SvgIconNameEnum.PinWMap"
      [iconColorPrimary]="SvgIconColorEnum.Cinnabar"
      [iconColorSecondary]="SvgIconColorEnum.Lochmara"
      [link]="'https://www.capstone.jobs/page/warehouse-opportunities'"
      [isExternalUrl]="true"
      dataCy="jobOpenings"
    ></app-menu-item>
    <app-menu-item
      title="Team Directory"
      i18n-title="@@resources-container_teamDirectory"
      subTitle="Team contact information"
      i18n-subTitle="@@resources-container_teamDirectorySubtitle"
      [iconName]="SvgIconNameEnum.Conversation"
      [iconColorPrimary]="SvgIconColorEnum.MountainMeadow"
      [iconColorSecondary]="SvgIconColorEnum.Lochmara"
      [link]="'team-directory'"
      dataCy="teamDirectory"
    ></app-menu-item>
  </app-menu>
</main>
