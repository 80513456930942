import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AccordionService } from './accordion.service';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { SvgIconName } from '../../enums/SvgIcon';
import { AnalyticsUtilitiesService } from '../../services/analytics-utilities.service';
import { AnalyticsActions } from '../../enums/AnalyticsActions';
import { AnalyticsCategories } from '../../enums/AnalyticsCategories';

let nextUniqueId = 0;

@Component({
  selector: 'app-accordion-panel',
  templateUrl: './accordion-panel.component.html',
  styleUrls: ['./accordion-panel.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          visibility: 'visible'
        })
      ),
      state(
        'close',
        style({
          height: 0,
          'min-height': 0,
          'padding-bottom': 0,
          'padding-top': 0,
          visibility: 'hidden'
        })
      ),
      transition('close => open', [
        animate('0.225s cubic-bezier(0.4, 0, 0.2, 1)')
      ]),
      transition('open => close', [
        animate('0.225s cubic-bezier(0.4, 0, 0.2, 1)')
      ])
    ])
  ]
})
export class AccordionPanelComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() bodySpacing = true;
  @Input() disabled = false;
  @Input() analyticsId: string;
  @Input() analyticsLabel: any;
  @ViewChild('panelTrigger', { static: false }) panelTrigger: ElementRef;
  isExpanded = false;
  SvgIconNameEnum = SvgIconName;
  protected defaultId = `accordion-${nextUniqueId++}`;
  protected componentId: string;

  mvrPanels: Array<string> = [
    'Motor Vehicle Report (MVR) Documents',
    'Documentos de informes de vehículos motorizados',
    'Motor Vehicle Report (MVR) Documents [California]',
    'Documentos de informes de vehículos motorizados [California]',
    'Motor Vehicle Report (MVR) Documents [New Hampshire]',
    'Documentos de informes de vehículos motorizados [New Hampshire]',
    'Motor Vehicle Report (MVR) Documents [Washington]',
    'Documentos de informes de vehículos motorizados [Washington]'
  ];

  constructor(
    public accordion: AccordionService,
    private analyticsUtilities: AnalyticsUtilitiesService,
    private element: ElementRef
  ) {
    this.id = this.id;
  }

  @Input()
  get id(): string {
    return this.componentId;
  }

  set id(value: string) {
    this.componentId = value || this.defaultId;
  }

  get sectionId() {
    return 'section-' + this.id;
  }

  get isOpen() {
    if (this.accordion.isMulti) {
      return this.isExpanded;
    } else {
      if (this.mvrPanels.includes(this.title)) {
        this.isExpanded = true;
        return true;
      } else {
        return this.accordion.expandedPanel === this;
      }
    }
  }

  onClick() {
    let isExpanded = false;
    if (this.accordion.isMulti) {
      isExpanded = this.isExpanded = !this.isExpanded;
    } else {
      this.accordion.toggleExpandPanel(this);
      isExpanded = this.accordion.expandedPanel === this;
    }
    this.panelTrigger.nativeElement.focus();

    if (this.analyticsId) {
      this.analyticsUtilities.customAnalyticsEvent(this.analyticsId, {
        value: isExpanded,
        label: this.analyticsLabel,
        action: AnalyticsActions.ToggleExpand,
        category: AnalyticsCategories.AccordionPanel
      });
    }
  }

  onFocus() {
    this.accordion.activePanel = this;
  }

  focus() {
    this.element.nativeElement.children[0].children[0].focus();
  }
}
