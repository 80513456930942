import { InjectionToken } from '@angular/core';
import { SiteMapItemKeys } from '../enums/SiteMapItemKeys';
import { SiteMapItemGroups } from '../enums/SiteMapItemGroups';

export const APP_SITE_MAP = new InjectionToken('app.site.map');

export interface SiteMapItem {
  key: SiteMapItemKeys;
  group?: string;
  children?: SiteMapItem[];
  mapToParentParams?: (params: any) => any;
  createUrlCommand?: (params: any) => any;
}

export const AppSiteMap: SiteMapItem = {
  key: SiteMapItemKeys.Root,
  createUrlCommand: () => [''],
  children: [
    {
      key: SiteMapItemKeys.NewsFeed,
      createUrlCommand: () => ['/news'],
      children: [
        {
          key: SiteMapItemKeys.NewsDetails,
          createUrlCommand: params => ['/news', params.id],
          children: [{ key: SiteMapItemKeys.PreviewNewsPost }]
        },
        { key: SiteMapItemKeys.DynamicContent }
      ]
    },
    {
      key: SiteMapItemKeys.Wages,
      createUrlCommand: () => ['/wages/history'],
      children: [
        {
          group: SiteMapItemGroups.WagesDetails,
          key: SiteMapItemKeys.WagesDetails,
          createUrlCommand: params => ['/wages/pay/', params.id],
          children: [
            {
              key: SiteMapItemKeys.GoalEdit
            }
          ]
        },
        {
          group: SiteMapItemGroups.WagesDetails,
          key: SiteMapItemKeys.Training
        },
        {
          group: SiteMapItemGroups.WagesDetails,
          key: SiteMapItemKeys.Goal
        }
      ]
    },
    {
      key: SiteMapItemKeys.RoadCrew,
      createUrlCommand: () => ['/roadcrew'],
      children: [
        {
          key: SiteMapItemKeys.OpportunityDetails
        },
        {
          key: SiteMapItemKeys.OpportunitiesList
        },
        {
          key: SiteMapItemKeys.RoadCrewApplication
        }
      ]
    },
    {
      key: SiteMapItemKeys.ShiftRequests,
      createUrlCommand: () => ['/shifts'],
      children: [
        { key: SiteMapItemKeys.ShiftDetails },
        { key: SiteMapItemKeys.ShiftCreate },
        { key: SiteMapItemKeys.ShiftEdit },
        { key: SiteMapItemKeys.ShiftPreview }
      ]
    },
    {
      key: SiteMapItemKeys.Resources,
      createUrlCommand: () => ['/resources'],
      children: [
        { key: SiteMapItemKeys.ResourcesFAQ },
        { key: SiteMapItemKeys.ResourcesTeam },
        {
          key: SiteMapItemKeys.ResourcesStars,
          createUrlCommand: () => ['/resources/stars'],
          children: [{ key: SiteMapItemKeys.ResourcesStar }]
        }
      ]
    },
    { key: SiteMapItemKeys.Survey },
    { key: SiteMapItemKeys.LandingPage },
    { key: SiteMapItemKeys.Disclaimer },
    { key: SiteMapItemKeys.Settings },
    {
      key: SiteMapItemKeys.MyTeam,
      createUrlCommand: params => ['/my-team'],
      children: [
        {
          key: SiteMapItemKeys.MyTeamMemberProduction,
          group: SiteMapItemGroups.TeamMemberDetails
        },
        {
          key: SiteMapItemKeys.MyTeamMemberCheckInHistory,
          group: SiteMapItemGroups.TeamMemberDetails
        }
      ]
    },
    { key: SiteMapItemKeys.WeeklyCheckIn },
    {
      key: SiteMapItemKeys.AssociateCheckInHistory,
      group: SiteMapItemGroups.AssociateProfile
    },
    {
      key: SiteMapItemKeys.AssociateProduction,
      group: SiteMapItemGroups.AssociateProfile,
      createUrlCommand: params => ['/profile/production'],
      children: [
        {
          key: SiteMapItemKeys.TakePhoto
        }
      ]
    },
    { key: SiteMapItemKeys.AddPwa },
    { key: SiteMapItemKeys.Covid }
  ]
};
