import { Injectable } from '@angular/core';
import { Survey } from '../../core/dataEntities/surveys/survey';
import { FormBuilder, Validators } from '@angular/forms';
import { Question } from '../../core/dataEntities/surveys/question';
import { SurveyQuestionTypes } from '../../core/enums/SurveyQuestionTypes';

@Injectable()
export class SurveyFormService {
  constructor(private fb: FormBuilder) {
  }

  createSurveyForm(survey: Survey) {
    if (!survey) {
      return;
    }
    return this.fb.group({
      id: { value: survey.id, disabled: true },
      title: { value: survey.title, disabled: true },
      subtitle: { value: survey.subtitle, disabled: true },
      description: { value: survey.description, disabled: true },
      questions: this.fb.array(
        survey.questions.map(this.createQuestionFormGroup.bind(this))
      )
    });
  }

  public createQuestionFormGroup(question: Question) {
    switch (question.type) {
      case SurveyQuestionTypes.YesNo:
        return this.createYesNoQuestionFormGroup(question);
      case SurveyQuestionTypes.Scale:
        return this.createScaleQuestionFormGroup(question);
      case SurveyQuestionTypes.Open:
        return this.createOpenQuestionFormGroup(question);
      default:
        throw new Error('Unknown Survey question type');
    }
  }

  public createYesNoQuestionFormGroup(question: Question) {
    const validators = [];
    return this.createQuestionFormGroupInternal(question, validators);
  }

  public createScaleQuestionFormGroup(question: Question) {
    const validators = [];
    return this.createQuestionFormGroupInternal(question, validators);
  }

  public createOpenQuestionFormGroup(question: Question) {
    const validators = [Validators.maxLength(150)];
    return this.createQuestionFormGroupInternal(question, validators);
  }

  private createQuestionFormGroupInternal(question: Question, validators) {
    if (question.required) {
      validators.push(Validators.required);
    }

    return this.fb.group({
      id: { value: question.id, disabled: true },
      question: { value: question.question, disabled: true },
      type: { value: question.type, disabled: true },
      answer: ['', validators]
    });
  }
}
