/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navigation-bar/navigation-bar.component.ngfactory";
import * as i3 from "./navigation-bar/navigation-bar.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../../node_modules/ng-sidebar/lib/sidebar-container.component.ngfactory";
import * as i6 from "@angular/common";
import * as i7 from "ng-sidebar/lib/sidebar-container.component";
import * as i8 from "../../../../node_modules/ng-sidebar/lib/sidebar.component.ngfactory";
import * as i9 from "ng-sidebar/lib/sidebar.component";
import * as i10 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-bar", [], null, null, null, i2.View_NavigationBarComponent_0, i2.RenderType_NavigationBarComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavigationBarComponent, [i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { drawer: 0 }), i1.ɵqud(671088640, 2, { content: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 13, "ng-sidebar-container", [], null, null, null, i5.View_SidebarContainer_0, i5.RenderType_SidebarContainer)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "sidebar-container": 0, "sidebar-container--open": 1 }), i1.ɵdid(6, 1753088, null, 0, i7.SidebarContainer, [i1.ChangeDetectorRef, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "ng-sidebar", [["data-cy", "accountPage"]], null, [[null, "onClosed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClosed" === en)) {
        var pd_0 = (((_co.drawerClosed == null) ? null : _co.drawerClosed.emit()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_Sidebar_0, i8.RenderType_Sidebar)), i1.ɵdid(8, 770048, [[1, 4], ["drawer", 4]], 0, i9.Sidebar, [[2, i7.SidebarContainer], i1.ChangeDetectorRef, i1.PLATFORM_ID], { opened: [0, "opened"], mode: [1, "mode"], trapFocus: [2, "trapFocus"], showBackdrop: [3, "showBackdrop"], closeOnClickOutside: [4, "closeOnClickOutside"], keyClose: [5, "keyClose"] }, { onClosed: "onClosed" }), (_l()(), i1.ɵeld(9, 0, [[2, 0], ["content", 1]], 0, 1, "div", [["class", "drawer"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(11, 0, null, 1, 4, "div", [["class", "page-content"], ["ng-sidebar-content", ""]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LayoutComponent_1)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, true, _co.isDrawerOpen); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.isDrawerOpen; var currVal_2 = "over"; var currVal_3 = true; var currVal_4 = true; var currVal_5 = true; var currVal_6 = true; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = (i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.hideNavigationBar$)) === false); _ck(_v, 14, 0, currVal_7); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 4308992, null, 0, i10.LayoutComponent, [i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("app-layout", i10.LayoutComponent, View_LayoutComponent_Host_0, { isDrawerOpen: "isDrawerOpen" }, { drawerClosed: "drawerClosed" }, ["[slot='drawer']", "*"]);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
