import { Surveys } from '../../../shared/enums/ApiResponseCodes';
import * as fromRoot from '../../../reducers';
import { first, map, switchMap } from 'rxjs/operators';
import moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/api.gateway.service";
import * as i2 from "../../../shared/services/content-delivery.service";
import * as i3 from "@ngrx/store";
export class SurveyService {
    constructor(apiGateway, contentDeliveryService, store) {
        this.apiGateway = apiGateway;
        this.contentDeliveryService = contentDeliveryService;
        this.store = store;
        this.surveysRemaindersKey = 'surveysRemainders';
        this.remainderFrequencyInHours = 24;
    }
    getAvailableSurveys(userId) {
        return this.store.select(fromRoot.getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.get(`/comms/surveys/contentful/current/${userId}`, authToken);
        }), map(response => {
            const surveys = response.currentSurveys || [];
            return surveys.map((s) => {
                return {
                    id: s.contentfulId,
                    title: s.title,
                    subtitle: s.subtitle
                };
            });
        }));
    }
    getSurvey(id) {
        return this.contentDeliveryService.getSurvey(id);
    }
    getUnsubmittedSurveys(userId) {
        return this.store.select(fromRoot.getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway
                .get(`comms/surveys/contentful/current/${userId}`, authToken)
                .pipe(map(response => {
                return (response &&
                    response.currentSurveys &&
                    !!response.currentSurveys.length);
            }));
        }));
    }
    submitSurvey(survey) {
        return this.store.select(fromRoot.getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway
                .post(`comms/surveys/contentful/submission`, authToken, survey)
                .map(response => {
                return {
                    success: response.message === Surveys.SURVEY_SUBMITTED_SUCCESSFULLY
                };
            });
        }));
    }
    showSurveyRemainder(userId, surveyId) {
        let remainders = this.getSurveysRemaindersFromLocalStorage();
        const now = moment.utc();
        const lastRemainder = remainders.find(user => user.userId === userId && user.surveyId === surveyId);
        if (lastRemainder == null) {
            remainders.push({
                userId,
                surveyId,
                lastNotifyDate: now.toISOString()
            });
            localStorage.setItem(this.surveysRemaindersKey, JSON.stringify(remainders));
            return true;
        }
        const durationDiff = moment
            .duration(now.diff(moment(lastRemainder.lastNotifyDate)))
            .asHours();
        if (durationDiff > this.remainderFrequencyInHours) {
            remainders = remainders.map(user => user.userId === userId && user.surveyId === surveyId
                ? Object.assign({}, user, { lastNotifyDate: now.toISOString() }) : user);
            localStorage.setItem(this.surveysRemaindersKey, JSON.stringify(remainders));
            return true;
        }
        return false;
    }
    getSurveyRemainderInfo(surveyId) {
        if (surveyId === 1) {
            return {
                surveyId: '1',
                remainderHeader: '2019 Associate Survey',
                remainderText: 'Your input is important. Please take this quick survey and provide your feedback.'
            };
        }
    }
    getSurveysRemaindersFromLocalStorage() {
        const surveys = localStorage.getItem(this.surveysRemaindersKey);
        if (surveys == null) {
            return [];
        }
        return JSON.parse(localStorage.getItem(this.surveysRemaindersKey));
    }
}
SurveyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SurveyService_Factory() { return new SurveyService(i0.ɵɵinject(i1.ApiGatewayService), i0.ɵɵinject(i2.ContentDeliveryService), i0.ɵɵinject(i3.Store)); }, token: SurveyService, providedIn: "root" });
class SurveyRemainder {
}
