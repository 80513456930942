/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../filter-modal-layout/filter-modal-layout.component.ngfactory";
import * as i4 from "../filter-modal-layout/filter-modal-layout.component";
import * as i5 from "@angular/forms";
import * as i6 from "angular-mydatepicker";
import * as i7 from "../../../../../shared/components/loader/loader.component.ngfactory";
import * as i8 from "../../../../../shared/components/loader/loader.component";
import * as i9 from "../../../../../shared/components/button-link/button-link.component.ngfactory";
import * as i10 from "../../../../../shared/components/button-link/button-link.component";
import * as i11 from "../../../../../shared/services/analytics-utilities.service";
import * as i12 from "./date-filter.component";
import * as i13 from "../../../../../core/contracts/OpportunitiesService";
import * as i14 from "../../../../../shared/modules/modal/dialog-ref";
import * as i15 from "../../../../../shared/modules/modal/dialog-config";
var styles_DateFilterComponent = [i0.styles];
var RenderType_DateFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DateFilterComponent, data: {} });
export { RenderType_DateFilterComponent as RenderType_DateFilterComponent };
function View_DateFilterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["opportunity"]))], null, null); }
function View_DateFilterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["opportunities"]))], null, null); }
function View_DateFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "p", [["class", "date-filter__summary"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateFilterComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateFilterComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵted(8, null, [" start on/after ", " "])), i1.ɵppd(9, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.totalCount; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.totalCount; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), _co.date.dateRange.beginJsDate, "MMMM d, y")); _ck(_v, 8, 0, currVal_2); }); }
export function View_DateFilterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 29, "app-filter-modal-layout", [], null, null, null, i3.View_FilterModalLayoutComponent_0, i3.RenderType_FilterModalLayoutComponent)), i1.ɵdid(2, 114688, null, 0, i4.FilterModalLayoutComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Filter By Start Date "])), (_l()(), i1.ɵeld(5, 0, null, 1, 20, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "date-filter__select-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setTodayDate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Select Todays Date "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h2", [["class", "date-filter__subheading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Job Starts After: "])), (_l()(), i1.ɵeld(10, 0, null, null, 11, "div", [["class", "mydatepicker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 16777216, null, null, 10, "input", [["angular-mydatepicker", ""], ["class", "input-box"], ["name", "mydate"], ["type", "hidden"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "rangeDateSelection"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup" === en)) {
        var pd_4 = (i1.ɵnov(_v, 16).onKeyUp($event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i1.ɵnov(_v, 16).onBlur() !== false);
        ad = (pd_5 && ad);
    } if (("rangeDateSelection" === en)) {
        var pd_6 = (_co.dateChanged($event) !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.date = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(512, null, i6.LocaleService, i6.LocaleService, []), i1.ɵprd(512, null, i6.UtilService, i6.UtilService, []), i1.ɵprd(512, null, i6.DefaultConfigService, i6.DefaultConfigService, []), i1.ɵdid(16, 671744, [["dp", 4]], 0, i6.AngularMyDatePickerDirective, [i6.LocaleService, i6.UtilService, i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.Renderer2, i1.ChangeDetectorRef, i1.ElementRef, i6.DefaultConfigService], { options: [0, "options"] }, { rangeDateSelection: "rangeDateSelection" }), i1.ɵprd(1024, null, i5.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i6.AngularMyDatePickerDirective]), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i5.DefaultValueAccessor, i6.AngularMyDatePickerDirective]), i1.ɵdid(19, 671744, null, 0, i5.NgModel, [[8, null], [6, i5.NG_VALIDATORS], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(21, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(22, 0, null, null, 1, "app-loader", [], [[2, "is-visible", null]], null, null, i7.View_LoaderComponent_0, i7.RenderType_LoaderComponent)), i1.ɵdid(23, 114688, null, 0, i8.LoaderComponent, [], { isLoading: [0, "isLoading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateFilterComponent_1)), i1.ɵdid(25, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(26, 0, null, 2, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "app-button-link", [["label", "Reset Filter"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.resetFilter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ButtonLinkComponent_0, i9.RenderType_ButtonLinkComponent)), i1.ɵdid(28, 638976, null, 0, i10.ButtonLinkComponent, [i11.AnalyticsUtilitiesService], { appearance: [0, "appearance"], label: [1, "label"] }, { buttonClick: "buttonClick" }), (_l()(), i1.ɵeld(29, 0, null, null, 1, "app-button-link", [["label", "Done"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.filter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ButtonLinkComponent_0, i9.RenderType_ButtonLinkComponent)), i1.ɵdid(30, 638976, null, 0, i10.ButtonLinkComponent, [i11.AnalyticsUtilitiesService], { appearance: [0, "appearance"], label: [1, "label"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_7 = _co.myDpOptions; _ck(_v, 16, 0, currVal_7); var currVal_8 = "mydate"; var currVal_9 = _co.date; _ck(_v, 19, 0, currVal_8, currVal_9); var currVal_11 = _co.countsLoading; _ck(_v, 23, 0, currVal_11); var currVal_12 = !_co.countsLoading; _ck(_v, 25, 0, currVal_12); var currVal_13 = _co.ButtonAppearanceEnum.Link; var currVal_14 = "Reset Filter"; _ck(_v, 28, 0, currVal_13, currVal_14); var currVal_15 = _co.ButtonAppearanceEnum.Link; var currVal_16 = "Done"; _ck(_v, 30, 0, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 21).ngClassValid; var currVal_5 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 21).ngClassPending; _ck(_v, 11, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_10 = i1.ɵnov(_v, 23).visible; _ck(_v, 22, 0, currVal_10); }); }
export function View_DateFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-date-filter", [], null, null, null, View_DateFilterComponent_0, RenderType_DateFilterComponent)), i1.ɵdid(1, 114688, null, 0, i12.DateFilterComponent, [i13.OpportunitiesService, i14.DialogRef, i15.DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DateFilterComponentNgFactory = i1.ɵccf("app-date-filter", i12.DateFilterComponent, View_DateFilterComponent_Host_0, {}, {}, []);
export { DateFilterComponentNgFactory as DateFilterComponentNgFactory };
