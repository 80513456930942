import { Component, OnInit } from '@angular/core';
import { SvgIconName } from '../../enums/SvgIcon';

@Component({
  selector: 'app-svg-defs',
  templateUrl: './svg-defs.component.html',
  styleUrls: ['./svg-defs.component.scss']
})
export class SvgDefsComponent implements OnInit {
  SvgIconNameEnum = SvgIconName;

  constructor() {}

  ngOnInit() {}
}
