import { forkJoin } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { SortDirections } from '../../../../core/enums/SortDirections';
import { isEmpty, keys, find } from 'lodash';
import { getAssociateHomeState, getAuthToken } from '../../../../reducers';
import { defaultOpportunitiesListFilter } from '../reducers/opportunities-list.reducer';
import { JobStatus } from '../../../../shared/enums/JobStatus';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/site.service";
import * as i2 from "../../../../shared/services/content-delivery.service";
import * as i3 from "../../../../shared/services/api.gateway.service";
import * as i4 from "@ngrx/store";
export class OpportunitiesApiService {
    constructor(siteService, contentDeliveryService, apiGateway, store, localeId) {
        this.siteService = siteService;
        this.contentDeliveryService = contentDeliveryService;
        this.apiGateway = apiGateway;
        this.store = store;
        this.localeId = localeId;
        this.roadCrewApiEndpoint = 'roadcrew';
        this.homeState = '';
        const associateHomeState$ = this.store.select(getAssociateHomeState);
        associateHomeState$.subscribe(val => {
            this.homeState = val;
        });
    }
    getDetails(opportunityId, otherId, associateId) {
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.get(`${this.roadCrewApiEndpoint}/get_opportunity_details`, authToken, {
                opportunityId,
                associateId,
                id: otherId,
                language: this.localeId
            });
        }));
    }
    getOpenShifts(siteId) {
        const query = {
            'fields.author.sys.contentType.sys.id': 'author',
            'fields.category.sys.contentType.sys.id': 'newsCategory',
            'fields.sites[in]': `${siteId}`,
            'fields.category.fields.key': 'workOpportunity'
        };
        return forkJoin(this.contentDeliveryService.getNews(0, 10, query), this.siteService.getSiteInfo(siteId)).pipe(map(([feed, siteInfo]) => {
            const shifts = [];
            feed.posts.forEach(post => {
                shifts.push({
                    id: post.id,
                    date: post.shift.startDateTime,
                    site: siteInfo
                });
            });
            return shifts;
        }));
    }
    getListOfAppliedOpportunities(associateId) {
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.get(`${this.roadCrewApiEndpoint}/opportunities/${associateId}/applied`, authToken);
        }));
    }
    getRoadCrewOpenings(associateId, params) {
        const requestParams = {
            associateId,
            start: params.page ? params.page.skip : 0,
            size: params.page ? params.page.limit : 25,
            associateHomeState: this.homeState
        };
        let requestBody = null;
        if (params.filter) {
            requestBody = Object.assign({}, this.prepareOpportunityListFilter(params.filter));
        }
        if (params.sort) {
            requestParams.sortBy = params.sort.active;
            requestParams.sortDirection =
                params.sort.direction === SortDirections.Asc
                    ? 'ascending'
                    : 'descending';
        }
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway
                .post(`${this.roadCrewApiEndpoint}/opportunities`, authToken, requestBody, requestParams)
                .pipe(map((response) => {
                if (isEmpty(response)) {
                    return null;
                }
                return response;
            }));
        }));
    }
    markAppliedOpportunityAsSeen(opportunityId, associateId) {
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.post(`${this.roadCrewApiEndpoint}/opportunities/mark_as_seen`, authToken, {
                opportunityId,
                associateId
            });
        }));
    }
    applyForOpportunity(opportunity, associateId, associateName, preferredAirport) {
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.post(`${this.roadCrewApiEndpoint}/opportunities/apply`, authToken, Object.assign({}, this.prepareApplyOpportunityObject(opportunity, associateId, associateName, preferredAirport)), {
                opportunityId: opportunity.opportunityID,
                associateId,
                preferredAirport
            });
        }));
    }
    cancelAppliedOpportunity(opportunityId, associateId) {
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.post(`${this.roadCrewApiEndpoint}/opportunities/cancel`, authToken, null, {
                opportunityId,
                associateId
            });
        }));
    }
    getAggregatedOpportunities(associateId, propertyName, filter) {
        const filters = this.prepareOpportunityListFilter(filter);
        const key = find(keys(filters), k => k.toLowerCase() === propertyName.toLowerCase());
        filters[key] = defaultOpportunitiesListFilter[key];
        const requestBody = Object.assign({}, filters);
        const requestParams = {
            associateId,
            type: 'byvalue',
            propertyName,
            associateHomeState: this.homeState
        };
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway
                .post(`${this.roadCrewApiEndpoint}/opportunities/aggregated`, authToken, requestBody, requestParams)
                .pipe(map((response) => {
                if (isEmpty(response)) {
                    return null;
                }
                return response;
            }));
        }));
    }
    getOpportunitiesCount(associateId, filter) {
        const filters = this.prepareOpportunityListFilter(filter);
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway
                .post(`${this.roadCrewApiEndpoint}/opportunities/count`, authToken, Object.assign({}, filters), {
                associateId,
                associateHomeState: this.homeState
            })
                .pipe();
        }));
    }
    getIsEachOpportunityReviewed(associateId) {
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway
                .get(`${this.roadCrewApiEndpoint}/opportunities/${associateId}/unseen`, authToken)
                .pipe(map(result => {
                return !result;
            }));
        }));
    }
    prepareApplyOpportunityObject(opportunity, associateId, associateName, preferredAirport) {
        return {
            id: opportunity.opportunityID,
            status: JobStatus.Applied,
            associate_eid: associateId,
            associate_name: associateName,
            preferred_airport: preferredAirport,
            opportunity_title: null,
            last_seen_on: opportunity.lastSeenOn,
            sub_status: opportunity.subStatus,
            startDate: opportunity.startDate,
            endDate: opportunity.endDate,
            expireDate: opportunity.expireDate,
            duration: opportunity.duration,
            responsiblePerson: opportunity.responsiblePerson,
            positions: opportunity.positions
        };
    }
    prepareOpportunityListFilter(filter) {
        return {
            duration: filter.duration || defaultOpportunitiesListFilter.duration,
            jobTitle: filter.jobTitle || defaultOpportunitiesListFilter.jobTitle,
            locationState: filter.locationState || defaultOpportunitiesListFilter.locationState,
            startDateFrom: filter.startDateFrom || defaultOpportunitiesListFilter.startDateFrom,
            query: filter.query || defaultOpportunitiesListFilter.query
        };
    }
}
OpportunitiesApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpportunitiesApiService_Factory() { return new OpportunitiesApiService(i0.ɵɵinject(i1.SiteService), i0.ɵɵinject(i2.ContentDeliveryService), i0.ɵɵinject(i3.ApiGatewayService), i0.ɵɵinject(i4.Store), i0.ɵɵinject(i0.LOCALE_ID)); }, token: OpportunitiesApiService, providedIn: "root" });
