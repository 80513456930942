import { of } from 'rxjs';
import { getAuthTokenWithApexUserId } from '../../reducers';
import { first, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.gateway.service";
import * as i2 from "@ngrx/store";
export class FeedService {
    constructor(apiGatewayService, storeService) {
        this.apiGatewayService = apiGatewayService;
        this.storeService = storeService;
    }
    getPostsAdditionalData(postsIds) {
        return this.storeService.select(getAuthTokenWithApexUserId).pipe(first(), switchMap(({ authToken, userId }) => {
            if (!userId) {
                throw new Error('User not found.');
            }
            if (postsIds.length === 0) {
                return of({
                    user: userId,
                    postLikeDetails: []
                });
            }
            return this.apiGatewayService.get(`comms/newsfeed/posts/likes/associate/${userId}`, authToken, {
                postIds: postsIds.join(',')
            });
        }));
    }
    likePost(postId, like) {
        return this.storeService.select(getAuthTokenWithApexUserId).pipe(first(), switchMap(({ authToken, userId }) => {
            if (!userId) {
                throw new Error('User not found.');
            }
            return this.apiGatewayService.put(`comms/newsfeed/post/${postId}/like/associate/${userId}`, authToken, {
                IsLikedByUser: like
            });
        }));
    }
}
FeedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeedService_Factory() { return new FeedService(i0.ɵɵinject(i1.ApiGatewayService), i0.ɵɵinject(i2.Store)); }, token: FeedService, providedIn: "root" });
