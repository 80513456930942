import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Features } from '../../../../../core/enums/Features';
import { Roles } from '../../../../../core/enums/Roles';
import {
  AppState,
  getHasRoadCrewAccess,
  getRoadCrewErrorMessage,
  getAssociateHomeState
} from 'src/app/reducers';
import { PageTitleAppearance } from '../../../../../shared/enums/PageTitle';

@Component({
  selector: 'app-opportunities-parent',
  templateUrl: './opportunities-parent.component.html',
  styleUrls: ['./opportunities-parent.component.scss']
})
export class OpportunitiesParentComponent implements OnInit {
  public isRoadCrewEnabled$: Observable<boolean>;
  public roadCrewRegistrationMissing$: Observable<string>;
  public associateHomeState$: Observable<string>;
  FeaturesEnum = Features;
  RolesEnum = Roles;
  PageTitleAppearanceEnum = PageTitleAppearance;

  cantRegisterForRoadCrewMsg = '';
  associateHomeState = '';

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.isRoadCrewEnabled$ = this.store.select(getHasRoadCrewAccess);
    this.roadCrewRegistrationMissing$ = this.store.select(
      getRoadCrewErrorMessage
    );
    this.roadCrewRegistrationMissing$.subscribe(val => {
      this.cantRegisterForRoadCrewMsg = val;
    });
    this.associateHomeState$ = this.store.select(getAssociateHomeState);
    this.associateHomeState$.subscribe(val => {
      this.associateHomeState = val;
    });
  }
}
