<app-form-field [required]="true">
  <app-label i18n="@@personal-details-edit_firstName">First Name</app-label>
  <app-text-input [formControl]="formGroup.get('firstName')"></app-text-input>
  <app-validation [errors]="formGroup.get('firstName').errors">
    <app-validation-message
      *ngIf="formGroup.get('firstName').errors?.required"
      i18n="@@personal-details-edit_firstNameRequired"
    >
      Your first name is required
    </app-validation-message>
  </app-validation>
</app-form-field>
<app-form-field [required]="true">
  <app-label i18n="@@personal-details-edit_lastName">Last Name</app-label>
  <app-text-input [formControl]="formGroup.get('lastName')"></app-text-input>
  <app-validation [errors]="formGroup.get('lastName').errors">
    <app-validation-message
      *ngIf="formGroup.get('lastName').errors?.required"
      i18n="@@personal-details-edit_lastNameRequired"
    >
      Your last name is required
    </app-validation-message>
  </app-validation>
</app-form-field>
<app-form-field [required]="true">
  <app-label i18n="@@personal-details-edit_sex">Sex</app-label>
  <app-select
    [formControl]="formGroup.get('sex')"
    [options]="sexOptions"
  ></app-select>
  <app-validation [errors]="formGroup.get('sex').errors">
    <app-validation-message
      *ngIf="formGroup.get('sex').errors?.required"
      i18n="@@personal-details-edit_sexRequired"
    >
      Please select sex
    </app-validation-message>
  </app-validation>
</app-form-field>
<app-form-field [required]="true">
  <app-label i18n="@@personal-details-edit_jobTitle">Job Title</app-label>
  <app-select
    [formControl]="formGroup.get('jobTitle')"
    [options]="jobTitleOptions"
    labelKey="label"
  ></app-select>
  <app-validation [errors]="formGroup.get('jobTitle').errors">
    <app-validation-message
      *ngIf="formGroup.get('jobTitle').errors?.required"
      i18n="@@personal-details-edit_jobTitleRequired"
    >
      Please select job title
    </app-validation-message>
  </app-validation>
</app-form-field>
