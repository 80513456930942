import { Component, Input } from '@angular/core';
import { WeekPayDetails } from '../../../core/dataEntities/wages/weekPayDetails';
import { PageHeaderAppearance } from '../../../shared/enums/PageHeader';

@Component({
  selector: 'app-earnings-header',
  templateUrl: './earnings-header.component.html',
  styleUrls: ['./earnings-header.component.scss']
})
export class EarningsHeaderComponent {
  PageHeaderAppearanceEnum = PageHeaderAppearance;

  @Input() weekPayDetails: WeekPayDetails;
  @Input() isHistoricalEarning: boolean;
  @Input() isFutureProcessing: boolean;

  constructor() {}
}
