import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProgressBarAppearance } from '../../enums/ProgressBar';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
  @Input() current: number;
  @Input() max: number;
  @Input() id = 'progress-bar';
  @Input() ariaLabelledBy: string;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() appearance: ProgressBarAppearance = ProgressBarAppearance.Green;
  @Input() displayValues = true;

  percentValue;
  isProgressMax: boolean;

  ProgressBarAppearanceEnum = ProgressBarAppearance;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.current || changes.max) {
      this.calcPercent();
    }
  }

  calcPercent() {
    const percents = (this.current / this.max) * 100;
    this.percentValue = percents > 100 ? 100 : percents;
    this.isProgressMax = percents >= 100;
  }
}
