import { Inject, LOCALE_ID } from '@angular/core';
import { ApiGatewayService } from './api.gateway.service';
import { Store } from '@ngrx/store';
import { AppState, getAuthToken } from '../../reducers';
import { Observable } from 'rxjs';
import { first, switchMap, map } from 'rxjs/operators';
import { StaticContentService } from '../../core/contracts/StaticContentService';

export class StaticContentApiService implements StaticContentService {
  constructor(
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>,
    @Inject(LOCALE_ID) protected localeId: string
  ) {}

  getRoadCrewStaticContent(fragments: any): Observable<any> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .get<any>(`roadcrew/persistentdata`, authToken, {
            fragments,
            language: this.localeId
          })
          .pipe(
            map(response => {
              return response.result;
            })
          );
      })
    );
  }

  getLoginTermsAndConditions(): Observable<any> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.get<any>(`roadcrew/loginterms`, authToken).pipe(
          map(response => {
            return this.localeId === 'en' ? response.english : response.spanish;
          })
        );
      })
    );
  }

  getScriptPinnedPost(): Observable<any> {
    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway.get<any>(`roadcrew/scriptstars`, authToken).pipe(
          map(response => {
            return this.localeId === 'en' ? response.english : response.spanish;
          })
        );
      })
    );
  }
}
