import { NgModule } from '@angular/core';

import { SharedComponentsModule } from '../shared/components/shared-components/shared-components.module';
import { SurveyComponent } from './survey.component';
import { SurveyRoutingModule } from './survey-routing.module';
import { SurveyService } from './services/mock/survey.service';
import { SurveysComponent } from './containers/surveys/surveys.component';
import { StoreModule } from '@ngrx/store';
import * as fromSurveys from './reducers/surveys.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SurveysEffects } from './effects/surveys.effects';
import { SurveyCardComponent } from './components/survey-card/survey-card.component';
import { SurveyDetailsComponent } from './containers/survey-details/survey-details.component';
import { SurveyFormService } from './services/surveyForm.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RemainderComponent } from './components/remainder/remainder.component';
import { ModalLayoutComponent } from '../shared/components/modal/modal-layout/modal-layout.component';
import { SurveyQuestionComponent } from './components/survey-question/survey-question.component';
import { PipesModule } from '../shared/pipes/pipes.module';

@NgModule({
  declarations: [
    SurveyComponent,
    SurveysComponent,
    SurveyCardComponent,
    SurveyDetailsComponent,
    RemainderComponent,
    SurveyQuestionComponent
  ],
  imports: [
    PipesModule,
    SharedComponentsModule,
    SurveyRoutingModule,
    StoreModule.forFeature(fromSurveys.surveysFeatureKey, fromSurveys.reducer),
    EffectsModule.forFeature([SurveysEffects]),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [SurveyService, SurveyFormService],
  exports: [
    SurveyComponent,
    SurveysComponent,
    SurveyCardComponent,
    ModalLayoutComponent
  ],
  entryComponents: [RemainderComponent]
})
export class SurveyModule {}
