export const environment = {
  production: true,
  version: '2.9.0.20221101.1',
  azureADTenantConfig: {
    clientID: '1898425f-b334-4f2c-bafd-06495c63b007',
    authority:
      'https://login.microsoftonline.com/9dab2a65-ed4d-474a-8978-65d857a5b21e',
    cacheLocation: 'localStorage',
    postLogoutRedirectUri: '/?isAutoLoggedOut=IS_AUTO_LOGOUT',
    validateAuthority: true,
    consentScopes: ['user.read']
  },
  applicationInsights: {
    instrumentationKey: '5ccf0a66-1f62-42ac-a406-70924d76a19a',
    accountId: '7c2446ae-d7a4-4230-a7a2-fbc7315f1ec5',
    role: 'prod'
  },
  capstoneApi: {
    host: 'https://fd.capstonelogistics.com',
    apiVersion: '2019-09-16',
    accessKey: null
  },
  features: {
    surveys: true,
    covid: true,
    opportunities: true
  },
  userIdle: {
    idle: 5,
    timeout: 600,
    ping: 2
  },
  contentDelivery: {
    space: 'i97a15rgt2sm',
    environment: 'master',
    accessToken: 'ua7Srm9X5ZXXOXZYsgWsk9tAsB6XxHK1OI-RMm8Mnu0',
    previewAccessToken: 'VdWx9sV8SehNNJ2xjg_24J993hYL_68ACDBU153DuOU'
  },
  hotjar: {
    siteId: '1712063'
  }
};
