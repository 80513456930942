<div
  *ngIf="isLoaderVisible$ | async"
  class="loader"
  [class.loader--full-height]="isFullHeight"
  role="status"
  aria-live="polite"
  aria-busy="true"
>
  <ng-lottie class="loader__loader" [options]="options"></ng-lottie>
</div>
