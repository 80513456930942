/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg-icon/svg-icon.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../svg-icon/svg-icon.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/router";
import * as i7 from "./button-link.component";
import * as i8 from "../../services/analytics-utilities.service";
var styles_ButtonLinkComponent = [i0.styles];
var RenderType_ButtonLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonLinkComponent, data: {} });
export { RenderType_ButtonLinkComponent as RenderType_ButtonLinkComponent };
function View_ButtonLinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-svg-icon", [], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { button__icon: 0, "button__icon--small": 1 }), i1.ɵdid(4, 638976, null, 0, i4.SvgIconComponent, [i5.DomSanitizer], { name: [0, "name"], colorPrimary: [1, "colorPrimary"], colorSecondary: [2, "colorSecondary"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, (_co.iconSize === _co.ButtonIconSizeEnum.Small)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.iconName; var currVal_2 = _co.iconColorPrimary; var currVal_3 = _co.iconColorSecondary; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ButtonLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_2)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ButtonLinkComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 0, 0, currVal_0); }); }
function View_ButtonLinkComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ButtonLinkComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ButtonLinkComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [], [[1, "aria-describedby", 0], [1, "analyticsId", 0], [1, "analyticsLabel", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(3, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], state: [1, "state"], routerLink: [2, "routerLink"] }, null), i1.ɵpod(4, { navigateBack: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_5)), i1.ɵdid(6, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_6)), i1.ɵdid(8, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.appearanceClass; _ck(_v, 2, 0, currVal_5); var currVal_6 = _co.queryParams; var currVal_7 = (_co.isBack ? _ck(_v, 4, 0, true) : null); var currVal_8 = _co.link; _ck(_v, 3, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = (_co.labelTemplate ? _co.labelTemplate : i1.ɵnov(_v.parent, 1)); _ck(_v, 6, 0, currVal_9); var currVal_10 = i1.ɵnov(_v.parent, 0); _ck(_v, 8, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.ariaDescribedBy ? _co.ariaDescribedBy : null); var currVal_1 = _co.analyticsId; var currVal_2 = _co.analyticsLabel; var currVal_3 = i1.ɵnov(_v, 3).target; var currVal_4 = i1.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_ButtonLinkComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ButtonLinkComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ButtonLinkComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [], [[8, "href", 4], [1, "aria-describedby", 0], [1, "analyticsId", 0], [1, "analyticsLabel", 0]], null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_8)), i1.ɵdid(4, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_9)), i1.ɵdid(6, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.appearanceClass; _ck(_v, 2, 0, currVal_4); var currVal_5 = (_co.labelTemplate ? _co.labelTemplate : i1.ɵnov(_v.parent, 1)); _ck(_v, 4, 0, currVal_5); var currVal_6 = i1.ɵnov(_v.parent, 0); _ck(_v, 6, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; var currVal_1 = (_co.ariaDescribedBy ? _co.ariaDescribedBy : null); var currVal_2 = _co.analyticsId; var currVal_3 = _co.analyticsLabel; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_ButtonLinkComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ButtonLinkComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ButtonLinkComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["data-cy", "button"]], [[1, "type", 0], [1, "disabled", 0], [1, "aria-describedby", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickButton($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_11)), i1.ɵdid(4, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_12)), i1.ɵdid(6, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.appearanceClass; _ck(_v, 2, 0, currVal_3); var currVal_4 = (_co.labelTemplate ? _co.labelTemplate : i1.ɵnov(_v.parent, 1)); _ck(_v, 4, 0, currVal_4); var currVal_5 = i1.ɵnov(_v.parent, 0); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = (_co.disabled ? true : null); var currVal_2 = (_co.ariaDescribedBy ? _co.ariaDescribedBy : null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ButtonLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["buttonIcon", 2]], null, 0, null, View_ButtonLinkComponent_1)), (_l()(), i1.ɵand(0, [["defaultLabelTemplate", 2]], null, 0, null, View_ButtonLinkComponent_3)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_4)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_7)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLinkComponent_10)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.link && !_co.isExternalUrl); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.link && _co.isExternalUrl); _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.link; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ButtonLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-link", [], null, null, null, View_ButtonLinkComponent_0, RenderType_ButtonLinkComponent)), i1.ɵdid(1, 638976, null, 0, i7.ButtonLinkComponent, [i8.AnalyticsUtilitiesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonLinkComponentNgFactory = i1.ɵccf("app-button-link", i7.ButtonLinkComponent, View_ButtonLinkComponent_Host_0, { appearance: "appearance", type: "type", size: "size", color: "color", fullWidth: "fullWidth", isExternalUrl: "isExternalUrl", link: "link", queryParams: "queryParams", label: "label", labelTemplate: "labelTemplate", iconName: "iconName", iconColorPrimary: "iconColorPrimary", iconColorSecondary: "iconColorSecondary", iconSize: "iconSize", disabled: "disabled", ariaDescribedBy: "ariaDescribedBy", isBack: "isBack", analyticsId: "analyticsId", analyticsLabel: "analyticsLabel" }, { buttonClick: "buttonClick" }, []);
export { ButtonLinkComponentNgFactory as ButtonLinkComponentNgFactory };
