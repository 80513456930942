import { createAction, props } from '@ngrx/store';
import { User } from '../dataEntities/user/user';
import { UserSettings } from '../dataEntities/user/userSettings';
import { UserInfoDetails } from '../dataEntities/user/userInfoDetails';

export const userLogin = createAction('[User] Login', props<{ user: User }>());

export const setAuthToken = createAction(
  '[User] Set User Auth Token',
  props<{ token: string }>()
);
export const userLogout = createAction(
  '[User] Logout',
  props<{ isAutoLogout: boolean }>()
);
export const agreeWithTerms = createAction('[User] Agree with terms');

export const userInfoLoaded = createAction(
  '[User] User Info Loaded',
  props<{ userInfo: UserInfoDetails }>()
);

export const saveUserSettings = createAction(
  '[User] Save User Settings',
  props<{ userSettings: UserSettings }>()
);

export const userSettingsSaved = createAction(
  '[User] User Settings Saved',
  props<{ userSettings: UserSettings }>()
);

export const takePhoto = createAction(
  '[User] Take Photo',
  props<{ photo: string }>()
);

export const savePhoto = createAction('[User] Save Photo');

export const photoSaved = createAction(
  '[User] Photo Saved',
  props<{ photo: string }>()
);

export const removePhoto = createAction('[User] Remove Photo');
export const photoRemoved = createAction('[User] Photo Removed');
