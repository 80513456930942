import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';

import { Options } from 'ng5-slider';
import { SliderAppearance, SliderAverage } from '../../enums/Slider';
import { Subscription } from 'rxjs';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import { ValidationStatus } from '../../enums/ValidationStatus';
import { MathHelpers } from '../../../../../shared/helpers/math.helpers';

@Component({
  selector: 'app-goal-slider',
  templateUrl: './goal-slider.component.html',
  styleUrls: ['./goal-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoalSliderComponent),
      multi: true
    }
  ]
})
export class GoalSliderComponent
  implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  formGroup: FormGroup;
  @Input() floor: number;
  @Input() ceil: number;
  @Input() step: number;
  @Input() label: string;
  @Input() ariaLabelId: string;
  @Input() appearance: SliderAppearance = SliderAppearance.Default;
  @Input() validationStatus: ValidationStatus;
  @Input() siteAverage: number;
  @Input() associateAverage?: number;
  @Input() averageFormat?: string;
  @Input() maxLimit: number;

  GoalValidationStatusEnum = ValidationStatus;
  SliderAverageEnum = SliderAverage;
  SvgIconNameEnum = SvgIconName;
  options: Options = {
    showTicks: true,
    showSelectionBar: true,
    hideLimitLabels: true,
    boundPointerLabels: false,
    ariaLabelledBy: 'slider-label'
  };
  siteAveragePercentValue: number;
  associateAveragePercentValue: number;
  activeAverage: SliderAverage;
  siteAverageFormatted: number;
  associateAverageFormatted: number;
  private formControlSubscription: Subscription;

  constructor(private fb: FormBuilder) {
    this.formGroup = this.fb.group({
      value: null
    });
  }

  ngOnInit() {
    this.formControlSubscription = this.formGroup
      .get('value')
      .valueChanges.subscribe(value => {
        this.propagateChange(value);
      });

    this.updateSlider(!!this.ariaLabelId);
  }

  ngOnDestroy() {
    this.formControlSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.floor ||
      changes.ceil ||
      changes.step ||
      changes.siteAverage ||
      changes.associateAverage ||
      changes.maxLimit
    ) {
      this.updateSlider();
    }
  }

  writeValue(value: any): void {
    this.formGroup.get('value').setValue(value, { emitEvent: false });
    this.setActiveAverage(this.formGroup.get('value').value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  updateSlider(updateAriaId = false) {
    this.siteAverageFormatted = MathHelpers.roundNumber(
      this.siteAverage,
      'en',
      this.averageFormat
    );
    this.associateAverageFormatted = MathHelpers.roundNumber(
      this.associateAverage,
      'en',
      this.averageFormat
    );

    const newOptions = {
      ...this.options,
      ceil: this.maxLimit < this.ceil ? this.maxLimit : this.ceil,
      floor: this.floor,
      step: this.step,
      ticksArray: [],
      maxLimit: this.maxLimit
    };
    this.ceil = newOptions.ceil;

    if (this.siteAverage) {
      newOptions.ticksArray.push(this.siteAverageFormatted);
    }

    if (this.associateAverage) {
      newOptions.ticksArray.push(this.associateAverageFormatted);
    }
    if (updateAriaId) {
      newOptions.ariaLabelledBy = this.ariaLabelId;
    }

    this.options = newOptions;
    this.associateAveragePercentValue = this.calcPercent(
      this.associateAverageFormatted
    );
    this.siteAveragePercentValue = this.calcPercent(this.siteAverageFormatted);
  }

  calcPercent(average) {
    return ((average - this.floor) * 100) / (this.ceil - this.floor);
  }

  onValueChange(event) {
    this.formGroup.get('value').setValue(event.value);
    this.setActiveAverage(event.value);
  }

  setSliderValue(value: number): void {
    this.formGroup.get('value').setValue(value);
    this.setActiveAverage(value);
    this.updateSlider();
  }

  setActiveAverage(value) {
    switch (true) {
      case this.associateAverageFormatted === value &&
        this.siteAverageFormatted === value:
        this.activeAverage = SliderAverage.All;
        break;
      case this.siteAverageFormatted === value:
        this.activeAverage = SliderAverage.Site;
        break;
      case this.associateAverageFormatted === value:
        this.activeAverage = SliderAverage.Personal;
        break;
      default:
        this.activeAverage = SliderAverage.None;
    }
  }

  private propagateChange = (value: any) => {};
}
