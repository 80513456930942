<app-modal-layout (closeModal)="closeModal()">
  <app-modal-header
    slot="modal-header"
    primaryText="Weekly"
    i18n-primaryText="@@about-weekly-observations_weekly"
    secondaryText="Observations"
    i18n-secondaryText="@@about-weekly-observations_observations"
  ></app-modal-header>
  <div slot="modal-content">
    <app-modal-content-row [sideSpacing]="true">
      <p i18n="@@about-weekly-observations_whenPracticing">
        When you practicing safe work, everyone stays safer.
      </p>
      <p i18n="@@about-weekly-observations_duringTraining">
        During your training, supervisors and managers will make 10 observations
        per week where they will offer safety tips and guidance.
      </p>
      <p i18n="@@about-weekly-observations_onceTrainingIsComplete">
        Once your training is complete, supervisors and managers will make 5
        observations per week.
      </p>
    </app-modal-content-row>
  </div>
</app-modal-layout>
