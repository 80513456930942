import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'merge'
})
export class MergePipe implements PipeTransform {
  transform(valueObj: any, ...args: any[]): any {
    let newObject = {
      ...valueObj
    };
    args.forEach(argument => {
      newObject = {
        ...newObject,
        ...argument
      };
    });
    return newObject;
  }
}
