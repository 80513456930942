import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-certification-readonly',
  templateUrl: './certification-readonly.component.html'
})
export class CertificationReadonlyComponent implements OnInit {
  @Input() formGroup: FormGroup;
  constructor() {}

  ngOnInit() {}
}
