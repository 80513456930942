import { Component, OnInit } from '@angular/core';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { Store } from '@ngrx/store';
import { AppState, selectActiveModule } from '../../../reducers';
import { Observable } from 'rxjs';
import { Module } from '../../../shared/enums/Module';
import { Roles } from '../../enums/Roles';
import { Features } from '../../enums/Features';
import * as fromRoot from '../../../reducers';
import { selectIsEachOpportunityReviewed } from '../../../wages/modules/work/reducers/opportunities.reducer';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
  SvgIconNameEnum = SvgIconName;
  ModuleEnum = Module;
  Roles = Roles;
  Features = Features;

  activeModule$: Observable<string>;
  isEachSurveySubmitted$: Observable<boolean>;
  isEachOpportunityReviewed$: Observable<boolean>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.activeModule$ = this.store.select(selectActiveModule);
    this.isEachSurveySubmitted$ = this.store.select(
      fromRoot.selectIsEachSurveySubmitted
    );
    this.isEachOpportunityReviewed$ = this.store.select(
      selectIsEachOpportunityReviewed
    );
  }
}
