import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ContentfulClientApi, Entry, EntryCollection } from 'contentful';
import { defer, EMPTY, from, Observable } from 'rxjs';
import {
  PostCategoryType,
  PostLinkType,
  PostSafetyTipType
} from '../../news/enums/PostTypes';
import { Item } from '../../core/responses/contentful/item';
import { ContentfulClientFactory } from './contentful-client-factory.service';
import { filter, first, map, retryWhen, switchMap } from 'rxjs/operators';
import { DynamicPage } from '../../core/dataEntities/dynamicContent/page';
import { Post } from '../../core/dataEntities/feed/post';
import { Feed } from '../../core/dataEntities/feed/feed';
import { ShiftDetails } from '../../core/dataEntities/shifts/shiftDetails';
import { ShiftContactTypes } from '../../core/enums/ShiftContactTypes';
import _ from 'lodash';
import { Locales } from '../../core/enums/Locales';
import { AppState, getIsAppOnline } from '../../reducers';
import { Store } from '@ngrx/store';
import { genericRetryStrategy } from '../../core/helpers/generic-retry-strategy';
import { LayoutActions } from '../../core/actions';
import { ExpandablePanel } from '../../core/dataEntities/dynamicContent/expandablePanel';
import { SurveyResponse } from '../../core/responses/surveys/surveyResponse';
import { QuestionResponse } from '../../core/responses/surveys/questionResponse';

@Injectable()
export class ContentDeliveryService {
  readonly contentfulLocale: string;
  private client: ContentfulClientApi;
  private previewClient: ContentfulClientApi;

  constructor(
    contentfulClientFactory: ContentfulClientFactory,
    @Inject(LOCALE_ID) localeId: string,
    private store: Store<AppState>
  ) {
    this.client = contentfulClientFactory.create();
    this.previewClient = contentfulClientFactory.createPreviewClient();
    this.contentfulLocale = this.getLocale(localeId);
  }

  getNewsCount(lastSeenDate, sitesIds) {
    return this.getEntriesCount({
      content_type: 'news',
      order: '-sys.createdAt',
      'sys.createdAt[gte]': lastSeenDate,
      'fields.sites[in]': `All,${sitesIds !== null ? sitesIds.join(',') : ''}`
    });
  }

  getShiftCount(managerId) {
    return this.getEntriesCount({
      content_type: 'news',
      'fields.author.sys.contentType.sys.id': 'author',
      'fields.category.sys.contentType.sys.id': 'newsCategory',
      'fields.author.fields.authorId': managerId,
      'fields.category.fields.key': 'workOpportunity'
    });
  }

  getEntriesCount(query) {
    return this.requestContentfulApi(() =>
      this.client.getEntries({
        ...query,
        skip: 0,
        limit: 0
      })
    ).pipe(
      map((result: EntryCollection<any>) => {
        return result.total;
      })
    );
  }

  getNews(skip: number, limit: number, query?: any): Observable<Feed> {
    return this.requestContentfulApi(() =>
      this.client.getEntries({
        content_type: 'news',
        locale: this.contentfulLocale,
        skip,
        limit,
        order: '-sys.createdAt',
        ...query
      })
    ).pipe(
      map((result: EntryCollection<any>) => {
        return {
          skip: result.skip,
          total: result.total,
          posts: this.mapNewsItems(result.items)
        } as Feed;
      })
    );
  }

  getPost(postId: string): Observable<Post> {
    return this.requestContentfulApi(() =>
      this.client.getEntry(postId, {
        locale: this.contentfulLocale
      })
    ).pipe(
      map((result: any) => {
        if (result) {
          const mappedPost = this.mapNewsItems([result]);
          return mappedPost[0];
        }
        return null;
      })
    );
  }

  getPostPreview(postId: string): Observable<Post> {
    return this.requestContentfulApi(() =>
      this.previewClient.getEntry(postId)
    ).pipe(
      map((result: any) => {
        if (result) {
          const mappedPost = this.mapNewsItems([result]);
          return mappedPost[0];
        }
        return null;
      })
    );
  }

  getPage(slug: string): Observable<DynamicPage> {
    return this.requestContentfulApi(() =>
      this.client.getEntries({
        locale: this.contentfulLocale,
        include: 2,
        content_type: 'page',
        'fields.slug': slug
      })
    ).pipe(
      map((result: EntryCollection<any>) => {
        if (result.items.length > 0) {
          return this.mapPageItem(result.items[0]);
        }

        return null;
      })
    );
  }

  getSurvey(surveyId): Observable<SurveyResponse> {
    return this.requestContentfulApi(() =>
      this.client.getEntry(surveyId, {
        locale: this.contentfulLocale
      })
    ).pipe(
      map((result: Entry<any>) => {
        return this.mapSurvey(result);
      })
    );
  }

  mapSurvey(survey: Entry<any>): SurveyResponse {
    return {
      id: survey.sys.id,
      title: survey.fields.title,
      subtitle: survey.fields.subtitle,
      description: survey.fields.description,
      questions: survey.fields.questions.map(this.mapQuestion.bind(this))
    };
  }

  mapQuestion(question: Entry<any>): QuestionResponse {
    return {
      id: question.sys.id,
      question: question.fields.question,
      type: question.fields.type,
      required: question.fields.required
    };
  }

  mapNewsItems(items: Entry<any>[]): Post[] {
    return items.map((item: Item) => {
      const author =
        item.fields && item.fields.author && item.fields.author.fields;
      const link =
        item.fields && item.fields.link ? item.fields.link.fields : null;
      const post = item.fields;
      const linkType =
        item.fields.link &&
        item.fields.link.sys &&
        item.fields.link.sys.contentType &&
        item.fields.link.sys.contentType.sys
          ? this.getLinkType(item.fields.link.sys.contentType.sys.id)
          : null;
      const category =
        post.category && post.category.fields
          ? this.getCategory(post.category.fields.key)
          : null;
      const safetyTipType =
        category === PostCategoryType.SafetyTip
          ? this.getSafetyTipType(
              post.link && post.link.fields && post.link.fields.type
            )
          : null;
      const authorModel = {
        id: author && author.authorId,
        firstName: author && author.firstName,
        lastName: author && author.lastName,
        profilePicture:
          author && author.image ? author.image.fields.file.url : null
      };

      return {
        id: item.sys.id,
        date: item.sys.createdAt,
        title: post.title,
        author: authorModel,
        summary: post.description,
        postImage: this.getPostPicture(post.picture, category),
        category,
        linkType,
        safetyTipType,
        externalPostUrl:
          linkType && linkType === PostLinkType.External ? link.url : null,
        internalBlogPost:
          linkType === PostLinkType.Internal
            ? {
                body: link.body,
                slug: link.slug,
                title: link.title
              }
            : null,
        shift:
          category === PostCategoryType.WorkOpportunity
            ? this.mapShiftPost(link, item.sys.id, authorModel, post.sites)
            : null,
        endDate: post.endDate,
        sites: post.sites
      } as Post;
    });
  }

  mapPageItem(item: Entry<any>): DynamicPage {
    const page = item.fields;
    return {
      id: page.slug,
      slug: page.slug,
      title: page.title,
      body: page.body.map(bodyItem => {
        const contentType =
          bodyItem.sys &&
          bodyItem.sys.contentType &&
          bodyItem.sys.contentType.sys &&
          bodyItem.sys.contentType.sys.id;
        return {
          expandablePanel:
            contentType === 'expandablePanel'
              ? ({
                  title: bodyItem.fields.title,
                  description: bodyItem.fields.description,
                  items: bodyItem.fields.items.map(panelItem => {
                    return {
                      title: panelItem.fields.title,
                      description: panelItem.fields.description
                    };
                  })
                } as ExpandablePanel)
              : null,
          text:
            contentType === 'textContent'
              ? { body: bodyItem.fields.body }
              : null
        };
      })
    } as DynamicPage;
  }

  getCategory(category: string): PostCategoryType {
    switch (category) {
      case 'announcement':
        return PostCategoryType.Announcement;
      case 'event':
        return PostCategoryType.Event;
      case 'alert':
        return PostCategoryType.Alert;
      case 'starAward':
        return PostCategoryType.Award;
      case 'workOpportunity':
        return PostCategoryType.WorkOpportunity;
      case 'birthday':
        return PostCategoryType.Birthday;
      case 'anniversary':
        return PostCategoryType.Anniversary;
      case 'safetyTip':
        return PostCategoryType.SafetyTip;
      default:
        return PostCategoryType.Congrats;
    }
  }

  getLinkType(linkType: string): PostLinkType {
    if (linkType === 'blogPost') {
      return PostLinkType.Internal;
    } else {
      return PostLinkType.External;
    }
  }

  getSafetyTipType(safetyTipLink: string): PostSafetyTipType {
    switch (safetyTipLink) {
      case 'Equipment':
        return PostSafetyTipType.Equipment;
      case 'Environment':
        return PostSafetyTipType.Environment;
      case 'General Safety':
        return PostSafetyTipType.GeneralSafety;
      default:
        return PostSafetyTipType.ManualLabor;
    }
  }

  getPostPicture(picture: any, category: PostCategoryType) {
    return picture
      ? picture.fields.file.url
      : category === PostCategoryType.WorkOpportunity
      ? 'assets/images/shift-post.svg'
      : category === PostCategoryType.Birthday
      ? 'assets/images/birthday-post.svg'
      : category === PostCategoryType.Anniversary
      ? 'assets/images/anniversary-post.svg'
      : null;
  }

  mapShiftPost(
    shift: any,
    newsEntryId: string,
    author: any,
    sites: string[]
  ): ShiftDetails {
    return {
      id: newsEntryId,
      slug: shift.slug,
      startDateTime: shift.startDateTime,
      siteId: sites ? (sites.length > 0 ? Number(_.first(sites)) : null) : null,
      contactType:
        shift.reachBy.length === 2
          ? ShiftContactTypes.Both
          : _.first(shift.reachBy) === 'Text'
          ? ShiftContactTypes.Text
          : ShiftContactTypes.Call,
      duration: shift.duration,
      associatesNeeded: shift.numberOfAssociates,
      manager: {
        ...author
      },
      phoneNumber: shift.phoneNumber,
      authorRole: shift.authorRole
    } as ShiftDetails;
  }

  private getLocale(localeId) {
    return localeId === Locales.es ? 'es-US' : 'en-US';
  }

  private requestContentfulApi(request) {
    return this.store.select(getIsAppOnline).pipe(
      filter(isAppOnline => isAppOnline != null),
      first(),
      switchMap(isAppOnline => {
        if (!isAppOnline) {
          this.store.dispatch(LayoutActions.apiOffline());
          return EMPTY;
        }
        return defer(() => from(request())).pipe(
          retryWhen(genericRetryStrategy())
        );
      })
    );
  }
}
