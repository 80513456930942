import { Component, OnInit } from '@angular/core';
import { PageHeaderAppearance } from '../../../../../shared/enums/PageHeader';
import { PageTitleAppearance } from '../../../../../shared/enums/PageTitle';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';
import { ShiftDetails } from '../../../../../core/dataEntities/shifts/shiftDetails';
import { Store } from '@ngrx/store';
import { AppState, selectShift } from '../../../../../reducers';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ShiftActions } from '../../actions';

@Component({
  selector: 'app-shift-preview',
  templateUrl: './shift-preview.component.html',
  styleUrls: ['./shift-preview.component.scss']
})
export class ShiftPreviewComponent implements OnInit {
  shiftDetails$: Observable<ShiftDetails>;
  PageHeaderAppearanceEnum = PageHeaderAppearance;
  PageTitleAppearanceEnum = PageTitleAppearance;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;
  siteName: string;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.shiftDetails$ = this.store.select(selectShift);
  }

  publishShift() {
    this.shiftDetails$.pipe(first()).subscribe(shift => {
      this.store.dispatch(
        shift.id
          ? ShiftActions.updateShift({ shift })
          : ShiftActions.createShift({ shift })
      );
    });
  }
}
