/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./radio-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./radio-input.component";
import * as i4 from "@angular/cdk/collections";
import * as i5 from "../radio-group.component";
var styles_RadioInputComponent = [i0.styles];
var RenderType_RadioInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RadioInputComponent, data: {} });
export { RenderType_RadioInputComponent as RenderType_RadioInputComponent };
export function View_RadioInputComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "input", [["type", "radio"]], [[8, "id", 0], [8, "checked", 0], [1, "name", 0], [1, "value", 0], [1, "aria-invalid", 0], [1, "aria-describedby", 0], [1, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onInputChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { option__input: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [["class", "option__label"]], [[1, "for", 0]], null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var currVal_7 = _ck(_v, 3, 0, true); _ck(_v, 2, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputId; var currVal_1 = _co.checked; var currVal_2 = _co.name; var currVal_3 = _co.value; var currVal_4 = _co.isInvalid; var currVal_5 = (_co.isInvalid ? _co.errorId : null); var currVal_6 = (_co.disabled ? "" : null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.inputId; _ck(_v, 4, 0, currVal_8); }); }
export function View_RadioInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-radio-input", [], null, null, null, View_RadioInputComponent_0, RenderType_RadioInputComponent)), i1.ɵdid(1, 114688, null, 0, i3.RadioInputComponent, [i4.UniqueSelectionDispatcher, [2, i5.RadioGroupComponent]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RadioInputComponentNgFactory = i1.ɵccf("app-radio-input", i3.RadioInputComponent, View_RadioInputComponent_Host_0, { disabled: "disabled", value: "value", checked: "checked" }, {}, ["*"]);
export { RadioInputComponentNgFactory as RadioInputComponentNgFactory };
