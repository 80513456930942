<ng-template #buttonIcon>
  <app-svg-icon
    *ngIf="iconName"
    [ngClass]="{
      button__icon: true,
      'button__icon--small': iconSize === ButtonIconSizeEnum.Small
    }"
    [name]="iconName"
    [colorPrimary]="iconColorPrimary"
    [colorSecondary]="iconColorSecondary"
  ></app-svg-icon>
</ng-template>

<ng-template #defaultLabelTemplate>
  {{ label }}
</ng-template>

<a
  *ngIf="link && !isExternalUrl"
  [routerLink]="link"
  [queryParams]="queryParams"
  [attr.aria-describedby]="ariaDescribedBy ? ariaDescribedBy : null"
  [ngClass]="appearanceClass"
  [state]="isBack ? { navigateBack: true } : null"
  [attr.analyticsId]="analyticsId"
  [attr.analyticsLabel]="analyticsLabel"
>
  <ng-container
    *ngTemplateOutlet="labelTemplate ? labelTemplate : defaultLabelTemplate"
  ></ng-container>
  <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
</a>

<a
  *ngIf="link && isExternalUrl"
  [href]="link"
  [attr.aria-describedby]="ariaDescribedBy ? ariaDescribedBy : null"
  [ngClass]="appearanceClass"
  [attr.analyticsId]="analyticsId"
  [attr.analyticsLabel]="analyticsLabel"
>
  <ng-container
    *ngTemplateOutlet="labelTemplate ? labelTemplate : defaultLabelTemplate"
  ></ng-container>
  <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
</a>

<button
  data-cy="button"
  *ngIf="!link"
  [attr.type]="type"
  [attr.disabled]="disabled ? true : null"
  [attr.aria-describedby]="ariaDescribedBy ? ariaDescribedBy : null"
  (click)="onClickButton($event)"
  [ngClass]="appearanceClass"
>
  <ng-container
    *ngTemplateOutlet="labelTemplate ? labelTemplate : defaultLabelTemplate"
  ></ng-container>
  <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
</button>
