import { Observable, Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { filter, take } from 'rxjs/operators';
import { hasModifierKey } from '@angular/cdk/keycodes';
import { InfoBoxContainerComponent } from './info-box-container.component';
import { InfoBoxResult } from '../info-box.service';

export const enum InfoBoxState {
  OPEN,
  CLOSING,
  CLOSED
}

export class InfoBoxRef<T, R = any> {
  componentInstance: T;

  disableClose: boolean | undefined = false;
  // @ts-ignore TS6133
  public state = InfoBoxState.OPEN;
  private readonly afterOpenedSubject = new Subject<void>();
  private readonly afterClosedSubject = new Subject<InfoBoxResult<R>>();
  private readonly beforeClosedSubject = new Subject<InfoBoxResult<R>>();
  private closeFallbackTimeout: any;
  private result: InfoBoxResult<R>;

  constructor(
    private overlayRef: OverlayRef,
    public containerInstance: InfoBoxContainerComponent
  ) {
    containerInstance.animationStateChanged
      .pipe(
        filter(
          event => event.phaseName === 'done' && event.toState === 'enter'
        ),
        take(1)
      )
      .subscribe(() => {
        this.afterOpenedSubject.next();
        this.afterOpenedSubject.complete();
      });

    containerInstance.animationStateChanged
      .pipe(
        filter(event => event.phaseName === 'done' && event.toState === 'exit'),
        take(1)
      )
      .subscribe(() => {
        clearTimeout(this.closeFallbackTimeout);
        this.overlayRef.dispose();
      });

    overlayRef.detachments().subscribe(() => {
      this.beforeClosedSubject.next(this.result);
      this.beforeClosedSubject.complete();
      this.afterClosedSubject.next(this.result);
      this.afterClosedSubject.complete();
      this.componentInstance = null;
      this.overlayRef.dispose();
      this.state = InfoBoxState.CLOSED;
    });

    overlayRef
      .keydownEvents()
      .pipe(
        filter(event => {
          return (
            event.code === 'Escape' &&
            !this.disableClose &&
            !hasModifierKey(event)
          );
        })
      )
      .subscribe(event => {
        event.preventDefault();
        this.close();
      });
  }

  close(infoBoxResult?: InfoBoxResult<R>): void {
    this.result = infoBoxResult;

    this.containerInstance.animationStateChanged
      .pipe(
        filter(event => event.phaseName === 'start'),
        take(1)
      )
      .subscribe(event => {
        this.beforeClosedSubject.next(infoBoxResult);
        this.beforeClosedSubject.complete();
        this.state = InfoBoxState.CLOSED;
        this.overlayRef.detachBackdrop();

        this.closeFallbackTimeout = setTimeout(() => {
          this.overlayRef.dispose();
        }, event.totalTime + 100);
      });

    this.containerInstance.startExitAnimation();
    this.state = InfoBoxState.CLOSING;
  }

  afterOpened(): Observable<void> {
    return this.afterOpenedSubject.asObservable();
  }

  afterClosed(): Observable<InfoBoxResult<R>> {
    return this.afterClosedSubject.asObservable();
  }

  beforeClosed(): Observable<InfoBoxResult<R>> {
    return this.beforeClosedSubject.asObservable();
  }

  backdropClick(): Observable<MouseEvent> {
    return this.overlayRef.backdropClick();
  }

  keydownEvents(): Observable<KeyboardEvent> {
    return this.overlayRef.keydownEvents();
  }
}
