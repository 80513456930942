import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiStepFormComponent } from './multi-step-form.component';
import { CanStepBeEditablePipe } from './pipes/can-step-be-editable.pipe';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MultiStepFormEditableStepComponent } from './steps/multi-step-form-editable-step/multi-step-form-editable-step.component';
import { MultiStepFormReadonlyStepComponent } from './steps/multi-step-form-readonly-step/multi-step-form-readonly-step.component';
import {
  EditableStepDefDirective,
  FormStepDefDirective,
  ReadOnlyStepDefDirective
} from './directives/form-step-def.directive';
import { GetNextEditableStepTitlePipe } from './pipes/get-next-editable-step-title.pipe';
import { PortalModule } from '@angular/cdk/portal';

const components = [
  FormStepDefDirective,
  MultiStepFormComponent,
  ReadOnlyStepDefDirective,
  EditableStepDefDirective
];

@NgModule({
  declarations: [
    ...components,
    CanStepBeEditablePipe,
    MultiStepFormEditableStepComponent,
    MultiStepFormReadonlyStepComponent,
    GetNextEditableStepTitlePipe
  ],
  exports: [
    ...components,
    MultiStepFormReadonlyStepComponent,
    MultiStepFormEditableStepComponent
  ],
  imports: [
    CommonModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    PortalModule
  ]
})
export class MultiStepFormModule {}
