import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { SvgIconName } from '../../../enums/SvgIcon';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  @Input() primaryText: string;
  @Input() secondaryText: string;
  @Input() secondaryTextTemplate: TemplateRef<any>;
  @Input() date: string;
  @Input() dateRange: string;
  @Input() iconName: SvgIconName;

  constructor() { }

  ngOnInit() {
  }

}
