import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { SurveyDetailsComponent } from './containers/survey-details/survey-details.component';
import { SurveyGuard } from './guards/survey.guard';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { DisabledGuard } from '../core/guards/disabled/disabled.guard';
import { environment } from '../../environments/environment';

const routes: Routes = [
  {
    path: 'survey/:id',
    component: SurveyDetailsComponent,
    canActivate: [AuthenticationGuard, DisabledGuard, SurveyGuard],
    data: {
      canActivate: environment.features.surveys,
      hideNavigationBar: true,
      siteMapKey: SiteMapItemKeys.Survey
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveyRoutingModule {}
