/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-location.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/modal/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../../../../shared/components/modal/modal-layout/modal-layout.component";
import * as i4 from "../../../../../shared/components/modal/modal-header/modal-header.component.ngfactory";
import * as i5 from "../../../../../shared/components/modal/modal-header/modal-header.component";
import * as i6 from "../../../../../shared/components/modal/modal-content-row/modal-content-row.component.ngfactory";
import * as i7 from "../../../../../shared/components/modal/modal-content-row/modal-content-row.component";
import * as i8 from "../../../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i9 from "../../../../../shared/components/svg-icon/svg-icon.component";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../../../../shared/components/button-link/button-link.component.ngfactory";
import * as i12 from "../../../../../shared/components/button-link/button-link.component";
import * as i13 from "../../../../../shared/services/analytics-utilities.service";
import * as i14 from "./confirm-location.component";
import * as i15 from "../../../../../shared/modules/modal/dialog-ref";
import * as i16 from "../../../../../shared/modules/modal/dialog-config";
var styles_ConfirmLocationComponent = [i0.styles];
var RenderType_ConfirmLocationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmLocationComponent, data: {} });
export { RenderType_ConfirmLocationComponent as RenderType_ConfirmLocationComponent };
export function View_ConfirmLocationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "app-modal-layout", [], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalLayoutComponent, [], null, { closeModal: "closeModal" }), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-modal-header", [["primaryText", "Confirm"], ["secondaryText", "Location"], ["slot", "modal-header"]], null, null, null, i4.View_ModalHeaderComponent_0, i4.RenderType_ModalHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i5.ModalHeaderComponent, [], { primaryText: [0, "primaryText"], secondaryText: [1, "secondaryText"] }, null), (_l()(), i1.ɵeld(4, 0, null, 1, 13, "div", [["class", "confirm-location"], ["slot", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "app-modal-content-row", [], null, null, null, i6.View_ModalContentRowComponent_0, i6.RenderType_ModalContentRowComponent)), i1.ɵdid(6, 114688, null, 0, i7.ModalContentRowComponent, [], { sideSpacing: [0, "sideSpacing"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-svg-icon", [["class", "confirm-location__icon"]], null, null, null, i8.View_SvgIconComponent_0, i8.RenderType_SvgIconComponent)), i1.ɵdid(8, 638976, null, 0, i9.SvgIconComponent, [i10.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You may be asked to fly for this job. Is your preferred airport is still "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", "?"])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "confirm-location__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-button-link", [["label", "Yes"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.confirmAirport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ButtonLinkComponent_0, i11.RenderType_ButtonLinkComponent)), i1.ɵdid(15, 638976, null, 0, i12.ButtonLinkComponent, [i13.AnalyticsUtilitiesService], { appearance: [0, "appearance"], label: [1, "label"] }, { buttonClick: "buttonClick" }), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-button-link", [["label", "Change Airport"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.changeAirport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ButtonLinkComponent_0, i11.RenderType_ButtonLinkComponent)), i1.ɵdid(17, 638976, null, 0, i12.ButtonLinkComponent, [i13.AnalyticsUtilitiesService], { appearance: [0, "appearance"], color: [1, "color"], label: [2, "label"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Confirm"; var currVal_1 = "Location"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = true; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.SvgIconNameEnum.CautionLocation; _ck(_v, 8, 0, currVal_3); var currVal_5 = _co.ButtonAppearanceEnum.Link; var currVal_6 = "Yes"; _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_7 = _co.ButtonAppearanceEnum.Link; var currVal_8 = _co.ButtonColorEnum.Red; var currVal_9 = "Change Airport"; _ck(_v, 17, 0, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.preferredAirport; _ck(_v, 12, 0, currVal_4); }); }
export function View_ConfirmLocationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-location", [], null, null, null, View_ConfirmLocationComponent_0, RenderType_ConfirmLocationComponent)), i1.ɵdid(1, 49152, null, 0, i14.ConfirmLocationComponent, [i15.DialogRef, i16.DIALOG_DATA], null, null)], null, null); }
var ConfirmLocationComponentNgFactory = i1.ɵccf("app-confirm-location", i14.ConfirmLocationComponent, View_ConfirmLocationComponent_Host_0, {}, {}, []);
export { ConfirmLocationComponentNgFactory as ConfirmLocationComponentNgFactory };
