import { Action, createReducer, on } from '@ngrx/store';
import { LayoutActions, UserActions } from '../../../../core/actions';
import { ShiftDetails } from '../../../../core/dataEntities/shifts/shiftDetails';
import { SubDepartments } from '../../../../core/dataEntities/managers/subDepartments';
import { ShiftActions } from '../actions';
import { ShiftListItem } from '../../../../core/dataEntities/shifts/shiftListItem';

export const shiftFeatureKey = 'shift';

export interface ShiftState {
  shiftDetails: ShiftDetails;
  sites: SubDepartments;
  managerShiftRequests: ShiftListItem[];
  managerShiftRequestsCount: number;
  isShiftDetailsLoading: boolean;
  isSitesLoading: boolean;
  isManagerShiftsLoading: boolean;
}

export const initialState: ShiftState = {
  shiftDetails: null,
  sites: null,
  managerShiftRequests: null,
  managerShiftRequestsCount: null,
  isShiftDetailsLoading: false,
  isSitesLoading: false,
  isManagerShiftsLoading: false
};
export const shiftReducer = createReducer(
  initialState,
  on(ShiftActions.loadShiftAndSites, (state, action) => {
    return {
      ...state,
      isSitesLoading: true,
      isShiftDetailsLoading: true
    };
  }),
  on(ShiftActions.loadSites, (state, action) => {
    return {
      ...state,
      isSitesLoading: true
    };
  }),
  on(ShiftActions.sitesListLoaded, (state, action) => {
    const sites = {
      accessSubDepartments: action.response.accessSubDepartments
    } as SubDepartments;

    return {
      ...state,
      isSitesLoading: false,
      sites
    };
  }),
  on(ShiftActions.loadShift, (state, action) => {
    return {
      ...state,
      isShiftDetailsLoading: true
    };
  }),
  on(ShiftActions.previewShift, (state, action) => {
    return {
      ...state,
      shiftDetails: action.shift
    };
  }),
  on(ShiftActions.shiftLoaded, (state, action) => {
    return {
      ...state,
      shiftDetails: action.shift,
      isShiftDetailsLoading: false
    };
  }),
  on(ShiftActions.shiftCreated, (state, action) => {
    return {
      ...state,
      shiftDetails: null,
      managerShiftRequests: null,
      managerShiftRequestsCount: state.managerShiftRequestsCount + 1
    };
  }),
  on(ShiftActions.shiftUpdated, (state, action) => {
    return {
      ...state,
      shiftDetails: null
    };
  }),
  on(ShiftActions.getShiftCount, (state, action) => {
    return {
      ...state,
      shiftCount: null
    };
  }),
  on(ShiftActions.shiftRemoved, (state, action) => {
    const shifts = state.managerShiftRequests.filter(
      shift => shift.id !== action.shiftId
    );

    return {
      ...state,
      managerShiftRequests: shifts,
      managerShiftRequestsCount: state.managerShiftRequestsCount - 1
    };
  }),
  on(ShiftActions.shiftCountLoaded, (state, action) => {
    return {
      ...state,
      managerShiftRequestsCount: action.response
    };
  }),
  on(ShiftActions.loadManagerShifts, (state, action) => {
    return {
      ...state,
      isManagerShiftsLoading: true
    };
  }),
  on(ShiftActions.clearShift, (state, action) => {
    return {
      ...state,
      shiftDetails: null
    };
  }),
  on(ShiftActions.managerShiftsLoaded, (state, action) => {
    return {
      ...state,
      managerShiftRequests: action.shifts,
      isManagerShiftsLoading: false
    };
  }),
  on(ShiftActions.errorOccuredOnShiftLoading, state => {
    return {
      ...state,
      shiftDetails: null,
      isShiftDetailsLoading: false
    };
  }),
  on(LayoutActions.showError, LayoutActions.apiOffline, state => {
    return {
      ...state,
      isShiftDetailsLoading: false,
      isSitesLoading: false
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(state: ShiftState | undefined, action: Action) {
  return shiftReducer(state, action);
}

export const selectShift = (state: ShiftState) => {
  return state.shiftDetails;
};

export const selectSites = (state: ShiftState) => {
  return state.sites;
};

export const selectIsShiftLoading = (state: ShiftState) => {
  return state.isShiftDetailsLoading;
};

export const selectIsSitesLoading = (state: ShiftState) => {
  return state.isSitesLoading;
};

export const getManagerShiftRequestsCount = (state: ShiftState) => {
  return state.managerShiftRequestsCount;
};

export const selectManagerShifts = (state: ShiftState) => {
  return state.managerShiftRequests;
};

export const selectIsManagerShiftsLoading = (state: ShiftState) => {
  return state.isManagerShiftsLoading;
};
