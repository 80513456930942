import { Component, Input, OnInit } from '@angular/core';
import { TrainingStatus } from '../../../enums/TrainingStatus';

@Component({
  selector: 'app-card-layout-timeline',
  templateUrl: './card-layout-timeline.component.html',
  styleUrls: ['./card-layout-timeline.component.scss']
})
export class CardLayoutTimelineComponent implements OnInit {
  @Input() trainingStatus: TrainingStatus;

  public TrainingStatusEnum = TrainingStatus;

  constructor() {}

  ngOnInit() {}
}
