import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  AppState,
  getActiveWeekGoal,
  getActiveWeekPerformance,
  selectActiveWeekPayDetails,
  selectIsGoalLoading,
  selectIsPayLoading,
  selectIsPerformanceLoading,
  selectIsUserTrainee
} from '../../../reducers';
import { first, map, shareReplay, filter } from 'rxjs/operators';
import moment from 'moment';
import { WeekPayDetails } from '../../../core/dataEntities/wages/weekPayDetails';
import { GoalDetails } from '../../../core/dataEntities/goal/goalDetails';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { WagesActions } from '../../actions';
import { GoalActions } from '../../modules/goal/actions';
import { PerformanceDetails } from '../../../core/dataEntities/goal/performanceDetails';
import { SlidersConfig } from '../../modules/goal/config/slidersConfig';
import { ItemType } from '../../modules/goal/enums/ItemType';
import _ from 'lodash';
import { ButtonColor, ButtonType } from '../../../shared/enums/ButtonLink';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { DateTimeUtils } from '../../../shared/utils/DateTimeUtils';

@Component({
  selector: 'app-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.scss']
})
export class GoalComponent implements OnInit {
  public ButtonTypeEnum = ButtonType;

  public weekPayDetails$: Observable<WeekPayDetails>;
  public isHistoricalEarning$: Observable<boolean>;
  public isFutureProcessing$: Observable<boolean>;
  public isPayLoading$: Observable<boolean>;
  public isGoalLoading: Observable<boolean>;
  public isPerformanceLoading: Observable<boolean>;
  public isTrainee$: Observable<boolean>;
  public goalDetails$: Observable<GoalDetails>;
  public performance$: Observable<PerformanceDetails>;

  public isHistoricalGoal: boolean;
  public currentItemsPerHour: number;
  public goalItemsPerHour: number;
  public currentWorkedHours: number;
  public netRevenueStats: {
    goalRevenuePerItem: number;
    avgRevenuePerItem: number;
    siteAvgRevenuePerItem: number;
  };
  public PageTitleAppearanceEnum = PageTitleAppearance;
  public ButtonColorEnum = ButtonColor;
  public linkToGoalPage;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      return this.store.dispatch(
        WagesActions.loadPaymentWeekDetails({
          weekEndingDate: params.get('id')
        })
      );
    });
  }

  ngOnInit() {
    const weekPayDetails$ = this.store.select(selectActiveWeekPayDetails);
    const goalDetails$ = this.store.select(getActiveWeekGoal);
    const performance$ = this.store.select(getActiveWeekPerformance, {
      config: SlidersConfig
    });

    this.isPayLoading$ = this.store.select(selectIsPayLoading);
    this.isGoalLoading = this.store.select(selectIsGoalLoading);
    this.isPerformanceLoading = this.store.select(selectIsPerformanceLoading);
    this.isTrainee$ = this.store.select(selectIsUserTrainee);

    this.weekPayDetails$ = combineLatest(
      weekPayDetails$,
      this.isPayLoading$
    ).pipe(
      map(([weekPayDetails, isPayLoading]) => {
        if (!weekPayDetails || isPayLoading) {
          return null;
        }
        return weekPayDetails;
      })
    );

    this.goalDetails$ = combineLatest(goalDetails$, this.isGoalLoading).pipe(
      map(([goalDetails, isGoalLoading]) => {
        if (!goalDetails || isGoalLoading) {
          return null;
        }
        return goalDetails;
      })
    );

    this.performance$ = combineLatest(
      performance$,
      this.isPerformanceLoading
    ).pipe(
      map(([performance, isPerformanceLoading]) => {
        if (!performance || isPerformanceLoading) {
          return null;
        }
        return performance;
      })
    );

    this.isHistoricalEarning$ = this.weekPayDetails$.pipe(
      map(weekPayDetails => {
        if (!weekPayDetails) {
          return;
        }
        return moment(weekPayDetails.weekEndingDate).isSameOrBefore(moment());
      }),
      shareReplay(1)
    );

    this.isFutureProcessing$ = this.weekPayDetails$.pipe(
      map(weekPayDetails => {
        if (!weekPayDetails) {
          return;
        }
        return moment(weekPayDetails.paymentProcessDate).isAfter(moment());
      }),
      shareReplay(1)
    );

    combineLatest(this.goalDetails$, this.performance$, this.route.paramMap)
      .pipe(first())
      .subscribe(([goalDetails, performance, params]) => {
        const weekEndingDate = params.get('week');
        if (goalDetails == null) {
          this.store.dispatch(
            GoalActions.loadWeeklyGoal({
              weekEndingDate
            })
          );
        }

        if (performance == null) {
          this.store.dispatch(
            GoalActions.loadWeeklyPerformance({
              weekEndingDate
            })
          );
        }
      });

    combineLatest(this.goalDetails$, this.performance$)
      .pipe(
        filter(
          ([goalDetails, performance]) =>
            goalDetails != null && performance != null
        ),
        first()
      )
      .subscribe(([goalDetails, performance]) => {
        this.netRevenueStats = {
          goalRevenuePerItem:
            goalDetails.itemType === ItemType.Pallets
              ? goalDetails.revenuePerPallet
              : goalDetails.revenuePerCase,
          avgRevenuePerItem:
            goalDetails.itemType === ItemType.Pallets
              ? performance.associateProductivityAverage.productivityMetrics
                  .revenuePerPallet
              : performance.associateProductivityAverage.productivityMetrics
                  .revenuePerCase,
          siteAvgRevenuePerItem:
            goalDetails.itemType === ItemType.Pallets
              ? performance.siteProductivityAverage.productivityMetrics
                  .revenuePerPallet
              : performance.siteProductivityAverage.productivityMetrics
                  .revenuePerCase
        };

        this.goalItemsPerHour =
          goalDetails.itemType === ItemType.Pallets
            ? goalDetails.palletsPerHour
            : goalDetails.casesPerHour;

        this.isHistoricalGoal = moment(goalDetails.id).isSameOrBefore(moment());

        this.currentItemsPerHour = _.round(
          goalDetails.itemType === ItemType.Pallets
            ? performance.associateCurrentWeekProductivity.productivityMetrics
                .palletsPerHour
            : performance.associateCurrentWeekProductivity.productivityMetrics
                .casesPerHour
        );

        this.currentWorkedHours = _.round(
          performance.associateCurrentWeekProductivity.hours,
          1
        );

        this.linkToGoalPage = this.isHistoricalGoal
          ? ['/goal', DateTimeUtils.getCurrentWeekEndDate()]
          : ['/goal', goalDetails.id];
      });
  }

  editGoal() {
    this.router.navigate(this.linkToGoalPage);
  }
}
