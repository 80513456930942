/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../card-layout/card-layout/card-layout.component.ngfactory";
import * as i2 from "../../../card-layout/card-layout/card-layout.component";
import * as i3 from "../../../card-layout/card-layout-item/card-layout-item.component.ngfactory";
import * as i4 from "../../../card-layout/card-layout-item/card-layout-item.component";
import * as i5 from "../../../accordion/accordion-panel.component.ngfactory";
import * as i6 from "../../../accordion/accordion-panel.component";
import * as i7 from "../../../accordion/accordion.service";
import * as i8 from "../../../../services/analytics-utilities.service";
import * as i9 from "../../../accordion/accordion-header-primary/accordion-header-primary.component.ngfactory";
import * as i10 from "../../../accordion/accordion-header-primary/accordion-header-primary.component";
import * as i11 from "../../../accordion/accordion.component.ngfactory";
import * as i12 from "../../../accordion/accordion.component";
import * as i13 from "@angular/common";
import * as i14 from "./production-details-observations.component";
var styles_ProductionDetailsObservationsComponent = [];
var RenderType_ProductionDetailsObservationsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProductionDetailsObservationsComponent, data: {} });
export { RenderType_ProductionDetailsObservationsComponent as RenderType_ProductionDetailsObservationsComponent };
function View_ProductionDetailsObservationsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-card-layout", [], [[8, "className", 0]], null, null, i1.View_CardLayoutComponent_0, i1.RenderType_CardLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i2.CardLayoutComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "app-card-layout-item", [], [[8, "className", 0]], null, null, i3.View_CardLayoutItemComponent_0, i3.RenderType_CardLayoutItemComponent)), i0.ɵdid(3, 114688, null, 0, i4.CardLayoutItemComponent, [[2, i2.CardLayoutComponent]], null, null), (_l()(), i0.ɵted(-1, 0, [" No observation found "]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 3).class; _ck(_v, 2, 0, currVal_1); }); }
function View_ProductionDetailsObservationsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-accordion-panel", [], null, null, null, i5.View_AccordionPanelComponent_0, i5.RenderType_AccordionPanelComponent)), i0.ɵdid(1, 49152, [[1, 4]], 0, i6.AccordionPanelComponent, [i7.AccordionService, i8.AnalyticsUtilitiesService, i0.ElementRef], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "app-accordion-header-primary", [["slot", "header"]], null, null, null, i9.View_AccordionHeaderPrimaryComponent_0, i9.RenderType_AccordionHeaderPrimaryComponent)), i0.ɵdid(3, 114688, null, 0, i10.AccordionHeaderPrimaryComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"] }, null), i0.ɵppd(4, 2), (_l()(), i0.ɵeld(5, 0, null, 1, 1, "p", [["slot", "body"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_0 = (((_v.context.index + 1) + ". ") + _v.context.$implicit.behavior); var currVal_1 = i0.ɵunv(_v, 3, 1, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.date, "EEEE, MM/dd")); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.action; _ck(_v, 6, 0, currVal_2); }); }
function View_ProductionDetailsObservationsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-accordion", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_AccordionComponent_0, i11.RenderType_AccordionComponent)), i0.ɵprd(512, null, i7.AccordionService, i7.AccordionService, []), i0.ɵdid(2, 1294336, null, 1, i12.AccordionComponent, [i7.AccordionService], null, null), i0.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ProductionDetailsObservationsComponent_3)), i0.ɵdid(5, 278528, null, 0, i13.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.safetyObservation; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ProductionDetailsObservationsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i13.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductionDetailsObservationsComponent_1)), i0.ɵdid(2, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductionDetailsObservationsComponent_2)), i0.ɵdid(4, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.safetyObservation.length === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.safetyObservation.length > 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProductionDetailsObservationsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-production-details-observations", [], null, null, null, View_ProductionDetailsObservationsComponent_0, RenderType_ProductionDetailsObservationsComponent)), i0.ɵdid(1, 114688, null, 0, i14.ProductionDetailsObservationsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductionDetailsObservationsComponentNgFactory = i0.ɵccf("app-production-details-observations", i14.ProductionDetailsObservationsComponent, View_ProductionDetailsObservationsComponent_Host_0, { safetyObservation: "safetyObservation" }, {}, []);
export { ProductionDetailsObservationsComponentNgFactory as ProductionDetailsObservationsComponentNgFactory };
