<app-modal-layout (closeModal)="closeModal()">
  <app-modal-header
    slot="modal-header"
    primaryText="Exception Earnings"
    i18n-primaryText="@@exception-earnings-details_exceptionEarnings"
    secondaryText="BreakDown"
    i18n-secondaryText="@@exception-earnings-details_breakDown"
    [date]="taskDetails.date"
  ></app-modal-header>

  <div slot="modal-content">
    <app-modal-content-row [sideSpacing]="true">
      <p i18n="@@exception-earnings-details_exceptionEarningsBreakdownInfo">
        You may qualify for Exception Earnings to compensate for additional work requirements or outstanding issues.
      </p>
    </app-modal-content-row>

    <table cdk-table [dataSource]="tableData.cellData" class="table table--small">
      <caption class="visually-hidden" i18n="@@exception-earnings-details_exceptionEarningsBreakdown">Exception earnings breakdown</caption>
      <ng-container cdkColumnDef="exceptionEarningsType">
        <th cdk-header-cell *cdkHeaderCellDef scope="col" i18n="@@exception-earnings-details_exceptionEarningsType">Exception Type</th>
        <td cdk-cell *cdkCellDef="let element" class="table__cell table__cell--bold">
          <ng-container>
            {{ element.exceptionEarningsType }}
          </ng-container>
        </td>
        <td cdk-footer-cell *cdkFooterCellDef class="table__cell table__cell--bold">
          <ng-container i18n="@@exception-earnings-details_totalHourlyEarnings">Total Exception Earnings</ng-container>
        </td>
      </ng-container>
      <ng-container cdkColumnDef="earnings">
        <th cdk-header-cell *cdkHeaderCellDef scope="col" class="table__cell table__cell--number" i18n="@@exception-earnings-details_total">
          Total
        </th>
        <td cdk-cell *cdkCellDef="let element" class="table__cell table__cell--number">
          <ng-container>
            {{ element.earnings | currency }}
          </ng-container>
        </td>
        <td cdk-footer-cell *cdkFooterCellDef class="table__cell table__cell--number">{{ tableData.footerData | currency }}</td>
      </ng-container>

      <tr cdk-header-row *cdkHeaderRowDef="columns"></tr>
      <tr cdk-row *cdkRowDef="let row; columns: columns"></tr>
      <tr cdk-footer-row *cdkFooterRowDef="columns"></tr>
    </table>
  </div>
</app-modal-layout>
