/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./about-check-in.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/modal/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../../shared/components/modal/modal-layout/modal-layout.component";
import * as i4 from "../../../shared/components/modal/modal-header/modal-header.component.ngfactory";
import * as i5 from "../../../shared/components/modal/modal-header/modal-header.component";
import * as i6 from "../../../shared/components/modal/modal-content-row/modal-content-row.component.ngfactory";
import * as i7 from "../../../shared/components/modal/modal-content-row/modal-content-row.component";
import * as i8 from "./about-check-in.component";
import * as i9 from "../../../shared/modules/modal/dialog-ref";
var styles_AboutCheckInComponent = [i0.styles];
var RenderType_AboutCheckInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AboutCheckInComponent, data: {} });
export { RenderType_AboutCheckInComponent as RenderType_AboutCheckInComponent };
function View_AboutCheckInComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" About Check-Ins "]))], null, null); }
export function View_AboutCheckInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "app-modal-layout", [], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalLayoutComponent, [], null, { closeModal: "closeModal" }), (_l()(), i1.ɵand(0, [["secondaryText", 2]], null, 0, null, View_AboutCheckInComponent_1)), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-modal-header", [["slot", "modal-header"]], null, null, null, i4.View_ModalHeaderComponent_0, i4.RenderType_ModalHeaderComponent)), i1.ɵdid(4, 114688, null, 0, i5.ModalHeaderComponent, [], { secondaryTextTemplate: [0, "secondaryTextTemplate"] }, null), (_l()(), i1.ɵeld(5, 0, null, 1, 10, "div", [["slot", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 9, "app-modal-content-row", [], null, null, null, i6.View_ModalContentRowComponent_0, i6.RenderType_ModalContentRowComponent)), i1.ɵdid(7, 114688, null, 0, i7.ModalContentRowComponent, [], { sideSpacing: [0, "sideSpacing"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "h2", [["class", "about-check-in__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" What's are weekly Check-In? "])), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Weekly Check-Ins are a quickly way to get your feedback on how your week went "])), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "h2", [["class", "about-check-in__heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" What happens when I fill one out? "])), (_l()(), i1.ɵeld(14, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your site manager can see every associate's check-in score. This helps them keep track of who's feeling successful and who can use some help. "]))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2); _ck(_v, 4, 0, currVal_0); var currVal_1 = true; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_AboutCheckInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-about-check-in", [], null, null, null, View_AboutCheckInComponent_0, RenderType_AboutCheckInComponent)), i1.ɵdid(1, 49152, null, 0, i8.AboutCheckInComponent, [i9.DialogRef], null, null)], null, null); }
var AboutCheckInComponentNgFactory = i1.ɵccf("app-about-check-in", i8.AboutCheckInComponent, View_AboutCheckInComponent_Host_0, {}, {}, []);
export { AboutCheckInComponentNgFactory as AboutCheckInComponentNgFactory };
