import {BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal} from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener, Inject,
  Optional, ViewChild
} from '@angular/core';
import {AnimationEvent} from '@angular/animations';
import {FocusTrap, FocusTrapFactory} from '@angular/cdk/a11y';
import {DOCUMENT} from '@angular/common';
import { DialogConfig } from '../dialog-config';

export function throwMatDialogContentAlreadyAttachedError() {
  throw Error('Attempting to attach dialog content after content is already attached');
}

export class BaseModalContainerComponent extends BasePortalOutlet {

  private focusTrap: FocusTrap;
  private elementFocusedBeforeDialogWasOpened: HTMLElement | null = null;

  state: 'void' | 'enter' | 'exit' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();
  id: string;

  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;

  constructor(
    private elementRef: ElementRef,
    private focusTrapFactory: FocusTrapFactory,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(DOCUMENT) private document: any,
    public config: DialogConfig) {
    super();
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this.portalOutlet.hasAttached()) {
      throwMatDialogContentAlreadyAttachedError();
    }

    this.savePreviouslyFocusedElement();
    return this.portalOutlet.attachComponentPortal(portal);
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    if (this.portalOutlet.hasAttached()) {
      throwMatDialogContentAlreadyAttachedError();
    }

    this.savePreviouslyFocusedElement();
    return this.portalOutlet.attachTemplatePortal(portal);
  }

  private trapFocus() {
    const element = this.elementRef.nativeElement;

    if (!this.focusTrap) {
      this.focusTrap = this.focusTrapFactory.create(element);
    }
    if (this.config.autoFocus) {
      this.focusTrap.focusInitialElementWhenReady();
    } else {
      const activeElement = this.document.activeElement;
      if (activeElement !== element && !element.contains(activeElement)) {
        element.focus();
      }
    }
  }

  private restoreFocus() {
    const toFocus = this.elementFocusedBeforeDialogWasOpened;
    if (this.config.restoreFocus && toFocus && typeof toFocus.focus === 'function') {
      toFocus.focus();
    }

    if (this.focusTrap) {
      this.focusTrap.destroy();
    }
  }

  private savePreviouslyFocusedElement() {
    if (this.document) {
      this.elementFocusedBeforeDialogWasOpened = this.document.activeElement as HTMLElement;

      if (this.elementRef.nativeElement.focus) {
        Promise.resolve().then(() => this.elementRef.nativeElement.focus());
      }
    }
  }

  @HostListener('@dialogContainer.done', ['$event'])
  onAnimationDone(event: AnimationEvent) {
    if (event.toState === 'enter') {
      this.trapFocus();
    } else if (event.toState === 'exit') {
      this.restoreFocus();
    }

    this.animationStateChanged.emit(event);
  }

  @HostListener('@dialogContainer.start', ['$event'])
  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation(): void {
    this.state = 'exit';
    this.changeDetectorRef.markForCheck();
  }
}
