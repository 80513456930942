<app-form-field [required]="true">
  <app-label i18n="@@licence-edit_preferredAirport">
    Preferred airport departure
  </app-label>
  <app-autocomplete
    [formControl]="formGroup.get('preferredAirport')"
    [data]="airports"
    displayKey="display"
    i18n-placeholder="@@licence-edit_preferredAirportPlaceholder"
    placeholder="City, Airport Name, or Airport Code"
  ></app-autocomplete>
  <app-validation [errors]="formGroup.get('preferredAirport').errors">
    <app-validation-message
      *ngIf="formGroup.get('preferredAirport').errors?.required"
      i18n="@@licence-edit_preferredAirportRequired"
    >
      Enter preferred departure airport
    </app-validation-message>
  </app-validation>
</app-form-field>
<app-notice
  class="licence-edit__airport-notice"
  [iconName]="SvgIconName.Airplane"
  i18n="@@licence-edit_airportNotice"
>
  Capstone may not always be able to use your preferred airport.
</app-notice>
<app-form-field [required]="true">
  <app-label i18n="@@licence-edit_isDriver">
    Select a travel preference
  </app-label>
  <app-radio-group [formControl]="formGroup.get('isDriver')">
    <app-radio-input [value]="driverOptions[0].value">
      {{ driverOptions[0].label }}
    </app-radio-input>
    <app-radio-input [value]="driverOptions[1].value">
      {{ driverOptions[1].label }}
    </app-radio-input>
  </app-radio-group>
  <app-validation [errors]="formGroup.get('isDriver').errors">
    <app-validation-message
      *ngIf="formGroup.get('isDriver').errors?.required"
      i18n="@@licence-edit_isDriverRequired"
    >
      Answer to this question is mandatory
    </app-validation-message>
  </app-validation>
</app-form-field>
<ng-container
  *ngTemplateOutlet="
    formGroup.get('isDriver').value != null
      ? formGroup.get('isDriver').value
        ? driver
        : nonDriver
      : null
  "
></ng-container>

<ng-template #nonDriver>
  <app-form-field [required]="true">
    <app-label i18n="@@licence-edit_typeOfID">Type of ID</app-label>
    <app-select
      [formControl]="formGroup.get('typeOfID')"
      [options]="typeOfIDOptions"
      [placeholder]="'Select ID type'"
      i18n-placeholder="@@licence-edit_typeOfIDPlaceholder"
    ></app-select>
    <app-validation
      [errors]="
        formGroup.get('typeOfID').errors
          | merge: (formGroup.errors?.childErrors)['typeOfID']
      "
    >
      <app-validation-message
        *ngIf="
          (
            formGroup.get('typeOfID').errors
            | merge: (formGroup.errors?.childErrors)['typeOfID']
          )?.required
        "
        i18n="@@licence-edit_typeOfIDRequired"
      >
        Select ID type
      </app-validation-message>
    </app-validation>
  </app-form-field>
  <app-form-field [required]="true">
    <app-document
      [documentsType]="DocumentTypeEnum.ID"
      [formControl]="formGroup.get('documents')"
      [addDocumentButtonLabelTemplate]="addValidIdButtonTemplate"
      [previewDocumentButtonLabelTemplate]="reviewTemplate"
      [hasInsurance]="hasInsurance"
      [errors]="
        (
          formGroup.get('documents').errors
          | merge: (formGroup.errors?.childErrors)['documents']
        )?.required
      "
    ></app-document>
    <app-validation
      [errors]="
        formGroup.get('documents').errors
          | merge: (formGroup.errors?.childErrors)['documents']
      "
    >
      <app-validation-message
        *ngIf="
          (
            formGroup.get('documents').errors
            | merge: (formGroup.errors?.childErrors)['documents']
          )?.required
        "
        i18n="@@licence-edit_nonDriverDocumentsRequired"
      >
        Photo of your ID is required
      </app-validation-message>
    </app-validation>
  </app-form-field>
  <app-form-field [required]="true">
    <app-label i18n="@@licence-edit_IDExpirationDate">
      ID Expiration Date
    </app-label>
    <app-date-picker
      (dateChangeFromChild)="dateChangeFromChild($event)"
      [formControl]="formGroup.get('IDExpirationDate')"
      format="YYYY-MM-DD"
      [selectedState]="
        selectedState || formGroup.get('licenceIssuedState').value
      "
      [documentType]="documentType"
    ></app-date-picker>
    <app-validation
      [errors]="
        formGroup.get('IDExpirationDate').errors
          | merge: (formGroup.errors?.childErrors)['IDExpirationDate']
      "
    >
      <app-validation-message
        *ngIf="
          (
            formGroup.get('IDExpirationDate').errors
            | merge: (formGroup.errors?.childErrors)['IDExpirationDate']
          )?.required
        "
        i18n="@@licence-edit_IDExpirationDateRequired"
      >
        Enter expiration date listed on your ID
      </app-validation-message>
      <app-validation-message
        *ngIf="
          (
            formGroup.get('IDExpirationDate').errors
            | merge: (formGroup.errors?.childErrors)['IDExpirationDate']
          )?.minDate
        "
        i18n="@@licence-edit_IDExpirationDateMinDate"
      >
        Date has to be later than today
      </app-validation-message>
    </app-validation>
  </app-form-field>
</ng-template>

<ng-template #driver>
  <app-form-field [required]="true">
    <app-label i18n="@@licence-edit_isDriverHasInsurance">
      Do you have personal car insurance?
    </app-label>
    <app-radio-group [formControl]="formGroup.get('hasInsurance')">
      <app-radio-input [value]="driverInsuranceOptions[0].value">
        {{ driverInsuranceOptions[0].label }}
      </app-radio-input>
      <app-radio-input [value]="driverInsuranceOptions[1].value">
        {{ driverInsuranceOptions[1].label }}
      </app-radio-input>
    </app-radio-group>
    <app-validation [errors]="formGroup.get('hasInsurance').errors">
      <app-validation-message
        *ngIf="formGroup.get('hasInsurance').errors?.required"
        i18n="@@licence-edit_edit_isDriverHasInsuranceRequired"
      >
        Answer to this question is mandatory
      </app-validation-message>
    </app-validation>
  </app-form-field>
  <app-notice class="licence-edit__notice" i18n="@@licence-edit_driverNotice">
    <p>
      Capstone Logistics will run a Motor Vehicle Record search on associates
      who opt in to drive.
    </p>
    <p>
      This will return a 3-year of history point history, violations,
      convictions and license status on your driving record.
    </p>
  </app-notice>
  <app-form-field [required]="true">
    <app-document
      [documentsType]="DocumentTypeEnum.License"
      [formControl]="formGroup.get('documents')"
      [addDocumentButtonLabelTemplate]="addLicenceAndInsuranceButtonTemplate"
      [previewDocumentButtonLabelTemplate]="reviewTemplate"
      [hasInsurance]="hasInsurance"
      [errors]="
        (
          formGroup.get('documents').errors
          | merge: (formGroup.errors?.childErrors)['documents']
        )?.required
      "
    ></app-document>
    <app-validation
      [errors]="
        formGroup.get('documents').errors
          | merge: (formGroup.errors?.childErrors)['documents']
      "
    >
      <app-validation-message
        *ngIf="
          (
            formGroup.get('documents').errors
            | merge: (formGroup.errors?.childErrors)['documents']
          )?.required
        "
        i18n="@@licence-edit_driversDocumentsRequired"
      >
        Photos of your State Issued License are required
      </app-validation-message>
    </app-validation>
  </app-form-field>
  <app-form-field [required]="true">
    <app-label i18n="@@licence-edit_licenceIssuedState">
      license Issued In
    </app-label>
    <app-select
      i18n-placeholder="@@licence-edit_licenceIssuedStatePlaceholder"
      [placeholder]="'Select a state'"
      [formControl]="formGroup.get('licenceIssuedState')"
      [options]="stateOptions"
    ></app-select>
    <app-validation
      [errors]="
        formGroup.get('licenceIssuedState').errors
          | merge: (formGroup.errors?.childErrors)['licenceIssuedState']
      "
    >
      <app-validation-message
        *ngIf="
          (
            formGroup.get('licenceIssuedState').errors
            | merge: (formGroup.errors?.childErrors)['licenceIssuedState']
          )?.required
        "
        i18n="@@licence-edit_licenceIssuedStateRequired"
      >
        Select the state your ID was issued in
      </app-validation-message>
    </app-validation>
  </app-form-field>
  <app-form-field [required]="true">
    <app-label i18n="@@licence-edit_licenceNumber">license Number</app-label>
    <app-text-input
      i18n-placeholder="@@licence-edit_licenceNumberPlaceholder"
      [placeholder]="'Eg. A123-4455-9876'"
      [formControl]="formGroup.get('licenceNumber')"
    ></app-text-input>
    <app-validation
      [errors]="
        formGroup.get('licenceNumber').errors
          | merge: (formGroup.errors?.childErrors)['licenceNumber']
      "
    >
      <app-validation-message
        *ngIf="
          (
            formGroup.get('licenceNumber').errors
            | merge: (formGroup.errors?.childErrors)['licenceNumber']
          )?.required
        "
        i18n="@@licence-edit_licenceNumberRequired"
      >
        Enter license number
      </app-validation-message>
    </app-validation>
  </app-form-field>
  <app-form-field [required]="true">
    <app-label i18n="@@licence-edit_licenceExpirationDate">
      license Expiration Date
    </app-label>
    <app-date-picker
      (dateChangeFromChild)="dateChangeFromChild($event)"
      [formControl]="formGroup.get('licenceExpirationDate')"
      dayPlaceholder="DD"
      monthPlaceholder="MM"
      yearPlaceholder="YY"
      [selectedState]="
        selectedState || formGroup.get('licenceIssuedState').value
      "
      documentType="driversLicense"
    ></app-date-picker>
    <app-validation
      [errors]="
        formGroup.get('licenceExpirationDate').errors
          | merge: (formGroup.errors?.childErrors)['licenceExpirationDate']
      "
    >
      <app-validation-message
        *ngIf="
          (
            formGroup.get('licenceExpirationDate').errors
            | merge: (formGroup.errors?.childErrors)['licenceExpirationDate']
          )?.required
        "
        i18n="@@licence-edit_licenceExpirationDateRequired"
      >
        Enter expiration date listed on your licence
      </app-validation-message>
      <app-validation-message
        *ngIf="
          (
            formGroup.get('licenceExpirationDate').errors
            | merge: (formGroup.errors?.childErrors)['licenceExpirationDate']
          )?.minDate
        "
        i18n="@@licence-edit_licenceExpirationDateMinDate"
      >
        Date has to be later than today
      </app-validation-message>
    </app-validation>
  </app-form-field>
  <app-form-field
    *ngIf="formGroup.get('hasInsurance').value === true"
    [required]="formGroup.get('hasInsurance').value === true"
  >
    <app-label i18n="@@licence-edit_insuranceExpirationDate">
      Insurance Expiration Date
    </app-label>
    <app-date-picker
      (dateChangeFromChild)="dateChangeFromChild($event)"
      [formControl]="formGroup.get('insuranceExpirationDate')"
      format="YYYY-MM-DD"
      [selectedState]="
        selectedState || formGroup.get('licenceIssuedState').value
      "
      documentType="insurance"
    ></app-date-picker>
    <app-validation
      [errors]="
        formGroup.get('insuranceExpirationDate').errors
          | merge: (formGroup.errors?.childErrors)['insuranceExpirationDate']
      "
    >
      <app-validation-message
        *ngIf="
          (
            formGroup.get('insuranceExpirationDate').errors
            | merge: (formGroup.errors?.childErrors)['insuranceExpirationDate']
          )?.required
        "
        i18n="@@licence-edit_insuranceExpirationDateRequired"
      >
        Enter expiration date listed on your insurance
      </app-validation-message>
      <app-validation-message
        *ngIf="
          (
            formGroup.get('insuranceExpirationDate').errors
            | merge: (formGroup.errors?.childErrors)['insuranceExpirationDate']
          )?.minDate
        "
        i18n="@@licence-edit_insuranceExpirationDateMinDate"
      >
        Date has to be later than today
      </app-validation-message>
    </app-validation>
  </app-form-field>
</ng-template>

<ng-template
  #addLicenceAndInsuranceButtonTemplate
  i18n="@@licence-edit_addlicenceButton"
>
  Add License & Insurance
</ng-template>

<ng-template #addValidIdButtonTemplate i18n="@@licence-edit_addIdButton">
  Add Valid ID
</ng-template>

<ng-template #reviewTemplate i18n="@@licence-edit_reviewPhotosButton">
  Review All Id Photos
</ng-template>
