import { Routes } from '@angular/router';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { PwaNotificationContainerComponent } from './containers/pwa-notification-container/pwa-notification-container.component';
import { DisclaimerContainerComponent } from './containers/disclaimer-container/disclaimer-container.component';
import { LandingGuard } from '../core/guards/landing/landing.guard';
import { LandingPageContainerComponent } from './containers/landing-page-container/landing-page-container.component';
import { CovidContainerComponent } from './containers/covid-container/covid-container.component';
import { Module } from '../shared/enums/Module';
const ɵ0 = {
    hideNavigationBar: true,
    siteMapKey: SiteMapItemKeys.LandingPage
}, ɵ1 = {
    hideNavigationBar: true,
    siteMapKey: SiteMapItemKeys.AddPwa,
    pageTitle: TranslationMessages.AddPwaTitle
}, ɵ2 = {
    hideNavigationBar: true,
    siteMapKey: SiteMapItemKeys.Disclaimer,
    pageTitle: TranslationMessages.DisclaimerTitle
}, ɵ3 = {
    showMenuBar: true,
    siteMapKey: SiteMapItemKeys.Covid,
    pageTitle: TranslationMessages.CovidTitle,
    module: Module.Covid
};
const routes = [
    {
        path: '',
        component: LandingPageContainerComponent,
        canActivate: [LandingGuard],
        data: ɵ0
    },
    {
        path: 'add-pwa',
        canActivate: [AuthenticationGuard],
        component: PwaNotificationContainerComponent,
        data: ɵ1
    },
    {
        path: 'disclaimer',
        component: DisclaimerContainerComponent,
        data: ɵ2
    },
    {
        path: 'covid',
        component: CovidContainerComponent,
        data: ɵ3
    }
];
export class StaticContentRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
