<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  focusable="false"
  role="img"
  width="100%"
  height="100%"
  [attr.aria-hidden]="!description"
  [attr.style]="iconColors"
>
  <title *ngIf="description">{{ description }}</title>
  <use [attr.xlink:href]="'#' + name"></use>
</svg>
