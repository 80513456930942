import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { LayoutActions, UserActions } from '../../../../core/actions';
import { GoalActions } from '../actions';
import {
  PerformanceDetails,
  ProductivityAverages
} from '../../../../core/dataEntities/goal/performanceDetails';
import isEmpty from 'lodash/fp/isEmpty';
import {
  GoalSlidersConfig,
  ProductivityMetricConfig
} from '../config/slidersConfig';
import { WagesActions } from '../../../actions';

export const performanceFeatureKey = 'performance';

export interface PerformanceState extends EntityState<PerformanceDetails> {
  activeWeekId: string;
  isPerformanceLoading: boolean;
}

export const adapter: EntityAdapter<PerformanceDetails> = createEntityAdapter<
  PerformanceDetails
>();

export const initialState: PerformanceState = adapter.getInitialState({
  activeWeekId: null,
  isPerformanceLoading: false
});

export const goalReducer = createReducer(
  initialState,
  on(GoalActions.loadWeeklyPerformance, state => {
    return {
      ...state
    };
  }),
  on(GoalActions.weeklyPerformanceLoaded, (state, action) => {
    return adapter.upsertOne(action.performance, {
      ...state,
      isPerformanceLoading: false
    });
  }),
  on(GoalActions.togglePerformanceLoading, (state, action) => {
    return {
      ...state,
      isPerformanceLoading: !state.isPerformanceLoading
    };
  }),
  on(LayoutActions.showError, LayoutActions.apiOffline, state => {
    return {
      ...state,
      isPerformanceLoading: false
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  }),
  on(WagesActions.loadPaymentWeekDetails, (state, action) => {
    return {
      ...state,
      activeWeekId: action.weekEndingDate
    };
  })
);

export function reducer(state: PerformanceState | undefined, action: Action) {
  return goalReducer(state, action);
}

const { selectEntities } = adapter.getSelectors();

export const selectAllPerformances = selectEntities;

export const selectIsPerformanceLoading = (state: PerformanceState) => {
  return state.isPerformanceLoading;
};
export const selectActiveWeekId = (state: PerformanceState) => {
  return state.activeWeekId;
};

const selectPerformanceMetricValue = (
  value: number,
  config: ProductivityMetricConfig
): number => {
  if (value >= config.min && value <= config.max) {
    return value;
  }
  return null;
};

const selectPerformanceMetricAverages = (
  averages: ProductivityAverages,
  config: GoalSlidersConfig
): ProductivityAverages => {
  return {
    ...averages,
    hours: selectPerformanceMetricValue(averages.hours, config.hours),
    productivityMetrics: {
      palletsPerHour: selectPerformanceMetricValue(
        averages.productivityMetrics.palletsPerHour,
        config.palletsPerHour
      ),
      revenuePerPallet: selectPerformanceMetricValue(
        averages.productivityMetrics.revenuePerPallet,
        config.revenuePerPallet
      ),
      casesPerHour: selectPerformanceMetricValue(
        averages.productivityMetrics.casesPerHour,
        config.casesPerHour
      ),
      revenuePerCase: selectPerformanceMetricValue(
        averages.productivityMetrics.revenuePerCase,
        config.revenuePerCase
      )
    }
  };
};

export const getActiveWeekAssociatePerformance = createSelector(
  selectAllPerformances,
  selectActiveWeekId,
  (performances, activeWeekId) => {
    return performances[activeWeekId];
  }
);

export const getActiveWeekPerformance = createSelector(
  selectAllPerformances,
  selectActiveWeekId,
  (performances, activeWeekId, props) => {
    const performance = performances[activeWeekId];
    if (!performance) {
      return performance;
    }
    return {
      ...performance,
      associateProductivityAverage: selectPerformanceMetricAverages(
        performance.associateProductivityAverage,
        props.config
      ),
      siteProductivityAverage: selectPerformanceMetricAverages(
        performance.siteProductivityAverage,
        props.config
      )
    };
  }
);

export const getActiveWeekPaySplit = createSelector(
  selectAllPerformances,
  selectActiveWeekId,
  (performances, activeWeekId) => {
    if (
      !activeWeekId ||
      !performances ||
      isEmpty(performances) ||
      !performances[activeWeekId]
    ) {
      return null;
    }
    const activeWeekPerformance = performances[activeWeekId];
    return activeWeekPerformance.associatePaySplit
      ? activeWeekPerformance.associatePaySplit
      : activeWeekPerformance.sitePaySplit;
  }
);
