/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./chip.component";
var styles_ChipComponent = [i0.styles];
var RenderType_ChipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChipComponent, data: {} });
export { RenderType_ChipComponent as RenderType_ChipComponent };
export function View_ChipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { chip: 0, "chip--green": 1, "chip--blue": 2, "chip--yellow": 3, "chip--red": 4 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, (_co.appearance === _co.ChipAppearanceEnum.Green), (_co.appearance === _co.ChipAppearanceEnum.Blue), (_co.appearance === _co.ChipAppearanceEnum.Yellow), (_co.appearance === _co.ChipAppearanceEnum.Red)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chip", [], null, null, null, View_ChipComponent_0, RenderType_ChipComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChipComponentNgFactory = i1.ɵccf("app-chip", i3.ChipComponent, View_ChipComponent_Host_0, { appearance: "appearance" }, {}, ["*"]);
export { ChipComponentNgFactory as ChipComponentNgFactory };
