<app-card-layout
  *ngIf="
    trainingStatus !== TrainingStatusEnum.NoRecordFound &&
    trainingStatus !== TrainingStatusEnum.FinishedTraining
  "
  [ngClass]="{
    'weekly-training': true,
    'weekly-training--margin-bottom': marginBottom
  }"
>
  <app-card-layout-item>
    <ng-container *ngIf="trainingStatus === TrainingStatusEnum.WeekOneTraining">
      <app-page-section-header
        title="1st week Training Plan"
        i18n-title="@@weekly-training_1week-title"
      ></app-page-section-header>
      <p
        class="weekly-training__subtitle"
        i18n="@@weekly-training_1week-subtitle"
      >
        This week lets focus on getting you trained on the basics of unloading
        safely and productively.
      </p>
      <app-check-list>
        <app-check-list-item
          title="Equipment Training"
          i18n-title="@@weekly-training_1week-1item-title"
          i18n="@@weekly-training_1week-1item-subtitle"
        >
          Get started working on warehouse equipment.
        </app-check-list-item>
        <app-check-list-item
          title="Capstone Safety"
          i18n-title="@@weekly-training_1week-2item-title"
          i18n="@@weekly-training_1week-2item-subtitle"
        >
          Learn how to safely unload and navigate the warehouse.
        </app-check-list-item>
        <app-check-list-item
          title="Begin Unloading"
          i18n-title="@@weekly-training_1week-3item-title"
          i18n="@@weekly-training_1week-3item-subtitle"
        >
          Learn best-practices for unloading and selecting.
        </app-check-list-item>
      </app-check-list>
    </ng-container>
    <ng-container *ngIf="trainingStatus === TrainingStatusEnum.WeekTwoTraining">
      <app-page-section-header
        title="2nd Week Training Plan"
        i18n-title="@@weekly-training_2week-title"
      ></app-page-section-header>
      <p
        class="weekly-training__subtitle"
        i18n="@@weekly-training_2week-subtitle"
      >
        This week you’ll become a master of the warehouse flow and how to keep
        things moving
      </p>
      <app-check-list>
        <app-check-list-item
          title="Warehouse Flow"
          i18n-title="@@weekly-training_2week-1item-title"
          i18n="@@weekly-training_2week-1item-subtitle"
        >
          Get the lay of the land and learn how to safely move through the site.
        </app-check-list-item>
        <app-check-list-item
          title="Load Flow"
          i18n-title="@@weekly-training_2week-2item-title"
          i18n="@@weekly-training_2week-2item-subtitle"
        >
          Get to know how product moves through the facility.
        </app-check-list-item>
        <app-check-list-item
          title="Systems Training"
          i18n-title="@@weekly-training_2week-3item-title"
          i18n="@@weekly-training_2week-3item-subtitle"
        >
          Learn about digital tools used by associates.
        </app-check-list-item>
      </app-check-list>
    </ng-container>
    <ng-container
      *ngIf="trainingStatus === TrainingStatusEnum.WeekThreeTraining"
    >
      <app-page-section-header
        title="3rd Week Training Plan"
        i18n-title="@@weekly-training_3week-title"
      ></app-page-section-header>
      <p
        class="weekly-training__subtitle"
        i18n="@@weekly-training_3week-subtitle"
      >
        Now that you’ve mastered productivity, learn more about the unique
        offering Capstone prings to partners.
      </p>
      <app-check-list>
        <app-check-list-item
          title="Who Is Our Customer?"
          i18n-title="@@weekly-training_3week-1item-title"
          i18n="@@weekly-training_3week-1item-subtitle"
        >
          Learn about the product and services your partner provides.
        </app-check-list-item>
        <app-check-list-item
          title="OSD Process"
          i18n-title="@@weekly-training_3week-2item-title"
          i18n="@@weekly-training_3week-2item-subtitle"
        >
          How to report over, shorted, or damaged products.
        </app-check-list-item>
        <app-check-list-item
          title="Capstone-level Quality Work"
          i18n-title="@@weekly-training_3week-3item-title"
          i18n="@@weekly-training_3week-3item-subtitle"
        >
          How Capstone ensured quality work for our partners.
        </app-check-list-item>
      </app-check-list>
    </ng-container>
    <ng-container
      *ngIf="
        trainingStatus === TrainingStatusEnum.WeekFourTraining ||
        trainingStatus === TrainingStatusEnum.WeekFiveTraining ||
        trainingStatus === TrainingStatusEnum.WeekSixTraining
      "
    >
      <app-page-section-header
        title="4-6th Week Training Plan"
        i18n-title="@@weekly-training_4week-title"
      ></app-page-section-header>
      <p
        class="weekly-training__subtitle"
        i18n="@@weekly-training_4week-subtitle"
      >
        Your last three weeks of training will focus on mastering your pay and
        fine-tuning your processes.
      </p>
      <app-check-list>
        <app-check-list-item
          title="Understanding Your Pay"
          i18n-title="@@weekly-training_4week-1item-title"
          i18n="@@weekly-training_4week-1item-subtitle"
        >
          Maximize your earnings each week.
        </app-check-list-item>
        <app-check-list-item
          title="Preferred Methods Training"
          i18n-title="@@weekly-training_4week-2item-title"
          i18n="@@weekly-training_4week-2item-subtitle"
        >
          Learn how to work with your partner and meet their processes and
          expectations.
        </app-check-list-item>
      </app-check-list>
    </ng-container>
  </app-card-layout-item>
</app-card-layout>
