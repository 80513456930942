<input
  [ngClass]="{
    text__input: true,
    'text__input--invalid': isInvalid
  }"
  [attr.id]="id"
  [formControl]="formControl"
  [attr.aria-invalid]="isInvalid"
  [attr.aria-describedby]="isInvalid ? errorId : null"
  [attr.disabled]="disabled ? '' : null"
  placeholder="{{ placeholder }}"
  [mask]="inputMask"
  [attr.type]="inputType"
/>
