/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../page-section-header/page-section-header.component.ngfactory";
import * as i2 from "../../../page-section-header/page-section-header.component";
import * as i3 from "./multi-step-form-editable-step.component";
var styles_MultiStepFormEditableStepComponent = [];
var RenderType_MultiStepFormEditableStepComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MultiStepFormEditableStepComponent, data: {} });
export { RenderType_MultiStepFormEditableStepComponent as RenderType_MultiStepFormEditableStepComponent };
export function View_MultiStepFormEditableStepComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-section-header", [], null, null, null, i1.View_PageSectionHeaderComponent_0, i1.RenderType_PageSectionHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.PageSectionHeaderComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.subTitle; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_MultiStepFormEditableStepComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-multi-step-form-editable-step", [], null, null, null, View_MultiStepFormEditableStepComponent_0, RenderType_MultiStepFormEditableStepComponent)), i0.ɵdid(1, 114688, null, 0, i3.MultiStepFormEditableStepComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultiStepFormEditableStepComponentNgFactory = i0.ɵccf("app-multi-step-form-editable-step", i3.MultiStepFormEditableStepComponent, View_MultiStepFormEditableStepComponent_Host_0, { title: "title", subTitle: "subTitle" }, {}, ["*"]);
export { MultiStepFormEditableStepComponentNgFactory as MultiStepFormEditableStepComponentNgFactory };
