import { Component, Input, OnInit } from '@angular/core';
import { ValidationComponent } from '../validation.component';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss']
})
export class ValidationMessageComponent implements OnInit {
  @Input() id: string;

  constructor(private validation: ValidationComponent) {}

  ngOnInit() {}

  getControl() {
    if (!this.validation) {
      return null;
    }

    return this.validation.for;
  }
}
