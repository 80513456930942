import {
  ComponentFactoryResolver,
  ElementRef,
  InjectionToken,
  ViewContainerRef
} from '@angular/core';

export const INFOBOX_DATA = new InjectionToken<any>('InfoBoxData');

export class InfoBoxConfig {
  //  tslint:disable
  id?: string;
  viewContainerRef?: ViewContainerRef;
  componentFactoryResolver?: ComponentFactoryResolver;
  data: any;
  hasBackdrop?: boolean = false;
  backdropClass?: string = 'white-backdrop';
  bottom?: string = '';
  size?: any;

  originX?: 'start' | 'center' | 'end' = 'center';
  originY?: 'top' | 'center' | 'bottom' = 'center';
  overlayX?: 'start' | 'center' | 'end' = 'center';
  overlayY?: 'top' | 'center' | 'bottom' = 'center';
  offsetY?: number = -8;
  offsetX?: number = 10;
  disableClose?: boolean = false;
  autoFocus?: boolean = true;
  width?: string = '';
  height?: string = '';
  disposeOnNavigation?: boolean = true;
  triggerEl: ElementRef;
  overlayStrategy?: any;
  scrollStrategy?: 'close' | 'reposition';
  singlePerGroup?: boolean = false;
  // tslint:enable
}
