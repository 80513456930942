<div class="empty-state">
  <app-svg-icon
    [name]="emptyStateIcon"
    class="empty-state__icon"
    i18n-description="@@emptyStateDocument"
    description="Empty state document"
  ></app-svg-icon>

  <ng-template #defaultStateTemplate>
    {{ emptyStateMessage }}
  </ng-template>

  <p class="empty-state__message">
    <ng-container
      *ngTemplateOutlet="
        emptyStateTemplate ? emptyStateTemplate : defaultStateTemplate
      "
    ></ng-container>
  </p>
</div>
