import {
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { TabLabelDirective } from '../tab-label.directive';
import { TemplatePortal } from '@angular/cdk/portal';
import { TabGroupService } from '../services/tab-group.service';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html'
})
export class TabComponent implements OnInit {
  @Input() textLabel: string;
  @ContentChild(TabLabelDirective, { read: TemplateRef, static: true })
  labelTemplate: TemplateRef<any>;

  @ViewChild(TemplateRef, { static: true }) implicitContent: TemplateRef<any>;

  labelPortal: TemplatePortal;
  contentPortal: TemplatePortal;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private tabGroupService: TabGroupService
  ) {}

  @Input()
  set isActive(value: boolean) {
    this.tabGroupService.setActiveTab(value ? this : null);
  }

  ngOnInit() {
    this.contentPortal = new TemplatePortal<any>(
      this.implicitContent,
      this.viewContainerRef
    );
    this.labelPortal = new TemplatePortal<any>(
      this.labelTemplate,
      this.viewContainerRef,
      { tab: this }
    );
  }
}
