import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import ProgressBar from 'progressbar.js';
import { SvgIconName } from '../../enums/SvgIcon';
import { GoalGaugeAppearance } from '../../enums/GoalGauge';
import { GoalGaugeService } from './goal-gauge.service';

let nextUniqueId = 0;

@Component({
  selector: 'app-goal-gauge',
  templateUrl: './goal-gauge.component.html',
  styleUrls: ['./goal-gauge.component.scss']
})
export class GoalGaugeComponent implements OnInit, OnChanges {
  @Input() current: number;
  @Input() max: number;
  @Input() label: string;
  @Input() suffix: string;
  @Input() hideLabel = false;
  @Input() isAverageScale = false;
  @Input() currentSuffix: string;
  @Input() customMaxValue: string;
  @Input() customMaxLabel: string;
  @Input() labelTemplate: TemplateRef<any>;

  @ViewChild('path', { static: true }) path: ElementRef;

  public isAboveGoal: boolean;
  public barValue: number;
  public bar: ProgressBar;
  public appearance: GoalGaugeAppearance;
  public localMaxValue: string;
  public GoalGaugeAppearanceEnum = GoalGaugeAppearance;
  public SvgIconNameEnum = SvgIconName;
  protected defaultId = `goal-gauge-${++nextUniqueId}`;

  constructor(private goalGaugeService: GoalGaugeService) {}

  ngOnInit() {
    this.bar = this.goalGaugeService.createGauge(this.path, this.barValue);
    this.localMaxValue = this.customMaxValue
      ? this.customMaxValue
      : this.max
      ? this.max.toString()
      : undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.current || changes.max) {
      this.updateValues();

      if (this.bar) {
        this.bar.animate(this.barValue);
      }
    }

    if (changes.isAverageScale) {
      this.updateGaugeAppearance();
    }
  }

  get id(): string {
    return this.defaultId;
  }

  updateValues() {
    const value = this.current / this.max;
    if (value > 1) {
      this.isAboveGoal = true;
      this.barValue = 1;
    } else {
      this.barValue = value;
      this.isAboveGoal = false;
    }

    this.updateGaugeAppearance();
  }

  updateGaugeAppearance() {
    if (this.isAverageScale) {
      switch (true) {
        case this.isAboveGoal === true:
          this.appearance = GoalGaugeAppearance.AboveGoalAvg;
          break;
        case this.barValue === 1:
          this.appearance = GoalGaugeAppearance.MetGoal;
          break;
        case this.barValue < 1 && this.barValue >= 0.9:
          this.appearance = GoalGaugeAppearance.Green;
          break;
        case this.barValue < 0.9 && this.barValue >= 0.65:
          this.appearance = GoalGaugeAppearance.Blue;
          break;
        case this.barValue < 0.65:
          this.appearance = GoalGaugeAppearance.Red;
          break;
      }
    } else {
      switch (true) {
        case this.isAboveGoal === true:
          this.appearance = GoalGaugeAppearance.AboveGoal;
          break;
        case this.barValue === 1:
          this.appearance = GoalGaugeAppearance.MetGoal;
          break;
        case this.barValue < 1:
          this.appearance = GoalGaugeAppearance.Green;
          break;
      }
    }
  }
}
