import { Routes } from '@angular/router';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { NewsFeedContainerComponent } from './containers/news-feed/news-feed-container.component';
import { NewsComponent } from './news.component';
import { PreviewFeedCardComponent } from './containers/preview-feed-card/preview-feed-card.component';
import { BlogPostComponent } from '../shared/components/feed/blog-post/blog-post.component';
import { FeedType } from '../shared/enums/FeedType';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { Module } from '../shared/enums/Module';
const ɵ0 = {
    module: Module.News,
    pageTitle: TranslationMessages.NewsTitle
}, ɵ1 = {
    showMenuBar: true,
    pageTitle: TranslationMessages.NewsTitle,
    siteMapKey: SiteMapItemKeys.NewsFeed
}, ɵ2 = {
    showMenuBar: false,
    feedType: FeedType.News,
    siteMapKey: SiteMapItemKeys.NewsDetails
}, ɵ3 = {
    module: Module.News,
    showMenuBar: false,
    hideNavigationBar: true,
    siteMapKey: SiteMapItemKeys.PreviewNewsPost
};
const routes = [
    {
        path: 'news',
        component: NewsComponent,
        canActivate: [AuthenticationGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: NewsFeedContainerComponent,
                data: ɵ1
            },
            {
                path: ':id',
                component: BlogPostComponent,
                data: ɵ2
            }
        ]
    },
    {
        path: 'preview-news-post/:id',
        component: PreviewFeedCardComponent,
        canActivate: [],
        data: ɵ3
    }
];
export class NewsRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
