import {
  Component,
  ContentChildren,
  OnInit,
  QueryList,
  Input,
  forwardRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormStepDefDirective } from './directives/form-step-def.directive';
import { MultiStepFormService } from './services/multi-step-form.service';

@Component({
  selector: 'app-multi-step-form',
  templateUrl: './multi-step-form.component.html',
  styleUrls: ['./multi-step-form.component.scss'],
  providers: [MultiStepFormService]
})
export class MultiStepFormComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @ContentChildren(
    forwardRef(() => FormStepDefDirective),
    { descendants: true }
  )
  formSteps: QueryList<FormStepDefDirective>;

  constructor(public multiStepFormService: MultiStepFormService) {}

  ngOnInit() {}

  next() {
    if (this.multiStepFormService.canGoToNextStep(this.formGroup)) {
      const nextStep = this.multiStepFormService.getNextEditableStep(
        this.formSteps
      );
      if (nextStep) {
        this.multiStepFormService.setEditableStep(nextStep);
        let timeOutNum = 0;
        if (this.isAndroid()) {
          timeOutNum = 200;
        }
        setTimeout(() => {
          document.getElementById(nextStep.name).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
          });
        }, timeOutNum);
      }
    }
  }

  isAndroid() {
    return (
      window.navigator.platform.match(/Android/i) ||
      window.navigator.platform.match(/Linux/i)
    );
  }

  hideKeyboard() {
    const field = document.createElement('input');
    field.setAttribute('type', 'text');
    document.body.appendChild(field);

    setTimeout(() => {
      field.focus();
      setTimeout(() => {
        field.setAttribute('style', 'display:none;');
      }, 50);
    }, 50);
  }
}
