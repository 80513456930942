import { Routes } from '@angular/router';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { SurveyDetailsComponent } from './containers/survey-details/survey-details.component';
import { SurveyGuard } from './guards/survey.guard';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { DisabledGuard } from '../core/guards/disabled/disabled.guard';
import { environment } from '../../environments/environment';
const ɵ0 = {
    canActivate: environment.features.surveys,
    hideNavigationBar: true,
    siteMapKey: SiteMapItemKeys.Survey
};
const routes = [
    {
        path: 'survey/:id',
        component: SurveyDetailsComponent,
        canActivate: [AuthenticationGuard, DisabledGuard, SurveyGuard],
        data: ɵ0
    }
];
export class SurveyRoutingModule {
}
export { ɵ0 };
