<div
  [ngClass]="{
    'post-category': true,
    'post-category--yellow':
      category === PostCategoryTypeEnum.Award ||
      category === PostCategoryTypeEnum.Congrats ||
      category === PostCategoryTypeEnum.Birthday ||
      category === PostCategoryTypeEnum.Anniversary,
    'post-category--red': category === PostCategoryTypeEnum.Alert,
    'post-category--blue': category === PostCategoryTypeEnum.Event,
    'post-category--green':
      category === PostCategoryTypeEnum.Announcement ||
      category === PostCategoryTypeEnum.WorkOpportunity,
    'post-category--no-name': !showName
  }"
>
  <ng-container *ngIf="category === PostCategoryTypeEnum.Announcement">
    <app-svg-icon
      [name]="SvgIconNameEnum.Megaphone"
      [colorPrimary]="SvgIconColorEnum.MountainMeadow"
      [colorSecondary]="SvgIconColorEnum.Bermuda"
      class="post-category__icon"
    ></app-svg-icon>
    <span *ngIf="showName" i18n="@@post-category_announcement">
      Announcement
    </span>
  </ng-container>

  <ng-container
    *ngIf="
      category === PostCategoryTypeEnum.Alert ||
      category === PostCategoryTypeEnum.SafetyTip
    "
  >
    <app-svg-icon
      [name]="SvgIconNameEnum.Warning"
      [colorPrimary]="SvgIconColorEnum.Cinnabar"
      class="post-category__icon"
    ></app-svg-icon>
    <span *ngIf="showName" i18n="@@post-category_alert">Alert</span>
  </ng-container>

  <ng-container *ngIf="category === PostCategoryTypeEnum.Award">
    <app-svg-icon
      [name]="SvgIconNameEnum.Star"
      [colorPrimary]="SvgIconColorEnum.Saffron"
      [colorSecondary]="SvgIconColorEnum.SweetCorn"
      class="post-category__icon"
    ></app-svg-icon>
    <span *ngIf="showName" i18n="@@post-category_starAward">Star Award</span>
  </ng-container>

  <ng-container *ngIf="category === PostCategoryTypeEnum.Event">
    <app-svg-icon
      [name]="SvgIconNameEnum.Calendar"
      [colorPrimary]="SvgIconColorEnum.Lochmara"
      [colorSecondary]="SvgIconColorEnum.Spindle"
      class="post-category__icon"
    ></app-svg-icon>
    <span *ngIf="showName" i18n="@@post-category_newEvent">New event</span>
  </ng-container>

  <ng-container *ngIf="category === PostCategoryTypeEnum.Congrats">
    <app-svg-icon
      [name]="SvgIconNameEnum.Star"
      [colorPrimary]="SvgIconColorEnum.Saffron"
      [colorSecondary]="SvgIconColorEnum.SweetCorn"
      class="post-category__icon"
    ></app-svg-icon>
    <span *ngIf="showName" i18n="@@post-category_congrats">Congrats</span>
  </ng-container>

  <ng-container *ngIf="category === PostCategoryTypeEnum.WorkOpportunity">
    <app-svg-icon
      [name]="SvgIconNameEnum.HalfBanknote"
      [colorPrimary]="SvgIconColorEnum.Genoa"
      [colorSecondary]="SvgIconColorEnum.Bermuda"
      class="post-category__icon"
    ></app-svg-icon>
    <span *ngIf="showName" i18n="@@post-category_workOpportunity">
      Work Opportunity
    </span>
  </ng-container>

  <ng-container
    *ngIf="
      category === PostCategoryTypeEnum.Birthday ||
      category === PostCategoryTypeEnum.Anniversary
    "
  >
    <app-svg-icon
      [name]="SvgIconNameEnum.Star"
      [colorPrimary]="SvgIconColorEnum.Saffron"
      [colorSecondary]="SvgIconColorEnum.SweetCorn"
      class="post-category__icon"
    ></app-svg-icon>
    <span *ngIf="showName" i18n="@@post-category_teamAnnouncement">
      Team Announcement
    </span>
  </ng-container>
</div>
