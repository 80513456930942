import { Component, HostBinding, OnInit, Optional } from '@angular/core';
import { CardLayoutComponent } from '..';

@Component({
  selector: 'app-card-layout-item',
  templateUrl: './card-layout-item.component.html',
  styleUrls: ['./card-layout-item.component.scss']
})
export class CardLayoutItemComponent implements OnInit {
  @HostBinding('class') get class() {
    const className = 'card-layout-item';
    return this.card
      ? this.card.getClass(this.card.appearance, className)
      : className;
  }

  constructor(@Optional() public card: CardLayoutComponent) {}

  ngOnInit() {}
}
