<app-modal-layout (closeModal)="closeModal()">
  <app-modal-header
    slot="modal-header"
    primaryText="Skipping"
    i18n-primaryText="@@skip-certifications_skipping"
    secondaryText="Certifications"
    i18n-secondaryText="@@skip-certifications_certifications"
  ></app-modal-header>
  <div slot="modal-content" class="skip-certifications">
    <app-modal-content-row [sideSpacing]="true">
      <app-svg-icon
        class="skip-certifications__icon"
        [name]="SvgIconNameEnum.Caution"
      ></app-svg-icon>
      <p i18n="@@skip-certifications_driverInsuranceIsNotRequired">
        You didn't enter any certifications or skills. Adding certifications
        greatly increases your chance of being picked for Road Crew.
      </p>
      <p i18n="@@skip-certifications_areYouSure">
        Are you sure you want to skip this?
      </p>
    </app-modal-content-row>
    <div class="skip-certifications__actions">
      <app-button-link
        (buttonClick)="closeModal()"
        label="Back"
        i18n-label="@@skip-certifications_back"
        [appearance]="ButtonAppearanceEnum.Link"
      ></app-button-link>

      <app-button-link
        (buttonClick)="skip()"
        label="Skip Certifications"
        i18n-label="@@skip-certifications_skipCertifications"
        [appearance]="ButtonAppearanceEnum.Link"
        [color]="ButtonColorEnum.Red"
      ></app-button-link>
    </div>
  </div>
</app-modal-layout>
