import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { catchError, retryWhen, switchMap } from 'rxjs/operators';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
import { UserActions } from '../../core/actions';
import { genericRetryStrategy } from '../../core/helpers/generic-retry-strategy';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(
    private msalService: MsalService,
    private store: Store<AppState>
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.getToken().pipe(
      switchMap(token => {
        return next.handle(this.updateAuthToken(request, token)).pipe(
          retryWhen(
            genericRetryStrategy({ excludedStatusCodes: [0, 401, 404, 500] })
          ),
          catchError(error => {
            if (
              error instanceof HttpErrorResponse &&
              (error.status === 0 ||
                error.status === 401 ||
                error.status === 504)
            ) {
              this.msalService.clearCacheForScope(token);
              return this.getToken().pipe(
                switchMap(accessToken => {
                  return next.handle(
                    this.updateAuthToken(request, accessToken)
                  );
                })
              );
            } else {
              return throwError(error);
            }
          })
        );
      })
    );
  }

  private getToken(): Observable<string> {
    return from(
      this.msalService.acquireTokenSilent(
        [environment.azureADTenantConfig.clientID],
        environment.azureADTenantConfig.authority
      )
    ).pipe(
      catchError(error => {
        console.log('refresh token error, ', error);
        this.store.dispatch(UserActions.userLogout({ isAutoLogout: false }));
        return of(null);
      })
    );
  }

  private updateAuthToken(request: HttpRequest<any>, token: string) {
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
    return request;
  }
}
