/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/portal";
import * as i2 from "./info-box-container.component";
var styles_InfoBoxContainerComponent = [];
var RenderType_InfoBoxContainerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InfoBoxContainerComponent, data: { "animation": [{ type: 7, name: "infoBoxContainer", definitions: [{ type: 0, name: "void, exit", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "enter", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "* => enter", animation: [{ type: 4, styles: null, timings: "225ms cubic-bezier(0.0,0.0,0.2,1)" }], options: null }, { type: 1, expr: "* => void, * => exit", animation: [{ type: 4, styles: null, timings: "275ms cubic-bezier(0.4,0.0,1,1)" }], options: null }], options: {} }] } });
export { RenderType_InfoBoxContainerComponent as RenderType_InfoBoxContainerComponent };
function View_InfoBoxContainerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_InfoBoxContainerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { portalOutlet: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InfoBoxContainerComponent_1)), i0.ɵdid(2, 212992, [[1, 4]], 0, i1.CdkPortalOutlet, [i0.ComponentFactoryResolver, i0.ViewContainerRef], { portal: [0, "portal"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InfoBoxContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-info-box-container", [], [[40, "@infoBoxContainer", 0]], [["component", "@infoBoxContainer.done"], ["component", "@infoBoxContainer.start"]], function (_v, en, $event) { var ad = true; if (("component:@infoBoxContainer.done" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onAnimationDone($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@infoBoxContainer.start" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onAnimationStart($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_InfoBoxContainerComponent_0, RenderType_InfoBoxContainerComponent)), i0.ɵdid(1, 49152, null, 0, i2.InfoBoxContainerComponent, [i0.ChangeDetectorRef], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).infoBoxContainer; _ck(_v, 0, 0, currVal_0); }); }
var InfoBoxContainerComponentNgFactory = i0.ɵccf("app-info-box-container", i2.InfoBoxContainerComponent, View_InfoBoxContainerComponent_Host_0, {}, {}, []);
export { InfoBoxContainerComponentNgFactory as InfoBoxContainerComponentNgFactory };
