import { getAuthTokenWithApexUserId } from '../../reducers';
import { FeedService } from '../../shared/services/feed.service';
import { first, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/content-delivery.service";
import * as i2 from "../../shared/services/api.gateway.service";
import * as i3 from "../../core/contracts/StaticContentService";
import * as i4 from "@ngrx/store";
export class ResourcesService extends FeedService {
    constructor(contentDeliveryService, apiGateway, staticContentService, store, localeId) {
        super(apiGateway, store);
        this.contentDeliveryService = contentDeliveryService;
        this.apiGateway = apiGateway;
        this.staticContentService = staticContentService;
        this.store = store;
        this.pinnedPostId = 'pinned-post';
        this.contentLocale = localeId;
    }
    starsFeed(page, take) {
        const query = {
            'fields.category.sys.contentType.sys.id': 'newsCategory',
            'fields.category.fields.key[match]': 'starAward'
        };
        return this.contentDeliveryService.getNews(page * take, take, query);
    }
    loadPost(postId) {
        if (postId === this.pinnedPostId) {
            return this.loadPinnedPost();
        }
        return this.contentDeliveryService.getPost(postId);
    }
    loadPinnedPost() {
        return this.staticContentService.getScriptPinnedPost();
    }
    getDirectory() {
        return this.store.select(getAuthTokenWithApexUserId).pipe(first(), switchMap(({ authToken, userId }) => {
            if (!userId) {
                throw new Error('User not found.');
            }
            return this.apiGateway.get(`siteresources/associates/${userId}/directory`, authToken);
        }));
    }
}
ResourcesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResourcesService_Factory() { return new ResourcesService(i0.ɵɵinject(i1.ContentDeliveryService), i0.ɵɵinject(i2.ApiGatewayService), i0.ɵɵinject(i3.StaticContentService), i0.ɵɵinject(i4.Store), i0.ɵɵinject(i0.LOCALE_ID)); }, token: ResourcesService, providedIn: "root" });
