import {
  Component,
  ContentChild,
  InjectionToken,
  Input,
  OnInit,
  Optional
} from '@angular/core';
import { FormFieldControl } from './form-field.control';
import { ValidationComponent } from '../validation/validation.component';
import { LabelComponent } from '../label/label.component';
import { RootFormDirective } from '../root-form/root-form.directive';

export const FORM_FIELD = new InjectionToken<FormFieldComponent>('FormField');

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  providers: [{ provide: FORM_FIELD, useExisting: FormFieldComponent }]
})
export class FormFieldComponent implements OnInit {
  @Input() labelFor: string;
  @Input() required = false;
  @Input() narrow = false;
  @ContentChild(FormFieldControl, { static: true }) control: FormFieldControl;
  @ContentChild(LabelComponent, { static: false }) label: LabelComponent;
  @ContentChild(ValidationComponent, { static: true })
  validationContainer: ValidationComponent;

  @Input()
  validation: ValidationComponent;

  constructor(@Optional() protected rootForm: RootFormDirective) {}

  get validationComponent() {
    return this.validation || this.validationContainer;
  }

  get isFormSubmitted() {
    if (!this.rootForm) {
      return true;
    }
  }

  isInvalid() {
    if (!this.validationComponent || !this.control) {
      return false;
    }

    return (
      ((this.rootForm &&
        this.rootForm.formDirective &&
        (this.rootForm.formDirective.submitted ||
          (!this.rootForm.formDirective.submitted &&
            this.control.control.status !== 'INVALID' &&
            this.control.control.value !== null &&
            this.control.control.value !== undefined &&
            this.control.control.value.hasOwnProperty('day')))) ||
        this.control.control.touched) &&
      this.validationComponent.isErrorState(
        this.control.control,
        this.rootForm && this.rootForm.formDirective
      )
    );
  }

  hasValidation() {
    return !!this.validationComponent;
  }

  ngOnInit() {
    if (this.validationComponent && this.control) {
      this.validationComponent.for = this.control;
      this.validationComponent.shouldShowError = this.isInvalid.bind(this);
    }
  }
}
