import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { PageTitleAppearance } from '../../enums/PageTitle';
import { SvgIconColor, SvgIconName } from '../../enums/SvgIcon';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() appearance: PageTitleAppearance = PageTitleAppearance.Blue;
  @Input() narrow = false;
  @Input() iconName: SvgIconName;
  @Input() iconColorPrimary: SvgIconColor;
  @Input() iconColorSecondary: SvgIconColor;

  PageTitleAppearanceEnum = PageTitleAppearance;

  constructor() {}

  ngOnInit() {}
}
