<dl class="list">
  <ng-container *ngFor="let listItem of listItems">
    <div class="list__item">
      <ng-template #defaultTitleTemplate>
        {{ listItem.title }}
      </ng-template>

      <dt class="list__title">
        <app-svg-icon
          class="list__icon"
          [name]="SvgIconNameEnum.CheckCircle"
        ></app-svg-icon>
        <ng-container
          *ngTemplateOutlet="
            listItem.titleTemplate
              ? listItem.titleTemplate
              : defaultTitleTemplate
          "
        ></ng-container>
      </dt>
      <dd class="list__description">
        {{ listItem.content }}
      </dd>
    </div>
  </ng-container>
</dl>
