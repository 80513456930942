<div
  class="table-container table-container--scrollable"
  *ngIf="dayEarnings?.allEarningsDetails.length"
>
  <div class="table-wrapper">
    <table
      cdk-table
      [dataSource]="dayEarnings?.allEarningsDetails"
      class="table"
    >
      <caption class="visually-hidden">
        {{ dayEarnings.date | date: 'EEEE MM/dd' }}
        <span i18n="@@earnings-breakdown_earningsBreakdown">
          earnings breakdown
        </span>
      </caption>
      <ng-container cdkColumnDef="task">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          scope="col"
          i18n="@@earnings-breakdown_workType"
        >
          Work Type
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="table__cell table__cell--bold cell-ellipsis"
        >
          <div
            *ngIf="element.earningType === EarningTypesEnum.ProductionEarning"
            class="cell-ellipsis__container"
          >
            <ng-container
              *ngIf="
                element.loadType === LoadTypesEnum.Service;
                else otherLoadType
              "
            >
              <app-svg-icon
                [name]="SvgIconNameEnum.Boxes"
                class="icon icon--green"
              ></app-svg-icon>
              <div class="icon__label">
                <span class="icon__label-number">{{ element.po }}</span>
                <span *ngIf="element.vendorName" class="icon__label-vendor">
                  {{ element.vendorName }}
                </span>
              </div>
            </ng-container>
            <ng-template #otherLoadType>
              <app-svg-icon
                [name]="SvgIconNameEnum.Truck"
                class="icon icon--green"
              ></app-svg-icon>
              <div class="icon__label">
                <span class="icon__label-number">{{ element.po }}</span>
                <span *ngIf="element.vendorName" class="icon__label-vendor">
                  {{ element.vendorName }}
                </span>
              </div>
            </ng-template>
          </div>
          <ng-container
            *ngIf="element.earningType === EarningTypesEnum.HourlyEarning"
          >
            <app-svg-icon
              [name]="SvgIconNameEnum.Clock"
              class="icon icon--blue"
            ></app-svg-icon>
            <span
              class="icon__label"
              i18n="@@earnings-breakdown_hourlyEarnings"
            >
              Hourly Earnings
            </span>
          </ng-container>
          <ng-container
            *ngIf="element.earningType === EarningTypesEnum.ExceptionEarning"
          >
            <app-svg-icon
              [name]="SvgIconNameEnum.TriangleTick"
              class="icon icon--gray"
            ></app-svg-icon>
            <span
              class="icon__label"
              i18n="@@earnings-breakdown_exceptionEarnings"
            >
              Exception Earnings
            </span>
          </ng-container>
        </td>
        <td
          cdk-footer-cell
          *cdkFooterCellDef
          [attr.colspan]="2"
          class="table__cell table__cell--bold table__cell--footer-sticky"
        >
          <app-svg-icon
            [name]="SvgIconNameEnum.Banknote"
            class="icon"
          ></app-svg-icon>
          <span class="icon__label" i18n="@@earnings-breakdown_totalEarnings">
            Total Earnings
          </span>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="netRevenue">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          scope="col"
          class="table__cell table__cell--number"
          i18n="@@earnings-breakdown_netRev"
        >
          Net Rev.
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="table__cell table__cell--number"
        >
          <ng-container
            *ngIf="element.earningType === EarningTypesEnum.ProductionEarning"
          >
            {{ element.netRevenue | currency }}
          </ng-container>
          <ng-container
            *ngIf="element.earningType === EarningTypesEnum.HourlyEarning"
          >
            {{ element.earnings | currency }}
          </ng-container>
          <ng-container
            *ngIf="element.earningType === EarningTypesEnum.ExceptionEarning"
          >
            {{ element.earnings | currency }}
          </ng-container>
        </td>
        <td cdk-footer-cell *cdkFooterCellDef [attr.colspan]="2"></td>
      </ng-container>

      <ng-container cdkColumnDef="split">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          scope="col"
          class="table__cell table__cell--number"
          i18n="@@jobSplit"
        >
          Job Split
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="table__cell table__cell--number"
        >
          <ng-container
            *ngIf="element.earningType === EarningTypesEnum.ProductionEarning"
          >
            {{
              element.loadType === LoadTypesEnum.Service
                ? '--'
                : (element.splitPercent | number: '1.2-2') + '%'
            }}
          </ng-container>
        </td>
        <td
          cdk-footer-cell
          *cdkFooterCellDef
          [ngStyle]="{ display: 'none' }"
        ></td>
      </ng-container>

      <ng-container cdkColumnDef="pay">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          scope="col"
          class="table__cell table__cell--number"
        >
          <ng-container i18n="@@earnings-breakdown_associatePay">
            Associate Pay
          </ng-container>
          %
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="table__cell table__cell--number"
        >
          <ng-container
            *ngIf="element.earningType === EarningTypesEnum.ProductionEarning"
          >
            {{
              isCaliforniaView
                ? 'N/A'
                : (element.earningsPercent | number: '1.2-2') + '%'
            }}
          </ng-container>
        </td>
        <td
          cdk-footer-cell
          *cdkFooterCellDef
          [ngStyle]="{ display: 'none' }"
        ></td>
      </ng-container>

      <ng-container cdkColumnDef="earnings" stickyEnd>
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          scope="col"
          class="table__cell table__cell--action"
          i18n="@@earnings"
        >
          Earnings
        </th>
        <td
          cdk-cell
          *cdkCellDef="let element"
          class="table__cell table__cell--action table__cell--number"
        >
          <button
            class="table__button"
            (click)="
              openDetailsModal(element.earningType, element, isCaliforniaView)
            "
            [attr.data-cy]="
              element.earningType === EarningTypesEnum.ProductionEarning
                ? 'productionEarningsButton'
                : element.earningType === EarningTypesEnum.HourlyEarning
                ? 'hourlyEarningsButton'
                : element.earningType === EarningTypesEnum.ExceptionEarning
                ? 'exceptionEarningsButton'
                : null
            "
          >
            <span class="table__button-label">
              <ng-container
                *ngIf="
                  element.earningType === EarningTypesEnum.ProductionEarning
                "
              >
                {{
                  isCaliforniaView
                    ? 'N/A'
                    : (element.associateEarnings | currency)
                }}
              </ng-container>
              <ng-container
                *ngIf="element.earningType === EarningTypesEnum.HourlyEarning"
              >
                {{ element.earnings | currency }}
              </ng-container>
              <ng-container
                *ngIf="
                  element.earningType === EarningTypesEnum.ExceptionEarning
                "
              >
                {{ element.earnings | currency }}
              </ng-container>
            </span>
            <span class="table__button-icon-wrapper">
              <app-svg-icon
                [name]="SvgIconNameEnum.ArrowRightFilled"
                class="table__button-icon"
              ></app-svg-icon>
            </span>
          </button>
        </td>
        <td
          cdk-footer-cell
          *cdkFooterCellDef
          class="table__cell table__cell--action table__cell--number"
        >
          <span class="table__totals">
            {{
              dayEarnings?.newCaliforniaPayModelUsed
                ? dayEarnings?.totalExceptionDailyEarnings > 0
                  ? (dayEarnings?.totalExceptionDailyEarnings | currency)
                  : 'N/A'
                : (dayEarnings?.totalDailyEarnings | currency)
            }}
          </span>
        </td>
      </ng-container>

      <tr cdk-header-row *cdkHeaderRowDef="columns"></tr>
      <tr
        cdk-row
        *cdkRowDef="let row; columns: columns; let element"
        [ngClass]="{
          table__row: true,
          'table__row--highlight':
            element.earningType === EarningTypesEnum.HourlyEarning ||
            element.earningType === EarningTypesEnum.ExceptionEarning
        }"
      ></tr>
      <tr cdk-footer-row *cdkFooterRowDef="columns"></tr>
    </table>
  </div>
</div>

<ng-template #emptyStateTranslation i18n="@@earnings-breakdown_noDataFound">
  No recent work history is available at the moment.
</ng-template>

<app-empty-state
  *ngIf="!dayEarnings?.allEarningsDetails.length"
  [emptyStateTemplate]="emptyStateTranslation"
></app-empty-state>
