import {
  Component,
  OnInit,
  forwardRef,
  Output,
  EventEmitter,
  OnDestroy,
  Input
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subject } from 'rxjs';
import { ValidationStatus } from '../../enums/ValidationStatus';
import { NumberInputAppearance } from '../../../../../shared/enums/NumberInput';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsUtilitiesService } from '../../../../../shared/services/analytics-utilities.service';
import { AnalyticsActions } from '../../../../../shared/enums/AnalyticsActions';
import { AnalyticsCategories } from '../../../../../shared/enums/AnalyticsCategories';

@Component({
  selector: 'app-goal-slider-input',
  templateUrl: './goal-slider-input.component.html',
  styleUrls: ['./goal-slider-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoalSliderInputComponent),
      multi: true
    }
  ]
})
export class GoalSliderInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  formGroup: FormGroup;
  onDestroySubject = new Subject();
  ValidationStatusEnum = ValidationStatus;
  isInputFocused = false;

  @Input() name: string;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() validationStatus: ValidationStatus;

  @Input() hasError = false;
  @Input() appearance: NumberInputAppearance;
  @Output() keyupEvent = new EventEmitter();
  @Output() blurEvent = new EventEmitter();
  @Output() focusEvent = new EventEmitter();

  @Input() analyticsId: string;
  @Input() analyticsLabel: any;

  // Use this input only if same control is used twice or more in same form
  @Input() value = null;

  constructor(
    private fb: FormBuilder,
    private analyticsUtilities: AnalyticsUtilitiesService
  ) {
    this.formGroup = this.fb.group({
      value: null
    });
  }

  ngOnInit() {
    this.formGroup
      .get('value')
      .valueChanges.pipe(takeUntil(this.onDestroySubject))
      .subscribe(value => {
        this.propagateChange(value);
        this.value = value;
      });
  }

  ngOnDestroy() {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  writeValue(value) {
    this.formGroup.get('value').setValue(value, { emitEvent: false });
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  onKeyup(event) {
    this.keyupEvent.emit(event);
  }

  onFocus(event) {
    this.isInputFocused = true;
    this.focusEvent.emit(event);
    if (this.analyticsId) {
      this.analyticsUtilities.customAnalyticsEvent(this.analyticsId, {
        label: this.analyticsLabel,
        action: AnalyticsActions.Focus,
        category: AnalyticsCategories.Inputs
      });
    }
  }

  onBlur(event) {
    this.isInputFocused = false;
    this.blurEvent.emit(event);
  }

  private propagateChange = (value: any) => {};
}
