<app-page-section-header
  data-cy="productivityThisWeek"
  i18n-title="@@productivity_Title"
  title="Productivity this week"
  i18n-subtitle="@@productivity_Subtitle"
  subtitle="See how you are measuring up to the productivity and hourly goals you set."
></app-page-section-header>

<div class="productivity">
  <div class="productivity__item">
    <ng-template #gaugePalletsLabel i18n="@@productivity_palletsPerHour">
      Pallets per hour
    </ng-template>

    <ng-template #gaugeCasesLabel i18n="@@productivity_casesPerHour">
      Cases per hour
    </ng-template>

    <div class="productivity__link-container">
      <a
        class="productivity__image-link"
        [routerLink]="linkToGoalEditPage"
        cdkMonitorElementFocus
      >
        <app-goal-gauge
          [id]="'items'"
          [isAverageScale]="true"
          [current]="currentItemsPerHour"
          [max]="goalItemsPerHour"
          suffix="avg."
          i18n-suffix="@@avg."
          [labelTemplate]="
            itemType === itemTypeEnum.Pallets
              ? gaugePalletsLabel
              : gaugeCasesLabel
          "
        ></app-goal-gauge>
      </a>
    </div>
  </div>
  <div class="productivity__item">
    <div class="productivity__link-container">
      <a
        class="productivity__image-link"
        [routerLink]="linkToGoalEditPage"
        cdkMonitorElementFocus
      >
        <app-goal-gauge
          [id]="'hours'"
          [max]="goalWorkedHours"
          [current]="currentWorkedHours"
          suffix="so far"
          i18n-suffix="@@soFar"
          label="Hours worked"
          i18n-label="@@productivity_hoursWorked"
        ></app-goal-gauge>
      </a>
    </div>
  </div>
</div>
