<main class="landing">
  <div class="landing__header welcome-header">
    <app-svg-icon
      class="welcome-header__icon"
      [name]="SvgIconNameEnum.CapstoneLogo"
      i18n-description="@@CapstoneLogotype"
      description="Capstone logotype"
    ></app-svg-icon>
    <h1 class="welcome-header__heading">
      <span class="welcome-header__subheading" i18n="@@welcomeTo">
        Welcome to
      </span>
      <ng-container i18n="@@myCapstone">My Capstone</ng-container>
    </h1>
  </div>
  <div class="landing__action">
    <app-button-link
      (buttonClick)="login()"
      [fullWidth]="true"
      i18n-label="@@landing-page_logIntoMyAccount"
      label="Log into my account"
    ></app-button-link>
  </div>
</main>
