import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { AppState } from '../../../reducers';

@Injectable({
  providedIn: 'root'
})
export class LandingGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate() {
    return this.store
      .pipe(select(fromRoot.getIsUserLoggedIn))
      .map(isUserLoggedIn => {
        const navigation = this.router.getCurrentNavigation();
        if (isUserLoggedIn) {
          this.router.navigate(['news'], navigation.extras);
          return false;
        }
        return true;
      });
  }
}
