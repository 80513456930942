import {
  AfterViewInit,
  Component,
  ContentChildren,
  OnInit,
  QueryList,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { TabLabelWrapperDirective } from '../tab-label-wrapper.directive';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { END, ENTER, hasModifierKey, HOME, SPACE } from '@angular/cdk/keycodes';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tab-header',
  templateUrl: './tab-header.component.html',
  styleUrls: ['./tab-header.component.scss']
})
export class TabHeaderComponent
  implements OnInit, AfterViewInit, OnDestroy {
  private selectedTabIndex: number;

  @Input() get selectedIndex(): number {
    return this.selectedTabIndex;
  }
  set selectedIndex(index) {
    this.selectedTabIndex = index;
    this.keyManager.updateActiveItem(index);
  }

  @Output() selectIndex = new EventEmitter();
  @Output() selectFocusedIndex = new EventEmitter();
  @Output() indexFocused = new EventEmitter();

  @ContentChildren(TabLabelWrapperDirective, { descendants: false })
  tabLabels: QueryList<TabLabelWrapperDirective>;

  keyManager: FocusKeyManager<TabLabelWrapperDirective>;
  private destroySubject = new Subject();

  get focusIndex(): number {
    // tslint:disable-next-line:no-non-null-assertion
    return this.keyManager ? this.keyManager.activeItemIndex! : 0;
  }

  set focusIndex(value: number) {
    if (this.focusIndex === value || !this.keyManager) {
      return;
    }
    this.keyManager.setActiveItem(value);
  }

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.keyManager = new FocusKeyManager<TabLabelWrapperDirective>(
      this.tabLabels
    )
      .withHorizontalOrientation('ltr')
      .withWrap();
    this.keyManager.setFirstItemActive();
    this.keyManager.change
      .pipe(takeUntil(this.destroySubject))
      .subscribe(newFocusIndex => {
        this.indexFocused.emit(newFocusIndex);
      });
  }

  onKeydown(event) {
    if (hasModifierKey(event)) {
      return;
    }

    switch (event.keyCode) {
      case HOME:
        this.keyManager.setFirstItemActive();
        event.preventDefault();
        break;
      case END:
        this.keyManager.setLastItemActive();
        event.preventDefault();
        break;
      case ENTER:
      case SPACE:
        if (this.focusIndex !== this.selectedTabIndex) {
          this.selectFocusedIndex.emit(this.focusIndex);
          event.preventDefault();
        }
        break;
      default:
        this.keyManager.onKeydown(event);
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next(true);
  }
}
