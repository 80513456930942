import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  AppState,
  getActiveWeekGoal,
  getActiveWeekPaySplit,
  getActiveWeekPerformance,
  getUserEmailPrefix
} from '../../../../reducers';
import { EMPTY, of } from 'rxjs';
import { Router } from '@angular/router';
import { GoalService } from '../services/goal.service';
import { GoalActions } from '../actions';
import { LayoutActions } from '../../../../core/actions';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../../../shared/error-handlers/catch-app-error';
import { GoalSubmitRequest } from '../../../../core/request/goal/goalSubmitRequest';
import { GoalDetails } from '../../../../core/dataEntities/goal/goalDetails';
import { IdDateFormat } from '../../../../shared/constants';
import moment from 'moment';
import { SlidersConfig } from '../config/slidersConfig';
import { TranslationService } from '../../../../shared/services/translation.service';
import { TranslationMessages } from '../../../../shared/enums/TranslationMessages';

@Injectable()
export class GoalEffects {
  constructor(
    private actions$: Actions,
    private goalService: GoalService,
    private store: Store<AppState>,
    private router: Router,
    @Inject(APP_ERROR_MESSAGES) private errorMessages,
    @Inject(LOCALE_ID) protected localeId: string,
    private translation: TranslationService
  ) {}

  submitGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalActions.submitGoal),
      withLatestFrom(
        this.store.select(getUserEmailPrefix),
        this.store.select(getActiveWeekPaySplit)
      ),
      mergeMap(([{ goal }, userId, associatePaySplit]) => {
        const request = this.createGoalSubmitRequest(userId, goal);

        return this.goalService
          .submitGoal(request, goal.id, associatePaySplit)
          .pipe(
            switchMap(response => {
              if (response) {
                return of(
                  LayoutActions.showSuccessMessage({
                    title: this.translation.translate(
                      TranslationMessages.GoalSubmitted
                    ),
                    subtitle: this.translation.translate(
                      TranslationMessages.GoalKeepUp
                    ),
                    persistRouteChangeCount: 1
                  }),
                  GoalActions.goalSubmitted({ goal: response })
                );
              }

              return EMPTY;
            }),
            catchError(handleAppError(this.errorMessages))
          );
      })
    )
  );
  goalSubmitted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GoalActions.goalSubmitted),
        tap(action => {
          return this.router.navigate(['/wages', action.goal.id, 'goal']);
        })
      ),
    { dispatch: false }
  );
  loadWeeklyGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalActions.loadWeeklyGoal),
      withLatestFrom(
        this.store.select(getActiveWeekGoal),
        this.store.select(getActiveWeekPerformance, { config: SlidersConfig })
      ),
      filter(
        ([{ weekEndingDate }, activeWeekGoal, performance]) =>
          activeWeekGoal !== null && !!performance
      ),
      mergeMap(([{ weekEndingDate }, activeWeekGoal, performance]) => {
        this.store.dispatch(GoalActions.toggleGoalLoading());
        return this.goalService
          .getWeeklyGoal(weekEndingDate, performance.associatePaySplit)
          .pipe(
            map(weeklyGoal => {
              return GoalActions.goalLoaded({
                goal: weeklyGoal
              });
            }),
            catchError(handleAppError(this.errorMessages))
          );
      })
    )
  );
  loadWeeklyPerformance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalActions.loadWeeklyPerformance),
      withLatestFrom(
        this.store.select(getActiveWeekPerformance, { config: SlidersConfig })
      ),
      filter(
        ([{ weekEndingDate }, performance]) =>
          performance !== null && weekEndingDate != null
      ),
      switchMap(([{ weekEndingDate }]) => {
        this.store.dispatch(GoalActions.togglePerformanceLoading());

        return this.goalService.getWeeklyPerformance(weekEndingDate).pipe(
          map(performance => {
            return GoalActions.weeklyPerformanceLoaded({
              performance: {
                id: moment(weekEndingDate).format(IdDateFormat),
                ...performance
              }
            });
          }),
          catchError(handleAppError(this.errorMessages))
        );
      })
    )
  );

  private createGoalSubmitRequest(
    userId: string,
    goalDetails: GoalDetails
  ): GoalSubmitRequest {
    return {
      user: userId,
      casesPerHour: goalDetails.casesPerHour,
      palletsPerHour: goalDetails.palletsPerHour,
      revenuePerCase: goalDetails.revenuePerCase,
      revenuePerPallet: goalDetails.revenuePerPallet,
      hours: goalDetails.hours,
      itemType: goalDetails.itemType
    } as GoalSubmitRequest;
  }
}
