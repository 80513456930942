<ng-container *ngFor="let formStep of formSteps; let i = index">
  <section
    [ngClass]="{
      'multi-step-form': true,
      'is-active': formStep.isEditable
    }"
    [attr.id]="formStep.name"
  >
    <ng-container *ngIf="formStep.isEditable; else readOnly">
      <ng-container
        *ngTemplateOutlet="
          formStep.editableDef?.template;
          context: {
            step: formStep,
            stepForm: formGroup.get(formStep.name),
            nextStepTitle:
              multiStepFormService.editableStep$
              | async
              | getNextEditableStepTitle: formSteps
          }
        "
      ></ng-container>
    </ng-container>
    <ng-template #readOnly>
      <ng-container
        *ngTemplateOutlet="
          formStep.readOnlyDef?.template;
          context: {
            step: formStep,
            stepForm: formGroup.get(formStep.name),
            canStepBeEditable:
              i
              | canStepBeEditable
                : formSteps
                : (multiStepFormService.editableStep$ | async)
          }
        "
      ></ng-container>
    </ng-template>
  </section>
</ng-container>
