import {
  Component,
  Inject,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';
import { DialogRef } from '../../../../../shared/modules/modal/dialog-ref';
import { DIALOG_DATA } from '../../../../../shared/modules/modal/dialog-config';
import { filter, find, flow, isEqual, map } from 'lodash/fp';
import { CheckBoxInputAlign } from '../../../../../shared/enums/CheckBoxInput';

@Component({
  selector: 'app-region-filter',
  templateUrl: './region-filter.component.html',
  styleUrls: ['./region-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionFilterComponent implements OnInit {
  SvgIconNameEnum = SvgIconName;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;
  CheckBoxInputAlignEnum = CheckBoxInputAlign;
  availableFilters = [];
  selectedRegions = [];

  constructor(
    private modalRef: DialogRef<RegionFilterComponent>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.selectedRegions = [...this.data.selectedRegions];
    this.availableFilters = filter(region => region.count !== 0)(
      this.data.regions
    );
  }

  selectDuration(value) {
    const index = this.selectedRegions.indexOf(value);
    if (index > -1) {
      this.selectedRegions.splice(index, 1);
    } else {
      this.selectedRegions = [...this.selectedRegions, value];
    }
  }

  filter() {
    this.modalRef.close({
      success: true,
      data: {
        regions: this.selectedRegions
      }
    });
  }

  resetFilter() {
    this.deselectAll();
    this.modalRef.close({
      success: true,
      data: {
        regions: this.selectedRegions
      }
    });
  }

  closeModal() {
    this.modalRef.close({ success: false });
  }

  selectAll() {
    this.selectedRegions = [
      ...flow(map(duration => duration['value']))(this.availableFilters)
    ];
  }

  deselectAll() {
    this.selectedRegions = [];
  }

  arrayHasValue(array, value) {
    if (array && array.length && value) {
      return !!find(i => isEqual(value, i))(array);
    }
    return false;
  }
}
