import { createAction, props } from '@ngrx/store';
import { WeeklyCheckIn } from '../../core/dataEntities/profile/weeklyCheckIn';
import { WeeklyCheckinList } from '../../core/dataEntities/profile/weeklyCheckinList';
import { AssociateProductionDetails } from '../../core/dataEntities/associate/associateProductionDetails';

export const loadWeeklyCheckinList = createAction(
  '[Profile] Load Weekly Checkin List'
);
export const weeklyCheckinListLoaded = createAction(
  '[Profile] Weekly Checkin List Loaded',
  props<{ weeklyCheckinList: WeeklyCheckinList }>()
);

export const saveWeeklyCheckIn = createAction(
  '[Profile] Save Weekly Check-In',
  props<{
    date: string;
    checkIn: WeeklyCheckIn;
  }>()
);

export const weeklyCheckInSaved = createAction(
  '[Profile] Weekly Check-In Saved',
  props<{ date: string }>()
);

export const loadProductionDetails = createAction(
  '[Profile] Load Production Details'
);
export const productionDetailsLoaded = createAction(
  '[Profile] Production Details Loaded',
  props<{ productionDetails: AssociateProductionDetails }>()
);

export const toggleProductionDetailsIsLoading = createAction(
  '[Profile] Toggle Production Details is Loading',
  props<{ isLoading: boolean }>()
);

export const editPhoto = createAction('[Profile] Edit Photo');
