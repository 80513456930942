import * as fromRoot from '../../reducers';
import { first, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/api.gateway.service";
import * as i2 from "@ngrx/store";
export class WagesApiService {
    constructor(apiGateway, store) {
        this.apiGateway = apiGateway;
        this.store = store;
    }
    getWeeklyPayments() {
        return this.store.select(fromRoot.getAuthTokenWithApexUserId).pipe(first(), switchMap(({ authToken, userId }) => {
            if (!userId) {
                throw new Error('User not found.');
            }
            return this.apiGateway.get(`associates/${userId}/earnings/history`, authToken);
        }));
    }
    getWeekPaymentsBreakdown(weekEndDate) {
        return this.store.select(fromRoot.getAuthTokenWithApexUserId).pipe(first(), switchMap(({ authToken, userId }) => {
            if (!userId) {
                throw new Error('User not found.');
            }
            return this.apiGateway.get(`associates/${userId}/earnings/weekly/${weekEndDate}/details`, authToken);
        }));
    }
}
WagesApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WagesApiService_Factory() { return new WagesApiService(i0.ɵɵinject(i1.ApiGatewayService), i0.ɵɵinject(i2.Store)); }, token: WagesApiService, providedIn: "root" });
