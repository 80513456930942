/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i4 from "../../../shared/components/svg-icon/svg-icon.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./notification-bar.component";
import * as i7 from "@angular/router";
var styles_NotificationBarComponent = [i0.styles];
var RenderType_NotificationBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationBarComponent, data: { "animation": [{ type: 7, name: "enterLeaveTrigger", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { height: "0", "min-height": "0" }, offset: null }, { type: 4, styles: { type: 6, styles: {}, offset: null }, timings: "0.3s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { height: "0", "min-height": "0" }, offset: null }, timings: "0.3s ease-out" }], options: null }], options: {} }] } });
export { RenderType_NotificationBarComponent as RenderType_NotificationBarComponent };
function View_NotificationBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "notification-bar__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noActionNotification.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "notification-bar__container notification-bar__container--noaction"], ["role", "status"]], [[24, "@enterLeaveTrigger", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "notification-bar__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationBarComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.noActionNotification.subtitle; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.noActionNotification.title; _ck(_v, 2, 0, currVal_1); }); }
function View_NotificationBarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "notification-bar__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorNotification.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "notification-bar__container notification-bar__container--error"], ["role", "alert"]], [[24, "@enterLeaveTrigger", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "notification-bar__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationBarComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "notification-bar__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDismissError() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-svg-icon", [["class", "notification-bar__icon"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(7, 638976, null, 0, i4.SvgIconComponent, [i5.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dismiss"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.errorNotification.subtitle; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.SvgIconNameEnum.Close; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.errorNotification.title; _ck(_v, 2, 0, currVal_1); }); }
function View_NotificationBarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "notification-bar__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoNotification.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationBarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "notification-bar__container notification-bar__container--info"], ["role", "status"]], [[24, "@enterLeaveTrigger", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "notification-bar__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationBarComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "notification-bar__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDismissInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-svg-icon", [["class", "notification-bar__icon"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(7, 638976, null, 0, i4.SvgIconComponent, [i5.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dismiss"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.infoNotification.subtitle; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.SvgIconNameEnum.Close; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.infoNotification.title; _ck(_v, 2, 0, currVal_1); }); }
function View_NotificationBarComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "notification-bar__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.successNotification.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationBarComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "notification-bar__container notification-bar__container--success"], ["role", "status"]], [[24, "@enterLeaveTrigger", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "notification-bar__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationBarComponent_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "notification-bar__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDismissSuccess() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-svg-icon", [["class", "notification-bar__icon"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(7, 638976, null, 0, i4.SvgIconComponent, [i5.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dismiss"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.successNotification.subtitle; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.SvgIconNameEnum.Close; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.successNotification.title; _ck(_v, 2, 0, currVal_1); }); }
export function View_NotificationBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["data-cy", "notificationBar"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "notification-bar": 0, "notification-bar--show-over": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationBarComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationBarComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationBarComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationBarComponent_7)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, _co.showOver()); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.noActionNotification; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.errorNotification; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.infoNotification; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.successNotification; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_NotificationBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-bar", [], null, null, null, View_NotificationBarComponent_0, RenderType_NotificationBarComponent)), i1.ɵdid(1, 114688, null, 0, i6.NotificationBarComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationBarComponentNgFactory = i1.ɵccf("app-notification-bar", i6.NotificationBarComponent, View_NotificationBarComponent_Host_0, { errorNotification: "errorNotification", infoNotification: "infoNotification", noActionNotification: "noActionNotification", successNotification: "successNotification" }, { dismissError: "dismissError", dismissInfo: "dismissInfo", dismissSuccess: "dismissSuccess" }, []);
export { NotificationBarComponentNgFactory as NotificationBarComponentNgFactory };
