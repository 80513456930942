import { ComponentType } from '@angular/cdk/portal';
import {
  Inject,
  Injectable,
  Injector,
  Optional,
  TemplateRef
} from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import { BaseModalService } from '../base-modal/base-modal.service';
import { DIALOG_DEFAULT_OPTIONS, DialogConfig } from '../dialog-config';
import { DialogRef } from '../dialog-ref';
import { TopSheetComponent } from './top-sheet.component';

@Injectable()
export class TopSheetService extends BaseModalService {
  constructor(
    overlay: Overlay,
    injector: Injector,
    @Optional() location: Location,
    @Optional() @Inject(DIALOG_DEFAULT_OPTIONS) private config: DialogConfig
  ) {
    super(overlay, injector, location, config);
  }

  open<T, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: DialogConfig
  ): DialogRef<T, R> {
    const defaultConfig = this.config || new DialogConfig();

    config = this.applyConfigDefaults(config, {
      ...defaultConfig,
      ariaLabelledBy: 'top-sheet-header'
    });

    config.position = {
      top: '0'
    };

    const overlayRef = this.createOverlay(config);
    const dialogContainer = this.attachDialogContainer(
      overlayRef,
      TopSheetComponent,
      config
    );
    const dialogRef = this.attachDialogContent<T, R>(
      componentOrTemplateRef,
      dialogContainer.instance,
      overlayRef,
      config
    );

    return dialogRef;
  }
}
