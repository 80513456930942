import { createAction, props } from '@ngrx/store';

export const openSidenav = createAction('[Layout] Open Sidenav');
export const closeSidenav = createAction('[Layout] Close Sidenav');
export const showError = createAction(
  '[Layout] Show Error Message',
  props<{
    message: string;
    error: Error | any;
    showOver?: boolean;
  }>()
);
export const showInfoMessage = createAction(
  '[Layout] Show Info Message',
  props<{
    message: string;
    showOver?: boolean;
    persistRouteChangeCount?: number;
  }>()
);
export const showSuccessMessage = createAction(
  '[Layout] Show Success Message',
  props<{
    title: string;
    subtitle?: string;
    showOver?: boolean;
    persistRouteChangeCount?: number;
  }>()
);
export const dismissError = createAction('[Layout] Dismiss Error Message');
export const dismissInfo = createAction('[Layout] Dismiss Info Message');
export const dismissSuccess = createAction('[Layout] Dismiss Success Message');
export const appOnline = createAction('[Layout] App Online');
export const appOffline = createAction('[Layout] App Offline');

export const apiOffline = createAction('[Layout] Api Offline');

export const menuNotification = createAction('[Layout] Menu Notification');
export const dismissMenuNotification = createAction(
  '[Layout] Dismiss Menu Notification'
);

export const appOnInit = createAction('[Layout] App on Init');
export const toggleMenuBar = createAction(
  '[Layout] Toggle Menu Bar',
  props<{ show: boolean }>()
);

export const toggleAppLoading = createAction(
  '[Layout] Toggle Full Screen App Loader',
  props<{ loading: boolean }>()
);

export const toggleNavigationBar = createAction(
  '[Layout] Toggle Navigation Bar',
  props<{ hide: boolean }>()
);

export const showAlertUpdate = createAction('[Layout] Show Alert Update');

export const cleanLayoutMessages = createAction('[Layout] Clean Info Messages');

export const reducePersistRouteChangeCount = createAction(
  '[Layout] Reduce Persist Route Change Count'
);
