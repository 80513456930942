import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { DialogRef } from '../../../../../shared/modules/modal/dialog-ref';
import { DIALOG_DATA } from '../../../../../shared/modules/modal/dialog-config';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';
import { filter, find, flow, isEqual, map } from 'lodash/fp';

@Component({
  selector: 'app-job-title-filter',
  templateUrl: './job-title-filter.component.html',
  styleUrls: ['./job-title-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobTitleFilterComponent implements OnInit {
  SvgIconNameEnum = SvgIconName;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;
  selectedJobTitles = [];
  availableFilters = [];

  constructor(
    private modalRef: DialogRef<JobTitleFilterComponent>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.selectedJobTitles = [...this.data.selectedJobTitles];
    this.availableFilters = filter(jobTitle => jobTitle.count !== 0)(
      this.data.jobTitles
    );
  }

  selectJobTitle(value) {
    const index = this.selectedJobTitles.indexOf(value);
    if (index > -1) {
      this.selectedJobTitles.splice(index, 1);
    } else {
      this.selectedJobTitles = [...this.selectedJobTitles, value];
    }
  }

  filter() {
    this.modalRef.close({
      success: true,
      data: {
        jobTitles: this.selectedJobTitles
      }
    });
  }

  selectAll() {
    this.selectedJobTitles = [
      ...flow(map(duration => duration['jobTitle']))(this.availableFilters)
    ];
  }

  deselectAll() {
    this.selectedJobTitles = [];
  }

  resetFilter() {
    this.deselectAll();
    this.modalRef.close({
      success: true,
      data: {
        jobTitles: this.selectedJobTitles
      }
    });
  }

  closeModal() {
    this.modalRef.close({ success: false });
  }

  arrayHasValue(array, value) {
    if (array && array.length && value) {
      return !!find(i => isEqual(value, i))(array);
    }
    return false;
  }
}
