import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  Output,
  EventEmitter
} from '@angular/core';
import { SvgIconColor, SvgIconName } from '../../../enums/SvgIcon';
import { MenuColorAppearance, MenuTitleFont } from '../../../enums/Menu';
import { ChipAppearance } from '../../../enums/Chip';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html'
})
export class MenuItemComponent implements OnInit {
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() subTitle: string;
  @Input() subTitleTemplate: TemplateRef<any>;
  @Input() subTitleColor: MenuColorAppearance = MenuColorAppearance.Black;
  @Input() chipTitle: string;
  @Input() chipTitleTemplate: TemplateRef<any>;
  @Input() chipAppearance = ChipAppearance.Green;
  @Input() titleFont = MenuTitleFont.Lato;
  @Input() appearance: MenuColorAppearance;
  @Input() iconName: SvgIconName;
  @Input() iconColorPrimary: SvgIconColor;
  @Input() iconColorSecondary: SvgIconColor;
  @Input() imageTemplate: TemplateRef<any>;
  @Input() isHighlighted = false;
  @Input() isExternalUrl = false;
  @Input() link: string | any[];
  @Input() queryParams: any;
  @Input() dataCy: string;
  @Output() buttonClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClickButton(event) {
    this.buttonClick.emit(event);
  }
}
