import { Routes } from '@angular/router';
import { WagesComponent } from './wages.component';
import { PayHistoryComponent } from './containers/pay-history/pay-history.component';
import { PayComponent } from './containers/pay/pay.component';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { TrainingComponent } from './containers/training/training.component';
import { Module } from '../shared/enums/Module';
import { GoalComponent } from './containers/goal/goal.component';
import { GoalPreviewGuard } from './modules/goal/guards/goal-preview.guard';
const ɵ0 = {
    pageTitle: TranslationMessages.WagesHistoryTitle
}, ɵ1 = {
    module: Module.Wages,
    showMenuBar: true,
    pageTitle: TranslationMessages.WagesHistoryTitle,
    siteMapKey: SiteMapItemKeys.Wages
}, ɵ2 = {
    module: Module.Wages,
    pageTitle: TranslationMessages.WagesPayTitle,
    siteMapKey: SiteMapItemKeys.WagesDetails
}, ɵ3 = {
    module: Module.Wages,
    siteMapKey: SiteMapItemKeys.Training
}, ɵ4 = {
    module: Module.Wages,
    pageTitle: TranslationMessages.GoalBreakdownTitle,
    siteMapKey: SiteMapItemKeys.Goal
};
const routes = [
    {
        path: 'wages',
        component: WagesComponent,
        canActivate: [AuthenticationGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: PayHistoryComponent,
                data: ɵ1
            },
            {
                path: ':id/pay',
                component: PayComponent,
                data: ɵ2
            },
            {
                path: ':id/training',
                component: TrainingComponent,
                data: ɵ3
            },
            {
                path: ':id/goal',
                canActivate: [GoalPreviewGuard],
                component: GoalComponent,
                data: ɵ4
            }
        ]
    }
];
export class WagesRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
