<ul
  class="menu"
  [ngClass]="{
    menu: true,
    'menu--top-rounded-border': topRoundedBorder,
    'menu--bottom-rounded-border': bottomRoundedBorder
  }"
  *ngIf="menuItems.length > 0"
>
  <ng-container *ngFor="let menuItem of menuItems">
    <li
      [ngClass]="{
        'menu-item': true,
        'menu-item--green':
          menuItem.appearance === MenuColorAppearanceEnum.Green,
        'menu-item--red': menuItem.appearance === MenuColorAppearanceEnum.Red,
        'menu-item--yellow':
          menuItem.appearance === MenuColorAppearanceEnum.Yellow,
        'menu-item--highlighted': menuItem.isHighlighted
      }"
      [attr.data-cy]="menuItem.dataCy"
    >
      <a
        *ngIf="menuItem.isExternalUrl && menuItem.link"
        class="menu-item__link"
        [href]="menuItem.link"
        cdkMonitorElementFocus
      >
        <ng-container *ngTemplateOutlet="inner"></ng-container>
      </a>

      <a
        *ngIf="!menuItem.isExternalUrl && menuItem.link"
        class="menu-item__link"
        [routerLink]="menuItem.link"
        [queryParams]="menuItem.queryParams"
        cdkMonitorElementFocus
      >
        <ng-container *ngTemplateOutlet="inner"></ng-container>
      </a>

      <button
        *ngIf="!menuItem.link"
        (click)="menuItem.onClickButton($event)"
        class="menu-item__link"
        type="button"
        cdkMonitorElementFocus
      >
        <ng-container *ngTemplateOutlet="inner"></ng-container>
      </button>

      <ng-template #inner>
        <span
          *ngIf="menuItem.iconName || menuItem.imageTemplate"
          class="menu-item__image-container"
        >
          <app-svg-icon
            *ngIf="menuItem.iconName"
            class="menu-item__icon"
            [name]="menuItem.iconName"
            [colorPrimary]="menuItem.iconColorPrimary"
            [colorSecondary]="menuItem.iconColorSecondary"
          ></app-svg-icon>
          <ng-container *ngIf="menuItem.imageTemplate">
            <ng-container
              *ngTemplateOutlet="menuItem.imageTemplate"
            ></ng-container>
          </ng-container>
        </span>

        <span class="menu-item__content">
          <ng-template #defaultTitleTemplate>
            {{ menuItem.title }}
          </ng-template>

          <span>
            <span
              [ngClass]="{
                'menu-item__title': true,
                'menu-item__title--roboto':
                  menuItem.titleFont === MenuTitleFontEnum.Roboto,
                'menu-item__title--has-chip':
                  menuItem.chipTitle || menuItem.chipTitleTemplate
              }"
            >
              <ng-container
                *ngTemplateOutlet="
                  menuItem.titleTemplate
                    ? menuItem.titleTemplate
                    : defaultTitleTemplate
                "
              ></ng-container>
            </span>

            <ng-template #defaultChipTitleTemplate>
              {{ menuItem.chipTitle }}
            </ng-template>

            <app-chip
              *ngIf="menuItem.chipTitle || menuItem.chipTitleTemplate"
              class="menu-item__chip"
              [appearance]="menuItem.chipAppearance"
            >
              <ng-container
                *ngTemplateOutlet="
                  menuItem.chipTitleTemplate
                    ? menuItem.chipTitleTemplate
                    : defaultChipTitleTemplate
                "
              ></ng-container>
            </app-chip>
          </span>

          <ng-template #defaultSubtitleTemplate>
            {{ menuItem.subTitle }}
          </ng-template>

          <span
            [ngClass]="{
              'menu-item__subtitle': true,
              'menu-item__subtitle--red':
                menuItem.subTitleColor === MenuColorAppearanceEnum.Red
            }"
          >
            <ng-container
              *ngTemplateOutlet="
                menuItem.subTitleTemplate
                  ? menuItem.subTitleTemplate
                  : defaultSubtitleTemplate
              "
            ></ng-container>
          </span>
        </span>
        <app-svg-icon
          class="menu-item__arrow"
          [name]="SvgIconNameEnum.ArrowRightFilled"
        ></app-svg-icon>
      </ng-template>
    </li>
  </ng-container>
</ul>
