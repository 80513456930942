import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppState, getAuthTokenWithApexUserId } from '../../reducers';
import { first, switchMap } from 'rxjs/operators';
import { ApiGatewayService } from './api.gateway.service';
import { Store } from '@ngrx/store';
import { PostDetailsResponse } from '../../core/responses/news/postDetailsResponse';

@Injectable({
  providedIn: 'root'
})
export abstract class FeedService {
  protected constructor(
    private apiGatewayService: ApiGatewayService,
    private storeService: Store<AppState>
  ) {}

  getPostsAdditionalData(postsIds: string[]): Observable<PostDetailsResponse> {
    return this.storeService.select(getAuthTokenWithApexUserId).pipe(
      first(),
      switchMap(({ authToken, userId }) => {
        if (!userId) {
          throw new Error('User not found.');
        }

        if (postsIds.length === 0) {
          return of({
            user: userId,
            postLikeDetails: []
          });
        }

        return this.apiGatewayService.get<PostDetailsResponse>(
          `comms/newsfeed/posts/likes/associate/${userId}`,
          authToken,
          {
            postIds: postsIds.join(',')
          }
        );
      })
    );
  }

  likePost(postId: string, like: boolean): Observable<any> {
    return this.storeService.select(getAuthTokenWithApexUserId).pipe(
      first(),
      switchMap(({ authToken, userId }) => {
        if (!userId) {
          throw new Error('User not found.');
        }

        return this.apiGatewayService.put<any>(
          `comms/newsfeed/post/${postId}/like/associate/${userId}`,
          authToken,
          {
            IsLikedByUser: like
          }
        );
      })
    );
  }
}
