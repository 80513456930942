<div
  [ngClass]="{
    'page-header': true,
    'page-header--blue': appearance === PageHeaderAppearanceEnum.Blue,
    'page-header--transparent':
      appearance === PageHeaderAppearanceEnum.Transparent,
    'page-header--green': appearance === PageHeaderAppearanceEnum.Green
  }"
  [attr.role]="isHeader ? 'banner' : null"
>
  <a
    *ngIf="type === PageHeaderTypeEnum.Back"
    [routerLink]="link"
    [queryParams]="queryParams"
    [state]="{ navigateBack: true }"
    class="page-header__link"
    data-cy="backButton"
  >
    <app-svg-icon
      [name]="SvgIconNameEnum.ArrowLeft"
      class="page-header__link-icon"
    ></app-svg-icon>
    <span class="page-header__link-title" i18n="@@page-header_back">
      Back
    </span>
  </a>
  <a
    *ngIf="type === PageHeaderTypeEnum.Close"
    [routerLink]="link"
    [state]="{ navigateBack: true }"
    class="page-header__link"
  >
    <app-svg-icon
      [name]="SvgIconNameEnum.Close"
      class="page-header__link-icon"
    ></app-svg-icon>
    <span
      data-cy="closeButton"
      class="page-header__link-title"
      i18n="@@page-header_close"
    >
      Close
    </span>
  </a>
</div>
