import { Pipe, PipeTransform } from '@angular/core';
import { StatsLevel } from '../../../../enums/StatsLevel';
import { ChipAppearance } from '../../../../enums/Chip';

@Pipe({
  name: 'associateStatsLevelAppearance'
})
export class AssociateStatsLevelAppearancePipe implements PipeTransform {
  transform(value: StatsLevel, ...args: any[]): ChipAppearance {
    switch (value) {
      case StatsLevel.Top10:
      case StatsLevel.Top20:
      case StatsLevel.Top30:
        return ChipAppearance.Green;
      case StatsLevel.Top50:
      case StatsLevel.Bottom50:
        return ChipAppearance.Blue;
      case StatsLevel.Bottom30:
      case StatsLevel.Bottom20:
      case StatsLevel.Bottom10:
        return ChipAppearance.Yellow;
      default:
        return null;
    }
  }
}
