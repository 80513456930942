export enum TranslationMessages {
  DailyEarningsEmptyStateMessage = 'dailyEarningsEmptyStateMessage',
  NoInternetConnectionMessage = 'noInternetConnectionMessage',
  PalletsPerHour = 'palletsPerHour',
  CasesPerHour = 'casesPerHour',
  WorkHours = 'workHours',
  RevenuePerPallet = 'revenuePerPallet',
  RevenuePerCase = 'revenuePerCase',
  PalletsPerHourInfoBoxText = 'palletsPerHourInfoBoxText',
  CasesPerHourInfoBoxText = 'casesPerHourInfoBoxText',
  WorkHoursInfoBoxText = 'workHoursInfoBoxText',
  RevenuePerPalletInfoBoxText = 'revenuePerPalletInfoBoxText',
  RevenuePerCaseInfoBoxText = 'revenuePerCaseInfoBoxText',
  CurrentGoalInfoBoxText = 'currentGoalInfoBoxText',
  NewsTitle = 'newsTitle',
  WagesHistoryTitle = 'wagesHistoryTitle',
  WagesPayTitle = 'wagesPayTitle',
  MyTeamTitle = 'myTeamTitle',
  GoalBreakdownTitle = 'goalBreakdownTitle',
  GoalTitle = 'goalTitle',
  ResourcesFaqTitle = 'resourcesFaqTitle',
  ResourcesStarsTitle = 'resourcesStarsTitle',
  ResourcesTeamDirectoryTitle = 'resourcesTeamDirectoryTitle',
  ResourcesTitle = 'resourcesTitle',
  ShiftsTitle = 'shiftsTitle',
  ShiftCreateTitle = 'shiftCreateTitle',
  ShiftEditTitle = 'shiftEditTitle',
  ShiftPreviewTitle = 'shiftPreviewTitle',
  ShiftDetailsTitle = 'shiftDetailsTitle',
  DisclaimerTitle = 'disclaimerTitle',
  AddPwaTitle = 'addPwaTitle',
  AssociateCheckInHistoryTitle = 'associateCheckInHistoryTitle',
  AssociateProductionStatsTitle = 'associateProductionStatsTitle',
  SettingsTitle = 'settingsTitle',
  WeeklyCheckInTitle = 'weeklyCheckInTitle',
  ProductionStatsTitle = 'productionStatsTitle',
  CheckInHistoryTitle = 'checkInHistoryTitle',
  CovidTitle = 'covidTitle',
  RoadCrewTitle = 'roadCrewTitle',
  ShiftRequestRemoved = 'shiftRequestRemoved',
  ShiftRequestPosted = 'shiftRequestPosted',
  ShiftRequestNotifiedBody = 'shiftRequestNotifiedBody',
  ShiftRequestUpdated = 'shiftRequestUpdated',
  SessionExpired = 'sessionExpired',
  SettingsSaved = 'settingsSaved',
  LoggedOut = 'loggedOut',
  SurveySubmitted = 'surveySubmitted',
  SurveyThankYou = 'surveyThankYou',
  GoalSubmitted = 'goalSubmitted',
  GoalKeepUp = 'goalKeepUp',

  ErrorUnknown = 'errorUnknown',
  ErrorMessagesAssociateNotFound = 'errorMessagesAssociateNotFound',
  ErrorMessagesWeekEndingDateNotSaturday = 'errorMessagesWeekEndingDateNotSaturday',
  ErrorMessagesEarningsNotAllowedForSite = 'errorMessagesEarningsNotAllowedForSite',
  ErrorMessagesSurveyAlreadySubmitted = 'errorMessagesSurveyAlreadySubmitted',
  ErrorMessagesSurveyNotFound = 'errorMessagesSurveyNotFound',
  ErrorMessagesUnknownError = 'errorMessagesUnknownError',
  ErrorMessagesFileUploadFailed = 'errorMessagesFileUploadFailed',

  WeeklyCheckInSubmitted = 'weeklyCheckInSubmitted',
  ThanksForTheQuckUpdate = 'thanksForTheQuickUpdate',

  CallMe = 'callMe',
  TextMe = 'textMe',
  CallOrTextMe = 'callOrTextMe',
  Associate = 'associate',
  Associates = 'associates',

  MyTeamSortByTenure = 'myTeamSortByTenure',
  MyTeamSortByHoursWorked = 'myTeamSortByHoursWorked',
  MyTeamSortByAssociateName = 'myTeamSortByAssociateName',
  MyTeamSortByCheckInScore = 'myTeamSortByCheckInScore',

  AssociateStatsLevelTitleTop10 = 'associateStatsLevelTitleTop10',
  AssociateStatsLevelTitleTop20 = 'associateStatsLevelTitleTop20',
  AssociateStatsLevelTitleTop30 = 'associateStatsLevelTitleTop30',
  AssociateStatsLevelTitleTop50 = 'associateStatsLevelTitleTop50',
  AssociateStatsLevelTitleBottom50 = 'associateStatsLevelTitleBottom50',
  AssociateStatsLevelTitleBottom30 = 'associateStatsLevelTitleBottom30',
  AssociateStatsLevelTitleBottom20 = 'associateStatsLevelTitleBottom20',
  AssociateStatsLevelTitleBottom10 = 'associateStatsLevelTitleBottom10',

  Male = 'male',
  Female = 'female',
  PositiveDriverAvailability = 'positiveDriverAvailability',
  NegativeDriverAvailability = 'negativeDriverAvailability',
  IsDriverHasInsuranceYes = 'isDriverHasInsuranceYes',
  IsDriverHasInsuranceNo = 'isDriverHasInsuranceNo',
  StateID = 'stateID',
  Passport = 'passport',
  DriversLicense = 'driversLicense',
  Resident = 'resident',
  MilitaryID = 'militaryID',
  /* new 13 certificates */
  AssociateCertificationTeamLead = 'teamLead',
  AssociateCertificationSelectorVoice = 'selectorVoice',
  AssociateCertificationSelectorRF = 'selectorRF',
  AssociateCertificationSingleEPJ = 'singleEPJ',
  AssociateCertificationDoubleEPJ = 'doubleEPJ',
  AssociateCertificationTripleEPJ = 'tripleEPJ',
  AssociateCertificationReachTruckOperator = 'reachTruckOperator',
  AssociateCertificationCherryPicker = 'cherryPicker',
  AssociateCertificationDockStocker = 'dockStocker',
  AssociateCertificationSitDownForklift = 'sitDownForklift',
  AssociateCertificationClampMachine = 'clampMachine',
  AssociateCertificationSlipMachine = 'slipMachine',
  AssociateCertificationYardJockey = 'yardJockey',
  AssociateCertificationNoneNeeded = 'noneNeeded',
  /* end certificates */

  DocumentDisclaimerText = 'documentDisclaimerText',
  PhotoOfId = 'photoOfId',
  FrontOfDriverLicence = 'frontOfDriverLicence',
  DriverInsuranceCard = 'driverInsuranceCard',
  CameraSkipDriverInsuranceCard = 'cameraSkipDriverInsuranceCard',

  AsstTeamLeader = 'asstTeamLeader',
  Auditor = 'auditor',
  BackupTeamLead = 'backupTeamLead',
  Baler = 'baler',
  Clerk = 'clerk',
  ComplianceProgramAdministrator = 'complianceProgramAdministrator',
  CustomerServiceSupport = 'customerServiceSupport',
  DriverAssistant = 'driverAssistant',
  FieldRecruiter = 'fieldRecruiter',
  ForkliftOperator = 'forkliftOperator',
  FreightHandler = 'freightHandler',
  FreightHandlerRoadCrew = 'freightHandlerRoadCrew',
  FreightHauler = 'freightHauler',
  Fueler = 'fueler',
  Lead = 'lead',
  LeadAudit = 'leadAudit',
  LeadI = 'leadI',
  LeadIII = 'leadIII',
  LiftOperator = 'liftOperator',
  Loader = 'loader',
  Maintenance = 'maintenance',
  MaterialHandler = 'materialHandler',
  OfficeOrClerical = 'officeOrClerical',
  Painter = 'painter',
  PalletHandler = 'palletHandler',
  Palletizer = 'palletizer',
  ProductionManager = 'productionManager',
  ProductionSupervisor = 'productionSupervisor',
  Receiver = 'receiver',
  ReclaimsSpecialist = 'reclaimsSpecialist',
  Recruiter = 'recruiter',
  ReturnsProcessor = 'returnsProcessor',
  Sanitation = 'sanitation',
  SchedulingCoordinator = 'schedulingCoordinator',
  SeasonalFreightHandler = 'seasonalFreightHandler',
  SecurityOrGuardShack = 'securityOrGuardShack',
  Selector = 'selectorJob',
  SelectorRoadCrew = 'selectorRoadCrew',
  SeniorLeadman = 'seniorLeadman',
  SiteOpsAdminAsst = 'siteOpsAdminAsst',
  Spotter = 'spotter',
  Switcher = 'switcher',
  SwitcherNonCDL = 'switcherNonCDL',
  TrailerStripper = 'trailerStripper',
  TravelTeamSelector = 'travelTeamSelector',
  WarehouseAssociate = 'warehouseAssociate',

  OpportunityDetailsPageTitle = 'opportunityDetailsPageTitle',
  OpportunitiesListPageTitle = 'OpportunitiesListPageTitle',
  RoadCrewApplicationTitle = 'roadCrewApplicationTitle',
  RoadCrewRegistrationComplete = 'roadCrewRegistrationComplete',

  RegionWest = 'regionWest',
  RegionMidwest = 'midwest',
  RegionSouth = 'regionSouth',
  RegionNorthEast = 'regionNorthEast',

  OpportunityListSortStartDate = 'opportunityListSortStartDate',
  OpportunityListSortDuration = 'opportunityListSortDuration',
  OpportunityListSortNewest = 'opportunityListSortNewest',
  OpportunityListSortLocation = 'opportunityListSortLocation',
  OpportunityNotFound = 'opportunityNotFound',
  OpportunityStartDatePassed = 'opportunityStartDatePassed',
  OpportunityExpireDatePassed = 'opportunityExpireDatePassed',
  OpportunityAlreadyRequested = 'opportunityAlreadyRequested',
  OpportunityAppliedSuccessfully = 'opportunityAppliedSuccessfully',
  OpportunityApplicationCancelled = 'opportunityApplicationCancelled',
  RoadCrewApplicationNotSubmitted = 'roadCrewApplicationNotSubmitted',

  RoadCrewCantApplyOne = 'roadCrewCantApplyOne',
  RoadCrewCantApplyTwo = 'roadCrewCantApplyTwo',
  RoadCrewCantApplyThree = 'roadCrewCantApplyThree',
  RoadCrewCantApplyFour = 'roadCrewCantApplyFour',

  RoadCrewCantRegisterOne = 'roadCrewCantRegisterOne',
  RoadCrewCantRegisterTwo = 'roadCrewCantRegisterTwo',
  RoadCrewCantRegisterThree = 'roadCrewCantRegisterThree'
}
