<span
  [ngClass]="{
    'accordion-rating': true,
    'accordion-rating--disabled': panel.disabled
  }"
>
  <span
    [ngClass]="{
      'accordion-rating__rating-box': true,
      'accordion-rating__rating-box--dark-red':
        appearance === StatsAppearanceEnum.DarkRed,
      'accordion-rating__rating-box--red':
        appearance === StatsAppearanceEnum.Red,
      'accordion-rating__rating-box--yellow':
        appearance === StatsAppearanceEnum.Yellow,
      'accordion-rating__rating-box--blue':
        appearance === StatsAppearanceEnum.Blue,
      'accordion-rating__rating-box--green':
        appearance === StatsAppearanceEnum.Green
    }"
  >
    {{ rating ? rating : '?' }}
  </span>
  <span class="accordion-rating__title">
    <ng-template #defaultTitleTemplate>
      {{ title }}
    </ng-template>
    <ng-container
      *ngTemplateOutlet="titleTemplate ? titleTemplate : defaultTitleTemplate"
    ></ng-container>
  </span>
</span>
