<div class="filter-modal-layout" data-cy="modal" cdkTrapFocus>
  <h1 class="filter-modal-layout__heading" tabindex="0" cdkFocusInitial>
    <ng-content select="[slot='modal-header']"></ng-content>
  </h1>

  <div class="filter-modal-layout__content">
    <ng-content select="[slot='modal-content']"></ng-content>
  </div>
  <div class="filter-modal-layout__action">
    <ng-content select="[slot='modal-action']"></ng-content>
  </div>
</div>
