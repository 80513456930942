/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-header.component.ngfactory";
import * as i2 from "./page-header.component";
import * as i3 from "@angular/common";
import * as i4 from "./page-header-container.component";
import * as i5 from "@ngrx/store";
var styles_PageHeaderContainerComponent = [];
var RenderType_PageHeaderContainerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageHeaderContainerComponent, data: {} });
export { RenderType_PageHeaderContainerComponent as RenderType_PageHeaderContainerComponent };
export function View_PageHeaderContainerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-page-header", [], null, [[null, "isClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isClicked" === en)) {
        var pd_0 = (_co.isClicked.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PageHeaderComponent_0, i1.RenderType_PageHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.PageHeaderComponent, [], { link: [0, "link"], type: [1, "type"], appearance: [2, "appearance"], isHeader: [3, "isHeader"] }, { isClicked: "isClicked" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.backUrl$)); var currVal_1 = _co.type; var currVal_2 = _co.appearance; var currVal_3 = _co.isHeader; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_PageHeaderContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-header-container", [], null, null, null, View_PageHeaderContainerComponent_0, RenderType_PageHeaderContainerComponent)), i0.ɵdid(1, 114688, null, 0, i4.PageHeaderContainerComponent, [i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageHeaderContainerComponentNgFactory = i0.ɵccf("app-page-header-container", i4.PageHeaderContainerComponent, View_PageHeaderContainerComponent_Host_0, { type: "type", appearance: "appearance", isHeader: "isHeader" }, { isClicked: "isClicked" }, []);
export { PageHeaderContainerComponentNgFactory as PageHeaderContainerComponentNgFactory };
