<app-page-header-container></app-page-header-container>

<main class="main-container" *ngIf="tempPhoto$ | async as tempPhoto">
  <app-page-title
    data-cy="pageHeaderTitle"
    i18n-title="@@photo-preview_lookingSharp"
    title="Looking Sharp!"
    [appearance]="PageTitleAppearanceEnum.Transparent"
  ></app-page-title>
  <div class="photo-preview">
    <app-associate-photo
      [@slideInPhoto]="triggerAnimation"
      class="photo-preview__photo"
      [photo]="tempPhoto"
      width="136"
    ></app-associate-photo>

    <app-card-layout [@slideInDetails]="triggerAnimation">
      <app-card-layout-item>
        <app-page-section-header
          i18n-title="@@photo-preview_profilePhotoGuidelines"
          title="Profile Image Guidelines"
        ></app-page-section-header>
        <p i18n="@@photo-preview_photoGuidelines">
          Please check to make sure your photo follow Capstone Guidelines:
        </p>
        <ul>
          <li i18n="@@photo-preview_firstItem">
            Make sure your photo includes your face.
          </li>
          <li i18n="@@photo-preview_secondItem">
            Make sure your wearing appropriate clothing for  your work photo.
          </li>
          <li i18n="@@photo-preview_thirdItem">
            Know that your boss, team, and site partner can see  your photo.
          </li>
        </ul>
        <p class="photo-preview__warning">
          <app-svg-icon
            [name]="SvgIconNameEnum.Warning"
            class="photo-preview__warning-icon"
          ></app-svg-icon>
          <span i18n="@@photo-preview_warning">
            Any inappropriate photos will be removed and reported directly by
            your manager.
          </span>
        </p>
      </app-card-layout-item>

      <app-card-layout-item>
        <div class="photo-preview__actions">
          <app-button-link
            i18n-label="@@photo-preview_retakePhoto"
            label="Retake Photo"
            [appearance]="ButtonAppearanceEnum.Secondary"
            (buttonClick)="retake()"
          ></app-button-link>
          <app-button-link
            i18n-label="@@photo-preview_saveMyPhoto"
            label="Save My Photo"
            (buttonClick)="save()"
          ></app-button-link>
        </div>
      </app-card-layout-item>
    </app-card-layout>
  </div>
</main>
