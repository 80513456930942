<main class="applications">
  <div *ngIf="applicationUpdates && applicationUpdates.length > 0">
    <app-menu-header
      [bottomRoundedBorder]="false"
      i18n="@@applications_recentUpdates"
    >
      {{ applicationUpdates.length }} Recent Updates
    </app-menu-header>
    <app-menu [topRoundedBorder]="false" [bottomRoundedBorder]="false">
      <ng-container *ngFor="let application of applicationUpdates">
        <div *ngIf="!application?.postingBySkillset">
          <ng-template #applicationTitle>
            <span
              *ngIf="application?.duration"
              i18n="@@opportunities-list_weeksInTitle"
            >
              {{ application?.duration }}+ {application?.duration, plural, =1
              {Week In} other {Weeks In}}
            </span>
            <span style="text-transform:capitalize">
              {{
                StringHelpers.joinStrings(
                  application.location?.city,
                  application.location?.stateName
                )
              }}
            </span>
          </ng-template>

          <ng-template #applicationSubtitle i18n="@@applications_itemSubtitle">
            Status: {{ application.status }}
          </ng-template>

          <ng-template #new i18n="@@applications_new">
            New
          </ng-template>

          <app-menu-item
            [titleTemplate]="applicationTitle"
            [subTitleTemplate]="applicationSubtitle"
            [appearance]="application.menuColorAppearance"
            [chipTitleTemplate]="application.hasUpdates ? new : null"
            [chipAppearance]="ChipAppearanceEnum.Red"
            (buttonClick)="openDetails(application)"
          ></app-menu-item>
        </div>
        <div *ngIf="application?.postingBySkillset">
          <ng-template #applicationTitle>
            <span
              style="text-transform:capitalize"
              i18n="@@applications_title_National"
            >
              National opportunity for
            </span>
            <span style="text-transform:capitalize">
              {{ application.skillsetName }}
            </span>
            <span
              *ngIf="application?.duration && application?.duration !== '0'"
              i18n="@@applications_title_Weeks_In"
            >
              {{ application?.duration }}+ {application?.duration, plural, =1
              {Week} other {Weeks}}
            </span>
            <span
              *ngIf="application?.duration && application?.duration === '0'"
              i18n="@@applications_title_Weeks_In_Alt"
            >
              Less Than a Week
            </span>
          </ng-template>

          <ng-template #applicationSubtitle i18n="@@applications_itemSubtitle">
            Status: {{ application.status }}
          </ng-template>

          <ng-template #new i18n="@@applications_new">
            New
          </ng-template>

          <app-menu-item
            [titleTemplate]="applicationTitle"
            [subTitleTemplate]="applicationSubtitle"
            [appearance]="application.menuColorAppearance"
            [chipTitleTemplate]="application.hasUpdates ? new : null"
            [chipAppearance]="ChipAppearanceEnum.Red"
            (buttonClick)="openDetails(application)"
            [iconName]="
              !application?.postingBySkillset
                ? SvgIconNameEnum.Location
                : SvgIconNameEnum.Airplane
            "
            [iconColorPrimary]="
              !application?.postingBySkillset
                ? SvgIconColorEnum.MountainMeadow
                : SvgIconColorEnum.Cinnabar
            "
          ></app-menu-item>
        </div>
      </ng-container>
    </app-menu>
  </div>
  <div *ngIf="pendingUpdates && pendingUpdates.length > 0">
    <app-menu-header
      [topRoundedBorder]="false"
      [bottomRoundedBorder]="false"
      i18n="@@applications_pendingApplication"
    >
      {{ pendingUpdates.length }} Pending Application
    </app-menu-header>
    <app-menu [topRoundedBorder]="false">
      <ng-container *ngFor="let application of pendingUpdates">
        <div *ngIf="!application?.postingBySkillset">
          <ng-template #applicationTitle>
            <span
              *ngIf="application?.duration"
              i18n="@@opportunities-list_weeksInTitle"
            >
              {{ application?.duration }}+ {application?.duration, plural, =1
              {Week In} other {Weeks In}}
            </span>
            <span style="text-transform:capitalize">
              {{
                StringHelpers.joinStrings(
                  application.location?.city,
                  application.location?.stateName
                )
              }}
            </span>
          </ng-template>

          <ng-template #applicationSubtitle i18n="@@applications_itemSubtitle">
            Status: {{ application.status }}
          </ng-template>

          <ng-template #new i18n="@@applications_new">
            New
          </ng-template>

          <app-menu-item
            [titleTemplate]="applicationTitle"
            [subTitleTemplate]="applicationSubtitle"
            [appearance]="application.menuColorAppearance"
            [chipTitleTemplate]="application.hasUpdates ? new : null"
            [chipAppearance]="ChipAppearanceEnum.Red"
            (buttonClick)="openDetails(application)"
            [iconName]="
              !application?.postingBySkillset
                ? SvgIconNameEnum.Location
                : SvgIconNameEnum.Airplane
            "
            [iconColorPrimary]="
              !application?.postingBySkillset
                ? SvgIconColorEnum.MountainMeadow
                : SvgIconColorEnum.Cinnabar
            "
          ></app-menu-item>
        </div>
        <div *ngIf="application?.postingBySkillset">
          <ng-template #roadCrewItemTitle>
            <span
              style="text-transform:capitalize"
              i18n="@@applications_title_National"
            >
              National opportunity for
            </span>
            <span style="text-transform:capitalize">
              {{ application.skillsetName }}
            </span>
            <span
              *ngIf="application?.duration && application?.duration !== '0'"
              i18n="@@applications_title_Weeks_In"
            >
              {{ application?.duration }}+ {application?.duration, plural, =1
              {Week} other {Weeks}}
            </span>
            <span
              *ngIf="application?.duration && application?.duration === '0'"
              i18n="@@applications_title_Weeks_In_Alt"
            >
              Less Than a Week
            </span>
          </ng-template>

          <ng-template #roadCrewItemSubtitle i18n="@@applications_itemSubtitle">
            Status: {{ application.status }}
          </ng-template>

          <ng-template #new i18n="@@applications_new">
            New
          </ng-template>

          <app-menu-item
            [titleTemplate]="roadCrewItemTitle"
            [subTitleTemplate]="roadCrewItemSubtitle"
            [appearance]="application.menuColorAppearance"
            [chipTitleTemplate]="application.hasUpdates ? new : null"
            [chipAppearance]="ChipAppearanceEnum.Red"
            (buttonClick)="openDetails(application)"
            [iconName]="
              !application?.postingBySkillset
                ? SvgIconNameEnum.Location
                : SvgIconNameEnum.Airplane
            "
            [iconColorPrimary]="
              !application?.postingBySkillset
                ? SvgIconColorEnum.MountainMeadow
                : SvgIconColorEnum.Cinnabar
            "
          ></app-menu-item>
        </div>
      </ng-container>
    </app-menu>
  </div>
  <div
    *ngIf="
      applicationUpdates === null ||
      (applicationUpdates.length === 0 && pendingUpdates === null) ||
      pendingUpdates.length === 0
    "
  >
    <ng-template #emptyStateMessage>
      <p i18n="@@application_noApplications">
        You don't have any active or pending applications right now.
      </p>
      <p i18n="@@application_viewOpenings">
        View Openings to apply to opportunities!
      </p>
    </ng-template>
    <app-empty-state
      *ngIf="
        (applicationUpdates === null || applicationUpdates.length === 0) &&
        (pendingUpdates === null || pendingUpdates.length === 0)
      "
      [emptyStateTemplate]="emptyStateMessage"
      [emptyStateIcon]="SvgIconNameEnum.EmptyStateApplications"
    ></app-empty-state>
  </div>
</main>
