import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LayoutActions, UserActions } from '../actions';
import { Router } from '@angular/router';
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { ApplicationInsightsService } from '../../shared/services/application-insights.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import {
  AppState,
  getIsUserLoggedIn,
  getAuthToken,
  getUserEmailPrefix,
  getUserId,
  isUserAgreeWithTerms,
  selectUserSettings,
  getIsUserProfileLoading
} from '../../reducers';
import { Store } from '@ngrx/store';
import { TranslationService } from '../../shared/services/translation.service';
import { AlertService } from '../../shared/services/alert.service';
import { TranslationMessages } from '../../shared/enums/TranslationMessages';
import { PhoneReminderComponent } from '../../profile/containers/settings-container/phone-reminder/phone-reminder.component';
import { UserInfoDetails } from '../dataEntities/user/userInfoDetails';
import { UserService } from '../../shared/services/user.service';
import { AssociatesService } from '../../shared/services/associates.service';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../shared/error-handlers/catch-app-error';
import { AuthorizationService } from '../../shared/services/authorization.service';
import { EMPTY, of } from 'rxjs';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>,
    private appInsights: ApplicationInsightsService,
    private authentication: AuthenticationService,
    private userService: UserService,
    private associatesService: AssociatesService,
    private translationService: TranslationService,
    private alertService: AlertService,
    private authorizationService: AuthorizationService,
    @Inject(APP_ERROR_MESSAGES) private errorMessages
  ) {}

  userLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.userLogin),
        withLatestFrom(this.store.select(getUserEmailPrefix)),
        tap(([action, userId]) => {
          this.appInsights.setUserId(userId);
          return this.router.navigate(['disclaimer']);
        })
      ),
    { dispatch: false }
  );

  loginSuccess$ = createEffect(() =>
    this.authentication.loginSuccess$().pipe(
      map(payload => {
        const user = this.authentication.getUser();
        user.token = payload.token;
        const decodedToken = this.authentication.decodeToken(payload.token);
        user.roles = decodedToken.roles || [];
        return UserActions.userLogin({ user });
      })
    )
  );

  loginFailure$ = createEffect(
    () =>
      this.authentication.loginFailure$().pipe(
        tap(payload => {
          console.log('login failure ' + JSON.stringify(payload));
        })
      ),
    {
      dispatch: false
    }
  );

  acquireTokenSuccess$ = createEffect(() => {
    return this.authentication.acquireTokenSuccess$().pipe(
      withLatestFrom(this.store.select(getAuthToken)),
      switchMap(([action, token]) => {
        if (action.payload && action.payload.token !== token) {
          return of(UserActions.setAuthToken({ token: action.payload.token }));
        }
        return EMPTY;
      })
    );
  });

  userLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.userLogout),
        tap(action => {
          return this.authentication.logout(action.isAutoLogout);
        })
      ),
    { dispatch: false }
  );

  agreeWithTerms$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.agreeWithTerms),
        tap(() => {
          return this.router.navigate([''], { replaceUrl: true });
        })
      ),
    { dispatch: false }
  );

  appInsightsSetUserId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.appOnInit),
        switchMap(() => this.store.select(getUserEmailPrefix)),
        filter(userId => !!userId),
        tap(userId => {
          return this.appInsights.setUserId(userId);
        })
      ),
    { dispatch: false }
  );

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.showError),
        tap(action => {
          return this.appInsights.logApplicationError(action.error);
        })
      ),
    { dispatch: false }
  );

  loadUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.appOnInit, UserActions.userLogin),
      withLatestFrom(
        this.store.select(getUserId),
        this.store.select(getIsUserLoggedIn)
      ),
      filter(([action, userId, isUserLoggedIn]) => isUserLoggedIn === true),
      switchMap(([action, userId, isLoggedIn]) => {
        return this.userService.getUserInfo(userId).pipe(
          switchMap((userInfo: UserInfoDetails) => {
            return of(UserActions.userInfoLoaded({ userInfo }));
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  showMissingPhoneReminder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.appOnInit, UserActions.agreeWithTerms),
        withLatestFrom(
          this.store.select(isUserAgreeWithTerms),
          this.store.select(getIsUserProfileLoading),
          this.store.select(getUserId),
          this.store.select(selectUserSettings),
          this.authorizationService.isAssociate$
        ),
        filter(
          ([action, isAgree, isInfoLoading]) =>
            isAgree === true && isInfoLoading === false
        ),
        map(
          ([
            action,
            isAgree,
            isInfoLoading,
            userId,
            userSettings,
            isAssociate
          ]) => {
            if (
              !userSettings.phoneNumber &&
              isAssociate &&
              this.associatesService.shouldShowMissingPhoneReminder(userId)
            ) {
              this.alertService.open(PhoneReminderComponent);
            }
          }
        )
      ),
    { dispatch: false }
  );

  saveUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserSettings),
      tap(() =>
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }))
      ),
      withLatestFrom(this.store.select(getUserId)),
      switchMap(([action, userId]) => {
        return this.userService
          .saveUserSettings(userId, action.userSettings)
          .pipe(
            switchMap(response => {
              return of(
                UserActions.userSettingsSaved({
                  userSettings: action.userSettings
                }),
                LayoutActions.showSuccessMessage({
                  title: this.translationService.translate(
                    TranslationMessages.SettingsSaved
                  )
                })
              );
            }),
            finalize(() =>
              this.store.dispatch(
                LayoutActions.toggleAppLoading({ loading: false })
              )
            )
          );
      })
    )
  );

  userSettingsSaved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.userSettingsSaved, UserActions.userInfoLoaded),
        tap(action => {
          let actionLanguage;
          const currentLang = this.translationService.getLanguage();
          if (action.type === '[User] User Info Loaded') {
            actionLanguage = action.userInfo.preference.language;
            if (currentLang !== actionLanguage) {
              this.redirectToNewLocale(actionLanguage, actionLanguage);
            }
          } else {
            actionLanguage = action.userSettings.language;
            if (currentLang !== actionLanguage) {
              this.redirectToNewLocale(
                actionLanguage,
                actionLanguage + '/settings?languageChange=true'
              );
            }
          }
        })
      ),
    { dispatch: false }
  );

  private redirectToNewLocale(lang, url) {
    this.translationService.setLanguage(lang);
    window.location.assign('/' + url);
  }
}
