import { createAction, props } from '@ngrx/store';
import { ShiftDetails } from '../../../../core/dataEntities/shifts/shiftDetails';
import { SubDepartmentsResponse } from '../../../../core/responses/managers/subDepartmentsResponse';
import { ShiftListItem } from '../../../../core/dataEntities/shifts/shiftListItem';

export const createShift = createAction(
  '[Shifts Component] Create Shift',
  props<{ shift: ShiftDetails }>()
);

export const shiftCreated = createAction('[Shifts Component] Shift Created');

export const loadShift = createAction(
  '[Shifts Component] Load Shift',
  props<{ id: string }>()
);

export const loadShiftAndSites = createAction(
  '[Shifts Component] Load Shift & Sites',
  props<{ shiftId: string }>()
);

export const shiftLoaded = createAction(
  '[Shifts Component] Get Shift',
  props<{ shift: ShiftDetails }>()
);

export const loadSites = createAction('[Shifts Component] Get List Of Sites');

export const sitesListLoaded = createAction(
  '[Shifts Component] SitesListLoaded',
  props<{ response: SubDepartmentsResponse }>()
);

export const previewShift = createAction(
  '[Shifts Component] Preview Shift',
  props<{ shift: ShiftDetails }>()
);

export const updateShift = createAction(
  '[Shifts Component] Update Shift',
  props<{ shift: ShiftDetails }>()
);

export const shiftUpdated = createAction(
  '[Shifts Component] Shift Updated',
  props<{ shiftId: string }>()
);

export const getShiftCount = createAction(
  '[Shifts Component] Get Shift Count',
  props<{ managerId: string }>()
);

export const shiftCountLoaded = createAction(
  '[Shifts Component] ShiftCountLoaded',
  props<{ response: number }>()
);

export const errorOccuredOnShiftLoading = createAction(
  '[Shifts Component] Error on Shift Loading'
);

export const removeShift = createAction(
  '[Shifts Component] Remove Shift Post',
  props<{ shiftId: string }>()
);

export const shiftRemoved = createAction(
  '[Shifts Component] Shift Post Removed',
  props<{ shiftId: string }>()
);

export const loadManagerShifts = createAction(
  '[Shifts Component] Load Manager Shifts'
);

export const managerShiftsLoaded = createAction(
  '[Shifts Component] Manager Shifts Loaded',
  props<{ shifts: ShiftListItem[] }>()
);

export const clearShift = createAction(
  '[Shifts Component] Clear Shift Preview'
);
