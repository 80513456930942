/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./open-positions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/pipes/translate.pipe";
import * as i4 from "../../../../../shared/services/translation.service";
import * as i5 from "../../../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i6 from "../../../../../shared/components/svg-icon/svg-icon.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../pipes/has-certificate.pipe";
import * as i9 from "./open-positions.component";
var styles_OpenPositionsComponent = [i0.styles];
var RenderType_OpenPositionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OpenPositionsComponent, data: {} });
export { RenderType_OpenPositionsComponent as RenderType_OpenPositionsComponent };
function View_OpenPositionsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "open-positions__certified"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" (certified) "]))], null, null); }
function View_OpenPositionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵppd(3, 2), i1.ɵpod(4, { "open-positions__certificates-item": 0, "open-positions__certificates-item--green": 1 }), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(0, i3.TranslatePipe, [i4.TranslationService]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_OpenPositionsComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵppd(9, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, true, i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit, _co.userCertificates))); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit, _co.userCertificates)); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit)); _ck(_v, 5, 0, currVal_1); }); }
function View_OpenPositionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "open-positions__certificates"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenPositionsComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.certificatesNeeded; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OpenPositionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "open-positions__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "open-positions__position"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-svg-icon", [["class", "open-positions__icon"]], null, null, null, i5.View_SvgIconComponent_0, i5.RenderType_SvgIconComponent)), i1.ɵdid(3, 638976, null, 0, i6.SvgIconComponent, [i7.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenPositionsComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.Person; _ck(_v, 3, 0, currVal_0); var currVal_3 = (_v.context.$implicit.certificatesNeeded.length > 0); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.associatesNeeded; var currVal_2 = _v.context.$implicit.position; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_OpenPositionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.HasCertificatePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "open-positions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenPositionsComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openPositions; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_OpenPositionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-open-positions", [], null, null, null, View_OpenPositionsComponent_0, RenderType_OpenPositionsComponent)), i1.ɵdid(1, 114688, null, 0, i9.OpenPositionsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OpenPositionsComponentNgFactory = i1.ɵccf("app-open-positions", i9.OpenPositionsComponent, View_OpenPositionsComponent_Host_0, { openPositions: "openPositions", userCertificates: "userCertificates" }, {}, []);
export { OpenPositionsComponentNgFactory as OpenPositionsComponentNgFactory };
