import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { PwaNotificationContainerComponent } from './containers/pwa-notification-container/pwa-notification-container.component';
import { DisclaimerContainerComponent } from './containers/disclaimer-container/disclaimer-container.component';
import { LandingGuard } from '../core/guards/landing/landing.guard';
import { LandingPageContainerComponent } from './containers/landing-page-container/landing-page-container.component';
import { CovidContainerComponent } from './containers/covid-container/covid-container.component';
import { Module } from '../shared/enums/Module';

const routes: Routes = [
  {
    path: '',
    component: LandingPageContainerComponent,
    canActivate: [LandingGuard],
    data: {
      hideNavigationBar: true,
      siteMapKey: SiteMapItemKeys.LandingPage
    }
  },
  {
    path: 'add-pwa',
    canActivate: [AuthenticationGuard],
    component: PwaNotificationContainerComponent,
    data: {
      hideNavigationBar: true,
      siteMapKey: SiteMapItemKeys.AddPwa,
      pageTitle: TranslationMessages.AddPwaTitle
    }
  },
  {
    path: 'disclaimer',
    component: DisclaimerContainerComponent,
    data: {
      hideNavigationBar: true,
      siteMapKey: SiteMapItemKeys.Disclaimer,
      pageTitle: TranslationMessages.DisclaimerTitle
    }
  },
  {
    path: 'covid',
    component: CovidContainerComponent,
    data: {
      showMenuBar: true,
      siteMapKey: SiteMapItemKeys.Covid,
      pageTitle: TranslationMessages.CovidTitle,
      module: Module.Covid
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticContentRoutingModule {}
