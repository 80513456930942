import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../shared/components/shared-components/shared-components.module';
import { DynamicContentComponent } from './dynamic-content.component';
import { PageContainerComponent } from './containers/page-container/page-container.component';
import { DynamicContentRoutingModule } from './dynamic-content-routing.module';
import { ExpandablePanelComponent } from './components/expandable-panel/expandable-panel.component';
import { EffectsModule } from '@ngrx/effects';
import { DynamicContentEffects } from './effects/dynamic-content.effects';
import { StoreModule } from '@ngrx/store';
import * as formDynamicContent from '../dynamic-content/reducers/dynamic-content.reducer';

@NgModule({
  declarations: [
    DynamicContentComponent,
    PageContainerComponent,
    ExpandablePanelComponent
  ],
  imports: [
    SharedComponentsModule,
    DynamicContentRoutingModule,
    EffectsModule.forFeature([DynamicContentEffects]),
    StoreModule.forFeature(
      formDynamicContent.dynamicContentFeatureKey,
      formDynamicContent.reducer
    )
  ]
})
export class DynamicContentModule {}
