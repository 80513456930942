<app-filter-modal-layout>
  <ng-container slot="modal-header" i18n="@@duration-filter_header">
    Filter By Duration
  </ng-container>
  <ng-container slot="modal-content">
    <div *ngIf="data?.durations && data?.durations.length">
      <button
        *ngIf="
          selectedDurations?.length &&
          selectedDurations?.length === availableFilters?.length
        "
        class="duration-filter__select-button"
        (click)="deselectAll()"
        i18n="@@duration-filter_deselectAll"
      >
        Deselect All
      </button>
      <button
        *ngIf="
          !selectedDurations?.length ||
          selectedDurations.length < availableFilters?.length
        "
        class="duration-filter__select-button"
        (click)="selectAll()"
        i18n="@@duration-filter_selectAll"
      >
        Select All
      </button>
      <div *ngFor="let duration of data?.durations">
        <app-checkbox-input
          [disabled]="duration?.count === 0"
          [ngModel]="arrayHasValue(selectedDurations, duration?.duration)"
          (ngModelChange)="selectDuration(duration?.duration)"
        >
          <span
            i18n="@@duration-filter_about"
            class="duration-filter__about"
            *ngIf="duration?.duration !== '0'"
          >
            About
          </span>
          <span
            i18n="@@duration-filter_weeks"
            *ngIf="duration?.duration !== '0'"
          >
            {{ duration?.duration }} {duration?.duration, plural, =1 {Week}
            other {Weeks}} ({{ duration?.count }})
          </span>
          <span
            i18n="@@duration-filter_weeks_alt"
            *ngIf="duration?.duration === '0'"
          >
            Less Than a Week
          </span>
          <span *ngIf="duration?.duration === '0'">
            &nbsp;({{ duration?.count }})
          </span>
        </app-checkbox-input>
      </div>
    </div>
    <ng-template #emptyStateMessage i18n="@@duration-filter_noDataFound">
      There are no filter options.
    </ng-template>

    <app-empty-state
      *ngIf="data?.durations === null || data?.durations.length === 0"
      [emptyStateTemplate]="emptyStateMessage"
      [emptyStateIcon]="SvgIconNameEnum.EmptyStateApplications"
    ></app-empty-state>
  </ng-container>
  <ng-container slot="modal-action">
    <app-button-link
      (buttonClick)="resetFilter()"
      label="Reset Filter"
      i18n-label="@@duration-filter_resetFilter"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>

    <app-button-link
      (buttonClick)="filter()"
      label="Done"
      i18n-label="@@duration-filter_done"
      [appearance]="ButtonAppearanceEnum.Link"
    ></app-button-link>
  </ng-container>
</app-filter-modal-layout>
