<app-modal-layout (closeModal)="closeModal()">
  <ng-template
    #secondaryText
    i18n="@@shift-delete-modal_closeShiftModalSecondaryText"
  >
    Close shift
  </ng-template>

  <app-modal-header
    slot="modal-header"
    [secondaryTextTemplate]="secondaryText"
  ></app-modal-header>
  <div slot="modal-content">
    <app-modal-content-row [sideSpacing]="true">
      <p i18n="@@shift-delete-modal_modalText">
        Delete this shift request will remove the post from the Newsfeed. Any
        associates who haven’t been notified yet will not see this shift
        request.
      </p>
      <p i18n="@@shift-delete-modal_modalDisclaimer">
        This action can’t be undone.
      </p>
    </app-modal-content-row>
    <div class="shift-delete__actions">
      <ng-template #cancelButton i18n="@@shift-delete-modal_cancelButton">
        Nevermind
      </ng-template>

      <ng-template #deleteButton i18n="@@shift-delete-modal_deleteButton">
        Close Shift Request
      </ng-template>

      <app-button-link
        (buttonClick)="closeModal()"
        [labelTemplate]="cancelButton"
        [appearance]="ButtonAppearanceEnum.Link"
      ></app-button-link>

      <app-button-link
        (buttonClick)="deleteShift()"
        [labelTemplate]="deleteButton"
        [appearance]="ButtonAppearanceEnum.Secondary"
        [color]="ButtonColorEnum.Red"
      ></app-button-link>
    </div>
  </div>
</app-modal-layout>
