import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import * as fromRoot from '../../../reducers';

@Component({
  selector: 'app-profile-header-container',
  templateUrl: './profile-header-container.component.html',
  styleUrls: ['./profile-header-container.component.scss']
})
export class ProfileHeaderContainerComponent implements OnInit {
  isUserLoggedIn$: Observable<boolean>;
  userName$: Observable<string>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.isUserLoggedIn$ = this.store.pipe(select(fromRoot.getIsUserLoggedIn));
    this.userName$ = this.store.pipe(select(fromRoot.getUserName));
  }
}
