import { Pipe, PipeTransform } from '@angular/core';
import { TrainingStatus } from '../../../../enums/TrainingStatus';

@Pipe({
  name: 'traineeWorkWeek'
})
export class TraineeWorkWeekPipe implements PipeTransform {

  transform(value: TrainingStatus, ...args: any[]): any {
    if (!value) {
      return 0;
    }
    switch (value) {
      case TrainingStatus.WeekOneTraining:
        return 1;
      case TrainingStatus.WeekTwoTraining:
        return 2;
      case TrainingStatus.WeekThreeTraining:
        return 3;
      case TrainingStatus.WeekFourTraining:
        return 4;
      case TrainingStatus.WeekFiveTraining:
        return 5;
      case TrainingStatus.WeekSixTraining:
        return 6;
      case TrainingStatus.FinishedTraining:
      case TrainingStatus.NoRecordFound:
      default:
        return 0;
    }
  }
}
