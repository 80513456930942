<p class="read-only-details">{{ formGroup.get('email').value }}</p>
<p class="read-only-details">
  <span class="read-only-details__item">
    {{ formGroup.get('phoneNumber').value }}
  </span>
  <span class="read-only-details__item">
    {{ formGroup.get('homePhoneNumber').value }}
  </span>
</p>
<p class="read-only-details">
  <span
    class="read-only-details__item"
    i18n="@@contact-information-readonly_emergencyContact"
  >
    EC: {{ formGroup.get('emergencyContactName').value }}
  </span>
  <span class="read-only-details__item">
    {{ formGroup.get('emergencyContactPhoneNumber').value }}
  </span>
</p>
