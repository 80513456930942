/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./associate-page-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../associate-qr-code/associate-qr-code.component.ngfactory";
import * as i3 from "../associate-qr-code/associate-qr-code.component";
import * as i4 from "../../../modules/modal/dialog/dialog.service";
import * as i5 from "../../chip/chip.component.ngfactory";
import * as i6 from "../../chip/chip.component";
import * as i7 from "../../associate-photo/associate-photo.component.ngfactory";
import * as i8 from "../../associate-photo/associate-photo.component";
import * as i9 from "@angular/platform-browser";
import * as i10 from "../../../modules/modal/bottom-sheet/bottom-sheet.service";
import * as i11 from "@ngrx/store";
import * as i12 from "@angular/common";
import * as i13 from "./associate-page-header.component";
var styles_AssociatePageHeaderComponent = [i0.styles];
var RenderType_AssociatePageHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssociatePageHeaderComponent, data: {} });
export { RenderType_AssociatePageHeaderComponent as RenderType_AssociatePageHeaderComponent };
function View_AssociatePageHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-associate-qr-code", [["class", "associate-page-header__qr"]], null, null, null, i2.View_AssociateQrCodeComponent_0, i2.RenderType_AssociateQrCodeComponent)), i1.ɵdid(1, 114688, null, 0, i3.AssociateQrCodeComponent, [i4.DialogService], { apexId: [0, "apexId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.apexId; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AssociatePageHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-chip", [], null, null, null, i5.View_ChipComponent_0, i5.RenderType_ChipComponent)), i1.ɵdid(1, 114688, null, 0, i6.ChipComponent, [], { appearance: [0, "appearance"] }, null), (_l()(), i1.ɵted(-1, 0, [" Trainee "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ChipAppearanceEnum.Green; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AssociatePageHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "section", [["class", "associate-page-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-associate-photo", [["width", "96"]], null, [[null, "deletePhotoEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deletePhotoEvent" === en)) {
        var pd_0 = (_co.deletePhoto($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_AssociatePhotoComponent_0, i7.RenderType_AssociatePhotoComponent)), i1.ɵdid(2, 638976, null, 0, i8.AssociatePhotoComponent, [i9.DomSanitizer, i10.BottomSheetService, i11.Store], { photo: [0, "photo"], canAddPhoto: [1, "canAddPhoto"], canDeletePhoto: [2, "canDeletePhoto"], width: [3, "width"] }, { deletePhotoEvent: "deletePhotoEvent" }), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "associate-page-header__side"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "associate-page-header__user-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "associate-page-header__info"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssociatePageHeaderComponent_1)), i1.ɵdid(8, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "associate-page-header__user-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["data-cy", "apexId"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["ID# ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssociatePageHeaderComponent_2)), i1.ɵdid(13, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.photoSrc; var currVal_1 = _co.isAssociateView; var currVal_2 = true; var currVal_3 = "96"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.isAssociateView; _ck(_v, 8, 0, currVal_5); var currVal_7 = _co.isTrainee; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.userName; _ck(_v, 5, 0, currVal_4); var currVal_6 = _co.apexId; _ck(_v, 11, 0, currVal_6); }); }
export function View_AssociatePageHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-associate-page-header", [], null, null, null, View_AssociatePageHeaderComponent_0, RenderType_AssociatePageHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i13.AssociatePageHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssociatePageHeaderComponentNgFactory = i1.ɵccf("app-associate-page-header", i13.AssociatePageHeaderComponent, View_AssociatePageHeaderComponent_Host_0, { userName: "userName", apexId: "apexId", isTrainee: "isTrainee", photoSrc: "photoSrc", isAssociateView: "isAssociateView" }, { deletePhotoEvent: "deletePhotoEvent" }, []);
export { AssociatePageHeaderComponentNgFactory as AssociatePageHeaderComponentNgFactory };
