import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageHeaderAppearance, PageHeaderType } from '../../enums/PageHeader';
import { SvgIconName } from '../../enums/SvgIcon';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() link: string | any[];
  @Input() queryParams: any;
  @Input() type: PageHeaderType = PageHeaderType.Back;
  @Input() appearance: PageHeaderAppearance = PageHeaderAppearance.Blue;
  @Input() isHeader = true;
  @Output() isClicked = new EventEmitter();

  SvgIconNameEnum = SvgIconName;
  PageHeaderTypeEnum = PageHeaderType;
  PageHeaderAppearanceEnum = PageHeaderAppearance;
}
