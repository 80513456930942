import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateFilterComponent } from '../date-filter/date-filter.component';
import { BottomSheetService } from '../../../../../shared/modules/modal/bottom-sheet/bottom-sheet.service';
import { FilterType } from '../../enums/FilterType';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-opportunities-list-filter',
  templateUrl: './opportunities-list-filter.component.html',
  styleUrls: ['./opportunities-list-filter.component.scss']
})
export class OpportunitiesListFilterComponent {
  @Input() filter: any;
  @Input() totalCount: number;
  @Input() userApexId: number;
  @Output() filterChange = new EventEmitter();
  @Output() openFilter = new EventEmitter();
  @Output() clearFilter = new EventEmitter();

  public SvgIconNameEnum = SvgIconName;

  constructor(private bottomSheetService: BottomSheetService) {}

  filterByDate() {
    const config = {
      data: {
        filter: this.filter,
        startDateFrom: this.filter.startDateFrom,
        totalCount: this.totalCount,
        userId: this.userApexId
      },
      fullHeight: true
    };

    this.bottomSheetService
      .open(DateFilterComponent, config)
      .afterClosed()
      .subscribe(result => {
        if (result && result.success) {
          const filter = {
            ...this.filter,
            startDateFrom: result.data.startDateFrom
          };
          this.filterChange.emit(filter);
        }
      });
  }

  filterByDuration() {
    this.openFilter.emit(FilterType.Duration);
  }

  filterByJobTitle() {
    this.openFilter.emit(FilterType.JobTitle);
  }

  filterByRegion() {
    this.openFilter.emit(FilterType.LocationState);
  }

  clearFilters() {
    this.clearFilter.emit();
  }
}
