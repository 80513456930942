<span
  [ngClass]="{
    'upload-preview': true,
    'upload-preview--blue':
      UploadPreviewAppearanceEnum.Blue === appearance && !image,
    'upload-preview--red':
      UploadPreviewAppearanceEnum.Red === appearance && !image,
    'upload-preview--white':
      UploadPreviewAppearanceEnum.White === appearance && image,
    'is-focused': focused
  }"
>
  <span class="upload-preview__box">
    <ng-container *ngIf="!image">
      <app-svg-icon
        [name]="iconName"
        class="upload-preview__icon"
      ></app-svg-icon>
      <span class="upload-preview__error">{{ error }}</span>
      <span class="upload-preview__text">{{ text }}</span>
      <span class="upload-preview__subtext">{{ subtext }}</span>
    </ng-container>

    <ng-container *ngIf="image">
      <img
        *ngIf="image"
        [src]="image"
        class="upload-preview__image"
        alt="preview"
      />
      <span class="upload-preview__edit">
        <app-svg-icon
          class="upload-preview__edit-icon"
          [name]="SvgIconNameEnum.Pencil"
        ></app-svg-icon>
      </span>
    </ng-container>
  </span>
</span>
