import { ComponentType } from '@angular/cdk/portal';
import {
  Inject,
  Injectable,
  Injector,
  Optional,
  TemplateRef
} from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import { BaseModalService } from '../base-modal/base-modal.service';
import { DIALOG_DEFAULT_OPTIONS, DialogConfig } from '../dialog-config';
import { DialogRef } from '../dialog-ref';
import { BottomSheetComponent } from './bottom-sheet.component';
import { AnalyticsUtilitiesService } from '../../../services/analytics-utilities.service';
import { AnalyticsActions } from '../../../enums/AnalyticsActions';
import { AnalyticsEvents } from '../../../enums/AnalyticsEvents';
import { AnalyticsCategories } from '../../../enums/AnalyticsCategories';

@Injectable()
export class BottomSheetService extends BaseModalService {
  constructor(
    overlay: Overlay,
    injector: Injector,
    private analyticsUtilities: AnalyticsUtilitiesService,
    @Optional() location: Location,
    @Optional() @Inject(DIALOG_DEFAULT_OPTIONS) private config: DialogConfig
  ) {
    super(overlay, injector, location, config);
  }

  open<T, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: DialogConfig
  ): DialogRef<T, R> {
    config = this.applyConfigDefaults(
      config,
      this.config || new DialogConfig()
    );
    config.position = {
      bottom: '0'
    };

    const overlayRef = this.createOverlay(config);
    const dialogContainer = this.attachDialogContainer(
      overlayRef,
      BottomSheetComponent,
      config
    );
    const dialogRef = this.attachDialogContent<T, R>(
      componentOrTemplateRef,
      dialogContainer.instance,
      overlayRef,
      config
    );

    this.analyticsUtilities.customAnalyticsEvent(AnalyticsEvents.BottomSheet, {
      label: dialogRef.componentInstance.constructor.name,
      value: config ? config.data : null,
      action: AnalyticsActions.Open,
      category: AnalyticsCategories.Dialogs
    });

    return dialogRef;
  }
}
