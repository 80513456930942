import { createAction, props } from '@ngrx/store';
import { DynamicPage } from '../../core/dataEntities/dynamicContent/page';

export const loadPage = createAction(
  '[Dynamic Content] Load Page',
  props<{ slug: string }>()
);

export const pageLoaded = createAction(
  '[Dynamic Content] Page Loaded',
  props<{ page: DynamicPage }>()
);

export const toggleDynamicContentIsLoading = createAction(
  '[Dynamic Content] Toggle Dynamic Content is Loading',
  props<{ isLoading: boolean }>()
);
