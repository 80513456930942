import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { createReducer, Action, on } from '@ngrx/store';
import { WagesActions } from '../actions';
import { WeekPaySummary } from '../../core/dataEntities/wages';
import moment from 'moment';
import { DateFormat, IdDateFormat } from '../../shared/constants';
import { LayoutActions, UserActions } from '../../core/actions';
import { DateTimeUtils } from '../../shared/utils/DateTimeUtils';

export const payHistoryFeatureKey = 'payHistory';

export interface WagesState extends EntityState<WeekPaySummary> {
  isPayHistoryLoading: boolean;
}

export const adapter: EntityAdapter<WeekPaySummary> = createEntityAdapter<
  WeekPaySummary
>();
export const initialState: WagesState = adapter.getInitialState({
  isPayHistoryLoading: false
});
export const wagesReducer = createReducer(
  initialState,
  on(WagesActions.loadPayHistory, (state, action) => {
    return {
      ...state,
      isPayHistoryLoading: true
    };
  }),
  on(WagesActions.payHistoryLoaded, (state, action) => {
    const payHistories = action.payHistories.map(ph => {
      const weekEndingDate = moment(ph.weekEndingDate);
      const weekStartDate = DateTimeUtils.getWeekStartDateByEndDate(
        ph.weekEndingDate
      );
      const today = moment();
      const isCurrentWeek =
        today.isSameOrAfter(weekStartDate) &&
        today.isSameOrBefore(weekEndingDate);
      const weeksAgo = today.diff(weekStartDate, 'weeks');
      return {
        ...ph,
        id: weekEndingDate.format(IdDateFormat),
        weekStartDate: weekStartDate.format(DateFormat),
        isCurrentWeek,
        weeksAgo
      } as WeekPaySummary;
    });
    const emptyPayHistoryState = adapter.removeAll(state);
    return adapter.addMany(payHistories, {
      ...emptyPayHistoryState,
      isPayHistoryLoading: false
    });
  }),
  on(LayoutActions.showError, LayoutActions.apiOffline, state => {
    return {
      ...state,
      isPayHistoryLoading: false
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(state: WagesState | undefined, action: Action) {
  return wagesReducer(state, action);
}

const { selectAll } = adapter.getSelectors();
export const selectAllPayHistories = selectAll;

export const selectIsPayHistoryLoading = (state: WagesState) => {
  return state.isPayHistoryLoading;
};
