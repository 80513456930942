/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-item-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./menu-item-image.component";
import * as i4 from "@angular/platform-browser";
var styles_MenuItemImageComponent = [i0.styles];
var RenderType_MenuItemImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuItemImageComponent, data: {} });
export { RenderType_MenuItemImageComponent as RenderType_MenuItemImageComponent };
function View_MenuItemImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.photo; var currVal_1 = i1.ɵinlineInterpolate(1, "", ((_co.firstName + " ") + _co.lastName), ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MenuItemImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.firstChar + _co.secondChar); _ck(_v, 1, 0, currVal_0); }); }
export function View_MenuItemImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [["class", "menu-item-image"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-color": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuItemImageComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuItemImageComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.bgColor); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.imageSrc; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.imageSrc; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_MenuItemImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-item-image", [], null, null, null, View_MenuItemImageComponent_0, RenderType_MenuItemImageComponent)), i1.ɵdid(1, 638976, null, 0, i3.MenuItemImageComponent, [i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuItemImageComponentNgFactory = i1.ɵccf("app-menu-item-image", i3.MenuItemImageComponent, View_MenuItemImageComponent_Host_0, { firstName: "firstName", lastName: "lastName", imageSrc: "imageSrc" }, {}, []);
export { MenuItemImageComponentNgFactory as MenuItemImageComponentNgFactory };
