export enum ButtonAppearance {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Link = 'Link'
}

export enum ButtonSize {
  Default = 'Default',
  Small = 'Small'
}

export enum ButtonType {
  Button = 'button',
  Submit = 'submit'
}

export enum ButtonIconSize {
  Small = 'Small',
  Regular = 'Regular'
}

export enum ButtonColor {
  Blue = 'Blue',
  Green = 'Green',
  Red = 'Red'
}
