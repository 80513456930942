import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { OpportunitiesActions } from '../actions';
import { OpportunityDetails } from '../../../../core/dataEntities/opportunities/opportunityDetails';

export const opportunityDetailsFeatureKey = 'opportunityDetails';

export interface OpportunityDetailsState
  extends EntityState<OpportunityDetails> {
  activeOpportunityId: string;
  isOpportunityLoading: boolean;
}

export const adapter: EntityAdapter<OpportunityDetails> = createEntityAdapter<
  OpportunityDetails
>();

export const initialState: OpportunityDetailsState = adapter.getInitialState({
  activeOpportunityId: null,
  isOpportunityLoading: false
});

export const opportunityDetailsReducer = createReducer(
  initialState,
  on(OpportunitiesActions.opportunityDetailsLoaded, (state, action) => {
    if (action.opportunity) {
      return adapter.upsertOne(action.opportunity, {
        ...state,
        activeOpportunityId: action.opportunity.id
      });
    }
    return {
      ...state,
      activeOpportunityId: null
    };
  }),
  on(OpportunitiesActions.toggleOpportunityLoading, (state, action) => ({
    ...state,
    isOpportunityLoading: action.isLoading
  }))
);

export function reducer(
  state: OpportunityDetailsState | undefined,
  action: Action
) {
  return opportunityDetailsReducer(state, action);
}

const { selectEntities } = adapter.getSelectors();

export const selectIsOpportunityLoading = (state: OpportunityDetailsState) => {
  return state.isOpportunityLoading;
};

export const selectActiveOpportunityId = (state: OpportunityDetailsState) => {
  return state.activeOpportunityId;
};

export const selectOpportunityDetails = createSelector(
  selectActiveOpportunityId,
  selectEntities,
  (id, entities): OpportunityDetails => {
    return entities[id] ? entities[id] : null;
  }
);
