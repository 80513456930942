import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NewsActions } from '../actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  AppState,
  getNewsActivePost,
  getUserId,
  getUserSites
} from '../../reducers';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../shared/error-handlers/catch-app-error';
import { NewsFeedService } from '../services/news-feed.service';
import { PostDetailsResponse } from '../../core/responses/news/postDetailsResponse';
import { ResourcesActions } from '../../resources/actions';

@Injectable()
export class NewsEffects {
  constructor(
    private actions$: Actions,
    private newsFeedService: NewsFeedService,
    private store: Store<AppState>,
    @Inject(APP_ERROR_MESSAGES) private errorMessages
  ) {}

  loadNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.loadNews),
      withLatestFrom(
        this.store.select(getUserId),
        this.store.select(getUserSites)
      ),
      switchMap(([action, userId, userSites]) => {
        let totalUnseenNewsCount$ = of({
          unseenNewsCount: null,
          lastSeenNewsDate: null
        });

        if (action.page === 0) {
          totalUnseenNewsCount$ = this.newsFeedService
            .getNewsFeedLastSeen()
            .pipe(
              switchMap(lastSeenNewsDate => {
                const requests = [this.newsFeedService.postNewsFeedLastSeen()];
                if (lastSeenNewsDate) {
                  requests.push(
                    this.newsFeedService.getTotalNewPostCount(lastSeenNewsDate)
                  );
                }

                return forkJoin(requests).pipe(
                  map(([x1, unseenNewsCount]) => ({
                    unseenNewsCount,
                    lastSeenNewsDate
                  }))
                );
              })
            );
        }

        return forkJoin([
          totalUnseenNewsCount$,
          this.newsFeedService.loadNewsFeed(action.page, action.itemsPerPage)
        ]).pipe(
          switchMap(([{ unseenNewsCount, lastSeenNewsDate }, news]) => {
            const postIds = news.posts.map(post => post.id);
            return this.newsFeedService.getPostsAdditionalData(postIds).pipe(
              switchMap((postsDetails: PostDetailsResponse) => {
                const newsLoadedParams = {
                  news,
                  page: action.page,
                  lastSeenNewsDate: null,
                  additionalData: postsDetails,
                  userId,
                  userSites
                };

                if (unseenNewsCount) {
                  newsLoadedParams.lastSeenNewsDate = lastSeenNewsDate;
                }
                const newsLoaded = NewsActions.newsLoaded(newsLoadedParams);
                if (unseenNewsCount) {
                  return of(
                    newsLoaded,
                    NewsActions.unseenNewsCountLoaded({
                      unseenNewsCount,
                      lastSeenNewsDate
                    })
                  );
                }
                return of(newsLoaded);
              })
            );
          }),
          catchError(err => {
            console.error(err);
            return of(NewsActions.errorOccurredOnNewsLoading());
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  loadPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.loadPost),
      withLatestFrom(this.store.select(getNewsActivePost)),
      switchMap(([action, activePost]) => {
        if (activePost) {
          return of(
            NewsActions.postLoaded({ post: activePost, postId: action.postId })
          );
        }

        return forkJoin(
          this.newsFeedService.loadPost(action.postId),
          this.newsFeedService.getPostsAdditionalData([action.postId])
        ).pipe(
          map(([loadedPost, additionalDetails]) => {
            return NewsActions.postLoaded({
              post: loadedPost,
              postId: action.postId,
              additionalDetails
            });
          }),
          catchError(() => {
            return of(NewsActions.errorOccurredOnPostLoading());
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  likePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.likePost),
      mergeMap(action => {
        return this.newsFeedService
          .likePost(action.postId, action.isLiked)
          .pipe(
            mergeMap(postLiked => {
              return of(
                NewsActions.postLiked(action),
                ResourcesActions.postLiked(action)
              );
            })
          );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );
}
