import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../shared/components/shared-components/shared-components.module';
import { ProfileComponent } from './profile.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileHeaderContainerComponent } from './containers/profile-header-container/profile-header-container.component';
import { SurveyModule } from '../surveys/survey.module';
import { SidebarModule } from 'ng-sidebar';
import { StoreModule } from '@ngrx/store';
import * as formProfile from '../profile/reducers/profile.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProfileEffects } from './effects/profile.effects';
import { ProfileLinkItemComponent } from './components/profile-link-item/profile-link-item.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { SettingsContainerComponent } from './containers/settings-container/settings-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneReminderComponent } from './containers/settings-container/phone-reminder/phone-reminder.component';
import { WeeklyCheckInComponent } from './containers/weekly-check-in/weekly-check-in.component';
import { SkipCheckInComponent } from './components/skip-check-in/skip-check-in.component';
import { AboutCheckInComponent } from './components/about-check-in/about-check-in.component';
import { RatingRadioInputComponent } from './components/rating-radio-input/rating-radio-input.component';
import { AssociateProductionComponent } from './containers/associate-production/associate-production.component';
import { AssociateCheckInHistoryComponent } from './containers/associate-check-in-history/associate-check-in-history.component';
import { AssociateProfileTabsComponent } from './components/associate-profile-tabs/associate-profile-tabs.component';
import { DirectivesModule } from '../core/directives/directives.module';
import { PhotoPreviewComponent } from './containers/photo-preview/photo-preview.component';
import { ChangePhotoComponent } from './components/change-photo/change-photo.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileHeaderContainerComponent,
    ProfileLinkItemComponent,
    QrCodeComponent,
    SettingsContainerComponent,
    PhoneReminderComponent,
    WeeklyCheckInComponent,
    AboutCheckInComponent,
    SkipCheckInComponent,
    RatingRadioInputComponent,
    AssociateCheckInHistoryComponent,
    AssociateProductionComponent,
    AssociateProfileTabsComponent,
    PhotoPreviewComponent,
    ChangePhotoComponent
  ],
  imports: [
    SharedComponentsModule,
    DirectivesModule,
    ProfileRoutingModule,
    SurveyModule,
    SidebarModule.forRoot(),
    StoreModule.forFeature(formProfile.profileFeatureKey, formProfile.reducer),
    EffectsModule.forFeature([ProfileEffects]),
    FormsModule,
    ReactiveFormsModule,
    A11yModule
  ],
  exports: [
    ProfileComponent,
    ProfileHeaderContainerComponent,
    ProfileLinkItemComponent,
    SettingsContainerComponent,
    PhoneReminderComponent,
    RatingRadioInputComponent
  ],
  entryComponents: [
    QrCodeComponent,
    PhoneReminderComponent,
    AboutCheckInComponent,
    SkipCheckInComponent,
    RatingRadioInputComponent,
    ChangePhotoComponent
  ]
})
export class ProfileModule {}
