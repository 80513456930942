import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import { MyTeamActions } from '../actions';
import {
  createServerDataTableAdapter,
  ServerDataTableState
} from '../../shared/data-table';
import { TeamMemberRow } from '../view-models/team-member-record';
import { SortDirections } from '../../core/enums/SortDirections';
import { MyTeamSortOptions } from '../../core/enums/MyTeamSortOptions';
import { UserActions } from '../../core/actions';

export const myTeamFeatureKey = 'myTeam';

export interface MyTeamState {
  teamMembers: ServerDataTableState<TeamMemberRow>;
  isLoading: boolean;
}

export const adapter = createServerDataTableAdapter<TeamMemberRow>({
  infinitePage: true
});
export const initialState: MyTeamState = {
  teamMembers: adapter.getInitialState({
    sort: {
      active: MyTeamSortOptions.Tenure,
      direction: SortDirections.Asc
    },
    page: {
      pageSize: 20
    }
  }),
  isLoading: false
};

export const myTeamReducer = createReducer(
  initialState,
  on(
    MyTeamActions.loadMyTeam,
    MyTeamActions.changeMyTeamPage,
    (state, action) => ({
      ...state,
      isLoading: true
    })
  ),
  on(MyTeamActions.myTeamLoaded, (state, action) => ({
    ...state,
    teamMembers: adapter.itemsLoaded(action, state.teamMembers)
  })),
  on(MyTeamActions.changeMyTeamSort, (state, action) => ({
    ...state,
    teamMembers: adapter.changeSort(action, state.teamMembers)
  })),
  on(MyTeamActions.changeMyTeamPage, (state, action) => ({
    ...state,
    teamMembers: adapter.changePage(action, state.teamMembers)
  })),
  on(MyTeamActions.changeMyTeamFilter, (state, action) => ({
    ...state,
    teamMembers: adapter.changeFilters(action, state.teamMembers)
  })),
  on(MyTeamActions.toggleMyTeamIsLoading, (state, action) => ({
    ...state,
    isLoading: action.isLoading
  })),
  on(UserActions.userLogout, (state, action) => ({
    ...initialState
  })),
  on(MyTeamActions.teamMemberPhotoDeleted, (state, action) => ({
    ...state,
    teamMembers: adapter.updateListItem(state.teamMembers, action.apexId, {
      profilePhoto: null
    })
  }))
);

export function reducer(state: MyTeamState | undefined, action: Action) {
  return myTeamReducer(state, action);
}

const {
  selectDataTablePage,
  selectDataTableSort,
  selectDataTableFilter,
  selectCurrentItems
} = adapter.getSelectors();

const getMyTeamState = createFeatureSelector<MyTeamState>(myTeamFeatureKey);

export const getMyTeamMembersState = createSelector(
  getMyTeamState,
  state => state.teamMembers
);

export const selectAllMyTeamMembers = createSelector(
  getMyTeamMembersState,
  selectCurrentItems
);
export const selectMyTeamSort = createSelector(
  getMyTeamMembersState,
  selectDataTableSort
);
export const selectMyTeamFilter = createSelector(
  getMyTeamMembersState,
  selectDataTableFilter
);
export const selectMyTeamPage = createSelector(
  getMyTeamMembersState,
  selectDataTablePage
);
export const isMyTeamLoading = createSelector(
  getMyTeamState,
  state => state.isLoading
);
export const isEndOfMyTeamList = createSelector(
  selectMyTeamPage,
  selectAllMyTeamMembers,
  (page, allTeamMembers) => {
    return page.totalCount > 0 && page.totalCount <= allTeamMembers.length;
  }
);
