import { getAuthToken, getUserId } from '../../reducers';
import moment from 'moment';
import { first, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/api.gateway.service";
import * as i2 from "@ngrx/store";
export class WeeklyCheckInService {
    constructor(apiGateway, store) {
        this.apiGateway = apiGateway;
        this.store = store;
        this.associateWeeklyCheckInReminderKey = 'associateWeeklyCheckInReminder';
    }
    saveWeeklyCheckIn(userId, userSite, date, checkIn) {
        const requestBody = {
            associateGuid: userId,
            weekEndingDate: date,
            subDept: userSite,
            score: checkIn.score,
            message: checkIn.message
        };
        return this.store.select(getAuthToken).pipe(first(), switchMap(authToken => {
            return this.apiGateway.post(`associates/weeklyCheckIn`, authToken, requestBody);
        }));
    }
    shouldShowWeeklyCheckIn(userId) {
        const reminder = this.getReminderStateFromLocalStorage().find(user => user.userId === userId);
        const now = moment.utc();
        if (reminder == null) {
            return true;
        }
        if (!moment(reminder.lastNotifyDateTime).isSame(now, 'week')) {
            return true;
        }
        if (moment(now).diff(reminder.lastNotifyDateTime, 'hours') < 4) {
            return false;
        }
        if (reminder.count === 2 &&
            moment(now).diff(reminder.lastNotifyDateTime, 'hours') > 24) {
            return true;
        }
        return reminder.count < 3;
    }
    isCheckInSkipped(userId) {
        const reminders = this.getReminderStateFromLocalStorage();
        const lastReminder = reminders.find(user => user.userId === userId);
        if (lastReminder == null) {
            return false;
        }
        return lastReminder.count > 0;
    }
    skipSurvey() {
        this.store
            .select(getUserId)
            .pipe(first())
            .subscribe(userId => {
            const now = moment.utc();
            let reminders = this.getReminderStateFromLocalStorage();
            const reminder = reminders.find(user => user.userId === userId);
            if (reminder == null) {
                reminders.push({
                    userId,
                    lastNotifyDateTime: now.toISOString(),
                    count: 1
                });
                localStorage.setItem(this.associateWeeklyCheckInReminderKey, JSON.stringify(reminders));
            }
            else {
                const isSameWeek = moment(reminder.lastNotifyDateTime).isSame(now, 'week');
                reminders = reminders.map(user => user.userId === userId
                    ? Object.assign({}, user, { lastNotifyDateTime: now.toISOString(), count: isSameWeek ? user.count + 1 : 0 }) : user);
                localStorage.setItem(this.associateWeeklyCheckInReminderKey, JSON.stringify(reminders));
            }
        });
    }
    getReminderStateFromLocalStorage() {
        const reminder = localStorage.getItem(this.associateWeeklyCheckInReminderKey);
        if (reminder == null) {
            return [];
        }
        return JSON.parse(localStorage.getItem(this.associateWeeklyCheckInReminderKey));
    }
}
WeeklyCheckInService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WeeklyCheckInService_Factory() { return new WeeklyCheckInService(i0.ɵɵinject(i1.ApiGatewayService), i0.ɵɵinject(i2.Store)); }, token: WeeklyCheckInService, providedIn: "root" });
