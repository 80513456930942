import {
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { FormFieldControl } from '../../../../../shared/components/form/form-field/form-field.control';
import { BottomSheetService } from '../../../../../shared/modules/modal/bottom-sheet/bottom-sheet.service';
import { DocumentCopyComponent } from '../document-copy/document-copy.component';
import {
  FORM_FIELD,
  FormFieldComponent
} from '../../../../../shared/components/form/form-field/form-field.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SvgIconColor, SvgIconName } from '../../../../../shared/enums/SvgIcon';
import { ButtonColor } from '../../../../../shared/enums/ButtonLink';
import { DocumentType } from '../../enums/DocumentType';

let nextUniqueId = 0;

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DocumentComponent)
    },
    { provide: FormFieldControl, useExisting: DocumentComponent }
  ]
})
export class DocumentComponent
  implements OnInit, OnDestroy, ControlValueAccessor, FormFieldControl {
  @Input() addDocumentButtonLabelTemplate: TemplateRef<any>;
  @Input() previewDocumentButtonLabelTemplate: TemplateRef<any>;
  @Input() documents: File[];
  @Input() documentsType: DocumentType;
  @Input() errors: any;
  @Input() hasInsurance: boolean;

  emptyStateImage = 'assets/images/document-card.svg';
  errorStateImage = 'assets/images/document-card-error.svg';

  onDestroySubject = new Subject();
  formControl: FormControl;

  protected defaultId = `app-document-${nextUniqueId++}`;
  protected componentId: string;
  public SvgIconNameEnum = SvgIconName;
  public SvgIconColorEnum = SvgIconColor;
  public ButtonColorEnum = ButtonColor;

  constructor(
    fb: FormBuilder,
    private bottomSheetService: BottomSheetService,
    @Optional() @Inject(FORM_FIELD) private formField: FormFieldComponent
  ) {
    this.formControl = fb.control('');
  }

  @Input()
  get id(): string {
    return this.componentId;
  }
  set id(value: string) {
    this.componentId = value || this.defaultId;
  }

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(value => {
        this.propagateChange(value);
      });
  }

  previewDocument() {
    const config = {
      data: {
        documents: Array.isArray(this.formControl.value)
          ? this.formControl.value.map(doc => {
              return {
                name: doc.name,
                size: 0,
                data: doc.file,
                type: '',
                modifiedDate: null
              };
            })
          : null,
        documentsType: this.documentsType
          ? this.documentsType
          : DocumentType.License,
        hasInsurance: this.hasInsurance !== undefined ? this.hasInsurance : true
      },
      fullHeight: true
    };
    this.bottomSheetService
      .open(DocumentCopyComponent, config)
      .afterClosed()
      .subscribe(result => {
        if (result && result.success) {
          const docs = result.data.map(doc => {
            return {
              name: doc.name,
              file: doc.data
            };
          });
          this.formControl.setValue(docs);
        }
      });
  }

  get errorId(): string {
    return `${this.id}-error`;
  }

  public get control() {
    return this.formControl;
  }

  get hasValidation() {
    if (!this.formField) {
      return false;
    }
    return this.formField.hasValidation();
  }

  get isInvalid() {
    if (!this.formField) {
      return false;
    }
    return this.formField.isInvalid();
  }

  writeValue(value) {
    this.formControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}
  private propagateChange = (value: any) => {};

  ngOnDestroy() {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }
}
