<app-modal-layout (closeModal)="closeModal()">
  <app-modal-header
    slot="modal-header"
    primaryText="Cancel"
    i18n-primaryText="@@cancel-application_cancelTitle"
    secondaryText="Application"
    i18n-secondaryText="@@cancel-application_application"
  ></app-modal-header>
  <div slot="modal-content" class="cancel-application">
    <app-modal-content-row [sideSpacing]="true">
      <app-svg-icon
        class="cancel-application__icon"
        [name]="SvgIconNameEnum.Caution"
      ></app-svg-icon>
      <p
        *ngIf="cancelType === CancelTypeEnum.Opportunity"
        i18n="@@cancel-application_areYouSureOpportunity"
      >
        Are you sure you don't want to be considered for this opportunity?
      </p>
      <p
        *ngIf="cancelType === CancelTypeEnum.RoadCrewApplication"
        i18n="@@cancel-application_areYouSureApplication"
      >
        Are you sure you want to leave? Your application will not be saved.
      </p>
    </app-modal-content-row>
    <div class="cancel-application__actions">
      <app-button-link
        (buttonClick)="closeModal()"
        label="Nevermind"
        i18n-label="@@cancel-application_back"
        [appearance]="ButtonAppearanceEnum.Link"
      ></app-button-link>

      <app-button-link
        (buttonClick)="cancel()"
        label="Cancel Application"
        i18n-label="@@cancel-application_cancel"
        [appearance]="ButtonAppearanceEnum.Link"
        [color]="ButtonColorEnum.Red"
      ></app-button-link>
    </div>
  </div>
</app-modal-layout>
