import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '../../../shared/modules/modal/dialog-ref';
import { ApplicationInsightsService } from '../../../shared/services/application-insights.service';
import { DIALOG_DATA } from '../../../shared/modules/modal/dialog-config';
import { CustomEvents } from '../../../shared/enums/CustomEvents';
import { SvgIconName } from '../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-service-earnings-details-card',
  templateUrl: './service-earnings-details-card.component.html'
})
export class ServiceEarningsDetailsCardComponent implements OnInit, OnDestroy {
  public columns = ['type', 'amount'];
  taskDetails;
  tableData;
  SvgIconNameEnum = SvgIconName;

  constructor(
    private modalRef: DialogRef<ServiceEarningsDetailsCardComponent>,
    private appInsights: ApplicationInsightsService,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.taskDetails = this.data.taskDetails;
    this.tableData = {
      cellData: [
        {
          type: 'case-count',
          amount: this.taskDetails.associateCaseCount || 0,
          isCurrency: false,
          isPercentage: false
        },
        {
          type: 'pallet-count',
          amount: this.taskDetails.associatePalletCount || 0,
          isCurrency: false,
          isPercentage: false
        },
        {
          type: 'net-revenue',
          amount: this.taskDetails.netRevenue,
          isCurrency: true,
          isPercentage: false
        },
        {
          type: 'associate-pay',
          amount: this.taskDetails.earningsPercent,
          isCurrency: false,
          isPercentage: true
        }
      ],
      footerData: this.taskDetails.associateEarnings
    };

    this.appInsights.logCustomEventStart(
      CustomEvents.SelectingDetailsModalOpen
    );
  }

  ngOnDestroy() {
    this.appInsights.logCustomEventStop(
      CustomEvents.SelectingDetailsModalOpen,
      this.taskDetails
    );
  }

  closeModal() {
    this.modalRef.close({ success: true });
  }
}
