/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-field.component";
import * as i4 from "../root-form/root-form.directive";
var styles_FormFieldComponent = [i0.styles];
var RenderType_FormFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormFieldComponent, data: {} });
export { RenderType_FormFieldComponent as RenderType_FormFieldComponent };
function View_FormFieldComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FormFieldComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "label", [], [[1, "for", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { label: 0, "label--required": 1, "label--invalid": 2, "label--hidden": 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFieldComponent_3)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, true, _co.required, _co.isInvalid(), ((_co.label == null) ? null : _co.label.hidden)); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent, 4); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.control == null) ? null : _co.control.id); _ck(_v, 0, 0, currVal_0); }); }
function View_FormFieldComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FormFieldComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "legend", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { label: 0, "label--required": 1, "label--invalid": 2, "label--hidden": 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFieldComponent_5)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, _co.required, _co.isInvalid(), ((_co.label == null) ? null : _co.label.hidden)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v.parent, 4); _ck(_v, 5, 0, currVal_1); }, null); }
function View_FormFieldComponent_6(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_FormFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFieldComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["legendTemplate", 2]], null, 0, null, View_FormFieldComponent_4)), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_FormFieldComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_co.label == null) ? null : _co.label.asLegend); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_FormFieldComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "validation-container"]], null, null, null, null, null)), i1.ɵncd(null, 2)], null, null); }
export function View_FormFieldComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFieldComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "input-container": 0, "input-container--has-validation-message": 1, "input-container--narrow": 2 }), i1.ɵncd(null, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFieldComponent_7)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, true, _co.validationContainer, _co.narrow); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.isInvalid() && _co.validationContainer); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_FormFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-form-field", [], null, null, null, View_FormFieldComponent_0, RenderType_FormFieldComponent)), i1.ɵprd(6144, null, i3.FORM_FIELD, null, [i3.FormFieldComponent]), i1.ɵdid(2, 114688, null, 3, i3.FormFieldComponent, [[2, i4.RootFormDirective]], null, null), i1.ɵqud(335544320, 1, { control: 0 }), i1.ɵqud(603979776, 2, { label: 0 }), i1.ɵqud(335544320, 3, { validationContainer: 0 })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FormFieldComponentNgFactory = i1.ɵccf("app-form-field", i3.FormFieldComponent, View_FormFieldComponent_Host_0, { labelFor: "labelFor", required: "required", narrow: "narrow", validation: "validation" }, {}, ["app-label", "*", "app-validation"]);
export { FormFieldComponentNgFactory as FormFieldComponentNgFactory };
