/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./associate-qr-code.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/a11y";
import * as i3 from "../../svg-icon/svg-icon.component.ngfactory";
import * as i4 from "../../svg-icon/svg-icon.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./associate-qr-code.component";
import * as i7 from "../../../modules/modal/dialog/dialog.service";
var styles_AssociateQrCodeComponent = [i0.styles];
var RenderType_AssociateQrCodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssociateQrCodeComponent, data: {} });
export { RenderType_AssociateQrCodeComponent as RenderType_AssociateQrCodeComponent };
export function View_AssociateQrCodeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "qr-code"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "button", [["cdkMonitorElementFocus", ""], ["class", "qr-code__button"], ["data-cy", "qr-code"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showQRCode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i2.CdkMonitorFocus, [i1.ElementRef, i2.FocusMonitor], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Show my QR code "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-svg-icon", [["class", "qr-code__code-icon"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(6, 638976, null, 0, i4.SvgIconComponent, [i5.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "qr-code__expand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-svg-icon", [["class", "qr-code__expand-icon"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(9, 638976, null, 0, i4.SvgIconComponent, [i5.DomSanitizer], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.QRCode; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.SvgIconNameEnum.ExpandArrows; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_AssociateQrCodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-associate-qr-code", [], null, null, null, View_AssociateQrCodeComponent_0, RenderType_AssociateQrCodeComponent)), i1.ɵdid(1, 114688, null, 0, i6.AssociateQrCodeComponent, [i7.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssociateQrCodeComponentNgFactory = i1.ɵccf("app-associate-qr-code", i6.AssociateQrCodeComponent, View_AssociateQrCodeComponent_Host_0, { apexId: "apexId" }, {}, []);
export { AssociateQrCodeComponentNgFactory as AssociateQrCodeComponentNgFactory };
