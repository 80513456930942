import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ResourcesActions } from '../actions';
import {
  catchError,
  mergeMap,
  switchMap,
  withLatestFrom,
  map
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, getStarsFeedActivePost } from '../../reducers';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../shared/error-handlers/catch-app-error';
import { PostDetailsResponse } from '../../core/responses/news/postDetailsResponse';
import { Feed } from '../../core/dataEntities/feed/feed';
import { ResourcesService } from '../services/resources.service';
import { NewsActions } from '../../news/actions';

@Injectable()
export class StarsFeedEffects {
  constructor(
    private actions$: Actions,
    private resourcesService: ResourcesService,
    private store: Store<AppState>,
    @Inject(APP_ERROR_MESSAGES) private errorMessages
  ) {}

  loadStars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourcesActions.loadScriptStars),
      mergeMap(action => {
        return this.resourcesService
          .starsFeed(action.page, action.itemsPerPage)
          .pipe(
            switchMap((stars: Feed) => {
              const postIds = stars.posts.map(post => post.id);

              return this.resourcesService.getPostsAdditionalData(postIds).pipe(
                switchMap((postsDetails: PostDetailsResponse) => {
                  return of(
                    ResourcesActions.scriptStarsLoaded({
                      feed: stars,
                      page: action.page,
                      additionalData: postsDetails
                    })
                  );
                })
              );
            }),
            catchError(err => {
              console.error(err);
              return of(ResourcesActions.errorOccurredOnStarsFeedLoading());
            })
          );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  likePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourcesActions.likePost),
      mergeMap(action => {
        return this.resourcesService
          .likePost(action.postId, action.isLiked)
          .pipe(
            mergeMap(postLiked => {
              return of(
                ResourcesActions.postLiked(action),
                NewsActions.postLiked(action)
              );
            })
          );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  loadPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourcesActions.loadStarPost),
      withLatestFrom(this.store.select(getStarsFeedActivePost)),
      mergeMap(([action, postInState]) => {
        if (postInState) {
          return of(
            ResourcesActions.postLoaded({
              post: postInState,
              postId: action.postId
            })
          );
        }
        return forkJoin(
          this.resourcesService.loadPost(action.postId),
          this.resourcesService.getPostsAdditionalData([action.postId])
        ).pipe(
          map(([loadedPost, additionalDetails]) => {
            return ResourcesActions.postLoaded({
              post: loadedPost,
              postId: action.postId,
              additionalData:
                loadedPost.id === this.resourcesService.pinnedPostId
                  ? null
                  : additionalDetails
            });
          }),
          catchError(() => {
            return of(ResourcesActions.errorOccurredOnPostLoading());
          })
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );
}
