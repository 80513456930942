import { Component, Input } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-feed-separator',
  templateUrl: './feed-separator.component.html',
  styleUrls: ['./feed-separator.component.scss']
})
export class FeedSeparatorComponent {
  @Input() date: string;
  @Input() isFirst = false;
  @Input() unseenCount: Observable<number>;

  constructor() {}

  daysAgo(date: string) {
    return moment().diff(moment(date), 'days');
  }
}
