import {
  ContentChildren,
  Directive,
  Input,
  QueryList,
  TemplateRef
} from '@angular/core';
import { MultiStepFormService } from '../services/multi-step-form.service';

@Directive({ selector: '[appReadOnlyStepDef]' })
export class ReadOnlyStepDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[appEditableStepDef]' })
export class EditableStepDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[appFormStepDef]'
})
export class FormStepDefDirective {
  private stepName: string;

  @Input('appFormStepDef')
  get name(): string {
    return this.stepName;
  }
  set name(name: string) {
    if (name) {
      this.stepName = name;
    }
  }

  @ContentChildren(ReadOnlyStepDefDirective, { descendants: true })
  readonlyDefs: QueryList<ReadOnlyStepDefDirective>;

  get readOnlyDef(): ReadOnlyStepDefDirective {
    return this.readonlyDefs && this.readonlyDefs.first;
  }

  @ContentChildren(EditableStepDefDirective, { descendants: true })
  editableDefs: QueryList<EditableStepDefDirective>;

  get editableDef(): EditableStepDefDirective {
    return this.editableDefs && this.editableDefs.first;
  }

  @Input()
  public set isEditable(value) {
    if (value) {
      this.multiStepFormService.setEditableStep(this);
    } else {
      this.multiStepFormService.setEditableStep(null);
    }
  }

  public get isEditable() {
    return this.multiStepFormService.isEditableStep(this);
  }

  @Input()
  title: string;
  @Input()
  subTitle: string;

  constructor(private multiStepFormService: MultiStepFormService) {}

  public edit() {
    return (this.isEditable = true);
  }
}
