import { NavigationActions } from '../actions';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

export const navigationFeatureKey = 'navigation';

export interface NavigationState {
  history: string[];
  activeIndex: number;
}

export const initialState: NavigationState = {
  history: null,
  activeIndex: null
};

const navigationReducer = createReducer(
  initialState,
  on(NavigationActions.navigatedBack, (state, action) => {
    return {
      ...state,
      activeIndex: state.activeIndex - 1
    };
  }),

  on(NavigationActions.navigationInitialized, (state, action) => {
    return {
      ...state,
      history: [...action.parentsUrl, action.url],
      activeIndex: action.parentsUrl.length
    };
  }),
  on(NavigationActions.navigatedForward, (state, action) => {
    let cutIndex = state.activeIndex + 1;
    if (action.replaceAppUrl) {
      cutIndex--;
    }
    const activeHistory = state.history.slice(0, cutIndex);
    return {
      ...state,
      history: [...activeHistory, action.url],
      activeIndex: activeHistory.length
    };
  })
);

export function reducer(state: NavigationState | undefined, action: Action) {
  return navigationReducer(state, action);
}

export const getNavigationState = createFeatureSelector(navigationFeatureKey);

const selectNavigationActiveIndex = createSelector(
  getNavigationState,
  (state: NavigationState) => state.activeIndex
);
const selectNavigationHistory = createSelector(
  getNavigationState,
  (state: NavigationState) => state.history
);

export const selectIsNavigationInitialized = createSelector(
  selectNavigationHistory,
  history => history != null
);

export const selectNavigationBackUrl = createSelector(
  selectNavigationHistory,
  selectNavigationActiveIndex,
  (history, activeIndex) => {
    if (!history) {
      return;
    }
    if (activeIndex > history.length) {
      return null;
    }

    return history[activeIndex - 1];
  }
);
