import { createReducer, createSelector, on } from '@ngrx/store';
import { LayoutActions } from '../actions';

export const layoutFeatureKey = 'layout';

export interface LayoutState {
  showSidenav: boolean;
  errorMessage: any;
  warningMessage: any;
  infoMessage: any;
  successMessage: any;
  isAppOnline: boolean;
  hasMenuNotification: boolean;
  showMenuBar: boolean;
  hideNavigationBar: boolean;
  persistRouteChangeCount: number;
  isAppLoading: boolean;
  appLoadersCount: number;
}

export const initialState: LayoutState = {
  showSidenav: false,
  errorMessage: null,
  warningMessage: null,
  infoMessage: null,
  successMessage: null,
  isAppOnline: null,
  hasMenuNotification: false,
  showMenuBar: true,
  hideNavigationBar: true,
  persistRouteChangeCount: 0,
  isAppLoading: false,
  appLoadersCount: 0
};
export const reducer = createReducer(
  initialState,
  on(LayoutActions.closeSidenav, state => ({
    ...state,
    showSidenav: false
  })),
  on(LayoutActions.openSidenav, state => ({
    ...state,
    showSidenav: true
  })),
  on(LayoutActions.showError, (state, action) => ({
    ...state,
    errorMessage: {
      title: action.message,
      showOver: action.showOver
    }
  })),
  on(LayoutActions.showSuccessMessage, (state, action) => ({
    ...state,
    successMessage: {
      title: action.title,
      subtitle: action.subtitle,
      showOver: action.showOver
    },
    persistRouteChangeCount: action.persistRouteChangeCount
      ? action.persistRouteChangeCount
      : 0
  })),
  on(LayoutActions.dismissError, (state, action) => ({
    ...state,
    errorMessage: null
  })),
  on(LayoutActions.showInfoMessage, (state, action) => ({
    ...state,
    infoMessage: {
      title: action.message,
      showOver: action.showOver
    },
    persistRouteChangeCount: action.persistRouteChangeCount
      ? action.persistRouteChangeCount
      : 0
  })),
  on(LayoutActions.dismissInfo, (state, action) => ({
    ...state,
    infoMessage: null
  })),
  on(LayoutActions.dismissSuccess, (state, action) => ({
    ...state,
    successMessage: null
  })),
  on(LayoutActions.appOnline, (state, action) => ({
    ...state,
    isAppOnline: true
  })),
  on(LayoutActions.appOffline, (state, action) => ({
    ...state,
    isAppOnline: false
  })),
  on(LayoutActions.menuNotification, (state, action) => ({
    ...state,
    hasMenuNotification: true
  })),
  on(LayoutActions.dismissMenuNotification, (state, action) => ({
    ...state,
    hasMenuNotification: false
  })),
  on(LayoutActions.cleanLayoutMessages, (state, action) => ({
    ...state,
    infoMessage: null,
    errorMessage: null,
    successMessage: null
  })),
  on(LayoutActions.reducePersistRouteChangeCount, (state, action) => ({
    ...state,
    persistRouteChangeCount:
      state.persistRouteChangeCount > 0
        ? state.persistRouteChangeCount - 1
        : state.persistRouteChangeCount
  })),
  on(LayoutActions.toggleMenuBar, (state, action) => {
    return {
      ...state,
      showMenuBar: action.show
    };
  }),
  on(LayoutActions.toggleNavigationBar, (state, action) => {
    return {
      ...state,
      hideNavigationBar: action.hide
    };
  }),
  on(LayoutActions.toggleAppLoading, (state, action) => {
    let count = state.appLoadersCount;
    if (action.loading) {
      count = count + 1;
    } else {
      count = count > 0 ? count - 1 : count;
    }
    return {
      ...state,
      appLoadersCount: count,
      isAppLoading: count > 0
    };
  })
);

export const getShowSidenav = (state: LayoutState) => state.showSidenav;
export const getErrorMessage = (state: LayoutState) => state.errorMessage;
export const getInfoMessage = (state: LayoutState) => state.infoMessage;
export const getSuccessMessage = (state: LayoutState) => state.successMessage;
export const getIsAppOnline = (state: LayoutState) => state.isAppOnline;
export const getIsAppLoading = (state: LayoutState) => state.isAppLoading;
export const getAppHasNotification = createSelector(
  getErrorMessage,
  getInfoMessage,
  getSuccessMessage,
  getIsAppOnline,
  (errorMessage, infoMessage, successMessage, isAppOnline) => {
    return !isAppOnline || !!errorMessage || !!infoMessage || !!successMessage;
  }
);

export const getPersistRouteChangeCount = (state: LayoutState) =>
  state.persistRouteChangeCount;

export const hasMenuNotification = (state: LayoutState) =>
  state.hasMenuNotification;

export const showMenuBar = (state: LayoutState) => state.showMenuBar;

export const hideNavigationBar = (state: LayoutState) =>
  state.hideNavigationBar;
