/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/alert/alert.component.ngfactory";
import * as i2 from "../../../../shared/components/alert/alert.component";
import * as i3 from "./phone-reminder.component";
import * as i4 from "../../../../shared/modules/modal/dialog-ref";
import * as i5 from "@angular/router";
var styles_PhoneReminderComponent = [];
var RenderType_PhoneReminderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PhoneReminderComponent, data: {} });
export { RenderType_PhoneReminderComponent as RenderType_PhoneReminderComponent };
export function View_PhoneReminderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-alert", [["actionTitle", "Sign Up"], ["dismissTitle", "Not Right Now"], ["subtitle", "Stay informed on new work opportunities and important alerts."], ["title", "Turn On Text Alerts"]], null, [[null, "closeAlert"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeAlert" === en)) {
        var pd_0 = (_co.onCloseAlert($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 638976, null, 0, i2.AlertComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"], iconName: [2, "iconName"], appearance: [3, "appearance"], actionTitle: [4, "actionTitle"], dismissTitle: [5, "dismissTitle"] }, { closeAlert: "closeAlert" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Turn On Text Alerts"; var currVal_1 = "Stay informed on new work opportunities and important alerts."; var currVal_2 = _co.SvgIconName.Earnings; var currVal_3 = _co.AlertAppearance.Green; var currVal_4 = "Sign Up"; var currVal_5 = "Not Right Now"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_PhoneReminderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-phone-reminder", [], null, null, null, View_PhoneReminderComponent_0, RenderType_PhoneReminderComponent)), i0.ɵdid(1, 114688, null, 0, i3.PhoneReminderComponent, [i4.DialogRef, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhoneReminderComponentNgFactory = i0.ɵccf("app-phone-reminder", i3.PhoneReminderComponent, View_PhoneReminderComponent_Host_0, {}, {}, []);
export { PhoneReminderComponentNgFactory as PhoneReminderComponentNgFactory };
