<article
  class="feed-card"
  data-cy="feedCard"
  [attr.aria-labelledby]="'feed-card-heading-' + post.id"
  [attr.aria-describedby]="'feed-card-summary-' + post.id"
  [attr.aria-posinset]="ariaPosinset"
  [attr.aria-setsize]="ariaSetsize"
>
  <app-post-profile
    [post]="post"
    [isPinnedPost]="isPinnedPost"
  ></app-post-profile>
  <app-post-image
    [image]="post.postImage"
    [title]="post.title"
    [type]="post.category"
    [subtype]="post.safetyTipType"
    [postId]="post.id"
  ></app-post-image>
  <div class="feed-card__title">
    <h2
      *ngIf="post.category !== PostCategoryTypeEnum.SafetyTip"
      class="feed-card__heading"
      [attr.id]="'feed-card-heading-' + post.id"
    >
      <span class="feed-card__heading-text">{{ post.title }}</span>
    </h2>
    <app-post-category
      class="feed-card__category"
      [category]="post.category"
    ></app-post-category>
  </div>
  <div
    [@showMore]="extendableBlogPost && !isExtended"
    [ngClass]="{
      'feed-card__summary': true,
      'feed-card__summary--extendable': extendableBlogPost && !isExtended
    }"
    *ngIf="post.summary"
  >
    <ng-content select="[slot='content']"></ng-content>
    <div
      #contentfulContent
      class="contentful-content"
      [attr.id]="'feed-card-summary-' + post.id"
      [innerHTML]="summary"
    ></div>
  </div>
  <div class="feed-card__actions">
    <ng-content select="[slot='actions']"></ng-content>
  </div>
</article>
