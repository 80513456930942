<div
  [ngClass]="{
    'menu-header': true,
    'menu-header--top-rounded-border': topRoundedBorder,
    'menu-header--bottom-rounded-border': bottomRoundedBorder
  }"
>
  <ng-content></ng-content>
  <div class="menu-header__action" *ngIf="actionTemplate">
    <ng-container *ngTemplateOutlet="actionTemplate"></ng-container>
  </div>
</div>
