/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./expandable-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/accordion/accordion-panel.component.ngfactory";
import * as i3 from "../../../shared/components/accordion/accordion-panel.component";
import * as i4 from "../../../shared/components/accordion/accordion.service";
import * as i5 from "../../../shared/services/analytics-utilities.service";
import * as i6 from "@angular/common";
import * as i7 from "../../../shared/components/accordion/accordion.component.ngfactory";
import * as i8 from "../../../shared/components/accordion/accordion.component";
import * as i9 from "./expandable-panel.component";
var styles_ExpandablePanelComponent = [i0.styles];
var RenderType_ExpandablePanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExpandablePanelComponent, data: {} });
export { RenderType_ExpandablePanelComponent as RenderType_ExpandablePanelComponent };
function View_ExpandablePanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.panel.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ExpandablePanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-accordion-panel", [], null, null, null, i2.View_AccordionPanelComponent_0, i2.RenderType_AccordionPanelComponent)), i1.ɵdid(1, 49152, [[1, 4]], 0, i3.AccordionPanelComponent, [i4.AccordionService, i5.AnalyticsUtilitiesService, i1.ElementRef], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(2, 0, null, 1, 0, "div", [["slot", "body"]], [[1, "data-cy", 0], [8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ("body_" + _v.context.index); var currVal_2 = _co.convertToHtmlString(_v.context.$implicit.description); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_ExpandablePanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "expandable-panel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExpandablePanelComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "app-accordion", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_AccordionComponent_0, i7.RenderType_AccordionComponent)), i1.ɵprd(512, null, i4.AccordionService, i4.AccordionService, []), i1.ɵdid(6, 1294336, null, 1, i8.AccordionComponent, [i4.AccordionService], null, null), i1.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ExpandablePanelComponent_2)), i1.ɵdid(9, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.panel.title; _ck(_v, 2, 0, currVal_0); _ck(_v, 6, 0); var currVal_2 = _co.panel.items; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.convertToHtmlString(_co.panel.description); _ck(_v, 3, 0, currVal_1); }); }
export function View_ExpandablePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expandable-panel", [], null, null, null, View_ExpandablePanelComponent_0, RenderType_ExpandablePanelComponent)), i1.ɵdid(1, 49152, null, 0, i9.ExpandablePanelComponent, [], null, null)], null, null); }
var ExpandablePanelComponentNgFactory = i1.ɵccf("app-expandable-panel", i9.ExpandablePanelComponent, View_ExpandablePanelComponent_Host_0, { panel: "panel" }, {}, []);
export { ExpandablePanelComponentNgFactory as ExpandablePanelComponentNgFactory };
