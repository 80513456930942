/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./qr-code.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./qr-code.component";
import * as i3 from "../../../shared/modules/modal/dialog-config";
import * as i4 from "@angular/platform-browser";
var styles_QrCodeComponent = [i0.styles];
var RenderType_QrCodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QrCodeComponent, data: {} });
export { RenderType_QrCodeComponent as RenderType_QrCodeComponent };
export function View_QrCodeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "qr"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "qr__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ID# ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "qrcode"], ["data-cy", "qrCodeModal"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "qr__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Use your QR code to scan in and out at work. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.apexId; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.innerHtml; _ck(_v, 3, 0, currVal_1); }); }
export function View_QrCodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-qr-code", [], null, null, null, View_QrCodeComponent_0, RenderType_QrCodeComponent)), i1.ɵdid(1, 114688, null, 0, i2.QrCodeComponent, [i3.DIALOG_DATA, i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QrCodeComponentNgFactory = i1.ɵccf("app-qr-code", i2.QrCodeComponent, View_QrCodeComponent_Host_0, {}, {}, []);
export { QrCodeComponentNgFactory as QrCodeComponentNgFactory };
