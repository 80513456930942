import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, first, switchMap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NewsFeedService } from '../../services/news-feed.service';
import { Post } from '../../../core/dataEntities/feed/post';

@Component({
  selector: 'app-preview-feed-card',
  templateUrl: './preview-feed-card.component.html',
  styleUrls: ['./preview-feed-card.component.scss']
})
export class PreviewFeedCardComponent implements OnInit, OnDestroy {
  public post: Post;
  public content: string;
  public isPostLoading$: Observable<boolean>;
  public isPostErrorOccurred$: Observable<any>;

  postLoadingSubject = new BehaviorSubject(false);
  postErrorOccurredSubject = new BehaviorSubject(false);
  onDestroySubject = new Subject();

  constructor(
    private route: ActivatedRoute,
    private newsFeedService: NewsFeedService
  ) {
    this.isPostLoading$ = this.postLoadingSubject.asObservable();
    this.isPostErrorOccurred$ = this.postErrorOccurredSubject.asObservable();
  }

  ngOnInit() {
    this.postLoadingSubject.next(true);
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          return this.newsFeedService
            .loadPostPreview(params.get('id'))
            .pipe(finalize(() => this.postLoadingSubject.next(false)));
        }),
        first()
      )
      .subscribe(
        post => {
          if (post) {
            this.post = post;
          }
        },
        () => {
          this.postErrorOccurredSubject.next(true);
        }
      );
  }

  ngOnDestroy(): void {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }
}
