import { BaseModalContainerComponent } from './base-modal/base-modal-container.component';
import { Observable, Subject } from 'rxjs';
import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay';
import { filter, take } from 'rxjs/operators';
import { hasModifierKey } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { DialogPosition } from './dialog-config';
import { DialogResult } from './base-modal/base-modal.service';

let uniqueId = 0;

export const enum DialogState {
  OPEN,
  CLOSING,
  CLOSED
}

export class DialogRef<T, R = any> {
  componentInstance: T;

  disableClose: boolean | undefined = this.containerInstance.config
    .disableClose;

  private readonly afterOpenedSubject = new Subject<void>();
  private readonly afterClosedSubject = new Subject<DialogResult<R>>();
  private readonly beforeClosedSubject = new Subject<DialogResult<R>>();

  private result: DialogResult<R>;
  private closeFallbackTimeout: any;
  // @ts-ignore TS6133
  private state = DialogState.OPEN;

  constructor(
    private overlayRef: OverlayRef,
    public containerInstance: BaseModalContainerComponent,
    location?: Location,
    readonly id: string = `dialog-${uniqueId++}`
  ) {
    containerInstance.id = id;
    containerInstance.animationStateChanged
      .pipe(
        filter(
          event => event.phaseName === 'done' && event.toState === 'enter'
        ),
        take(1)
      )
      .subscribe(() => {
        this.afterOpenedSubject.next();
        this.afterOpenedSubject.complete();
      });

    containerInstance.animationStateChanged
      .pipe(
        filter(event => event.phaseName === 'done' && event.toState === 'exit'),
        take(1)
      )
      .subscribe(() => {
        clearTimeout(this.closeFallbackTimeout);
        this.overlayRef.dispose();
      });

    overlayRef.detachments().subscribe(() => {
      this.beforeClosedSubject.next(this.result);
      this.beforeClosedSubject.complete();
      this.afterClosedSubject.next(this.result);
      this.afterClosedSubject.complete();
      this.componentInstance = null;
      this.overlayRef.dispose();
    });

    overlayRef
      .keydownEvents()
      .pipe(
        filter(event => {
          return (
            event.code === 'Escape' &&
            !this.disableClose &&
            !hasModifierKey(event)
          );
        })
      )
      .subscribe(event => {
        event.preventDefault();
        this.close();
      });
  }

  close(dialogResult?: DialogResult<R>): void {
    this.result = dialogResult;

    this.containerInstance.animationStateChanged
      .pipe(
        filter(event => event.phaseName === 'start'),
        take(1)
      )
      .subscribe(event => {
        this.beforeClosedSubject.next(dialogResult);
        this.beforeClosedSubject.complete();
        this.state = DialogState.CLOSED;
        this.overlayRef.detachBackdrop();

        this.closeFallbackTimeout = setTimeout(() => {
          this.overlayRef.dispose();
        }, event.totalTime + 100);
      });

    this.containerInstance.startExitAnimation();
    this.state = DialogState.CLOSING;
  }

  updatePosition(position?: DialogPosition): this {
    const strategy = this._getPositionStrategy();

    if (position && (position.left || position.right)) {
      position.left
        ? strategy.left(position.left)
        : strategy.right(position.right);
    } else {
      strategy.centerHorizontally();
    }

    if (position && (position.top || position.bottom)) {
      position.top
        ? strategy.top(position.top)
        : strategy.bottom(position.bottom);
    } else {
      strategy.centerVertically();
    }

    this.overlayRef.updatePosition();

    return this;
  }

  afterOpened(): Observable<void> {
    return this.afterOpenedSubject.asObservable();
  }

  afterClosed(): Observable<DialogResult<R>> {
    return this.afterClosedSubject.asObservable();
  }

  beforeClosed(): Observable<DialogResult<R>> {
    return this.beforeClosedSubject.asObservable();
  }

  backdropClick(): Observable<MouseEvent> {
    return this.overlayRef.backdropClick();
  }

  keydownEvents(): Observable<KeyboardEvent> {
    return this.overlayRef.keydownEvents();
  }

  private _getPositionStrategy(): GlobalPositionStrategy {
    return this.overlayRef.getConfig()
      .positionStrategy as GlobalPositionStrategy;
  }
}
