import {
  ApplicationRef,
  Component,
  HostListener,
  Inject,
  LOCALE_ID,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  combineLatest,
  fromEvent,
  merge,
  Observable,
  ReplaySubject,
  Subject
} from 'rxjs';
import { Store } from '@ngrx/store';
import 'hammerjs';
import _ from 'lodash';

import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { LayoutActions, UserActions } from './core/actions';
import * as fromRoot from './reducers';
import { AppState } from './reducers';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppNotification } from './core/dataEntities/notification/notification';
import { TranslationService } from './shared/services/translation.service';
import { TranslationMessages } from './shared/enums/TranslationMessages';
import { UserIdleService } from 'angular-user-idle';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { SvgIconColor, SvgIconName } from './shared/enums/SvgIcon';
import { QrCodeComponent } from './profile/components/qr-code/qr-code.component';
import { DialogService } from './shared/modules/modal/dialog/dialog.service';
import { Roles } from './core/enums/Roles';
import { ProfileLinkItemAppearance } from './profile/enums/ProfileLinkItem';
import { ApplicationTitleService } from './shared/services/application-title.service';
import { UpdateService } from './shared/services/update.service';
import { UpdatePromptService } from './shared/services/update-prompt.service';
import { AnonymousGatewayService } from './shared/services/anonymous.gateway.service';
import { Features } from './core/enums/Features';
import 'rxjs-compat/add/observable/fromEventPattern';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  onDestroySubject = new Subject();
  title = 'associate-engagement';

  showSidenav$: Observable<boolean>;
  errorMessage$: Observable<AppNotification>;
  infoMessage$: Observable<AppNotification>;
  isUserLoggingOut$: Observable<boolean>;
  isUserLoggedIn$: Observable<boolean>;
  isUserAgreeWithTerms$: Observable<boolean>;
  appHasNotification$: Observable<boolean>;
  noActionNotification$: Observable<AppNotification>;
  successNotification$: Observable<AppNotification>;
  userName$: Observable<string>;
  showMenuBar$: Observable<boolean>;
  userApexId$: Observable<number>;
  shiftCount$: Observable<number>;

  @ViewChild(SidebarComponent, { static: true })
  userProfile: SidebarComponent;
  SvgIconName = SvgIconName;
  SvgIconColor = SvgIconColor;
  ProfileLinkItemAppearance = ProfileLinkItemAppearance;
  Roles = Roles;
  public Features = Features;
  private userIdle$ = new ReplaySubject<UserIdleService>();

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    private store: Store<AppState>,
    private translationService: TranslationService,
    private applicationRef: ApplicationRef,
    private router: Router,
    private dialogService: DialogService,
    private appTitle: ApplicationTitleService,
    updateService: UpdateService,
    updatePromptService: UpdatePromptService,
    private anonymousGatewayService: AnonymousGatewayService
  ) {}

  ngOnInit() {
    this.checkIfAppOnline();

    this.appTitle.track();

    this.showSidenav$ = this.store.select(fromRoot.getShowSidenav);
    this.errorMessage$ = this.store.select(fromRoot.getErrorMessage);
    this.infoMessage$ = this.store.select(fromRoot.getInfoMessage);
    this.isUserLoggingOut$ = this.store.select(fromRoot.getIsUserLoggingOut);
    this.userApexId$ = this.store.select(fromRoot.getApexId);

    this.successNotification$ = this.store.select(fromRoot.getSuccessMessage);
    this.appHasNotification$ = this.store.select(
      fromRoot.getAppHasNotification
    );

    this.isUserLoggedIn$ = this.store.select(fromRoot.getIsUserLoggedIn);
    this.isUserAgreeWithTerms$ = this.store.select(
      fromRoot.isUserAgreeWithTerms
    );

    this.userName$ = this.store.select(fromRoot.getUserName);

    this.noActionNotification$ = this.store
      .select(fromRoot.getIsAppOnline)
      .pipe(
        map(isAppOnline => {
          console.log('isAppOnline', isAppOnline);
          if (isAppOnline === false) {
            return {
              title: this.translationService.translate(
                TranslationMessages.NoInternetConnectionMessage
              )
            };
          }
        })
      );

    const inactivityEvents = merge(
      fromEvent(window, 'click'),
      fromEvent(window, 'mouseover'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'touchstart'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'scroll'),
      fromEvent(document, 'keyup')
    );

    this.applicationRef.isStable
      .pipe(
        first(isStable => isStable),
        takeUntil(this.onDestroySubject)
      )
      .subscribe(() => {
        this.userIdle$.next(
          new UserIdleService(
            environment.userIdle,
            new NgZone({ enableLongStackTrace: false })
          )
        );
      });

    combineLatest(
      inactivityEvents,
      this.userIdle$.pipe(first(), takeUntil(this.onDestroySubject))
    ).subscribe(([event, userIdle]) => {
      userIdle.resetTimer();
    });

    this.userIdle$
      .pipe(first(), takeUntil(this.onDestroySubject))
      .subscribe(userIdle => {
        userIdle.setCustomActivityEvents(inactivityEvents);
      });

    combineLatest(
      this.isUserLoggedIn$,
      this.userIdle$.pipe(first(), takeUntil(this.onDestroySubject))
    ).subscribe(([isUserLoggedIn, userIdle]) => {
      if (isUserLoggedIn) {
        userIdle.startWatching();
      } else {
        userIdle.stopWatching();
      }
    });

    this.userIdle$
      .pipe(
        first(),
        takeUntil(this.onDestroySubject),
        switchMap(userIdle => userIdle.onTimeout())
      )
      .subscribe(() =>
        this.store.dispatch(UserActions.userLogout({ isAutoLogout: true }))
      );

    this.userIdle$
      .pipe(
        first(),
        takeUntil(this.onDestroySubject),
        switchMap(userIdle => userIdle.onTimerStart())
      )
      .subscribe();

    this.store.dispatch(LayoutActions.appOnInit());

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.closeSidenav();
      }
    });

    this.showMenuBar$ = combineLatest(
      this.store.select(fromRoot.getShowMenuBar),
      this.isUserLoggedIn$,
      this.isUserAgreeWithTerms$
    ).pipe(
      map(([showMenuBar, isUserLoggedIn, isUserAgreeWithTerms]) => {
        return showMenuBar && isUserLoggedIn && isUserAgreeWithTerms;
      })
    );

    this.shiftCount$ = this.store.select(fromRoot.getShiftCount);
    this.processLanguageChange();
  }

  ngOnDestroy() {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  @HostListener('window:online')
  onlineHandler() {
    this.checkIfAppOnline();
  }

  @HostListener('window:offline')
  offLineHandler() {
    this.store.dispatch(LayoutActions.appOffline());
  }

  closeSidenav() {
    this.store.dispatch(LayoutActions.closeSidenav());
  }

  openSidenav() {
    this.store.dispatch(LayoutActions.openSidenav());
  }

  onDismissError() {
    this.store.dispatch(LayoutActions.dismissError());
  }

  onDismissInfo() {
    this.store.dispatch(LayoutActions.dismissInfo());
  }

  onDismissSuccess() {
    this.store.dispatch(LayoutActions.dismissSuccess());
  }

  showQRCode() {
    this.userApexId$.pipe(first()).subscribe(apexId => {
      this.closeSidenav();
      this.dialogService.open(QrCodeComponent, { data: { apexId } });
    });
  }

  isMobileSafari() {
    const isMobileView =
      window.navigator.platform.match(/iPhone/i) ||
      window.navigator.platform.match(/Android/i) ||
      window.navigator.platform.match(/Linux/i) ||
      window.navigator.platform.match(/iPod/i) ||
      window.navigator.platform.match(/iPad/i);

    return (
      isMobileView &&
      !this.isRunningStandaloneAndroid() &&
      !this.isRunningStandaloneIOS()
    );
  }

  processLanguageChange() {
    const currentLocale = _.trim(window.location.pathname, '/').substring(0, 2);
    let language = this.translationService.getLanguage();
    if (!language) {
      language = currentLocale;
    }
    if (currentLocale !== language && language) {
      this.redirect(language);
    }
  }

  redirect(url: string) {
    window.location.href = '/' + url;
  }

  checkIfAppOnline() {
    this.anonymousGatewayService
      .get('favicon.ico?cache-bust=' + Date.now())
      .subscribe(
        () => {
          this.store.dispatch(LayoutActions.appOnline());
        },
        e => {
          this.store.dispatch(LayoutActions.appOffline());
        }
      );
  }

  isRunningStandaloneAndroid() {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  isRunningStandaloneIOS() {
    return 'standalone' in window.navigator && window.navigator['standalone'];
  }
}
