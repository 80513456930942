import { Injectable } from '@angular/core';
import ProgressBar from 'progressbar.js';

export interface IProgressBarPath {
  set(value: number);
  animate(barValue: number);
}

@Injectable()
export class GoalGaugeService {
  constructor() {}

  createGauge(container, barValue): IProgressBarPath {
    const gauge = new ProgressBar.Path(container.nativeElement, {
      easing: 'easeInOut',
      duration: 1400
    });
    gauge.set(0);
    gauge.animate(barValue);

    return gauge;
  }
}
