/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../button-link/button-link.component.ngfactory";
import * as i2 from "../button-link/button-link.component";
import * as i3 from "../../services/analytics-utilities.service";
import * as i4 from "@angular/common";
import * as i5 from "./file-uploader.component";
import * as i6 from "@ngrx/store";
import * as i7 from "../../services/translation.service";
var styles_FileUploaderComponent = [];
var RenderType_FileUploaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileUploaderComponent, data: {} });
export { RenderType_FileUploaderComponent as RenderType_FileUploaderComponent };
function View_FileUploaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-button-link", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.openUploadPrompt() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonLinkComponent_0, i1.RenderType_ButtonLinkComponent)), i0.ɵdid(1, 638976, null, 0, i2.ButtonLinkComponent, [i3.AnalyticsUtilitiesService], { label: [0, "label"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonLabel; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FileUploaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", "defaultPreview"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.files[0] == null) ? null : _co.files[0].data); _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileUploaderComponent_3)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files[0]; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FileUploaderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-button-link", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.openUploadPrompt() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonLinkComponent_0, i1.RenderType_ButtonLinkComponent)), i0.ɵdid(1, 638976, null, 0, i2.ButtonLinkComponent, [i3.AnalyticsUtilitiesService], { label: [0, "label"] }, { buttonClick: "buttonClick" }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Wrong file type"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonLabel; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FileUploaderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { uploader: 0 }), i0.ɵqud(671088640, 2, { defaultButtonTemplate: 0 }), i0.ɵqud(671088640, 3, { defaultPreviewTemplate: 0 }), i0.ɵqud(671088640, 4, { defaultErrorTemplate: 0 }), i0.ɵqud(671088640, 5, { container: 0 }), (_l()(), i0.ɵeld(5, 0, [[1, 0], ["uploader", 1]], null, 0, "input", [["class", "visually-hidden"], ["type", "file"]], [[8, "id", 0], [8, "accept", 0]], [[null, "change"], [null, "focusin"], [null, "focusout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onUpload() !== false);
        ad = (pd_0 && ad);
    } if (("focusin" === en)) {
        var pd_1 = (_co.manageFocus(true) !== false);
        ad = (pd_1 && ad);
    } if (("focusout" === en)) {
        var pd_2 = (_co.manageFocus(false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵeld(7, 16777216, [[5, 3], ["templateContainer", 1]], null, 0, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(0, [[2, 2], ["defaultButtonTemplate", 2]], null, 0, null, View_FileUploaderComponent_1)), (_l()(), i0.ɵand(0, [[3, 2], ["defaultPreviewTemplate", 2]], null, 0, null, View_FileUploaderComponent_2)), (_l()(), i0.ɵand(0, [[4, 2], ["defaultErrorTemplate", 2]], null, 0, null, View_FileUploaderComponent_4))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputId; var currVal_1 = _co.fileType; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = _co.inputId; _ck(_v, 6, 0, currVal_2); }); }
export function View_FileUploaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-file-uploader", [], null, null, null, View_FileUploaderComponent_0, RenderType_FileUploaderComponent)), i0.ɵdid(1, 4440064, null, 0, i5.FileUploaderComponent, [i6.Store, i0.ChangeDetectorRef, i7.TranslationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploaderComponentNgFactory = i0.ɵccf("app-file-uploader", i5.FileUploaderComponent, View_FileUploaderComponent_Host_0, { buttonTemplate: "buttonTemplate", buttonLabel: "buttonLabel", previewTemplate: "previewTemplate", errorTemplate: "errorTemplate", multiple: "multiple", fileType: "fileType", uploadedFiles: "uploadedFiles" }, { filesUploaded: "filesUploaded" }, []);
export { FileUploaderComponentNgFactory as FileUploaderComponentNgFactory };
