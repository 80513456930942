import { combineLatest } from 'rxjs';
import { getAuthTokenWithApexUserId, getIsUserProfileLoading, getUserSitesIds } from '../../reducers';
import { first, map, switchMap, mergeMap, filter } from 'rxjs/operators';
import { FeedService } from '../../shared/services/feed.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/content-delivery.service";
import * as i2 from "../../shared/services/api.gateway.service";
import * as i3 from "@ngrx/store";
export class NewsFeedService extends FeedService {
    constructor(contentDeliveryService, apiGateway, store) {
        super(apiGateway, store);
        this.contentDeliveryService = contentDeliveryService;
        this.apiGateway = apiGateway;
        this.store = store;
    }
    loadNewsFeed(page, take) {
        return combineLatest(this.store.select(getUserSitesIds), this.store.select(getIsUserProfileLoading)).pipe(filter(([userSitesIds, isProfileInfoLoading]) => !isProfileInfoLoading), first(), mergeMap(([userSitesIds]) => {
            const query = {
                'fields.sites[in]': `All,${userSitesIds !== null ? userSitesIds.join(',') : ''}`
            };
            return this.contentDeliveryService.getNews(page * take, take, userSitesIds ? query : null);
        }));
    }
    loadPost(postId) {
        return this.contentDeliveryService.getPost(postId);
    }
    loadPostPreview(postId) {
        return this.contentDeliveryService.getPostPreview(postId);
    }
    getTotalNewPostCount(lastSeenDate) {
        return combineLatest(this.store.select(getUserSitesIds), this.store.select(getIsUserProfileLoading)).pipe(filter(([userSitesIds, isProfileInfoLoading]) => !isProfileInfoLoading), first(), mergeMap(([userSitesIds]) => {
            return this.contentDeliveryService.getNewsCount(lastSeenDate, userSitesIds ? userSitesIds : '');
        }));
    }
    postNewsFeedLastSeen() {
        return this.store.select(getAuthTokenWithApexUserId).pipe(first(), switchMap(({ authToken, userId }) => {
            if (!userId) {
                throw new Error('User not found.');
            }
            return this.apiGateway.put(`comms/newsfeed/posts/lastSeen/associate/${userId}`, authToken, {});
        }));
    }
    getNewsFeedLastSeen() {
        return this.store.select(getAuthTokenWithApexUserId).pipe(first(), switchMap(({ authToken, userId }) => {
            if (!userId) {
                throw new Error('User not found.');
            }
            return this.apiGateway
                .get(`comms/newsfeed/posts/lastSeen/associate/${userId}`, authToken)
                .pipe(map(response => {
                return response.lastSeen;
            }));
        }));
    }
}
NewsFeedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NewsFeedService_Factory() { return new NewsFeedService(i0.ɵɵinject(i1.ContentDeliveryService), i0.ɵɵinject(i2.ApiGatewayService), i0.ɵɵinject(i3.Store)); }, token: NewsFeedService, providedIn: "root" });
