import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MyTeamActions } from '../actions';
import {
  catchError,
  debounce,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { EMPTY, forkJoin, interval, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  selectMyTeamFilter,
  selectMyTeamPage,
  selectMyTeamSort
} from '../reducers/my-team.reducer';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../shared/error-handlers/catch-app-error';
import { TeamService } from '../services/team.service';
import { AssociatesService } from '../../shared/services/associates.service';
import { UserService } from '../../shared/services/user.service';
import { selectActiveTeamMemberInfo } from '../reducers/team-member-details.reducer';
import { Associates } from '../../shared/enums/ApiResponseCodes';
import moment from 'moment';
import { MyTeamSortOptions } from '../../core/enums/MyTeamSortOptions';
import { MenuColorAppearance } from '../../shared/enums/Menu';
import { ManageAssociatePhotoComponent } from '../components/manage-associate-photo/manage-associate-photo.component';
import { BottomSheetService } from '../../shared/modules/modal/bottom-sheet/bottom-sheet.service';
import { StringHelpers } from '../../shared/helpers/string.helpers';
import { LayoutActions } from '../../core/actions';
import { DateTimeUtils } from '../../shared/utils/DateTimeUtils';

@Injectable()
export class MyTeamEffects {
  constructor(
    private actions$: Actions,
    private teamService: TeamService,
    private associatesService: AssociatesService,
    private userService: UserService,
    private store: Store<AppState>,
    private bottomSheetService: BottomSheetService,
    @Inject(APP_ERROR_MESSAGES) private errorMessages
  ) {}

  reloadMyTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyTeamActions.changeMyTeamSort, MyTeamActions.changeMyTeamFilter),
      switchMap(() => {
        return of(MyTeamActions.loadMyTeam());
      })
    )
  );

  changeSiteFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyTeamActions.changeSiteFilter),
      withLatestFrom(this.store.select(selectMyTeamFilter)),
      map(([action, filter]) => {
        return MyTeamActions.changeMyTeamFilter({
          filter: {
            ...filter,
            site: action.site
          }
        });
      })
    )
  );

  changeQueryFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyTeamActions.changeQueryFilter),
      debounce(() => interval(300)),
      withLatestFrom(this.store.select(selectMyTeamFilter)),
      map(([action, filter]) => {
        return MyTeamActions.changeMyTeamFilter({
          filter: {
            ...filter,
            query: action.query
          }
        });
      })
    )
  );

  loadTeamMembersPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyTeamActions.changeMyTeamPage),
      withLatestFrom(
        this.store.select(selectMyTeamSort),
        this.store.select(selectMyTeamFilter),
        this.store.select(selectMyTeamPage)
      ),
      switchMap(([action, sort, filter, page]) => {
        return this.loadMyTeam(filter, sort, page, false);
      })
    )
  );

  loadMyTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyTeamActions.loadMyTeam),
      withLatestFrom(
        this.store.select(selectMyTeamSort),
        this.store.select(selectMyTeamFilter),
        this.store.select(selectMyTeamPage)
      ),
      switchMap(([action, sort, filter, page]) => {
        return this.loadMyTeam(filter, sort, page, true);
      })
    )
  );

  loadTeamMemberCheckIns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyTeamActions.loadTeamMemberCheckIns),
      withLatestFrom(this.store.select(selectActiveTeamMemberInfo)),
      switchMap(([action, teamMemberInfo]) => {
        return forkJoin([
          teamMemberInfo == null
            ? this.userService.getUserInfo(action.associateId)
            : of({ teamMemberInfo }),
          this.associatesService.getAssociateWeeklyCheckinList(
            action.associateId
          )
        ]).pipe(
          switchMap(([associateInfo, weeklyCheckinListResponse]) => {
            const items = weeklyCheckinListResponse.weeklyCheckIns;
            const weeklyCheckinList = {
              rollingAverage: weeklyCheckinListResponse.rollingAverage,
              rollingAverageAppearance: this.associatesService.getRollingAverageAppearance(
                weeklyCheckinListResponse.rollingAverage
              ),
              weeklyCheckIns: items.map(item =>
                this.associatesService.mapWeeklyCheckinListItem(item)
              )
            };

            if (teamMemberInfo == null) {
              return of(
                MyTeamActions.myTeamMemberDetailsLoaded({
                  id: action.associateId,
                  name: StringHelpers.capitalize(associateInfo.fullName),
                  apexId: associateInfo.apexId,
                  isTrainee: associateInfo.isTrainee,
                  profilePhoto: associateInfo.profilePhoto
                }),
                MyTeamActions.teamMemberCheckInsLoaded({
                  associateId: action.associateId,
                  weeklyCheckinList
                })
              );
            }

            return of(
              MyTeamActions.teamMemberCheckInsLoaded({
                associateId: action.associateId,
                weeklyCheckinList
              })
            );
          }),
          catchError(err => {
            if (err.error.message === Associates.ASSOCIATE_NOT_FOUND) {
              return of(MyTeamActions.teamMemberDetailsNotFound());
            }

            return handleAppError(this.errorMessages);
          }),
          finalize(() => {
            this.store.dispatch(
              MyTeamActions.toggleTeamMemberCheckInsIsLoading({
                isLoading: false
              })
            );
          })
        );
      })
    )
  );

  loadTeamMemberProduction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyTeamActions.loadTeamMemberProduction),
      withLatestFrom(this.store.select(selectActiveTeamMemberInfo)),
      switchMap(([action, teamMemberInfo]) => {
        return forkJoin([
          this.associatesService.getAssociateProduction(action.associateId)
        ]).pipe(
          switchMap(([productionDetails]) => {
            return of(
              MyTeamActions.myTeamMemberDetailsLoaded({
                id: action.associateId,
                name: StringHelpers.capitalize(
                  productionDetails.associateInfo.fullName
                ),
                apexId: productionDetails.associateInfo.apexId,
                isTrainee: productionDetails.associateInfo.isTrainee,
                profilePhoto: productionDetails.associateInfo.profilePhoto
              }),
              MyTeamActions.teamMemberProductionLoaded({
                associateId: action.associateId,
                productionDetails
              })
            );
          }),
          catchError(err => {
            if (err.error.message === Associates.ASSOCIATE_NOT_FOUND) {
              return of(MyTeamActions.teamMemberDetailsNotFound());
            }

            return handleAppError(this.errorMessages);
          }),
          finalize(() => {
            this.store.dispatch(
              MyTeamActions.toggleTeamMemberProductionIsLoading({
                isLoading: false
              })
            );
          })
        );
      })
    )
  );

  deleteTeamMemberPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyTeamActions.deleteTeamMemberPhoto),
      switchMap(action => {
        return this.bottomSheetService
          .open(ManageAssociatePhotoComponent, {
            data: {
              fullName: action.teamMember.name,
              photo: action.teamMember.profilePhoto
            }
          })
          .afterClosed()
          .pipe(
            tap(() =>
              this.store.dispatch(
                LayoutActions.toggleAppLoading({ loading: true })
              )
            ),
            switchMap(confirmation => {
              if (confirmation && confirmation.success) {
                this.store.dispatch(
                  MyTeamActions.toggleTeamMemberPhotoIsRemoving({
                    apexId: action.teamMember.apexId,
                    isRemoving: true
                  })
                );
                return this.associatesService
                  .deletePhoto(action.teamMember.id)
                  .pipe(
                    map(() =>
                      MyTeamActions.teamMemberPhotoDeleted({
                        apexId: action.teamMember.apexId
                      })
                    ),
                    catchError(handleAppError(this.errorMessages)),
                    finalize(() => {
                      this.store.dispatch(
                        MyTeamActions.toggleTeamMemberPhotoIsRemoving({
                          apexId: action.teamMember.apexId,
                          isRemoving: false
                        })
                      );
                    })
                  );
              }
              return EMPTY;
            }),
            finalize(() =>
              this.store.dispatch(
                LayoutActions.toggleAppLoading({ loading: false })
              )
            )
          );
      })
    )
  );

  private loadMyTeam(filter, sort, page, reloadList) {
    if (!filter.site) {
      return EMPTY;
    }

    const sixWeekEndDate = DateTimeUtils.getSixWeeksEndDate();
    return this.teamService
      .loadSiteTeam(filter.site, filter.query, sort, page)
      .pipe(
        map(myTeamResponse => {
          const teamMembers = myTeamResponse.teamMembers.map(tm => {
            return {
              ...tm,
              id: tm.apexId,
              fullName: StringHelpers.capitalize(tm.fullName),
              isTrainee: this.associatesService.isAssociateTrainee(
                tm.tenure,
                tm.trainingStatus
              ),
              lastWeeklyCheckInScore: moment
                .utc(tm.lastWeeklyCheckInTaken)
                .isSameOrAfter(moment(sixWeekEndDate).startOf())
                ? tm.lastWeeklyCheckInScore
                : null,
              subtitleColor:
                sort.active === MyTeamSortOptions.LastWeeklyCheckInScore &&
                tm.lastWeeklyCheckInScore != null &&
                tm.lastWeeklyCheckInScore < 4
                  ? MenuColorAppearance.Red
                  : MenuColorAppearance.Black
            };
          });

          return MyTeamActions.myTeamLoaded({
            items: teamMembers,
            totalCount: myTeamResponse.pagination.totalItems,
            fillCurrentItems: !reloadList
          });
        }),
        catchError(handleAppError(this.errorMessages)),
        finalize(() => {
          this.store.dispatch(
            MyTeamActions.toggleMyTeamIsLoading({ isLoading: false })
          );
        })
      );
  }
}
