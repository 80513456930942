import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss']
})
export class NoInternetComponent {

  @Input()
  message: string;

  @Input()
  link: string;

  constructor() {}
}
