import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkComponent } from './work.component';
import { SharedComponentsModule } from '../../../shared/components/shared-components/shared-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentCopyComponent } from './components/document-copy/document-copy.component';
import { DocumentComponent } from './components/document/document.component';
import { RouterModule } from '@angular/router';
import { MultiStepFormModule } from '../../../shared/components/multi-step-form/multi-step-form.module';
import { PersonalDetailsReadonlyComponent } from './components/personal-details/personal-details-readonly/personal-details-readonly.component';
import { PersonalDetailsEditComponent } from './components/personal-details/personal-details-edit/personal-details-edit.component';
import { ContactInformationReadonlyComponent } from './components/contact-information/contact-information-readonly/contact-information-readonly.component';
import { ContactInformationEditComponent } from './components/contact-information/contact-information-edit/contact-information-edit.component';
import { LicenceReadonlyComponent } from './components/licence/licence-readonly/licence-readonly.component';
import { LicenceEditComponent } from './components/licence/licence-edit/licence-edit.component';
import { CertificationReadonlyComponent } from './components/certification/certification-readonly/certification-readonly.component';
import { CertificationEditComponent } from './components/certification/certification-edit/certification-edit.component';
import { SignatureEditComponent } from './components/signature/signature-edit/signature-edit.component';
import { PipesModule } from '../../../shared/pipes/pipes.module';
import { SkipInsuranceComponent } from './components/skip-insurance/skip-insurance.component';
import { OpportunitiesDetailsContainerComponent } from './containers/opportunities-details/opportunities-details-container.component';
import { OpportunitiesService } from '../../../core/contracts/OpportunitiesService';
import { StoreModule } from '@ngrx/store';
import * as fromOpportunityDetails from '../../modules/work/reducers/opportunity-details.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OpportunitiesEffects } from './effects/opportunities.effects';
import { OpportunityDetailsComponent } from './components/opportunity-details/opportunity-details.component';
import { MultiStepFormDemoComponent } from '../../../../stories/demo/multi-step-form-demo.component';
import { OpenPositionsComponent } from './components/open-positions/open-positions.component';
import { HasCertificatePipe } from './pipes/has-certificate.pipe';
import { OpportunitiesComponent } from './containers/opportunities/opportunities.component';
import { TabsModule } from '../../../shared/components/tabs/tabs.module';
import * as fromOpportunities from '../../modules/work/reducers/opportunities.reducer';
import * as fromOpportunitiesList from '../../modules/work/reducers/opportunities-list.reducer';
import { OpeningsComponent } from './components/openings/openings.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { WorkRoutingModule } from './work-routing.module';
import { ApplicationFormService } from './services/application-form.service';
import { RoadCrewApplicationService } from '../../../core/contracts/RoadCrewApplicationService';
import { FormUtilitiesService } from './services/form-utilities.service';
import { SkipCertificationsComponent } from './components/skip-certifications/skip-certifications.component';
import { CancelApplicationComponent } from './components/cancel-application/cancel-application.component';
import { RoadCrewApplicationContainerComponent } from './containers/road-crew-application/road-crew-application-container.component';
import { OpportunitiesListComponent } from './components/opportunities-list/opportunities-list.component';
import { OpportunitiesListContainerComponent } from './containers/opportunities-list/opportunities-list-container.component';
import { ConfirmLocationComponent } from './components/confirm-location/confirm-location.component';
import { UpdateLocationComponent } from './components/update-location/update-location.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { OpportunitiesListFilterComponent } from './components/opportunities-list-filter/opportunities-list-filter.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { DurationFilterComponent } from './components/duration-filter/duration-filter.component';
import { JobTitleFilterComponent } from './components/job-title-filter/job-title-filter.component';
import { A11yModule } from '@angular/cdk/a11y';
import { RegionFilterComponent } from './components/region-filter/region-filter.component';
import { RegionFilterUtilsService } from './services/region-filter-utils.service';
import { FilterModalLayoutComponent } from './components/filter-modal-layout/filter-modal-layout.component';
import { UploadPreviewComponent } from './components/upload-preview/upload-preview.component';
import { OpportunitiesApiService } from './services/opportunities-api.service';
import { RoadCrewApplicationApiService } from './services/road-crew-application.api.service';

@NgModule({
  declarations: [
    WorkComponent,
    DocumentComponent,
    DocumentCopyComponent,
    PersonalDetailsReadonlyComponent,
    PersonalDetailsEditComponent,
    ContactInformationReadonlyComponent,
    ContactInformationEditComponent,
    LicenceReadonlyComponent,
    LicenceEditComponent,
    CertificationReadonlyComponent,
    CertificationEditComponent,
    SignatureEditComponent,
    SkipInsuranceComponent,
    OpportunitiesDetailsContainerComponent,
    OpportunityDetailsComponent,
    MultiStepFormDemoComponent,
    OpenPositionsComponent,
    HasCertificatePipe,
    SkipCertificationsComponent,
    HasCertificatePipe,
    OpportunitiesComponent,
    OpeningsComponent,
    ApplicationsComponent,
    OpportunitiesListContainerComponent,
    CancelApplicationComponent,
    RoadCrewApplicationContainerComponent,
    OpportunitiesListComponent,
    DateFilterComponent,
    OpportunitiesListFilterComponent,
    DurationFilterComponent,
    JobTitleFilterComponent,
    ConfirmLocationComponent,
    UpdateLocationComponent,
    RegionFilterComponent,
    FilterModalLayoutComponent,
    UploadPreviewComponent
  ],
  imports: [
    WorkRoutingModule,
    CommonModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MultiStepFormModule,
    PipesModule,
    StoreModule.forFeature(
      fromOpportunityDetails.opportunityDetailsFeatureKey,
      fromOpportunityDetails.reducer
    ),
    StoreModule.forFeature(
      fromOpportunities.opportunitiesFeatureKey,
      fromOpportunities.reducer
    ),
    StoreModule.forFeature(
      fromOpportunitiesList.opportunitiesListFeatureKey,
      fromOpportunitiesList.reducer
    ),
    EffectsModule.forFeature([OpportunitiesEffects]),
    WorkRoutingModule,
    TabsModule,
    AngularMyDatePickerModule,
    A11yModule
  ],
  entryComponents: [
    DocumentCopyComponent,
    SkipInsuranceComponent,
    SkipCertificationsComponent,
    CancelApplicationComponent,
    ConfirmLocationComponent,
    UpdateLocationComponent,
    DateFilterComponent,
    DurationFilterComponent,
    RegionFilterComponent,
    JobTitleFilterComponent
  ],
  exports: [OpportunitiesComponent],
  providers: [
    {
      provide: OpportunitiesService,
      useClass: OpportunitiesApiService
    },
    {
      provide: RoadCrewApplicationService,
      useClass: RoadCrewApplicationApiService
    },
    ApplicationFormService,
    FormUtilitiesService,
    RegionFilterUtilsService
  ]
})
export class WorkModule {}
