<div class="sidenav__header">
  <ng-content select="[slot='header']"></ng-content>
</div>
<div class="sidenav__content">
  <ng-content select="[slot='content']"></ng-content>
</div>
<div class="sidenav__footer footer">
  <div class="footer__bottom-bar">
    <ul class="reset-list-style" slot="content">
      <app-profile-link-item
        role="listitem"
        [link]="'settings'"
        [iconName]="SvgIconNameEnum.Settings"
        [iconColorPrimary]="SvgIconColorEnum.Horizon"
        [iconColorSecondary]="SvgIconColorEnum.Spindle"
        title="Settings"
        i18n-title="@@settings"
        data-cy="settings"
      ></app-profile-link-item>
      <app-profile-link-item
        role="listitem"
        (buttonClick)="logout()"
        [iconName]="SvgIconNameEnum.Logout"
        [iconColorPrimary]="SvgIconColorEnum.Horizon"
        [iconColorSecondary]="SvgIconColorEnum.Spindle"
        title="Log Out"
        i18n-title="@@sidebar_logOut"
        data-cy="logout"
      ></app-profile-link-item>
    </ul>

    <span class="footer__version">{{ appVersion }}</span>
  </div>
</div>
