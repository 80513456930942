<app-page-header-container></app-page-header-container>

<main class="main-container">
  <app-loader [isLoading]="isPostLoading$ | async"></app-loader>

  <article *ngIf="(isPostLoading$ | async) === false && post" class="blog-post">
    <app-post-image
      class="blog-post__image"
      [image]="post.postImage"
      [title]="post.title"
    ></app-post-image>
    <div class="blog-post__content">
      <app-post-category
        [category]="post.category"
        [showName]="true"
      ></app-post-category>
      <h1 class="blog-post__heading">{{ post.title }}</h1>
      <div
        class="blog-post__post contentful-content"
        [innerHTML]="content"
      ></div>
      <app-post-profile [post]="post"></app-post-profile>
      <div class="blog-post__separator"></div>
      <div class="blog-post__actions">
        <button
          *ngIf="post.isLiked !== undefined && post.likes !== undefined"
          type="button"
          [ngClass]="{
            like: true,
            'like--pressed': post.isLiked
          }"
          [attr.aria-pressed]="post.isLiked"
          (click)="onLikeEvent()"
        >
          <span class="like__title">{{ post.likes }}</span>
          <app-svg-icon
            [name]="SvgIconNameEnum.Like"
            class="like__icon"
          ></app-svg-icon>
        </button>
        <app-button-link
          class="read-more"
          i18n-label="@@blog-post_backToNewsFeed"
          label="Back to Team News"
          [link]="['/news']"
          [appearance]="ButtonAppearanceEnum.Link"
        ></app-button-link>
      </div>
    </div>
  </article>

  <ng-template #emptyStateTranslation i18n="@@blog-post_noDataFound">
    The resource could not be found.
  </ng-template>

  <app-empty-state
    *ngIf="
      (isPostErrorOccurred$ | async) ||
      ((isAppOnline$ | async) === false && post === null)
    "
    [emptyStateTemplate]="emptyStateTranslation"
  ></app-empty-state>
</main>
