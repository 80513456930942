import { Action, createReducer, on } from '@ngrx/store';
import { ResourcesActions } from '../actions';
import { LayoutActions, UserActions } from '../../core/actions';
import { Directory } from '../../core/dataEntities/resources/directory';

export const resourcesFeatureKey = 'resources';

export interface ResourcesState {
  isDirectoryLoading: boolean;
  directory: Directory;
}

export const initialState: ResourcesState = {
  isDirectoryLoading: false,
  directory: null
};

export const resourcesReducer = createReducer(
  initialState,
  on(ResourcesActions.loadDirectory, (state, action) => {
    return {
      ...state,
      isDirectoryLoading: true
    };
  }),
  on(ResourcesActions.directoryLoaded, (state, action) => {
    const contacts = [];

    if (action.directory === null) {
      return {
        ...state,
        isDirectoryLoading: false
      };
    }

    if (action.directory.siteManagers) {
      contacts.push.apply(contacts, action.directory.siteManagers);
    }
    if (action.directory.director) {
      contacts.push(action.directory.director);
    }
    if (action.directory.seniorDirector) {
      contacts.push(action.directory.seniorDirector);
    }
    if (action.directory.vicePresident) {
      contacts.push(action.directory.vicePresident);
    }

    return {
      ...state,
      isDirectoryLoading: false,
      directory: {
        contacts
      }
    };
  }),
  on(LayoutActions.showError, LayoutActions.apiOffline, state => {
    return {
      ...state,
      isFaqLoading: false,
      isDirectoryLoading: false
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  })
);

export function reducer(state: ResourcesState | undefined, action: Action) {
  return resourcesReducer(state, action);
}

export const selectDirectory = (state: ResourcesState) => {
  return state.directory;
};

export const selectIsDirectoryLoading = (state: ResourcesState) => {
  return state.isDirectoryLoading;
};
