/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preview-feed-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/feed/news-feed-card/news-feed-card.component.ngfactory";
import * as i3 from "../../../shared/components/feed/news-feed-card/news-feed-card.component";
import * as i4 from "../../../shared/components/empty-state/empty-state.component.ngfactory";
import * as i5 from "../../../shared/components/empty-state/empty-state.component";
import * as i6 from "../../../shared/services/translation.service";
import * as i7 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i8 from "../../../shared/components/loader/loader.component";
import * as i9 from "@angular/common";
import * as i10 from "./preview-feed-card.component";
import * as i11 from "@angular/router";
import * as i12 from "../../services/news-feed.service";
var styles_PreviewFeedCardComponent = [i0.styles];
var RenderType_PreviewFeedCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviewFeedCardComponent, data: {} });
export { RenderType_PreviewFeedCardComponent as RenderType_PreviewFeedCardComponent };
function View_PreviewFeedCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "article", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-news-feed-card", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NewsFeedCardComponent_0, i2.RenderType_NewsFeedCardComponent)), i1.ɵdid(2, 4308992, null, 0, i3.NewsFeedCardComponent, [], { post: [0, "post"], ariaPosinset: [1, "ariaPosinset"], ariaSetsize: [2, "ariaSetsize"], previewMode: [3, "previewMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.post; var currVal_1 = 0; var currVal_2 = 0; var currVal_3 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PreviewFeedCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" The resource could not be found. Check if all required fields are filled.\n"]))], null, null); }
function View_PreviewFeedCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-state", [], null, null, null, i4.View_EmptyStateComponent_0, i4.RenderType_EmptyStateComponent)), i1.ɵdid(1, 114688, null, 0, i5.EmptyStateComponent, [i6.TranslationService], { emptyStateTemplate: [0, "emptyStateTemplate"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 6); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PreviewFeedCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-loader", [], [[2, "is-visible", null]], null, null, i7.View_LoaderComponent_0, i7.RenderType_LoaderComponent)), i1.ɵdid(1, 114688, null, 0, i8.LoaderComponent, [], { isLoading: [0, "isLoading"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PreviewFeedCardComponent_1)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["emptyStateTranslation", 2]], null, 0, null, View_PreviewFeedCardComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_PreviewFeedCardComponent_3)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.isPostLoading$)); _ck(_v, 1, 0, currVal_1); var currVal_2 = ((i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.isPostLoading$)) === false) && _co.post); _ck(_v, 4, 0, currVal_2); var currVal_3 = ((i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.isPostLoading$)) === false) && (i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 10).transform(_co.isPostErrorOccurred$)) || !_co.post)); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).visible; _ck(_v, 0, 0, currVal_0); }); }
export function View_PreviewFeedCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preview-feed-card", [], null, null, null, View_PreviewFeedCardComponent_0, RenderType_PreviewFeedCardComponent)), i1.ɵdid(1, 245760, null, 0, i10.PreviewFeedCardComponent, [i11.ActivatedRoute, i12.NewsFeedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreviewFeedCardComponentNgFactory = i1.ɵccf("app-preview-feed-card", i10.PreviewFeedCardComponent, View_PreviewFeedCardComponent_Host_0, {}, {}, []);
export { PreviewFeedCardComponentNgFactory as PreviewFeedCardComponentNgFactory };
