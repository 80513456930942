/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./contact-information-readonly.component";
var styles_ContactInformationReadonlyComponent = [];
var RenderType_ContactInformationReadonlyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContactInformationReadonlyComponent, data: {} });
export { RenderType_ContactInformationReadonlyComponent as RenderType_ContactInformationReadonlyComponent };
export function View_ContactInformationReadonlyComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "read-only-details"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "p", [["class", "read-only-details"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "read-only-details__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "read-only-details__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "p", [["class", "read-only-details"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "read-only-details__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" EC: ", " "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "read-only-details__item"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formGroup.get("email").value; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.formGroup.get("phoneNumber").value; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.formGroup.get("homePhoneNumber").value; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.formGroup.get("emergencyContactName").value; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.formGroup.get("emergencyContactPhoneNumber").value; _ck(_v, 11, 0, currVal_4); }); }
export function View_ContactInformationReadonlyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-contact-information-readonly", [], null, null, null, View_ContactInformationReadonlyComponent_0, RenderType_ContactInformationReadonlyComponent)), i0.ɵdid(1, 114688, null, 0, i1.ContactInformationReadonlyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactInformationReadonlyComponentNgFactory = i0.ɵccf("app-contact-information-readonly", i1.ContactInformationReadonlyComponent, View_ContactInformationReadonlyComponent_Host_0, { formGroup: "formGroup" }, {}, []);
export { ContactInformationReadonlyComponentNgFactory as ContactInformationReadonlyComponentNgFactory };
