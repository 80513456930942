import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { LayoutActions, UserActions } from '../../../../core/actions';
import { GoalActions } from '../actions';
import { GoalDetails } from '../../../../core/dataEntities/goal/goalDetails';
import { WagesActions } from '../../../actions';

export const goalFeatureKey = 'goal';

export interface GoalState extends EntityState<GoalDetails> {
  activeWeekId: string;
  isGoalLoading: boolean;
}

export const adapter: EntityAdapter<GoalDetails> = createEntityAdapter<
  GoalDetails
>();

export const initialState: GoalState = adapter.getInitialState({
  activeWeekId: null,
  isGoalLoading: false
});

export const goalReducer = createReducer(
  initialState,
  on(GoalActions.goalLoaded, (state, action) => {
    return adapter.upsertOne(action.goal, {
      ...state,
      isGoalLoading: false
    });
  }),
  on(GoalActions.goalSubmitted, (state, action) => {
    const updateGoal = {
      id: action.goal.id,
      changes: action.goal
    };

    return adapter.updateOne(updateGoal, {
      ...state,
      isGoalLoading: false
    });
  }),
  on(GoalActions.toggleGoalLoading, (state, action) => {
    return {
      ...state,
      isGoalLoading: !state.isGoalLoading
    };
  }),
  on(LayoutActions.showError, LayoutActions.apiOffline, state => {
    return {
      ...state,
      isGoalLoading: false
    };
  }),
  on(UserActions.userLogout, (state, action) => {
    return initialState;
  }),
  on(WagesActions.loadPaymentWeekDetails, (state, action) => {
    return {
      ...state,
      activeWeekId: action.weekEndingDate
    };
  })
);

export function reducer(state: GoalState | undefined, action: Action) {
  return goalReducer(state, action);
}

const { selectEntities } = adapter.getSelectors();
export const selectAllGoals = selectEntities;

export const selectActiveWeekId = (state: GoalState) => {
  return state.activeWeekId;
};

export const selectIsGoalLoading = (state: GoalState) => {
  return state.isGoalLoading;
};

export const getActiveWeekGoal = createSelector(
  selectAllGoals,
  selectActiveWeekId,
  (goals, activeWeekId) => {
    return goals[activeWeekId];
  }
);
