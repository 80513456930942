/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bottom-sheet.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/portal";
import * as i3 from "./bottom-sheet.component";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/common";
import * as i6 from "../dialog-config";
var styles_BottomSheetComponent = [i0.styles];
var RenderType_BottomSheetComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BottomSheetComponent, data: { "animation": [{ type: 7, name: "dialogContainer", definitions: [{ type: 0, name: "void, exit", styles: { type: 6, styles: { transform: "translateY(100%)" }, offset: null }, options: undefined }, { type: 0, name: "enter", styles: { type: 6, styles: { transform: "translateY(0%)" }, offset: null }, options: undefined }, { type: 1, expr: "* => enter", animation: [{ type: 4, styles: null, timings: "225ms cubic-bezier(0.0,0.0,0.2,1)" }], options: null }, { type: 1, expr: "* => void, * => exit", animation: [{ type: 4, styles: null, timings: "375ms cubic-bezier(0.4,0.0,1,1)" }], options: null }], options: {} }] } });
export { RenderType_BottomSheetComponent as RenderType_BottomSheetComponent };
function View_BottomSheetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_BottomSheetComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { portalOutlet: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomSheetComponent_1)), i1.ɵdid(2, 212992, [[1, 4]], 0, i2.CdkPortalOutlet, [i1.ComponentFactoryResolver, i1.ViewContainerRef], { portal: [0, "portal"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BottomSheetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bottom-sheet", [], [[2, "bottom-sheet-container", null], [2, "bottom-sheet-container--full-screen", null], [1, "tabindex", 0], [1, "aria-modal", 0], [1, "id", 0], [1, "role", 0], [1, "aria-labelledby", 0], [1, "aria-label", 0], [1, "aria-describedby", 0], [40, "@dialogContainer", 0]], [["component", "@dialogContainer.done"], ["component", "@dialogContainer.start"]], function (_v, en, $event) { var ad = true; if (("component:@dialogContainer.done" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onAnimationDone($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@dialogContainer.start" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onAnimationStart($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_BottomSheetComponent_0, RenderType_BottomSheetComponent)), i1.ɵdid(1, 49152, null, 0, i3.BottomSheetComponent, [i1.ElementRef, i4.FocusTrapFactory, i1.ChangeDetectorRef, [2, i5.DOCUMENT], i6.DialogConfig], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).class; var currVal_1 = i1.ɵnov(_v, 1).fullScreen; var currVal_2 = i1.ɵnov(_v, 1).tabindex; var currVal_3 = i1.ɵnov(_v, 1).ariaModal; var currVal_4 = i1.ɵnov(_v, 1).attrId; var currVal_5 = i1.ɵnov(_v, 1).attrRole; var currVal_6 = i1.ɵnov(_v, 1).attrAriaLabelBy; var currVal_7 = i1.ɵnov(_v, 1).attrAriaLabel; var currVal_8 = i1.ɵnov(_v, 1).attrAriaDescribedBy; var currVal_9 = i1.ɵnov(_v, 1).dialogContainer; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
var BottomSheetComponentNgFactory = i1.ɵccf("app-bottom-sheet", i3.BottomSheetComponent, View_BottomSheetComponent_Host_0, {}, {}, []);
export { BottomSheetComponentNgFactory as BottomSheetComponentNgFactory };
