<div *appFeatureToggle="FeaturesEnum.Opportunities">
  <app-page-title
    [appearance]="PageTitleAppearanceEnum.Transparent"
    title="Road Crew"
    i18n-title="@@opportunities_workOpportunities"
  ></app-page-title>
  <ng-container *ngIf="isRoadCrewEnabled$ | async">
    <app-opportunities
      *appIfHasPermission="[
        RolesEnum.Associate,
        RolesEnum.SiteLeads,
        RolesEnum.SiteClerks
      ]"
      [associateHomeState]="associateHomeState"
    ></app-opportunities>
  </ng-container>

  <ng-template #emptyStateRoadCrewDynamic>
    {{ cantRegisterForRoadCrewMsg | translate }}
  </ng-template>

  <app-empty-state
    *ngIf="(isRoadCrewEnabled$ | async) === false"
    [emptyStateTemplate]="emptyStateRoadCrewDynamic"
  ></app-empty-state>

  <ng-template #emptyStateRoadCrewStatic>
    {{ 'roadCrewCantRegisterThree' | translate }}
  </ng-template>

  <app-empty-state
    *appIfHasPermission="[
      RolesEnum.SiteManager,
      RolesEnum.SiteSupervisor,
      RolesEnum.None
    ]"
    [emptyStateTemplate]="emptyStateRoadCrewStatic"
  ></app-empty-state>
</div>
