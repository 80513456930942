import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { OpportunityDetails } from '../../../../../core/dataEntities/opportunities/opportunityDetails';
import { SvgIconColor, SvgIconName } from '../../../../../shared/enums/SvgIcon';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';
import { JobStatus } from '../../../../../shared/enums/JobStatus';
import { PageSectionHeaderAppearance } from '../../../../../shared/enums/PageSectionHeader';
import { JobSubStatus } from '../../../../../shared/enums/JobSubStatus';
import { ApplicationFormStatus } from '../../../../../core/enums/ApplicationFormStatus';

@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
  styleUrls: ['./opportunity-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityDetailsComponent implements OnInit {
  public SvgIconEnum = SvgIconName;
  public SvgIconColorEnum = SvgIconColor;
  public ButtonAppearanceEnum = ButtonAppearance;
  public PageSectionHeaderAppearanceEnum = PageSectionHeaderAppearance;
  public ButtonColorEnum = ButtonColor;
  public JobStatusEnum = JobStatus;
  public JobSubStatusEnum = JobSubStatus;
  public ApplicationFormStatusEnum = ApplicationFormStatus;

  @Input() opportunityDetails: OpportunityDetails;
  @Input() userCertificates: string[];
  @Input() hasApplicationFormSubmitted: boolean;
  @Input() applicationFormStatus: ApplicationFormStatus;
  @Input() userEmail: string;

  @Output() apply = new EventEmitter();
  @Output() updateDetails = new EventEmitter();
  @Output() register = new EventEmitter();
  @Output() cancel = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  applyToOpportunity() {
    if (this.opportunityDetails.canApply) {
      this.apply.emit();
    }
  }
}
