<input
  [ngClass]="{
    option__input: true
  }"
  type="radio"
  [id]="inputId"
  [checked]="checked"
  [attr.name]="name"
  [attr.value]="value"
  [attr.aria-invalid]="isInvalid"
  [attr.aria-describedby]="isInvalid ? errorId : null"
  [attr.disabled]="disabled ? '' : null"
  (change)="onInputChange($event)"
/>
<label class="option__label" [attr.for]="inputId">
  <ng-content></ng-content>
</label>
