<div class="qr">
  <h1 class="qr__title">
    ID# {{ data.apexId }}
  </h1>
  <div
    class="qrcode"
    [innerHTML]="innerHtml"
    data-cy="qrCodeModal"
  ></div>
  <p class="qr__description" i18n="@@qr-code_Description">
    Use your QR code to scan in and out at work.
  </p>
</div>
