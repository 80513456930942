<div class="post-image">
  <img
    *ngIf="image"
    class="post-image__src"
    [attr.src]="imageUrl"
    alt="{{ title }}"
  />
  <div
    *ngIf="type === PostCategoryTypeEnum.SafetyTip"
    [ngClass]="{
      'post-safety-tip': true,
      'post-safety-tip--equipment': subtype === PostSafetyTipTypeEnum.Equipment,
      'post-safety-tip--environment':
        subtype === PostSafetyTipTypeEnum.Environment,
      'post-safety-tip--manual-labor':
        subtype === PostSafetyTipTypeEnum.ManualLabor,
      'post-safety-tip--general-safety':
        subtype === PostSafetyTipTypeEnum.GeneralSafety
    }"
  >
    <h2
      class="post-safety-tip__heading"
      [attr.id]="'feed-card-heading-' + postId"
    >
      {{ title }}
    </h2>
  </div>
</div>
