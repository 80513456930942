import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { Post } from '../../../../core/dataEntities/feed/post';
import { SvgIconName } from '../../../enums/SvgIcon';

@Component({
  selector: 'app-post-profile',
  templateUrl: './post-profile.component.html',
  styleUrls: ['./post-profile.component.scss']
})
export class PostProfileComponent implements OnInit {
  @Input() post: Post;
  @Input() isPinnedPost = false;

  SvgIconNameEnum = SvgIconName;

  constructor() {}

  ngOnInit() {}

  momentsAgo(date: string) {
    let diff = moment().diff(moment(date), 'minutes');

    if (diff <= 30) {
      return 0;
    }

    if (diff <= 60) {
      return 1;
    }

    diff = moment().diff(moment(date), 'hours');

    if (diff <= 6) {
      return 2;
    }

    return diff;
  }

  hoursAgo(date: string) {
    return moment().diff(moment(date), 'hours');
  }
}
