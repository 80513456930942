import * as __NgCli_locale_1 from "@angular/common/locales/en";
import * as __NgCli_locale_2 from "@angular/common";
__NgCli_locale_2.registerLocaleData(__NgCli_locale_1.default);
import { enableProdMode } from '@angular/core';
import 'hammerjs';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(err => console.error(err));
const oldXHROpen = window.XMLHttpRequest.prototype.open;
window.XMLHttpRequest.prototype.open = function (method, url, async, user, password) {
    if (url === 'https://dc.services.visualstudio.com/v2/track' &&
        !window.navigator.onLine) {
        oldXHROpen.abort();
    }
    return oldXHROpen.apply(this, arguments);
};
