import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../shared/components/shared-components/shared-components.module';
import { ResourcesComponent } from './resources.component';
import { ResourcesRoutingModule } from './resources-routing.module';
import { ResourcesContainerComponent } from './containers/resources-container/resources-container.component';
import { EffectsModule } from '@ngrx/effects';
import { ResourcesEffects } from './effects/resources.effects';
import { TeamDirectoryComponent } from './components/team-directory/team-directory.component';
import { ContactInfoComponent } from './components/team-directory/contact-info/contact-info.component';
import { NgxMaskModule } from 'ngx-mask';
import { StarsFeedContainerComponent } from './containers/stars-feed/stars-feed-container.component';
import { StarsFeedEffects } from './effects/stars-feed.effects';
import { DirectivesModule } from '../core/directives/directives.module';

@NgModule({
  declarations: [
    ResourcesComponent,
    ResourcesContainerComponent,
    TeamDirectoryComponent,
    ContactInfoComponent,
    StarsFeedContainerComponent
  ],
  imports: [
    SharedComponentsModule,
    ResourcesRoutingModule,
    EffectsModule.forFeature([ResourcesEffects, StarsFeedEffects]),
    NgxMaskModule.forRoot(),
    DirectivesModule
  ],
  exports: [
    ResourcesComponent,
    ResourcesContainerComponent,
    TeamDirectoryComponent,
    ContactInfoComponent,
    StarsFeedContainerComponent
  ],
  entryComponents: []
})
export class ResourcesModule {}
