/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./about-weekly-observations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../modal/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../modal/modal-layout/modal-layout.component";
import * as i4 from "../../modal/modal-header/modal-header.component.ngfactory";
import * as i5 from "../../modal/modal-header/modal-header.component";
import * as i6 from "../../modal/modal-content-row/modal-content-row.component.ngfactory";
import * as i7 from "../../modal/modal-content-row/modal-content-row.component";
import * as i8 from "./about-weekly-observations.component";
import * as i9 from "../../../modules/modal/dialog-ref";
var styles_AboutWeeklyObservationsComponent = [i0.styles];
var RenderType_AboutWeeklyObservationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AboutWeeklyObservationsComponent, data: {} });
export { RenderType_AboutWeeklyObservationsComponent as RenderType_AboutWeeklyObservationsComponent };
export function View_AboutWeeklyObservationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "app-modal-layout", [], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalLayoutComponent, [], null, { closeModal: "closeModal" }), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-modal-header", [["primaryText", "Weekly"], ["secondaryText", "Observations"], ["slot", "modal-header"]], null, null, null, i4.View_ModalHeaderComponent_0, i4.RenderType_ModalHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i5.ModalHeaderComponent, [], { primaryText: [0, "primaryText"], secondaryText: [1, "secondaryText"] }, null), (_l()(), i1.ɵeld(4, 0, null, 1, 8, "div", [["slot", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "app-modal-content-row", [], null, null, null, i6.View_ModalContentRowComponent_0, i6.RenderType_ModalContentRowComponent)), i1.ɵdid(6, 114688, null, 0, i7.ModalContentRowComponent, [], { sideSpacing: [0, "sideSpacing"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" When you practicing safe work, everyone stays safer. "])), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" During your training, supervisors and managers will make 10 observations per week where they will offer safety tips and guidance. "])), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Once your training is complete, supervisors and managers will make 5 observations per week. "]))], function (_ck, _v) { var currVal_0 = "Weekly"; var currVal_1 = "Observations"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = true; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AboutWeeklyObservationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-about-weekly-observations", [], null, null, null, View_AboutWeeklyObservationsComponent_0, RenderType_AboutWeeklyObservationsComponent)), i1.ɵdid(1, 49152, null, 0, i8.AboutWeeklyObservationsComponent, [i9.DialogRef], null, null)], null, null); }
var AboutWeeklyObservationsComponentNgFactory = i1.ɵccf("app-about-weekly-observations", i8.AboutWeeklyObservationsComponent, View_AboutWeeklyObservationsComponent_Host_0, {}, {}, []);
export { AboutWeeklyObservationsComponentNgFactory as AboutWeeklyObservationsComponentNgFactory };
