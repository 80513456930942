import { Injectable } from '@angular/core';

import { flow, filter, map, flatten, find, reduce } from 'lodash/fp';

@Injectable()
export class RegionFilterUtilsService {
  constructor() {}

  mapRegionToStatesCount(
    regions,
    states: { locationState: string; count: string }[]
  ) {
    return regions.map(region => {
      return {
        value: region.id,
        label: region.label,
        statesLabels: region.states.map(item => {
          return item['label'];
        }),
        count: flow(
          filter(state =>
            find(regionState => regionState.code === state.locationState)(
              region.states
            )
          ),
          reduce((a, b) => a + (Number(b['count']) || 0), 0)
        )(states)
      };
    });
  }

  getStateValuesByRegion(regionValue, regions) {
    return flow(
      filter(region => region.id === regionValue),
      map(region => map(item => item['code'])(region.states)),
      flatten
    )(regions);
  }
}
