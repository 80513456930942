<app-page-header-container
  [appearance]="PageHeaderAppearanceEnum.Green"
></app-page-header-container>
<main class="main-container">
  <ng-container
    *ngIf="
      (isOpportunityDetailsLoading$ | async) === false && opportunityDetails$
        | async as opportunityDetails
    "
  >
    <ng-template #weeksTemplate>
      <div *ngIf="!opportunityDetails?.postingBySkillset">
        <span
          *ngIf="
            opportunityDetails?.duration && opportunityDetails?.duration !== '0'
          "
          i18n="@@opportunity-details-container_weeksInTitle"
        >
          {{ opportunityDetails?.duration }}+ {opportunityDetails?.duration,
          plural, =1 {Week In} other {Weeks In}}
        </span>
        <span
          *ngIf="
            opportunityDetails?.duration && opportunityDetails?.duration === '0'
          "
          i18n="@@opportunity-details-container_weeksInTitleAlt"
        >
          Less Than a Week In
        </span>
        <span style="text-transform:capitalize">
          {{
            StringHelpers.joinStrings(
              opportunityDetails.location?.city,
              opportunityDetails.location?.stateName
            )
          }}
        </span>
      </div>
      <div *ngIf="opportunityDetails?.postingBySkillset">
        <span
          style="text-transform:capitalize"
          i18n="@@opportunities-details_NationalTitle"
        >
          National opportunity for
        </span>
        <span style="text-transform:capitalize">
          {{ opportunityDetails.skillsetName }}
        </span>
        <span
          *ngIf="
            opportunityDetails?.duration && opportunityDetails?.duration !== '0'
          "
          i18n="@@opportunities-details_NationalTitleWeeks"
        >
          {{ opportunityDetails?.duration }}+ {opportunityDetails?.duration,
          plural, =1 {Week} other {Weeks}}
        </span>
        <span
          *ngIf="
            opportunityDetails?.duration && opportunityDetails?.duration === '0'
          "
          i18n="@@opportunities-details_NationalTitleWeeksAlt"
        >
          Less Than a Week
        </span>
      </div>
    </ng-template>

    <app-page-title
      [appearance]="PageTitleAppearanceEnum.Green"
      [titleTemplate]="weeksTemplate"
    ></app-page-title>
    <div class="opportunities-details">
      <app-opportunity-details
        [opportunityDetails]="opportunityDetails"
        [userCertificates]="
          applicationForm ? applicationForm.certificateInfo.certificates : null
        "
        [hasApplicationFormSubmitted]="applicationForm !== null"
        [applicationFormStatus]="applicationFormStatus$ | async"
        [userEmail]="userEmail$ | async"
        (register)="openApplicationForm()"
        (apply)="applyForOpportunity()"
        (updateDetails)="openApplicationForm()"
        (cancel)="cancelAppliedOpportunity()"
      ></app-opportunity-details>
    </div>
  </ng-container>
  <ng-template
    #emptyStateTranslation
    i18n="@@opportunity-details-container_noDataFound"
  >
    Opportunity details not found.
  </ng-template>
  <app-empty-state
    *ngIf="
      (isOpportunityDetailsLoading$ | async) === false &&
      (opportunityDetails$ | async) === null
    "
    [emptyStateTemplate]="emptyStateTranslation"
  ></app-empty-state>
</main>
