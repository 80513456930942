import { Store } from '@ngrx/store';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { AppState, getIsAppOnline } from '../../reducers';
import { filter, first, switchMap } from 'rxjs/operators';
import { LayoutActions } from '../../core/actions';

@Injectable()
export class IsOnlineInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.store.select(getIsAppOnline).pipe(
      filter(isAppOnline => isAppOnline != null),
      first(),
      switchMap(isAppOnline => {
        if (!isAppOnline) {
          this.store.dispatch(LayoutActions.apiOffline());
          return EMPTY;
        }
        return next.handle(request);
      })
    );
  }
}
