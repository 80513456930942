import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { SvgIconName } from 'src/app/shared/enums/SvgIcon';
import { AlertAppearance } from '../../../core/enums/Alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() iconName: SvgIconName;
  @Input() appearance: AlertAppearance;
  @Input() actionLink: string | any[];
  @Input() actionTitle: string;
  @Input() dismissTitle: string;
  @Output() closeAlert = new EventEmitter();

  public AlertAppearanceEnum = AlertAppearance;
  public currentAppearance = AlertAppearance.White;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appearance.currentValue) {
      if (!!this.appearance) {
        this.currentAppearance = this.appearance;
      }
    }
  }

  onDismissAlert() {
    this.closeAlert.emit({ success: false });
  }

  onAction() {
    this.closeAlert.emit({ success: true });
  }
}
