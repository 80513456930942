/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./applications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/components/menu/menu-item/menu-item.component.ngfactory";
import * as i4 from "../../../../../shared/components/menu/menu-item/menu-item.component";
import * as i5 from "../../../../../shared/components/menu/menu-header/menu-header.component.ngfactory";
import * as i6 from "../../../../../shared/components/menu/menu-header/menu-header.component";
import * as i7 from "../../../../../shared/components/menu/menu.component.ngfactory";
import * as i8 from "../../../../../shared/components/menu/menu.component";
import * as i9 from "../../../../../shared/components/empty-state/empty-state.component.ngfactory";
import * as i10 from "../../../../../shared/components/empty-state/empty-state.component";
import * as i11 from "../../../../../shared/services/translation.service";
import * as i12 from "./applications.component";
var styles_ApplicationsComponent = [i0.styles];
var RenderType_ApplicationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApplicationsComponent, data: {} });
export { RenderType_ApplicationsComponent as RenderType_ApplicationsComponent };
function View_ApplicationsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Week In"]))], null, null); }
function View_ApplicationsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Weeks In"]))], null, null); }
function View_ApplicationsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "+ "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_7)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var currVal_1 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 1, 0, currVal_0); }); }
function View_ApplicationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.StringHelpers.joinStrings(((_v.parent.parent.context.$implicit.location == null) ? null : _v.parent.parent.context.$implicit.location.city), ((_v.parent.parent.context.$implicit.location == null) ? null : _v.parent.parent.context.$implicit.location.stateName)); _ck(_v, 3, 0, currVal_1); }); }
function View_ApplicationsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" Status: ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.status; _ck(_v, 0, 0, currVal_0); }); }
function View_ApplicationsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" New "]))], null, null); }
function View_ApplicationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["applicationTitle", 2]], null, 0, null, View_ApplicationsComponent_4)), (_l()(), i1.ɵand(0, [["applicationSubtitle", 2]], null, 0, null, View_ApplicationsComponent_8)), (_l()(), i1.ɵand(0, [["new", 2]], null, 0, null, View_ApplicationsComponent_9)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-menu-item", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.openDetails(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MenuItemComponent_0, i3.RenderType_MenuItemComponent)), i1.ɵdid(5, 114688, [[1, 4]], 0, i4.MenuItemComponent, [], { titleTemplate: [0, "titleTemplate"], subTitleTemplate: [1, "subTitleTemplate"], chipTitleTemplate: [2, "chipTitleTemplate"], chipAppearance: [3, "chipAppearance"], appearance: [4, "appearance"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1); var currVal_1 = i1.ɵnov(_v, 2); var currVal_2 = (_v.parent.context.$implicit.hasUpdates ? i1.ɵnov(_v, 3) : null); var currVal_3 = _co.ChipAppearanceEnum.Red; var currVal_4 = _v.parent.context.$implicit.menuColorAppearance; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ApplicationsComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Week"]))], null, null); }
function View_ApplicationsComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Weeks"]))], null, null); }
function View_ApplicationsComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "+ "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_13)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_14)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var currVal_1 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 1, 0, currVal_0); }); }
function View_ApplicationsComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Less Than a Week "]))], null, null); }
function View_ApplicationsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" National opportunity for "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_12)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_15)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) && (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) !== "0")); _ck(_v, 5, 0, currVal_1); var currVal_2 = (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) && (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) === "0")); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.skillsetName; _ck(_v, 3, 0, currVal_0); }); }
function View_ApplicationsComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" Status: ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.status; _ck(_v, 0, 0, currVal_0); }); }
function View_ApplicationsComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" New "]))], null, null); }
function View_ApplicationsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["applicationTitle", 2]], null, 0, null, View_ApplicationsComponent_11)), (_l()(), i1.ɵand(0, [["applicationSubtitle", 2]], null, 0, null, View_ApplicationsComponent_16)), (_l()(), i1.ɵand(0, [["new", 2]], null, 0, null, View_ApplicationsComponent_17)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-menu-item", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.openDetails(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MenuItemComponent_0, i3.RenderType_MenuItemComponent)), i1.ɵdid(5, 114688, [[1, 4]], 0, i4.MenuItemComponent, [], { titleTemplate: [0, "titleTemplate"], subTitleTemplate: [1, "subTitleTemplate"], chipTitleTemplate: [2, "chipTitleTemplate"], chipAppearance: [3, "chipAppearance"], appearance: [4, "appearance"], iconName: [5, "iconName"], iconColorPrimary: [6, "iconColorPrimary"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1); var currVal_1 = i1.ɵnov(_v, 2); var currVal_2 = (_v.parent.context.$implicit.hasUpdates ? i1.ɵnov(_v, 3) : null); var currVal_3 = _co.ChipAppearanceEnum.Red; var currVal_4 = _v.parent.context.$implicit.menuColorAppearance; var currVal_5 = (!((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.postingBySkillset) ? _co.SvgIconNameEnum.Location : _co.SvgIconNameEnum.Airplane); var currVal_6 = (!((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.postingBySkillset) ? _co.SvgIconColorEnum.MountainMeadow : _co.SvgIconColorEnum.Cinnabar); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_ApplicationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_10)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !((_v.context.$implicit == null) ? null : _v.context.$implicit.postingBySkillset); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.postingBySkillset); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ApplicationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-menu-header", [], null, null, null, i5.View_MenuHeaderComponent_0, i5.RenderType_MenuHeaderComponent)), i1.ɵdid(2, 49152, null, 0, i6.MenuHeaderComponent, [], { bottomRoundedBorder: [0, "bottomRoundedBorder"] }, null), (_l()(), i1.ɵted(3, 0, [" ", " Recent Updates "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "app-menu", [], null, null, null, i7.View_MenuComponent_0, i7.RenderType_MenuComponent)), i1.ɵdid(5, 114688, null, 1, i8.MenuComponent, [], { topRoundedBorder: [0, "topRoundedBorder"], bottomRoundedBorder: [1, "bottomRoundedBorder"] }, null), i1.ɵqud(603979776, 1, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 2, 0, currVal_0); var currVal_2 = false; var currVal_3 = false; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = _co.applicationUpdates; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.applicationUpdates.length; _ck(_v, 3, 0, currVal_1); }); }
function View_ApplicationsComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Week In"]))], null, null); }
function View_ApplicationsComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Weeks In"]))], null, null); }
function View_ApplicationsComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "+ "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_23)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_24)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var currVal_1 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 1, 0, currVal_0); }); }
function View_ApplicationsComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_22)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.StringHelpers.joinStrings(((_v.parent.parent.context.$implicit.location == null) ? null : _v.parent.parent.context.$implicit.location.city), ((_v.parent.parent.context.$implicit.location == null) ? null : _v.parent.parent.context.$implicit.location.stateName)); _ck(_v, 3, 0, currVal_1); }); }
function View_ApplicationsComponent_25(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" Status: ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.status; _ck(_v, 0, 0, currVal_0); }); }
function View_ApplicationsComponent_26(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" New "]))], null, null); }
function View_ApplicationsComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["applicationTitle", 2]], null, 0, null, View_ApplicationsComponent_21)), (_l()(), i1.ɵand(0, [["applicationSubtitle", 2]], null, 0, null, View_ApplicationsComponent_25)), (_l()(), i1.ɵand(0, [["new", 2]], null, 0, null, View_ApplicationsComponent_26)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-menu-item", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.openDetails(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MenuItemComponent_0, i3.RenderType_MenuItemComponent)), i1.ɵdid(5, 114688, [[2, 4]], 0, i4.MenuItemComponent, [], { titleTemplate: [0, "titleTemplate"], subTitleTemplate: [1, "subTitleTemplate"], chipTitleTemplate: [2, "chipTitleTemplate"], chipAppearance: [3, "chipAppearance"], appearance: [4, "appearance"], iconName: [5, "iconName"], iconColorPrimary: [6, "iconColorPrimary"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1); var currVal_1 = i1.ɵnov(_v, 2); var currVal_2 = (_v.parent.context.$implicit.hasUpdates ? i1.ɵnov(_v, 3) : null); var currVal_3 = _co.ChipAppearanceEnum.Red; var currVal_4 = _v.parent.context.$implicit.menuColorAppearance; var currVal_5 = (!((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.postingBySkillset) ? _co.SvgIconNameEnum.Location : _co.SvgIconNameEnum.Airplane); var currVal_6 = (!((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.postingBySkillset) ? _co.SvgIconColorEnum.MountainMeadow : _co.SvgIconColorEnum.Cinnabar); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_ApplicationsComponent_30(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Week"]))], null, null); }
function View_ApplicationsComponent_31(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Weeks"]))], null, null); }
function View_ApplicationsComponent_29(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "+ "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_30)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_31)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var currVal_1 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.parent.context.$implicit == null) ? null : _v.parent.parent.parent.context.$implicit.duration); _ck(_v, 1, 0, currVal_0); }); }
function View_ApplicationsComponent_32(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Less Than a Week "]))], null, null); }
function View_ApplicationsComponent_28(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" National opportunity for "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "text-transform:capitalize"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_29)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_32)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) && (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) !== "0")); _ck(_v, 5, 0, currVal_1); var currVal_2 = (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) && (((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.duration) === "0")); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.skillsetName; _ck(_v, 3, 0, currVal_0); }); }
function View_ApplicationsComponent_33(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" Status: ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.status; _ck(_v, 0, 0, currVal_0); }); }
function View_ApplicationsComponent_34(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" New "]))], null, null); }
function View_ApplicationsComponent_27(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["roadCrewItemTitle", 2]], null, 0, null, View_ApplicationsComponent_28)), (_l()(), i1.ɵand(0, [["roadCrewItemSubtitle", 2]], null, 0, null, View_ApplicationsComponent_33)), (_l()(), i1.ɵand(0, [["new", 2]], null, 0, null, View_ApplicationsComponent_34)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-menu-item", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.openDetails(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MenuItemComponent_0, i3.RenderType_MenuItemComponent)), i1.ɵdid(5, 114688, [[2, 4]], 0, i4.MenuItemComponent, [], { titleTemplate: [0, "titleTemplate"], subTitleTemplate: [1, "subTitleTemplate"], chipTitleTemplate: [2, "chipTitleTemplate"], chipAppearance: [3, "chipAppearance"], appearance: [4, "appearance"], iconName: [5, "iconName"], iconColorPrimary: [6, "iconColorPrimary"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1); var currVal_1 = i1.ɵnov(_v, 2); var currVal_2 = (_v.parent.context.$implicit.hasUpdates ? i1.ɵnov(_v, 3) : null); var currVal_3 = _co.ChipAppearanceEnum.Red; var currVal_4 = _v.parent.context.$implicit.menuColorAppearance; var currVal_5 = (!((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.postingBySkillset) ? _co.SvgIconNameEnum.Location : _co.SvgIconNameEnum.Airplane); var currVal_6 = (!((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.postingBySkillset) ? _co.SvgIconColorEnum.MountainMeadow : _co.SvgIconColorEnum.Cinnabar); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_ApplicationsComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_20)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_27)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !((_v.context.$implicit == null) ? null : _v.context.$implicit.postingBySkillset); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.postingBySkillset); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ApplicationsComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-menu-header", [], null, null, null, i5.View_MenuHeaderComponent_0, i5.RenderType_MenuHeaderComponent)), i1.ɵdid(2, 49152, null, 0, i6.MenuHeaderComponent, [], { topRoundedBorder: [0, "topRoundedBorder"], bottomRoundedBorder: [1, "bottomRoundedBorder"] }, null), (_l()(), i1.ɵted(3, 0, [" ", " Pending Application "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "app-menu", [], null, null, null, i7.View_MenuComponent_0, i7.RenderType_MenuComponent)), i1.ɵdid(5, 114688, null, 1, i8.MenuComponent, [], { topRoundedBorder: [0, "topRoundedBorder"] }, null), i1.ɵqud(603979776, 2, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_19)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = false; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.pendingUpdates; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.pendingUpdates.length; _ck(_v, 3, 0, currVal_2); }); }
function View_ApplicationsComponent_36(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You don't have any active or pending applications right now. "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" View Openings to apply to opportunities! "]))], null, null); }
function View_ApplicationsComponent_37(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-state", [], null, null, null, i9.View_EmptyStateComponent_0, i9.RenderType_EmptyStateComponent)), i1.ɵdid(1, 114688, null, 0, i10.EmptyStateComponent, [i11.TranslationService], { emptyStateTemplate: [0, "emptyStateTemplate"], emptyStateIcon: [1, "emptyStateIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v.parent, 1); var currVal_1 = _co.SvgIconNameEnum.EmptyStateApplications; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ApplicationsComponent_35(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["emptyStateMessage", 2]], null, 0, null, View_ApplicationsComponent_36)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_37)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.applicationUpdates === null) || (_co.applicationUpdates.length === 0)) && ((_co.pendingUpdates === null) || (_co.pendingUpdates.length === 0))); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ApplicationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "main", [["class", "applications"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_18)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationsComponent_35)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.applicationUpdates && (_co.applicationUpdates.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.pendingUpdates && (_co.pendingUpdates.length > 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.applicationUpdates === null) || ((_co.applicationUpdates.length === 0) && (_co.pendingUpdates === null))) || (_co.pendingUpdates.length === 0)); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ApplicationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-applications", [], null, null, null, View_ApplicationsComponent_0, RenderType_ApplicationsComponent)), i1.ɵdid(1, 49152, null, 0, i12.ApplicationsComponent, [], null, null)], null, null); }
var ApplicationsComponentNgFactory = i1.ɵccf("app-applications", i12.ApplicationsComponent, View_ApplicationsComponent_Host_0, { applicationUpdates: "applicationUpdates", pendingUpdates: "pendingUpdates" }, { openItem: "openItem" }, []);
export { ApplicationsComponentNgFactory as ApplicationsComponentNgFactory };
