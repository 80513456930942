import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyTeamMembersComponent } from './containers/my-team-members/my-team-members.component';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';
import { Roles } from '../core/enums/Roles';
import { AuthorizationGuard } from '../core/guards/authorizationGuard/authorization.guard';
import { MyTeamComponent } from './my-team.component';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { MyTeamMemberCheckInComponent } from './containers/my-team-member-check-in/my-team-member-check-in.component';
import { MyTeamMemberProductionComponent } from './containers/my-team-member-production/my-team-member-production.component';
import { Module } from '../shared/enums/Module';

const routes: Routes = [
  {
    path: 'my-team',
    component: MyTeamComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {
      siteMapKey: SiteMapItemKeys.MyTeam,
      allowedRoles: [Roles.SiteManager, Roles.SiteSupervisor]
    },
    children: [
      {
        path: '',
        component: MyTeamMembersComponent,
        data: {
          module: Module.MyTeam,
          pageTitle: TranslationMessages.MyTeamTitle
        }
      },
      {
        path: ':id/check-ins',
        component: MyTeamMemberCheckInComponent,
        data: {
          module: Module.MyTeam,
          siteMapKey: SiteMapItemKeys.MyTeamMemberCheckInHistory,
          pageTitle: TranslationMessages.AssociateCheckInHistoryTitle
        }
      },
      {
        path: ':id/production',
        component: MyTeamMemberProductionComponent,
        data: {
          module: Module.MyTeam,
          siteMapKey: SiteMapItemKeys.MyTeamMemberProduction,
          pageTitle: TranslationMessages.AssociateProductionStatsTitle
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyTeamRoutingModule {}
