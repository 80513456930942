import { Component, OnInit } from '@angular/core';
import { LayoutActions, UserActions } from '../../../core/actions';
import { Store } from '@ngrx/store';
import { AppState, getIsUserProfileLoading } from '../../../reducers';
import { ButtonAppearance } from '../../../shared/enums/ButtonLink';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { Observable } from 'rxjs';
import { StaticContentService } from '../../../core/contracts/StaticContentService';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-disclaimer-container',
  templateUrl: './disclaimer-container.component.html',
  styleUrls: ['./disclaimer-container.component.scss']
})
export class DisclaimerContainerComponent implements OnInit {
  isUserInfoLoading$: Observable<boolean>;
  content = null;
  constructor(
    private store: Store<AppState>,
    private staticContentService: StaticContentService
  ) {}

  ButtonAppearanceEnum = ButtonAppearance;
  SvgIconNameEnum = SvgIconName;

  ngOnInit() {
    this.isUserInfoLoading$ = this.store.select(getIsUserProfileLoading);

    this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }));
    this.staticContentService
      .getLoginTermsAndConditions()
      .pipe(
        finalize(() =>
          this.store.dispatch(
            LayoutActions.toggleAppLoading({ loading: false })
          )
        )
      )
      .subscribe(content => (this.content = content));
  }

  decline() {
    this.store.dispatch(UserActions.userLogout({ isAutoLogout: false }));
  }

  agreeToTerms() {
    this.store.dispatch(UserActions.agreeWithTerms());
  }
}
