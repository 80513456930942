import { Injectable } from '@angular/core';
import { AccordionPanelComponent } from './accordion-panel.component';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AccordionService {
  private activePanelSubject = new BehaviorSubject<AccordionPanelComponent>(
    null
  );
  private multiSelect = false;
  private expandedAccordionPanel: AccordionPanelComponent;

  mvrPanels: Array<string> = [
    'Motor Vehicle Report (MVR) Documents',
    'Documentos de informes de vehículos motorizados',
    'Motor Vehicle Report (MVR) Documents [California]',
    'Documentos de informes de vehículos motorizados [California]',
    'Motor Vehicle Report (MVR) Documents [New Hampshire]',
    'Documentos de informes de vehículos motorizados [New Hampshire]',
    'Motor Vehicle Report (MVR) Documents [Washington]',
    'Documentos de informes de vehículos motorizados [Washington]'
  ];

  activePanel$ = this.activePanelSubject.asObservable();

  set expandedPanel(panel: AccordionPanelComponent) {
    this.expandedAccordionPanel = panel;
  }

  get expandedPanel() {
    return this.expandedAccordionPanel;
  }

  toggleExpandPanel(panel: AccordionPanelComponent) {
    if (!this.mvrPanels.includes(panel.title)) {
      this.expandedPanel === panel
        ? (this.expandedPanel = null)
        : (this.expandedPanel = panel);
    }
  }

  set activePanel(panel: AccordionPanelComponent) {
    this.activePanelSubject.next(panel);
  }

  set isMulti(value) {
    this.multiSelect = value;
  }

  get isMulti() {
    return this.multiSelect;
  }
}
