<div class="opportunities-list">
  <app-menu [topRoundedBorder]="false" [bottomRoundedBorder]="false">
    <ng-container *ngFor="let opportunity of opportunitiesList">
      <div *ngIf="!opportunity?.postingBySkillset">
        <ng-template #opportunityTitle>
          <span
            *ngIf="opportunity?.duration && opportunity?.duration !== '0'"
            i18n="@@opportunities-list_weeksInTitle"
          >
            {{ opportunity?.duration }}+ {opportunity?.duration, plural, =1
            {Week In} other {Weeks In}}
          </span>
          <span
            *ngIf="opportunity?.duration && opportunity?.duration === '0'"
            i18n="@@opportunities-list_weeksInTitleAlt"
          >
            Less Than a Week In
          </span>
          <span style="text-transform:capitalize">
            {{
              StringHelpers.joinStrings(
                opportunity.location?.city,
                opportunity.location?.stateName
              )
            }}
          </span>
        </ng-template>

        <ng-template #opportunitySubTitle>
          <div>
            <span
              *ngIf="
                opportunity.associatesNeededTotal ||
                opportunity.associatesNeededTotal === 0
              "
              i18n="@@opportunities-list_opportunityPositionCount"
            >
              {{ opportunity.associatesNeededTotal }}
              {opportunity.associatesNeededTotal, plural, =1 {Opening} other
              {Openings}},
            </span>
            <span i18n="@@opportunities-list_opportunityStarts">
              starts {{ opportunity.startDate | date: 'MM/dd/yy' }}
            </span>
          </div>
        </ng-template>
        <app-menu-item
          [titleTemplate]="opportunityTitle"
          [subTitleTemplate]="opportunitySubTitle"
          (buttonClick)="openOpportunity.emit(opportunity)"
          [iconName]="
            !opportunity?.postingBySkillset
              ? SvgIconName.Location
              : SvgIconName.Airplane
          "
          [iconColorPrimary]="
            !opportunity?.postingBySkillset
              ? SvgIconColor.MountainMeadow
              : SvgIconColor.Cinnabar
          "
        ></app-menu-item>
      </div>

      <div *ngIf="opportunity?.postingBySkillset">
        <ng-template #opportunityTitle>
          <span
            style="text-transform:capitalize"
            i18n="@@opportunities-list_National"
          >
            National opportunity for
          </span>
          <span style="text-transform:capitalize">
            {{ opportunity.skillsetName }}
          </span>
          <span
            *ngIf="opportunity?.duration && opportunity?.duration !== '0'"
            i18n="@@opportunities-list_weeksInTitleNational"
          >
            {{ opportunity?.duration }}+ {opportunity?.duration, plural, =1
            {Week} other {Weeks}}
          </span>
          <span
            *ngIf="opportunity?.duration && opportunity?.duration === '0'"
            i18n="@@opportunities-list_weeksInTitleAltNational"
          >
            Less Than a Week
          </span>
        </ng-template>

        <ng-template #opportunitySubTitle>
          <div>
            <span
              *ngIf="
                opportunity.associatesNeededTotal ||
                opportunity.associatesNeededTotal === 0
              "
              i18n="@@opportunities-list_opportunityPositionCount"
            >
              {{ opportunity.associatesNeededTotal }}
              {opportunity.associatesNeededTotal, plural, =1 {Opening} other
              {Openings}},
            </span>
            <span i18n="@@opportunities-list_opportunityStarts">
              starts {{ opportunity.startDate | date: 'MM/dd/yy' }}
            </span>
          </div>
        </ng-template>
        <app-menu-item
          [titleTemplate]="opportunityTitle"
          [subTitleTemplate]="opportunitySubTitle"
          (buttonClick)="openOpportunity.emit(opportunity)"
          [iconName]="
            !opportunity?.postingBySkillset
              ? SvgIconName.Location
              : SvgIconName.Airplane
          "
          [iconColorPrimary]="
            !opportunity?.postingBySkillset
              ? SvgIconColor.MountainMeadow
              : SvgIconColor.Cinnabar
          "
        ></app-menu-item>
      </div>
    </ng-container>
  </app-menu>
  <app-paginator
    *ngIf="totalCount !== 0"
    [totalCount]="totalCount"
    [pageIndex]="page?.pageIndex"
    [pageSize]="page?.pageSize"
    (pageChange)="changePage.emit($event)"
  ></app-paginator>
</div>
