import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent {
  @Input() actionTemplate: TemplateRef<any>;
  @Input() topRoundedBorder = true;
  @Input() bottomRoundedBorder = true;

  constructor() {}
}
