import { Component, Input, OnInit } from '@angular/core';
import { Post } from '../../../../core/dataEntities/feed/post';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { RichTextHtmlRendererOptions } from '../../../constants';
import { SvgIconColor, SvgIconName } from '../../../enums/SvgIcon';
import { ButtonAppearance, ButtonColor } from '../../../enums/ButtonLink';
import moment from 'moment';

@Component({
  selector: 'app-shift-request-card',
  templateUrl: './shift-request-card.component.html',
  styleUrls: ['./shift-request-card.component.scss']
})
export class ShiftRequestCardComponent implements OnInit {
  @Input() post: Post;
  @Input() ariaPosinset: number;
  @Input() ariaSetsize: number;
  @Input() isPinnedPost = false;

  public summary: string;
  public endDate: string;
  public durationInHours: string;
  public SvgIconNameEnum = SvgIconName;
  public SvgIconColorEnum = SvgIconColor;
  public ButtonAppearanceEnum = ButtonAppearance;
  public ButtonColorEnum = ButtonColor;

  constructor() {}

  ngOnInit() {
    this.summary = documentToHtmlString(
      this.post.summary,
      RichTextHtmlRendererOptions
    );

    this.endDate = moment(this.post.shift.startDateTime)
      .add(this.post.shift.duration, 'hours')
      .format();

    this.post.shift.duration === 24
      ? (this.durationInHours = '24:00')
      : (this.durationInHours = moment
          .utc()
          .startOf('day')
          .add({ hours: this.post.shift.duration })
          .format('H:mm'));
  }
}
