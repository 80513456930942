export enum PageHeaderType {
  Back = 'Back',
  Close = 'Close'
}

export enum PageHeaderAppearance {
  Blue = 'Blue',
  Transparent = 'Transparent',
  Green = 'Green'
}
