/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./menu-item.component";
var styles_MenuItemComponent = [];
var RenderType_MenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MenuItemComponent, data: {} });
export { RenderType_MenuItemComponent as RenderType_MenuItemComponent };
export function View_MenuItemComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_MenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-menu-item", [], null, null, null, View_MenuItemComponent_0, RenderType_MenuItemComponent)), i0.ɵdid(1, 114688, null, 0, i1.MenuItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuItemComponentNgFactory = i0.ɵccf("app-menu-item", i1.MenuItemComponent, View_MenuItemComponent_Host_0, { title: "title", titleTemplate: "titleTemplate", subTitle: "subTitle", subTitleTemplate: "subTitleTemplate", subTitleColor: "subTitleColor", chipTitle: "chipTitle", chipTitleTemplate: "chipTitleTemplate", chipAppearance: "chipAppearance", titleFont: "titleFont", appearance: "appearance", iconName: "iconName", iconColorPrimary: "iconColorPrimary", iconColorSecondary: "iconColorSecondary", imageTemplate: "imageTemplate", isHighlighted: "isHighlighted", isExternalUrl: "isExternalUrl", link: "link", queryParams: "queryParams", dataCy: "dataCy" }, { buttonClick: "buttonClick" }, []);
export { MenuItemComponentNgFactory as MenuItemComponentNgFactory };
