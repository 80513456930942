import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { AnimationOptions } from 'ngx-lottie';
import { LottiePlayer } from 'ngx-lottie/src/symbols';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
  isLoaderVisibleSubject = new BehaviorSubject(false);
  isLoaderVisible$: Observable<boolean>;

  options: AnimationOptions = {
    path: './assets/data/loader_animation.json',
    beforeAnimationIsCreated: (player: LottiePlayer) => {
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );

      if (isSafari) {
        player.setLocationHref(location.href);
      }
    }
  };

  @Input() debounceTime = 500;

  @Input()
  set isLoading(value) {
    this.isLoaderVisibleSubject.next(value);
  }

  @Input()
  isFullHeight = false;

  @HostBinding('class.is-visible') get visible() {
    return this.isLoaderVisibleSubject.value;
  }

  constructor() {}

  ngOnInit() {
    this.isLoaderVisible$ = this.isLoaderVisibleSubject.pipe(
      debounce(isLoaderVisible => {
        if (isLoaderVisible) {
          return timer(this.debounceTime);
        }
        return timer(0);
      })
    );
  }
}
