import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { ButtonType } from '../../../shared/enums/ButtonLink';
import { AppState, getUserId } from '../../../reducers';
import { Store } from '@ngrx/store';
import { ProfileActions } from '../../actions';
import { WeeklyCheckIn } from '../../../core/dataEntities/profile/weeklyCheckIn';
import moment from 'moment';
import { BottomSheetService } from '../../../shared/modules/modal/bottom-sheet/bottom-sheet.service';
import { first } from 'rxjs/operators';
import { NavigationActions } from '../../../core/actions';
import { WeeklyCheckInService } from '../../services/weekly-check-in.service';
import { AboutCheckInComponent } from '../../components/about-check-in/about-check-in.component';
import { SkipCheckInComponent } from '../../components/skip-check-in/skip-check-in.component';
import { RatingRatioInputAppearance } from '../../../shared/enums/RatingRatioInput';
import { RadioGroupDirection } from '../../../shared/enums/RadioGroup';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-weekly-check-in',
  templateUrl: './weekly-check-in.component.html',
  styleUrls: ['./weekly-check-in.component.scss'],
  animations: [
    trigger('enterLeaveTrigger', [
      transition(':enter', [
        style({ height: '0' }),
        animate('0.3s ease', style({}))
      ]),
      transition(':leave', [animate('0.3s ease', style({ height: 0 }))])
    ])
  ]
})
export class WeeklyCheckInComponent implements OnInit {
  PageTitleAppearanceEnum = PageTitleAppearance;
  RatingRatioInputAppearanceEnum = RatingRatioInputAppearance;
  RadioGroupDirectionEnum = RadioGroupDirection;
  ButtonTypeEnum = ButtonType;
  public checkInFormGroup: FormGroup;
  public startDay: any;
  public endDay: any;
  public isCheckInSkipped = false;
  public weekEndingDate: string;
  public isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private bottomSheetService: BottomSheetService,
    private weeklyCheckInService: WeeklyCheckInService,
    private route: ActivatedRoute
  ) {
    this.route.paramMap
      .pipe(first())
      .subscribe(
        (params: ParamMap) => (this.weekEndingDate = params.get('week'))
      );

    this.checkInFormGroup = this.fb.group({
      score: [null, [Validators.required]],
      message: ['', [Validators.maxLength(500)]]
    });
  }

  ngOnInit() {
    this.startDay = moment(this.weekEndingDate).add(-7, 'days');
    this.endDay = moment(this.weekEndingDate);

    this.store
      .select(getUserId)
      .pipe(first())
      .subscribe(userId => {
        this.isCheckInSkipped = this.weeklyCheckInService.isCheckInSkipped(
          userId
        );
      });
  }

  onSubmit(form) {
    if (form.valid) {
      const checkIn = {
        score: form.get('score').value,
        message: form.get('message').value
      } as WeeklyCheckIn;

      this.store.dispatch(
        ProfileActions.saveWeeklyCheckIn({ date: this.weekEndingDate, checkIn })
      );
    }
  }

  openAbout() {
    this.bottomSheetService.open(AboutCheckInComponent);
  }

  openSkip() {
    this.bottomSheetService
      .open(SkipCheckInComponent)
      .afterClosed()
      .pipe(first())
      .subscribe(confirmation => {
        if (confirmation && confirmation.success) {
          this.weeklyCheckInService.skipSurvey();
          this.store.dispatch(
            NavigationActions.navigateBack({
              replaceUrl: true
            })
          );
        }
      });
  }
}
