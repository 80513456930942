import { first, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import isEmpty from 'lodash/fp/isEmpty';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState, getAuthToken } from '../../reducers';
import { TeamMembersResponse } from '../../core/responses/team/teamMembersResponse';
import { SortDirections } from '../../core/enums/SortDirections';
import { ApiGatewayService } from '../../shared/services/api.gateway.service';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  constructor(
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>
  ) {}

  loadSiteTeam(site, query, sort, page): Observable<TeamMembersResponse> {
    const params: any = {
      pageSize: page.pageSize,
      currentPage: page.pageIndex + 1,
      sortBy: sort.active,
      sortAsc: sort.direction === SortDirections.Asc
    };

    if (query) {
      params.searchBy = query;
    }

    return this.store.select(getAuthToken).pipe(
      first(),
      switchMap(authToken => {
        return this.apiGateway
          .get<TeamMembersResponse>(`managers/team/${site}`, authToken, params)
          .map((response: any) => {
            if (isEmpty(response)) {
              return null;
            }
            return response;
          });
      })
    );
  }
}
