import { Component, OnInit } from '@angular/core';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { AlertAppearance } from '../../enums/Alert';
import { DialogRef } from '../../../shared/modules/modal/dialog-ref';

@Component({
  selector: 'app-application-update',
  templateUrl: './application-update.component.html'
})
export class ApplicationUpdateComponent implements OnInit {
  SvgIconName = SvgIconName;
  AlertAppearance = AlertAppearance;

  constructor(private alertUpdateRef: DialogRef<ApplicationUpdateComponent>) {}

  ngOnInit() {}

  onCloseAlert(response) {
    response.success === false
      ? this.alertUpdateRef.close(response)
      : document.location.reload();
  }
}
