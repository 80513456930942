import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-earnings-tabs',
  templateUrl: './earnings-tabs.component.html'
})
export class EarningsTabsComponent implements OnInit {
  @Input() isTrainee: boolean;
  @Input() isHistoricalEarning: boolean;
  @Input() goal: number;

  public showTabs = false;

  constructor() {}

  ngOnInit() {
    this.showTabs =
      (this.isTrainee && !this.isHistoricalEarning) ||
      (!this.isTrainee &&
        (this.goal != null ||
          (this.goal == null && !this.isHistoricalEarning)));
  }
}
