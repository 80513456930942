<p class="read-only-details">
  <span class="read-only-details__item">
    {{ formGroup.get('firstName').value }}
  </span>
  <span class="read-only-details__item">
    {{ formGroup.get('lastName').value }}
  </span>
</p>
<p class="read-only-details">
  <span class="read-only-details__item">
    {{
      formGroup.get('sex').value &&
        translationService.translate(formGroup.get('sex').value)
    }}
  </span>
  <span class="read-only-details__item">
    {{
      formGroup.get('jobTitle').value &&
        translationService.translate(formGroup.get('jobTitle').value)
    }}
  </span>
</p>
