import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { SvgIconName } from '../../enums/SvgIcon';
import { SortDirections } from '../../../core/enums/SortDirections';

@Component({
  selector: 'app-sort-search',
  templateUrl: './sort-search.component.html',
  styleUrls: ['./sort-search.component.scss'],
  animations: [
    trigger('openCloseSearch', [
      state('true', style({ width: '100%', opacity: '1' })),
      state('false', style({ width: '*', opacity: '*' })),
      transition('false <=> true', animate('0.3s ease-in-out'))
    ]),
    trigger('openCloseSort', [
      state('true', style({ visibility: '*', opacity: '*' })),
      state('false', style({ visibility: 'hidden', opacity: '0' })),
      transition('false <=> true', animate('0.3s ease-out'))
    ])
  ]
})
export class SortSearchComponent implements OnInit, OnChanges {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  public SvgIconNameEnum = SvgIconName;
  public SortDirectionsEnum = SortDirections;
  public isSearchOpen = false;
  public activeSortName: string;

  @Input()
  searchQuery;

  @Input()
  sort;

  @Input()
  sortByOptions: any[];

  @Output()
  searchQueryChange = new EventEmitter();

  @Output()
  sortChange = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.sort && this.sort.active) {
      this.setActiveSortName(this.sort && this.sort.active);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sort || changes.sortByOptions) {
      this.setActiveSortName(this.sort && this.sort.active);
    }
  }

  openSearch() {
    this.isSearchOpen = true;
    this.searchInput.nativeElement.focus();
  }

  closeSearch() {
    this.isSearchOpen = false;
    this.searchQueryChange.emit('');
  }

  onSearchQueryChange(query) {
    this.searchQueryChange.emit(query);
  }

  onSortByChange(sortBy) {
    this.sortChange.emit({
      ...this.sort,
      active: sortBy
    });

    this.setActiveSortName(sortBy);
  }

  setActiveSortName(sortBy) {
    this.activeSortName = this.sortByOptions.find(x => x.id === sortBy).name;
  }

  toggleSortDirection() {
    let newSortDirection = SortDirections.Asc;

    if (this.sort && this.sort.direction === SortDirections.Asc) {
      newSortDirection = SortDirections.Desc;
    }

    this.sortChange.emit({
      ...this.sort,
      direction: newSortDirection
    });
  }
}
