export enum Surveys {
  SURVEY_SUBMITTED_SUCCESSFULLY = 20000,
  SURVEY_ALREADY_SUBMITTED_FOR_ASSOCIATE = 20001,
  SURVEY_NOT_FOUND = 20002
}

export enum Associates {
  ASSOCIATE_NOT_FOUND = 10000,
  WEEK_ENDING_DATE_NOT_SATURDAY = 10001,
  EARNINGS_NOT_ALLOWED_FOR_SITE = 10002
}
