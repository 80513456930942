import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OpenShiftListItem } from '../../../../../core/dataEntities/opportunities/openShiftListItem';
import { OpportunityListItem } from '../../../../../core/dataEntities/opportunities/opportunityListItem';
import { OpportunityType } from '../../../../../core/enums/OpportunityType';
import { StringHelpers } from '../../../../../shared/helpers/string.helpers';
import { SvgIconColor, SvgIconName } from '../../../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-openings',
  templateUrl: './openings.component.html',
  styleUrls: ['./openings.component.scss']
})
export class OpeningsComponent {
  StringHelpers = StringHelpers;

  @Input() openShifts: OpenShiftListItem[];
  @Input() roadCrewOpenings: OpportunityListItem[];
  @Input() roadCrewOpeningsCount: number;
  @Input() associateHomeState: string;
  @Output() openItem = new EventEmitter();
  @Output() openAll = new EventEmitter();

  SvgIconName = SvgIconName;
  SvgIconColor = SvgIconColor;

  constructor() {}

  openRoadCrew(item) {
    this.openItem.emit({
      id: item.otherId,
      opportunityId: item.opportunityID,
      opportunityType: OpportunityType.RoadCrew,
      isNew: null
    });
  }

  openShift(item) {
    this.openItem.emit({
      opportunityId: item.id,
      opportunityType: OpportunityType.Shift,
      isNew: null
    });
  }
}
