import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsUtilitiesService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public customAnalyticsEvent(analyticsId: string, data?): void {
    const customEvent = new CustomEvent('customAnalytics', {
      detail: {
        analyticsId,
        analyticsData: data
      }
    });
    this.document.dispatchEvent(customEvent);
  }
}
