export enum PostLinkType {
  Internal,
  External
}

export enum PostCategoryType {
  Award,
  Congrats,
  Alert,
  Event,
  Announcement,
  WorkOpportunity,
  Birthday,
  Anniversary,
  SafetyTip
}

export enum PostSafetyTipType {
  Equipment = 'Equipment',
  Environment = 'Environment',
  ManualLabor = 'Manual Labor',
  GeneralSafety = 'General Safety'
}
