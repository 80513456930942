import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiGatewayService {
  private apiHost = environment.capstoneApi.host;
  private apiVersion = environment.capstoneApi.apiVersion;
  private apiAccessKey = environment.capstoneApi.accessKey;

  constructor(private http: HttpClient) {}

  get<T>(url, token, params = null) {
    return this.request<T>('GET', url, token, null, params);
  }

  post<T>(url, token, body, params = null): Observable<T> {
    return this.request<T>('POST', url, token, body, params);
  }

  put<T>(url, token, body, params = null): Observable<T> {
    return this.request<T>('PUT', url, token, body, params);
  }

  delete<T>(url, token, params = null): Observable<T> {
    return this.request<T>('DELETE', url, token, null, params);
  }

  request<T>(
    method: 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS' | 'POST' | 'PUT',
    url: string,
    token: string,
    body = null,
    params = null
  ): Observable<T> {
    const requestParams = {
      ...params,
      'api-version': this.apiVersion
    };

    return this.http.request(method, `${this.apiHost}/${url}`, {
      headers: this.getRequestHeader(token),
      params: requestParams,
      body
    }) as any;
  }

  private getRequestHeader(token: string) {
    const header = {
      Authorization: `Bearer ${token}`
    };

    if (this.apiAccessKey) {
      Object.assign(header, { 'access-key': this.apiAccessKey });
    }

    return header;
  }
}
