import {
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormFieldControl } from '../form-field/form-field.control';
import {
  FORM_FIELD,
  FormFieldComponent
} from '../form-field/form-field.component';

let nextUniqueId = 0;

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent)
    },
    { provide: FormFieldControl, useExisting: SelectComponent }
  ]
})
export class SelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor, FormFieldControl {
  onDestroySubject = new Subject();
  formControl: FormControl;
  @Input() name: string;
  @Input() placeholder = 'Select';
  @Input() options: any[];
  @Input() disabled = false;
  @Input() valueKey = 'id';
  @Input() labelKey = 'name';
  protected defaultId = `app-select-${nextUniqueId++}`;

  constructor(
    fb: FormBuilder,
    @Optional() @Inject(FORM_FIELD) private formField: FormFieldComponent
  ) {
    this.id = this.id;
    this.formControl = fb.control('');
  }

  get hasValidation() {
    if (!this.formField) {
      return false;
    }

    return this.formField.hasValidation();
  }

  get isInvalid() {
    if (!this.formField) {
      return false;
    }
    return this.formField.isInvalid();
  }

  protected componentId: string;

  @Input()
  get id(): string {
    return this.componentId;
  }

  set id(value: string) {
    this.componentId = value || this.defaultId;
  }

  get errorId(): string {
    return `${this.id}-error`;
  }

  public get control() {
    return this.formControl;
  }

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(value => {
        this.propagateChange(value);
      });
  }

  ngOnDestroy() {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  writeValue(value) {
    this.formControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  private propagateChange = (value: any) => {};
}
