import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../../../core/guards/authenticationGuard/authentication.guard';
import { TranslationMessages } from '../../../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../../../core/enums/SiteMapItemKeys';
import { WorkComponent } from './work.component';
import { OpportunitiesDetailsContainerComponent } from './containers/opportunities-details/opportunities-details-container.component';
import { OpportunitiesListContainerComponent } from './containers/opportunities-list/opportunities-list-container.component';
import { RoadCrewApplicationContainerComponent } from './containers/road-crew-application/road-crew-application-container.component';
import { environment } from '../../../../environments/environment';
import { OpportunitiesParentComponent } from './containers/opportunities-parent/opportunities-parent.component';

const routes: Routes = [
  {
    path: 'roadcrew',
    component: OpportunitiesParentComponent,
    canActivate: [AuthenticationGuard],
    data: {
      module: 'roadCrew',
      showMenuBar: true,
      hideNavigationBar: false,
      pageTitle: TranslationMessages.RoadCrewApplicationTitle,
      siteMapKey: SiteMapItemKeys.RoadCrewApplication,
      canActivate: environment.features.opportunities
    }
  },
  {
    path: 'opportunities',
    component: WorkComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        component: OpportunitiesListContainerComponent,
        data: {
          showMenuBar: false,
          hideNavigationBar: true,
          pageTitle: TranslationMessages.OpportunitiesListPageTitle,
          siteMapKey: SiteMapItemKeys.OpportunitiesList,
          canActivate: environment.features.opportunities
        }
      },
      {
        path: ':id/details',
        component: OpportunitiesDetailsContainerComponent,
        data: {
          showMenuBar: false,
          hideNavigationBar: true,
          pageTitle: TranslationMessages.OpportunityDetailsPageTitle,
          siteMapKey: SiteMapItemKeys.OpportunityDetails,
          canActivate: environment.features.opportunities
        }
      }
    ]
  },
  {
    path: 'opportunity/apply',
    component: RoadCrewApplicationContainerComponent,
    canActivate: [AuthenticationGuard],
    data: {
      showMenuBar: false,
      hideNavigationBar: true,
      pageTitle: TranslationMessages.RoadCrewApplicationTitle,
      siteMapKey: SiteMapItemKeys.RoadCrewApplication,
      canActivate: environment.features.opportunities
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkRoutingModule {}
