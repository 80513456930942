/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./disclaimer-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../../../shared/components/svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../../../shared/components/button-link/button-link.component.ngfactory";
import * as i7 from "../../../shared/components/button-link/button-link.component";
import * as i8 from "../../../shared/services/analytics-utilities.service";
import * as i9 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i10 from "../../../shared/components/loader/loader.component";
import * as i11 from "./disclaimer-container.component";
import * as i12 from "@ngrx/store";
import * as i13 from "../../../core/contracts/StaticContentService";
var styles_DisclaimerContainerComponent = [i0.styles];
var RenderType_DisclaimerContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DisclaimerContainerComponent, data: {} });
export { RenderType_DisclaimerContainerComponent as RenderType_DisclaimerContainerComponent };
function View_DisclaimerContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "disclaimer__terms terms"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.content == null) ? null : _co.content.title); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.content == null) ? null : _co.content.content); _ck(_v, 3, 0, currVal_1); }); }
function View_DisclaimerContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "disclaimer__header welcome-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-svg-icon", [["class", "welcome-header__icon"], ["description", "Capstone logotype"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(3, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"], description: [1, "description"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "h1", [["class", "welcome-header__heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "welcome-header__subheading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Welcome to "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Capstone"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DisclaimerContainerComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "disclaimer__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-button-link", [["label", "I decline"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonLinkComponent_0, i6.RenderType_ButtonLinkComponent)), i1.ɵdid(13, 638976, null, 0, i7.ButtonLinkComponent, [i8.AnalyticsUtilitiesService], { appearance: [0, "appearance"], label: [1, "label"] }, { buttonClick: "buttonClick" }), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-button-link", [["label", "I Agree to the terms"]], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.agreeToTerms() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonLinkComponent_0, i6.RenderType_ButtonLinkComponent)), i1.ɵdid(15, 638976, null, 0, i7.ButtonLinkComponent, [i8.AnalyticsUtilitiesService], { label: [0, "label"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.CapstoneLogo; var currVal_1 = "Capstone logotype"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.content; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.ButtonAppearanceEnum.Link; var currVal_4 = "I decline"; _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_5 = "I Agree to the terms"; _ck(_v, 15, 0, currVal_5); }, null); }
export function View_DisclaimerContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "main", [["class", "disclaimer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DisclaimerContainerComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-loader", [], [[2, "is-visible", null]], null, null, i9.View_LoaderComponent_0, i9.RenderType_LoaderComponent)), i1.ɵdid(5, 114688, null, 0, i10.LoaderComponent, [], { isLoading: [0, "isLoading"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.isUserInfoLoading$)) === false); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.isUserInfoLoading$)); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 5).visible; _ck(_v, 4, 0, currVal_1); }); }
export function View_DisclaimerContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-disclaimer-container", [], null, null, null, View_DisclaimerContainerComponent_0, RenderType_DisclaimerContainerComponent)), i1.ɵdid(1, 114688, null, 0, i11.DisclaimerContainerComponent, [i12.Store, i13.StaticContentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DisclaimerContainerComponentNgFactory = i1.ɵccf("app-disclaimer-container", i11.DisclaimerContainerComponent, View_DisclaimerContainerComponent_Host_0, {}, {}, []);
export { DisclaimerContainerComponentNgFactory as DisclaimerContainerComponentNgFactory };
