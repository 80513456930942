import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  AppState,
  selectAccessSitesDropDownValues,
  selectIsShiftLoading,
  selectIsSitesLoading,
  selectShift
} from '../../../../../reducers';
import { ShiftDetails } from '../../../../../core/dataEntities/shifts/shiftDetails';
import { ShiftActions } from '../../actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, first, takeUntil } from 'rxjs/operators';
import { ShiftContactTypes } from '../../../../../core/enums/ShiftContactTypes';
import {
  ButtonAppearance,
  ButtonType
} from '../../../../../shared/enums/ButtonLink';
import moment from 'moment';
import _ from 'lodash';
import { TranslationService } from '../../../../../shared/services/translation.service';
import { TranslationMessages } from '../../../../../shared/enums/TranslationMessages';
import { ShiftService } from '../../services/shift.service';
import { selectNavigationBackUrl } from '../../../../../core/reducers/navigation.reducer';

@Component({
  selector: 'app-shift-edit',
  styleUrls: ['./shift-edit.component.scss'],
  templateUrl: './shift-edit.component.html'
})
export class ShiftEditComponent implements OnInit {
  public shiftId;

  onDestroySubject = new Subject();
  public shiftFormGroup: FormGroup;
  public sites$: Observable<any>;
  public isSitesLoading$: Observable<boolean>;
  public shiftDetails$: Observable<ShiftDetails>;
  public isShiftLoading$: Observable<boolean>;
  public isFormSubmitted = false;
  public ButtonTypeEnum = ButtonType;
  public ButtonAppearanceEnum = ButtonAppearance;
  public sites = [];
  public days = [];
  public startTimes = [];
  public endTimes = [];
  public associatesOptions = this.getAssociatesOptions();
  public contactTypesOptions = [
    {
      id: ShiftContactTypes.Call,
      name: this.translationService.translate(TranslationMessages.CallMe)
    },
    {
      id: ShiftContactTypes.Text,
      name: this.translationService.translate(TranslationMessages.TextMe)
    },
    {
      id: ShiftContactTypes.Both,
      name: this.translationService.translate(TranslationMessages.CallOrTextMe)
    }
  ];
  backUrl$: Observable<string>;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private translationService: TranslationService,
    private shiftService: ShiftService
  ) {
    this.shiftFormGroup = this.formBuilder.group({
      id: {
        value: null,
        disabled: true
      },
      manager: { value: null, disabled: true },
      siteId: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      associatesNeeded: [null, [Validators.required]],
      phoneNumber: [
        null,
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10)
        ]
      ],
      contactType: [null, [Validators.required]]
    });
  }

  ngOnInit() {
    this.sites$ = this.store.select(selectAccessSitesDropDownValues);
    this.shiftDetails$ = this.store.select(selectShift);
    this.isSitesLoading$ = this.store.select(selectIsSitesLoading);
    this.isShiftLoading$ = this.store.select(selectIsShiftLoading);
    this.days = this.shiftService.generateDays();
    this.startTimes = this.shiftService.generateStartTime(null);
    this.endTimes = this.shiftService.generateEndTime(null, null);
    this.backUrl$ = this.store.select(selectNavigationBackUrl);

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('id')) {
        this.store.dispatch(
          ShiftActions.loadShiftAndSites({ shiftId: params.get('id') })
        );
      } else {
        this.store.dispatch(ShiftActions.loadSites());
        this.store.dispatch(ShiftActions.loadShift({ id: null }));
      }
    });

    combineLatest(
      this.route.paramMap,
      this.shiftDetails$,
      this.sites$,
      this.isSitesLoading$
    )
      .pipe(
        takeUntil(this.onDestroySubject),
        filter(
          ([params, shiftDetails, sites, isSitesLoading]) =>
            isSitesLoading === false
        ),
        first()
      )
      .subscribe(([params, shiftDetails, sites]) => {
        this.shiftFormGroup.setValue({
          id: params.get('id')
            ? params.get('id')
            : shiftDetails
            ? shiftDetails.id
            : null,
          manager: shiftDetails.manager,
          siteId: shiftDetails.siteId
            ? shiftDetails.siteId
            : sites.length > 0
            ? _.first(sites).id
            : null,
          startDate: shiftDetails.startDateTime
            ? moment(shiftDetails.startDateTime).format('YYYY-MM-DD')
            : '',
          startTime: shiftDetails.startDateTime
            ? moment(shiftDetails.startDateTime).format('HH:mm')
            : '',
          endTime: shiftDetails.duration ? shiftDetails.duration : '',
          associatesNeeded: shiftDetails.associatesNeeded,
          phoneNumber: shiftDetails.phoneNumber,
          contactType: shiftDetails.contactType
        });

        this.sites = sites;
        this.startTimes = this.shiftService.generateStartTime(
          this.shiftFormGroup.get('startDate').value
        );
        this.endTimes = this.shiftService.generateEndTime(
          this.shiftFormGroup.get('startDate').value,
          this.shiftFormGroup.get('startTime').value
        );

        this.shiftFormGroup
          .get('startDate')
          .valueChanges.pipe(takeUntil(this.onDestroySubject))
          .subscribe(() => {
            this.startTimes = this.shiftService.generateStartTime(
              this.shiftFormGroup.get('startDate').value
            );
            if (this.startTimes[0]) {
              this.shiftFormGroup
                .get('startTime')
                .setValue(this.startTimes[0].id);
            }
          });

        merge(
          this.shiftFormGroup.get('startTime').valueChanges,
          this.shiftFormGroup.get('startDate').valueChanges
        )
          .pipe(takeUntil(this.onDestroySubject))
          .subscribe(() => {
            this.endTimes = this.shiftService.generateEndTime(
              this.shiftFormGroup.get('startDate').value,
              this.shiftFormGroup.get('startTime').value
            );
            if (this.endTimes[0]) {
              this.shiftFormGroup.get('endTime').setValue(this.endTimes[0].id);
            }
          });
      });
  }

  onSubmit(form) {
    this.isFormSubmitted = true;
    if (form.valid) {
      const shift = {
        id: form.get('id').value,
        phoneNumber: form.get('phoneNumber').value,
        manager: form.get('manager').value,
        duration: form.get('endTime').value,
        contactType: form.get('contactType').value,
        siteId: form.get('siteId').value,
        startDateTime: `${form.get('startDate').value} ${
          form.get('startTime').value
        }`,
        associatesNeeded: form.get('associatesNeeded').value,
        siteInfo: {
          name: this.sites.find(site => site.id === form.get('siteId').value)
            .name,
          subDept: form.get('siteId').value
        },
        authorRole: form.get('manager').value.title
      } as ShiftDetails;

      this.store.dispatch(ShiftActions.previewShift({ shift }));
    }
  }

  getAssociatesOptions() {
    const associatesOptions = [];
    for (let i = 1; i <= 10; i++) {
      associatesOptions.push({
        id: i,
        name:
          i === 1
            ? `1 ${this.translationService.translate(
                TranslationMessages.Associate
              )}`
            : `${i} ${this.translationService.translate(
                TranslationMessages.Associates
              )}`
      });
    }

    return associatesOptions;
  }
}
