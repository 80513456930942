import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CardLayoutAppearance } from '../../../enums/CardLayout';

@Component({
  selector: 'app-card-layout',
  templateUrl: './card-layout.component.html',
  styleUrls: ['./card-layout.component.scss']
})
export class CardLayoutComponent implements OnInit {
  @Input() appearance = CardLayoutAppearance.White;

  @HostBinding('class') get class() {
    const className = 'card-layout';
    return this.getClass(this.appearance, className);
  }

  getClass(appearance, className) {
    switch (appearance) {
      case CardLayoutAppearance.White:
        return className + ' ' + className + '--white';
      case CardLayoutAppearance.Blue:
        return className + ' ' + className + '--blue';
      case CardLayoutAppearance.Red:
        return className + ' ' + className + '--red';
      case CardLayoutAppearance.Green:
        return className + ' ' + className + '--green';
      case CardLayoutAppearance.Gray:
        return className + ' ' + className + '--gray';
      case CardLayoutAppearance.Gold:
        return className + ' ' + className + '--gold';
      default:
        return '';
    }
  }

  constructor() {}

  ngOnInit() {}
}
