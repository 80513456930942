import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeekPaySummaryResponse } from '../../core/responses/wages/weekPaySummaryResponse';
import { WeekPayDetailsResponse } from '../../core/responses/wages/weekPayDetailsResponse';
import { ApiGatewayService } from '../../shared/services/api.gateway.service';
import { WagesService } from '../../core/contracts/WagesService';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { AppState } from '../../reducers';
import { first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WagesApiService implements WagesService {
  constructor(
    private apiGateway: ApiGatewayService,
    private store: Store<AppState>
  ) {}

  getWeeklyPayments(): Observable<WeekPaySummaryResponse[]> {
    return this.store.select(fromRoot.getAuthTokenWithApexUserId).pipe(
      first(),
      switchMap(({ authToken, userId }) => {
        if (!userId) {
          throw new Error('User not found.');
        }
        return this.apiGateway.get<WeekPaySummaryResponse[]>(
          `associates/${userId}/earnings/history`,
          authToken
        );
      })
    );
  }

  getWeekPaymentsBreakdown(weekEndDate): Observable<WeekPayDetailsResponse> {
    return this.store.select(fromRoot.getAuthTokenWithApexUserId).pipe(
      first(),
      switchMap(({ authToken, userId }) => {
        if (!userId) {
          throw new Error('User not found.');
        }

        return this.apiGateway.get<WeekPayDetailsResponse>(
          `associates/${userId}/earnings/weekly/${weekEndDate}/details`,
          authToken
        );
      })
    );
  }
}
