import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { SurveyQuestionTypes } from '../../../core/enums/SurveyQuestionTypes';

@Component({
  selector: 'app-survey-question',
  templateUrl: './survey-question.component.html'
})
export class SurveyQuestionComponent implements OnInit {
  public SurveyQuestionTypesEnum = SurveyQuestionTypes;

  @Input()
  formGroup: FormGroup;

  get required() {
    if (!this.formGroup) {
      return false;
    }
    return this.getControlValidators(this.formGroup.get('answer')).required;
  }

  get question() {
    if (!this.formGroup) {
      return null;
    }
    return this.formGroup.get('question').value;
  }

  get id() {
    if (!this.formGroup) {
      return null;
    }
    return this.formGroup.get('id').value;
  }

  public scaleOptions = [
    '0 - NOT AT ALL LIKELY',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10 - EXTREMELY LIKELY'
  ];

  constructor() {}

  ngOnInit() {}

  getControlValidators(control: AbstractControl): ValidationErrors | null {
    if (!control.validator) {
      return {};
    }
    return control.validator({} as AbstractControl) || {};
  }
}
