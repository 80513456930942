import { _ } from 'lodash';
import { ValidationErrors } from '@angular/forms';

export type ChildErrorsFn = (control: ChildErrors) => ValidationErrors | null;

export class ChildErrors {
  childErrors: {};
  setArrayErrors(errorName, indices: string[]) {
    this.setArrayErrorsAsObject({ [errorName]: true }, indices);
    return this;
  }
  setArrayErrorsAsObject(error, indices: string[]) {
    this.childErrors = _.reduce(
      indices,
      (childErrorsAgg, duplicateIndex) => {
        return {
          ...childErrorsAgg,
          [duplicateIndex]: {
            ...childErrorsAgg[duplicateIndex],
            ...error
          }
        };
      },
      this.childErrors || {}
    );
    return this;
  }
  setErrors(childName, errors: object) {
    _.forEach(errors, (value, errorName) => {
      this.setError(childName, errorName, value);
    });
    return this;
  }
  setError(childName, errorName, value) {
    const childErrors = this.childErrors || {};
    this.childErrors = {
      ...childErrors,
      [childName]: {
        ...childErrors[childName],
        [errorName]: value
      }
    };
    return this;
  }
  createChildErrors(childName, factoryFn: ChildErrorsFn) {
    const childErrors = new ChildErrors();
    factoryFn(childErrors);
    this.setChildErrors(childName, childErrors);
    return this;
  }
  setChildErrors(childName, childErrors: ChildErrors) {
    this.childErrors = {
      ...this.childErrors,
      [childName]: childErrors
    };
  }
  getChildErrors(childName) {
    return this.childErrors[childName];
  }
  isEmpty() {
    return _.isEmpty(this.childErrors);
  }
}
