import { Component, Input, OnInit, Optional } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { RatingRatioInputAppearance } from '../../../shared/enums/RatingRatioInput';
import { SvgIconName } from '../../../shared/enums/SvgIcon';
import { RadioGroupComponent } from '../../../shared/components/form/radio/radio-group/radio-group.component';

let nextUniqueId = 0;

@Component({
  selector: 'app-rating-radio-input',
  templateUrl: './rating-radio-input.component.html',
  styleUrls: ['./rating-radio-input.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingRadioInputComponent implements OnInit {
  @Input() appearance: RatingRatioInputAppearance;
  @Input() disabled = false;

  protected defaultId = `rating-radio-input-${++nextUniqueId}`;
  public name: string;
  public inputValue: any = null;
  public isChecked = false;
  public RatingRatioInputAppearanceEnum = RatingRatioInputAppearance;
  public iconName: SvgIconName;

  constructor(
    private radioDispatcher: UniqueSelectionDispatcher,
    @Optional() public radioGroup: RadioGroupComponent
  ) {
    radioDispatcher.listen((id: string, name: string) => {
      if (id !== this.inputId && name === this.name) {
        this.checked = false;
      }
    });
  }

  ngOnInit() {
    if (this.radioGroup) {
      this.checked = this.radioGroup.value === this.value;
      this.name = this.radioGroup.id;
    }
    this.setIcon();
  }

  @Input()
  get value(): any {
    return this.inputValue;
  }

  set value(value: any) {
    if (this.inputValue !== value) {
      this.inputValue = value;
      if (this.radioGroup !== null) {
        if (!this.checked) {
          this.checked = this.radioGroup.value === value;
        }
        if (this.checked) {
          this.radioGroup.setSelected(this);
        }
      }
    }
  }

  @Input()
  get checked(): boolean {
    return this.isChecked;
  }

  set checked(value: boolean) {
    const newCheckedState = coerceBooleanProperty(value);
    if (this.isChecked !== newCheckedState) {
      this.isChecked = newCheckedState;
      if (
        newCheckedState &&
        this.radioGroup &&
        this.radioGroup.value !== this.value
      ) {
        this.radioGroup.setSelected(this);
        this.radioGroup.setTouched();
      } else if (
        !newCheckedState &&
        this.radioGroup &&
        this.radioGroup.value === this.value
      ) {
        this.radioGroup.setSelected(null);
      }

      if (newCheckedState) {
        this.radioDispatcher.notify(this.inputId, this.name);
      }
    }
  }

  onInputChange(event: Event) {
    event.stopPropagation();
    this.checked = true;

    if (this.radioGroup) {
      this.radioGroup.propagateChange(this.value);
    }
  }

  get isInvalid() {
    if (this.radioGroup) {
      return this.radioGroup.isInvalid;
    }
    return false;
  }

  get errorId(): string {
    return `${this.inputId}-error`;
  }

  get inputId(): string {
    return this.defaultId;
  }

  setIcon() {
    switch (this.appearance) {
      case this.RatingRatioInputAppearanceEnum.DarkRed:
        this.iconName = SvgIconName.Smile1;
        break;
      case this.RatingRatioInputAppearanceEnum.Red:
        this.iconName = SvgIconName.Smile2;
        break;
      case this.RatingRatioInputAppearanceEnum.Yellow:
        this.iconName = SvgIconName.Smile3;
        break;
      case this.RatingRatioInputAppearanceEnum.Blue:
        this.iconName = SvgIconName.Smile4;
        break;
      case this.RatingRatioInputAppearanceEnum.Green:
        this.iconName = SvgIconName.Smile5;
        break;
      default:
        break;
    }
  }
}
