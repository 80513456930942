/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./page-title.component";
var styles_PageTitleComponent = [i0.styles];
var RenderType_PageTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageTitleComponent, data: {} });
export { RenderType_PageTitleComponent as RenderType_PageTitleComponent };
function View_PageTitleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-svg-icon", [["class", "page-title__icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"], colorPrimary: [1, "colorPrimary"], colorSecondary: [2, "colorSecondary"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; var currVal_1 = _co.iconColorPrimary; var currVal_2 = _co.iconColorSecondary; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_PageTitleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 0, 0, currVal_0); }); }
function View_PageTitleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_PageTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "page-title": 0, "page-title--blue": 1, "page-title--white": 2, "page-title--transparent": 3, "page-title--green": 4, "page-title--yellow": 5, "page-title--warning": 6, "page-title--narrow": 7 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageTitleComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["defaultTitleTemplate", 2]], null, 0, null, View_PageTitleComponent_2)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h1", [["class", "page-title__heading"], ["data-cy", "pageTitleHeading"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageTitleComponent_3)), i1.ɵdid(9, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, (_co.appearance === _co.PageTitleAppearanceEnum.Blue), (_co.appearance === _co.PageTitleAppearanceEnum.White), (_co.appearance === _co.PageTitleAppearanceEnum.Transparent), (_co.appearance === _co.PageTitleAppearanceEnum.Green), (_co.appearance === _co.PageTitleAppearanceEnum.Yellow), (_co.appearance === _co.PageTitleAppearanceEnum.Warning), _co.narrow); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.iconName; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.titleTemplate ? _co.titleTemplate : i1.ɵnov(_v, 6)); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_PageTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-title", [], null, null, null, View_PageTitleComponent_0, RenderType_PageTitleComponent)), i1.ɵdid(1, 114688, null, 0, i6.PageTitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageTitleComponentNgFactory = i1.ɵccf("app-page-title", i6.PageTitleComponent, View_PageTitleComponent_Host_0, { title: "title", titleTemplate: "titleTemplate", appearance: "appearance", narrow: "narrow", iconName: "iconName", iconColorPrimary: "iconColorPrimary", iconColorSecondary: "iconColorSecondary" }, {}, ["*"]);
export { PageTitleComponentNgFactory as PageTitleComponentNgFactory };
