import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ChipAppearance } from '../../../enums/Chip';

@Component({
  selector: 'app-associate-page-header',
  templateUrl: './associate-page-header.component.html',
  styleUrls: ['./associate-page-header.component.scss']
})
export class AssociatePageHeaderComponent implements OnInit {
  public ChipAppearanceEnum = ChipAppearance;

  @Input() userName: string;
  @Input() apexId: number;
  @Input() isTrainee: boolean;
  @Input() photoSrc: string;
  @Input() isAssociateView = false;

  @Output() deletePhotoEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  deletePhoto(event) {
    this.deletePhotoEvent.emit(event);
  }
}
