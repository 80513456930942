import { Component, OnInit } from '@angular/core';
import { PageTitleAppearance } from '../../../shared/enums/PageTitle';
import { SvgIconColor, SvgIconName } from '../../../shared/enums/SvgIcon';

@Component({
  selector: 'app-covid-container',
  templateUrl: './covid-container.component.html',
  styleUrls: ['./covid-container.component.scss']
})
export class CovidContainerComponent implements OnInit {
  public PageTitleAppearanceEnum = PageTitleAppearance;
  public SvgIconNameEnum = SvgIconName;
  public SvgIconColorEnum = SvgIconColor;

  constructor() {}

  ngOnInit() {}
}
