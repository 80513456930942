import { Injectable, TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { TopSheetService } from '../modules/modal/top-sheet/top-sheet.service';
import { find, isEqual } from 'lodash/fp';

@Injectable()
export class AlertService {
  private activeAlerts = [];

  private config = {
    scrollStrategy: 'block',
    disposeOnNavigation: false,
    hasBackdrop: true,
    position: { top: '0px' },
    fullWidth: true
  };

  constructor(private topSheetService: TopSheetService) {}

  open<T>(component: ComponentType<T> | TemplateRef<T>, data?: any) {
    return this.openFromQueue(component, data);
  }

  private openFromQueue<T>(
    component: ComponentType<T> | TemplateRef<T>,
    data?: any,
    isReopen: boolean = false
  ) {
    if (this.activeAlerts.length > 0 && !isReopen) {
      this.addAlertToQueue(component, data);
      return;
    } else if (!isReopen) {
      this.addAlertToQueue(component, data);
    }

    const modal = this.topSheetService.open(component, {
      ...this.config,
      ...data
    });

    modal.afterClosed().subscribe(() => {
      this.activeAlerts.shift();
      if (this.activeAlerts.length > 0) {
        this.openFromQueue(
          this.activeAlerts[0].component,
          this.activeAlerts[0].data,
          true
        );
      }
    });

    return modal;
  }

  private addAlertToQueue(component, data) {
    if (!this.isAlertInQueue(this.activeAlerts, component, data)) {
      this.activeAlerts.push({ component, data });
    }
  }

  private isAlertInQueue(queue, component, data) {
    return !!find(alert => {
      return (
        alert.component.name === component.name && isEqual(data, alert.data)
      );
    })(queue);
  }
}
