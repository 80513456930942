<ng-template #defaultTitleTemplate>
  {{ title }}
</ng-template>

<ng-template #item>
  <app-svg-icon
    [name]="iconName"
    [colorPrimary]="iconColorPrimary"
    [colorSecondary]="iconColorSecondary"
    class="profile-link-item__icon"
  ></app-svg-icon>
  <span *ngIf="title || titleTemplate" class="profile-link-item__title">
    <ng-container
      *ngTemplateOutlet="titleTemplate ? titleTemplate : defaultTitleTemplate"
    ></ng-container>
  </span>
</ng-template>

<a
  *ngIf="link"
  [routerLink]="[link]"
  (click)="onLinkClick($event)"
  [ngClass]="{
    'profile-link-item': true,
    'profile-link-item--red': appearance === ProfileLinkItemAppearanceEnum.Red
  }"
>
  <ng-container *ngTemplateOutlet="item"></ng-container>
</a>
<button
  *ngIf="!link"
  (click)="onClickButton($event)"
  type="button"
  [ngClass]="{
    'profile-link-item': true,
    'profile-link-item--red': appearance === ProfileLinkItemAppearanceEnum.Red
  }"
>
  <ng-container *ngTemplateOutlet="item"></ng-container>
</button>
