import { select } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
export class LandingGuard {
    constructor(store, router) {
        this.store = store;
        this.router = router;
    }
    canActivate() {
        return this.store
            .pipe(select(fromRoot.getIsUserLoggedIn))
            .map(isUserLoggedIn => {
            const navigation = this.router.getCurrentNavigation();
            if (isUserLoggedIn) {
                this.router.navigate(['news'], navigation.extras);
                return false;
            }
            return true;
        });
    }
}
LandingGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LandingGuard_Factory() { return new LandingGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router)); }, token: LandingGuard, providedIn: "root" });
