import { Component, forwardRef, OnInit, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormFieldControl } from '../form-field/form-field.control';
import { keyBy } from 'lodash/fp';

export interface CheckboxListItems {
  label: string;
  id: string;
  children: Array<{ label: string; id: string }>;
}

let nextUniqueId = 0;
@Component({
  selector: 'app-nested-checkbox-list',
  templateUrl: './nested-checkbox-list.component.html',
  styleUrls: ['./nested-checkbox-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NestedCheckboxListComponent),
      multi: true
    },
    { provide: FormFieldControl, useExisting: NestedCheckboxListComponent }
  ]
})
export class NestedCheckboxListComponent
  implements OnInit, OnDestroy, ControlValueAccessor, FormFieldControl {
  formArray: FormArray;
  onDestroySubject = new Subject();
  @Input() items: CheckboxListItems[] = [];

  private onChange: any;

  protected componentId: string;
  protected defaultId = `nested-checkbox-list-${++nextUniqueId}`;

  constructor(private formBuilder: FormBuilder) {
    this.onChange = (_: any) => {};
  }

  @Input()
  get id(): string {
    return this.componentId;
  }

  set id(value: string) {
    this.componentId = value || this.defaultId;
  }

  get errorId(): string {
    return `${this.id}-error`;
  }

  public get control() {
    return this.formArray;
  }

  ngOnInit() {
    this.initFormGroup();
    this.formArray.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(items => {
        const checkedItemIds = this.getCheckedItemsIds(items);
        this.onChange(checkedItemIds);
        this.setCheckedItems(checkedItemIds);
      });
  }

  initFormGroup() {
    this.formArray = this.formBuilder.array(
      this.items
        ? this.items.map(item => {
            return this.formBuilder.group({
              id: item.id,
              label: item.label,
              children: this.formBuilder.array(
                item.children.map(child => {
                  return this.formBuilder.group({
                    id: child.id,
                    label: child.label,
                    checked: false
                  });
                })
              ),
              checked: false
            });
          })
        : []
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(checkedItemIds: any[]): void {
    this.setCheckedItems(checkedItemIds);
  }

  ngOnDestroy(): void {
    this.onDestroySubject.next();
  }

  getCheckedItemsIds(items): string[] {
    return items.reduce((acc, item) => {
      if (item.checked) {
        acc.push(item.id);
        item.children.map(childItem => {
          if (childItem.checked) {
            acc.push(childItem.id);
          }
        });
      }
      return acc;
    }, []);
  }

  setCheckedItems(itemsIds: string[]) {
    itemsIds = keyBy(item => item)(itemsIds);
    this.formArray.controls.forEach(control => {
      this.setItemCheckedState(control, !!itemsIds[control.get('id').value]);
      (control.get('children') as FormArray).controls.forEach(childControl => {
        this.setItemCheckedState(
          childControl,
          !!itemsIds[childControl.get('id').value]
        );
      });
    });
  }

  setItemCheckedState(
    control: AbstractControl,
    value: boolean,
    options = { emitEvent: false }
  ) {
    control.get('checked').setValue(value, options);
  }
}
