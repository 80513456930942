import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ChildErrors } from '../error-handlers/child-errors';

export function RequiredIf(
  fieldsToValidate: string | string[],
  validator: (control: AbstractControl) => {}
): ValidatorFn {
  return (control: AbstractControl) => {
    const childErrors = new ChildErrors();
    if (validator(control)) {
      if (fieldsToValidate instanceof Array) {
        fieldsToValidate.forEach(field => {
          setError(field);
        });
      } else {
        setError(fieldsToValidate);
      }
      return childErrors;
    }
    return null;

    function setError(fieldName) {
      if (control.value && !control.value[fieldName]) {
        childErrors.setError(fieldName, 'required', true);
      }
    }
  };
}
