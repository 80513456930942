import { Injectable, ApplicationRef, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { combineLatest, interval, Subject } from 'rxjs';
import { first, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppState, getIsAppOnline, isUserAgreeWithTerms } from '../../reducers';
import { Store } from '@ngrx/store';

@Injectable()
export class UpdateService implements OnDestroy {
  private onDestroySubject = new Subject();

  constructor(
    applicationRef: ApplicationRef,
    updates: SwUpdate,
    store: Store<AppState>
  ) {
    const appIsStable$ = applicationRef.isStable.pipe(
      first(isStable => isStable === true)
    );

    const interval$ = interval(60 * 60 * 1000);
    const agreedWithTerms$ = store.select(isUserAgreeWithTerms);
    const isAppOnline$ = store.select(getIsAppOnline);

    const onceAppIsStable$ = combineLatest(appIsStable$, agreedWithTerms$).pipe(
      filter(([stable, agree]) => stable && agree),
      first(),
      tap(() => {
        if (environment.production) {
          if (updates.isEnabled) {
            updates.checkForUpdate();
          }
        }
      }),
      switchMap(() => interval$)
    );

    if (environment.production) {
      combineLatest(onceAppIsStable$, isAppOnline$)
        .pipe(
          takeUntil(this.onDestroySubject),
          filter(([x, isAppOnline]) => isAppOnline)
        )
        .subscribe(() => {
          if (updates.isEnabled) {
            updates.checkForUpdate();
          }
        });
    }
  }

  ngOnDestroy() {
    this.onDestroySubject.next(null);
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }
}
