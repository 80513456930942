import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class DisabledGuard {
    constructor(router) {
        this.router = router;
    }
    canActivate(route) {
        if (route.data.routeEnabled == null || route.data.routeEnabled === true) {
            return true;
        }
        return this.router.navigate(['']).then(() => {
            return false;
        });
    }
}
DisabledGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DisabledGuard_Factory() { return new DisabledGuard(i0.ɵɵinject(i1.Router)); }, token: DisabledGuard, providedIn: "root" });
