<p
  *ngIf="formGroup.get('certificationsChecked').value"
  class="read-only-details"
  i18n="@@certification-readonly_certificationsMarked"
>
  {{ formGroup.get('certificationsChecked').value.length }}
  {formGroup.get('certificationsChecked').value.length, plural, =0 {No
  certifications marked} =1 {certification marked} other {certifications
  marked}}
</p>
