<div
  [ngClass]="{
    gauge: true,
    'gauge--above-goal-avg':
      appearance === GoalGaugeAppearanceEnum.AboveGoalAvg,
    'gauge--above-goal': appearance === GoalGaugeAppearanceEnum.AboveGoal,
    'gauge--met-goal': appearance === GoalGaugeAppearanceEnum.MetGoal,
    'gauge--green': appearance === GoalGaugeAppearanceEnum.Green,
    'gauge--blue': appearance === GoalGaugeAppearanceEnum.Blue,
    'gauge--red': appearance === GoalGaugeAppearanceEnum.Red
  }"
>
  <p
    [ngClass]="{
      gauge__label: true,
      'visually-hidden': hideLabel
    }"
  >
    <ng-template #defaultLabelTemplate>
      {{ label }}
    </ng-template>

    <ng-container
      *ngTemplateOutlet="labelTemplate ? labelTemplate : defaultLabelTemplate"
    ></ng-container>

    <span class="visually-hidden">
      {{ current }}
      <span i18n="@@outOf">out of</span>
      {{ max }} {{ suffix }}
    </span>
  </p>
  <div class="gauge__container">
    <svg
      class="gauge__svg"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 114 86"
      focusable="false"
      aria-hidden="true"
    >
      <defs>
        <marker
          [attr.id]="'EndMarker' + id"
          class="gauge__svg-marker"
          id="EndMarker"
          viewBox="0 0 9 12"
          markerWidth="2"
          markerHeight="2"
          refX="0"
          refY="6"
          orient="auto"
          fill="#565353"
          markerUnits="strokeWidth"
        >
          <path
            d="M8.5,5c0.7,0.5,0.7,1.5,0,1.9L4.9,9.6l-3,2.2c-0.8,0.6-1.9,0-1.9-1V6V1.2c0-1,1.1-1.5,1.9-1l3,2.2L8.5,5z"
          />
        </marker>
      </defs>

      <path
        stroke-width="6"
        fill="none"
        stroke="#eeeeee"
        stroke-linecap="round"
        d="M10,82c-4.2-7.6-6.6-16.2-6.6-25.5C3.4,27.5,26.9,4,55.9,4c25.9,0,47.5,18.8,51.7,43.5"
      />
      <path
        #path
        class="gauge__svg-path"
        [attr.marker-end]="'url(#EndMarker' + id + ')'"
        marker-end="url(#EndMarker)"
        stroke-width="6"
        fill="none"
        stroke="#565353"
        stroke-linecap="round"
        d="M10,82c-4.2-7.6-6.6-16.2-6.6-25.5C3.4,27.5,26.9,4,55.9,4c25.9,0,47.5,18.8,51.7,43.5"
      />
    </svg>

    <div class="gauge__goal" aria-hidden="true">
      <span class="gauge__goal-max">{{ localMaxValue }}</span>
      <span class="gauge__goal-label">
        <ng-template #defaultMaxLabelTemplate i18n="@@goal">
          goal
        </ng-template>
        <ng-template #customMaxLabelTemplate>
          {{ customMaxLabel }}
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            customMaxLabel ? customMaxLabelTemplate : defaultMaxLabelTemplate
          "
        ></ng-container>
      </span>
    </div>
    <app-svg-icon
      *ngIf="!current"
      [name]="SvgIconNameEnum.Hourglass"
      class="gauge__empty-icon"
    ></app-svg-icon>
    <div
      *ngIf="current && current > 0"
      class="gauge__center-content"
      aria-hidden="true"
    >
      <span class="gauge__current">
        {{ current | number: '1.0-0' }}{{ currentSuffix }}
      </span>
      <div class="gauge__suffix-container">
        <app-svg-icon
          [name]="SvgIconNameEnum.Star"
          class="gauge__suffix-icon"
        ></app-svg-icon>
        <span class="gauge__suffix-label">{{ suffix }}</span>
        <app-svg-icon
          [name]="SvgIconNameEnum.Star"
          class="gauge__suffix-icon"
        ></app-svg-icon>
      </div>
    </div>
  </div>
  <p
    i18n="@@goal-gauge_wereStillCalculating"
    *ngIf="!current"
    class="gauge__empty-notification"
  >
    We're still calculating...
    <br />
    Check back later.
  </p>
</div>
