import { Pipe, PipeTransform } from '@angular/core';
import { StatsLevel } from '../../../../enums/StatsLevel';
import { StatsAppearance } from '../../../../enums/Stats';

@Pipe({
  name: 'associateStatsAppearance'
})
export class AssociateStatsAppearancePipe implements PipeTransform {
  transform(value: any, ...args: any[]): StatsAppearance {
    switch (value) {
      case StatsLevel.Top10:
      case StatsLevel.Top20:
      case StatsLevel.Top30:
        return StatsAppearance.Green;
      case StatsLevel.Top50:
      case StatsLevel.Bottom50:
        return StatsAppearance.Blue;
      case StatsLevel.Bottom30:
      case StatsLevel.Bottom20:
      case StatsLevel.Bottom10:
        return StatsAppearance.Yellow;
      default:
        return null;
    }
  }
}
