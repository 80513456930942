import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  debounce,
  filter as rxFilter,
  finalize,
  first,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  APP_ERROR_MESSAGES,
  handleAppError
} from '../../../../shared/error-handlers/catch-app-error';
import { OpportunitiesService } from '../../../../core/contracts/OpportunitiesService';
import { EMPTY, forkJoin, interval, of } from 'rxjs';
import {
  AppState,
  getHasRoadCrewAccess,
  getUserEid,
  getUserName,
  getUserSitesIds,
  isUserAgreeWithTerms,
  selectOpportunityDetails
} from '../../../../reducers';
import { Store } from '@ngrx/store';
import { JobStatus } from '../../../../shared/enums/JobStatus';
import { MenuColorAppearance } from '../../../../shared/enums/Menu';
import moment from 'moment';
import { OpportunityType } from '../../../../core/enums/OpportunityType';
import { Router } from '@angular/router';
import {
  selectOpportunitiesListFilter,
  selectOpportunitiesListPage,
  selectOpportunitiesListSort
} from '../reducers/opportunities-list.reducer';
import { OpportunitiesActions, OpportunitiesListActions } from '../actions';
import { RoadCrewApplicationService } from '../../../../core/contracts/RoadCrewApplicationService';
import { LayoutActions, NavigationActions } from '../../../../core/actions';
import { TranslationMessages } from '../../../../shared/enums/TranslationMessages';
import { TranslationService } from '../../../../shared/services/translation.service';
import { FilterType } from '../enums/FilterType';
import { DurationFilterComponent } from '../components/duration-filter/duration-filter.component';
import { BottomSheetService } from '../../../../shared/modules/modal/bottom-sheet/bottom-sheet.service';
import { JobTitleFilterComponent } from '../components/job-title-filter/job-title-filter.component';
import { RegionFilterComponent } from '../components/region-filter/region-filter.component';
import { RegionFilterUtilsService } from '../services/region-filter-utils.service';
import { flatten, flow, isEmpty, map as lodashMap } from 'lodash/fp';
import { StaticContentService } from '../../../../core/contracts/StaticContentService';
import { RoadCrewStaticContent } from '../../../../shared/enums/RoadCrewStaticContent';
import { FormUtilitiesService } from '../services/form-utilities.service';

@Injectable()
export class OpportunitiesEffects {
  constructor(
    private actions$: Actions,
    private opportunitiesService: OpportunitiesService,
    private store: Store<AppState>,
    private router: Router,
    private applicationFormService: RoadCrewApplicationService,
    private translationService: TranslationService,
    private bottomSheetService: BottomSheetService,
    private regionFilterUtilsService: RegionFilterUtilsService,
    private staticContentService: StaticContentService,
    private formUtilitiesService: FormUtilitiesService,
    @Inject(APP_ERROR_MESSAGES) private errorMessages
  ) {}

  airports = null;

  loadOpportunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActions.loadOpportunityDetails),
      withLatestFrom(
        // this.store.select(getApexId) // might be used instead of EID
        this.store.select(getUserEid)
      ),
      tap(() => {
        this.store.dispatch(
          OpportunitiesActions.toggleOpportunityLoading({ isLoading: true })
        );
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }));
      }),
      switchMap(([action, userId]) => {
        return this.opportunitiesService
          .getDetails(action.id, action.otherId, userId)
          .pipe(
            finalize(() => {
              this.store.dispatch(
                OpportunitiesActions.toggleOpportunityLoading({
                  isLoading: false
                })
              );
              this.store.dispatch(
                LayoutActions.toggleAppLoading({ loading: false })
              );
            }),
            map(opportunity => {
              const opportunityData = isEmpty(opportunity) ? null : opportunity;
              return OpportunitiesActions.opportunityDetailsLoaded({
                opportunity: {
                  ...opportunityData,
                  id: opportunityData.opportunityID,
                  otherId: opportunityData.id
                }
              });
            }),
            catchError(() => {
              return of(
                OpportunitiesActions.opportunityDetailsLoaded({
                  opportunity: null
                })
              );
            })
          );
      })
    )
  );

  loadOpportunities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActions.loadOpportunities),
      withLatestFrom(
        this.store.select(getUserSitesIds),
        this.store.select(getUserEid)
      ),
      switchMap(([action, sites, userId]) => {
        return forkJoin(
          this.opportunitiesService.getRoadCrewOpenings(userId, {
            page: { skip: 0, limit: 5 }
          }),
          this.opportunitiesService.getListOfAppliedOpportunities(userId),
          this.applicationFormService.getApplication(userId),
          this.opportunitiesService.getOpportunitiesCount(userId, {
            startDateFrom: '',
            locationState: [],
            jobTitle: [],
            duration: [],
            query: ''
          })
        ).pipe(
          switchMap(
            ([roadCrew, applied, applicationForm, opportunitiesCount]) => {
              const updatedOpportunities =
                applied && applied.updatedItems
                  ? applied.updatedItems.map(item => {
                      return {
                        ...item,
                        id: item.opportunityID,
                        otherId: item.id,
                        menuColorAppearance:
                          item.status === JobStatus.Approved
                            ? MenuColorAppearance.Green
                            : item.status === JobStatus.Rejected
                            ? MenuColorAppearance.Yellow
                            : null,
                        hasUpdates: moment(item.updatedOn).isAfter(
                          item.lastSeenOn
                        )
                      };
                    })
                  : null;
              this.store.dispatch(
                this.handleApplicationFormResponse(applicationForm)
              );

              const adjustedCount =
                opportunitiesCount -
                applied.updatedItems.length -
                applied.pendingItems.length;
              const finalCount = adjustedCount < 0 ? 0 : adjustedCount;

              return of(
                OpportunitiesActions.allOpportunitiesLoaded({
                  roadCrewOpenings: roadCrew.items
                    ? roadCrew.items.map(item => {
                        return {
                          ...item,
                          id: item.opportunityID,
                          otherId: item.id
                        };
                      })
                    : null,
                  roadCrewOpeningsCount: finalCount,
                  updatedOpportunities,
                  pendingOpportunities:
                    applied && applied.pendingItems
                      ? applied.pendingItems.map(item => {
                          return {
                            ...item,
                            id: item.opportunityID,
                            otherId: item.id
                          };
                        })
                      : null
                })
              );
            }
          )
        );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  changeQueryFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesListActions.changeOpportunitiesListQueryFilter),
      debounce(() => interval(300)),
      withLatestFrom(this.store.select(selectOpportunitiesListFilter)),
      map(([action, filter]) => {
        return OpportunitiesListActions.changeOpportunitiesListFilter({
          filter: {
            ...filter,
            query: action.query
          }
        });
      })
    )
  );

  loadOpportunitiesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpportunitiesListActions.loadOpportunitiesList,
        OpportunitiesListActions.changeOpportunitiesListPage,
        OpportunitiesListActions.changeOpportunitiesListFilter,
        OpportunitiesListActions.changeOpportunitiesListSort,
        OpportunitiesListActions.clearFilters
      ),
      withLatestFrom(
        this.store.select(selectOpportunitiesListPage),
        this.store.select(selectOpportunitiesListFilter),
        this.store.select(selectOpportunitiesListSort),
        this.store.select(getUserEid)
        // this.store.select(getApexId) // might be used instead of EID
      ),
      tap(() =>
        this.store.dispatch(
          OpportunitiesListActions.toggleOpportunitiesListLoading({
            isLoading: true
          })
        )
      ),
      switchMap(([action, page, filter, sort, userId]) => {
        return this.staticContentService
          .getRoadCrewStaticContent([RoadCrewStaticContent.Regions])
          .pipe(
            switchMap(staticContent => {
              const filters = {
                ...filter,
                locationState:
                  filter.locationState && filter.locationState.length
                    ? flow(
                        lodashMap(region =>
                          this.regionFilterUtilsService.getStateValuesByRegion(
                            region,
                            staticContent.regions
                          )
                        ),
                        flatten
                      )(filter.locationState)
                    : []
              };
              const pager = {
                skip: page.pageIndex * page.pageSize,
                limit: page.pageSize
              };

              return this.opportunitiesService
                .getRoadCrewOpenings(userId, {
                  page: pager,
                  sort,
                  filter: filters
                })
                .pipe(
                  map(opportunitiesList => {
                    return OpportunitiesListActions.opportunitiesListLoaded({
                      items: [
                        ...opportunitiesList.items.map(item => ({
                          ...item,
                          id: item.opportunityID,
                          otherId: item.id
                        }))
                      ],
                      totalCount: opportunitiesList.totalCount
                    });
                  }),
                  finalize(() =>
                    this.store.dispatch(
                      OpportunitiesListActions.toggleOpportunitiesListLoading({
                        isLoading: false
                      })
                    )
                  )
                );
            })
          );
      }),
      catchError(handleAppError(this.errorMessages))
    )
  );

  openOpportunity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpportunitiesActions.openOpportunity),
        withLatestFrom(
          // this.store.select(getApexId),
          this.store.select(getUserEid)
        ),
        tap(([action, userId]) => {
          if (
            action.opportunityType === OpportunityType.RoadCrew &&
            action.isNew &&
            action.isNew === true
          ) {
            this.opportunitiesService.markAppliedOpportunityAsSeen(
              action.opportunityId,
              userId
            );
            this.store.dispatch(
              OpportunitiesActions.getIsEachOpportunityReviewed()
            );
          }
        }),
        tap(([action, userId]) => {
          this.router.navigate(
            ['/opportunities', action.opportunityId, 'details'],
            { queryParams: { id: action.id } }
          );
        })
      ),
    { dispatch: false }
  );

  loadApplicationForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActions.loadApplicationForm),
      withLatestFrom(
        // this.store.select(getApexId) // might be used instead of EID
        this.store.select(getUserEid)
      ),
      tap(() =>
        this.store.dispatch(
          LayoutActions.toggleAppLoading({
            loading: true
          })
        )
      ),
      switchMap(([action, userId]) => {
        return this.applicationFormService.getApplication(userId).pipe(
          finalize(() => {
            this.store.dispatch(
              LayoutActions.toggleAppLoading({
                loading: false
              })
            );
          }),
          map(applicationFormResponse => {
            return this.handleApplicationFormResponse(applicationFormResponse);
          }),
          catchError(handleAppError(this.errorMessages))
        );
      })
    )
  );

  submitApplicationForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActions.submitApplicationForm),
      withLatestFrom(
        // this.store.select(getApexId) // might be used instead of EID
        this.store.select(getUserEid)
      ),
      tap(() => {
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }));
      }),
      switchMap(([action, userId]) => {
        return this.applicationFormService
          .submitApplication(userId, action.form)
          .pipe(
            finalize(() => {
              this.store.dispatch(
                LayoutActions.toggleAppLoading({ loading: false })
              );
            }),
            map(response => {
              this.store.dispatch(
                LayoutActions.showSuccessMessage({
                  title: this.translationService.translate(
                    TranslationMessages.RoadCrewRegistrationComplete
                  ),
                  persistRouteChangeCount: 2
                })
              );
              this.store.dispatch(
                NavigationActions.navigateBack({ replaceUrl: true })
              );
              return OpportunitiesActions.saveApplicationForm({
                form: action.form
              });
            }),
            catchError(handleAppError(this.errorMessages))
          );
      })
    )
  );

  openApplicationForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpportunitiesActions.openApplicationForm),
        tap(() => {
          this.router.navigate(['/opportunity', 'apply']);
        })
      ),
    { dispatch: false }
  );

  applyForOpportunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActions.applyForOpportunity),
      withLatestFrom(
        this.store.select(getUserEid),
        this.store.select(getUserName),
        // this.store.select(getApexId) // might be used instead of EID
        this.store.select(selectOpportunityDetails)
      ),
      tap(() => {
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }));
      }),
      switchMap(([action, userId, userName, opportunity]) => {
        return this.opportunitiesService
          .applyForOpportunity(
            opportunity,
            userId,
            userName,
            action.preferredAirport
          )
          .pipe(
            map(response => {
              if (response && response.errorCode) {
                let errorMessage = '';
                switch (response.errorCode.toString()) {
                  case '101':
                    errorMessage = this.translationService.translate(
                      TranslationMessages.RoadCrewApplicationNotSubmitted
                    );
                    break;
                  case '102':
                    errorMessage = this.translationService.translate(
                      TranslationMessages.OpportunityStartDatePassed
                    );
                    break;
                  case '103':
                    errorMessage = this.translationService.translate(
                      TranslationMessages.OpportunityExpireDatePassed
                    );
                    break;
                  case '104':
                    errorMessage = this.translationService.translate(
                      TranslationMessages.OpportunityAlreadyRequested
                    );
                    break;
                  default:
                    errorMessage = response.errorCode;
                    break;
                }
                return LayoutActions.showError({
                  message: errorMessage,
                  error: response.errorCode
                });
              }
              setTimeout(() => {
                this.store.dispatch(
                  LayoutActions.showSuccessMessage({
                    title: this.translationService.translate(
                      TranslationMessages.OpportunityAppliedSuccessfully
                    )
                  })
                );
              });
              return NavigationActions.navigateBack({ replaceUrl: true });
            }),
            finalize(() => {
              this.store.dispatch(
                LayoutActions.toggleAppLoading({ loading: false })
              );
            }),
            catchError(handleAppError(this.errorMessages))
          );
      })
    )
  );

  cancelAppliedOpportunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActions.cancelAppliedOpportunity),
      withLatestFrom(
        this.store.select(getUserEid),
        // this.store.select(getApexId) // might be used instead of EID
        this.store.select(selectOpportunityDetails)
      ),
      tap(() => {
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }));
      }),
      switchMap(([action, userId, opportunity]) => {
        return this.opportunitiesService
          .cancelAppliedOpportunity(opportunity.opportunityID, userId)
          .pipe(
            map(response => {
              setTimeout(() => {
                this.store.dispatch(
                  LayoutActions.showSuccessMessage({
                    title: this.translationService.translate(
                      TranslationMessages.OpportunityApplicationCancelled
                    )
                  })
                );
              });
              return NavigationActions.navigateBack({ replaceUrl: true });
            }),
            finalize(() => {
              this.store.dispatch(
                LayoutActions.toggleAppLoading({ loading: false })
              );
            }),
            catchError(handleAppError(this.errorMessages))
          );
      })
    )
  );

  openDurationFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesListActions.openDurationFilter),
      withLatestFrom(
        this.store.select(getUserEid),
        // this.store.select(getApexId) // might be used instead of EID
        this.store.select(selectOpportunitiesListFilter)
      ),
      tap(() =>
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }))
      ),
      switchMap(([action, userId, filter]) => {
        return this.opportunitiesService
          .getAggregatedOpportunities(userId, FilterType.Duration, filter)
          .pipe(
            finalize(() =>
              this.store.dispatch(
                LayoutActions.toggleAppLoading({ loading: false })
              )
            ),
            map(response => {
              return {
                items: response.items
                  ? response.items.map(duration => ({
                      duration: duration.value,
                      count: duration.count
                    }))
                  : null
              };
            }),
            switchMap(durations => {
              const config = {
                data: {
                  durations: durations.items,
                  selectedDurations: filter.duration
                },
                fullHeight: true
              };
              return this.bottomSheetService
                .open(DurationFilterComponent, config)
                .afterClosed()
                .pipe(
                  switchMap(value => {
                    if (value && value.success) {
                      return of(
                        OpportunitiesListActions.changeOpportunitiesListFilter({
                          filter: {
                            ...filter,
                            duration: value.data.durations
                          }
                        })
                      );
                    }
                    return EMPTY;
                  })
                );
            }),
            catchError(handleAppError(this.errorMessages))
          );
      })
    )
  );

  openJobTitleFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesListActions.openJobTitleFilter),
      withLatestFrom(
        this.store.select(getUserEid),
        // this.store.select(getApexId) // might be used instead of EID
        this.store.select(selectOpportunitiesListFilter)
      ),
      tap(() =>
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }))
      ),
      switchMap(([action, userId, filter]) => {
        return this.opportunitiesService
          .getAggregatedOpportunities(userId, FilterType.JobTitle, filter)
          .pipe(
            finalize(() =>
              this.store.dispatch(
                LayoutActions.toggleAppLoading({ loading: false })
              )
            ),
            map(response => {
              return {
                items: response.items
                  ? response.items.map(jobTitle => ({
                      jobTitle: jobTitle.value,
                      count: jobTitle.count
                    }))
                  : null
              };
            }),
            switchMap(jobTitles => {
              const config = {
                data: {
                  jobTitles: jobTitles.items,
                  selectedJobTitles: filter.jobTitle
                },
                fullHeight: true
              };
              return this.bottomSheetService
                .open(JobTitleFilterComponent, config)
                .afterClosed()
                .pipe(
                  switchMap(value => {
                    if (value && value.success) {
                      return of(
                        OpportunitiesListActions.changeOpportunitiesListFilter({
                          filter: {
                            ...filter,
                            jobTitle: value.data.jobTitles
                          }
                        })
                      );
                    }
                    return EMPTY;
                  })
                );
            }),
            catchError(handleAppError(this.errorMessages))
          );
      })
    )
  );

  openRegionFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesListActions.openRegionFilter),
      withLatestFrom(
        this.store.select(getUserEid),
        // this.store.select(getApexId) // might be used instead of EID
        this.store.select(selectOpportunitiesListFilter)
      ),
      tap(() =>
        this.store.dispatch(LayoutActions.toggleAppLoading({ loading: true }))
      ),
      switchMap(([action, userId, filter]) => {
        const states$ = this.opportunitiesService
          .getAggregatedOpportunities(userId, FilterType.LocationState, filter)
          .pipe(
            map(response => {
              return {
                items: response.items
                  ? response.items.map(locationState => ({
                      locationState: locationState.value,
                      count: locationState.count
                    }))
                  : null
              };
            })
          );
        const regions$ = this.staticContentService.getRoadCrewStaticContent([
          RoadCrewStaticContent.Regions
        ]);
        return forkJoin(states$, regions$).pipe(
          finalize(() =>
            this.store.dispatch(
              LayoutActions.toggleAppLoading({ loading: false })
            )
          ),
          switchMap(([states, staticContent]) => {
            const config = {
              data: {
                regions: this.regionFilterUtilsService.mapRegionToStatesCount(
                  staticContent.regions,
                  states.items
                ),
                selectedRegions: filter.locationState
              },
              fullHeight: true
            };
            return this.bottomSheetService
              .open(RegionFilterComponent, config)
              .afterClosed()
              .pipe(
                switchMap(value => {
                  if (value && value.success) {
                    return of(
                      OpportunitiesListActions.changeOpportunitiesListFilter({
                        filter: {
                          ...filter,
                          locationState: value.data.regions
                        }
                      })
                    );
                  }
                  return EMPTY;
                })
              );
          }),
          catchError(handleAppError(this.errorMessages))
        );
      })
    )
  );

  triggerOpportunities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.appOnInit),
        switchMap(() => this.store.select(isUserAgreeWithTerms)),
        rxFilter(isUserAgree => isUserAgree),
        withLatestFrom(this.store.select(getHasRoadCrewAccess)),
        first(),
        tap(([agreeWithTerms, hasRoadCrewAccess]) => {
          if (hasRoadCrewAccess) {
            return this.store.dispatch(
              OpportunitiesActions.getIsEachOpportunityReviewed()
            );
          }
        })
      ),
    { dispatch: false }
  );

  getIsEachOpportunityReviewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActions.getIsEachOpportunityReviewed),
      withLatestFrom(this.store.select(getUserEid)),
      switchMap(([action, userId]) => {
        return this.opportunitiesService
          .getIsEachOpportunityReviewed(userId)
          .pipe(
            map(result => {
              return OpportunitiesActions.saveIsEachOpportunityReviewed({
                result
              });
            })
          );
      })
    )
  );

  private handleApplicationFormResponse(applicationFormResponse) {
    if (applicationFormResponse) {
      this.airports = this.formUtilitiesService.getAirports();
      let fullAirportName = '';

      if (this.airports != null) {
        const matchingAirports = this.airports.filter(airport =>
          airport.display.includes(
            applicationFormResponse.preApplyForm.travelInfo.preferredAirport
          )
        );

        if (matchingAirports.length === 0) {
          fullAirportName =
            applicationFormResponse.preApplyForm.travelInfo.preferredAirport;
        } else {
          fullAirportName = matchingAirports[0].id;
        }

        applicationFormResponse.preApplyForm.travelInfo.preferredAirport = fullAirportName;
      }

      return OpportunitiesActions.applicationFormLoaded({
        form: {
          ...applicationFormResponse.preApplyForm,
          travelInfo: {
            ...applicationFormResponse.preApplyForm.travelInfo,
            document:
              applicationFormResponse.preApplyForm.travelInfo.driverDocuments
          }
        },
        formStatus: applicationFormResponse.status
      });
    }
    return OpportunitiesActions.applicationFormLoaded({
      form: null,
      formStatus: null
    });
  }
}
