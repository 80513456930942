import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-multi-step-form-editable-step',
  templateUrl: './multi-step-form-editable-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiStepFormEditableStepComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  constructor() {}

  ngOnInit() {}
}
