<ul class="open-positions">
  <li *ngFor="let openPosition of openPositions" class="open-positions__item">
    <div class="open-positions__position">
      <app-svg-icon
        [name]="SvgIconNameEnum.Person"
        class="open-positions__icon"
      ></app-svg-icon>
      <span>
        {{ openPosition.associatesNeeded }} {{ openPosition.position }}
      </span>
    </div>

    <ul
      *ngIf="openPosition.certificatesNeeded.length > 0"
      class="open-positions__certificates"
    >
      <li
        *ngFor="let certificate of openPosition.certificatesNeeded"
        [ngClass]="{
          'open-positions__certificates-item': true,
          'open-positions__certificates-item--green':
            certificate | hasCertificate: userCertificates
        }"
      >
        {{ certificate | translate }}
        <span
          *ngIf="certificate | hasCertificate: userCertificates"
          class="open-positions__certified"
          i18n="@@open-positions_certified"
        >
          (certified)
        </span>
      </li>
    </ul>
  </li>
</ul>
