<div
  slot="modal-content"
  [ngClass]="{
    'document-copy': true,
    'document-copy--white': true
  }"
>
  <div
    [ngClass]="{
      'document-copy__header': true,
      'document-copy__header--blue': true
    }"
  >
    <button
      class="document-copy__close"
      (click)="closeModal()"
      data-cy="closeModalButton"
    >
      <app-svg-icon
        [name]="SvgIconNameEnum.Close"
        class="document-copy__close-icon"
      ></app-svg-icon>
      <span class="document-copy__close-title" i18n="@@page-header_close">
        Close
      </span>
    </button>
  </div>

  <div class="document-preview">
    <ng-template #previewPhotos i18n="@@document-preview_previewPhotos">
      Add/Review {documents.length, plural, =1 {Photo} other {Photos}}
    </ng-template>
    <app-page-title
      [titleTemplate]="previewPhotos"
      [appearance]="PageTitleAppearanceEnum.Transparent"
    ></app-page-title>
    <app-file-uploader
      *ngIf="documentsType === DocumentTypeEnum.License && hasInsurance"
      class="document-preview__uploader"
      [buttonTemplate]="uploadDriver"
      [previewTemplate]="driverPhoto"
      [errorTemplate]="driverError"
      fileType="image/jpg,image/jpeg,image/png"
      [uploadedFiles]="documents[0]"
      (filesUploaded)="onDriverLicenseUpload($event)"
    ></app-file-uploader>
    <app-file-uploader
      *ngIf="documentsType === DocumentTypeEnum.License && hasInsurance"
      class="document-preview__uploader"
      [buttonTemplate]="uploadInsurance"
      [previewTemplate]="insurancePhoto"
      [errorTemplate]="insuranceError"
      fileType="image/jpg,image/jpeg,image/png"
      [uploadedFiles]="documents[1]"
      (filesUploaded)="onInsuranceLicenseUpload($event)"
    ></app-file-uploader>
    <app-file-uploader
      *ngIf="documentsType === DocumentTypeEnum.License && !hasInsurance"
      class="document-preview__uploader"
      [buttonTemplate]="uploadDriver"
      [previewTemplate]="driverPhoto"
      [errorTemplate]="driverError"
      fileType="image/jpg,image/jpeg,image/png"
      [uploadedFiles]="documents[0]"
      (filesUploaded)="onDriverLicenseUpload($event)"
    ></app-file-uploader>
    <app-file-uploader
      *ngIf="documentsType === DocumentTypeEnum.ID"
      class="document-preview__uploader"
      [buttonTemplate]="uploadID"
      [previewTemplate]="IDPhoto"
      [errorTemplate]="IDError"
      fileType="image/jpg,image/jpeg,image/png"
      [uploadedFiles]="documents[0]"
      (filesUploaded)="onIDUpload($event)"
    ></app-file-uploader>
    <div class="document-preview__container">
      <app-card-layout>
        <app-card-layout-item>
          <app-page-section-header
            i18n-title="@@document-preview_profilePhotoGuidelines"
            title="Make sure That..."
          ></app-page-section-header>
          <ul>
            <li i18n="@@document-preview_photoIsClear">
              The photo is clear and well-lit.
            </li>
            <li i18n="@@document-preview_detailsNoneBlurry">
              None of ID details are blurry or out of focus.
            </li>
            <li i18n="@@document-preview_faceIsVisible">
              Your face is visible.
            </li>
            <li
              i18n="@@document-preview_expirationDateIsVisible"
              *ngIf="documentsType === DocumentTypeEnum.License"
            >
              Expiration Date is visible in both photos.
            </li>
          </ul>
          <p class="document-preview__warning">
            <app-svg-icon
              [name]="SvgIconNameEnum.Warning"
              class="document-preview__warning-icon"
            ></app-svg-icon>
            <span i18n="@@document-preview_warning">
              Any unreadable photos or Expired IDs could disqualify you from
              future road crew openings.
            </span>
          </p>
        </app-card-layout-item>
        <app-card-layout-item>
          <app-button-link
            class="document-preview__save"
            i18n-label="@@document-preview_savePhotos"
            label="Save Photos"
            [disabled]="saveDisabled"
            (buttonClick)="save()"
          ></app-button-link>
        </app-card-layout-item>
      </app-card-layout>
    </div>
  </div>
</div>

<ng-template #uploadDriver let-context>
  <app-upload-preview
    [appearance]="UploadPreviewAppearanceEnum.Blue"
    [focused]="context.focused"
    [iconName]="SvgIconNameEnum.FileDriverLicense"
    text="Upload Drivers License"
    i18n-text="@@document-preview_uploadDriversCard"
    subtext="(JPG, PNG files only)"
    i18n-subtext="@@document-preview_imageFileType"
  ></app-upload-preview>
</ng-template>

<ng-template #driverPhoto let-context>
  <app-upload-preview
    [focused]="context.focused"
    [image]="context.files[0]?.data"
  ></app-upload-preview>
</ng-template>

<ng-template #driverError let-context>
  <app-upload-preview
    [appearance]="UploadPreviewAppearanceEnum.Red"
    [iconName]="SvgIconNameEnum.FileError"
    [focused]="context.focused"
    text="Upload Drivers License"
    i18n-text="@@document-preview_uploadDriversCard"
    subtext="(JPG, PNG files only)"
    i18n-subtext="@@document-preview_imageFileType"
    error="Invalid file type"
    i18n-error="@@document-preview_imageFileTypeError"
  ></app-upload-preview>
</ng-template>

<ng-template #uploadInsurance let-context>
  <app-upload-preview
    [appearance]="UploadPreviewAppearanceEnum.Blue"
    [iconName]="SvgIconNameEnum.FileInsuranceLicense"
    [focused]="context.focused"
    text="Upload Insurance Card"
    i18n-text="@@document-preview_uploadInsuranceCard"
    subtext="(JPG, PNG files only)"
    i18n-subtext="@@document-preview_imageFileType"
  ></app-upload-preview>
</ng-template>

<ng-template #insurancePhoto let-context>
  <app-upload-preview
    [focused]="context.focused"
    [image]="context.files[0]?.data"
    (buttonClick)="context.openUploadPrompt()"
  ></app-upload-preview>
</ng-template>

<ng-template #insuranceError let-context>
  <app-upload-preview
    [appearance]="UploadPreviewAppearanceEnum.Red"
    [iconName]="SvgIconNameEnum.FileError"
    [focused]="context.focused"
    text="Upload Insurance Card"
    i18n-text="@@document-preview_uploadInsuranceCard"
    subtext="(JPG, PNG files only)"
    i18n-subtext="@@document-preview_imageFileType"
    error="Invalid file type"
    i18n-error="@@document-preview_imageFileTypeError"
  ></app-upload-preview>
</ng-template>

<ng-template #uploadID let-context>
  <app-upload-preview
    [appearance]="UploadPreviewAppearanceEnum.Blue"
    [iconName]="SvgIconNameEnum.FileDriverLicense"
    [focused]="context.focused"
    text="Upload ID"
    i18n-text="@@document-preview_uploadIDCard"
    subtext="(JPG, PNG files only)"
    i18n-subtext="@@document-preview_imageFileType"
  ></app-upload-preview>
</ng-template>

<ng-template #IDPhoto let-context>
  <app-upload-preview
    [focused]="context.focused"
    [image]="context.files[0]?.data"
  ></app-upload-preview>
</ng-template>

<ng-template #IDError let-context>
  <app-upload-preview
    [appearance]="UploadPreviewAppearanceEnum.Red"
    [iconName]="SvgIconNameEnum.FileError"
    [focused]="context.focused"
    text="Upload ID"
    i18n-text="@@document-preview_uploadIDCard"
    subtext="(JPG, PNG files only)"
    i18n-subtext="@@document-preview_imageFileType"
    error="Invalid file type"
    i18n-error="@@document-preview_imageFileTypeError"
  ></app-upload-preview>
</ng-template>
