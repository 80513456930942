import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../core/guards/authenticationGuard/authentication.guard';
import { ResourcesContainerComponent } from './containers/resources-container/resources-container.component';
import { ResourcesComponent } from './resources.component';
import { TeamDirectoryComponent } from './components/team-directory/team-directory.component';
import { StarsFeedContainerComponent } from './containers/stars-feed/stars-feed-container.component';
import { BlogPostComponent } from '../shared/components/feed/blog-post/blog-post.component';
import { FeedType } from '../shared/enums/FeedType';
import { TranslationMessages } from '../shared/enums/TranslationMessages';
import { SiteMapItemKeys } from '../core/enums/SiteMapItemKeys';

const routes: Routes = [
  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [AuthenticationGuard],
    data: {
      showMenuBar: true,
      pageTitle: TranslationMessages.ResourcesTitle
    },
    children: [
      {
        path: '',
        component: ResourcesContainerComponent,
        data: {
          pageTitle: TranslationMessages.ResourcesTitle,
          siteMapKey: SiteMapItemKeys.Resources
        }
      },
      {
        path: 'team-directory',
        component: TeamDirectoryComponent,
        data: {
          pageTitle: TranslationMessages.ResourcesTeamDirectoryTitle,
          siteMapKey: SiteMapItemKeys.ResourcesTeam
        }
      },
      {
        path: 'stars',
        component: StarsFeedContainerComponent,
        data: {
          showMenuBar: false,
          pageTitle: TranslationMessages.ResourcesStarsTitle,
          siteMapKey: SiteMapItemKeys.ResourcesStars
        }
      },
      {
        path: 'stars/:id',
        component: BlogPostComponent,
        data: {
          feedType: FeedType.Stars,
          pageTitle: TranslationMessages.ResourcesStarsTitle,
          siteMapKey: SiteMapItemKeys.ResourcesStar
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResourcesRoutingModule {}
