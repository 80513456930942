<app-page-header-container></app-page-header-container>
<main class="main-container">
  <app-loader [isLoading]="isPageLoading$ | async"></app-loader>

  <article *ngIf="dynamicPage$ | async as page">
    <app-page-title
      [title]="page.title"
      [appearance]="PageTitleAppearanceEnum.Blue"
    ></app-page-title>

    <div *ngFor="let bodyItem of page.body" class="page">
      <app-expandable-panel
        *ngIf="bodyItem.expandablePanel"
        [panel]="bodyItem.expandablePanel"
      ></app-expandable-panel>
      <div
        *ngIf="bodyItem.text"
        class="page__text"
        [innerHTML]="convertToHtmlString(bodyItem.text.body)"
      ></div>
    </div>
  </article>

  <ng-template #emptyStateMessage i18n="@@dynamicPage_noFound">
    Page Not Found
  </ng-template>

  <div
    *ngIf="
      (isPageLoading$ | async) === false && (dynamicPage$ | async) === null
    "
    class="main-content"
  >
    <app-empty-state [emptyStateTemplate]="emptyStateMessage"></app-empty-state>
  </div>
</main>
