/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./my-team-member-check-in.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/profile/check-in-history/check-in-history.component.ngfactory";
import * as i3 from "../../../shared/components/profile/check-in-history/check-in-history.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../shared/components/profile/associate-page-header/associate-page-header.component.ngfactory";
import * as i6 from "../../../shared/components/profile/associate-page-header/associate-page-header.component";
import * as i7 from "../../components/team-member-tabs/team-member-tabs.component.ngfactory";
import * as i8 from "../../components/team-member-tabs/team-member-tabs.component";
import * as i9 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i10 from "../../../shared/components/loader/loader.component";
import * as i11 from "../../../shared/components/empty-state/empty-state.component.ngfactory";
import * as i12 from "../../../shared/components/empty-state/empty-state.component";
import * as i13 from "../../../shared/services/translation.service";
import * as i14 from "../../../shared/components/page-header/page-header-container.component.ngfactory";
import * as i15 from "../../../shared/components/page-header/page-header-container.component";
import * as i16 from "@ngrx/store";
import * as i17 from "./my-team-member-check-in.component";
import * as i18 from "@angular/router";
var styles_MyTeamMemberCheckInComponent = [i0.styles];
var RenderType_MyTeamMemberCheckInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MyTeamMemberCheckInComponent, data: {} });
export { RenderType_MyTeamMemberCheckInComponent as RenderType_MyTeamMemberCheckInComponent };
function View_MyTeamMemberCheckInComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-check-in-history", [], null, null, null, i2.View_CheckInHistoryComponent_0, i2.RenderType_CheckInHistoryComponent)), i1.ɵdid(2, 49152, null, 0, i3.CheckInHistoryComponent, [], { userName: [0, "userName"], weeklyCheckinList: [1, "weeklyCheckinList"], lastWeeklyCheckin: [2, "lastWeeklyCheckin"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.ngIf.name; var currVal_1 = _v.context.ngIf; var currVal_2 = i1.ɵunv(_v, 2, 2, i1.ɵnov(_v, 3).transform(_co.lastWeeklyCheckin$)); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MyTeamMemberCheckInComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-associate-page-header", [], null, [[null, "deletePhotoEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deletePhotoEvent" === en)) {
        var pd_0 = (_co.deletePhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_AssociatePageHeaderComponent_0, i5.RenderType_AssociatePageHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i6.AssociatePageHeaderComponent, [], { userName: [0, "userName"], apexId: [1, "apexId"], isTrainee: [2, "isTrainee"], photoSrc: [3, "photoSrc"] }, { deletePhotoEvent: "deletePhotoEvent" }), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-team-member-tabs", [], null, null, null, i7.View_TeamMemberTabsComponent_0, i7.RenderType_TeamMemberTabsComponent)), i1.ɵdid(5, 49152, null, 0, i8.TeamMemberTabsComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-loader", [], [[2, "is-visible", null]], null, null, i9.View_LoaderComponent_0, i9.RenderType_LoaderComponent)), i1.ɵdid(7, 114688, null, 0, i10.LoaderComponent, [], { isLoading: [0, "isLoading"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_MyTeamMemberCheckInComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf.name; var currVal_1 = _v.parent.context.ngIf.apexId; var currVal_2 = _v.parent.context.ngIf.isTrainee; var currVal_3 = _v.parent.context.ngIf.profilePhoto; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.isWeeklyCheckinLoading$)); _ck(_v, 7, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 12).transform(((i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.isWeeklyCheckinLoading$)) === false) && _co.weeklyCheckinList$))); _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 7).visible; _ck(_v, 6, 0, currVal_4); }); }
function View_MyTeamMemberCheckInComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "main", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MyTeamMemberCheckInComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.isTeamMemberPhotoRemoving$)) === false); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MyTeamMemberCheckInComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Team member not found.\n"]))], null, null); }
function View_MyTeamMemberCheckInComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-state", [], null, null, null, i11.View_EmptyStateComponent_0, i11.RenderType_EmptyStateComponent)), i1.ɵdid(1, 114688, null, 0, i12.EmptyStateComponent, [i13.TranslationService], { emptyStateTemplate: [0, "emptyStateTemplate"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 8); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MyTeamMemberCheckInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-header-container", [], null, null, null, i14.View_PageHeaderContainerComponent_0, i14.RenderType_PageHeaderContainerComponent)), i1.ɵdid(1, 114688, null, 0, i15.PageHeaderContainerComponent, [i16.Store], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-loader", [], [[2, "is-visible", null]], null, null, i9.View_LoaderComponent_0, i9.RenderType_LoaderComponent)), i1.ɵdid(3, 114688, null, 0, i10.LoaderComponent, [], { isLoading: [0, "isLoading"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MyTeamMemberCheckInComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["emptyStateTemplate", 2]], null, 0, null, View_MyTeamMemberCheckInComponent_4)), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_MyTeamMemberCheckInComponent_5)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.isMainLoaderVisible$)); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.teamMemberInfo$)); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.teamMemberInfo$)) === null) && (i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 12).transform(_co.isWeeklyCheckinLoading$)) === false)); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).visible; _ck(_v, 2, 0, currVal_0); }); }
export function View_MyTeamMemberCheckInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-my-team-member-check-in", [], null, null, null, View_MyTeamMemberCheckInComponent_0, RenderType_MyTeamMemberCheckInComponent)), i1.ɵdid(1, 114688, null, 0, i17.MyTeamMemberCheckInComponent, [i16.Store, i18.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MyTeamMemberCheckInComponentNgFactory = i1.ɵccf("app-my-team-member-check-in", i17.MyTeamMemberCheckInComponent, View_MyTeamMemberCheckInComponent_Host_0, {}, {}, []);
export { MyTeamMemberCheckInComponentNgFactory as MyTeamMemberCheckInComponentNgFactory };
