/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/portal";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "../tab-label-wrapper.directive";
import * as i6 from "../tab-header/tab-header.component.ngfactory";
import * as i7 from "../tab-header/tab-header.component";
import * as i8 from "../services/tab-group.service";
import * as i9 from "./tab-group.component";
var styles_TabGroupComponent = [i0.styles];
var RenderType_TabGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabGroupComponent, data: {} });
export { RenderType_TabGroupComponent as RenderType_TabGroupComponent };
function View_TabGroupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_TabGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabGroupComponent_3)), i1.ɵdid(1, 212992, null, 0, i2.CdkPortalOutlet, [i1.ComponentFactoryResolver, i1.ViewContainerRef], { portal: [0, "portal"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.labelPortal; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TabGroupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.textLabel; _ck(_v, 1, 0, currVal_0); }); }
function View_TabGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["appTabLabelWrapper", ""], ["cdkMonitorElementFocus", ""], ["class", "tab-group__tab"], ["role", "tab"]], [[8, "id", 0], [1, "aria-selected", 0], [1, "aria-controls", 0], [1, "tabIndex", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setActiveTab(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "tab-group__tab": 0, "tab-group__tab--active": 1 }), i1.ɵdid(4, 147456, null, 0, i4.CdkMonitorFocus, [i1.ElementRef, i4.FocusMonitor], null, null), i1.ɵdid(5, 16384, [[1, 4]], 0, i5.TabLabelWrapperDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabGroupComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabGroupComponent_4)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "tab-group__tab"; var currVal_5 = _ck(_v, 3, 0, true, (_co.selectedTabIndex === _v.context.index)); _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_6 = _v.context.$implicit.labelTemplate; _ck(_v, 7, 0, currVal_6); var currVal_7 = !_v.context.$implicit.labelTemplate; _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTabLabelId(_v.context.index); var currVal_1 = (_co.selectedTabIndex === _v.context.index); var currVal_2 = _co.getTabContentId(_v.context.index); var currVal_3 = _co.getTabIndex(_v.context.index); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_TabGroupComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_TabGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-tab-header", [], null, [[null, "selectFocusedIndex"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectFocusedIndex" === en)) {
        var pd_0 = (_co.setActiveTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_TabHeaderComponent_0, i6.RenderType_TabHeaderComponent)), i1.ɵdid(1, 4440064, null, 1, i7.TabHeaderComponent, [], null, { selectFocusedIndex: "selectFocusedIndex" }), i1.ɵqud(603979776, 1, { tabLabels: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TabGroupComponent_1)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "tab-group__tab-content"], ["role", "tabpanel"]], [[8, "id", 0], [1, "aria-labelledby", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabGroupComponent_5)), i1.ɵdid(7, 212992, null, 0, i2.CdkPortalOutlet, [i1.ComponentFactoryResolver, i1.ViewContainerRef], { portal: [0, "portal"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.tabs; _ck(_v, 4, 0, currVal_0); var currVal_3 = ((_co.selectedTab == null) ? null : _co.selectedTab.contentPortal); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getTabContentId(_co.selectedTabIndex); var currVal_2 = _co.getTabLabelId(_co.selectedTabIndex); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_TabGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-tab-group", [], null, null, null, View_TabGroupComponent_0, RenderType_TabGroupComponent)), i1.ɵprd(512, null, i8.TabGroupService, i8.TabGroupService, []), i1.ɵdid(2, 49152, null, 1, i9.TabGroupComponent, [i8.TabGroupService], null, null), i1.ɵqud(603979776, 1, { tabs: 1 })], null, null); }
var TabGroupComponentNgFactory = i1.ɵccf("app-tab-group", i9.TabGroupComponent, View_TabGroupComponent_Host_0, {}, { tabChange: "tabChange" }, []);
export { TabGroupComponentNgFactory as TabGroupComponentNgFactory };
