/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/svg-icon/svg-icon.component.ngfactory";
import * as i3 from "../../../components/svg-icon/svg-icon.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./info-box.component";
import * as i6 from "./info-box-ref";
import * as i7 from "./info-box.config";
var styles_InfoBoxComponent = [i0.styles];
var RenderType_InfoBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoBoxComponent, data: {} });
export { RenderType_InfoBoxComponent as RenderType_InfoBoxComponent };
export function View_InfoBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "info-box__button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissTooltip() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close information box"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-svg-icon", [["class", "info-box__button-icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(4, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "info-box__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SvgIconNameEnum.Close; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.text; _ck(_v, 6, 0, currVal_1); }); }
export function View_InfoBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-box", [], null, null, null, View_InfoBoxComponent_0, RenderType_InfoBoxComponent)), i1.ɵdid(1, 114688, null, 0, i5.InfoBoxComponent, [i6.InfoBoxRef, i7.INFOBOX_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoBoxComponentNgFactory = i1.ɵccf("app-info-box", i5.InfoBoxComponent, View_InfoBoxComponent_Host_0, {}, {}, []);
export { InfoBoxComponentNgFactory as InfoBoxComponentNgFactory };
