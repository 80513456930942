import { Component, Inject } from '@angular/core';
import { SvgIconName } from '../../../../../shared/enums/SvgIcon';
import {
  ButtonAppearance,
  ButtonColor
} from '../../../../../shared/enums/ButtonLink';
import { DialogRef } from '../../../../../shared/modules/modal/dialog-ref';
import { DIALOG_DATA } from '../../../../../shared/modules/modal/dialog-config';

export enum CancelType {
  Opportunity = 'opportunity',
  RoadCrewApplication = 'roadCrewApplication'
}

@Component({
  selector: 'app-cancel-application',
  templateUrl: './cancel-application.component.html',
  styleUrls: ['./cancel-application.component.scss']
})
export class CancelApplicationComponent {
  SvgIconNameEnum = SvgIconName;
  ButtonAppearanceEnum = ButtonAppearance;
  ButtonColorEnum = ButtonColor;
  CancelTypeEnum = CancelType;
  public cancelType: CancelType;

  constructor(
    private modalRef: DialogRef<CancelApplicationComponent>,
    @Inject(DIALOG_DATA) data: any
  ) {
    this.cancelType = data.cancelType;
  }

  closeModal() {
    this.modalRef.close({ success: false });
  }

  cancel() {
    this.modalRef.close({ success: true });
  }
}
