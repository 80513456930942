/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./svg-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./svg-icon.component";
import * as i4 from "@angular/platform-browser";
var styles_SvgIconComponent = [i0.styles];
var RenderType_SvgIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SvgIconComponent, data: {} });
export { RenderType_SvgIconComponent as RenderType_SvgIconComponent };
function View_SvgIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:title", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
export function View_SvgIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, ":svg:svg", [["focusable", "false"], ["height", "100%"], ["role", "img"], ["version", "1.1"], ["width", "100%"], ["xmlns", "http://www.w3.org/2000/svg"]], [[1, "aria-hidden", 0], [1, "style", 2]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SvgIconComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.description; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.description; var currVal_1 = _co.iconColors; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = ("#" + _co.name); _ck(_v, 3, 0, currVal_3); }); }
export function View_SvgIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-svg-icon", [], null, null, null, View_SvgIconComponent_0, RenderType_SvgIconComponent)), i1.ɵdid(1, 638976, null, 0, i3.SvgIconComponent, [i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SvgIconComponentNgFactory = i1.ɵccf("app-svg-icon", i3.SvgIconComponent, View_SvgIconComponent_Host_0, { name: "name", description: "description", colorPrimary: "colorPrimary", colorSecondary: "colorSecondary" }, {}, []);
export { SvgIconComponentNgFactory as SvgIconComponentNgFactory };
